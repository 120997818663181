import { trackFilterApplied } from "@src/analytics/base";
import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { updateParams } from "@src/util/route";
import * as React from "react";
import { Checklist } from "../checklist/Checklist";

// // // //

interface OwnProps {
    // name is the query key
    name: string;
    // values is the array of possible values
    values: string[];
}

// ChecklistFilter renders the checklist filter which updates the route when a checkbox is checked
export class ChecklistFilter extends React.Component<
    OwnProps & { route: RouteState }
> {
    constructor(props: OwnProps & { route: RouteState }) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public onClick(checkedValues: string[]) {
        const [params] = updateParams({
            params: this.props.route.params,
            key: this.props.name,
            val: checkedValues,
            replace: true,
        });
        // reset the page param when the filter is applied
        delete params.page;
        const url = buildUrlWithParams({
            location: this.props.route.location,
            params,
        });
        trackFilterApplied(this.props.name, checkedValues, url);
        this.props.route.updateRouteAction(url, this.props.route.nextPathname);
    }

    public render() {
        // get the checked values from the query string
        let checkedValues: string[] = [];
        // get values from the query params
        const queryValues = this.props.route.params[this.props.name];
        if (typeof queryValues === "string") {
            // there is a single value in params
            checkedValues = [queryValues];
        } else if (Array.isArray(queryValues)) {
            // multiple options were checked. we ensure that the values are actually strings because `checkedValues` expects its values to be strings
            queryValues.forEach((value) => {
                if (typeof value === "string") {
                    checkedValues.push(value);
                }
            });
        }

        return (
            <Checklist
                values={this.props.values || []}
                checkedValues={checkedValues}
                onClick={this.onClick}
                className="justify-content-end"
            />
        );
    }
}

export const ChecklistFilterCC = withRouter(ChecklistFilter);

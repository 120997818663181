import { SyncNotificationRecipientsInput } from "@src/schema/SyncNotificationRecipientsInput";
import { gql } from "graphql-tag";

// // // //

/**
 * Query for Integration Sync Status recipients
 */
export const UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS = gql`
    mutation UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS(
        $input: SyncNotificationRecipientsInput!
    ) {
        updateSyncNotificationRecipients(input: $input)
    }
`;

/**
 * Response interface for UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS
 */
export interface SyncStatusRecipientsUpdaterResponse {
    updateSyncNotificationRecipients: string[];
}

/**
 * Variables interface for UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS
 */
export interface SyncStatusRecipientsUpdaterVariables {
    input: SyncNotificationRecipientsInput;
}

import * as React from "react";

// // // //

interface FocusProviderState {
    focused: boolean;
}

/**
 * FocusProviderProps
 * @param props.className - optional className to apply to the this.ref <div>
 * @param props.children - function that accepts state.focused and a function to update state.focused
 */
interface FocusProviderProps {
    className?: string;
    children: (childProps: {
        focused: boolean;
        setFocused: (updatedFocus: boolean) => void;
    }) => React.ReactNode;
}

/**
 * FocusProvider
 * Manages DOM focus and passes state + update method to props.children
 * Useful for dropdown menus + autocomplete inputs where a state change should occur when the user clicks outside the component
 * @param props - see `FocusProviderProps`
 */
export class FocusProvider extends React.Component<
    FocusProviderProps,
    FocusProviderState
> {
    public ref: HTMLDivElement | null;

    constructor(props: FocusProviderProps) {
        super(props);

        this.state = {
            focused: false,
        };

        // Bind the onMouseDown function to `this`
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    public componentDidMount() {
        // Adds this.onMouseDown event handler when component mounts
        document.addEventListener("mousedown", this.onMouseDown);
    }

    public componentWillUnmount() {
        // Removes this.onMouseDown event handler when component unmounts
        document.removeEventListener("mousedown", this.onMouseDown);
    }

    public onMouseDown(e: Event) {
        // Short-circuit function if e.target isn't a DOM node
        // NOTE - this is necessary to prevent a TS error
        if (!(e.target instanceof Node)) {
            return;
        }

        // Check if mouseDown event was inside or outside this.ref
        const isInsideRef = this.ref && this.ref.contains(e.target);

        // Short-circuit function if the mouseDown event was inside this.ref
        if (isInsideRef) {
            return;
        }

        // Set "state.focused" to "false" if the click was OUTSIDE this.ref
        this.setState({ focused: false });
    }

    public render() {
        const { focused } = this.state;
        const { className = "" } = this.props;

        return (
            <div
                ref={(ref) => {
                    this.ref = ref;
                }}
                className={className}
            >
                {this.props.children({
                    focused,
                    setFocused: (updatedFocus: boolean) => {
                        this.setState({ focused: updatedFocus });
                    },
                })}
            </div>
        );
    }
}

import { UrlParamsValue } from "@src/shared_modules/router";
import { APPLIED_ORG_SAVED_VIEW_PARAM } from "./constants";
import { OrgSavedView } from "./types";

// // // //

/**
 * findOrgSavedView
 * Used to locate the currently applied OrgSavedView instance
 */
export function findOrgSavedView(props: {
    routeParams: UrlParamsValue;
    orgSavedViews: OrgSavedView[];
}): OrgSavedView | null {
    const { routeParams, orgSavedViews } = props;

    // Check routeParams for const value
    const orgSavedViewID: string | undefined =
        routeParams[APPLIED_ORG_SAVED_VIEW_PARAM];

    // Return null if orgSavedViewID is not defined
    if (!orgSavedViewID) {
        return null;
    }

    // Attempt to locate the foundOrgSavedView
    const foundOrgSavedView: OrgSavedView | undefined = orgSavedViews.find(
        (osv) => osv.id === orgSavedViewID
    );

    // Return null if value is present, but not found in orgSavedViews
    if (foundOrgSavedView === undefined) {
        return null;
    }

    // Return OrgSavedView
    return foundOrgSavedView;
}

import { ReportList, REPORT_LIST_FRAGMENT } from "@src/schema/ReportList";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";

// // // //

/**
 * Query for Report data
 */
export const REPORT_LIST_FETCHER_QUERY = gql`
    query REPORT_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        usage {
            reports {
                list(filters: $filters) {
                    ...REPORT_LIST_FRAGMENT
                }
            }
        }
    }
    ${REPORT_LIST_FRAGMENT}
`;

/**
 * Response interface for REPORT_LIST_FETCHER_QUERY
 */
export interface ReportListFetcherResponse {
    usage: {
        reports: {
            list: ReportList;
            __typename: "Reports";
        };
        __typename: "Usage";
    };
}

/**
 * Variables interface for REPORT_LIST_FETCHER_QUERY
 */
export interface ReportListFetcherVariables {
    filters: FiltersInput;
}

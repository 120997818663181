import classnames from "classnames";
import * as React from "react";
import { NumberInput } from "./NumberInput";
import { InputSize } from "./types";

// // // //

interface CurrencyInputProps {
    value: number | null;
    step?: number;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    size?: InputSize;
    debounceTimeoutMs?: number;
    onChange: (updatedVal: number | null) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * CurrencyInput
 * Renders a placeholder <NumberInput /> Component
 * Pending an updated visual design for CurrencyInput Component
 *
 * @param props - see `CurrencyInputProps`
 */
export function CurrencyInput(props: CurrencyInputProps) {
    let { value, size = InputSize.md } = props;
    if (value !== null) {
        value = value / 100;
    }
    return (
        <div
            className={classnames(
                "d-inline-flex align-items-center w-100",
                "tw-relative"
            )}
        >
            <span
                className={classnames({
                    "tw-pl-6 tw-mb-[-2px]": true,
                    "tw-absolute": true,
                    ["font-size-12-px"]: size === InputSize.sm,
                    ["font-size-14-px"]: size === InputSize.md,
                    ["font-size-16-px"]: size === InputSize.lg,
                })}
            >
                $
            </span>
            <NumberInput
                {...props}
                min={0}
                value={value}
                onChange={(updatedValue: number) => {
                    props.onChange(updatedValue * 100);
                }}
                className={classnames({
                    ["!tw-pl-[30px]"]: size === InputSize.lg,
                    ["!tw-pl-[28px]"]: size === InputSize.md,
                    ["!tw-pl-[26px]"]: size === InputSize.sm,
                    [props.className]: props.className !== "",
                })}
            />
        </div>
    );
}

import { BreadcrumbNode } from "../types";
import { webhooksState } from "./state";
import { settingsNode } from "../settings";

// // // //

export const webhooksNode: BreadcrumbNode = {
    getState: webhooksState,
    parent: settingsNode,
};

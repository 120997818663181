import React from "react";

export function EmptyUsage(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 570 115"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-291 -328)">
                    <g transform="translate(291 328)">
                        <path fill="#EFEFEF" d="M0 54.925H36.383V115H0z"></path>
                        <path
                            fill="#EFEFEF"
                            d="M194.043 18.022H230.42600000000002V115H194.043z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M388.085 77.239H424.46799999999996V115H388.085z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M97.021 37.761H133.404V115H97.021z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M291.064 54.925H327.447V115H291.064z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M485.106 18.022H521.489V115H485.106z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M48.511 73.806H84.894V115H48.511z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M242.553 0H278.936V115H242.553z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M436.596 80.672H472.979V115H436.596z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M145.532 80.672H181.91500000000002V115H145.532z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M339.574 37.761H375.957V115H339.574z"
                        ></path>
                        <path
                            fill="#EFEFEF"
                            d="M533.617 73.806H570V115H533.617z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M0 54.925H36.383V84.96199999999999H0z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M194.043 18.022H230.42600000000002V66.94H194.043z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M388.085 77.239H424.46799999999996V96.12H388.085z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M97.021 37.761H133.404V76.38H97.021z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            style={{ mixBlendMode: "multiply" }}
                            d="M291.064 54.925H327.447V84.96199999999999H291.064z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M485.106 18.022H521.489V66.94H485.106z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M48.511 73.806H84.894V94.40299999999999H48.511z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M242.553 0H278.936V57.5H242.553z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M436.596 80.672H472.979V97.836H436.596z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M145.532 80.672H181.91500000000002V97.836H145.532z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M339.574 37.761H375.957V76.38H339.574z"
                        ></path>
                        <path
                            fill="#F7F7F7"
                            d="M533.617 73.806H570V94.40299999999999H533.617z"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import classNames from "classnames";
import * as React from "react";
import { CheckBox } from "../checkbox";

// // // //

// ChecklistProps represents the props that should be passed to the Checklist component
interface ChecklistProps {
    values: string[];
    checkedValues: string[];
    onClick: (a: string[]) => void;
    className?: string;
}

// Checklist component renders the checklist
export class Checklist extends React.Component<ChecklistProps> {
    public onClick(value: string) {
        let checkedValues = [];
        if (this.props.checkedValues.includes(value)) {
            checkedValues = this.props.checkedValues.filter(
                (checkedValue) => value !== checkedValue
            );
        } else {
            checkedValues = [...this.props.checkedValues, value];
        }
        this.props.onClick(checkedValues);
    }

    public render() {
        const { values, checkedValues, className } = this.props;
        return (
            <ul
                className={`d-flex align-items-center flex-wrap ${className ||
                    ""}`}
            >
                {values.map((value: string, index: number) => {
                    const checked = checkedValues.includes(value);
                    return (
                        <li
                            key={`checklist-item-${value}`}
                            className={`d-flex align-items-center pr-20-px pb-10-px`}
                        >
                            <CheckBox
                                checked={checked}
                                onClick={() => {
                                    this.onClick(value);
                                }}
                                tabIndex={index + 1}
                            />
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.onClick(value);
                                }}
                                className={classNames(
                                    "tw-border-none tw-bg-transparent tw-cursor-pointer tw-outline-none",
                                    "text-grey-mid font-secondary font-size-14-px line-height-19-px pr-5-px"
                                )}
                            >
                                {value}
                            </button>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

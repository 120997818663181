import {
    CursorPagination,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { AdvancedFiltersItemsPerPage } from "@src/modules/advanced_filters/components/advanced_filters";
import { EmptyQuery } from "@src/shared_modules/list";
import { Loader } from "@src/shared_modules/loader";
import * as React from "react";
import { Pagination } from "@src/schema/Pagination";
import {
    CursorPager,
    PaginationCC,
} from "../../../../shared_modules/pagination";
import { isCursorPagination } from "./isCursorPagination";

// // // //

/**
 * AdvancedFiltersPaginator
 * Renders ItemsPerPage + Pagination + EmptyState + Loading state
 * @param props.filtersInput - FiltersInput from AdvancedFiltersProvider
 * @param props.pagination - Pagination from RolesFetcher
 * @param props.itemCount - The number of items currently rendered on the page
 * @param props.loading - Whether or not to show the loading state
 * @param props.emptyStateMessage - Message for EmptyQuery
 * @param props.children - Table with search results
 * @param props.itemsPerPageOptions - (optional) value for AdvancedFiltersItemsPerPage.itemsPerPageOptions
 * @param totalRows - (optional) totalRows count + loading state (used for CursorPager only)
 */
export function AdvancedFiltersPaginator(props: {
    filtersInput: FiltersInput;
    pagination: Pagination | CursorPagination;
    itemCount: number;
    loading: boolean;
    emptyStateMessage: string;
    children: React.ReactNode;
    itemsPerPageOptions?: number[];
    totalRows?: {
        count: number;
        loading: boolean;
    };
}) {
    const {
        pagination,
        filtersInput,
        emptyStateMessage,
        loading,
        itemCount,
        itemsPerPageOptions,
        totalRows,
    } = props;

    // Defines flag indicatin whether or not to show the empty state
    const empty = itemCount === 0;

    // Gets flag indicating whether or not pagination is cursor-based
    const isCursorBased = isCursorPagination(pagination);

    return (
        <React.Fragment>
            <AdvancedFiltersItemsPerPage
                className="px-30-px pb-15-px"
                pagination={pagination}
                filtersInput={filtersInput}
                itemCount={itemCount}
                itemsPerPageOptions={itemsPerPageOptions}
                totalRows={totalRows}
            />

            {/* Handle loading state */}
            {loading && (
                <div className="my-100-px">
                    <Loader />
                </div>
            )}

            {/* Handle empty state */}
            {!loading && empty && (
                <div className="px-30-px">
                    <EmptyQuery
                        header={emptyStateMessage}
                        message="There were no results matching your search."
                    />
                </div>
            )}

            {/* Render props.children */}
            {!loading && !empty && (
                <React.Fragment>
                    <div className="px-30-px">{props.children}</div>
                    <div className="px-30-px pb-30-px">
                        {/* Render CursorPager for cursor-based pagination */}
                        {isCursorBased && (
                            <div className="mt-15-px">
                                <CursorPager
                                    filtersInput={filtersInput}
                                    itemCount={itemCount}
                                    pagination={pagination as CursorPagination}
                                    totalRows={totalRows}
                                />
                            </div>
                        )}

                        {/* Render PaginationCC for offset-based pagination */}
                        {!isCursorBased && (
                            <PaginationCC
                                {...(pagination as Pagination)}
                                filtersInput={filtersInput}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

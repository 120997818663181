import gql from "graphql-tag";
import { ConnectedApp } from "./ConnectedApp";
import { Pagination } from "./Pagination";

// // // //

/**
 * ConnectedAppsList represents a single connected app user
 */
export interface ConnectedAppsList {
    results: ConnectedApp[];
    pagination: Pagination;
    __typename: "ConnectedAppsList";
}

// export const CONNECTED_APPS_LIST_FRAGMENT = gql`
//     fragment CONNECTED_APPS_LIST_FRAGMENT on ConnectedAppsList {
//         results {
//             ...CONNECTED_APP_FRAGMENT
//         }
//         pagination {
//             ...PAGINATION_FRAGMENT
//         }
//     }
//     ${PAGINATION_FRAGMENT}
//     ${CONNECTED_APP_FRAGMENT}
// `;

// Non-nested version of above fragment
export const CONNECTED_APPS_LIST_FRAGMENT = gql`
    fragment CONNECTED_APPS_LIST_FRAGMENT on ConnectedAppsList {
        results {
            id
            organizationID
            source
            integrationInstanceID
            vendorID
            approvalState
            connectedUsersCount
            dangerRiskCount
            highRiskCount
            mediumRiskCount
            lowRiskCount
            minimumRiskCount
            unknownRiskCount
            sourceState
            sourceAppID
            sourceAppName
            sourceCreatedAt
            createdAt
            updatedAt
            approvalStateUpdatedAt
            lastConnectedAt
            vendor {
                id
                category
                description
                domain
                gdprLink
                logo
                name
                nameAliases
                pricingLink
                privacyLink
                state
                tldVendor
                tosLink
                verified
            }
            oauthIntegration {
                id
                name
                isActive
                status
                lastFetch
                tokenStatus {
                    status
                    statusReason
                }
            }
        }
        pagination {
            pageCount
            rangeStart
            rangeEnd
            page
            totalItems
            itemsPerPage
            sort
            direction
        }
    }
`;

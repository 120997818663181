/**
 * IndividualPageType
 * Types of individual pages
 * Used to determine where each `RelatedVendor` should link to
 */
export enum IndividualPageType {
    info = "info",
    spend = "spend",
    spendChart = "spendChart",
    usage = "usage",
    teamUsage = "teamUsage",
    roles = "roles",
    roleAssignments = "roleAssignments",
    connectedAppsApplications = "connectedAppsApplications",
    connectedAppsUsers = "connectedAppsUsers",
    purchaseOrders = "purchaseOrders",
}

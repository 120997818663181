import React from "react";

export function User(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#005BC0" transform="translate(-747 -239)">
                    <g transform="translate(747 239)">
                        <path d="M10.384 3.428c0 1.892-1.55 3.427-3.461 3.427-1.912 0-3.462-1.535-3.462-3.427C3.461 1.535 5.011 0 6.923 0c1.911 0 3.461 1.535 3.461 3.428z"></path>
                        <path d="M8.53 6.857c2.584.831 4.45 3.202 4.45 5.997H0c0-2.797 1.868-5.169 4.455-5.999"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

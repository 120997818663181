import classnames from "classnames";
import React from "react";
import { FilterExpression } from "./graphql";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListExpressionBadgeProps
 * expression - FilterExpression indication whether this component is rendering "And" or "Or"
 * hideBottomStem - boolean flag that hides the bottom border for the expression badge,
 * i.e.
 *
 *      |          instead of...        |
 *      |                               |
 *  Expression                      Expression
 *                                      |
 *                                      |
 */
interface FilterListExpressionBadgeProps {
    expression: FilterExpression;
    hideBottomStem?: boolean;
}

/**
 * FilterListExpressionBadge
 * Renders the "And" and "Or" badge placed between each FilterListItem
 */
export function FilterListExpressionBadge(
    props: FilterListExpressionBadgeProps
) {
    // Return badge for FilterExpression.and
    if (props.expression === FilterExpression.and) {
        return (
            <li
                className={classnames(
                    "py-10-px ml-30-px",
                    styles.andExpressionBadge
                )}
            >
                <span
                    className={classnames(
                        "bg-primary px-5-px py-5-px rounded d-inline-block",
                        styles.andExpressionBadgeContent
                    )}
                >
                    <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                        And
                    </p>
                </span>
            </li>
        );
    }

    // Return badge for FilterExpression.or
    return (
        <li
            className={classnames({
                "pt-10-px ml-30-px": true,
                "pb-10-px": !props.hideBottomStem,
                [styles.orExpressionBadge]: true,
            })}
        >
            <span
                className={classnames(
                    "bg-tertiary px-5-px py-5-px rounded d-inline-block",
                    styles.orExpressionBadgeContent
                )}
            >
                <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                    Or
                </p>
            </span>
        </li>
    );
}

import { integrationIndDash, routes } from "@src/routes";
import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState } from "../types";

// // // //

export const integrationsState = (route: RouteState): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.integrations,
                query: {},
            },
            as: routes.integrations,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "Integrations",
            },
        },
        enabled: true,
        active: route.nextPathname === routes.integrations,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Integrations" },
        },
    };
};

export const integrationIndividualDashState = (
    route: RouteState
): BreadcrumbState => {
    const service: string = route.pathParams.service;
    return {
        linkProps: {
            href: {
                pathname: routes.integrationIndividualDash,
                query: { service },
            },
            as: integrationIndDash(service),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: `Integration Individual Dash - ${service}`,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.integrationIndividualDash,
        resolverProps: {
            type: DisplayResolverTypes.integration,
            params: { source: service },
        },
    };
};

export const integrationIndividualAuthState = (
    _route: RouteState
): BreadcrumbState => {
    return {
        enabled: true,
        active: true, // This will always be the last crumb
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Authenticate" },
        },
    };
};

import { routes } from "@src/routes";
import { Alert, AlertTypes } from "@src/shared_modules/alert";
import { HourGlass, RocketShip } from "@src/shared_modules/emojis";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { Loader } from "@src/shared_modules/loader";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import * as React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IntegrationsFetcher } from "@src/modules/integrations/components/integrations_fetcher";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { Frame, frameStyles } from "../../../../shared_modules/frame";
import { initCheckSync, updateSyncAction } from "./action";
import { SyncingState } from "./reducer";

// // // //

// DispatchProps are the properties to dispatch
interface DispatchProps {
    initCheckSync: (service: string, isSpend: boolean) => void;
    updateSyncAction: (syncing: boolean, timeExpired: boolean) => void;
}

// State is the state of the reducer
interface State {
    integrationSuccess: SyncingState;
}

interface IntegrationData {
    // for some reason es lint is getting tripped that this isn't being used
    // eslint-disable-next-line react/no-unused-prop-types
    service: string;
    content: {
        isSpend: boolean;
        isUsage: boolean;
        isCompliance: boolean;
        name: string;
        isBeta: boolean;
        longSync: Boolean;
    };
}

// Props are the props that are passed to the success message
type Props = SyncingState & IntegrationData & DispatchProps;

interface SuccessProps {
    svg: React.ReactNode;
    text: string;
    hasVal: boolean;
    href: string;
}

// getTitle generates the title text for an integration depending on the type of integration it is
function getTitle(props: IntegrationData): string {
    if (props.content.isSpend) {
        return `We're syncing your ${props.content.name} spend`;
    }
    if (props.content.isUsage && props.content.isCompliance) {
        return `We're syncing your ${props.content.name} usage & apps that have access to your ${props.content.name} data`;
    }
    return `We're syncing your ${props.content.name} usage`;
}

// LongSuccess renders the success message for a long sync, since it's a long sync we tell them to kick back and we'll be with them shortly
function LongSuccess(props: IntegrationData) {
    let syncText = `The ${props.content.name} sync can take up to 24 hours. We will send you an email when your SaaS spend is live in the dashboard. `;
    // if it's a beta sync we render the beta sync slightly differently
    if (props.content.isBeta) {
        syncText = `The ${props.content.name} integration is in Beta and can take up to 24 hours to sync. We will send you an email when your SaaS spend is live in the dashboard.`;
    }

    return (
        <div className="tw-text-center tw-font-normal">
            <h3 className={`tw-pb-8 ${frameStyles.title}`}>
                {getTitle(props)} <RocketShip />
            </h3>
            <p className={frameStyles.description}>{syncText}</p>
        </div>
    );
}

// SuccessLink renders a link to the appropriate listing page on success
function SuccessLink(props: SuccessProps) {
    if (!props.hasVal) {
        return null;
    }

    return (
        <LinkCC
            href={props.href}
            className="d-flex align-items-center blue-lg-round-button tw-mx-6 tw-font-bold"
        >
            <span className="d-flex align-items-center">{props.svg}</span>{" "}
            {props.text}
        </LinkCC>
    );
}

// SyncingComplete is the component that we render when we've completed a sync
function SyncingComplete(props: IntegrationData & SyncingState) {
    if (props.timeExpired) {
        return (
            <div>
                <h3 className={frameStyles.title}>
                    Your {props.content.name} usage data is taking longer than
                    expected to sync
                </h3>
                <p className={frameStyles.description}>
                    This is likely because of the amount of data you have. We
                    will send you an email when your SaaS usage data is live in
                    the dashboard.
                </p>
            </div>
        );
    }
    // Height & fill styles for svgs
    const svgClassNames = "tw-h-8 tw-fill-white";
    return (
        <div>
            <h3
                className={classnames(
                    "tw-max-w-5xl", // width
                    "tw-font-avenir font-size-28-px line-height-40-px", // font
                    "tw-text-center", // alignment
                    "tw-mx-auto" // margin
                )}
            >
                {props.content.name} Sync Successful!
            </h3>
            <div className="d-flex align-items-center justify-content-center tw-pt-10">
                <SuccessLink
                    hasVal={props.content.isSpend}
                    text="Manage Spend"
                    svg={
                        <Icon
                            type={IconTypes.Spend}
                            className={svgClassNames}
                        />
                    }
                    href={routes.appListing}
                />
                <SuccessLink
                    hasVal={props.content.isUsage}
                    text="Optimize Usage"
                    svg={
                        <Icon
                            type={IconTypes.Usage}
                            className={svgClassNames}
                        />
                    }
                    href={routes.usage}
                />
                <SuccessLink
                    hasVal={props.content.isCompliance}
                    text="Strengthen Compliance"
                    svg={
                        <Icon type={IconTypes.Auth} className={svgClassNames} />
                    }
                    href={routes.connectedAppsListing}
                />
            </div>
        </div>
    );
}

// SuccessSync renders the successful sync component, if it's syncing it renders a syncing message otherwise it renders
// a component directing users to the appropriate part of the app
class SuccessSync extends React.Component<Props> {
    public componentDidMount() {
        // initialize checking the sync status
        this.props.initCheckSync(
            this.props.service,
            this.props.content.isSpend
        );
    }

    public componentWillUnmount() {
        // when the component unmounts we set the syncing to true so that if a user comes
        // back to this page again we can trigger the whole syncing process again
        this.props.updateSyncAction(true, false);
    }

    public render() {
        // if the page is completed syncing we render the syncing complete
        if (!this.props.syncing) {
            return <SyncingComplete {...this.props} />;
        }

        return (
            <section className="d-flex align-items-center justify-content-center flex-column">
                <Loader />
                <h2 className={frameStyles.title}>
                    {getTitle(this.props)} <HourGlass />
                </h2>
                <h5 className={frameStyles.description}>
                    This may take a minute or two.
                </h5>
            </section>
        );
    }
}

// mapStateToProps generates the map state to props for the integration success
function mapStateToProps({ integrationSuccess }: State): SyncingState {
    return {
        ...integrationSuccess,
    };
}

// mapDispatchToProps generates the dispatch props for the success component
function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators({ initCheckSync, updateSyncAction }, dispatch);
}

const SuccessSyncCC = connect(mapStateToProps, mapDispatchToProps)(SuccessSync);

// SuccessFrame renders a frame wrapping around the success
export function SuccessFrame(props: IntegrationData) {
    // if the content is a long sync we render the long sync or if we're in beta we render
    if (props.content.longSync || props.content.isBeta) {
        return <LongSuccess {...props} />;
    }

    return <SuccessSyncCC {...props} />;
}

// Success renders the success flow for a given integration wrapping around the individual component so we can fetch data about
// the individual component before rendering the success frame
export function Success(props: { user: UserState; route: RouteState }) {
    return (
        <section className="tw-w-full">
            <Frame showLogo={false}>
                <IntegrationsFetcher fetchPolicy="cache-and-network">
                    {({ loading, getIntegration }) => {
                        if (loading) {
                            return <Loader />;
                        }

                        const service = props.route.pathParams.service;
                        const integrationData = getIntegration(service);

                        if (integrationData == null) {
                            captureMessage(
                                `Couldn't find an integration with that name ${service}`
                            );
                            return (
                                <Alert
                                    type={AlertTypes.danger}
                                    removable={false}
                                    text="Couldn't find an integration with that name"
                                />
                            );
                        }

                        return <SuccessFrame {...integrationData} />;
                    }}
                </IntegrationsFetcher>
            </Frame>
        </section>
    );
}

// IntegrationSuccess is the component that we render when an integration has successfully synced
export const IntegrationSuccess = withUser(withRouter(Success));

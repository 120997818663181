import { ldClient } from "./client";
import { FeatureNames } from "./flags";

// // // //

/**
 * useFeatureFlag
 * Accepts a featureName and returns a boolean indicating whether or not the feature flag is turned on
 */
export function useFeatureFlag(featureName: FeatureNames): boolean {
    if (ldClient === null) {
        return false;
    }

    return ldClient.variation(featureName, false);
}

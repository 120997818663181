import { FeatureFlagCC, FeatureNames } from "../feature_flag";

// // // //

export function IntegrationsModeFeatureFlag(props: {
    variation?: boolean;
    children: React.ReactNode;
}) {
    const { variation = false } = props;
    return (
        <FeatureFlagCC
            variation={variation}
            featureName={FeatureNames.enableIntegrationsOnlyMode}
        >
            {props.children}
        </FeatureFlagCC>
    );
}

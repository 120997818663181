import * as React from "react";
import { trackClickRelativeDateInputButton } from "../../../analytics/date_input";
import {
    DATE_PRESET_VALUES,
    DATE_PRESETS,
    SIXTY_DAYS,
    THIRTY_DAYS,
} from "./constants";
import { CustomDateRangeInput } from "./CustomDateRangeInput";
import { DateInputButton } from "./DateInputButton";
import styles from "./styles.module.css";
import { DateInputValue } from "./types";

// // // //

/**
 * RelativeDateInput
 * Renders selectors for DATE_PRESETS + CustomDateRangeInput
 * Used by upcoming + past FilterExpressions
 * @param props.value - the current value from DateInput
 * @param props.setValue - callback to update props.value
 */
export function RelativeDateInput(props: {
    value: DateInputValue;
    setValue: (updatedValue: DateInputValue) => void;
}) {
    // Pulls first entry from props.value, casts as Number
    const daysValue: number = Number(props.value[0]);

    // Default state for customActive
    const customActiveDefault: boolean =
        (typeof daysValue === "number" &&
            !DATE_PRESET_VALUES.includes(daysValue)) ||
        props.value.length === 2;

    // Maintains internal state for whether or not the CustomDateRangeInput is being displayed
    const [customActive, setCustomActive] = React.useState<boolean>(
        customActiveDefault
    );

    return (
        <React.Fragment>
            <div className={styles.inputContainer}>
                {/* Render a DateInputButton for each preset */}
                {DATE_PRESETS.map((preset) => (
                    <DateInputButton
                        key={preset.label}
                        label={preset.label}
                        className="mt-10-px"
                        active={
                            props.value.length === 1 &&
                            preset.value === props.value[0] &&
                            !customActive
                        }
                        onClick={() => {
                            // Tracks the analytics event
                            trackClickRelativeDateInputButton(preset.label);

                            // Sets state.customActive to false
                            setCustomActive(false);

                            // Applies preset.value
                            props.setValue([preset.value]);
                        }}
                    />
                ))}

                {/* Render a DateInputButton for "Custom" range */}
                <DateInputButton
                    label="Custom"
                    className="mt-10-px"
                    active={customActive}
                    onClick={() => {
                        // Short-circuit if "Custom" is active
                        if (customActive) {
                            return;
                        }

                        // Tracks the analytics event
                        trackClickRelativeDateInputButton("Custom");

                        // Sets state.customActive to true
                        setCustomActive(true);

                        // Apply default custom range of 30-60 days
                        props.setValue([THIRTY_DAYS, SIXTY_DAYS]);
                    }}
                />
            </div>

            {/* Renders CustomDateRangeInput */}
            {customActive && (
                <CustomDateRangeInput
                    value={props.value}
                    onChange={props.setValue}
                />
            )}
        </React.Fragment>
    );
}

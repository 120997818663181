import { WORKFLOW_LIST_FRAGMENT, WorkflowList } from "@src/schema/WorkflowList";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";

// // // //

/**
 * Query for workflows data
 */
export const WORKFLOW_LIST_FETCHER_QUERY = gql`
    query WORKFLOW_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        workflows {
            workflows {
                list(filters: $filters) {
                    ...WORKFLOW_LIST_FRAGMENT
                }
            }
        }
    }
    ${WORKFLOW_LIST_FRAGMENT}
`;

/**
 * Response interface for WORKFLOW_LIST_FETCHER_QUERY
 */
export interface WorkflowListFetcherResponse {
    workflows: {
        workflows: {
            list: WorkflowList;
            __typename: "WorkflowsListing";
        };
        __typename: "Workflows";
    };
}

/**
 * Variables interface for WORKFLOW_LIST_FETCHER_QUERY
 */
export interface WorkflowListFetcherVariables {
    filters: FiltersInput;
}

import debounce from "lodash.debounce";
import * as React from "react";

// // // //

/**
 * UseDebouncedInputProps
 * `T` - the type of value we're passing into `props.onChange`
 * `debounceTimeoutMs` - (optional) dictates the amount of time (in ms) to debounce user input.
 * `onChange` - function invoked when a change event is detected on the `<input />` element
 */
interface UseDebouncedInputProps<T> {
    debounceTimeoutMs: number;
    onChange: (updatedVal: T) => void;
}

/**
 * TextInput
 * Renders a single-line <input /> for editing string values
 * @param props - see `UseDebouncedInputProps`
 */
export function useDebouncedInput<T>(
    props: UseDebouncedInputProps<T>
): (updatedVal: T) => void {
    // Pulls `debounceTimeoutMs` prop, defaults to `0`
    const { debounceTimeoutMs = 0 } = props;

    // Defines a function to invoke props.onChange
    let setInputValue = (updatedValue: T) => {
        props.onChange(updatedValue);
    };

    // If debounceTimeoutMs is defined, we redefine setInputValue to debounce the user input
    // We conditionally redefine `setInputValue` only as-needed for performance reasons.
    // NOTE - we implement the `useCallback` hook here to prevent the debounced version
    // of `setInputValue` from being overwritten each time this component re-renders.
    // Doc: https://medium.com/@rajeshnaroth/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
    // NOTE - we pass `[props.onChange]` as the second parameter to `useCallback` to act as the dependencies for the hook
    // When `props.onChange` - er, changes - the `setInputValue` function is re-defined as a debounced version of the LATEST value for `props.onChange`
    if (debounceTimeoutMs > 0) {
        setInputValue = React.useCallback(
            debounce((updatedValue: T) => {
                props.onChange(updatedValue);
            }, debounceTimeoutMs),
            [props.onChange]
        );
    }

    // Returns the setInputValue function
    return setInputValue;
}

import * as React from "react";
import ReactImageFallback from "react-image-fallback";

// Constants for fallback and initial images
const initialImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAQAAADa613fAAAAa0lEQVR42u3PMREAAAgEIL9/XANoBHcPGpCeeiEiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIpcFp73C7StSXrYAAAAASUVORK5CYII=";

interface ImageLoaderProps {
    alt: string;
    className?: string;
    src?: string;
}

// ImageLoader is a generic component used to load a square image
// Includes initial image and fallback image support
export default function ImageLoader(props: ImageLoaderProps) {
    // Pulls src from props, sets default value to initialImage if no `src` prop is provided
    const { src = initialImage } = props;

    return (
        <ReactImageFallback
            alt={props.alt}
            className={props.className}
            fallbackImage={initialImage}
            initialImage={initialImage}
            src={src}
        />
    );
}

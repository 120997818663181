import React from "react";

export function ConnectedAppsTileInfo(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <path
                d="M11.5 0.75C5.39844 0.75 0.5 5.69141 0.5 11.75C0.5 17.8516 5.39844 22.75 11.5 22.75C17.5586 22.75 22.5 17.8516 22.5 11.75C22.5 5.69141 17.5586 0.75 11.5 0.75ZM11.5 17.9375C10.7266 17.9375 10.125 17.3359 10.125 16.5625C10.125 15.7891 10.6836 15.1875 11.5 15.1875C12.2305 15.1875 12.875 15.7891 12.875 16.5625C12.875 17.3359 12.2305 17.9375 11.5 17.9375ZM14.4648 11.8359L12.5312 13.0391V13.125C12.5312 13.6836 12.0586 14.1562 11.5 14.1562C10.9414 14.1562 10.4688 13.6836 10.4688 13.125V12.4375C10.4688 12.0938 10.6406 11.75 10.9844 11.5352L13.4336 10.0742C13.7344 9.90234 13.9062 9.60156 13.9062 9.25781C13.9062 8.74219 13.4336 8.3125 12.918 8.3125H10.7266C10.168 8.3125 9.78125 8.74219 9.78125 9.25781C9.78125 9.81641 9.30859 10.2891 8.75 10.2891C8.19141 10.2891 7.71875 9.81641 7.71875 9.25781C7.71875 7.58203 9.05078 6.25 10.6836 6.25H12.875C14.6367 6.25 15.9688 7.58203 15.9688 9.25781C15.9688 10.2891 15.4102 11.2773 14.4648 11.8359Z"
                fill="#5F6060"
            />
        </svg>
    );
}

import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbState } from "../types";

// // // //

export const appFieldIndividualState = (route: RouteState) => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);
    return {
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.vendor,
            params: { vendorID },
        },
    };
};

export const appInfoState: BreadcrumbState = {
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Info" },
    },
};

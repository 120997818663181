import moment from "moment";
import * as React from "react";
import { formatMomentISO, genMoment } from "@src/util/date_util";
import { trackClickWeekInputButton } from "./analytics";
import { WEEK_PRESET_VALUES, WEEK_PRESETS } from "./constants";
import { CustomWeekRangeInput } from "./CustomDateRangeInput";
import { DateInputButton } from "../date_input/DateInputButton";
import { WeekInputValue } from "./types";

// // // //

/**
 * RelativeWeekInput
 * Renders selectors for DATE_PRESETS + CustomDateRangeInput
 * Used by upcoming + past FilterExpressions
 * @param props.value - the current value from WeekInput
 * @param props.setValue - callback to update props.value
 */
export function RelativeWeekInput(props: {
    value: WeekInputValue;
    setValue: (updatedValue: WeekInputValue) => void;
}) {
    // Pulls first entry from props.value, casts as Number
    const daysValue: number = Number(props.value[0]);

    // Get the Sunday of the current week in ISO format
    const CURRENT_WEEK_START = moment()
        .startOf("week")
        .utc();
    const CURRENT_WEEK_END = moment()
        .endOf("week")
        .subtract(1, "day")
        .utc();
    const CURRENT_WEEK_VALUE = formatMomentISO(CURRENT_WEEK_START);

    // Default state for currentWeekActive
    const currentWeekActive: boolean =
        typeof props.value[0] === "string" && props.value[1] === undefined;

    // Defines formatted date for currentWeek tab
    let currentWeekDisplay: string = CURRENT_WEEK_END.format("MMM DD");
    if (currentWeekActive) {
        currentWeekDisplay = genMoment(String(props.value[0]))
            .endOf("week")
            .subtract(1, "day")
            .utc()
            .format("MMM DD");
    }

    // Default state for customActive
    const customActiveDefault: boolean =
        (currentWeekActive === false &&
            typeof daysValue === "number" &&
            typeof !Number.isNaN(daysValue) &&
            !WEEK_PRESET_VALUES.includes(daysValue)) ||
        props.value.length === 2;

    // Maintains internal state for whether or not the CustomDateRangeInput is being displayed
    const [customActive, setCustomActive] = React.useState<boolean>(
        customActiveDefault
    );

    return (
        <React.Fragment>
            <div
                className="tw-flex tw-items-center tw-flex-wrap"
                style={{ minHeight: "32px" }}
            >
                {/* Render a DateInputButton for "Current Week" */}
                <DateInputButton
                    label="Current week"
                    active={currentWeekActive && !customActive}
                    onClick={() => {
                        // Tracks the analytics event
                        trackClickWeekInputButton("Current week");

                        // Sets state.customActive to false
                        setCustomActive(false);

                        // Applies preset.value
                        props.setValue([CURRENT_WEEK_VALUE]);
                    }}
                />

                {/* Render a DateInputButton for each preset */}
                {WEEK_PRESETS.map((preset) => (
                    <DateInputButton
                        key={preset.label}
                        label={preset.label}
                        active={
                            props.value.length === 1 &&
                            preset.value === props.value[0] &&
                            !customActive
                        }
                        onClick={() => {
                            // Tracks the analytics event
                            trackClickWeekInputButton(preset.label);

                            // Sets state.customActive to false
                            setCustomActive(false);

                            // Applies preset.value
                            props.setValue([preset.value]);
                        }}
                    />
                ))}

                {/* Render a DateInputButton for "Custom" range */}
                <DateInputButton
                    label="Custom"
                    // className="mt-10-px"
                    active={customActive}
                    onClick={() => {
                        // Short-circuit if "Custom" is active
                        if (customActive) {
                            return;
                        }

                        // Tracks the analytics event
                        trackClickWeekInputButton("Custom");

                        // Sets state.customActive to true
                        setCustomActive(true);
                    }}
                />
            </div>

            {/* Renders "Current Week" message */}
            {currentWeekActive && (
                <div className="d-flex flex-column w-100 mx-10-px">
                    <p className="font-size-14-px text-primary font-secondary-bold my-10-px">
                        Note - the selected week is active through{" "}
                        {currentWeekDisplay}
                    </p>
                    <p className="font-size-14-px my-10-px">
                        Select the{" "}
                        <span className="font-secondary-bold">
                            Last full week
                        </span>{" "}
                        preset for data with a complete range.
                    </p>
                </div>
            )}

            {/* Renders CustomDateRangeInput */}
            {customActive && (
                <CustomWeekRangeInput
                    value={props.value}
                    onChange={props.setValue}
                />
            )}
        </React.Fragment>
    );
}

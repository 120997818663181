import {
    trackCreateAdvancedFilter,
    trackEditAdvancedFilter,
    trackRemoveAdvancedFilter,
    trackUpdateAdvancedFilter,
} from "@src/analytics/advanced_filters";
import { captureMessage } from "@src/util/analytics";
import uniqueId from "lodash.uniqueid";
import {
    AdvancedFilterEditorAction,
    AdvancedFilterEditorActionTypes,
    AdvancedFilterEditorDefaultAction,
    AdvancedFilterEditorEditFilterAction,
    AdvancedFilterEditorReorderAction,
    AdvancedFilterEditorSetFiltersAction,
} from "./actions";
import {
    AdvancedFilterInput,
    EditorAdvancedFilterInput,
    EditorFilter,
    EditorFiltersInput,
    Filter,
    FilterExpression,
    FiltersInput,
} from "./graphql";

// // // //

/**
 * blankFilter
 * An empty `Filter` used as a base to create new instances
 */
export const blankFilter: EditorFilter = {
    id: "",
    source: "",
    val: "",
    expression: FilterExpression.empty,
    fieldName: "",
    filters: [],
};

/**
 * AdvancedFilterEditorState
 * The state managed by the `advancedFilterEditorReducer`
 * `editingFilter` - the `Filter` instance currently being edited (nullable)
 * `destinationFilter` - the `Filter` instance, whose `filters[]` inside of which a _new_ `Filter` will be encapsulated if `destinationFilter` is defined
 * `wrapNewFilterInOr` - a flag indicating that a newly-created `Filter` should be wrapped in another `Filter` instance whose `expression` is `FilterExpression.or`
 * `wrapNewFilterInAnd` - a flag indicating that a newly-created `Filter` should be wrapped in another `Filter` instance whose `expression` is `FilterExpression.and`
 * `query` - the `FiltersInput` instance being modled by the reducer
 */
export interface AdvancedFilterEditorState {
    editingFilter: EditorFilter | null;
    destinationFilter: EditorFilter | null;
    wrapNewFilterInOr: boolean;
    wrapNewFilterInAnd: boolean;
    query: EditorFiltersInput;
}

// // // //

/**
 * removeEmptyFilters
 * Removes Filter instances where (expression = and|or) AND (filters.length == 0)
 * @param filters - the Array of `Filter` instances to be processed
 * @returns - returns `Filter[]` without empty instances
 */
export function removeEmptyFilters(filters: EditorFilter[]): EditorFilter[] {
    return filters
        .filter((f: EditorFilter) => {
            return !(
                [FilterExpression.and, FilterExpression.or].includes(
                    f.expression
                ) && f.filters.length === 0
            );
        })
        .map((f: EditorFilter) => {
            return {
                ...f,
                filters: removeEmptyFilters(f.filters),
            };
        });
}

// // // //

/**
 * sanitizeFilters
 * Sanitizes a **sorted** array of Filters to ensure that the leading entry (filters[0]) is not an and/or expression
 * This is done because at the moment the UI doesn't have a way to gracefully accommodate working with _only_ and/or filters
 * @param filters - sorted Array of `Filter` instances to be processed
 * @returns - returns `Filter[]`
 */
export function sanitizeFilters(filters: EditorFilter[]): EditorFilter[] {
    // Returns empty array of filters.length === 0
    if (filters.length === 0) {
        return [];
    }

    // If the first filter in the sorted array has expression and/or, we clear out all the filters
    if (isAndOrExpression(filters[0])) {
        return [];
    }

    // Return the array of filters
    return filters;
}

// // // //

/**
 * Generates a unique ID for a single filter object
 * NOTE: this is required to build the UI in scenarios where React requires
 * unique `key` props while mapping an array inside a render function.
 * The IDs are also used by the `react-beautiful-dnd` library to help manage drag & drop logic
 */
export function getUniqueId(): string {
    return uniqueId("FILTER_");
}

// // // //

/**
 * Recursively locates an individual filter object by its unique ID
 * Used in reducer stages where a child filter needs to update its parent filter
 */
export function findFilterByIdRecursive(
    filters: EditorFilter[],
    id: string
): EditorFilter | null {
    // Stores the recursively found filter object
    let foundRecursive: EditorFilter | null;

    // Loops over each filter
    for (const filter of filters) {
        // Returns filter if the matching filter is found
        if (filter.id === id) {
            return filter;
        }

        // Invokes the function recursively on the nested filters on filter
        foundRecursive = findFilterByIdRecursive(filter.filters, id);

        // If the result of the recursive call is not null, we know
        // the filter has been located and should be returned
        if (foundRecursive !== null) {
            return foundRecursive;
        }
    }

    // Returns null if the desired filter isn't located in the `filters` array parameter
    return null;
}

// // // //

/**
 * Recursively locates an individual filter object by its unique ID inside the `FiltersInput` object
 * Used in reducer stages where a child filter needs to update its parent filter
 */
export function findFilterInFiltersInput(
    filtersInput: EditorFiltersInput,
    id: string
): EditorFilter | null {
    // Stores the recursively found filter object
    let foundRecursive: EditorFilter | null;

    foundRecursive = findFilterByIdRecursive(filtersInput.defaults, id);

    if (foundRecursive) {
        return foundRecursive;
    }

    // Loops over each filter
    for (const afi of filtersInput.advanced) {
        // Invokes the function recursively on the nested filters on filter
        foundRecursive = findFilterByIdRecursive(afi.filters, id);

        // If the result of the recursive call is not null, we know
        // the filter has been located and should be returned
        if (foundRecursive !== null) {
            return foundRecursive;
        }
    }

    // Returns null if the desired filter isn't located in the `filters` array parameter
    return null;
}

// // // //

/**
 * Finds an individal Filter instance inside a nested advanced filters object.
 * This is used by the `advancedFilterEditorReducer` to update a specific filter when an update is detected
 * @param filters
 * @param replacementFilter
 */
export function replaceFilterByIdRecursive(
    filters: EditorFilter[],
    replacementFilter: EditorFilter
): EditorFilter[] {
    return filters.map((filter) => {
        if (filter.id === replacementFilter.id) {
            return { ...replacementFilter };
        }
        return {
            ...filter,
            filters: [
                ...replaceFilterByIdRecursive(
                    filter.filters,
                    replacementFilter
                ),
            ],
        };
    });
}

// // // //

/**
 * Used by the `advancedFilterEditorReducer` to remove an individal Filter instance inside a nested advanced filters object
 * @param filters
 * @param removedFilter
 */
export function removeFilterByIdRecursive(
    filters: EditorFilter[],
    removedFilter: EditorFilter
): EditorFilter[] {
    return filters
        .filter((f) => f.id !== removedFilter.id)
        .map((f) => {
            return {
                ...f,
                filters: [
                    ...removeFilterByIdRecursive(f.filters, removedFilter),
                ],
            };
        });
}

// // // //

/**
 * Recursively clones an array of filters and returns the deep-copy.
 * NOTE - we re-define the `id` attribute here to prevent behavior inconsistencies that
 * crop up with the `react-beautiful-dnd` library that occur when recursive drag-and-dropable
 * components update. Specifically, certain nested components stop being dragable - this appears
 * to be an issue resulting from the nested components not fully re-rendering because their
 * props haven't changed, even though their parent `<Droppable />` wrapper has been re-rendered.
 * The re-assignment of fresh, unique `id` attributes also resolves an issue with `react-beautiful-dnd`
 * where recursively nested drag-and-dropable components do not always reliably propagate
 * state change in their ordering in the UI - i.e. the nested filter ordering may be correct
 * in `state.filters`, but the UI doesn't match the ordering state without re-assigning fresh id attributes.
 * @param filters
 */
export function cloneFilters(filters: EditorFilter[]): EditorFilter[] {
    return filters.map((f) => {
        const clonsedFilter: EditorFilter = {
            ...f,
            id: getUniqueId(),
            filters: cloneFilters(f.filters),
        };
        return clonsedFilter;
    });
}

// // // //

// Helper functions for sortFiltersRecursive
export function isAndOrExpression<T extends Filter>(f: T): boolean {
    return [FilterExpression.and, FilterExpression.or].includes(f.expression);
}

function isAndExpression<T extends Filter>(f: T): boolean {
    return FilterExpression.and === f.expression;
}

function isOrExpression<T extends Filter>(f: T): boolean {
    return FilterExpression.or === f.expression;
}

/**
 * sortFiltersRecursive
 * Sorts a EditorFilter[] instance recursively, returns result
 * @param filters - Array<EditorFilter> to be sorted
 */
export function sortFiltersRecursive<T extends Filter>(filters: T[]): T[] {
    // Sorts using array sort function
    // If the result is negative, a is sorted before b.
    // If the result is positive, b is sorted before a.
    // If the result is 0, no change in sort of the two values
    return filters
        .sort((a: T, b: T): number => {
            // If expressions are equal, return 0
            if (a.expression === b.expression) {
                return 0;
            }

            // Return original sort, if neither A not B are AND/OR
            if (!isAndOrExpression(b) && !isAndOrExpression(a)) {
                return 0;
            }

            // Return B before A, if A is OR and B is AND
            if (isOrExpression(a) && isAndExpression(b)) {
                return 1;
            }

            // Return A before B, if A is AND and B is OR
            if (isOrExpression(b) && isAndExpression(a)) {
                return -1;
            }

            // Return B before A, if A is AND/OR and B is not
            if (isAndOrExpression(a) && !isAndOrExpression(b)) {
                return 1;
            }

            // Return A before B, if A is not AND/OR and B is
            if (!isAndOrExpression(a) && isAndOrExpression(b)) {
                return -1;
            }

            // Should anything not be caught by the above, return 0
            return 0;
        })
        .map((f) => {
            return {
                ...f,
                filters: sortFiltersRecursive(f.filters),
            };
        });
}

/**
 * convertFilterToEditorFilter
 * Converts a `Filter` instance to an `EditorFilter` instance so the data can be consumed by the AdvancedFiltersEditor
 * @param filter - the `Filter` instance we're converting
 * @param source - the value for the `source` property on the `Filter` type ("defaults" or AppliedAdvancedFilter.key)
 */
export function convertFilterToEditorFilter(
    filter: Filter,
    source: string
): EditorFilter {
    return {
        id: getUniqueId(),
        source,
        fieldName: filter.fieldName,
        expression: filter.expression,
        val: filter.val,
        filters: filter.filters.map(
            (f: Filter): EditorFilter => convertFilterToEditorFilter(f, source)
        ),
    };
}

/**
 * convertEditorFilterToFilter
 * Converts an `EditorFilter` instance BACK to a standard `Filter` instance
 * This is done when propagating updated FiltersInput data through AdvancedFiltersEditor.props.onChange(updatedFiltersInput) => void
 * @param editorFilter - the `EditorFilter` instance we're converting
 */
export function convertEditorFilterToFilter(
    editorFilter: EditorFilter
): Filter {
    return {
        fieldName: editorFilter.fieldName,
        expression: editorFilter.expression,
        val: editorFilter.val,
        filters: editorFilter.filters.map(
            (ef: EditorFilter): Filter => convertEditorFilterToFilter(ef)
        ),
    };
}

/**
 * Provides the initial state for the advancedFilterEditorReducer.
 * We perform a deep-copy on the filters passed into the AdvancedFilterEditor to
 * ensure that no object references exist to data that should not be mutated indirectly
 * @param query
 */
export function getInitialState(
    query: FiltersInput
): AdvancedFilterEditorState {
    // Parses FiltersInput `{ defaults: Filter[] }` into `EditorFilter[]`
    const defaultFilters: EditorFilter[] = query.defaults.map(
        (f: Filter): EditorFilter => {
            return convertFilterToEditorFilter(f, "defaults");
        }
    );

    // Parses FiltersInput `{ advanced: AdvancedFilterInput[] }` into `EditorAdvancedFilterInput[]`
    const advancedFilters: EditorAdvancedFilterInput[] = query.advanced.map(
        (af: AdvancedFilterInput): EditorAdvancedFilterInput => {
            return {
                key: af.key,
                filters: af.filters.map(
                    (f: Filter): EditorFilter => {
                        return convertFilterToEditorFilter(f, af.key);
                    }
                ),
            };
        }
    );

    // Returns the initial state
    return {
        editingFilter: null,
        destinationFilter: null,
        wrapNewFilterInOr: false,
        wrapNewFilterInAnd: false,
        query: {
            ...query,
            defaults: sanitizeFilters(
                sortFiltersRecursive(cloneFilters(defaultFilters))
            ),
            advanced: advancedFilters.map((afi: EditorAdvancedFilterInput) => {
                return {
                    ...afi,
                    filters: sanitizeFilters(
                        sortFiltersRecursive(cloneFilters(afi.filters))
                    ),
                };
            }),
        },
    };
}

// // // //

/**
 * Adds an individual filter - invoked by the ADD_FILTER AdvancedFilterEditorDefaultAction
 * @param state
 * @param action
 */
function addFilter(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorDefaultAction
): AdvancedFilterEditorState {
    // Tracks the analytics event
    trackCreateAdvancedFilter(
        action.filter.expression,
        action.filter.fieldName,
        action.filter.val
    );

    // Defines the new filter we're creating
    let newFilter: EditorFilter = {
        ...action.filter,
        id: getUniqueId(),
    };

    // Handles state.wrapNewFilterInAnd
    if (state.wrapNewFilterInOr) {
        newFilter = {
            ...blankFilter,
            id: getUniqueId(),
            expression: FilterExpression.or,
            filters: [
                {
                    ...newFilter,
                },
            ],
        };
    }

    // Handles state.wrapNewFilterInAnd
    if (state.wrapNewFilterInAnd) {
        newFilter = {
            ...blankFilter,
            id: getUniqueId(),
            expression: FilterExpression.and,
            filters: [{ ...newFilter }],
        };
    }

    // Handles state.destinationFilter
    if (state.destinationFilter !== null) {
        // Defines newFilter using state.destinationFilter
        newFilter = {
            ...state.destinationFilter,
            filters: [...state.destinationFilter.filters, newFilter],
        };

        // Because state.destinationFilter already exists in our state, we
        // replace that filter with newFilter and return that updated state
        return {
            ...state,
            wrapNewFilterInOr: false,
            wrapNewFilterInAnd: false,
            editingFilter: null,
            destinationFilter: null,
            query: {
                ...state.query,
                defaults: sanitizeFilters(
                    sortFiltersRecursive(
                        replaceFilterByIdRecursive(
                            state.query.defaults,
                            newFilter
                        )
                    )
                ),
                advanced: state.query.advanced.map(
                    (af: EditorAdvancedFilterInput) => {
                        return {
                            ...af,
                            filters: sanitizeFilters(
                                sortFiltersRecursive(
                                    replaceFilterByIdRecursive(
                                        af.filters,
                                        newFilter
                                    )
                                )
                            ),
                        };
                    }
                ),
            },
        };
    }

    // // // //

    // Finds the source for the new filters
    const updatedQuery: EditorFiltersInput = {
        ...state.query,
    };

    // If the newFilter belongs in state.query.defaults, we append it to that array of Filters[] and sort the result
    if (action.filter.source === "defaults") {
        updatedQuery.defaults = sortFiltersRecursive([
            ...state.query.defaults,
            newFilter,
        ]);

        // Else, we know that the newFilter belongs in one of the `query.advanced` AdvancedFilterInput objects
    } else {
        // Finds the AdvancedFilterInput associated with action.filter.source
        let destinationAdvancedFilterInput:
            | EditorAdvancedFilterInput
            | undefined = state.query.advanced.find(
            (af: EditorAdvancedFilterInput) => af.key === action.filter.source
        );

        // If the `AdvancedFilterInput` associated with action.filter.source is not found,
        // this indicates that `state.query` does not yet include an `AdvancedFilterInput` instance
        // for that particular source, so we create it and add it to query.advanced
        if (destinationAdvancedFilterInput === undefined) {
            // Defines new `AdvancedFilterInput` instance with empty filters array
            destinationAdvancedFilterInput = {
                key: action.filter.source,
                filters: [],
            };

            // Appends new `AdvancedFilterInput` object to to query.advanced
            updatedQuery.advanced.push(destinationAdvancedFilterInput);
        }

        // Appends the new Filter to `destinationAdvancedFilterInput.filters`
        destinationAdvancedFilterInput.filters.push(newFilter);
        destinationAdvancedFilterInput.filters = sanitizeFilters(
            sortFiltersRecursive(destinationAdvancedFilterInput.filters)
        );

        // Next we find that particular `AdvancedFilterInput` in query.advanced and append the newFilter to the its `filters[]` array
        updatedQuery.advanced = updatedQuery.advanced.map(
            (af: EditorAdvancedFilterInput) => {
                if (af.key === destinationAdvancedFilterInput.key) {
                    return destinationAdvancedFilterInput;
                }
                return af;
            }
        );
    }

    // Returns state with updatedQuery
    return {
        ...state,
        wrapNewFilterInOr: false,
        wrapNewFilterInAnd: false,
        editingFilter: null,
        destinationFilter: null,
        query: updatedQuery,
    };
}

// // // //

/**
 * Edit an individual filter - invoked by the EDIT_FILTER AdvancedFilterEditorDefaultAction
 * @param state
 * @param action
 */
function editFilter(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorEditFilterAction
): AdvancedFilterEditorState {
    // Tracks the analytics event
    trackEditAdvancedFilter(
        action.filter.expression,
        action.filter.fieldName,
        action.filter.val
    );

    // Pulls wrapNewFilterInAnd & wrapNewFilterInOr from action, assigns default value of `false` to both
    // NOTE - wrapNewFilterInOr is declared using `let` because we may need to reassign a different value below
    const { wrapNewFilterInAnd = false } = action;
    let { wrapNewFilterInOr = false } = action;

    // If action.destinationFilter is defined, we set state.destinationFilter
    if (action.destinationFilter) {
        // Redefine wrapNewFilterInOr - this is done to ensure we don't nest OR statements
        // For example, if `destinationFilter` is already an OR statement => we set `wrapNewFilterInOr` to `false`
        wrapNewFilterInOr =
            wrapNewFilterInOr &&
            action.destinationFilter.expression !== FilterExpression.or;
    }

    // Update the state with the updated wrapNewFilterInAnd, wrapNewFilterInOr, and destinationFilter
    return {
        ...state,
        wrapNewFilterInAnd,
        wrapNewFilterInOr,
        destinationFilter: action.destinationFilter || null,
        editingFilter: {
            ...action.filter,
        },
    };
}

// // // //

/**
 * Stop editing a filter - invoked by the CLEAR_EDITOR AdvancedFilterEditorDefaultAction
 * @param state
 * @param action
 */
function clearEditor(
    state: AdvancedFilterEditorState
): AdvancedFilterEditorState {
    return {
        ...state,
        wrapNewFilterInOr: false,
        editingFilter: null,
    };
}

// // // //

/**
 * Removes an individual filter and it's associated nested filters - invoked by the REMOVE_FILTER AdvancedFilterEditorDefaultAction
 * @param state
 * @param action
 */
function removeFilter(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorDefaultAction
): AdvancedFilterEditorState {
    // Tracks the analytics event
    trackRemoveAdvancedFilter(
        action.filter.expression,
        action.filter.fieldName,
        action.filter.val
    );

    return {
        ...state,
        query: {
            ...state.query,
            defaults: sanitizeFilters(
                sortFiltersRecursive(
                    removeEmptyFilters(
                        removeFilterByIdRecursive(
                            state.query.defaults,
                            action.filter
                        )
                    )
                )
            ),
            advanced: state.query.advanced
                .map((af: EditorAdvancedFilterInput) => {
                    return {
                        ...af,
                        filters: sanitizeFilters(
                            sortFiltersRecursive(
                                removeEmptyFilters(
                                    removeFilterByIdRecursive(
                                        af.filters,
                                        action.filter
                                    )
                                )
                            )
                        ),
                    };
                })
                // Remove any AdvancedFilterInput instances with an empty filters array
                .filter((af: EditorAdvancedFilterInput) => {
                    return af.filters.length > 0;
                }),
        },
    };
}

// // // //

/**
 * Updates an individual filter - invoked by the UPDATE_FILTER AdvancedFilterEditorDefaultAction
 * NOTE - thus must be used to update a filter recursively
 * @param state
 * @param action
 */
function updateFilter(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorDefaultAction
): AdvancedFilterEditorState {
    // Tracks the analytics event
    trackUpdateAdvancedFilter(
        action.filter.expression,
        action.filter.fieldName,
        action.filter.val
    );

    return {
        ...state,
        editingFilter: null,
        destinationFilter: null,
        wrapNewFilterInOr: false,
        wrapNewFilterInAnd: false,
        query: {
            ...state.query,
            defaults: removeEmptyFilters(
                replaceFilterByIdRecursive(state.query.defaults, action.filter)
            ),
            advanced: state.query.advanced.map(
                (af: EditorAdvancedFilterInput) => {
                    return {
                        ...af,
                        filters: removeEmptyFilters(
                            replaceFilterByIdRecursive(
                                af.filters,
                                action.filter
                            )
                        ),
                    };
                }
            ),
        },
    };
}

// // // //

/**
 * reorder
 * Reorders a Filter[] array
 */
function reorder(
    filters: EditorFilter[],
    startIndex: number,
    endIndex: number
) {
    // Creates a copy of the `list` parameter - TS treats the `list` param as an immutable array,
    // so instead we make a copy called `ordered` and perform our operations on that
    const ordered: EditorFilter[] = Array.from(filters);
    const [removed] = ordered.splice(startIndex, 1);
    ordered.splice(endIndex, 0, removed);
    return ordered;
}

/**
 * Reorders filters - invoked by the REORDER_FILTERS AdvancedFilterEditorReorderAction
 * NOTE: we invoke `cloneFilters` here to address an issue with nested drag-and-drop behavior with
 * react-beautiful-dnd. See the `cloneFilters` annotations above for more details.
 * @param state
 * @param action
 */
function reorderFilters(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorReorderAction
): AdvancedFilterEditorState {
    // Finds the Filter[] array that needs re-ordering
    let destinationFilter: EditorFilter | null;

    // Defines a flag indicating whether or not the `action.destinationFilterId` corresponds
    // to one of the `AdvancedFilterInput` instances in `state.query.advanced`
    const isadvancedFilter: boolean = state.query.advanced
        .map((afi: AdvancedFilterInput) => afi.key)
        .includes(action.destinationFilterId);

    // First, check if destinationFilter is referencing a top-level Filters[] array in FiltersInput.defaults or FiltersInput.advanced
    if (action.destinationFilterId === "defaults") {
        return {
            ...state,
            query: {
                ...state.query,
                defaults: sortFiltersRecursive(
                    reorder(
                        state.query.defaults,
                        action.startIndex,
                        action.endIndex
                    )
                ),
            },
        };

        // If the destination references the key of one of the AdvancedFilterInput objects, find the AdvancedFilterInput and reorder its filters
    }
    if (isadvancedFilter) {
        return {
            ...state,
            query: {
                ...state.query,
                advanced: state.query.advanced.map(
                    (afi: EditorAdvancedFilterInput) => {
                        if (action.destinationFilterId === afi.key) {
                            return {
                                ...afi,
                                filters: sortFiltersRecursive(
                                    reorder(
                                        afi.filters,
                                        action.startIndex,
                                        action.endIndex
                                    )
                                ),
                            };
                        }
                        return afi;
                    }
                ),
            },
        };
    }

    // Next, we check if the `action.destinationFilterId` corresponds to a filter whose `filters: Filter[]` array was re-ordered
    // At this point we know that we're re-ordering an array of `Filter` instances nested within `defaults` or `advanced`
    destinationFilter = findFilterInFiltersInput(
        state.query,
        action.destinationFilterId
    );

    // If the updatedFilter is _still_ null, short-circuit the function execution
    // because something is wrong - return original state parameter.
    // This SHOULD NOT happen, so we also throw an exception
    if (destinationFilter === null) {
        captureMessage(
            "No destinationFilter found in `reorderFilters` function in WorkflowEditor reducer"
        );
        return state;
    }

    // Defines updatedFilter with reordered nested filters
    const updatedFilter: EditorFilter = {
        ...destinationFilter,
        filters: sortFiltersRecursive(
            reorder(
                destinationFilter.filters,
                action.startIndex,
                action.endIndex
            )
        ),
    };

    // Replaces filter in `state` with updatedFilter
    const updatedQuery: EditorFiltersInput = {
        ...state.query,
        defaults: cloneFilters(
            replaceFilterByIdRecursive(state.query.defaults, updatedFilter)
        ),
        advanced: state.query.advanced.map((afi: EditorAdvancedFilterInput) => {
            return {
                ...afi,
                filters: cloneFilters(
                    replaceFilterByIdRecursive(afi.filters, updatedFilter)
                ),
            };
        }),
    };

    // Returns the updated state
    // NOTE - we invoke `cloneFilters` here to re-assign unique ID attributes
    // to each filter so `react-beautiful-dnd` recognizes a re-order event in nested lists
    return {
        ...state,
        query: updatedQuery,
    };
}

// // // //

/**
 * Sets filters input - invoked by the SET AdvancedFilterEditorReorderAction
 * NOTE: we invoke `cloneFilters` here to address an issue with nested drag-and-drop behavior with
 * react-beautiful-dnd. See the `cloneFilters` annotations above for more details.
 * @param state
 * @param action
 */
function setFilters(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorSetFiltersAction
): AdvancedFilterEditorState {
    return {
        ...state,
        query: action.editorFiltersInput,
    };
}

// // // //

/**
 * Reducer for managing advanced filters.
 * Handles adding filters, removing filters, updating a single filter, and re-ordering filters
 * @param state
 * @param action
 */
export function advancedFilterEditorReducer(
    state: AdvancedFilterEditorState,
    action: AdvancedFilterEditorAction
): AdvancedFilterEditorState {
    switch (action.type) {
        case AdvancedFilterEditorActionTypes.addFilter:
            return addFilter(state, action);
        case AdvancedFilterEditorActionTypes.editFilter:
            return editFilter(state, action);
        case AdvancedFilterEditorActionTypes.clearEditor:
            return clearEditor(state);
        case AdvancedFilterEditorActionTypes.removeFilter:
            return removeFilter(state, action);
        case AdvancedFilterEditorActionTypes.updateFilter:
            return updateFilter(state, action);
        case AdvancedFilterEditorActionTypes.reorderFilters:
            return reorderFilters(state, action);
        case AdvancedFilterEditorActionTypes.setFilters:
            return setFilters(state, action);
        default:
            throw new Error(
                `Invalid action passed to advancedFilterEditorReducer`
            );
    }
}

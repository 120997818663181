import { routes, terminatedUsersReportIndividual } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState, GetBreadcrumbState } from "../types";

// // // //

// Used for all workflow routes that do not invoke a resolver
// These will all be active since they have no sub routes
function buildActiveWorkflowState(label: string): GetBreadcrumbState {
    return (_route: RouteState) => ({
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label },
        },
    });
}

export const workflowListState = (route: RouteState): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.workflowList,
                query: {},
            },
            as: routes.workflowList,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "Workflow List",
            },
        },
        enabled: true,
        active: route.nextPathname === routes.workflowList,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Reports" },
        },
    };
};

export const workflowNewState = buildActiveWorkflowState("New");

export const workflowDestinationsState = buildActiveWorkflowState(
    "Destinations"
);

export const suggestedWorkflowsState = buildActiveWorkflowState("Suggested");

export const workflowIndState = (route: RouteState): BreadcrumbState => {
    const { workflowID } = route.pathParams;
    return {
        enabled: true,
        active: route.nextPathname === routes.workflowEdit,
        resolverProps: {
            type: DisplayResolverTypes.workflow,
            params: { workflowID },
        },
    };
};

export const workflowEditState = buildActiveWorkflowState("Edit");

export const terminatedUserReportListState = (
    route: RouteState
): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.terminatedUsersReportList,
                query: {},
            },
            as: routes.terminatedUsersReportList,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: { breadcrumb: "terminatedUsersReportList" },
        },
        enabled: true,
        active: route.nextPathname === routes.terminatedUsersReportList,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Terminated Users" },
        },
    };
};

export const terminatedUserReportNewState = buildActiveWorkflowState("New");

export const terminatedUserReportIndividualState = (
    route: RouteState
): BreadcrumbState => {
    const { reportID } = route.pathParams;
    return {
        linkProps: {
            href: {
                pathname: routes.terminatedUsersReportIndividual,
                query: {
                    reportID,
                },
            },
            as: terminatedUsersReportIndividual(reportID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: { breadcrumb: "terminatedUsersReportIndividual" },
        },
        enabled: true,
        active: route.nextPathname === routes.terminatedUsersReportIndividual,
        resolverProps: {
            type: DisplayResolverTypes.report,
            params: { reportID },
        },
    };
};

export const terminatedUserReportIndividualConfigureState = (
    route: RouteState
): BreadcrumbState => {
    return {
        enabled: true,
        active:
            route.nextPathname ===
            routes.terminatedUsersReportIndividualConfigure,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Configure" },
        },
    };
};

export const terminatedUsersReportBucketIndividualState = (
    route: RouteState
): BreadcrumbState => {
    const { reportID, appInfoID } = route.pathParams;
    return {
        enabled: true,
        active:
            route.nextPathname === routes.terminatedUsersReportBucketIndividual,
        resolverProps: {
            type: DisplayResolverTypes.userReportResultBucket,
            params: { reportID, appInfoID },
        },
    };
};

import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { GetBreadcrumbState } from "../types";

// // // //

export const usageIndividualApplicationsState: GetBreadcrumbState = (
    _route: RouteState
) => ({
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Users" },
    },
});

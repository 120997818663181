import { track } from "@src/analytics/track";
import { AppEvents } from "./events";

interface StateProps {
    application_id: number;
    old_state: string;
    new_state: string;
}

export function trackStateUpdate(
    appID: number,
    oldState: string,
    nextState: string
) {
    const props: StateProps = {
        application_id: appID,
        old_state: oldState,
        new_state: nextState,
    };
    track(AppEvents.stateUpdated, props);
}

// we have to import from `/graphql` here because otherwise there is a circular dependency issue
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import gql from "graphql-tag";

export interface Vendor {
    id: number;
    category: string;
    description: null | string;
    domain: string;
    gdprLink: null | string;
    logo: string;
    name: string;
    nameAliases: string[];
    pricingLink: null | string;
    privacyLink: null | string;
    state: string;
    tldVendor: boolean;
    tosLink: null | string;
    verified: boolean;
    children?: Vendor[] | null;
    siblings?: Vendor[] | null;
    parent?: Vendor | null;
}

export interface VendorFetcherVariables {
    filters: FiltersInput;
}

export interface VendorFetcherResponse {
    vendors: {
        listVendors: {
            list: {
                results: Vendor[];
            };
        };
    };
}

/**
 * VENDOR_FIELDS_FRAGMENT
 * GQL Fragment used to fetch the base fields for a single Vendor
 */
export const VENDOR_FIELDS_FRAGMENT = gql`
    fragment vendorFields on Vendor {
        id
        name
        logo
        domain
        category
        description
        state
        tldVendor
        verified
        nameAliases
        privacyLink
        tosLink
        pricingLink
        gdprLink
    }
`;

/**
 * VENDOR_FETCHER_SINGLE_QUERY
 * Used to fetch a single Vendor WITHOUT it's parent + siblings + children Vendors
 */
export const VENDOR_FETCHER_SINGLE_QUERY = gql`
    query VENDOR_FETCHER_SINGLE_QUERY($filters: FiltersInput!) {
        vendors {
            listVendors {
                list(filters: $filters) {
                    results {
                        ...vendorFields
                    }
                }
            }
        }
    }
    ${VENDOR_FIELDS_FRAGMENT}
`;

/**
 * VENDOR_FETCHER_RELATED_QUERY
 * Used to fetch a single Vendor + it's parent + siblings + children Vendors
 */
export const VENDOR_FETCHER_RELATED_QUERY = gql`
    query VENDOR_FETCHER_RELATED_QUERY($filters: FiltersInput!) {
        vendors {
            listVendors {
                list(filters: $filters) {
                    results {
                        ...vendorFields
                        parent {
                            ...vendorFields
                        }
                        children {
                            ...vendorFields
                        }
                        siblings {
                            ...vendorFields
                        }
                    }
                }
            }
        }
    }
    ${VENDOR_FIELDS_FRAGMENT}
`;

/**
 * buildVendorFetcherVariables
 * Builds VendorFetcherVariables for VENDOR_FETCHER_*_QUERY
 * @param props.vendorID - the ID of the Vendor we're querying for
 * @param props.organizationID - value from UserState.organizationID
 */
export function buildVendorFetcherVariables(props: {
    vendorID: number;
    organizationID: number;
}): VendorFetcherVariables {
    const { vendorID, organizationID } = props;

    return {
        filters: {
            defaults: [
                {
                    fieldName: "id",
                    val: vendorID,
                    expression: FilterExpression.eq,
                    filters: [],
                },
            ],
            advanced: [],
            organizationID,
        },
    };
}

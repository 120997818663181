import { Workflow } from "@src/schema/Workflow";
import { UserState, withUser } from "@src/shared_modules/user";
import { WorkflowListFetcher } from "@src/modules/workflows/components/workflow/list_fetcher/component";
import { captureMessage } from "@src/util/analytics";
import React from "react";
import {
    FilterExpression,
    FiltersInput,
} from "../../modules/advanced_filters/components/advanced_filter_editor";
import { ResolverProps } from "./component";

// // // //

// Takes a workflowID and resolves a workflow to props.children or–if !props.children - resolves to the webhook name
export function WorkflowResolverLayout(
    props: ResolverProps<Workflow> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const workflowID: string | undefined = props.params?.workflowID;

    if (workflowID === undefined) {
        captureMessage("WorkflowResolver - params.workflowID is undefined");
        return null;
    }

    const { organizationID } = props.user;

    const filtersInput: FiltersInput = {
        organizationID,
        advanced: [],
        defaults: [
            {
                fieldName: "id",
                expression: FilterExpression.eq,
                val: workflowID,
                filters: [],
            },
        ],
    };

    return (
        // Fetch a single workflow by workflow id
        <WorkflowListFetcher filters={filtersInput}>
            {({ loading, workflows }) => {
                const workflow: Workflow | undefined = workflows[0];

                if (props.children) {
                    return props.children({ value: workflow, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (workflow === undefined) {
                    captureMessage(`Workflow - ${workflowID} not found`);
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return <React.Fragment>{workflow.name}</React.Fragment>;
            }}
        </WorkflowListFetcher>
    );
}

export const WorkflowResolver: React.ComponentType<ResolverProps<
    Workflow
>> = withUser(WorkflowResolverLayout);

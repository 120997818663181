import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { useGraphQLErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    buildUserDashboardSettingsMutationInput,
    USER_DASHBOARD_SETTINGS_MUTATION,
    UserDashboardSettingsMutationInput,
    UserDashboardSettingsMutationResponse,
} from "./graphql";
import { UserDashboardSettingValue } from "./types";

// // // //

/**
 * MutationResponse
 * The response returned by the Apollo Mutation Component
 */
interface MutationResponse {
    data: UserDashboardSettingsMutationResponse | undefined;
}

/**
 * SaveUserDashboardSettingsProps
 * Parameters for the saveUserDashboardSettings function provided by the UserDashboardSettingsMutation component to props.children
 */
interface SaveUserDashboardSettingsProps {
    componentName: string;
    value: UserDashboardSettingValue;
}

/**
 * UserDashboardSettingsMutationLayoutProps
 * @param successMessage - (optional) A message that's displayed when the mutation resolves successfully
 * @param children - A function that:
 *          - accepts `saveUserDashboardSettings` -> a helper function to fire off the UserDashboardSettings mutation
 *          - accepts `savingUserDashboardSettings` -> the loading state for the UserDashboardSettings mutation
 *          - returns -> React.ReactNode
 */
interface UserDashboardSettingsMutationLayoutProps {
    successMessage?: string;
    children: (props: {
        savingUserDashboardSettings: boolean;
        saveUserDashboardSettings: (
            props: SaveUserDashboardSettingsProps
        ) => Promise<MutationResponse>;
    }) => React.ReactNode;
}

/**
 * UserDashboardSettingsMutation
 * Wires up the UserDashboardSettings mutation and exposes the loading state and a helper function to `props.children`
 * @param props - see UserDashboardSettingsMutationProps
 */
export function UserDashboardSettingsMutationLayout(
    props: UserDashboardSettingsMutationLayoutProps & WithToasterProps
) {
    const [setError, ErrorComponent] = useGraphQLErrorHandler();

    return (
        <React.Fragment>
            {/* Renders ErrorComponent from useGraphQLErrorHandler */}
            <ErrorComponent />

            {/* Renders <Mutation /> and props.children */}
            <Mutation<
                UserDashboardSettingsMutationResponse,
                UserDashboardSettingsMutationInput
            >
                mutation={USER_DASHBOARD_SETTINGS_MUTATION}
            >
                {(commitMutation, { loading }) => {
                    // Defines function to assist invoking commitMutation
                    function saveUserDashboardSettings({
                        componentName,
                        value,
                    }: SaveUserDashboardSettingsProps): Promise<
                        MutationResponse
                    > {
                        // Returns Promise<MutationResponse> so downstream components can await the resolution of the mutation
                        return new Promise<MutationResponse>(
                            (resolve, reject) => {
                                // Invokes commitMutation
                                return commitMutation({
                                    variables: buildUserDashboardSettingsMutationInput(
                                        {
                                            componentName,
                                            value,
                                        }
                                    ),
                                })
                                    .then((resp: MutationResponse) => {
                                        // Show success toaster IFF props.successMessage is defined
                                        if (props.successMessage) {
                                            props.addToaster({
                                                type: "success",
                                                text: props.successMessage,
                                            });
                                        }

                                        // Resolve with resp
                                        return resolve(resp);
                                    })
                                    .catch((err) => {
                                        setError(err);
                                        reject(err);
                                    });
                            }
                        );
                    }

                    // Renders props.children with saveUserDashboardSettings + loading state
                    return props.children({
                        saveUserDashboardSettings,
                        savingUserDashboardSettings: loading,
                    });
                }}
            </Mutation>
        </React.Fragment>
    );
}

// // // //

export const UserDashboardSettingsMutation = withToaster(
    UserDashboardSettingsMutationLayout
);

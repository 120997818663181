import { routes } from "@src/routes";
import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const purchaseOrdersState = (route: RouteState) => {
    return {
        linkProps: {
            href: {
                pathname: routes.purchaseOrderListing,
                query: {},
            },
            as: routes.purchaseOrderListing,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "Purchase Order Listing",
            },
        },
        enabled: true,
        active: route.nextPathname === routes.purchaseOrderListing,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Purchase Orders" },
        },
    };
};

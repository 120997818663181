import { UserDashboardSetting } from "@src/modules/app/components/user_dashboard_settings";
import { InMemoryCache } from "apollo-cache-inmemory";
import get from "lodash.get";

// // // //

/**
 * updateCachedUserDashboardSettings
 * Inserts the UserDashboardSetting into the Apollo cache IFF it doesn't exist there
 * If the UserDashboardSetting already exists in the Apollo cache, Apollo will automatically update the value
 * This cache insertion prevents the FE from needing to refetch all the data from AdvancedFiltersProvider
 * @param props.cache - the Apollo InMemoryCache
 * @param props.query - the query we're reading + writing in the cache
 * @param props.variables - the variables paired with props.query
 * @param props.userDashboardSetting - the UserDashboardSetting we're inserting into the cache
 */
export function updateCachedUserDashboardSettings<T1, T2>(props: {
    cache: InMemoryCache;
    query: any;
    variables: T2;
    userDashboardSetting: UserDashboardSetting;
}): void {
    const { cache, query, variables, userDashboardSetting } = props;

    // Read query from Apollo cache
    // Note the use the generic T1 type prop
    const cacheValue: T1 = cache.readQuery<T1, T2>({
        query,
        variables,
    });

    // Pulls userDashboardSettings from cacheValue
    const userDashboardSettings: UserDashboardSetting[] = get(
        cacheValue,
        "userDashboardSettings",
        []
    );

    // Check UserDashboardSettings for instance matching setting.id
    const matchingUserDashboardSetting:
        | UserDashboardSetting
        | undefined = userDashboardSettings.find(
        (s) => s.id === userDashboardSetting.id
    );

    // If matchingUserDashboardSetting exists -> return
    // The Apollo cache will automatically update this data because it's keyed with a unique `id` property
    if (matchingUserDashboardSetting) {
        return;
    }

    // If setting does NOT exist in cache, add to cache and write query
    const updatedCacheValue: T1 = {
        ...cacheValue,
        userDashboardSettings: [...userDashboardSettings, userDashboardSetting],
    };

    // Write updatedCacheValue to Apollo cache
    cache.writeQuery<T1, T2>({
        query,
        variables,
        data: updatedCacheValue,
    });
}

import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import gql from "graphql-tag";
import { DEFAULT_PAGINATION } from "./constants";
import { buildColumnSettingsComponentName } from "./manage_columns/buildColumnSettingsComponentName";

/**
 * AF_FILTERS_FRAGMENT
 * A GraphQL fragment used to query for Filters data from the API
 */
export const AF_FILTERS_FRAGMENT = gql`
    fragment AF_FILTERS_FRAGMENT on Filters {
        defaults {
            key
            content {
                name
                description
            }
            fieldType
            expressions
            defaultExpression
            showInline
            possibleValues {
                internalValue
                humanValue
            }
        }
        advanced {
            key
            content {
                name
                description
            }
            filters {
                key
                content {
                    name
                    description
                }
                fieldType
                expressions
                defaultExpression
                showInline
                possibleValues {
                    internalValue
                    humanValue
                }
            }
        }
    }
`;

/**
 * AF_COLUMNS_FRAGMENT
 * A GraphQL fragment used to query for Columns data from the API
 */
export const AF_COLUMNS_FRAGMENT = gql`
    fragment AF_COLUMNS_FRAGMENT on Columns {
        defaults {
            key
            type
            sortable
            rowDataKeys
            sortedByDefault
            visibleByDefault
            defaultSortDirection
            content {
                name
                description
            }
        }
    }
`;

/**
 * AF_USER_SETTINGS_FRAGMENT
 * A GraphQL fragment used to query for user settings data from the API
 */
export const AF_USER_SETTINGS_FRAGMENT = gql`
    fragment AF_USER_SETTINGS_FRAGMENT on UserDashboardSetting {
        id
        component
        value
    }
`;

/**
 * AF_ORG_SAVED_VIEWS_FRAGMENT
 * A GraphQL fragment used to query OrgSavedView[] data from the API
 * NOTE - ideally the AF_ORG_SAVED_VIEWS_FRAGMENT would implement the AF_APPLIED_FILTERS_FRAGMENT,
 * but `grapqhql-tag`doesn't support nested fragments, so we duplicate the AppliedFilters (see `filters`) query here
 */
export const AF_ORG_SAVED_VIEWS_FRAGMENT = gql`
    fragment AF_ORG_SAVED_VIEWS_FRAGMENT on OrgSavedView {
        id
        organizationID
        name
        description
        queryName
        queryDestination
        state
        attributes
        createdFrom
        vendorID
        tags {
            id
            organizationID
            name
            description
            totalOrgSavedViews
        }
        filters {
            defaults {
                fieldName
                expression
                val
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                            }
                        }
                    }
                }
            }
            advanced {
                key
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                                filters {
                                    fieldName
                                    expression
                                    val
                                }
                            }
                        }
                    }
                }
            }
            pagination {
                page
                itemsPerPage
                sort
                direction
            }
        }
    }
`;

/**
 * AF_QUERY_DETAILS_FRAGMENT
 * A GraphQL fragment used to query for QueryDetails data from the API
 */
export const AF_QUERY_DETAILS_FRAGMENT = gql`
    fragment AF_QUERY_DETAILS_FRAGMENT on QueryDetails {
        defaults {
            key
            value
        }
    }
`;

/**
 * AF_APPLIED_FILTERS_FRAGMENT
 * A GraphQL fragment used to query AppliedFilters data from the API
 */
export const AF_APPLIED_FILTERS_FRAGMENT = gql`
    fragment AF_APPLIED_FILTERS_FRAGMENT on AppliedFilters {
        defaults {
            fieldName
            expression
            val
            filters {
                fieldName
                expression
                val
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                        }
                    }
                }
            }
        }
        advanced {
            key
            filters {
                fieldName
                expression
                val
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                            }
                        }
                    }
                }
            }
        }
        pagination {
            page
            itemsPerPage
            sort
            direction
        }
    }
`;

/**
 * AF_SUGGESTED_FILTERS_FRAGMENT
 * A GraphQL fragment used to query for user settings data from the API
 * NOTE - the AF_SUGGESTED_FILTERS_FRAGMENT _should_ implement the AF_APPLIED_FILTERS_FRAGMENT,
 * but `grapqhql-tag`doesn't support nested fragments, so we repeat the AppliedFilters (see `filters`) query
 */
export const AF_SUGGESTED_FILTERS_FRAGMENT = gql`
    fragment AF_SUGGESTED_FILTERS_FRAGMENT on SuggestedFilter {
        id
        organizationID
        name
        queryName
        queryDestination
        state
        attributes
        content {
            name
            description
        }
        vendorID
        vendor {
            name
            logo
        }
        labels {
            defaults {
                name
                filterCount
                cta {
                    name
                    description
                }
                content {
                    name
                    description
                }
            }
        }
        filters {
            defaults {
                fieldName
                expression
                val
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                            }
                        }
                    }
                }
            }
            advanced {
                key
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                                filters {
                                    fieldName
                                    expression
                                    val
                                }
                            }
                        }
                    }
                }
            }
            pagination {
                page
                itemsPerPage
                sort
                direction
            }
        }
    }
`;

// // // //

// The above fragments may be implemented thusly
// Note how the fragments are interpolated at the bottom of the query
//
// const USAGE_INDIVIDUAL_FILTERS_QUERY = gql`
//     query($args: FilterQueryArgs!) {
//         usage {
//             vendorUsersAdvancedFilters {
//                 filters(args: $args) {
//                     ...AF_FILTERS_FRAGMENT
//                 }
//                 columns(args: $args) {
//                     ...AF_COLUMNS_FRAGMENT
//                 }
//             }
//         }
//         userDashboardSettings(filters: $userSettingsFilters) {
//             ...AF_USER_SETTINGS_FRAGMENT
//         }
//     }
//
//     ${AF_COLUMNS_FRAGMENT}
//     ${AF_FILTERS_FRAGMENT}
//     ${AF_USER_SETTINGS_FRAGMENT}
// `;

// // // //

/**
 * UserDashboardSettingFilters
 * Filters interface used when querying for UserDashboardSettings
 */
export interface UserDashboardSettingFilters {
    component: string[];
}

/**
 * buildUserSettingsFilters
 * Builds the filters used to query for UserDashboardSettings for user's default FiltersInput + ColumnSettings
 * @param params.componentName
 */
export function buildUserSettingsFilters(params: {
    componentName: string;
}): UserDashboardSettingFilters {
    const { componentName } = params;
    return {
        component: [buildColumnSettingsComponentName({ componentName })],
    };
}

// // // //

/**
 * ATTRIBUTES_FIELD_NAME
 * Defines the property we use for `Filter.fieldName` when querying for SuggestedFilters
 */
const ATTRIBUTES_FIELD_NAME: string = "attributes";

/**
 * STATE_FIELD_NAME
 * Defines constant for name of `state` filter when querying for SuggestedFilters
 */
const STATE_FIELD_NAME = "state";

/**
 * buildSuggestedFiltersQueryVariables
 * Builds the FiltersInput used to query for SuggestedFilters + OrgSavedViews
 * @param params.organizationID - value from UserState.organizationID
 * @param params.attributes - attributes used to query for a subset of the available SuggestedFilters
 */
export function buildSuggestedFiltersQueryVariables(params: {
    organizationID: number;
    attributes: string[];
}): FiltersInput {
    const { organizationID, attributes } = params;
    return {
        organizationID,
        defaults: [
            {
                val: [...attributes],
                expression: FilterExpression.icontains,
                fieldName: ATTRIBUTES_FIELD_NAME,
                filters: [],
            },
            {
                val: "active",
                expression: FilterExpression.eq,
                fieldName: STATE_FIELD_NAME,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };
}

import React from "react";

export function Integrations(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#BDBDBD"
                    fillRule="nonzero"
                    transform="translate(-529 -438)"
                >
                    <g transform="translate(529 438)">
                        <path d="M15.857 8.571h-1.286V5.143c0-.947-.767-1.714-1.714-1.714H9.43V2.143a2.143 2.143 0 00-4.286 0v1.286H1.714C.768 3.429 0 4.196 0 5.143V8.57h1.286a2.143 2.143 0 110 4.286H0v3.429C0 17.232.768 18 1.714 18h3.429v-1.286a2.143 2.143 0 014.286 0V18h3.428c.947 0 1.714-.768 1.714-1.714v-3.429h1.286a2.143 2.143 0 000-4.286z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

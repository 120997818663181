import { AppEvents } from "@src/analytics/events";
import { SpendEvents, trackToggleFiltersClicked } from "@src/analytics/spend";
import {
    OrganizationFieldInputType,
    OrganizationFieldType,
} from "@src/requests/custom_fields";
import { appIndividual, routes } from "@src/routes";
import { AddApplicationModal } from "@src/shared_modules/add_application_modal";
import { AppStateDropdown } from "@src/shared_modules/app_state";
import {
    DashboardOverviewHeader,
    HeaderButton,
    HeaderItem,
} from "@src/shared_modules/dashboard_list_header";
import {
    ColumnHeader,
    Header,
    HeaderLinkCC,
} from "@src/shared_modules/dashboard_listing";
import { DoraDateRangeFilterCC } from "@src/shared_modules/date_range_filter";
import { FilterDropdownCC } from "@src/shared_modules/filter_dropdown";
import { FilterPills } from "@src/shared_modules/filter_pills";
import { HorizontalDivider } from "@src/shared_modules/horizontal_divider";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { EmptyQuery, fetchListing } from "@src/shared_modules/list";
import { Loader } from "@src/shared_modules/loader";
import { showModal } from "@src/shared_modules/modal";
import { MoreFiltersButton } from "@src/shared_modules/more_filters_button/component";
import {
    OrganizationField,
    OrgFieldFetcher,
} from "@src/modules/org_fields/components/org_fields";
import { PaginationCC } from "@src/shared_modules/pagination";
import { SearchFilterCC } from "@src/shared_modules/search_filter";
import {
    MasterTeam,
    WithTeams,
} from "@src/modules/teams/components/teams_query";
import { FetchTooltips } from "@src/shared_modules/tooltips";
import classnames from "classnames";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FadeInOut } from "@src/shared_modules/transitions/FadeInOut";
import { ChecklistFilterCC } from "../../../../shared_modules/checklist_filter";
import { StateTooltip } from "./StateTooltip";
import { NumberRangeFilterCC } from "../../../../shared_modules/number_range_filter";
import { TeamsCircles } from "../../../../shared_modules/teams_circles/component";
import { VendorTile } from "../../../../shared_modules/vendor_tile";
import { modelType, SpendInfo, SpendListState } from "./reducer";
import styles from "./styles.module.css";

// // // //

interface DispatchProps {
    showModal: (a: string) => void;
}

interface State {
    spendListing: SpendListState;
}

// AppTile displays the application tile
function AppTile(props: SpendInfo) {
    const className =
        props.spend.totalDisplay === "$0" ? styles.transparentTile : "";
    let { totalDisplay, lastPurchaseAmount } = props.spend;
    if (props.spend.convertedTotal != null) {
        totalDisplay = props.spend.convertedTotal.formatted;
    }
    if (props.spend.lastConvertedAmount != null) {
        lastPurchaseAmount = props.spend.lastConvertedAmount.formatted;
    }
    return (
        <VendorTile
            key={`app-listing-${props.vendor.id}`}
            href={{
                pathname: routes.spendIndividual,
                query: { vendorID: props.vendor.id },
            }}
            as={appIndividual(props.vendor.id)}
            eventName={AppEvents.appTileClicked}
            eventProps={{
                vendor_id: props.vendor.id,
                last_spend: lastPurchaseAmount,
                spend_in_range: totalDisplay,
                last_payment_date: props.spend.lastPaymentDate,
            }}
            name={props.vendor.name}
            category={props.vendor.category}
            logo={props.vendor.logo}
            className={className}
        >
            <div className="col-md-2 d-flex">
                <TeamsCircles teams={props.teams || []} />
            </div>
            <div className={`d-flex flex-column ${styles.spendContainer}`}>
                <h4 className={styles.lastSpend}>{lastPurchaseAmount}</h4>
                <p className={styles.spendDate}>
                    {props.spend.lastPaymentDate != null
                        ? `on ${props.spend.lastPaymentDate}`
                        : "on never :)"}
                </p>
            </div>
            <div className={`d-flex flex-column ${styles.spendContainer}`}>
                <h4 className={styles.spendRange}>{totalDisplay}</h4>
                {props.renewalDate != null ? (
                    <p className={styles.spendDate}>
                        Renews {props.renewalDate}
                    </p>
                ) : null}
            </div>
            <div className={styles.stateContainer}>
                <AppStateDropdown
                    app={props}
                    activeAppState={props.state}
                    possibleAppStates={props.states}
                    appID={props.id}
                />
            </div>
        </VendorTile>
    );
}

function TeamsFilter(props: { teams: MasterTeam[] }) {
    const teamNames = props.teams.map((t) => t.name);
    return (
        <div className="flex-grow-1 col-3">
            <FilterDropdownCC
                name="teams"
                title="Teams"
                filters={teamNames}
                multiple={false}
            />
        </div>
    );
}

const TeamsFilterQuery = WithTeams(TeamsFilter);

// FiltersProps represents the props that should be passed to the Filters component
interface FiltersProps {
    states: string[] | undefined;
    onToggle: (a: boolean) => void;
    open: boolean;
    appliedCount: number;
}

// Filters generate the filters that we search for on the spend listing page
function Filters(props: FiltersProps) {
    return (
        <section className={`d-flex align-items-center ${styles.filterRow}`}>
            <div
                className={`d-flex no-gutters align-items-center ${styles.filtersWrapper}`}
            >
                <div className="flex-grow-1">
                    <FilterDropdownCC
                        name="states"
                        title="Status"
                        filters={props.states || []}
                        multiple={false}
                    />
                </div>
                <TeamsFilterQuery />
                <DoraDateRangeFilterCC
                    name="purchase_date"
                    defaultStart={moment()
                        .startOf("day")
                        .subtract(1, "years")}
                    defaultEnd={moment().startOf("day")}
                />
                <div className={`flex-grow-1 ${styles.searchWrapper}`}>
                    <SearchFilterCC searchName="query" />
                </div>
                <MoreFiltersButton
                    onClick={() => {
                        props.onToggle(false);
                    }}
                    text={`Advanced Filters${
                        props.appliedCount ? ` • ${props.appliedCount}` : ""
                    }`}
                    active={props.open}
                />
                <LinkCC
                    href={window.location.pathname}
                    className={styles.resetFiltersButton}
                    eventName={SpendEvents.toggleFiltersClicked}
                    eventProps={{
                        action: "reset",
                    }}
                >
                    Reset Filters
                </LinkCC>
            </div>
        </section>
    );
}

// NoSpendMessage renders the empty message when only results without spend were found
function NoSpendMessage() {
    return (
        <li className="col-12">
            <EmptyQuery
                header="No spend found"
                message="We did find application matches, but not in this date range."
                className={`${styles.noSpendMessage} mb-20-px mt-10-px py-30-px`}
            />
        </li>
    );
}

// Separator renders the separator between the tiles
function Separator() {
    return (
        <li className={`${styles.separator} d-flex align-items-center`}>
            <div className={`${styles.separatorLine} flex-grow-1`} />
            <h2 className={styles.separatorText}>
                Additional results with no spend
            </h2>
            <div className={`${styles.separatorLine} flex-grow-1`} />
        </li>
    );
}

/**
 * ColumnHeaders
 * Renders the header row of the spend listing
 */
function ColumnHeaders() {
    return (
        <FetchTooltips tag="spend_listing_table">
            <li className="d-flex align-items-center col-12">
                <ColumnHeader
                    className="col-md-3"
                    text="Name"
                    tooltipKey="spend_listing_table_name_tooltip"
                />
                <ColumnHeader
                    className="col-md-2"
                    text="Teams"
                    tooltipKey="spend_listing_table_teams_tooltip"
                />
                <HeaderLinkCC
                    className={styles.spendContainer}
                    text="Last Payment"
                    sort="last_purchase_price"
                    defaultDirection="desc"
                    default={false}
                    tooltipKey="spend_listing_table_last_payment_tooltip"
                />
                <HeaderLinkCC
                    className={styles.spendContainer}
                    text="Total Spend"
                    sort="converted_total"
                    default
                    defaultDirection="desc"
                    tooltipKey="spend_listing_table_total_spend_tooltip"
                />
                <Header className={styles.stateContainer}>
                    Status
                    <StateTooltip />
                </Header>
            </li>
        </FetchTooltips>
    );
}

// BodyProps are the props of the Body component
interface BodyProps {
    list: SpendListState;
}

// Body renders the core of the listing, if there are no application or only 1 app (meaning it's intello) we render the empty list component
function Body(props: BodyProps) {
    if (props.list.results.length === 0) {
        return (
            <div className={styles.emptyListWrapper}>
                <EmptyQuery />
            </div>
        );
    }

    const renderNoSpendMessage =
        props.list.results[0].spend.totalDisplay === "$0" &&
        props.list.pagination.page === 1;
    let previousSpend: string | null = null;
    return (
        <div className={styles.listingWrapper}>
            <ul className="d-flex row">
                {renderNoSpendMessage && <NoSpendMessage />}

                <ColumnHeaders />
                {props.list.results.map((app) => {
                    const renderSeparator =
                        previousSpend !== null &&
                        previousSpend !== "$0" &&
                        app.spend.totalDisplay === "$0" &&
                        app.spend.lastPurchaseAmount === "$0";
                    previousSpend = app.spend.totalDisplay;
                    return [
                        renderSeparator && <Separator key="separator" />,
                        <AppTile key="app" {...app} />,
                    ];
                })}
            </ul>
            <PaginationCC {...props.list.pagination} />
        </div>
    );
}

const FetchBody = fetchListing(Body);

// FilterProps represents the props that should be passed to any filter component
interface FilterComponentProps {
    // eslint reports false positive about the fields below
    // eslint-disable-next-line react/no-unused-prop-types
    name: string;
    // eslint-disable-next-line react/no-unused-prop-types
    values?: string[];
}

// SearchFilter renders the search filter
function SearchFilter(props: FilterComponentProps) {
    return (
        <div className={`pr-10-px ${styles.advancedFilterContainer}`}>
            <SearchFilterCC searchName={props.name} />
        </div>
    );
}

// DateFilter renders the date filter
function DateFilter(props: FilterComponentProps) {
    return <DoraDateRangeFilterCC name={props.name} />;
}

// NumberRangeFilter renders the number range filter
function NumberRangeFilter(props: FilterComponentProps) {
    return (
        <div className={styles.advancedFilterContainer}>
            <NumberRangeFilterCC name={props.name} />
        </div>
    );
}

// DropdownFilter renders the dropdown filter
function DropdownFilter(props: FilterComponentProps) {
    return (
        <div className={styles.advancedFilterContainer}>
            <FilterDropdownCC
                name={props.name}
                title="Select a value"
                filters={props.values || []}
                multiple={false}
            />
        </div>
    );
}

// ChecklistFilter renders the checklist filter
function ChecklistFilter(props: FilterComponentProps) {
    return <ChecklistFilterCC name={props.name} values={props.values || []} />;
}

// FilterProps represents the props that should be passed to the Filter component
interface FilterProps {
    field: OrganizationField;
}

// fieldTypeToComp is a mapping of field and input types to their appropriate component
const fieldTypeToComp: {
    [key in OrganizationFieldType]: {
        // NOTE: prevents "Shadowed name: 'key'" error
        [key in OrganizationFieldInputType]?: React.ComponentType<
            FilterComponentProps
        >;
    };
} = {
    text_area: {
        string: SearchFilter,
        number: NumberRangeFilter,
        multiline_string: SearchFilter,
    },
    date_picker: { date: DateFilter },
    dropdown: { list: DropdownFilter },
    checklist: { list: ChecklistFilter },
    switch: { list: ChecklistFilter },
};

// Filter renders a filter for a given field
function Filter(props: FilterProps) {
    const { fieldType, inputType, name, attributes } = props.field;
    const FilterComponent: React.ComponentType<FilterComponentProps> =
        fieldTypeToComp[fieldType][inputType];
    return (
        <div
            className={`d-flex align-items-center justify-content-between ${styles.filterWrapper}`}
        >
            <h4 className={styles.filterName}>{name}</h4>
            <div className={styles.filterComponentWrapper}>
                <FilterComponent name={name} values={attributes} />
            </div>
        </div>
    );
}

// MoreFiltersProps represents the props that should be passed to the MoreFilters component
interface MoreFiltersProps {
    open: boolean;
    onToggle: (a: boolean) => void;
    orgFields: OrganizationField[];
}

// MoreFilters renders the additional and custom filters
export function MoreFilters(props: MoreFiltersProps) {
    // Return null if props.open is false
    if (!props.open) {
        return null;
    }

    const additionalFilters = props.orgFields.filter(
        (field) => !field.editable
    );
    const customFilters = props.orgFields.filter((field) => field.editable);

    // Defines className for MoreFilters
    const moreFiltersClassName = classnames(
        styles.moreFilters,
        "d-flex flex-column px-20-px pt-20-px pb-50-px"
    );

    return (
        <div className={moreFiltersClassName}>
            <div className="row">
                <div className="col-7">
                    <div className={`${styles.moreFiltersSection}`}>
                        <h3 className={styles.moreFiltersHeader}>
                            Additional filters
                        </h3>
                        <p className={styles.moreFiltersSubheader}>
                            You can add your own custom filters on the{" "}
                            <LinkCC href={routes.manageOrganizationFields}>
                                Settings Page
                            </LinkCC>
                        </p>
                        <div className="pr-5-px">
                            {additionalFilters.map((field) => (
                                <Filter key={field.id} field={field} />
                            ))}
                        </div>
                    </div>
                    {customFilters.length > 0 && (
                        <div className={`${styles.moreFiltersSection}`}>
                            <h3 className={styles.moreFiltersHeader}>
                                Custom filters
                            </h3>
                            <p className={styles.moreFiltersSubheader}>
                                These filters are set by you
                            </p>
                            <div className="pr-5-px">
                                {customFilters.map((field) => (
                                    <Filter key={field.id} field={field} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-sm-7">
                    <HorizontalDivider />
                </div>
            </div>

            <div className="row">
                <div className="col-7 d-flex justify-content-end pt-30-px">
                    <button
                        onClick={() => {
                            props.onToggle(true);
                        }}
                        className={`transparent-grey-round-button font-secondary text-grey-mid ${styles.moreFiltersCancelButton}`}
                    >
                        Cancel
                    </button>{" "}
                    <button
                        onClick={() => {
                            props.onToggle(false);
                        }}
                        className="btn-sm btn-primary font-secondary-bold"
                    >
                        Show Applications
                    </button>
                </div>
            </div>
        </div>
    );
}

// TeamsHeader represents the teams header item
function TeamsHeader(props: { teams: MasterTeam[] }) {
    return (
        <HeaderItem
            type="info"
            number={props.teams.length}
            description="Teams"
            noRightBorder
        />
    );
}

const TeamHeaderQuery = WithTeams(TeamsHeader);

// Defines type signature applied to fieldTypeToFilterPill
type FieldTypeToFilterPill = {
    [key in OrganizationFieldType]: {
        // NOTE: prevents "Shadowed name: 'key'" error
        [key in OrganizationFieldInputType]?:
            | "string"
            | "string_list"
            | "dora_date_range"
            | "number_range";
    };
};

// fieldTypeToFilterPill is a mapping of field and input types to their appropriate filter pill type
const fieldTypeToFilterPill: FieldTypeToFilterPill = {
    text_area: {
        string: "string",
        number: "number_range",
        multiline_string: "string",
    },
    date_picker: { date: "dora_date_range" },
    dropdown: { list: "string" },
    checklist: { list: "string_list" },
    switch: { list: "string_list" },
};

// ActiveFilterPillsProps are the props of the ActiveFilterPills component
interface ActiveFilterPillsProps {
    orgFields: OrganizationField[];
}

// ActiveFilterPills renders the list of active filter pills. It is a separate component because otherwise flow kept reporting false positives
function ActiveFilterPills(props: ActiveFilterPillsProps) {
    const customFilters = props.orgFields.map((field) => {
        const type = fieldTypeToFilterPill[field.fieldType][field.inputType];
        return {
            type,
            displayName: field.name,
            paramName: field.name,
        };
    });
    return (
        <FilterPills
            filters={[
                {
                    type: "string",
                    displayName: "State",
                    paramName: "states",
                },
                {
                    type: "string",
                    displayName: "Team",
                    paramName: "teams",
                },
                {
                    type: "dora_date_range",
                    displayName: "Purchase Date",
                    paramName: "purchase_date",
                    defaultValue: {
                        min: moment()
                            .startOf("day")
                            .subtract(1, "year"),
                        max: moment().startOf("day"),
                    },
                },
                {
                    type: "string",
                    displayName: "Keyword",
                    paramName: "query",
                },
            ].concat(customFilters)}
        />
    );
}

// SpendListState is the state of the SpendList component
interface SpendListInternalState {
    filtersOpen: boolean;
    prevRoute: string;
}

type Props = {
    orgFields: OrganizationField[];
    route: RouteState;
} & DispatchProps &
    SpendListState;

// SpendListLayout renders the spend list page
export class SpendListLayout extends React.Component<
    Props,
    SpendListInternalState
> {
    constructor(props: Props) {
        super(props);

        this.state = {
            filtersOpen: false,
            prevRoute: props.route.fullUrl,
        };
        this.toggleFilters = this.toggleFilters.bind(this);
    }

    // toggleFilters toggles visibility of the "More filters" section. if it is visible and `restorePrev` is true,
    // we remove the latest added filters and restore their previous state
    public toggleFilters(restorePrev: boolean) {
        trackToggleFiltersClicked(this.state.filtersOpen ? "hide" : "show");
        if (!this.state.filtersOpen) {
            // save previous filters when opening the "More filters"
            this.setState({ prevRoute: this.props.route.fullUrl });
        } else if (restorePrev) {
            // restore previous filters if a user clicked the "Cancel" or the "More filters" button when the filters were already open
            this.props.route.updateRouteAction(this.state.prevRoute);
        }
        // toggle visibility
        this.setState({
            filtersOpen: !this.state.filtersOpen,
        });
    }

    // getAppliedFiltersCount returns the count of applied additional filters
    public getAppliedFiltersCount(): number {
        const { params } = this.props.route;
        // calculate the applied filters by looping over each organization fields and seeing if there's matching key for it in the query params
        return this.props.orgFields.filter((filter) => filter.name in params)
            .length;
    }

    public render() {
        // exclude the state filter from the "more filters" section because it is already displayed
        const orgFields = this.props.orgFields.filter(
            (field) => field.name !== "State"
        );

        // Defines className value for <section />
        const sectionClassName = classnames({
            "d-flex flex-column": true,
            [styles.noScroll]: this.state.filtersOpen,
            [styles.fullHeight]: this.state.filtersOpen,
        });

        return (
            <section className={sectionClassName}>
                <Filters
                    states={this.props.filters.states}
                    onToggle={this.toggleFilters}
                    open={this.state.filtersOpen}
                    appliedCount={this.getAppliedFiltersCount()}
                />
                <div
                    className={`flex-grow-1 ${styles.bodyWrapper} ${
                        this.state.filtersOpen ? styles.fullHeight : ""
                    }`}
                >
                    <MoreFilters
                        open={this.state.filtersOpen}
                        onToggle={this.toggleFilters}
                        orgFields={orgFields}
                    />

                    <DashboardOverviewHeader
                        value={this.props.total}
                        description="Total Apps"
                        wrapperClassName={styles.header}
                    >
                        <HeaderItem
                            type="primary"
                            number={this.props.metadata.appsWithSpend}
                            description="Apps With Spend"
                            noLeftBorder
                            noRightBorder
                        />
                        <TeamHeaderQuery />
                        <HeaderItem
                            type="success"
                            number={this.props.metadata.totalSpend}
                            description="Total Spend"
                        />
                        <HeaderButton
                            descriptionLines={[
                                "Missing an app?",
                                "Add a new application",
                            ]}
                            buttonText="+ Add an app"
                            onClick={() => {
                                this.props.showModal("add-application");
                            }}
                            className={`flex-grow-1 justify-content-end ${styles.addAppWrapper}`}
                        />
                    </DashboardOverviewHeader>
                    <ActiveFilterPills orgFields={this.props.orgFields} />
                    <FetchBody
                        modelType={modelType}
                        path="v2/applications"
                        query={this.props.route.params}
                    />
                </div>
                <AddApplicationModal />
            </section>
        );
    }
}

export function mapStateToProps({ spendListing }: State): SpendListState {
    return {
        ...spendListing,
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators(
        {
            showModal,
        },
        dispatch
    );
}

const SpendList = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SpendListLayout));

/**
 * SpendListCC
 * Render the SpendListing page
 *
 */
export function SpendListCC() {
    return (
        <OrgFieldFetcher>
            {({ loading, orgFields }) => {
                // Handles loading state
                if (loading) {
                    return (
                        <div className="tw-h-full tw-flex tw-flex-col tw-justify-center">
                            <FadeInOut>
                                <Loader />
                            </FadeInOut>
                        </div>
                    );
                }

                return <SpendList orgFields={orgFields} />;
            }}
        </OrgFieldFetcher>
    );
}

import { OrgSavedViewsEvents } from "@src/analytics/org_saved_views";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import classnames from "classnames";
import * as React from "react";
import { buildOrgSavedViewUrl } from "./buildOrgSavedViewUrl";
import { OrgSavedView } from "./types";

// // // //

/**
 * OrgSavedViewLinkProps
 * @param orgSavedView - The OrgSavedView for which the LinkCC is being rendered
 * @param children - Child content to render inside the LinkCC
 * @param className - (optional) Classname to be applied instead of default `btn-outline-primary`
 * @param pathname - (optional) - Alternative pathname applied by the LinkCC
 * @param location - (optional) - Alternative location applied by the LinkCC
 */
interface OrgSavedViewLinkProps {
    orgSavedView: OrgSavedView;
    className?: string;
    children: React.ReactNode;
    pathname?: string;
    location?: string;
}

/**
 * OrgSavedViewLink
 * Renders a LinkCC that applies SavedView.filters to the current URL
 * @param props - see OrgSavedViewLinkProps
 */
export function OrgSavedViewLinkLayout(
    props: OrgSavedViewLinkProps & { route: RouteState }
) {
    const {
        orgSavedView,
        route,
        className = "",
        pathname = null,
        location = null,
    } = props;

    // Defines URL to current location with the OrgSavedView applied in RouteState.params
    const url = buildOrgSavedViewUrl({
        location: location || route.location,
        orgSavedViewID: orgSavedView.id,
        routeParams: route.params,
    });

    return (
        <LinkCC
            className={classnames({
                [className]: className !== "",
            })}
            href={{
                pathname: pathname || route.nextPathname,
                query: route.pathParams,
            }}
            as={url}
            eventName={OrgSavedViewsEvents.applyOrgSavedView}
            eventProps={{ name: orgSavedView.name }}
        >
            {props.children}
        </LinkCC>
    );
}

export const OrgSavedViewLink: React.ComponentType<OrgSavedViewLinkProps> = withRouter(
    OrgSavedViewLinkLayout
);

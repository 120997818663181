import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";
import { AppInfoList, APP_INFO_LIST_FRAGMENT } from "@src/schema/AppInfoList";

// // // //

/**
 * Query for AppInfo data
 */
export const APP_INFO_LIST_FETCHER_QUERY = gql`
    query APP_INFO_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        usage {
            appInfoAdvancedFilters {
                list(filters: $filters) {
                    ...APP_INFO_LIST_FRAGMENT
                }
            }
        }
    }
    ${APP_INFO_LIST_FRAGMENT}
`;

/**
 * Response interface for APP_INFO_LIST_FETCHER_QUERY
 */
export interface AppInfoListFetcherResponse {
    usage: {
        appInfoAdvancedFilters: {
            list: AppInfoList;
            __typename: "AppInfoAdvancedFilters";
        };
        __typename: "Usage";
    };
}

/**
 * Variables interface for APP_INFO_LIST_FETCHER_QUERY
 */
export interface AppInfoListFetcherVariables {
    filters: FiltersInput;
}

import { Pagination } from "@src/schema/Pagination";
import { AppUserReportResultBucketList } from "@src/schema/AppUserReportResultBucketList";
import { AppUserReportResultBucket } from "@src/schema/AppUserReportResultBucket";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { Query } from "react-apollo";
import {
    AppUserReportResultBucketListFetcherResponse,
    AppUserReportResultBucketListFetcherVariables,
    APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY,
} from "./graphql";

// // // //

const EMPTY_LIST: AppUserReportResultBucketList = {
    results: [],
    pagination: {
        pageCount: 0,
        rangeStart: 0,
        rangeEnd: 0,
        page: 1,
        totalItems: 0,
        itemsPerPage: 25,
        sort: "",
        direction: "",
        __typename: "Pagination",
    },
    __typename: "AppUserReportResultBucketList",
};

interface Props {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        userReportResultBuckeList: AppUserReportResultBucket[];
        refetchUserReportResultBuckeList: () => void;
    }) => React.ReactNode;
}

/**
 * @name UserReportResultBucketListFetcher
 * @description Fetches UserReportResultBuckeList via GQL using AdvancedFilters
 * @param {Props} props
 */
export function UserReportResultBucketListFetcher(props: Props) {
    // IMPORTANT - we include `cache-and-network` fetchPolicy here to ensure that the Apollo Client
    // knows to render the updated data after we invoke `refetch`. This is necessary to handle a known bug in the Apollo Client
    // where a query dispatched without any changes in variables data will cause the loading state to hang if the fetchPolicy is `cache-only`.
    return (
        <Query<
            AppUserReportResultBucketListFetcherResponse,
            AppUserReportResultBucketListFetcherVariables
        >
            query={APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY}
            fetchPolicy="cache-and-network"
            variables={{
                filters: props.filtersInput,
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls UserReportResultBuckeList from data + assigns default value
                const list: AppUserReportResultBucketList =
                    data?.usage?.appUserReportResultBuckets?.list || EMPTY_LIST;

                // Safely pulls UserReportResultBucket[] + Pagination from UserReportResultBuckeList
                const userReportResultBuckeList: AppUserReportResultBucket[] =
                    list?.results || [];
                const pagination: Pagination =
                    list?.pagination || EMPTY_LIST.pagination;

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            userReportResultBuckeList,
                            refetchUserReportResultBuckeList: () => {
                                refetch();
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

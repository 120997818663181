import { updateLoading } from "@src/util/state_util";
import {
    UserAction,
    USER_ERR_RESULT,
    USER_LOADING,
    USER_RESULT,
} from "./actions";

export interface UserState {
    loading: boolean;
    id: number;
    email: string;
    name: string;
    organizationID: number;
    organization: {
        id: number;
        domain: string;
        clientView: number;
    };
    authenticated: boolean;
    state: string;
    isAdmin: boolean;
    dashboardUser: boolean;
}

export const defaultState: UserState = {
    loading: true,
    id: 0,
    email: "",
    name: "",
    organizationID: 0,
    organization: {
        id: 0,
        domain: "",
        clientView: 0,
    },
    authenticated: false,
    state: "",
    isAdmin: false,
    dashboardUser: false,
};

function result(_state: UserState, action: UserAction): UserState {
    return {
        loading: false,
        id: action.user.id,
        email: action.user.email,
        name: action.user.name,
        organizationID: action.user.organizationID,
        organization: action.user.organization,
        authenticated: true,
        state: action.user.state,
        isAdmin: action.user.assignedRoles.some((r) => r.name === "Admin"),
        dashboardUser:
            action.user.state === "live" || action.user.state === "admin",
    };
}

function error(state: UserState): UserState {
    return {
        ...state,
        loading: false,
        authenticated: false,
    };
}

export function user(state: UserState = defaultState, action: any): UserState {
    switch (action.type) {
        case USER_RESULT:
            return result(state, action);
        case USER_LOADING:
            return updateLoading(state, action.loading);
        case USER_ERR_RESULT:
            return error(state);
        default:
            return state;
    }
}

import gql from "graphql-tag";
import {
    InstanceTokenStatus,
    INSTANCE_TOKEN_STATUS_FRAGMENT,
} from "./InstanceTokenStatus";
import { IntegrationStatus } from "./IntegrationStatus";

// // // //

/**
 * OauthIntegration represents an oauth integration extension model
 */
export interface OauthIntegration {
    name: string | null;
    id: string;
    isActive: boolean;
    createdAt: string;
    status: IntegrationStatus;
    tokenStatus: InstanceTokenStatus;
    lastFetch?: string;
    __typename: "OauthIntegration";
}

export const OAUTH_INTEGRATION_FRAGMENT = gql`
    fragment OAUTH_INTEGRATION_FRAGMENT on OauthIntegration {
        name
        id
        isActive
        createdAt
        status
        lastFetch
        tokenStatus {
            ...INSTANCE_TOKEN_STATUS_FRAGMENT
        }
    }
    ${INSTANCE_TOKEN_STATUS_FRAGMENT}
`;

import { routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState } from "../types";

// // // //

export const apiKeysState = (route: RouteState): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.settings.apiKeys,
                query: {},
            },
            as: routes.settings.apiKeys,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: { breadcrumb: "settings.apiKeys" },
        },
        enabled: true,
        active: route.nextPathname === routes.settings.apiKeys,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "API Keys" },
        },
    };
};

import { FocusProvider } from "@src/shared_modules/focus_provider";
import classnames from "classnames";
import * as React from "react";
import { DropdownSelect } from "@src/shared_modules/dropdown_select";
import { NumberInput } from "@src/shared_modules/input";
import { mapFilterExpressionToSymbol } from "@src/modules/advanced_filters/components/advanced_filters/FilterExpressionSymbol";
import { Hotkey } from "@src/shared_modules/hotkey";
import styles from "./styles.module.css";
import { FilterExpression } from "../../advanced_filter_editor/graphql";
import { trackApply, trackOpen } from "./analytics";

// // // //

// Constants for event.key values
const KEY_ENTER = "Enter";

/**
 * Returns the human-readable preview for the NumberInput component
 */
function NumberInputPreviewText(props: {
    value: number | null;
    expression: FilterExpression;
}) {
    const { value, expression } = props;
    // Return null if value/expression are invalid
    if (!isNumberInputValueValid(props)) {
        return null;
    }

    // Get the formattted value + expression
    const formattedValue = value.toLocaleString();
    const formattedExpression = mapFilterExpressionToSymbol[expression];

    // Return number w/ expression symbol
    return (
        <React.Fragment>
            <span className="tw-mr-2">{formattedExpression}</span>
            {formattedValue}
        </React.Fragment>
    );
}

/**
 * Returns a boolean indicating whether or not a
 * value + FilterExpression pair is valid for the NumberInput component
 */
function isNumberInputValueValid(props: {
    value: number | null;
    expression: FilterExpression | null;
}): boolean {
    const { value, expression } = props;
    if (
        value === null ||
        expression === null ||
        Number.isNaN(value) ||
        typeof value !== "number"
    ) {
        return false;
    }
    return true;
}

/**
 * Renders the "Apply Date Filter" button used to trigger the
 * @param props.onClick - Callback fired off when the <button/> is clicked
 */
function ApplyFilterButton(props: { disabled: boolean; onClick: () => void }) {
    const { disabled } = props;
    return (
        <button
            className="btn btn-md btn-primary font-secondary-bold w-100 d-flex justify-content-center whitespace-nowrap"
            onClick={props.onClick}
            disabled={disabled}
        >
            Apply
        </button>
    );
}

/**
 * Renders a button that toggles the inline NumberInput
 * Displays result of getNumberInputPreviewText when a value is present
 * @param props.value - the current value of the NumberInput
 * @param props.expression - the current expression in the NumberInput
 * @param props.active - whether or not the inline NumberInput is open
 * @param props.onClick - callback invoked to open the inline NumberInput
 * @param props.placeholder - optional label to display instead default placeholder
 */
function NumberInputPreview(props: {
    value: number | null;
    active: boolean;
    onClick: () => void;
    expression: FilterExpression;
    placeholder?: string;
}) {
    const showPreview: boolean = isNumberInputValueValid({
        value: props.value,
        expression: props.expression,
    });

    return (
        <button
            onClick={props.onClick}
            className={classnames(
                "d-flex justify-content-between align-items-center tw-relative", // Flex + Position
                "tw-outline-none tw-rounded-lg tw-border tw-border-solid", // Border + Outline
                "bg-white font-secondary px-10-px py-5-px", // Color + Spacing
                styles.numberInputPreview,
                {
                    [styles.numberInputPreviewActive]: props.active,
                    "tw-rounded-br-none tw-rounded-bl-none": props.active,
                }
            )}
        >
            <div className="d-flex mr-10-px">
                {showPreview === false && (
                    <p
                        className="font-size-12-px text-grey-dim whitespace-nowrap"
                        style={{ lineHeight: "21px" }}
                    >
                        {props.placeholder
                            ? props.placeholder
                            : "Set number filter"}
                    </p>
                )}
                {showPreview && (
                    <div className="d-flex flex-column align-items-start">
                        <p
                            className="font-secondary-bold text-uppercase text-updated-black whitespace-nowrap"
                            style={{ fontSize: "8px" }}
                        >
                            {props.placeholder ? props.placeholder : "Number"}
                        </p>
                        <p
                            className="text-grey-mid whitespace-nowrap"
                            style={{ fontSize: "10px", marginTop: "1px" }}
                        >
                            <NumberInputPreviewText
                                value={props.value}
                                expression={props.expression}
                            />
                        </p>
                    </div>
                )}
            </div>
            <div className="d-flex">
                <i
                    className={classnames("fa fa-hashtag", {
                        "text-grey-dim": showPreview === false,
                    })}
                />
            </div>
        </button>
    );
}

// // // //

export function NumberInputInline(props: {
    value: any;
    expression: any;
    placeholder: string;
    integersOnly?: boolean;
    possibleExpressions: FilterExpression[];
    onChange: (onChangeProps: {
        value: number;
        expression: FilterExpression;
    }) => void;
}) {
    const { integersOnly = false } = props;
    const [value, setValue] = React.useState<number | null>(props.value);
    const [expression, setExpression] = React.useState<FilterExpression | null>(
        props.expression
    );

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    React.useEffect(() => {
        setExpression(props.expression);
    }, [props.expression]);

    return (
        <FocusProvider>
            {({ focused, setFocused }) => (
                <div className="tw-relative">
                    {/* Wire up esc hotkey to close inline input */}
                    <Hotkey
                        keyname="esc"
                        onKeyDown={() => {
                            setFocused(false);
                        }}
                    />

                    <NumberInputPreview
                        value={props.value}
                        expression={props.expression}
                        active={focused}
                        placeholder={props.placeholder}
                        onClick={() => {
                            if (focused) {
                                setFocused(false);
                                return;
                            }

                            trackOpen();
                            setFocused(true);
                        }}
                    />
                    {focused && (
                        <div
                            className={classnames(
                                styles.numberInputWrapper,
                                "bg-white py-15-px tw-shadow tw-absolute",
                                "tw-border tw-border-solid", // Border
                                "tw-rounded-bl-lg tw-rounded-br-lg tw-rounded-tr-lg" // border-radius
                            )}
                        >
                            <div className="tw-flex tw-items-center tw-gap-4 tw-px-4 tw-grid tw-grid-cols-5">
                                <div className="tw-col-span-1">
                                    <DropdownSelect
                                        disableSearch
                                        value={expression}
                                        placeholder="Exp"
                                        options={props.possibleExpressions.map(
                                            (e) => {
                                                return {
                                                    humanValue: (
                                                        <div className="w-100 d-flex justify-content-center">
                                                            {
                                                                mapFilterExpressionToSymbol[
                                                                    e
                                                                ]
                                                            }
                                                        </div>
                                                    ),
                                                    internalValue: e,
                                                };
                                            }
                                        )}
                                        onChange={(
                                            updatedExpression: FilterExpression
                                        ) => {
                                            setExpression(updatedExpression);
                                        }}
                                    />
                                </div>
                                <div className="tw-col-span-2">
                                    <NumberInput
                                        step={1}
                                        min={0}
                                        value={value}
                                        placeholder="Number"
                                        integersOnly={integersOnly}
                                        onChange={(updatedValue: number) => {
                                            setValue(updatedValue);
                                        }}
                                        onKeyDown={(e) => {
                                            // Invoke props.onChange on ENTER keydown
                                            if (e.key === KEY_ENTER) {
                                                if (
                                                    isNumberInputValueValid({
                                                        value,
                                                        expression,
                                                    })
                                                ) {
                                                    props.onChange({
                                                        value,
                                                        expression,
                                                    });
                                                    setFocused(false);
                                                    trackApply();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="tw-col-span-2">
                                    <ApplyFilterButton
                                        disabled={
                                            !isNumberInputValueValid({
                                                value,
                                                expression,
                                            })
                                        }
                                        onClick={() => {
                                            props.onChange({
                                                value,
                                                expression,
                                            });
                                            setFocused(false);
                                            trackApply();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </FocusProvider>
    );
}

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { FocusProvider } from "@src/shared_modules/focus_provider";
import classnames from "classnames";
import * as React from "react";
import { trackOpenDateInputInline } from "../../../analytics/date_input";
import { DateInput, DateInputProps } from "./DateInput";
import { getDateInputPreviewText } from "./getDateInputPreviewText";
import styles from "./styles.module.css";
import { DateInputValue } from "./types";

// // // //

/**
 * DateInputPreview
 * Renders a button that toggles the inline DateInput
 * Displays result of getDateInputPreviewText when a value is present
 * @param props.value - the current value of the DateInput
 * @param props.expression - the current expression in the DateInput
 * @param props.active - whether or not the inline DateInput is open
 * @param props.onClick - callback invoked to open the inline DateInput
 * @param props.placeholder - optional label to display instead default placeholder
 */
export function DateInputPreview(props: {
    value: DateInputValue;
    active: boolean;
    onClick: () => void;
    expression: FilterExpression;
    placeholder?: string;
}) {
    // Gets  the text rendered in the
    const dateInputPreview: string | null = getDateInputPreviewText({
        value: props.value,
        expression: props.expression,
    });

    return (
        <button
            onClick={props.onClick}
            className={classnames(
                "bg-white font-secondary d-flex justify-content-between align-items-center px-10-px py-5-px",
                styles.dateInputPreview,
                {
                    [styles.dateInputPreviewActive]: props.active,
                }
            )}
        >
            <div className="d-flex mr-10-px">
                {dateInputPreview === null && (
                    <p
                        className="font-size-12-px text-grey-dim"
                        style={{ lineHeight: "21px" }}
                    >
                        {props.placeholder
                            ? props.placeholder
                            : "Set date / range"}
                    </p>
                )}
                {dateInputPreview !== null && (
                    <div className="d-flex flex-column align-items-start">
                        <p
                            className="font-secondary-bold text-uppercase text-updated-black"
                            style={{ fontSize: "8px" }}
                        >
                            {props.placeholder
                                ? props.placeholder
                                : "Date Range"}
                        </p>
                        <p
                            className="text-grey-mid"
                            style={{ fontSize: "10px", marginTop: "1px" }}
                        >
                            {dateInputPreview}
                        </p>
                    </div>
                )}
            </div>
            <div className="d-flex">
                <i
                    className={classnames("fa fa-calendar-o", {
                        "text-grey-dim": dateInputPreview === null,
                    })}
                />
            </div>
        </button>
    );
}

/**
 * DateInputInline
 * Renders inline DateInput using FocusProvider + DateInputPreview + DateInput components
 * @param props - see `DateInputProps`
 */
export function DateInputInline(
    props: DateInputProps & { placeholder?: string }
) {
    const { value, expression } = props;

    return (
        <FocusProvider>
            {({ focused, setFocused }) => (
                <div className="tw-relative">
                    <DateInputPreview
                        value={value}
                        expression={expression}
                        active={focused}
                        placeholder={props.placeholder}
                        onClick={() => {
                            trackOpenDateInputInline();
                            setFocused(true);
                        }}
                    />
                    {focused && (
                        <div
                            className={classnames(
                                styles.dateInputWrapper,
                                "bg-white py-15-px"
                            )}
                        >
                            <DateInput
                                {...props}
                                inline={true}
                                onChange={(updatedValue) => {
                                    // Invokes props.onChange with updated value + expression
                                    props.onChange(updatedValue);

                                    // Set focused to false to close the input window
                                    setFocused(false);
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </FocusProvider>
    );
}

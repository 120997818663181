import {
    AdvancedFilter,
    AdvancedFilterInput,
    FieldType,
    Filter,
    FilterExpression,
    Filters,
    FiltersInput,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { PossibleFilterContent } from "@src/modules/advanced_filters/components/advanced_filter_editor/PossibleFilterContent";
import { isAndOrExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/reducer";
import { Pill } from "@src/shared_modules/pill";
import { captureMessage } from "@src/util/analytics";
import classnames from "classnames";
import * as React from "react";
import { FilterValue } from "../advanced_filter_editor/FilterValue";
import { Tooltip } from "../../../../shared_modules/tooltip";
import { FilterExpressionSymbol } from "./FilterExpressionSymbol";
import styles from "./styles.module.css";

// // // //

/**
 * getPossibleFilter
 * Returns the PossibleFilter instance associated with `props.filter`
 * Used in a few places we need to find a PossibleFilter that corresponds to a `Filter` instance
 * @param props.filter - the Filter whose corresponding PossibleFilter we're looking for
 * @param props.possibleFilters - the array of PossibleFilter[] instances we're searching
 */
export function getPossibleFilter(props: {
    filter: Filter;
    possibleFilters: PossibleFilter[];
}): PossibleFilter | undefined {
    // Searchs for the possibleFilter associated with props.filter
    const foundPossibleFilter:
        | PossibleFilter
        | undefined = props.possibleFilters.find(
        (pf: PossibleFilter) => pf.key === props.filter.fieldName
    );

    // Return `undefined` is no possibleFilter is found
    if (!foundPossibleFilter) {
        return undefined;
    }

    // If a possibleFilter is found, checks if its a date_range
    // If it is not, just return the regular foundPossibleFilter
    if (foundPossibleFilter.fieldType !== FieldType.date_range) {
        return foundPossibleFilter;
    }

    // If it is a date_range, we need to perform a second find to make sure
    // we've located the PossibleFilter with the correct default expression for props.filter
    // This is necessary because it's possible to have TWO `PossibleFilter` instances with the same key after
    return props.possibleFilters.find(
        (pf: PossibleFilter) =>
            pf.key === props.filter.fieldName &&
            pf.expressions.includes(props.filter.expression)
    );
}

/**
 * ExpressionBadge
 * Renders the `And` and `Or` expression badges rendered between each filter in `LongFormFilterPillContent`
 * @param props.expression - either `FilterExpression.and` or `FilterExpression.or`
 */
export function ExpressionBadge(props: {
    expression: FilterExpression.and | FilterExpression.or;
}) {
    // Defines bgClassName + label (defaults to `props.expression === FilterExpression.and`)
    let bgClassName = "border-primary";
    let textClassName = "text-primary";
    let label = "And";

    // If props.expression === "OR", change the bgClassName and label values
    if (props.expression === FilterExpression.or) {
        bgClassName = "border-tertiary";
        textClassName = "text-tertiary";
        label = "Or";
    }

    // Renders the `ExpressionBadge` JSX
    return (
        <div className={classnames(bgClassName, "tw-px-2 tw-rounded-full")}>
            <p
                className={classnames(
                    textClassName,
                    "font-size-12-px line-height-17-px font-secondary-heavy"
                )}
            >
                {label}
            </p>
        </div>
    );
}

/**
 * removeElementFromArrayAtIndex
 * Used when clicking the remove button on an individual FilterPill (both short- or long-form)
 * NOTE - this is the approach we need to take here because the `Filter` instances here do not have unique IDs,
 * and thus can only be reliably targeted for removal using their respective indicies relative to their parent array
 * @param T - generic type for this function (either Filter or AdvancedFilterInput)
 * @param arr - the array of elements we're removing an item from
 * @param index - the index of the element we're removing
 */
export function removeElementFromArrayAtIndex<T>(arr: T[], index: number): T[] {
    return arr.filter((_: T, i: number) => {
        return i !== index;
    });
}

/**
 * shouldRenderExpressionBadge
 * Returns a boolean indicating whether or not we want to render an ExpressionBadge after the
 * current `Filter` being rendered inside the `LongFormFilterPillContent` component.
 * We only want to render the badges when the `Filter` being rendered is NOT the last element in
 * the `LongFormFilterPillContent.props.filters` array AND when `params.filterExpression` matches `params.matchingFilterExpression`.
 * This prevents rendering situations like: `Filter AND Filter AND`, wheras we only want `Filter AND Filter`
 * @param index - the index of the `Filter` being rendered inside `LongFormFilterPillContent`
 * @param arrayLength - the length of the `Filter[]` thats being rendered by `LongFormFilterPillContent`
 * @param filterExpression - the `props.filterExpression` value from `LongFormFilterPillContent`
 * @param matchingFilterExpression - the FilterExpression matched against the `props.filterExpression` value from `LongFormFilterPillContent`
 */
export function shouldRenderExpressionBadge(
    index: number,
    arrayLength: number,
    filterExpression: FilterExpression,
    matchingFilterExpression: FilterExpression.and | FilterExpression.or
): boolean {
    return (
        index < arrayLength - 1 && filterExpression === matchingFilterExpression
    );
}

/**
 * LongFormFilterPillContent
 * Produces the "preview" of the Filter[] passed into the `FilterPillLongForm` component
 * NOTE - This exists separately because it needs to gracefully handle recursive rendering to accommodate
 * Filters where { expression: and / or }.
 * @param props.filters
 * @param props.possibleFilters
 * @param props.filterExpression
 */
export function LongFormFilterPillContent(props: {
    filters: Filter[];
    possibleFilters: PossibleFilter[];
    filterExpression: FilterExpression;
}) {
    return (
        <React.Fragment>
            {props.filters.map((f: Filter, index: number) => {
                // If the filter is an and/or expression, render this
                // component recursively for the nested filters and and return the result
                if (isAndOrExpression(f)) {
                    return (
                        <React.Fragment>
                            <div
                                className={classnames(
                                    "d-flex align-items-center tw-rounded-full tw-my-1 tw-py-1 tw-px-3 tw-gap-2",
                                    {
                                        "border-primary":
                                            f.expression ===
                                            FilterExpression.and,
                                        "border-tertiary":
                                            f.expression ===
                                            FilterExpression.or,
                                    }
                                )}
                            >
                                <LongFormFilterPillContent
                                    key={`${f.fieldName}-${String(index)}`}
                                    filters={f.filters}
                                    possibleFilters={props.possibleFilters}
                                    filterExpression={f.expression}
                                />
                            </div>
                            {/* Render "AND" badge if there's still more content to render */}
                            {index < props.filters.length - 1 && (
                                <ExpressionBadge
                                    expression={FilterExpression.and}
                                />
                            )}
                        </React.Fragment>
                    );
                }

                // Finds the PossibleFilter associated with each Filter `f`
                const possibleFilter:
                    | PossibleFilter
                    | undefined = getPossibleFilter({
                    filter: f,
                    possibleFilters: props.possibleFilters,
                });

                // Return null + captureMessage if no PossibleFilter is found
                if (!possibleFilter) {
                    captureMessage(
                        "AdvancedFilterPills - LongFormFilterPillContent -> no PossibleFilter found",
                        {
                            extra: {
                                fieldName: f.fieldName,
                                expression: f.expression,
                            },
                        }
                    );
                    return null;
                }

                // Defines flags to decide whether or not to render ExpressionBadge components
                const renderAndBadge: boolean = shouldRenderExpressionBadge(
                    index,
                    props.filters.length,
                    props.filterExpression,
                    FilterExpression.and
                );

                const renderOrBadge: boolean = shouldRenderExpressionBadge(
                    index,
                    props.filters.length,
                    props.filterExpression,
                    FilterExpression.or
                );

                // Render the `FilterListSummary` component, followed by an ExpressionBadge when applicable
                // NOTE - we supply `key` defined as `f.fieldName` concatenated with `String(index)` to ensure uniqueness
                // This is done because it's possible that we render multiple adjacent elements that reference the same PossibleFilter,
                // i.e. when we render { query like "foo" } OR { query like "bar" }
                return (
                    <div
                        key={f.fieldName + String(index)}
                        className="d-flex align-items-center tw-gap-2"
                    >
                        <PossibleFilterContent
                            possibleFilter={possibleFilter}
                        />

                        <FilterExpressionSymbol
                            className="font-size-12-px text-black font-secondary"
                            filterExpression={f.expression}
                            tooltip
                        />

                        <FilterValue
                            value={f.val}
                            filterExpression={f.expression}
                            possibleFilter={possibleFilter}
                        />

                        {/* Render AND badge */}
                        {renderAndBadge && (
                            <ExpressionBadge
                                expression={FilterExpression.and}
                            />
                        )}

                        {/* Render OR badge */}
                        {renderOrBadge && (
                            <ExpressionBadge expression={FilterExpression.or} />
                        )}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

/**
 * FilterPillLongFormProps
 * Props of the `FilterPillLongForm` component
 * @param filters - the array of Filters being fully rendered by this component
 * @param label - the label rendered before the "Where" text in the long-form FilterPill ("Defaults", advancedFilter.content.name)
 * @param onRemoveButtonClick - (optional) Callback method fired when clicking the little `x` to remove the pill
 */
interface FilterPillLongFormProps {
    label: string;
    description: string;
    filters: Filter[];
    possibleFilters: PossibleFilter[];
    onRemoveButtonClick?: () => void;
}

/**
 * FilterPillLongForm
 * Renders a long-form FilterPill including filter fieldNames, expressions, and values separated by and/or expressions.
 * This component is used when:
 * - rendering `filtersInput.defaults` where one of the root-level filters has an and/or expression
 * - anytime we render a the `AdvancedFilterInput.filters` from `filtersInput.advanced`
 * @param props - see FilterPillLongFormProps
 */
export function FilterPillLongForm(props: FilterPillLongFormProps) {
    return (
        <div
            className={classnames({
                "bg-white d-flex flex-row align-items-center font-secondary-heavy font-size-12-px tw-px-6 cursor-default tw-gap-2 tw-rounded-full": true,
                "tw-border tw-border-solid tw-border-gray-200": true,
                "flex-wrap": props.onRemoveButtonClick === undefined, // Apply "flex-wrap" when no remove button is present
                [styles.advancedFilterPillLongForm]: true,
            })}
        >
            {/* Renders the label for the long-form Filter Pill */}
            <div className={"tw-py-1"}>
                {/* Render label */}
                {!props.description && (
                    <p className="text-primary font-size-12-px line-height-17-px font-secondary-heavy">
                        {props.label}
                    </p>
                )}

                {/* Render label + description tooltip */}
                {props.description && (
                    <Tooltip
                        placement="top"
                        tooltipContent={
                            <p className="tw-p-3 font-size-12-px text-black font-secondary whitespace-nowrap">
                                {props.description}
                            </p>
                        }
                    >
                        {({ setTriggerRef }) => (
                            <p
                                ref={setTriggerRef}
                                className="text-primary font-size-12-px line-height-17-px font-secondary-heavy"
                            >
                                {props.label}
                            </p>
                        )}
                    </Tooltip>
                )}
            </div>

            {/* Renders the "Where" badge */}
            <div className="bg-grey-mid tw-px-2 tw-rounded-full">
                <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                    Where
                </p>
            </div>

            {/* Renders the `pillContent` from renderFiltersArray function */}
            {/* NOTE - we always pass `FilterExpression.and` in here because root-level filters are always joined by an implicit "and" expression */}
            <LongFormFilterPillContent
                filters={props.filters}
                possibleFilters={props.possibleFilters}
                filterExpression={FilterExpression.and}
            />

            {/* Renders button to remove pill by invoking `props.removeFilter` */}
            {props.onRemoveButtonClick && (
                <button
                    onClick={props.onRemoveButtonClick}
                    className={classnames(
                        "font-secondary-heavy font-size-18-px tw-text-gray-400 hover:tw-text-gray-600 tw-transition-colors", // Text Styles
                        "tw-bg-transparent tw-outline-none border-none cursor-pointer tw-pr-0 tw-pl-1"
                    )}
                >
                    ×
                </button>
            )}
        </div>
    );
}

/**
 * FilterPillShortFormProps
 * Props of the `FilterPillShortForm` component
 * @param filter - The Filter we're displaying
 * @param possibleFilter - The PossibleFilter associated with props.filter
 * @param onClickRemove - (optional) Callback function to remove the `props.filter` pill
 */
interface FilterPillShortFormProps {
    filter: Filter;
    possibleFilter: PossibleFilter;
    onClickRemove?: () => void;
}

/**
 * FilterPillShortForm
 * Renders a standard <Pill /> component for single `Filter` instance
 * @param props - see FilterPillShortFormProps
 */
export function FilterPillShortForm(props: FilterPillShortFormProps) {
    const { possibleFilter, filter } = props;
    return (
        <Pill onClickRemove={props.onClickRemove}>
            <div className="d-flex align-items-center tw-gap-2">
                <PossibleFilterContent possibleFilter={possibleFilter} />

                <FilterExpressionSymbol
                    className="font-size-12-px text-black font-secondary cursor-default"
                    filterExpression={filter.expression}
                    tooltip
                />

                <FilterValue
                    value={filter.val}
                    filterExpression={filter.expression}
                    possibleFilter={possibleFilter}
                />
            </div>
        </Pill>
    );
}

/**
 * DefaultPills
 * Renders Pills for `filters.defaults`
 * @param props - see `AdvancedFilterPillsProps`
 */
function DefaultPills(props: {
    filters: Filters;
    filtersInput: FiltersInput;
    requiredFilters: string[];
    setFiltersInput: (updatedFiltersInput: FiltersInput) => void;
    readOnly: boolean;
}) {
    const { filtersInput, filters, requiredFilters, readOnly } = props;

    // Defines a flag indicating whether or not to render pills for `filtersInput.defaults`
    const shouldRenderDefaults: boolean = filtersInput.defaults.length > 0;

    // Short-circuit function execution if `shouldRenderDefaults` is `false`
    if (!shouldRenderDefaults) {
        return null;
    }

    // Collects the `{ expression: FilterExpression }` values from root-level Filter instances in `filtersInput.defaults`
    // This is done to help us define `renderLongFormDefaultsPill` below
    const defaultFilterExpressions: FilterExpression[] = filtersInput.defaults.map(
        (f: Filter) => f.expression
    );

    // Defines a flag indicating whether or not to render the `FilterPillLongForm` component for `filtersInput.defaults`
    // If there is an `OR` expression in `filtersInput.defaults`, we want to render a single `FilterPillLongForm` instance
    const renderLongFormDefaultsPill: boolean = defaultFilterExpressions.includes(
        FilterExpression.or
    );

    // Render a single long-form FilterPill for the entire filtersInput.defaults array
    if (renderLongFormDefaultsPill) {
        // Assembles props for the FilterPillLongForm component
        const filterPillLongFormProps: FilterPillLongFormProps = {
            label: "Defaults",
            description: "",
            filters: filtersInput.defaults,
            possibleFilters: filters.defaults,
        };

        // Render the remove button IFF props.readOnly is falsey
        if (!readOnly) {
            filterPillLongFormProps.onRemoveButtonClick = () => {
                // Defines a copy of filtersInput with and empty `defaults` array
                // This is done because when we render a long-form pill for all of `filtersInput.defaults`,
                // We want to completely remove `filtersInput.defaults` when the onRemoveButtonClick callback is invoked
                const updatedFiltersInput: FiltersInput = {
                    ...filtersInput,
                    defaults: [],
                };

                // Invokes props.setFiltersInput to update the RouteState.params.filters
                props.setFiltersInput(updatedFiltersInput);
            };
        }

        return <FilterPillLongForm {...filterPillLongFormProps} />;
    }

    // Render a single short-form FilterPill for each filter in filtersInput.defaults
    return (
        <React.Fragment>
            {filtersInput.defaults.map((f: Filter, index: number) => {
                // Finds the PossibleFilter associated with Filter `f`
                const possibleFilter:
                    | PossibleFilter
                    | undefined = getPossibleFilter({
                    filter: f,
                    possibleFilters: filters.defaults,
                });

                // Short-circuit + captureMessage if possibleFilter isn't found
                if (!possibleFilter) {
                    captureMessage(
                        "AdvancedFilterPills - DefaultPills -> no PossibleFilter found",
                        {
                            extra: {
                                fieldName: f.fieldName,
                                expression: f.expression,
                            },
                        }
                    );
                    return null;
                }

                // If f is a required filter, return FilterPillShortForm without props.onClickRemove
                if (requiredFilters.includes(f.fieldName)) {
                    return (
                        <FilterPillShortForm
                            key={`${f.fieldName}-${String(index)}`}
                            filter={f}
                            possibleFilter={possibleFilter}
                        />
                    );
                }

                // Assembles props for the FilterPillShortForm component
                const filterPillShortFormProps: FilterPillShortFormProps = {
                    filter: f,
                    possibleFilter,
                };

                // Render the remove button IFF props.readOnly is falsey
                if (!readOnly) {
                    filterPillShortFormProps.onClickRemove = () => {
                        // Defines a copy of filtersInput without the `f: Filter`
                        const updatedFiltersInput: FiltersInput = {
                            ...filtersInput,
                            defaults: removeElementFromArrayAtIndex<Filter>(
                                filtersInput.defaults,
                                index
                            ),
                        };

                        // Invokes props.setFiltersInput to update the RouteState.params.filters
                        props.setFiltersInput(updatedFiltersInput);
                    };
                }

                // Render the short-form FilterPill
                return (
                    <FilterPillShortForm
                        key={`${f.fieldName}-${String(index)}`}
                        {...filterPillShortFormProps}
                    />
                );
            })}
        </React.Fragment>
    );
}

/**
 * AdvancedPills
 * Renders Pills for `filters.advanced`
 * @param props - see `AdvancedFilterPillsProps`
 */
function AdvancedPills(props: {
    filters: Filters;
    filtersInput: FiltersInput;
    setFiltersInput: (updatedFiltersInput: FiltersInput) => void;
    readOnly: boolean;
}) {
    const { filtersInput, filters } = props;

    return (
        <React.Fragment>
            {filtersInput.advanced.map(
                (advancedFilterInput: AdvancedFilterInput, index: number) => {
                    // Finds the AdvancedFilter associated with the AdvancedFilterInput
                    const advancedFilter:
                        | AdvancedFilter
                        | undefined = filters.advanced.find(
                        (af: AdvancedFilter) => {
                            return af.key === advancedFilterInput.key;
                        }
                    );

                    // Return null if no corresponding AdvancedFilter is found
                    if (!advancedFilter) {
                        return null;
                    }

                    // Assembles props for the FilterPillLongForm component
                    const filterPillLongFormProps: FilterPillLongFormProps = {
                        label: advancedFilter.content.name,
                        description: advancedFilter.content.description,
                        filters: advancedFilterInput.filters,
                        possibleFilters: advancedFilter.filters,
                    };

                    // Render the remove button IFF props.readOnly is falsey
                    if (!props.readOnly) {
                        filterPillLongFormProps.onRemoveButtonClick = () => {
                            // Defines a copy of filtersInput without the `advancedFilterInput` in `filtersInput.advanced`
                            const updatedFiltersInput: FiltersInput = {
                                ...filtersInput,
                                advanced: removeElementFromArrayAtIndex<
                                    AdvancedFilterInput
                                >(filtersInput.advanced, index),
                            };

                            // Invokes props.setFiltersInput to update the RouteState.params.filters
                            props.setFiltersInput(updatedFiltersInput);
                        };
                    }

                    // ALWAYS render a long-form FilterPill for advancedFilterInput.filters
                    return (
                        <FilterPillLongForm
                            key={advancedFilter.key}
                            {...filterPillLongFormProps}
                        />
                    );
                }
            )}
        </React.Fragment>
    );
}

/**
 * FilterPillsBody
 * Renders the body section of the advanced filter pills component that only contains pills and doesn't include the `Active Filters` label and the `Reset all` button
 * @param props - see `AdvancedFilterPillsProps`
 */
export function FilterPillsBody(props: {
    filters: Filters;
    filtersInput: FiltersInput;
    requiredFilters: string[];
    setFiltersInput: (updatedFiltersInput: FiltersInput) => void;
    readOnly: boolean;
}) {
    const {
        filtersInput,
        requiredFilters,
        filters,
        setFiltersInput,
        readOnly,
    } = props;

    // Only render pills components if there are filters defined
    const shouldRenderPills =
        filtersInput.defaults.length > 0 || filtersInput.advanced.length > 0;

    // Short-circuits the function call if the pills should not be rendered
    if (!shouldRenderPills) {
        return null;
    }

    return (
        <div className="d-flex flex-column col">
            <div className="row d-flex align-items-center tw-gap-2">
                {/* Render Pills for `filters.defaults` */}
                <DefaultPills
                    filtersInput={filtersInput}
                    requiredFilters={requiredFilters}
                    filters={filters}
                    setFiltersInput={setFiltersInput}
                    readOnly={readOnly}
                />

                {/* Render Pills for `filters.advanced` */}
                <AdvancedPills
                    filtersInput={filtersInput}
                    filters={filters}
                    setFiltersInput={setFiltersInput}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
}

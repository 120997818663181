import { BreadcrumbNode } from "../types";
import { spendIndPaymentsState } from "./state";
import { spendState } from "../spend";
import { appFieldIndividualNode } from "../appFieldIndividual";

// // // //

export const spendIndNode: BreadcrumbNode = {
    getState: spendState,
    parent: appFieldIndividualNode,
};

export const spendIndPaymentsNode: BreadcrumbNode = {
    getState: spendIndPaymentsState,
    parent: spendIndNode,
};

import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * ModalCloseButton
 * Defines a generic button that can be used to close the <Modal /> component
 * @param props.onClick - callback used to close the modal
 */
export function ModalCloseButton(props: {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}) {
    return (
        <button
            className={classnames(
                "font-size-12-px text-uppercase font-secondary text-updated-black",
                styles.modalCloseButton
            )}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                props.onClick(e);
            }}
        >
            <i className="fa fa-fw fa-times" />
            Close
        </button>
    );
}

import gql from "graphql-tag";

/**
 * Plan represents the billing information for
 * an organization
 */
export interface Plan {
    id?: string;
    customerID?: string;
    organizationID?: string;
    inTrial?: boolean;
    isTrialOver?: boolean;
    trialEnds?: any;
    status?: string;
    __typename: "Plan";
}

// Note: Existing plan trial query doesn't request all fields
export const PLAN_FRAGMENT = gql`
    fragment PLAN_FRAGMENT on Plan {
        id
        inTrial
        isTrialOver
        trialEnds
        status
    }
`;

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { HorizontalDivider } from "@src/shared_modules/horizontal_divider";
import classnames from "classnames";
import * as React from "react";
import {
    trackClickApplyDateButton,
    trackClickExpressionSelectorButton,
} from "../../../analytics/date_input";
import { ApplyDateButton } from "./ApplyDateButton";
import { THIRTY_DAYS } from "./constants";
import { DateInputButton } from "./DateInputButton";
import { FixedDateInput } from "./FixedDateInput";
import { isDateInputDataValid } from "./isDateInputDataValid";
import { RelativeDateInput } from "./RelativeDateInput";
import { DateInputValue } from "./types";
import { UndefinedDateInput } from "./UndefinedDateInput";

// // // //

/**
 * mapExpressionToDefaultValue
 * Maps each Date FilterExpression to its corresponding default label
 */
const mapExpressionToDefaultValue = {
    [FilterExpression.adp_upe]: [THIRTY_DAYS],
    [FilterExpression.adp_pde]: [THIRTY_DAYS],
    [FilterExpression.adp_fde]: ["", ""],
    [FilterExpression.adp_ude]: [],
    [FilterExpression.adp_dde]: [],
};

/**
 * mapExpressionToLabel
 * Maps each Date FilterExpression to its corresponding human-readable label
 */
const mapExpressionToLabel = {
    [FilterExpression.adp_upe]: "Upcoming",
    [FilterExpression.adp_pde]: "Past",
    [FilterExpression.adp_fde]: "Fixed dates",
    [FilterExpression.adp_ude]: "Custom",
    [FilterExpression.adp_dde]: "Custom",
};

/**
 * DateInputExpressionSelector
 * Renders a button used to select which FilterExpression to apply
 * @param props.active - whether or not the expression is selected
 * @param props.expression - the expression associated with this button
 * @param props.availableExpressions - array of available expressions
 * @param props.onClick - callback to set DateInput.state.expression
 */
function DateInputExpressionSelector(props: {
    active: boolean;
    expression: FilterExpression;
    availableExpressions: FilterExpression[];
    onClick: (filterExpression: FilterExpression) => void;
}) {
    const { expression, availableExpressions, active, onClick } = props;

    // Return null if props.expression isn't in availableExpressions
    if (!availableExpressions.includes(expression)) {
        return null;
    }

    // Renders DateInputButton with specific props
    return (
        <DateInputButton
            active={active}
            className="d-flex flex-grow-1 justify-content-center"
            label={mapExpressionToLabel[expression]}
            onClick={() => {
                trackClickExpressionSelectorButton(expression);
                onClick(expression);
            }}
        />
    );
}

// // // //

/**
 * DateInputProps
 * @param props.value - the current value for the DateInput
 * @param props.expression - the current expression for the DateInput
 * @param props.availableExpressions - FilterExpression array dictating which expressions are supported
 * @param props.inline - whether or not the DateInput is being rendered by DateInputInline
 * @param props.className - optional className applied to the DateInput's wrapper <div>
 * @param props.onChange - callback function invoked when a change event occurs, updates value + filterExpression
 */
export interface DateInputProps {
    value: DateInputValue;
    expression: FilterExpression;
    availableExpressions: FilterExpression[];
    inline?: boolean;
    className?: string;
    onChange: (updatedVal: {
        value: DateInputValue;
        expression: FilterExpression;
    }) => void;
}

/**
 * DateInput
 * Date input for AdvancedFilters - supports Past/Future/Fixed dates
 * @param props - see `DateInputProps`
 */
export function DateInput(props: DateInputProps) {
    // Setup hook to internally store selected value
    // Sets defalut value using props.value or mapExpressionToDefaultValue
    const [value, setValue] = React.useState<DateInputValue>(() => {
        // If the current value is NOT an array, invoke props.onChange with a default value
        if (!Array.isArray(props.value)) {
            const defaultExpression =
                props.expression || props.availableExpressions[0];
            return mapExpressionToDefaultValue[defaultExpression];
        }
        return props.value;
    });

    // Setup hook to internally store selected expression
    const [expression, setExpression] = React.useState<FilterExpression>(
        props.expression || props.availableExpressions[0]
    );

    // Defines intermediary function to update expression state + clear out value when it's incompatible with expression
    function updateExpression(updatedExpression: FilterExpression) {
        // Short-circuit function if updatedExpression === expression
        if (expression === updatedExpression) {
            return;
        }

        // If switching between upcoming/past -> skip updating value
        if (
            [FilterExpression.adp_upe, FilterExpression.adp_pde].includes(
                expression
            ) &&
            [FilterExpression.adp_upe, FilterExpression.adp_pde].includes(
                updatedExpression
            )
        ) {
            // Update expression
            setExpression(updatedExpression);
            return;
        }

        // If switching to fixed -> clear value state
        if (updatedExpression === FilterExpression.adp_fde) {
            // Update value + expression
            setValue(["", ""]);
            setExpression(updatedExpression);
            return;
        }

        // If switching to undefined/undefined -> clear value state
        if (
            [FilterExpression.adp_ude, FilterExpression.adp_dde].includes(
                updatedExpression
            )
        ) {
            // Update value + expression
            setValue([]);
            setExpression(updatedExpression);
            return;
        }

        // Otherwise, we're switching to upcoming/past from fixed -> clear value state
        setValue([THIRTY_DAYS]);
        setExpression(updatedExpression);
    }

    // Setup React.useEffect for updating EXTERNAL value + expression props when INTERNAL state changes
    React.useEffect(() => {
        // Short-circuit this callback when props.inline is TRUE
        // When props.inline is TRUE, we render the ApplyDateButton component to handle props.onChange
        if (props.inline === true) {
            return;
        }

        // Only invoke props.onChange when the internal state doesn't match props
        if (expression !== props.expression || value !== props.value) {
            props.onChange({ value, expression });
        }
    }, [value, expression]);

    // Pulls availableExpressions, inline, className from props
    const { availableExpressions, inline = false, className = "" } = props;

    // Defines flag to conditionally render the RelativeDateInput component
    const renderRelativeDateInput: boolean =
        expression === FilterExpression.adp_upe ||
        expression === FilterExpression.adp_pde;

    // Defines flags to conditionally render the "Custom" section for defined/undefined
    const hasDefinedExpression: boolean = availableExpressions.includes(
        FilterExpression.adp_dde
    );
    const hasUndefinedExpression: boolean = availableExpressions.includes(
        FilterExpression.adp_ude
    );

    // Determines if either "Undefined" or "Defined" expression is the default for the "Custom" section
    let defaultExistentialExpression: FilterExpression =
        FilterExpression.adp_ude;
    if (hasDefinedExpression && !hasUndefinedExpression) {
        defaultExistentialExpression = FilterExpression.adp_dde;
    }

    return (
        <div
            className={classnames({
                [className]: className !== "",
            })}
        >
            <div className="d-flex flex-row flex-wrap px-15-px">
                <DateInputExpressionSelector
                    expression={FilterExpression.adp_upe}
                    availableExpressions={availableExpressions}
                    active={expression === FilterExpression.adp_upe}
                    onClick={updateExpression}
                />

                <DateInputExpressionSelector
                    expression={FilterExpression.adp_pde}
                    availableExpressions={availableExpressions}
                    active={expression === FilterExpression.adp_pde}
                    onClick={updateExpression}
                />

                <DateInputExpressionSelector
                    expression={FilterExpression.adp_fde}
                    availableExpressions={availableExpressions}
                    active={expression === FilterExpression.adp_fde}
                    onClick={updateExpression}
                />

                <DateInputExpressionSelector
                    expression={defaultExistentialExpression}
                    availableExpressions={availableExpressions}
                    active={expression === defaultExistentialExpression}
                    onClick={updateExpression}
                />
            </div>

            <HorizontalDivider className="mt-10-px" />

            <div className="px-15-px">
                {/* Upcoming + Past Dates */}
                {renderRelativeDateInput && (
                    <RelativeDateInput value={value} setValue={setValue} />
                )}

                {/* Fixed Date */}
                {expression === FilterExpression.adp_fde && (
                    <FixedDateInput value={value} setValue={setValue} />
                )}

                {/* Custom Date */}
                {[FilterExpression.adp_ude, FilterExpression.adp_dde].includes(
                    expression
                ) && (
                    <UndefinedDateInput
                        value={value}
                        expression={expression}
                        availableExpressions={availableExpressions}
                        setValueAndExpression={(update) => {
                            setValue(update.value);
                            setExpression(update.expression);
                        }}
                    />
                )}

                {/* NOTE - we only render ApplyDateButton when props.inline is true */}
                {inline === true && (
                    <ApplyDateButton
                        disabled={!isDateInputDataValid({ value, expression })}
                        onClick={() => {
                            trackClickApplyDateButton();
                            props.onChange({ value, expression });
                        }}
                    />
                )}
            </div>
        </div>
    );
}

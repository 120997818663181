import classnames from "classnames";
import React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListContainerProps
 * @param props.children - the React component rendered inside the container
 * @param props.label - the label in the upper right describing the container
 * @param props.className - (optional) additional className to add to the container's <div>
 * @param props.includeAndBadge - (optional) boolean flag dictating whether or not to include the `AND` badge
 */
interface FilterListContainerProps {
    children: React.ReactNode;
    label: string;
    className?: string;
    includeAndBadge?: boolean;
}

/**
 * FilterListContainer
 * Renders a bordered + labeled container around a <FilterList /> component inside the `AdvancedFilterEditor`
 * @param props - see `FilterListContainerProps`
 */
export function FilterListContainer(props: FilterListContainerProps) {
    const { className = "" } = props;

    const containerClassName = classnames({
        [className]: className !== "",
    });

    return (
        <div className={containerClassName}>
            {props.includeAndBadge && (
                <div
                    className={classnames(
                        "pt-30-px ml-30-px",
                        styles.andExpressionBadge,
                        styles.filterListContainerAndBadge
                    )}
                >
                    <span
                        className={classnames(
                            "bg-primary px-5-px py-5-px rounded d-inline-block",
                            styles.andExpressionBadgeContent
                        )}
                    >
                        <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                            And
                        </p>
                    </span>
                </div>
            )}

            <span
                className={classnames(
                    "bg-grey-dim px-5-px py-5-px",
                    styles.filterListContainerSourceBadge
                )}
            >
                <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                    {props.label}
                </p>
            </span>
            <div className="px-10-px py-10-px border-grey-dim rounded-sm">
                {props.children}
            </div>
        </div>
    );
}

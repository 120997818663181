import { DropdownSelect } from "@src/shared_modules/dropdown_select";
import {
    InputSize,
    NumberInput,
    SearchInput,
    TextInput,
} from "@src/shared_modules/input";
import { DatePicker } from "@src/shared_modules/datepicker/component";
import { captureMessage } from "@src/util/analytics";
import { getFormattedDateValue } from "@src/util/date_util";
import classnames from "classnames";
import React from "react";
import {
    DateInput,
    DateInputInline,
    DateInputValue,
    isDateFieldType,
} from "../advanced_filters/date_input";
import { isDeprecatedDateValue } from "../advanced_filters/date_input/isDeprecatedDateValue";
import {
    WeekInput,
    WeekInputInline,
    WeekInputValue,
} from "../advanced_filters/week_input";
import { isWeekFieldType } from "../advanced_filters/week_input/isWeekInputDataValid";
import { CurrencyInput } from "../../../../shared_modules/input/CurrencyInput";
import { DynamicFilterValueInput } from "./DynamicFilterValueInput";
import { FieldType, FilterExpression, PossibleFilter } from "./graphql";
import { isFilterExpressionDynamic } from "./isFilterExpressionDynamic";
import { NumberInputInline } from "../advanced_filters/number_input";
import { VendorInput } from "./vendor_input";
import { SourceSingleDropdown } from "../advanced_filters/source_single_dropdown/component";

// // // //

// Defines constant to check if `PossibleFilter.key === query`
const POSSIBLE_FILTER_QUERY_KEY: string = "query";

interface FilterFormValueInputProps {
    value: string | number | boolean | string[] | number[];
    placeholder?: string;
    size?: InputSize;
    onChange: (updatedFilterData: {
        val: string | number | boolean | string[] | number[];
        expression: FilterExpression;
    }) => void;
    selectedFilter: PossibleFilter | undefined;
    debounceTimeoutMs?: number; // (optional) - debounce user input in the NumberInput, TextInput, and SearchInput components
    hideDatepickerIcon?: boolean; // Flag indicating whether or not to hide the icon inside the `Datepicker` components rendered here
    filterExpression: FilterExpression; // Used to check whether or not the Filter whose value being edited has a dynamic FilterExpression, propagated in props.onChange
    inline?: boolean; // optional - used to determine whether or not to render DateInput or DateInputInline
}

/**
 * FilterFormValueInput
 * Renders different input elements corresponding to each FieldType
 */
export function FilterFormValueInput(props: FilterFormValueInputProps) {
    // Pulls placeholder, InputSize from props, defines defaults
    const {
        inline = false,
        selectedFilter,
        placeholder = "Value",
        size = InputSize.md,
    } = props;

    // Render disabled placeholder input if selectedFilter is undefined
    if (selectedFilter === undefined) {
        return (
            <input
                className={classnames({
                    ["form-input"]: true,
                    ["form-input-sm"]: size === InputSize.sm,
                    ["form-input-md"]: size === InputSize.md,
                    ["form-input-lg"]: size === InputSize.lg,
                })}
                placeholder="Value"
                required
                disabled
            />
        );
    }

    // Render <DynamicFilterValueInput /> component, but only
    // if props.filterExpression is defined, AND it's a dynamic filter expression
    if (
        props.filterExpression !== undefined &&
        isFilterExpressionDynamic(props.filterExpression)
    ) {
        const stringValue: string = String(props.value);
        return (
            <DynamicFilterValueInput
                size={size}
                value={stringValue}
                onChange={(updatedValue) => {
                    props.onChange({
                        val: updatedValue,
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <WeekInput /> component
    if (isWeekFieldType(selectedFilter.fieldType)) {
        // Render WeekInputInline component
        if (inline) {
            return (
                <WeekInputInline
                    placeholder={props.placeholder}
                    value={props.value as WeekInputValue} // NOTE - invalid values are handled gracefully by WeekInput
                    expression={props.filterExpression}
                    availableExpressions={selectedFilter.expressions}
                    onChange={(updatedFilterData) => {
                        props.onChange({
                            val: updatedFilterData.value,
                            expression: updatedFilterData.expression,
                        });
                    }}
                />
            );
        }

        // Render WeekInput component
        return (
            <WeekInput
                value={props.value as WeekInputValue} // NOTE - invalid values are handled gracefully by WeekInput
                expression={props.filterExpression}
                availableExpressions={selectedFilter.expressions}
                className="border-grey-border bg-white py-15-px"
                onChange={(updatedFilterData) => {
                    props.onChange({
                        val: updatedFilterData.value,
                        expression: updatedFilterData.expression,
                    });
                }}
            />
        );
    }

    // Render <DateInput /> component, but only
    // if props.selectedFilter.fieldType is advanced_date_picker OR advanced_past_date_picker
    if (isDateFieldType(selectedFilter.fieldType)) {
        // Handle deprecated date-picker values
        if (
            isDeprecatedDateValue({
                value: props.value,
                expression: props.filterExpression,
            })
        ) {
            // Capture message that a deprecated Date value has been detected
            captureMessage(
                "FilterFormValueInput - deprecated Date filter detected",
                {
                    extra: {
                        filterVal: props.value,
                        filterExpression: props.filterExpression,
                        possibleFilterKey: selectedFilter.key,
                        possibleFilterName: selectedFilter.content.name,
                    },
                }
            );

            // Returns the deprecated DatePicker component
            return (
                <DatePicker
                    size={size}
                    hideIcon={props.hideDatepickerIcon}
                    ignoreStateValue
                    date={props.value as string}
                    id={`advanced-filter-datepicker-${selectedFilter.key}`}
                    numberOfMonths={1}
                    placeholder={placeholder}
                    onChange={(value) => {
                        const formattedValue = getFormattedDateValue(value);
                        props.onChange({
                            val: formattedValue,
                            expression: props.filterExpression,
                        });
                    }}
                />
            );
        }

        // Render DateInputInline
        if (inline) {
            return (
                <DateInputInline
                    placeholder={props.placeholder}
                    value={props.value as DateInputValue} // NOTE - invalid values are handled gracefully by DateInput
                    expression={props.filterExpression}
                    availableExpressions={selectedFilter.expressions}
                    onChange={(updatedFilterData) => {
                        props.onChange({
                            val: updatedFilterData.value,
                            expression: updatedFilterData.expression,
                        });
                    }}
                />
            );
        }

        // Render non-inline DateInput component
        return (
            <DateInput
                value={props.value as DateInputValue} // NOTE - invalid values are handled gracefully by DateInput
                expression={props.filterExpression}
                availableExpressions={selectedFilter.expressions}
                className="border-grey-border bg-white py-15-px"
                onChange={(updatedFilterData) => {
                    props.onChange({
                        val: updatedFilterData.value,
                        expression: updatedFilterData.expression,
                    });
                }}
            />
        );
    }

    // <input type="currency" /> for FieldType.currency
    if (selectedFilter.fieldType === FieldType.currency) {
        const numericValue = parseInt(String(props.value), 10);
        return (
            <CurrencyInput
                size={size}
                value={numericValue}
                placeholder={placeholder}
                debounceTimeoutMs={props.debounceTimeoutMs}
                onChange={(updatedValue: number | null) => {
                    props.onChange({
                        val: Number(updatedValue),
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <input type="number" /> for FieldType.number + FieldType.integer + FieldType.float
    if (
        [FieldType.number, FieldType.float, FieldType.integer].includes(
            selectedFilter.fieldType
        )
    ) {
        let numericValue: number | null = null;
        if (typeof props.value === "number") {
            numericValue = props.value;
        } else if (typeof props.value === "string") {
            numericValue = parseInt(String(props.value), 10);
        }

        // Coerce value to integer using Math.floor(...) if selectedFilter.fieldType is FieldType.integer
        if (
            numericValue !== null &&
            !Number.isNaN(numericValue) &&
            selectedFilter.fieldType === FieldType.integer
        ) {
            numericValue = Math.floor(numericValue);
        }

        // Render NumberInputInline if inline=true
        if (inline) {
            return (
                <NumberInputInline
                    value={numericValue}
                    placeholder={placeholder}
                    integersOnly={
                        selectedFilter.fieldType === FieldType.integer
                    }
                    expression={props.filterExpression}
                    possibleExpressions={selectedFilter.expressions}
                    onChange={(update) => {
                        props.onChange({
                            val: Number(update.value),
                            expression: update.expression,
                        });
                    }}
                />
            );
        }

        return (
            <NumberInput
                size={size}
                value={numericValue}
                placeholder={placeholder}
                integersOnly={selectedFilter.fieldType === FieldType.integer}
                debounceTimeoutMs={props.debounceTimeoutMs}
                onChange={(updatedValue: number | null) => {
                    props.onChange({
                        val: Number(updatedValue),
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <input type="text" /> for FieldType.text
    if (selectedFilter.fieldType === FieldType.text) {
        const stringValue = String(props.value);

        // Return SearchInput if PossibleFilter.key === "query"
        if (selectedFilter.key === POSSIBLE_FILTER_QUERY_KEY) {
            return (
                <SearchInput
                    size={size}
                    value={stringValue}
                    placeholder={placeholder}
                    debounceTimeoutMs={props.debounceTimeoutMs}
                    onChange={(updatedValue) => {
                        props.onChange({
                            val: updatedValue,
                            expression: props.filterExpression,
                        });
                    }}
                />
            );
        }

        // Return standard TextInput
        return (
            <TextInput
                size={size}
                value={stringValue}
                placeholder={placeholder}
                debounceTimeoutMs={props.debounceTimeoutMs}
                onChange={(updatedValue) => {
                    props.onChange({
                        val: updatedValue,
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <input type="text" /> for FieldType.textArray
    if (selectedFilter.fieldType === FieldType.textArray) {
        // Update props.value if it isn't the
        // correct type expected by FieldType.textArray
        if (!Array.isArray(props.value)) {
            // Invoke props.onChange with empty array value
            props.onChange({
                val: [],
                expression: props.filterExpression,
            });

            // Return null -> invocation of props.onChange above will force a re-render
            return null;
        }

        // Defines default value to populate TextInput.props.value
        let stringValue: string = "";

        // If props.value[0] is a string, use that to populate TextInput.props.value
        if (typeof props.value[0] === "string") {
            stringValue = String(props.value[0]);
        }

        // Return standard TextInput
        // NOTE - in the future we'll update this to render an input component that
        // supports adding/removing multiple strings, rather than just a single string
        return (
            <TextInput
                size={size}
                value={stringValue}
                placeholder={placeholder}
                debounceTimeoutMs={props.debounceTimeoutMs}
                onChange={(updatedValue: string) => {
                    props.onChange({
                        val: [updatedValue],
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <DatepickerInput /> for FieldType.date_picker & FieldType.date_range
    if (
        [FieldType.date_picker, FieldType.date_range].includes(
            selectedFilter.fieldType
        )
    ) {
        // Determines input value for <Datepicker />
        const dateInputValue: string =
            props.value === undefined ? "" : String(props.value);

        return (
            <DatePicker
                size={size}
                hideIcon={props.hideDatepickerIcon}
                ignoreStateValue
                date={dateInputValue}
                id={`advanced-filter-datepicker-${selectedFilter.key}`}
                numberOfMonths={1}
                placeholder={placeholder}
                onChange={(value) => {
                    const formattedValue = getFormattedDateValue(value);
                    props.onChange({
                        val: formattedValue,
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <DropdownSelect /> for FieldType.dropdown & FieldType.boolean_dropdown
    if (
        selectedFilter.fieldType === FieldType.dropdown ||
        selectedFilter.fieldType === FieldType.boolean_dropdown
    ) {
        // Ensures presence of PossibleFilter.possibleValues
        // If a non-array value was returned from the API, captureMessage and return null
        if (!Array.isArray(selectedFilter.possibleValues)) {
            captureMessage(
                "FilterFormValueInput - dropdown PossibleFilter has non-array value assigned to possibleValues property",
                {
                    extra: {
                        possibleFilterKey: selectedFilter.key,
                        possibleFilterName: selectedFilter.content.name,
                    },
                }
            );
            return null;
        }

        return (
            <DropdownSelect
                size={size}
                placeholder={placeholder}
                value={props.value}
                options={selectedFilter.possibleValues}
                onChange={(updatedValue) => {
                    props.onChange({
                        val: updatedValue,
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <DropdownSelect /> for FieldType.dropdown
    if (selectedFilter.fieldType === FieldType.vendor) {
        return (
            <VendorInput
                size={size}
                value={Number(props.value)}
                placeholder={placeholder}
                onChange={(updatedValue) => {
                    props.onChange({
                        val: updatedValue,
                        expression: props.filterExpression,
                    });
                }}
            />
        );
    }

    // Render <SourceSingleDropdown /> for Fieldtype.source_single_dropdown
    if (selectedFilter.fieldType === FieldType.source_single_dropdown) {
        return (
            <SourceSingleDropdown
                value={props.value as string}
                placeholder={placeholder}
                possibleValues={selectedFilter.possibleValues}
                onChange={(updatedValue) => {
                    props.onChange({
                        val: updatedValue,
                        expression: props.filterExpression,
                    });
                }}
                size={size}
            />
        );
    }

    // Return null if `selectedFilter.fieldType` doesn't match one of the availabe input cases above
    // NOTE - this is here to prevent an invariant violation that occurs when this component doesn't return anything
    return null;
}

import classnames from "classnames";
import * as React from "react";
import { Filters, FiltersInput } from "../advanced_filter_editor";
import { FilterPillsBody } from "./FilterPillsBody";
import styles from "./styles.module.css";

// // // //

/**
 * ReadOnlyFilterPills
 * Renders the read-only version of advanced filter pills
 * @param props - see `AdvancedFilterPillsProps`
 * @param props.className - (optional) class name to apply to the wrapper div
 */
export function ReadOnlyFilterPills(props: {
    filters: Filters;
    filtersInput: FiltersInput;
    className: string;
}) {
    return (
        <div
            className={classnames(
                "row d-flex flex-row",
                styles.advancedFilterPillsContainer,
                props.className
            )}
        >
            <FilterPillsBody
                {...props}
                requiredFilters={[]}
                setFiltersInput={() => {}}
                readOnly
            />
        </div>
    );
}

import { UPDATE_SYNC_ACTION, UpdateSyncAction } from "./action";

// SyncingState is the state of the syncing process
export interface SyncingState {
    syncing: boolean;
    // timeExpired r
    timeExpired: boolean;
}

// defaultState is the default state of the syncing process
const defaultState: SyncingState = {
    syncing: true,
    timeExpired: false,
};

// updateSync updates the syncing status of an oauth service
function updateSync(_: SyncingState, action: UpdateSyncAction): SyncingState {
    return {
        syncing: action.syncing,
        timeExpired: action.timeExpired,
    };
}

// integrationSuccess represents the integration success reducer which houses whether the integration is currently syncing
export function integrationSuccess(
    state: SyncingState = defaultState,
    action: any
): SyncingState {
    switch (action.type) {
        case UPDATE_SYNC_ACTION:
            return updateSync(state, action);
        default:
            return state;
    }
}

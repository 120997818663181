export enum AppEvents {
    stateUpdated = "Application State Updated",
    appTileClicked = "Application Tile Clicked",
    appCreatedFromModal = "Application Created From Modal",
}

export enum CoreEvents {
    pageLoad = "Page Loaded",
    linkClicked = "Page Link Clicked",
    modalLoaded = "Modal Loaded",
    modalClosed = "Modal Closed",
}

export enum DashboardEvents {
    filterApplied = "Filter Applied",
}

import * as React from "react";

// // // //

/**
 * FullscreenModal
 * Renders a full-screen modal
 * @param {FullscreenModalProps} props - see FullscreenModalProps
 */
export function FullscreenModal(props: { children: React.ReactNode }) {
    return (
        <section className="tw-bg-white tw-min-h-screen">
            <div className="tw-fixed tw-h-auto tw-min-h-screen tw-w-full tw-top-0 tw-left-0 tw-p-5 tw-z-10 bg-background-main row no-gutters tw-items-center">
                <div className="tw-p-10 tw-rounded-lg tw-bg-white tw-shadow-lg border-gray-border tw-my-0 tw-mx-auto tw-max-w-[465px]">
                    {props.children}
                </div>
            </div>
        </section>
    );
}

import { DropdownSelect } from "@src/shared_modules/dropdown_select";
import { InputSize, NumberInput } from "@src/shared_modules/input";
import { InfoTooltip } from "@src/shared_modules/info_tooltip";
import * as React from "react";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import styles from "./styles.module.css";

// // // //

// Sign + day constants
export const SIGN_PLUS: string = "+";
export const SIGN_MINUS: string = "-";
const DEFAULT_DAYS_VALUE: number = 30;

// Defines regex for validating dynamic filter values
const VALIDATOR_REGEX: RegExp = /^[\+ \-] \d* day$/;

// // // //

/**
 * parseDynamicFilterValue
 * Parses the value of a dynamic filter into sign + numDays
 * Includes support for partial values (i.e. sign is present, but no days value)
 * @param value - the value that's being parsed into a sign + days pair
 */
export function parseDynamicFilterValue(value: string): [string, number] {
    // Mutable variables to store the results from this function
    let parsedSignValue: string = SIGN_PLUS;
    let parsedDaysValue: number = DEFAULT_DAYS_VALUE;

    // Ensure the value param is valid
    // If not, return the defaults
    if (!validateDynamicFilterValue(value)) {
        return [parsedSignValue, parsedDaysValue];
    }

    // Parses the sign from the value param - should always be the leading character
    const signChar: string | undefined = value[0];

    // Ensures signChar is defined _and_ is either SIGN_PLUS or SIGN_MINUS
    // If so, assign the value to the parsedSignValue variable
    if ([SIGN_PLUS, SIGN_MINUS].includes(signChar)) {
        parsedSignValue = signChar;
    }

    // Parses `days` from the value param
    const daysValue: string | undefined = value.split(" ")[1];

    // Ensures daysValue is defined _and_ is a valid integer
    // If so, assign the value to the parsedDaysValue variable
    const numericDaysValue: number = parseInt(daysValue, 10);
    if (daysValue !== undefined && Number.isInteger(numericDaysValue)) {
        parsedDaysValue = numericDaysValue;
    }

    // Returns the parsed values for sign + days
    return [parsedSignValue, parsedDaysValue];
}

// // // //

/**
 * serializeDynamicFilterValue
 * Accepts sign + days and returns a formatted dynamic Filter value string
 * @param props.sign - the `sign` value
 * @param props.days - the `days` value
 */
export function serializeDynamicFilterValue(props: {
    sign: string;
    days: number;
}): string {
    // Returns the formatted string
    return `${props.sign} ${String(props.days)} day`;
}

// // // //

/**
 * validateDynamicFilterValue
 * Validates the dynamic filter value
 * @param value - the value that we're validating
 */
export function validateDynamicFilterValue(value: string): boolean {
    // Matches against VALIDATOR_REGEX
    const match: RegExpMatchArray | null = value.match(VALIDATOR_REGEX);

    // If match is null -> return false
    if (match === null) {
        return false;
    }

    // Otherwise -> return true
    return true;
}

// // // //

/**
 * DynamicFilterValueInputProps
 * @param size - optional - the size applied to the <DropdownSelect /> components rendered here. Defaults to `md`.
 * @param value - the value for the dynamic Filter
 * @param onChange - callback to update the dynamic Filter's val
 */
interface DynamicFilterValueInputProps {
    size?: InputSize;
    value: string;
    onChange: (updatedValue: string) => void;
}

/**
 * DynamicFilterValueInput
 * Renders an input to set/update values for Filter instances with dynamic expressions (ddlt, ddlte, ddgt, ddgte)
 * @param props - see `DynamicFilterValueInputProps`
 */
export function DynamicFilterValueInput(props: DynamicFilterValueInputProps) {
    const { size = InputSize.md } = props;
    // Parses sign, days vaules from props.value
    const [initialSignValue, initialDaysValue] = parseDynamicFilterValue(
        props.value
    );

    // Defines hooks to store sign, daysValue data
    const [signValue, setSignValue] = React.useState<string>(initialSignValue);
    const [daysValue, setDaysValue] = React.useState<number>(initialDaysValue);

    // Re-sets the internally used `signValue` + `daysValue` in the event that `props.value` changes
    // NOTE - the [props.value] array passed in as the second parameter to `useEffect` is a dependency for
    // the callback - this ensures that the callback function is ONLY invoked when `userQuery` changes
    React.useEffect(() => {
        // Parses signValue + daysValue from props.value
        // Returns default values if props.value isn't valid
        const [parsedSignValue, parsedDaysValue] = parseDynamicFilterValue(
            props.value
        );

        // Sets the values internal to this component
        setSignValue(parsedSignValue);
        setDaysValue(parsedDaysValue);

        // If props.value is NOT valid -> invoke props.onChange with a valid
        // string built from defaults. This is done to ensure that
        // there's _always_ an acceptable value emitted by this component
        if (!validateDynamicFilterValue(props.value)) {
            // Defines updatedFilterValue
            const updatedFilterValue: string = serializeDynamicFilterValue({
                sign: parsedSignValue,
                days: parsedDaysValue,
            });

            // Invokes props.onChange with the updatedFilterValue
            props.onChange(updatedFilterValue);
        }
    }, [props.value]);

    // Renders the component tree
    return (
        <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-column">
                <DropdownSelect
                    size={size}
                    className={styles.dynamicFilterSignDropdown}
                    value={signValue}
                    onChange={(updatedSign: string) => {
                        // Defines updatedFilterValue
                        const updatedFilterValue: string = serializeDynamicFilterValue(
                            {
                                sign: updatedSign,
                                days: daysValue,
                            }
                        );

                        // Do one last check to ensure that updatedFilterValue is valid
                        // Prevents props.onChange invocation with invalid values
                        if (!validateDynamicFilterValue(updatedFilterValue)) {
                            return;
                        }

                        // Invokes props.onChange with the updatedFilterValue
                        props.onChange(updatedFilterValue);
                    }}
                    options={[
                        { humanValue: SIGN_MINUS, internalValue: SIGN_MINUS },
                        { humanValue: SIGN_PLUS, internalValue: SIGN_PLUS },
                    ]}
                />
            </div>
            <div className="d-flex flex-column mx-10-px">
                <NumberInput
                    size={size}
                    value={daysValue}
                    placeholder={"Days"}
                    onChange={(updatedDaysValue: number | null) => {
                        // Ensures value is a positive integer
                        // Prevent the user from entering negative / decimal values
                        const sanitizedNumberValue: number = Math.abs(
                            Math.ceil(Number(updatedDaysValue))
                        );

                        // Defines updatedFilterValue
                        const updatedFilterValue: string = serializeDynamicFilterValue(
                            {
                                sign: signValue,
                                days: sanitizedNumberValue,
                            }
                        );

                        // Do one last check to ensure that updatedFilterValue is valid
                        // Prevents props.onChange invocation with invalid values
                        if (!validateDynamicFilterValue(updatedFilterValue)) {
                            return;
                        }

                        // Invokes props.onChange with the updatedFilterValue
                        props.onChange(updatedFilterValue);
                    }}
                />
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center">
                    <p className="font-secondary font-size-14-px">Days</p>
                    <FeatureContent uniqueKey="dynamic-filter-value-input">
                        {({ feature, loading }) => {
                            if (loading) {
                                return null;
                            }

                            return <InfoTooltip>{feature.header}</InfoTooltip>;
                        }}
                    </FeatureContent>
                </div>
            </div>
        </div>
    );
}

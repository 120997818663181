import { OrganizationFieldType } from "@src/requests/custom_fields";

/**
 * isOrgFieldFilterable
 * Returns a boolean dictating whether or not an OrgField is filterable
 */
export function isOrgFieldFilterable(props: {
    fieldType: OrganizationFieldType;
}): boolean {
    // ONLY checklist fields are *not* filterable
    return props.fieldType !== "checklist";
}

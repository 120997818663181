import { BreadcrumbNode } from "../types";
import { secureState, vendorConnectedAppListingState } from "./state";
import { appFieldIndividualNode } from "../appFieldIndividual/node";

// // // //

export const secureNode: BreadcrumbNode = {
    getState: secureState,
    parent: appFieldIndividualNode,
};

export const vendorConnectedAppListingNode: BreadcrumbNode = {
    getState: vendorConnectedAppListingState,
    parent: secureNode,
};

import { trackCreateOrgSavedView } from "@src/analytics/org_saved_views";
import {
    Filters,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import * as React from "react";
import { OrgSavedViewForm } from "./OrgSavedViewForm";
import { OrgSavedViewModal } from "./OrgSavedViewModal";
import {
    CreateOrgSavedViewParams,
    OrgSavedView,
    OrgSavedViewsApi,
} from "./types";

// // // //

/**
 * NewOrgSavedViewModalProps
 * @param filters - See AdvancedFiltersProvider.props.children
 * @param filtersInput - Currently applied FiltersInput
 * @param disabled - See OrgSavedViewForm.props.disabled
 * @param orgSavedViewsApi - See OrgSavedViewsApiProvider
 * @param onClose - callback to close the modal
 */
interface NewOrgSavedViewModalProps {
    filters: Filters;
    filtersInput: FiltersInput;
    disabled: boolean;
    onClose: () => void;
    orgSavedViewsApi: OrgSavedViewsApi;
}

/**
 * NewOrgSavedViewModal
 * Renders the OrgSavedViewForm + OrgSavedViewModal for creating a *new* OrgSavedView
 * @param props - see NewOrgSavedViewModalProps
 */
export function NewOrgSavedViewModal(props: NewOrgSavedViewModalProps) {
    const { disabled, orgSavedViewsApi } = props;
    return (
        <OrgSavedViewModal
            title=" Save View"
            filters={props.filters}
            filtersInput={props.filtersInput}
            onClose={() => props.onClose()}
        >
            <div className="mt-15-px">
                <OrgSavedViewForm
                    name={""}
                    description={""}
                    disabled={disabled}
                    tags={[]}
                    vendorID={orgSavedViewsApi.vendorID}
                    onSubmit={(formData: CreateOrgSavedViewParams) => {
                        // Track createOrgSavedView event
                        trackCreateOrgSavedView();

                        // Invoke props.createOrgSavedView with formData
                        orgSavedViewsApi
                            .createOrgSavedView(formData)
                            .then((orgSavedView: OrgSavedView | null) => {
                                // Close the modal if props.createOrgSavedView resolved successfully
                                if (orgSavedView !== null) {
                                    props.onClose();
                                }
                            });
                    }}
                />
            </div>
        </OrgSavedViewModal>
    );
}

import { track } from "@src/analytics/track";

// // // //

enum WeekInputEvents {
    openWeekInputInline = "WeekInputInline - Datepicker Opened",
    clickApplyDateButton = "WeekInput - ApplyDateButton Clicked",
}

/**
 * trackOpenWeekInputInline
 * Tracks when the user opens the `WeekInputInline` datepicker
 */
export function trackOpenWeekInputInline() {
    track(WeekInputEvents.openWeekInputInline, {});
}

/**
 * trackClickApplyDateButton
 * Tracks when the user clicks the `ApplyDateButton`
 */
export function trackClickApplyDateButton() {
    track(WeekInputEvents.clickApplyDateButton, {});
}

/**
 * trackClickWeekInputButton
 * Tracks when the user clicks the `ApplyDateButton`
 */
export function trackClickWeekInputButton(label: string) {
    track(WeekInputEvents.clickApplyDateButton, { label });
}

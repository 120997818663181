import {
    CursorInput,
    CursorPaginationInput,
    OffsetPaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { CursorDirection } from "../advanced_filter_editor/graphql";

// // // //

/**
 * isCursorInput
 * Accepts "any" and returns a type predicate indicating
 * whether or not the cursorInput parameter is a valid CusorInput instance.
 * @param cursorInput - the object that's being verified
 */
export function isCursorInput(cursorInput: any): cursorInput is CursorInput {
    // Return false if undefined
    if (cursorInput === undefined) {
        return false;
    }

    // Return true if null
    // NOTE - null is _technically_ valid, since a cursor input may
    // be null when loading the first page of results
    if (cursorInput === null) {
        return true;
    }

    // Verify presence of
    return (
        // Ensure cursorInput is a valid non-Array object
        typeof cursorInput === "object" &&
        Array.isArray(cursorInput) === false &&
        // Verify "cursor.direction"
        cursorInput.direction !== undefined &&
        [CursorDirection.prev, CursorDirection.next].includes(
            cursorInput.direction
        ) &&
        // Verify "cursor.position"
        cursorInput.position !== undefined &&
        typeof cursorInput.position === "string"
    );
}
/**
 * isCursorPaginationInput
 * Accepts a paginationInput and returns a type predicate indicating whether
 * or not the paginationInput parameters is a valid CursorPaginationInput instance.
 * Performs a shallow check on the `paginationInput` parameter to ensure validity.
 * @param paginationInput - the object that's being verified
 */
export function isCursorPaginationInput(
    paginationInput: OffsetPaginationInput | CursorPaginationInput
): paginationInput is CursorPaginationInput {
    // Coerces paginationInput type as CursorPaginationInput
    // Prevents TS errors when accessing `paginationInput.cursor`, since cursor is undefined on the standard PaginationInput.
    const value = paginationInput as CursorPaginationInput;

    return (
        // Verify "page"
        value.page !== undefined &&
        typeof value.page === "number" &&
        // Verify "itemsPerPage"
        value.itemsPerPage !== undefined &&
        typeof value.itemsPerPage === "number" &&
        // Verify "sort"
        value.sort !== undefined &&
        typeof value.sort === "string" &&
        // Verify "direction"
        value.direction !== undefined &&
        typeof value.direction === "string" &&
        ["", "asc", "desc"].includes(value.direction) &&
        // Verify "cursor"
        isCursorInput(value.cursorInput)
    );
}

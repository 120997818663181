import { loadMaintenanceBanner } from "@src/requests/content";
import classnames from "classnames";
import * as React from "react";

// // // //

// we define the "hidden" persisted state as a variable here bec we don't want
// to spin up a reducer for a single variable that shouldn't change from page to page
let hidden = false;

/**
 * MaintenanceBannerLayout
 * Presentation component for the MaintenanceBannerState
 * @param props - see MaintenanceBannerState
 */
export function MaintenanceBannerLayout(props: {
    title: string;
    message: string;
    onHide: () => void;
}) {
    const { title, message } = props;

    return (
        <div
            className={classnames(
                "tw-relative",
                "tw-flex tw-items-center tw-justify-between",
                "tw-z-10",
                "tw-text-white bg-primary font-secondary font-size-14-px ",
                "py-10-px px-20-px"
            )}
        >
            <div className="d-flex align-items-center">
                <strong className="font-secondary-heavy mr-10-px">
                    {title}
                </strong>
                <span>{message}</span>
            </div>

            <div className="d-flex">
                <button
                    className="btn-link text-white"
                    onClick={() => {
                        props.onHide();
                    }}
                >
                    <i className="fa fa-times" />
                </button>
            </div>
        </div>
    );
}

export interface MaintenanceBannerState {
    title: string; // A short title for the banner
    message: string; // A short message for the banner
    visible: boolean; // Whether or not the MaintenanceBannerComponent is visible
}

// MaintenanceBannerComponent
// Displays a Maintenance at the top of the page
// This component's content is managed by `MaintenanceBanner` in Contentful
// https://app.contentful.com/spaces/r94iek8mbu1h/content_types/maintenanceBanner
export class MaintenanceBannerComponent extends React.Component<
    {},
    MaintenanceBannerState & { hidden: boolean }
> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            title: "",
            visible: false,
            hidden,
        };
    }

    // Fetch the `MaintenanceBanner` content from Contentful
    public componentDidMount() {
        const self = this;
        loadMaintenanceBanner().then((response: MaintenanceBannerState) =>
            self.setState({
                ...response,
                hidden: self.state.hidden,
            })
        );
    }

    public render() {
        if (this.state.visible === false || this.state.hidden === true) {
            return null;
        }

        // Defines a flag indicating whether or not to render the CTA link in the banner
        const { title, message } = this.state;

        // Render MaintenanceBannerLayout
        return (
            <MaintenanceBannerLayout
                title={title}
                message={message}
                onHide={() => {
                    // Set the "hidden" state to "true" and hide the banner
                    hidden = true;
                    this.setState({
                        ...this.state,
                        hidden: true,
                    });
                }}
            />
        );
    }
}

import gql from "graphql-tag";
import { TokenStatus } from "./TokenStatus";

// // // //

/**
 * InstanceTokenStatus represnts the authentication type for an integration
 */
export interface InstanceTokenStatus {
    status: TokenStatus;
    statusReason: string;
    __typename: "InstanceTokenStatus";
}

export const INSTANCE_TOKEN_STATUS_FRAGMENT = gql`
    fragment INSTANCE_TOKEN_STATUS_FRAGMENT on InstanceTokenStatus {
        status
        statusReason
    }
`;

import classnames from "classnames";
import React from "react";
import { Tooltip, TooltipPlacement } from "../../../../shared_modules/tooltip";
import { PossibleFilter } from "./graphql";
import styles from "./styles.module.css";

// // // //

/**
 * PossibleFilterContent.tsx
 * Renders a PossibleFilter's name + description tooltip (IFF description is defined)
 * @param props.possibleFilter - the `PossibleFilter` whose content we're rendering
 */
export function PossibleFilterContent(props: {
    possibleFilter: PossibleFilter;
    tooltipPosition?: TooltipPlacement;
}) {
    const { possibleFilter, tooltipPosition = "top" } = props;

    // Defines <p> for content.name
    const name = (
        <p className="font-size-12-px text-primary font-secondary-heavy whitespace-nowrap cursor-default">
            {possibleFilter.content.name}
        </p>
    );

    // Return name if content.description isn't defined
    if (!possibleFilter.content.description) {
        return name;
    }

    // Return name wrapped in TopHoverTooltip
    return (
        <Tooltip
            placement={tooltipPosition}
            tooltipContent={
                <div
                    className={classnames(
                        "tw-p-3",
                        styles.possibleFilterTooltip
                    )}
                >
                    <p
                        className={`font-size-12-px text-black font-secondary ${styles.possibleFilterTooltipContent}`}
                    >
                        {possibleFilter.content.description}
                    </p>
                </div>
            }
        >
            {({ setTriggerRef }) => (
                <p
                    ref={setTriggerRef}
                    className="font-size-12-px text-primary font-secondary-heavy whitespace-nowrap cursor-default"
                >
                    {possibleFilter.content.name}
                </p>
            )}
        </Tooltip>
    );
}

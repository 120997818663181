import React from "react";

export function Warning(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            x="0"
            y="0"
            fill="#fdb249"
            viewBox="0 0 16 16"
        >
            <path d="M15.216 13.529L8.882 1.654a1 1 0 00-1.765 0L.784 13.529A1 1 0 001.667 15h12.667a1 1 0 00.882-1.471zM8 13a1 1 0 110-2 1 1 0 010 2zm1-3H7V6h2v4z"></path>
        </svg>
    );
}

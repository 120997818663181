import * as React from "react";
import { connect } from "react-redux";
import { buildAddToasterAction } from "./actions";
import { GlobalToasterProps } from "./component";

// // // //

/**
 * WithToasterProps
 * Props passed to a component that imeplements the `withToaster`
 */
export interface WithToasterProps {
    addToaster: (toaster: GlobalToasterProps) => void;
}

/**
 * mapDispatchToProps
 * Maps the AddToasterAction dispatch function to `props.addToaster`
 */
function mapDispatchToProps(dispatch): WithToasterProps {
    return {
        addToaster: (toaster: GlobalToasterProps) => {
            dispatch(buildAddToasterAction(toaster));
        },
    };
}

/**
 * withToaster
 * Renders a Component with the `addToaster` function injected into its props
 * @param Component
 */
export function withToaster<T extends any>(
    Component: React.ComponentType<T & WithToasterProps>
): React.ComponentType<T> {
    // Returns the component with props.addToaster
    function ComponentWithDispatch(props: T & WithToasterProps) {
        return <Component {...props} addToaster={props.addToaster} />;
    }

    // Connects ComponentWithDispatch to Redux
    // @ts-ignore
    return connect(null, mapDispatchToProps)(ComponentWithDispatch);
}

import {
    Filter,
    FilterExpression,
    PaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import {
    Column,
    ColumnSortDirection,
} from "@src/modules/advanced_filters/components/advanced_filters";
import { TableCellTypes } from "@src/schema/TableCellType";

// // // //

export const ID_FIELD_NAME = "id";

export const EXCLUDE_ARCHIVED_FILTER: Filter = {
    fieldName: "is_archived",
    expression: FilterExpression.eq,
    val: false,
    filters: [],
};

// Columns for Report Listing
export const REPORT_NAME_COLUMN_KEY = "report_name";
export const SYSTEM_OF_RECORD_COLUMN_KEY = "system_of_record";
export const CREATED_AT_COLUMN_KEY = "created_at";
export const DESCRIPTION_COLUMN_KEY = "description";

// Columns for Report Individual
export const APPLICATION_NAME_COLUMN_KEY = "application_name";
export const VENDOR_ID_COLUMN_KEY = "vendor_id";
export const INTEGRATION_INSTANCE_COLUMN_KEY = "integration_instance";
export const TERMINATED_USER_COUNT_COLUMN_KEY = "terminated_user_count";

// Columns for Report Results
export const USER_NAME_COLUMN_KEY = "name";
export const EMAIL_COLUMN_KEY = "email";
export const LAST_ACTIVE_AT_COLUMN_KEY = "last_active_at";

/**
 * Define default pagination for ReportAdvancedFilters
 */
export const REPORT_LIST_DEFAULT_PAGINATION: PaginationInput = {
    ...DEFAULT_PAGINATION,
    sort: "name",
    direction: "desc",
};

/**
 * REPORT_ACTIONS_COLUMN
 * Frontend-only column for `Actions` columns on the Report Listing + ReportIndividual pages
 */
export const ACTIONS_COLUMN: Column = {
    key: "actions-column",
    content: {
        name: "Actions",
        description: "",
    },
    sortable: false,
    visibleByDefault: true,
    type: TableCellTypes.text,
    rowDataKeys: [],
    sortedByDefault: false,
    defaultSortDirection: ColumnSortDirection.none,
};

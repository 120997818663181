import { webConfig } from "@src/config";
import { request } from "@src/util/request_util";

// // // //

/**
 * IntercomIdentifyResponse
 */
export interface IntercomIdentifyResponse {
    /**
     * Whether or not request has succeeded
     */
    success: boolean;
    /**
     * Intercom User hash
     */
    hash: string;
    /**
     * Success or failure message from API response
     */
    message: string;
}

/**
 * identifyIntercomUser
 * Calls next api intercom-identity endpoint to retrieve hashed value of Intercom User
 */
export function identifyIntercomUser(props: {
    userEmail: string;
}): Promise<IntercomIdentifyResponse> {
    // Define the current HTTP/HTTPS protocol
    // NOTE - this is necessary because the appHost env variable doesn't include http/https prefix
    const protocol: string = window.location.protocol;

    return new Promise((resolve) => {
        request(
            `${protocol}//${webConfig.appHost}/api/intercom-identify?userID=${props.userEmail}`,
            {
                method: "GET",
            },
            webConfig.appHost
        )
            .then(async (res: Response) => {
                const resp: IntercomIdentifyResponse = await res.json();
                return resolve(resp);
            })
            .catch((_e) => {
                // Resolve promise with default erroneous value
                resolve({
                    success: false,
                    hash: "",
                    message: "Unknown API error",
                });
            });
    });
}

import classnames from "classnames";
import * as React from "react";
import { InputSize } from "./types";
import { useDebouncedInput } from "./useDebouncedInputHook";

// // // //

/**
 * TextAreaInputProps
 * `value` - the value modeled by the `<textarea />` element
 * `placeholder` - (optional) value for by the `<textarea />`'s placeholder attribute
 * `className` - (optional) additional className to attach to the `<textarea />` element
 * `disabled` - (optional) dictates whether or not the `<textarea />` element is disabled
 * `required` - (optional) dictates whether or not the `<textarea />` element is required
 * `size` - (optional) prop to dictate the sizing CSS of the `<textarea />` element. Defaults to `InputSize.md`
 * `debounceTimeoutMs` - (optional) dictates the amount of time (in ms) to debounce user input.
 * `onChange` - function invoked when a change event is detected on the `<textarea />` element. Accepts `string` parameter values only.
 * `focusOnRender` - (optional) force the user's cursor into the <textarea /> on render
 * `onKeyDown` - (optional) callback fired on `keyDown` event
 * `onFocus` - (optional) callback fired on `focus` event
 * `onBlur` - (optional) callback fired on `blur` event
 * `rows` - (optional) number of rows to pass to the `rows` attribute of the `<textarea />` element
 * `columns` - (optional) number of columns to pass to the `cols` attribute of the `<textarea />` element
 */
interface TextAreaInputProps {
    value: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    required?: boolean;
    size?: InputSize;
    debounceTimeoutMs?: number;
    focusOnRender?: boolean;
    onChange: (updatedVal: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<any>) => void;
    onFocus?: (e: React.FocusEvent<any>) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    rows?: number;
    columns?: number;
}

/**
 * TextAreaInput
 * Renders a multiline <textarea /> for editing string values
 * @param props - see `TextAreaInputProps`
 */
export function TextAreaInput(props: TextAreaInputProps) {
    // Track the current inputValue internally to this component
    const [inputValue, setInputValue] = React.useState<string>(props.value);

    // Defines ref for <textarea /> component
    const textareaRef = React.useRef(null);

    // Focus input on render, if props.focusOnRender
    React.useEffect(() => {
        if (!props.focusOnRender) {
            return;
        }

        // Short-circuit if textarea is null (hasn't been rendered yet)
        if (textareaRef === null) {
            return;
        }

        // Focus the textarea
        textareaRef.current.focus();
    }, []);

    // Re-sets the internally used `inputValue` in the event that `props.value` changes
    // This prevents an occurrence where `inputValue` is not updated to the latest value passed as `props.value`
    // when this input's value is being controlled by its parent component
    React.useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    // Defines a function to invoke props.onChange with the useDebouncedInput hook
    const setValueFunction = useDebouncedInput<string>({
        debounceTimeoutMs: props.debounceTimeoutMs,
        onChange: props.onChange,
    });

    // Pulls `size` prop, defaults to `md`
    const { size = InputSize.md, className = "" } = props;

    return (
        <textarea
            ref={textareaRef}
            placeholder={props.placeholder}
            value={inputValue}
            disabled={props.disabled}
            required={props.required}
            className={classnames(
                "tw-resize-y", // Resize Vertical
                "tw-border-4 tw-outline-none border-grey", // Border
                "font-secondary text-grey-mid", // Font
                "tw-p-[12px]", // Default padding
                // Input sm
                {
                    ["font-size-12-px line-height-19-px py-5-px px-10-px"]:
                        size === InputSize.sm,
                },
                // Input md
                {
                    ["font-size-14-px line-height-24-px py-5-px"]:
                        size === InputSize.md,
                },
                // Input lg
                {
                    ["font-size-16-px line-height-24-px px-15-px py-10-px"]:
                        size === InputSize.lg,
                },
                { [className]: className !== "" }
            )}
            onKeyDown={props.onKeyDown}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
                const updatedValue: string = e.currentTarget.value;
                setInputValue(updatedValue);
                setValueFunction(updatedValue);
            }}
            rows={props.rows}
            cols={props.columns}
        />
    );
}

import { publicRoutes } from "@src/routes";
import { CloseButton } from "@src/shared_modules/close_button";
import { Anchor, RouteState, withRouter } from "@src/shared_modules/router";
import { UserState, withUser } from "@src/shared_modules/user";
import { FeatureFlagCC, FeatureNames } from "@src/shared_modules/feature_flag";
import { captureMessage } from "@src/util/analytics";
import classnames from "classnames";
import moment from "moment";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";

import { closedNotificationBar } from "../../analytics/dashboard";
import { genMoment } from "../../util/date_util";
import {
    PLAN_TRIAL_QUERY,
    PlanTrialQueryResponse,
    PlanTrialQueryVariables,
} from "./graphql";
import styles from "./styles.module.css";
import { TrialOverModal } from "./trial_over_modal";

// // // //

// we define the show message as a variable here bec we don't want to spin up a reducer for a single variable that shouldn't change from page to page
let showMessage = true;

// Countdown generates the count down sticky nav at the top of the page - it transitions itself in and out smoothly
class Countdown extends React.Component<
    PlanTrialQueryResponse,
    { showMessage: boolean }
> {
    constructor(props: PlanTrialQueryResponse) {
        super(props);
        this.state = {
            showMessage,
        };
    }

    public render() {
        const { trialEnds } = this.props.billing.plan;
        if (this.state.showMessage === false) {
            return null;
        }

        return (
            <div
                className={classnames(
                    "w-100 bg-white py-10-px px-20-px",
                    styles.countDownWrapper
                )}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center text-updated-black font-secondary font-size-13-px">
                        <p className="font-secondary-heavy">
                            You have{" "}
                            <span className="text-success">
                                {trialEnds != null
                                    ? genMoment(trialEnds).diff(
                                          moment(),
                                          "days"
                                      )
                                    : ""}{" "}
                                days remaining
                            </span>{" "}
                            during your free trial.
                        </p>
                        <p>
                            &nbsp; Make the process seamless in controlling your
                            software system
                        </p>
                    </div>

                    <div className="d-flex pl-50-px">
                        <div className="d-flex align-items-center pr-25-px">
                            <Anchor
                                href={publicRoutes.scheduleDemo}
                                className="btn-sm btn-primary font-secondary-bold font-size-14-px py-5-px"
                                target="_blank"
                            >
                                Schedule a Demo
                            </Anchor>
                        </div>

                        <CloseButton
                            onRemove={() => {
                                showMessage = false;
                                this.setState({
                                    showMessage: false,
                                });
                                // track that the user closed the notification bar
                                closedNotificationBar("sticky-plan-trial");
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

// TrialNotification handles notifying the user that they're in a trial - if the user is in the trial we show them a count down
// if they're trial has expired we show them the notification that their trial is over
export function TrialNotification(props: {
    data: PlanTrialQueryResponse;
    error?: Error;
}) {
    // if it's loading just return
    if (props.data == null || props.error != null) {
        return null;
    }
    if (props.data.billing == null || props.data.billing.plan == null) {
        captureMessage("didn't receive billing plan from gelt");
        return null;
    }
    const { inTrial, status, isTrialOver } = props.data.billing.plan;
    // if the user is in the trial and the trial isn't over
    if (inTrial) {
        return <Countdown {...props.data} />;
    }
    if (
        (status === "cancelled" ||
            status === "past_due" ||
            status === "trial_expired") &&
        isTrialOver
    ) {
        // if the status is cancelled and past due and the trial is over means  that the user hasn't converted to paid - if it's
        // unpaid it could be bec the invoice was marked as unpaid bec there cc is declined and we let ourselves handle that for now
        return <TrialOverModal />;
    }
    return null;
}

// PlanQuery issues the plan query - we have this as part of the nav component so that it can run whenever the page is mounted
// and show the stick trial, but also so that when a user comes to the site they are immediately signed up for a trial
function PlanQuery(props: {
    user: UserState;
    route: RouteState;
    className?: string;
}) {
    const variables: PlanTrialQueryVariables = {
        filters: {
            organizationID: props.user.organizationID,
            userID: props.user.id,
        },
    };

    return (
        <Query query={PLAN_TRIAL_QUERY} variables={variables}>
            {({ loading, error, data }: QueryResult) => {
                // Return null if loading
                if (loading) {
                    return null;
                    // Render null if GQL API error is detected
                }
                if (error) {
                    return null;
                }

                return (
                    // If this feature is enabled, then we are _disabling_ the rendering of the
                    // trial notification modal. When the feature is enabled and the variation is false,
                    // we render the modal, whereas the `true` variation bypasses rendering.
                    <FeatureFlagCC
                        variation={false}
                        featureName={FeatureNames.disableTrialOverNotification}
                    >
                        {/*  Return TrialNotification component */}
                        <TrialNotification data={data} />
                    </FeatureFlagCC>
                );
            }}
        </Query>
    );
}

// export the plan query
export const TrialNotificationQuery = withRouter(withUser(PlanQuery));

import { routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState } from "../types";

// // // //

export const connectedAppsOverviewState = (
    _route: RouteState,
    _featureFlags: Record<string, boolean>
): BreadcrumbState => {
    let pathname: string = routes.connectedAppsListing;

    return {
        linkProps: {
            href: {
                pathname,
                query: {},
            },
            as: pathname,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: pathname,
            },
        },
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: {
                label: "Secure",
            },
        },
    };
};

export const connectedAppsListingState = (
    _route: RouteState,
    _featureFlags: Record<string, boolean>
): BreadcrumbState => {
    return {
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Connected Apps" },
        },
    };
};

import React from "react";
import ReactDom from "react-dom";

// // // //

/**
 * TooltipPortal
 * Implements a React Portal to render tooltips in a component outside the parent component's DOM tree
 * This allows tooltips to be positioned _anywhere_ on the screen without needing to worry about Z-Index or Overflow CSS conflicts/collisions
 * **IMPORTANT** - a <div> with the ID `tooltip-container` **MUST** be present in both Storybook and Next.js for this to work correctly.
 * Doc: https://reactjs.org/docs/portals.html
 * TODO - ensure #tooltip-container is present in both Next.js + Storybook
 */
export function TooltipPortal(props: { children: React.ReactNode }) {
    // Get the DOM node where the portal will render props.children
    const domNode = document.getElementById("tooltip-container");

    // Return null if dom element isn't present
    // (this is mostly a fail-safe for Storybook)
    if (!domNode) {
        return null;
    }

    // Render props.children inside the React Portal
    return ReactDom.createPortal(props.children, domNode);
}

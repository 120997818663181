import { BillingFilters } from "@src/schema/BillingFilters";
import { Plan, PLAN_FRAGMENT } from "@src/schema/Plan";
import gql from "graphql-tag";

// // // //

/**
 * PlanTrialQueryResponse represents the response from PLAN_TRIAL_QUERY
 */
export interface PlanTrialQueryResponse {
    billing: {
        plan: Plan;
        __typename: "Billing";
    };
}

/**
 * PlanTrialQueryVariables represents the input GQL variables
 * to PLAN_TRIAL_QUERY
 */
export interface PlanTrialQueryVariables {
    filters: BillingFilters;
}

// PLAN_TRIAL_QUERY
// GQL Query to fetch the user's plan
export const PLAN_TRIAL_QUERY = gql`
    query PLAN_TRIAL_QUERY($filters: BillingFilters!) {
        billing {
            plan(filters: $filters) {
                ...PLAN_FRAGMENT
            }
        }
    }
    ${PLAN_FRAGMENT}
`;

import { track } from "@src/analytics/track";
import { FilterExpression } from "../components/advanced_filter_editor/graphql";

// // // //

enum DateInputEvents {
    clickExpressionSelectorButton = "DateInput - ExpressionSelector Button Clicked",
    openDateInputInline = "DateInputInline - Datepicker Opened",
    clickApplyDateButton = "DateInput - ApplyDateButton Clicked",
    clickRelativeDateInputButton = "RelativeDateInput - DateInputButton Clicked",
    clickUndefinedDateInputButton = "UndefinedDateInput - Undefined Button Clicked",
}

/**
 * trackClickExpressionSelectorButton
 * Tracks when the user clicks the `DateInputExpressionSelector` button
 */
export function trackClickExpressionSelectorButton(
    expression: FilterExpression
) {
    track(DateInputEvents.clickExpressionSelectorButton, { expression });
}

/**
 * trackOpenDateInputInline
 * Tracks when the user opens the `DateInputInline` datepicker
 */
export function trackOpenDateInputInline() {
    track(DateInputEvents.openDateInputInline, {});
}

/**
 * trackClickApplyDateButton
 * Tracks when the user clicks the `ApplyDateButton`
 */
export function trackClickApplyDateButton() {
    track(DateInputEvents.clickApplyDateButton, {});
}

/**
 * trackClickRelativeDateInputButton
 * Tracks when the user clicks the `DateInputButton` in `RelativeDateInput`
 */
export function trackClickRelativeDateInputButton(preset: string) {
    track(DateInputEvents.clickRelativeDateInputButton, { preset });
}

/**
 * trackClickUndefinedDateInputButton
 * Tracks when the user clicks the `undefined` button in `UndefinedDateInput`
 */
export function trackClickUndefinedDateInputButton() {
    track(DateInputEvents.clickUndefinedDateInputButton, {});
}

/**
 * trackClickDefinedDateInputButton
 * Tracks when the user clicks the `defined` button in `DefinedDateInput`
 */
export function trackClickDefinedDateInputButton() {
    track(DateInputEvents.clickUndefinedDateInputButton, {});
}

import { TextAreaInput, TextInput } from "@src/shared_modules/input";
import { VendorFetcher } from "@src/shared_modules/related_vendors";
import * as React from "react";
import ImageLoader from "../../../../shared_modules/image_loader";
import { OrgTagsInput } from "../org_tags";
import styles from "./styles.module.css";
import { CreateOrgSavedViewParams } from "./types";

// // // //

/**
 * RequiredAlert
 * Renders field validation error alert
 */
function RequiredAlert() {
    return (
        <p className="text-alert font-secondary font-size-12-px">
            This field is required
        </p>
    );
}

/**
 * OrgSavedViewFormProps
 * @param name - initial value for the saved view name
 * @param description - initial value for the saved view description
 * @param tags - initial value for the saved view tags
 * @param disabled - whether or not the submit button should be disabled
 * @param vendorID - See OrgSavedViewsApi.vendorID
 * @param onSubmit - a callback executed when the save button is clicked
 */
interface OrgSavedViewFormProps {
    name: string;
    description: string;
    tags: string[];
    disabled: boolean;
    vendorID: number | null;
    onSubmit: (formData: CreateOrgSavedViewParams) => void;
}

/**
 * OrgSavedViewForm
 * Renders the form that is used for editing a saved view
 * @props - see `OrgSavedViewFormProps`
 */
export function OrgSavedViewForm(props: OrgSavedViewFormProps) {
    // Manages local state for OrgSavedViewForm
    // Pulls initial values from props
    const [name, setName] = React.useState<string>(props.name);
    const [description, setDescription] = React.useState<string>(
        props.description
    );
    const [tags, setTags] = React.useState<string[]>(props.tags);

    // Manages form validation errors state
    const [nameIsInvalid, setNameIsInvalid] = React.useState<boolean>(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = React.useState<
        boolean
    >(false);
    const hasErrors = nameIsInvalid || descriptionIsInvalid;

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();

                // Validates the required fields
                setNameIsInvalid(name === "");
                setDescriptionIsInvalid(description === "");

                // Short-circuits the function execution if any of the required fields is empty
                if (name === "" || description === "") {
                    return;
                }

                // Invoke props.onSubmit with name + description + tags
                props.onSubmit({
                    name,
                    description,
                    tags,
                });
            }}
        >
            {/* Render Vendor name + logo, when available */}
            {props.vendorID !== null && (
                <VendorFetcher
                    vendorID={props.vendorID}
                    includeRelatedVendors={false}
                >
                    {({ vendor, loading }) => {
                        // If loading, return an ellipsis until the Vendor loads
                        if (loading || vendor === null) {
                            return null;
                        }

                        // Render the Vendor name
                        return (
                            <div className="d-flex flex-column pb-20-px">
                                <p className="font-secondary-bold text-updated-black font-size-14-px">
                                    Application
                                </p>
                                <div className="d-flex flex-row align-items-center mt-10-px">
                                    <ImageLoader
                                        alt={`${vendor.name} Logo`}
                                        src={vendor.logo}
                                        className={styles.vendorLogo}
                                    />
                                    <p className="font-secondary text-updated-black font-size-14-px ml-5-px">
                                        {vendor.name}
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                </VendorFetcher>
            )}

            <div className="d-flex align-items-center justify-content-between pb-10-px">
                <p className="font-secondary-bold text-updated-black font-size-14-px">
                    Name
                </p>
                {nameIsInvalid && <RequiredAlert />}
            </div>
            <TextInput value={name} onChange={setName} placeholder="Name" />

            <div className="d-flex align-items-center justify-content-between pt-20-px pb-10-px">
                <p className="font-secondary-bold text-updated-black font-size-14-px">
                    Description
                </p>
                {descriptionIsInvalid && <RequiredAlert />}
            </div>
            <TextAreaInput
                value={description}
                onChange={setDescription}
                placeholder="Description"
            />

            <div className="d-flex flex-column py-20-px">
                <p className="font-secondary-bold text-updated-black font-size-14-px pb-10-px">
                    Tags
                </p>
                <OrgTagsInput value={tags} onChange={setTags} />
            </div>

            <button
                disabled={props.disabled}
                className="btn btn-md btn-primary font-secondary-bold font-size-14-px"
            >
                Save + Apply View
            </button>

            {hasErrors && (
                <p className="font-secondary text-alert font-size-12-px text-center mt-20-px p-10-px tw-bg-alert-very-light">
                    Error saving this filter. Please see areas labeled in red
                    and resubmit.
                </p>
            )}
        </form>
    );
}

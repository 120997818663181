import { ColumnSetting } from "./types";

// // // //

/**
 * sortColumnSettings
 * Sorts an array of ColumnSetting[] data based on stickyLeft
 * @param props.columnSettings - the ColumnSetting[] being sorted
 */
export function sortColumnSettings(props: {
    columnSettings: ColumnSetting[];
}): ColumnSetting[] {
    return props.columnSettings.sort(
        (a: ColumnSetting, b: ColumnSetting): number => {
            // Sort by stickyLeft
            if (a.stickyLeft && a.stickyLeft === b.stickyLeft) {
                return 0;
            }

            // Return A before B
            if (a.stickyLeft && !b.stickyLeft) {
                return -1;
            }

            // Return B before A
            if (!a.stickyLeft && b.stickyLeft) {
                return 1;
            }

            // Should anything not be caught by the above, return 0
            return 0;
        }
    );
}

import { buildUrlWithParams, UrlParamsValue } from "@src/shared_modules/router";
import omit from "lodash.omit";
import { APPLIED_ORG_SAVED_VIEW_PARAM } from "./constants";

// // // //

/**
 * buildOrgSavedViewUrl
 * Returns a `string` representing the URL to apply that `OrgSavedView`
 * @param props.location - the location for the URL
 * @param props.orgSavedViewID - the ID of the `OrgSavedView` that's being applied
 * @param props.routeParams - value from RouteState.params
 */
export function buildOrgSavedViewUrl(props: {
    location: string;
    orgSavedViewID: string;
    routeParams: UrlParamsValue;
}) {
    const { orgSavedViewID, routeParams, location } = props;

    // Defines URL to current location with the OrgSavedView applied in RouteState.params
    return buildUrlWithParams({
        location,
        params: {
            ...omit(routeParams, ["filters"]), // Removes RouteState.params.filters
            [APPLIED_ORG_SAVED_VIEW_PARAM]: orgSavedViewID,
        },
    });
}

import { RouteState, withRouter } from "@src/shared_modules/router";
import { ModalPageCC } from "@src/shared_modules/modal_page";
import { buildQsFromObject } from "@src/util/route";
import omit from "lodash.omit";
import * as React from "react";
import { MANAGE_COLUMNS_PARAM } from "./constants";
import styles from "./styles.module.css";

// // // //

/**
 * ManageColumnsModalProps
 * @param children - React.ReactNode rendered inside the ModalPageCC
 */
interface ManageColumnsModalProps {
    route: RouteState;
    children: React.ReactNode;
}

/**
 * ManageColumnsModal
 * Renders a ModalPageCC for the `ManageColumns` component
 * Conditionally shows/hides itself based on the presence of RouteState.params[MANAGE_COLUMNS_PARAM]
 * @param props - see ManageColumnsModalProps
 */
export function ManageColumnsModalLayout(props: ManageColumnsModalProps) {
    const { route } = props;

    // Return null if `RouteState.params[MANAGE_COLUMNS_PARAM] is falsey
    if (!route.params[MANAGE_COLUMNS_PARAM]) {
        return null;
    }

    // Constructs link to current page without `RouteState.params[MANAGE_COLUMNS_PARAM]`
    const updatedParams = omit(route.params, [MANAGE_COLUMNS_PARAM]);
    const defaultRoute: string = `${route.location}${buildQsFromObject(
        updatedParams
    )}`;

    // NOTE - we pass `null` into `background` beacuse the "background" page is already rendered
    // This is because the ManageColumnsModal isn't a uniquely routable page - it's only shown/hidden based on `RouteState.params[MANAGE_COLUMNS_PARAM]`
    return (
        <ModalPageCC
            background={null}
            defaultRoute={defaultRoute}
            pathname={route.nextPathname}
            className={styles.manageColumnsModal}
            rightDrawer
        >
            <React.Fragment>{props.children}</React.Fragment>
        </ModalPageCC>
    );
}

export const ManageColumnsModal = withRouter(ManageColumnsModalLayout);

import { ApolloError } from "apollo-client";
import * as React from "react";
import { GraphQlErrorHandler } from "./component";

// // // //

/**
 * UseGraphQLErrorHandlerProps
 * @param removable - (optional) boolean indicating that the alert / toaster can be removed by the user (defaults to `true`)
 * @param componentType (optional) - Determines the component rendered by GraphQLErrorHandler (either <Alert /> or <Toaster />)
 */
export interface UseGraphQLErrorHandlerProps {
    componentType?: "alert" | "toaster";
    removable?: boolean;
}

/**
 * useGraphQLErrorHandler
 * Defines a custom React Hook that provides
 * - a function that wraps a `GraphQlErrorHandler` component to render an `ApolloError` (from error state)
 * - a function that can be used to set/unset the error state
 * @param props - see `UseGraphQLErrorHandlerProps`
 */
export function useGraphQLErrorHandler(
    props?: UseGraphQLErrorHandlerProps
): [
    (error: null | ApolloError | string) => void,
    React.JSXElementConstructor<{}>
] {
    // React hook for storing error state
    const [error, setError] = React.useState<ApolloError | null | string>(null);

    // Defines props object if none are supplied
    const defaultProps: UseGraphQLErrorHandlerProps = props || {};

    // Pulls default values from defaultProps
    const { componentType = "toaster", removable = true } = defaultProps;

    // Defines the error component
    function ErrorComponent() {
        // Renders the GraphQLErrorHandler with props
        return (
            <GraphQlErrorHandler
                error={error}
                removable={removable}
                componentType={componentType}
                onRemove={() => setError(null)}
            />
        );
    }

    // Returns setError + ErrorComponent
    return [setError, ErrorComponent];
}

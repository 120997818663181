import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { AppField } from "@src/modules/vendors/pages/vendor_listing_v1/types";
import * as React from "react";
import { Query } from "react-apollo";
import {
    APP_FIELDS_QUERY,
    AppFieldFilters,
    AppFieldQueryResponse,
    AppFieldQueryVariables,
    buildAppFieldFilters,
} from "./graphql";

// // // //

/**
 * AppFieldFetcherLayout
 * Fetches the organization fields to render
 */
interface AppFieldFetcherProps {
    filters: AppFieldFilters;
    children: (childProps: {
        loading: boolean;
        appFields: AppField[];
    }) => React.ReactNode;
}

/**
 * AppFieldFetcherLayout
 * fethches organization field for a given org and passes them to the children
 * @param props - see AppFieldFetcherLayout
 */
export function AppFieldFetcherLayout(
    props: AppFieldFetcherProps & { user: UserState }
) {
    const { user, filters } = props;

    return (
        <Query<AppFieldQueryResponse, AppFieldQueryVariables>
            query={APP_FIELDS_QUERY}
            variables={buildAppFieldFilters({
                orgID: user.organizationID,
                vendorID: filters.vendorID,
                orgFieldIDList: filters.orgFieldIDList,
            })}
        >
            {({ loading, error, data }) => {
                // Safely pull data from API response + handle empty response
                let appFields = data?.spend?.applicationFields || null;
                if (appFields === null) {
                    appFields = [];
                }

                // Renders error handler + props.children
                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            appFields,
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export const AppFieldFetcher: React.ComponentType<AppFieldFetcherProps> = withUser(
    AppFieldFetcherLayout
);

// // // //

type QsValue = string | number | boolean;
export interface QueryParams {
    [key: string]: QsValue | QsValue[];
}

/**
 * Builds a query string from an object. This allows us to easily meet
 * the way tornado constructs routes.
 *
 * @param {QueryParams} [obj={}]
 * @returns {string}
 */
export function buildQsFromObject(obj: QueryParams = {}): string {
    // Initialize empty string array - 'buffer'
    const buffer: string[] = [];

    // Composes a UTF-8 encoded query string given a variable name and value, and adds it to the buffer array.
    function updateBuffer(prop: string, v: QsValue): void {
        let val: QsValue = v;

        // We encode prop because it can contain special characters (e.g. for custom field names)
        const encodedProp = encodeURIComponent(prop);

        // If value is string, encode string to UTF-8
        if (typeof val === "string") {
            val = encodeURIComponent(val);
        }

        // If value is boolean or number, just convert to string
        if (typeof val === "boolean" || typeof val === "number") {
            val = val.toString();
        }

        // Add string to buffer array
        buffer.push(`${encodedProp}=${val}`);
    }

    Object.keys(obj).forEach((prop: string) => {
        const val: QsValue | QsValue[] = obj[prop];

        // If key's value is an array, map through each item and add string to the buffer array
        if (Array.isArray(val)) {
            val.map((v: QsValue) => updateBuffer(prop, v));
        } else {
            updateBuffer(prop, val);
        }
    });

    return `?${buffer.join("&")}`;
}

import { useSelector } from "react-redux";
import { UserState } from "./reducer";

// // // //

/**
 * useUserState
 * Hook to inject UserState into a component
 */
export function useUserState() {
    const userState: UserState = useSelector(
        (state: { user: UserState }) => state.user
    );
    return userState;
}

import { webConfig } from "@src/config";
import { request } from "../util/request_util";

// // // //

interface EventProps {
    [key: string]: any;
}

export interface Dimensions {
    width: number;
    height: number;
}

export interface NemoBody {
    name: string;
    props: EventProps;
    deviceHeight: number;
    deviceWidth: number;
    eventTime: number;
    url: string;
    referrer: string;
}

function body(
    eventName: string,
    eventProps: EventProps,
    dimensions: Dimensions,
    url: string,
    referrer: string
): NemoBody {
    const eventTime: number = Date.now();
    const props: EventProps = {
        ...eventProps,
    };
    return {
        name: eventName,
        props,
        eventTime,
        deviceHeight: dimensions.height,
        deviceWidth: dimensions.width,
        url,
        referrer,
    };
}

function saveEvent(
    route: string,
    eventName: string,
    eventProps: EventProps,
    dimensions: Dimensions,
    url: string,
    referrer: string
): Promise<EventAPI> {
    const b: NemoBody = body(eventName, eventProps, dimensions, url, referrer);
    return request(
        route,
        {
            body: JSON.stringify(b),
            method: "POST",
        },
        webConfig.nemoHost
    ).then((response) => response.json());
}

export function saveInternalEvent(
    eventName: string,
    eventProps: EventProps,
    dimensions: Dimensions,
    url: string,
    referrer: string
) {
    const route: string = "event/internal";
    return saveEvent(route, eventName, eventProps, dimensions, url, referrer);
}

interface EventAPI {
    logged: boolean;
}

export function nemoTrack(
    eventName: string,
    eventProps: EventProps
): Promise<EventAPI> {
    const dimensions: Dimensions = {
        height: window.innerHeight,
        width: window.innerWidth,
    };

    const url: string = window.location.href;
    return saveInternalEvent(
        eventName,
        eventProps,
        dimensions,
        url,
        document.referrer
    );
}

import { webConfig } from "@src/config";
import { RBACRole } from "@src/schema/RBACRole";
import { UserApi } from "@src/shared_modules/user/types";

/**
 * Initializes the Pendo Analytics integration
 * Includes additional checks to ensure that window.Intercom is defined
 * before initializing Pendo. This needs to be done so Pendo can integrate correctly with Intercom.
 * @param user - The current user
 * @param userHash - The current user from intercomIdentify
 */

export function initPendo(user: UserApi, userHash: string) {
    // Short-circuit if `pendo` global can't be located
    if (!("pendo" in window)) {
        return;
    }

    // Defines environmentSuffix to distinguish users + orgs between stage + production environments
    const environmentSuffix: string =
        webConfig.appEnv === "production" ? "prod" : "stage";

    // Isolate the current user's RBACRole (defaults to "none")
    const rbacRole: RBACRole | undefined = user.assignedRoles[0];
    let rbacRoleName: string = "none";
    if (rbacRole !== undefined) {
        rbacRoleName = rbacRole.name;
    }

    // Initialize Pendo Analytics with the current user's data
    // NOTE - ony use Strings, Numbers, or Bools for value types in `visitor` + `account`
    const { pendo } = window;
    pendo.initialize({
        apiKey: webConfig.pendoAnalyticsKey,
        excludeTitle: true,
        visitor: {
            id: `${user.id}-${environmentSuffix}`,
            saas_mgmt_user_id: `${user.id}-${environmentSuffix}`,
            saas_mgmt_user_role: rbacRoleName,
            saas_mgmt_user_created_at: user.createdAt,
            user_hash: userHash,
        },
        account: {
            id: user.organization.domain,
            saas_mgmt_org_id: `${user.organizationID}-${environmentSuffix}`,
            saas_mgmt_org_domain: user.organization.domain,
        },
    });
}

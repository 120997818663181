import { purchaseOrderVendorListingNode } from "../purchaseOrderVendorListing";
import { BreadcrumbNode } from "../types";
import { purchaseOrderVendorDetailsState } from "./state";

// // // //

export const purchaseOrderVendorDetailsNode: BreadcrumbNode = {
    getState: purchaseOrderVendorDetailsState,
    parent: purchaseOrderVendorListingNode,
};

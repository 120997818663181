import { parseQsToObject } from "@src/util/route";
import { getLocationAndQsFromLink } from "@src/util/route/getLocationAndQsFromLink_util";
import memoize from "lodash.memoize";
import { useRouter } from "next/router";
import { RouteState } from "./component";

// // // //

// Defines memoized versions of getLocationAndQsFromLink & parseQsToObject
const getLocationAndQsFromLinkMemo = memoize(getLocationAndQsFromLink);
const parseQsToObjectMemo = memoize(parseQsToObject);

/**
 * useRouteState
 * Hook to inject RouteState into a component
 */
export function useRouteState() {
    const router = useRouter();

    // Pulls location and QS from router.asPath
    // @ts-ignore
    const [loc, qs] = getLocationAndQsFromLinkMemo(router.asPath);

    // Parses query string to object
    const params = parseQsToObjectMemo(qs);

    // Assembles router passed in as `props.route`
    const route: RouteState = {
        qs,
        fullUrl: router.asPath,
        params,
        nextPathname: router.pathname,
        location: loc,
        pathParams: router.query,
        updateRouteAction: (href: string, pathname?: string) => {
            // Updates router history with pathname + href
            // This is typically done when updating a filter on a list/individual page
            if (pathname) {
                router.push(pathname, href);
                return;
            }

            // Updates router history for non-dynamic routes
            router.push(href, href);
        },
    };

    return route;
}

import { fetchExport } from "@src/requests/export";
import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import classnames from "classnames";
import download from "downloadjs";
import moment from "moment";
import * as React from "react";

/**
 * ExportToCSVButtonProps are the button props to export to a csv
 */
interface ExportToCSVButtonProps {
    // query is the graphql query to send - not this should be string rather than a graphql tag
    query: string;
    // variables are the graphql variables to send
    variables: Object;
    // filenamePrefix is the prefix to use for the filename
    filenamePrefix: string;
    // className is an optional className that will be appended to the <button> element
    // Note that this prop can likely be removed once the ListHamburger dropdown has been fully implemented
    className?: string;
    // buttonClassName - overrides the default `className` applied to `<button />`
    buttonClassName?: string;
    // hideIcon - boolean - dictates whether or not the icon is rendered (defaults to true)
    showIcon?: boolean;
    // buttonText - custom text for the button
    buttonText?: string;
    // children to render inside of the button
    children?: JSX.Element;
}

export function ExportLayout(props: ExportToCSVButtonProps & WithToasterProps) {
    const filename = `${props.filenamePrefix}-${moment()}-export.csv`;
    const {
        showIcon = true,
        className = "",
        buttonClassName = "d-flex align-items-center white-light-grey-black-sml-round-button font-weight-normal",
        buttonText = "Export to CSV",
        children,
    } = props;

    return (
        <button
            onClick={(e) => {
                e.preventDefault();

                // Shows "Starting" Toaster
                // NOTE - the 15s duration here will be overridden by the follow-up
                // invocations of props.addToaster for success + error states
                props.addToaster({
                    text: "Exporting CSV...",
                    type: "primary",
                    duration: 15, // 15s duration
                });

                // fetch the csv to download and then generate a download
                fetchExport({
                    query: props.query,
                    variables: props.variables,
                })
                    .then((blob) => {
                        download(blob, filename, "text/csv");
                        // Shows Success Toaster
                        props.addToaster({
                            text: "CSV exported successfully",
                            type: "success",
                        });
                    })
                    .catch((resp: { code: number; error: string }) => {
                        // Shows error Toaster
                        props.addToaster({
                            text: resp.error,
                            type: "danger",
                        });
                    });
            }}
            type="button"
            className={classnames(className, buttonClassName)}
        >
            {!children && (
                <React.Fragment>
                    {showIcon && (
                        <i className="fa fa-fw fa-file-excel-o mr-5-px" />
                    )}
                    {buttonText}
                </React.Fragment>
            )}

            {children}
        </button>
    );
}

// Composes using withToaster HOC
export default withToaster(ExportLayout);

import classnames from "classnames";
import * as React from "react";

// we need to initialize react-dates bec of how the lib works: see https://github.com/airbnb/react-dates
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { InputSize } from "@src/shared_modules/input";
import { genNullMoment } from "@src/util/date_util";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import styles from "./styles.module.css";

// // // //

// DatePickerInputIcons renders the icons inside the date picker input
function DatePickerInputIcons() {
    return (
        <div className="tw-flex tw-items-center">
            <div className="pr-10-px">
                <Icon
                    type={IconTypes.Field_Date_Picker}
                    className={styles.datePickerIcon}
                />
            </div>
            <div className={styles.datePickerTriangle} />
        </div>
    );
}

// DatePickerProps represents the props that should be passed to the DatePicker component
// NOTE - the `ignoreStateValue` prop exists to quietly address an issue with the default behavior in this component
// Specifically, it bypasses the default behavior of populating the datepicker with the latest value in this.state,
// rather than updating dynamically as props.date changes. This fixes an issue that crops
// up when the `date` prop changes, but the datepicker UI doesn't update to reflcet the change.
interface DatePickerProps {
    date?: string;
    id: string;
    size?: InputSize;
    hideIcon?: boolean; // Boolean indicating whether or not we should hide the icon displayed in the DatePicker
    ignoreStateValue?: boolean;
    numberOfMonths?: number; // The number of months to display (defaults to 2),
    placeholder?: string; // Placeholder text for the input (defaults to "Select a date..."),
    openDirection?: "up" | "down"; // Direction datepicker widget opens - must be 'up' or 'down',
    onChange: (date: string) => void;
}

// DatePickerState represents the state of the DatePicker component
interface DatePickerState {
    focus: boolean;
    date: moment.Moment | null;
}

// DatePicker renders the date picker
export class DatePicker extends React.Component<
    DatePickerProps,
    DatePickerState
> {
    constructor(props: DatePickerProps) {
        super(props);

        this.state = {
            focus: false,
            date: genNullMoment(props.date || null),
        };

        this.onChange = this.onChange.bind(this);
    }

    public onChange(date: moment.Moment | null) {
        this.setState({
            date,
        });

        let dateVal = "";
        if (date != null) {
            dateVal = date.toISOString();
        }
        // the lib will return null when a user is typing in a date, until they finish typing in a valid date
        this.props.onChange(dateVal);
    }

    public render() {
        // Assembles Datepicker className prop
        // NOTE - these CSS rules come from global-datepicker-styles.css
        const datePickerClassName = classnames("datePickerOverride", {
            ["datePickerOverrideSm"]: this.props.size === InputSize.sm,
            ["datePickerOverrideMd"]: this.props.size === InputSize.md,
        });

        // Checks props.ignoreStateValue
        // If ignoreStateValue, we pass this.props.date instead of this.state.date into the datepicker
        let datepickerValue: moment.Moment | null = this.props.ignoreStateValue
            ? genNullMoment(this.props.date || null)
            : this.state.date;

        // Gracefully handle invalid value passed in for props.date
        if (datepickerValue && !datepickerValue.isValid()) {
            datepickerValue = null;
        }

        // Determines `numberOfMonths` prop (defaults to 2)
        const {
            numberOfMonths = 2,
            placeholder = "Select a date...",
            openDirection = "down",
            hideIcon = false,
        } = this.props;

        // Defines customInputIconProp
        let customInputIconProp: React.ReactNode | undefined = (
            <DatePickerInputIcons />
        );

        // Re-defines customInputIconProp as `undefined` if `props.hideIcon` is true
        if (hideIcon) {
            customInputIconProp = undefined;
        }

        return (
            <div className={datePickerClassName}>
                <SingleDatePicker
                    date={datepickerValue}
                    id={this.props.id}
                    placeholder={placeholder}
                    openDirection={openDirection}
                    numberOfMonths={numberOfMonths}
                    onDateChange={this.onChange}
                    focused={this.state.focus}
                    onFocusChange={(focused: { focused: boolean }) => {
                        // once the date is updated the onFocusChange will also be called indicating that it shouldn't be focused anymore
                        this.setState({ focus: focused.focused });
                    }}
                    hideKeyboardShortcutsPanel
                    enableOutsideDays
                    isOutsideRange={() => false}
                    onClose={() => {
                        // on close we want to turn on the focus (for example if a user clicked on the page)
                        this.setState({ focus: false });
                    }}
                    keepOpenOnDateSelect={false}
                    customInputIcon={customInputIconProp}
                    inputIconPosition="after"
                />
            </div>
        );
    }
}

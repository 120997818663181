import {
    buildUrlWithParams,
    compressParam,
    UrlParamsValue,
} from "@src/shared_modules/router";
import cloneDeep from "lodash.clonedeep";
import {
    COMPRESSED_COLUMN_SETTINGS_PARAM,
    MANAGE_COLUMNS_PARAM,
} from "./constants";
import { ColumnSetting } from "./types";

// // // //

/**
 * getURLWithColumnSettings
 * Creates a URL-friendly representation of the ColummSettings state
 * @param props.location - the location for the URL
 * @param props.params - the object that's being turned into a query string (using buildQsFromObject)
 * @param props.columnSettings - the ColumnSettings[] being applied to the URL
 */
export function getURLWithColumnSettings(props: {
    location: string;
    params: UrlParamsValue;
    columnSettings: ColumnSetting[];
}): string {
    const { location, params, columnSettings } = props;

    // Makes a fresh copy of the params object (we don't want to edit the `RouteState` directly)
    const updatedParams: UrlParamsValue = cloneDeep(params);

    // Removes MANAGE_COLUMNS_PARAM
    // This is done to ensure that applying this URL closes the ManageColumnsModal component
    delete updatedParams[MANAGE_COLUMNS_PARAM];

    // Sets params[COMPRESSED_COLUMN_SETTINGS_PARAM] value
    updatedParams[COMPRESSED_COLUMN_SETTINGS_PARAM] = compressParam(
        JSON.stringify(columnSettings)
    );

    // Returns the complete URL with encoded params
    return buildUrlWithParams({ location, params: updatedParams });
}

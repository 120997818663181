import { webConfig } from "@src/config";
import * as LDClient from "launchdarkly-js-client-sdk";

// // // //

// Initialize ldClient singleton
// NOTE - a non-null value is assigned in initLdClient below
export let ldClient: LDClient.LDClient | null = null; // eslint-disable-line import/no-mutable-exports

// // // //

/**
 * initLdClient
 * Initializes LaunchDarkly client and assigns to ldClient singleton
 */
export function initLdClient(props: {
    userID: number;
    organizationID: number;
}): Promise<void> {
    const { userID, organizationID } = props;

    // Short-circuit invocation if ldClient has already been initialized
    if (ldClient !== null) {
        return;
    }

    // Define the LDContext with User's ID + organizationID
    const context: LDClient.LDContext = {
        key: userID.toString(),
        custom: {
            organizationID: organizationID,
        },
    };

    // Wrap the ldClient "ready" callback in a promise
    return new Promise((resolve) => {
        // Initialize LDClient
        ldClient = LDClient.initialize(webConfig.launchDarklyKey, context);

        // Wait for the client to be ready and resolve the Promise
        ldClient.on("ready", () => {
            resolve();
        });
    });
}

import * as React from "react";
// we need to initialize react-dates bec of how the lib works: see https://github.com/airbnb/react-dates
import { trackFilterApplied } from "@src/analytics/base";
import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { formatMoment } from "@src/util/date_util";
import { updateParams } from "@src/util/route";
import moment from "moment";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { QueryParams } from "../../util/route/updateParams_util";

// // // //

interface OwnProps {
    // name is the query key
    name: string;
    // defaultStart is the default date to use to start
    defaultStart?: moment.Moment | null;
    defaultEnd?: moment.Moment;
}

interface FilterState {
    // focusedInput represents the focused input on the page
    focusedInput: string | null;
}

// updateDateParam updates the parameters based on the start date, the end date and the query param key
function updateDateParam(
    startDate: string | null,
    endDate: string | null,
    key: string,
    params: QueryParams
) {
    const values: string[] = [];

    values.push(`start:${startDate || "null"}`);
    values.push(`end:${endDate || "null"}`);
    const [updatedParams] = updateParams({
        params,
        key,
        val: values,
        replace: true,
    });

    return updatedParams;
}

// getParamDate gets the moment date of a query param based on a prefix - "start" or "end"
export function getParamDate(
    params: QueryParams,
    key: string,
    prefix: "start" | "end",
    defaultValue?: moment.Moment | null
): moment.Moment | null {
    const queryValue = params[key];
    if (typeof queryValue === "undefined") {
        return defaultValue || null;
    }
    const values = Array.isArray(queryValue) ? queryValue : [queryValue];
    let value: moment.Moment | null = defaultValue || null;
    values.forEach((val) => {
        if (typeof val !== "string") {
            return;
        }
        // check if the value starts with a given prefix. we are not using statrsWith() here because it is not supported in ie
        if (val.lastIndexOf(`${prefix}:`, 0) !== 0) {
            return;
        }
        const extractedValue = val.replace(`${prefix}:`, "");
        // the "null" string overrides the default value
        value = extractedValue !== "null" ? moment(extractedValue) : null;
    });
    return value;
}

// DateRangeFilter is a filter component that allows a user to filter by dates and allows the consumer
// of the filter to set the values of those dates
export class DoraDateRangeFilter extends React.Component<
    OwnProps & { route: RouteState },
    FilterState
> {
    constructor(props: OwnProps & { route: RouteState }) {
        super(props);

        this.state = {
            // set the default end date + start dates
            focusedInput: null,
        };
    }

    public render() {
        // get the start date and the end date from the query params
        const startVal = getParamDate(
            this.props.route.params,
            this.props.name,
            "start",
            this.props.defaultStart
        );
        const endVal = getParamDate(
            this.props.route.params,
            this.props.name,
            "end",
            this.props.defaultEnd
        );

        // the proptypes below are from the react airbnb documentation that i found helpful to have inline
        return (
            <div className={`dateInput d-flex align-items-center`}>
                <div className={`dateWrapper d-flex align-items-center`}>
                    <DateRangePicker
                        customInputIcon={
                            <Icon
                                type={IconTypes.Field_Date_Picker}
                                className="datePickerIcon"
                            />
                        }
                        startDate={startVal} // momentPropTypes.momentObj or null,
                        startDateId="date-range-filter-start" // PropTypes.string.isRequired,
                        endDate={endVal} // momentPropTypes.momentObj or null,
                        endDateId="date-range-filter-end" // PropTypes.string.isRequired,
                        customArrowIcon={<span className="dash" />}
                        onDatesChange={({ startDate, endDate }) => {
                            const { route } = this.props;

                            // generate the params for the start and the end dates
                            const formattedStartDate = formatMoment(
                                startDate,
                                "YYYY-MM-DD"
                            );
                            const formattedEndDate = formatMoment(
                                endDate,
                                "YYYY-MM-DD"
                            );
                            const params = updateDateParam(
                                formattedStartDate,
                                formattedEndDate,
                                this.props.name,
                                route.params
                            );

                            // reset the page param when the filter is applied
                            delete params.page;
                            const url = buildUrlWithParams({
                                location: route.location,
                                params,
                            });
                            trackFilterApplied(
                                this.props.name,
                                {
                                    start: formattedStartDate,
                                    end: formattedEndDate,
                                },
                                url
                            );
                            route.updateRouteAction(url, route.nextPathname);
                        }} // PropTypes.func.isRequired,
                        hideKeyboardShortcutsPanel
                        enableOutsideDays
                        isOutsideRange={() => false}
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                            this.setState({
                                focusedInput,
                            })
                        } // PropTypes.func.isRequired,
                    />
                    <i
                        className={`fa fa-caret-down text-grey-mid caret`}
                        aria-hidden="true"
                        onClick={() => {
                            this.setState({ focusedInput: "endDate" });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export const DoraDateRangeFilterCC = withRouter(DoraDateRangeFilter);

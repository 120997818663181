import { Action } from "@src/shared_modules/redux_provider/types";
export const MODAL_ACTION = "__modal";

export type ModalAction = {
    name: string;
} & Action;

export function showModal(name: string): ModalAction {
    return {
        type: MODAL_ACTION,
        name,
    };
}

export function hideModal(): ModalAction {
    return {
        type: MODAL_ACTION,
        name: "",
    };
}

// onLoad dispatch action
export function onLoad(loadFn: () => Action) {
    return (dispatch) => {
        if (loadFn == null) {
            return;
        }
        dispatch(loadFn());
    };
}

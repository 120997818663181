import { PurchaseOrderList } from "../PurchaseOrderList";
import {
    empty as emptyPagination,
    single as singlePagination,
} from "./Pagination";
import { purchaseOrder01 } from "./PurchaseOrder";

// // // //

export const empty: PurchaseOrderList = {
    results: null,
    pagination: {
        ...emptyPagination,
    },
    __typename: "PurchaseOrderList",
};

export const populated: PurchaseOrderList = {
    results: [purchaseOrder01],
    pagination: {
        ...singlePagination,
    },
    __typename: "PurchaseOrderList",
};

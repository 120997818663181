import { track } from "@src/analytics/track";

// // // //

export enum SpendEvents {
    toggleFiltersClicked = "Toggle Filters Clicked",
}

// trackToggleMoreFilters tracks that a user clicked a button to show or hide the more filters section
export function trackToggleFiltersClicked(action: "hide" | "show" | "reset") {
    track(SpendEvents.toggleFiltersClicked, { action });
}

import { DropdownSelect } from "@src/shared_modules/dropdown_select";
import React from "react";
import {
    DateInputValue,
    isDateFilterExpression,
    isDateInputDataValid,
} from "../advanced_filters/date_input";
import { isDeprecatedDateValue } from "../advanced_filters/date_input/isDeprecatedDateValue";
import {
    isWeekFieldType,
    isWeekInputDataValid,
    WeekInputValue,
} from "../advanced_filters/week_input";
import { InputSize } from "../../../../shared_modules/input";
import { DynamicDateDeprecationAlert } from "./DynamicDateDeprecationAlert";
import { FilterFormValueInput } from "./FilterFormValueInput";
import {
    AdvancedFilter,
    EditorFilter,
    FieldType,
    FilterExpression,
    Filters,
    FilterVal,
    mapFilterExpressionToHumanValue,
    PossibleFilter,
    PossibleValue,
} from "./graphql";
import { isFilterExpressionDynamic } from "./isFilterExpressionDynamic";
import styles from "./styles.module.css";

// // // //

// Builds PossibleValue[] for FilterExpression dropdown
// NOTE - this normalizes each `FilterExpression` as a string
// This value is de-normalized BACK to a `FilterExpression` enum
// value in the onChange callback passed into <DropdownSelect />
const POSSIBLE_EXPRESSIONS: PossibleValue[] = Object.keys(
    mapFilterExpressionToHumanValue
).map((fe: FilterExpression) => {
    return {
        internalValue: String(fe),
        humanValue: mapFilterExpressionToHumanValue[fe],
    };
});

// // // //

interface FilterFormProps {
    filter: EditorFilter;
    selectedQueryFilters: Filters;
    onSubmit: (filter: EditorFilter) => void;
    onCancel: () => void;
}

/**
 * FilterGroup
 * A grouping of `PossibleFilters`
 */
interface FilterGroup {
    group: PossibleValue;
    filters: PossibleFilter[];
}

// canSubmit
// Returns a boolean indicating whether or not the FilterForm may be submitted
export function canSubmit(props: {
    fieldType: null | FieldType;
    fieldName: string;
    expression: FilterExpression | null;
    val: FilterVal | null;
}): boolean {
    const { fieldType, fieldName, expression, val } = props;
    const validFieldNameAndExpression: boolean =
        fieldName !== "" && expression !== "" && expression !== null;

    // If expression === isdefined || isundefined, val MUST be empty string
    if (isExistentialExpression(expression as FilterExpression)) {
        return val === "";
    }
    if (isWeekFieldType(fieldType)) {
        return isWeekInputDataValid({
            value: val as WeekInputValue,
            expression: expression as FilterExpression,
        });
    }
    if (isDateFilterExpression(expression as FilterExpression)) {
        return isDateInputDataValid({
            value: val as DateInputValue,
            expression: expression as FilterExpression,
        });
    }
    if (typeof val === "string") {
        return validFieldNameAndExpression && val.trim() !== "";
    }
    if (typeof val === "number") {
        if (fieldType === FieldType.integer) {
            return validFieldNameAndExpression && Number.isInteger(val);
        }
        return validFieldNameAndExpression;
    }
    if (typeof val === "boolean") {
        return validFieldNameAndExpression;
    }
    if (Array.isArray(val)) {
        return validFieldNameAndExpression;
    }
    return false;
}

/**
 * groupAvailableFilters
 * Returns a `FilterGroup[]` for each grouping of possible filters, including one for "defaults" and
 * one for each `AdvancedFilterInput` object in `Filters.advanced`
 * @param filters - a `Filters` instance
 */
export function groupAvailableFilters(filters: Filters): FilterGroup[] {
    // Defines array of FilterGroups retured by this function
    const groups: FilterGroup[] = [];

    // Assemble "defaults" group from filters.default
    const defaults: FilterGroup = {
        group: {
            internalValue: "defaults",
            humanValue: "Defaults",
            description: "",
        },
        filters: filters.defaults.filter(
            (f: PossibleFilter) => f.fieldType !== FieldType.hidden
        ),
    };
    groups.push(defaults);

    // Prepare all groups from filters.advanced
    filters.advanced.forEach((a: AdvancedFilter) => {
        groups.push({
            group: {
                humanValue: a.content.name,
                internalValue: a.key,
                description: a.content.description,
            },
            filters: a.filters.filter(
                (f: PossibleFilter) => f.fieldType !== FieldType.hidden
            ),
        });
    });

    // Returns the FilterGroup[] array
    return groups;
}

/**
 * isExistentialExpression
 * Returns a boolean indicating that the expression param is FilterExpression.isdefined || FilterExpression.isundefined
 * @param expression - the FilterExpression that's being checked
 */
function isExistentialExpression(expression: FilterExpression): boolean {
    return [FilterExpression.isdefined, FilterExpression.isundefined].includes(
        expression
    );
}

// FilterForm
// Defines a component to create/edit an individual Filter
export function FilterForm(props: FilterFormProps) {
    // Hooks to manage local state
    const [fieldSource, setFieldSource] = React.useState(props.filter.source);
    const [fieldName, setFieldName] = React.useState(props.filter.fieldName);
    const [expression, setExpression] = React.useState<FilterExpression | null>(
        props.filter.expression
    );
    const [val, setVal] = React.useState<FilterVal | null>(props.filter.val);

    // The array of available `FilterGroup` objects that populate the dropdown menus in the `FilterForm`
    const filterGroups: FilterGroup[] = groupAvailableFilters(
        props.selectedQueryFilters
    );

    // Defines variable to maintain the selectedFilter state
    let selectedFilter: PossibleFilter | undefined;

    // Defines variables to encapsulate the available options for the fieldName and expression dropdowns
    let fieldNameOptions: PossibleValue[] = [];
    let availableExpressions: PossibleValue[] = [];

    // selectFieldSource - helper function that clears `fieldName` + expression` + `val` when `fieldSource` changes
    function selectFieldSource(updatedFieldSource: string) {
        if (updatedFieldSource === fieldSource) {
            return;
        }
        setFieldSource(updatedFieldSource);
        setFieldName("");
        setExpression(null);
        setVal("");
    }

    // selectFieldName - helper function that clears `expression` + `val` when `fieldName` changes
    function selectFieldName(updatedFieldName: string) {
        if (updatedFieldName === fieldName) {
            return;
        }
        setFieldName(updatedFieldName);
        setExpression(null);
        setVal("");
    }

    // selectExpression - helper function that clears `val` when `expression` is FilterExpression.isdefined || FilterExpression.isundefined
    function selectExpression(updatedExpression: FilterExpression) {
        // Set `expression` state
        setExpression(updatedExpression);

        // Clears value when previous expression was "dynamic"
        if (isFilterExpressionDynamic(expression)) {
            setVal("");
            return;
        }

        // Clear `val` data when `expression` is FilterExpression.isdefined || FilterExpression.isundefined
        if (isExistentialExpression(expression)) {
            setVal("");
        }
    }

    // Finds the currently selected FilterGroup
    const selectedFilterGroup: FilterGroup | undefined = filterGroups.find(
        (fg: FilterGroup) => fg.group.internalValue === fieldSource
    );

    // Set default fieldSource automatically if none is defined/selected, and there's only ONE option
    // This is done so the user doesn't _always_ need to select default if it's the only source available
    if (selectedFilterGroup === undefined && filterGroups.length === 1) {
        // Pulls defaultFilterGroup from filterGroups
        const defaultFilterGroup: FilterGroup = filterGroups[0];

        // Pulls internalValue from defaultFilterGroup.group, casts as string
        // NOTE - we need to explicitly cast as string because `internalValue` can _technically_ be a non-string,
        // but in this instance we know it's a string because how we constructed them in `groupAvailableFilters`
        const defaultFieldSource: string = String(
            defaultFilterGroup.group.internalValue
        );

        // Sets fieldSource as defaultFieldSource
        selectFieldSource(defaultFieldSource);
    }

    // Sets selectedFilter, fieldNameOptions, availableExpressions if selectedFilterGroup is available
    if (selectedFilterGroup) {
        // Assmbles the available options for the `fieldName` <DropdownSelect/>
        fieldNameOptions = selectedFilterGroup.filters.map(
            (pv: PossibleFilter) => {
                return {
                    internalValue: pv.key,
                    humanValue: pv.content.name,
                    description: pv.content.description,
                };
            }
        );

        // Finds selectedFilter, if available
        selectedFilter = selectedFilterGroup.filters.find(
            (pf: PossibleFilter) => pf.key === fieldName
        );

        // Finds the available expressions based on the currently selected PossibleFilter
        availableExpressions = POSSIBLE_EXPRESSIONS.filter(
            (expr: PossibleValue) => {
                // Skip if selectedFilter is undefined
                if (selectedFilter === undefined) {
                    return false;
                }

                // NOTE - we need to perform an extra step to stringify `selectedFilter.expressions` since we cannot
                // do a string comparison against an array of enum values because FilterExpression[] !== string[]
                const stringifiedExpressions = selectedFilter.expressions.map(
                    (e: FilterExpression) => String(e)
                );

                // NOTE - we cast expr.internalValue as a string, because we KNOW it's a string, but TS does not
                // We KNOW this is a string because we define POSSIBLE_EXPRESSIONS above and explcitly define `internalValue` as a string
                return stringifiedExpressions.includes(
                    String(expr.internalValue)
                );
            }
        );

        // Append props.filter.expression IFF props.filter.expression is a deprecated dynamic expressions
        if (isFilterExpressionDynamic(props.filter.expression)) {
            // Ensure the expressiong being added is not a duplicate
            if (
                !availableExpressions
                    .map((pv) => pv.internalValue)
                    .some((exp) => exp === props.filter.expression)
            ) {
                availableExpressions.push({
                    internalValue: props.filter.expression,
                    humanValue:
                        mapFilterExpressionToHumanValue[
                            props.filter.expression
                        ],
                });
            }
        }
    }

    // Defines a flag dictating whether or not to render the FilterExpression dropdown
    // We only hide the FilterExpression dropdown when working with Date FilterExpressions
    let hideExpressionDropdown: boolean = false;

    // NOTE - The isFilterExpressionDynamic call can be removed when we deprecate the old dynamic date expressions
    if (selectedFilter && !isFilterExpressionDynamic(expression)) {
        hideExpressionDropdown = selectedFilter.expressions.some(
            isDateFilterExpression
        );
    }

    // Handle deprecated date-picker values
    if (
        hideExpressionDropdown &&
        isDeprecatedDateValue({
            value: props.filter.val,
            expression: props.filter.expression,
        })
    ) {
        // Show the expression dropdown if the current date value is deprecated
        // This will allow users to selected an alternative expression
        hideExpressionDropdown = false;

        // Updates availableExpressions to include the current filter's expression, along with the rest of the expressions from props.selectedFilter
        availableExpressions = [
            {
                internalValue: props.filter.expression,
                humanValue:
                    mapFilterExpressionToHumanValue[props.filter.expression],
            },
            ...selectedFilter.expressions.map((e) => {
                return {
                    internalValue: e,
                    humanValue: mapFilterExpressionToHumanValue[e],
                };
            }),
        ];
    }

    // Always hide expression dropdown for WeekInput filter
    if (selectedFilter && isWeekFieldType(selectedFilter.fieldType)) {
        hideExpressionDropdown = true;
    }

    return (
        <div className="row">
            <div className="col-sm-12">
                {/* When the user adds a new filter to an existing one */}
                {/* (i.e. think clicking the +OR or +Filter button inside an existing AND block), */}
                {/* the `newFilter` that's passed into the `FilterForm` has it's source pre-defined by */}
                {/* the filter to which it will be added. When this happens, we hide the source dropdown */}
                {/* to prevent the user from doing something like erroneously adding a filter to an invalid `Filters[]` array */}
                {!props.filter.source && (
                    <div className="row mt-10-px">
                        <div className="col-sm-12">
                            <div className={styles.formGroup}>
                                <p className={styles.formLabel}>Field Source</p>
                                <DropdownSelect
                                    size={InputSize.lg}
                                    value={fieldSource}
                                    onChange={(updatedFieldSource: string) =>
                                        selectFieldSource(updatedFieldSource)
                                    }
                                    options={filterGroups.map(
                                        (fg: { group: PossibleValue }) =>
                                            fg.group
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="row mt-10-px">
                    <div className="col-sm-12">
                        <div className={styles.formGroup}>
                            <p className={styles.formLabel}>Field Name</p>
                            <DropdownSelect
                                size={InputSize.lg}
                                value={fieldName}
                                onChange={(fieldNameVal: string) =>
                                    selectFieldName(fieldNameVal)
                                }
                                options={fieldNameOptions}
                            />
                        </div>
                    </div>
                </div>

                {/* Render FilterExpression dropdown? */}
                {!hideExpressionDropdown && (
                    <div className="row mt-10-px">
                        <div className="col-sm-12">
                            <div className={styles.formGroup}>
                                <p className={styles.formLabel}>Expression</p>
                                <DropdownSelect
                                    size={InputSize.lg}
                                    value={expression}
                                    onChange={(expressionVal: string) =>
                                        selectExpression(
                                            expressionVal as FilterExpression
                                        )
                                    }
                                    options={availableExpressions}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Only render FilterFormValueInput IFF Filter.expression is NOT isdefined/inundefined, or if it's NOT a Date expression  */}
                {!isExistentialExpression(expression) && (
                    <div className="row mt-10-px">
                        <div className="col-sm-12">
                            <div className={styles.formGroup}>
                                <p className={styles.formLabel}>Value</p>
                                <FilterFormValueInput
                                    size={InputSize.lg}
                                    selectedFilter={selectedFilter}
                                    value={val}
                                    filterExpression={expression}
                                    onChange={(updatedFilterData) => {
                                        setVal(updatedFilterData.val);
                                        setExpression(
                                            updatedFilterData.expression
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Render DynamicDateDeprecationAlert */}
                <DynamicDateDeprecationAlert
                    filter={props.filter}
                    currentExpression={expression}
                    possibleFilter={selectedFilter}
                />

                <div className="row mt-10-px">
                    <div className="col-sm-6 pr-5-px">
                        <button
                            className="btn-lg btn-stroked-primary w-100"
                            onClick={props.onCancel}
                        >
                            <i className="fa fa-fw mr-5-px fa-times" />
                            Cancel
                        </button>
                    </div>
                    <div className="col-sm-6 pl-5-px">
                        <button
                            className="btn-lg btn-primary w-100"
                            disabled={
                                !canSubmit({
                                    fieldType:
                                        selectedFilter?.fieldType || null,
                                    fieldName,
                                    expression,
                                    val,
                                })
                            }
                            onClick={() => {
                                const filterModel: EditorFilter = {
                                    ...props.filter,
                                    source: fieldSource,
                                    fieldName,
                                    val,
                                    expression,
                                };

                                props.onSubmit(filterModel);
                            }}
                        >
                            <i className="fa fa-fw mr-5-px fa-check" />
                            Save Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

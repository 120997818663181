import { UserApi } from "@src/shared_modules/user/types";

// // // //

/**
 * getOrgID
 * Returns the orgID that should be used throughout the system for a particular user
 * Will always return the User's canonical orgID unless clientview is turned on for
 * a user with state="admin", in which case the clientView orgID will be returned
 */
export function getOrgID(user: UserApi): number {
    // Return the clientView orgID
    if (user.state === "admin" && user.organization.clientView > 0) {
        return user.organization.clientView;
    }

    // Return the user's canonical orgID
    return user.organization.id;
}

import {
    Filter,
    FilterExpression,
    PaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";

// // // //

/**
 * Define Column.key constants used for custom column rendering in AppInfoTable
 */
export const APP_NAME_COLUMN_KEY: string = "application_name";

/**
 * Define PossibleFilter.key constants used for hiding/showing
 */
export const DATE_RANGE_FIELD_NAME: string = "date_range";
export const INSTANCE_ID_FIELD_NAME: string = "instance_id";
export const SOURCE_APPLICATION_ID_FIELD_NAME: string = "source_application_id";

/**
 * Define default date filter for AppInfo advanced filters
 */
export const DEFAULT_DATE_RANGE_FILTER: Filter = {
    fieldName: DATE_RANGE_FIELD_NAME,
    expression: FilterExpression.eq,
    val: "thirty_day_users",
    filters: [],
};

/**
 * Define default pagination for AppInfoAdvancedFilters
 */
export const APP_INFO_LIST_DEFAULT_PAGINATION: PaginationInput = {
    ...DEFAULT_PAGINATION,
    sort: "application_name",
    direction: "desc",
};

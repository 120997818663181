/**
 * UsageRank
 * Defines enum encapsulating Usage rank name with its respective numeric value
 */
export type UsageRank = 1000 | 2000 | 3000 | 3500 | 4000;
export enum UsageRanks {
    direct = 4000,
    casb = 3500,
    sso = 3000,
    desktop = 2000,
    authentications = 1000,
}

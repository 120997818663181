import { BreadcrumbNode } from "../types";
import {
    usageOverviewState,
    usageState,
    usageSavedViewsState,
    usageSuggestedFiltersState,
    usageApplicationsState,
} from "./state";

// // // //

// routes.usage
export const usageNode: BreadcrumbNode = {
    getState: usageState,
    parent: null,
};

// routes.usageApplications
export const usageApplicationsNode: BreadcrumbNode = {
    getState: usageApplicationsState,
    parent: usageNode,
};

// routes.usageOverview
export const usageOverviewNode: BreadcrumbNode = {
    getState: usageOverviewState,
    parent: usageNode,
};

// routes.usageSuggestedFilters
export const usageSuggestedFiltersNode: BreadcrumbNode = {
    getState: usageSuggestedFiltersState,
    parent: usageNode,
};

// routes.usageSavedViews
export const usageSavedViewsNode: BreadcrumbNode = {
    getState: usageSavedViewsState,
    parent: usageNode,
};

import gql from "graphql-tag";

import { Pagination } from "./Pagination";
import { Workflow } from "./Workflow";

// // // //

export interface WorkflowList {
    results: Workflow[];
    pagination: Pagination;
    __typename: "WorkflowsList";
}

export const WORKFLOW_LIST_FRAGMENT = gql`
    fragment WORKFLOW_LIST_FRAGMENT on WorkflowsList {
        results {
            id
            organizationID
            name
            category
            frequencyCategory
            timeToTrigger
            timeZoneToTrigger
            daysOfWeek
            dayOfMonth
            queryName
            queryDestination
            deliveryMethod
            recipients
            includeInternalOwner
            isActive
            createdFrom
            deliverOnNoResult
            filters {
                defaults {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                                filters {
                                    fieldName
                                    expression
                                    val
                                }
                            }
                        }
                    }
                }
                advanced {
                    key
                    filters {
                        fieldName
                        expression
                        val
                        filters {
                            fieldName
                            expression
                            val
                            filters {
                                fieldName
                                expression
                                val
                                filters {
                                    fieldName
                                    expression
                                    val
                                    filters {
                                        fieldName
                                        expression
                                        val
                                    }
                                }
                            }
                        }
                    }
                }
                pagination {
                    page
                    itemsPerPage
                    sort
                    direction
                }
            }
        }
        pagination {
            pageCount
            rangeStart
            rangeEnd
            page
            totalItems
            itemsPerPage
            sort
            direction
        }
    }
`;

import * as React from "react";
import classnames from "classnames";

// // // //

// CaretProps are the props that should be passed to the Caret component
interface CaretProps {
    isAsc?: boolean;
    isDesc?: boolean;
}

// Caret renders the sort up and down arrows near the column title
export function Caret(props: CaretProps) {
    const { isAsc, isDesc } = props;

    const caretIconClassnames = classnames(
        "fa fa-stack-1x", // Font-Awesome
        "tw-w-auto tw-left-0" // Position
    );
    return (
        <span
            className="fa-stack"
            style={{ width: "1em", minWidth: "1em", height: "1.9em" }}
        >
            <i
                className={classnames(caretIconClassnames, "fa-sort-asc", {
                    "tw-text-primary-1": isAsc,
                    "tw-text-gray-300": !isAsc,
                })}
                aria-hidden="true"
                style={{ marginTop: "-1px", fontSize: "1em" }}
            />
            <i
                className={classnames(caretIconClassnames, "fa-sort-desc", {
                    "tw-text-primary-1": isDesc,
                    "tw-text-gray-300": !isDesc,
                })}
                aria-hidden="true"
                style={{ fontSize: "1em" }}
            />
        </span>
    );
}

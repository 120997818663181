import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import get from "lodash.get";
import * as React from "react";
import { Query } from "react-apollo";
import { Pagination } from "@src/schema/Pagination";
import { PrimaryIntegrationUser } from "@src/schema/PrimaryIntegrationUser";
import {
    PRIMARY_INTEGRATION_USERS_FETCHER_QUERY,
    PrimaryIntegrationUsersFetcherResponse,
    PrimaryIntegrationUsersFetcherVariables,
} from "./graphql";

// // // //

/**
 * PrimaryIntegrationUsersFetcher
 * Fetches the PrimaryIntegrationUser data via GQL using AdvancedFilters
 * @param filtersInput - FiltersInput instance
 * @param children - Fucntion that accepts a loading state + query result + pagination + refetch function
 */
export function PrimaryIntegrationUsersFetcher(props: {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        refetchPrimaryIntegrationUsers: () => void;
        primaryIntegrationUsers: PrimaryIntegrationUser[];
    }) => React.ReactNode;
}) {
    // IMPORTANT - we include `cache-and-network` fetchPolicy here to ensure that the Apollo Client
    // knows to render the updated data after we invoke `refetch`. This is necessary to handle a known bug in the Apollo Client
    // where a query dispatched without any changes in variables data will cause the loading state to hang if the fetchPolicy is `cache-only`.
    return (
        <Query<
            PrimaryIntegrationUsersFetcherResponse,
            PrimaryIntegrationUsersFetcherVariables
        >
            query={PRIMARY_INTEGRATION_USERS_FETCHER_QUERY}
            fetchPolicy="cache-and-network"
            variables={{
                filters: props.filtersInput,
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls primaryIntegrationUsers from response
                let primaryIntegrationUsers:
                    | PrimaryIntegrationUser[]
                    | null = get(
                    data,
                    "usage.primaryIntegrationUsers.list.results",
                    []
                );

                // Safely pulls pagination from response
                const pagination: Pagination = get(
                    data,
                    "usage.primaryIntegrationUsers.list.pagination",
                    {}
                );

                // Handle null value for primaryIntegrationUsers
                if (primaryIntegrationUsers === null) {
                    primaryIntegrationUsers = [];
                }

                // Renders props.children + error handler
                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            primaryIntegrationUsers,
                            refetchPrimaryIntegrationUsers: () => {
                                return refetch({ filters: props.filtersInput });
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

import ImageLoader from "@src/shared_modules/image_loader";
import { InputSize, SearchInput } from "@src/shared_modules/input";
import { Loader } from "@src/shared_modules/loader";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { VendorSearch } from "@src/shared_modules/vendor_search";
import { ContentLoader } from "@src/shared_modules/content_loader";
import {
    Vendor,
    VendorFetcher as SingleVendorFetcher,
} from "@src/shared_modules/related_vendors";
import classnames from "classnames";
import * as React from "react";
import { Filter } from "../graphql";

// // // //

/**
 * VendorSuggestionsProps
 * `results` - array of `Vendor` instances to render
 * `onSelect` - function that sets the `VendorInput` value
 */
interface VendorSuggestionsProps {
    results: Vendor[];
    onSelect: (vendorId: number) => void;
}

// Styles for suggestionsWrapper elements
const suggestionsWrapperClassnames = classnames(
    "tw-max-h-[300px]", // height
    "tw-bg-white", // background
    "tw-pt-20 tw-pb-2", // padding
    "tw-overflow-y-scroll", // overflow
    "tw-rounded-md tw-border tw-border-gray-200" // border
);

const inputHeightClassnames = (size: InputSize) => ({
    "tw-h-[44px]": size === InputSize.lg,
    "tw-h-[34px]": size === InputSize.md,
});

/**
 * VendorSuggestions
 * Renders a list of auto-complete suggestions
 * @param props - see `VendorSuggestionsProps`
 */
function VendorSuggestions(props: VendorSuggestionsProps) {
    const { results, onSelect } = props;
    if (results.length === 0) {
        return null;
    }
    return (
        <div className={suggestionsWrapperClassnames}>
            {results.map((result) => (
                <button
                    key={result.id}
                    className={classnames(
                        "tw-flex tw-items-center", // flex
                        "tw-w-full", // width
                        "tw-border-none tw-outline-none", // border & outline
                        "tw-bg-white hover:tw-bg-gray-200" // background
                    )}
                    role="presentation"
                    onClick={() => {
                        onSelect(result.id);
                    }}
                >
                    <div className="tw-px-4 tw-py-0">
                        <ImageLoader
                            className="tw-max-w-[20px]"
                            src={result.logo}
                            alt={result.name}
                        />
                    </div>
                    <p className="tw-font-roboto font-size-16-px tw-py-4 tw-px-0">
                        {result.name}
                    </p>
                </button>
            ))}
        </div>
    );
}

// // // //

/**
 * SelectedVendorProps
 * `size` - optional - prop to dictate the sizing CSS of the component. Defaults to `InputSize.lg`
 * `vendor` - the Vendor we're rendering
 * `onClear` - clears the selected vendor
 */
interface SelectedVendorProps {
    size?: InputSize;
    vendor: Vendor;
    onClear: () => void;
}

/**
 * SelectedVendor
 * SelectedVendor renders the selected vendor in place of the search input
 * @param props - see `SelectedVendorProps`
 */
export function SelectedVendor(props: SelectedVendorProps) {
    const { size = InputSize.lg } = props;
    return (
        <div
            className={classnames(
                "col-sm-12 d-flex align-items-center justify-content-between",
                "tw-rounded-md tw-border-solid tw-border tw-border-gray-200",
                inputHeightClassnames(size)
            )}
        >
            <div className="d-flex align-items-center">
                <div className="tw-px-4 tw-py-0">
                    <ImageLoader
                        className="tw-max-w-[20px]"
                        src={props.vendor.logo}
                        alt={props.vendor.name}
                    />
                </div>
                <p className="font-size-16-px tw-font-roboto">
                    {props.vendor.name}
                </p>
            </div>
            <button
                className={classnames(
                    "transparent-button",
                    "!tw-text-base !tw-text-gray-700" // overriding transparent-button styles
                )}
                onClick={props.onClear}
            >
                &times; CLEAR
            </button>
        </div>
    );
}

// // // //

/**
 * SelectedVendorLoaderProps
 * `size` - optional - prop to dictate the sizing CSS of the component. Defaults to `InputSize.lg`
 */
interface SelectedVendorLoaderProps {
    size?: InputSize;
}

/**
 * SelectedVendorLoader
 * Renders a `<ContentLoader />` component that wraps the same markup as the `<SelectedVendor />`
 */
export function SelectedVendorLoader(props: SelectedVendorLoaderProps) {
    const { size = InputSize.lg } = props;
    return (
        <ContentLoader>
            <div
                className={classnames(
                    "col-sm-12 d-flex align-items-center justify-content-between py-5-px",
                    "tw-rounded-md tw-border-solid tw-border tw-border-gray-200",
                    inputHeightClassnames(size)
                )}
            >
                <div className="d-flex align-items-center">
                    <div className="tw-px-4 tw-py-0">
                        <ImageLoader
                            className="tw-max-w-[20px]"
                            src=""
                            alt="Loading"
                        />
                    </div>
                    <p className="tw-font-roboto font-size-16-px">Loading</p>
                </div>
                <button className="tw-outline-none tw-text-base tw-font-roboto !tw-text-gray-700 tansparent-button">
                    &times; CLEAR
                </button>
            </div>
        </ContentLoader>
    );
}

// // // //

/**
 * SelectedVendorFetcher
 * `size` - optional - prop to dictate the sizing CSS of the component. Defaults to `InputSize.lg`
 * `vendorId` - The ID of the Vendor being fetched
 * `onClear` - clears the selected vendor
 */
interface SelectedVendorFetcherProps {
    size?: InputSize;
    vendorId: number;
    onClear: () => void;
}

/**
 * SelectedVendorFetcher
 * Fetches a single Vendor and renders the `<SelectedVendor />` component
 * @param props - see `SelectedVendorFetcherProps`
 */
export function SelectedVendorFetcher(props: SelectedVendorFetcherProps) {
    const { size = InputSize.lg } = props;
    // Implements the `related_vendors/VendorFetcher` component to fetch a single Vendor by ID
    return (
        <SingleVendorFetcher
            vendorID={props.vendorId}
            includeRelatedVendors={false}
        >
            {({ vendor, loading }) => {
                // If loading, return the `<SelectedVendorLoader />` component
                if (loading) {
                    return <SelectedVendorLoader size={size} />;
                }

                // Render the <SelectedVendor /> component if vendor is defined
                if (vendor) {
                    return (
                        <SelectedVendor
                            size={size}
                            vendor={vendor}
                            onClear={props.onClear}
                        />
                    );
                }

                // If not loading, and no error and vendor isn't defined
                // Call props.onClear() to ensure that a bad query will clear the invalid vendorID
                props.onClear();

                // Return null to prevent invariant error
                return null;
            }}
        </SingleVendorFetcher>
    );
}

// // // //

/**
 * VendorFetcherProps
 * `user` - the Redux UserState from withUser() HOC
 * `value` - the value of the currently selected Vendor
 * `placeholder` - (optonal) the value for the placeholder
 * `defaultFilters` - (optional) See VendorSearch.props.defaultFilters
 * `onChange` - callback method fired off to update selected Vendor
 */
interface VendorFetcherProps {
    value: number;
    placeholder?: string;
    size?: InputSize;
    defaultFilters?: Filter[];
    onChange: (updatedVendorId: number | null) => void;
}

/**
 * VendorFetcher
 * Queries for Vendors
 * @param props - see `VendorFetcherProps`
 */
export function VendorFetcher(props: VendorFetcherProps & { user: UserState }) {
    const [userQuery, setUserQuery] = React.useState<string>("");

    // Defines default placeholder + InputSize + defaultFilters
    const {
        placeholder = "Search Vendors",
        size = InputSize.lg,
        defaultFilters = [],
    } = props;

    // Return the <SelectedVendor /> if props.value exists
    if (props.value) {
        return (
            <SelectedVendorFetcher
                size={size}
                vendorId={props.value}
                onClear={() => {
                    setUserQuery("");
                    props.onChange(null);
                }}
            />
        );
    }

    // Renders the component tree
    return (
        <div className="row d-block tw-min-w-[200px]">
            <div
                className={classnames(
                    "col-sm-12",
                    "tw-relative",
                    "tw-z-50",
                    inputHeightClassnames(size)
                )}
            >
                <div className="tw-absolute tw-flex tw-flex-grow tw-pr-12 tw-w-full">
                    <SearchInput
                        value={userQuery}
                        onChange={setUserQuery}
                        debounceTimeoutMs={800}
                        size={size}
                        placeholder={placeholder}
                    />
                </div>
                {/* we don't show the suggestions if there are less than 3 letters in the query */}
                {/* because otherwise the result would contain all the vendors having those letters in any part of the name */}
                {userQuery.length > 0 && (
                    <VendorSearch
                        query={userQuery}
                        defaultFilters={defaultFilters}
                    >
                        {({ loading, vendors }) => {
                            if (loading) {
                                return (
                                    <div
                                        className={suggestionsWrapperClassnames}
                                    >
                                        <Loader />
                                    </div>
                                );
                            }

                            // Render suggestions
                            if (vendors.length > 0 && !loading) {
                                return (
                                    <VendorSuggestions
                                        results={vendors}
                                        onSelect={(
                                            selectedVendorId: number
                                        ) => {
                                            setUserQuery("");
                                            props.onChange(selectedVendorId);
                                        }}
                                    />
                                );
                            }

                            // Render empty state
                            return (
                                <div className={suggestionsWrapperClassnames}>
                                    <div className="d-flex justify-content-center py-30-px">
                                        <p className="font-secondary-bold font-size-14-px">
                                            No Vendors found
                                        </p>
                                    </div>
                                </div>
                            );
                        }}
                    </VendorSearch>
                )}
            </div>
        </div>
    );
}

// // // //

export const VendorInput: React.ComponentType<VendorFetcherProps> = withUser(
    VendorFetcher
);

import classnames from "classnames";
import * as React from "react";

// // // //

interface Props {
    // children are the content to render
    children: React.ReactNode;
    // navItems are the nav items to render
    navItems: React.ReactNode;
    // Optional className to attach to mainContent
    className?: string;
}

// LeftNavLayout renders a layout for a fixed left hand nav.
// It arranges the nav on the left side (the consumer is responsible for what goes in the nav) and renders the main content of the body.
export function LeftNavLayout(props: Props) {
    const { navItems, children, className = "" } = props;
    return (
        <main className="tw-flex tw-h-screen">
            <nav
                className={classnames(
                    "tw-flex tw-flex-col tw-shrink-0 tw-items-center", // flex
                    "tw-bg-medium-grey-light", // background
                    "tw-overflow-y-auto" // overflow
                )}
            >
                {navItems}
            </nav>
            <div
                className={classnames(
                    "tw-w-full tw-overflow-y-auto tw-bg-white",
                    className
                )}
            >
                {children}
            </div>
        </main>
    );
}

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { FeatureContent } from "@src/shared_modules/feature_content";
import classnames from "classnames";
import * as React from "react";
import {
    trackClickDefinedDateInputButton,
    trackClickUndefinedDateInputButton,
} from "../../../analytics/date_input";
import { DateInputButton } from "./DateInputButton";
import styles from "./styles.module.css";
import { DateInputValue } from "./types";

// // // //

/**
 * FilterDescription
 * Renders the description for the defined/undefined expression
 * @param props.uniqueKey - see FeatureContent.props.uniqueKey
 */
function FilterDescription(props: { uniqueKey: string }) {
    return (
        <FeatureContent uniqueKey={props.uniqueKey}>
            {({ loading, feature }) => {
                if (loading) {
                    return <p className="font-secondary my-10-px">{"..."}</p>;
                }
                return (
                    <p className="font-secondary my-10-px">{feature.header}</p>
                );
            }}
        </FeatureContent>
    );
}

/**
 * UndefinedDateInput
 * Renders buttons for `Undefined` FilterExpression (Used by FilterExpression.adp_ude + FilterExpression.adp_dde)
 * @param props.value - the current value from DateInput
 * @param props.expression - the current expression from DateInput
 * @param props.availableExpressions - Array of available expressions
 * @param props.setValue - callback to update props.value
 */
export function UndefinedDateInput(props: {
    value: DateInputValue;
    expression: FilterExpression;
    availableExpressions: FilterExpression[];
    setValueAndExpression: (update: {
        value: DateInputValue;
        expression: FilterExpression;
    }) => void;
}) {
    const { expression, availableExpressions } = props;
    return (
        <React.Fragment>
            <div className={classnames("mt-10-px", styles.inputContainer)}>
                {availableExpressions.includes(FilterExpression.adp_ude) && (
                    <DateInputButton
                        label="Undefined date"
                        className="d-flex flex-grow-1 justify-content-center"
                        active={expression === FilterExpression.adp_ude}
                        onClick={() => {
                            trackClickUndefinedDateInputButton();
                            props.setValueAndExpression({
                                expression: FilterExpression.adp_ude,
                                value: [],
                            });
                        }}
                    />
                )}

                {availableExpressions.includes(FilterExpression.adp_dde) && (
                    <DateInputButton
                        label="Defined date"
                        className="d-flex flex-grow-1 justify-content-center"
                        active={expression === FilterExpression.adp_dde}
                        onClick={() => {
                            trackClickDefinedDateInputButton();
                            props.setValueAndExpression({
                                expression: FilterExpression.adp_dde,
                                value: [],
                            });
                        }}
                    />
                )}
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                    {expression === FilterExpression.adp_ude && (
                        <FilterDescription
                            uniqueKey={"date-input-undefined-message"}
                        />
                    )}
                    {expression === FilterExpression.adp_dde && (
                        <FilterDescription
                            uniqueKey={"date-input-defined-message"}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

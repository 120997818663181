import { USER_DASHBOARD_SETTING_COLUMNS_SETTINGS_KEY as SETTINGS_KEY } from "./constants";

/**
 * buildColumnSettingsComponentName
 * Produces a value for `UserDashboardSetting.component` for ColumnSetting[]
 * data belonging to a specific AdvancedFiltersProvider ->
 * @param props.componentName - see AdvancedFiltersProvider.props.componentName
 */
export function buildColumnSettingsComponentName(props: {
    componentName: string;
}): string {
    return `${props.componentName}--${SETTINGS_KEY}`;
}

import { publicRoutes, routes } from "@src/routes";

// // // //

/**
 * PageCategories
 * Enumeration of available page categories
 */
enum PageCategories {
    auditLog = "audit log",
    accessControl = "access control",
    connectedApps = "connected apps",
    appUsageSummary = "app usage summary",
    integrations = "integrations",
    onboarding = "onboarding",
    overview = "overview",
    settings = "settings",
    spend = "spend",
    team = "team",
    usage = "usage",
    users = "users",
    vendors = "vendors",
    workflow = "workflow",
    procurement = "procurement",
    webhook = "webhook",
}

/**
 * routeCategoriesMap
 * Maps each route to a list of page categories (primary and optional secondary)
 */
export const routeCategoriesMap: {
    [key: string]: PageCategories[];
} = {
    [publicRoutes.login]: [],
    [publicRoutes.loginSSO]: [],
    [publicRoutes.register]: [],
    [publicRoutes.reset_password]: [],
    [publicRoutes.forgot_password]: [],
    [routes.usageIndividual]: [PageCategories.usage],
    [routes.usageIndividualColumnSettings]: [PageCategories.usage],
    [routes.usageSuggestedFilters]: [PageCategories.usage],
    [routes.spendIndividual]: [PageCategories.spend],
    [routes.spendIndividualChart]: [PageCategories.spend],
    [routes.appFieldIndividual]: [PageCategories.vendors],
    [routes.teamIndividual]: [PageCategories.usage, PageCategories.team],
    [routes.onboarding]: [PageCategories.onboarding],
    [routes.overview]: [PageCategories.overview],
    [routes.appListing]: [PageCategories.spend],
    [routes.paymentListing]: [PageCategories.spend],
    [routes.users]: [PageCategories.users],
    [routes.userIndividualUsage]: [PageCategories.users, PageCategories.usage],
    [routes.userIndividualSpend]: [PageCategories.users, PageCategories.spend],
    [routes.userIndividualSpendChart]: [
        PageCategories.users,
        PageCategories.spend,
    ],
    [routes.connectedAppsListing]: [PageCategories.connectedApps],
    [routes.connectedAppsIndividualApplications]: [
        PageCategories.connectedApps,
    ],
    [routes.connectedAppsIndividualUsers]: [PageCategories.connectedApps],
    [routes.usage]: [PageCategories.usage],
    [routes.usageApplications]: [PageCategories.usage],
    [routes.usageSavedViews]: [PageCategories.usage],
    [routes.vendorListing]: [PageCategories.vendors],
    [routes.vendorListingV2]: [PageCategories.vendors],
    [routes.vendorListingManageColumns]: [PageCategories.vendors],
    [routes.vendorListingManageFilters]: [PageCategories.vendors],
    [routes.integrations]: [PageCategories.integrations],
    [routes.integrationIndividualDash]: [PageCategories.integrations],
    [routes.integrationIndividualAuth]: [PageCategories.integrations],
    [routes.integrationIndividualSuccess]: [PageCategories.integrations],
    [routes.manageOrganizationFields]: [PageCategories.settings],
    [routes.teams]: [PageCategories.team],
    [routes.settings.customFields]: [PageCategories.settings],
    [routes.settings.fieldUpload]: [PageCategories.settings],
    [routes.settings.newOrgField]: [PageCategories.settings],
    [routes.settings.editOrgField]: [PageCategories.settings],
    [routes.settings.users]: [PageCategories.settings, PageCategories.users],
    [routes.settings.paymentUploads]: [
        PageCategories.settings,
        PageCategories.spend,
    ],
    [routes.settings.teams]: [PageCategories.settings, PageCategories.team],
    [routes.settings.newTeam]: [PageCategories.settings, PageCategories.team],
    [routes.settings.editTeam]: [PageCategories.settings, PageCategories.team],
    [routes.settings.saml]: [PageCategories.settings],
    [routes.settings.set_team_department]: [PageCategories.settings],
    [routes.settings.demo]: [PageCategories.settings],
    [routes.settings.accessControlReconciliation]: [
        PageCategories.settings,
        PageCategories.accessControl,
    ],
    [routes.settings.accessControlVendorRolesUploadsListing]: [
        PageCategories.settings,
        PageCategories.accessControl,
    ],
    [routes.settings.accessControlVendorRolesUploadsNew]: [
        PageCategories.settings,
        PageCategories.accessControl,
    ],
    [routes.settings.auditLog]: [
        PageCategories.settings,
        PageCategories.auditLog,
    ],
    [routes.settings.webhooks]: [
        PageCategories.webhook,
        PageCategories.settings,
    ],
    [routes.settings.webhookNew]: [
        PageCategories.webhook,
        PageCategories.settings,
    ],
    [routes.settings.webhookIndividual]: [
        PageCategories.webhook,
        PageCategories.settings,
    ],
    [routes.globalRoles]: [PageCategories.accessControl],
    [routes.globalRoleAssignments]: [PageCategories.accessControl],
    [routes.rolesIndividual]: [PageCategories.accessControl],
    [routes.roleAssignmentsIndividual]: [PageCategories.accessControl],
    [routes.workflowList]: [PageCategories.workflow],
    [routes.workflowNew]: [PageCategories.workflow],
    [routes.workflowEdit]: [PageCategories.workflow],
    [routes.workflowDestinations]: [PageCategories.workflow],
    [routes.suggestedWorkflows]: [PageCategories.workflow],
    [routes.workflowIntegrationSuccess]: [
        PageCategories.workflow,
        PageCategories.integrations,
    ],
    [routes.connectedAppsIndividualApplications]: [
        PageCategories.connectedApps,
    ],
    [routes.connectedAppsIndividualUsers]: [PageCategories.connectedApps],
    [routes.purchaseOrderListing]: [PageCategories.procurement],
    [routes.purchaseOrderDetails]: [PageCategories.procurement],
    [routes.purchaseOrderVendorListing]: [PageCategories.procurement],
    [routes.purchaseOrderVendorDetails]: [PageCategories.procurement],
    [routes.vendorUsageAppListing]: [
        PageCategories.usage,
        PageCategories.appUsageSummary,
    ],
    [routes.vendorUsageAppIndividual]: [
        PageCategories.usage,
        PageCategories.users,
    ],
    [routes.vendorUsageAppIndividualTimeseries]: [
        PageCategories.usage,
        PageCategories.users,
    ],
    ["/_error"]: [],
};

import { routes, vendorUsageAppIndividualTimeseries } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const vendorUsageAppIndTimeseriesState = (route: RouteState) => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);
    const { instanceID, sourceApplicationID } = route.pathParams;

    return {
        linkProps: {
            href: {
                pathname: routes.vendorUsageAppIndividualTimeseries,
                query: {
                    vendorID: String(vendorID),
                    instanceID,
                    sourceApplicationID,
                },
            },
            as: vendorUsageAppIndividualTimeseries({
                vendorID,
                instanceID,
                sourceApplicationID,
            }),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "vendorUsageAppIndividualTimeseries",
                instanceID,
                sourceApplicationID,
            },
        },
        enabled: true,
        active:
            route.nextPathname === routes.vendorUsageAppIndividualTimeseries,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Timeseries" },
        },
    };
};

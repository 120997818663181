import { stripTypenameFromAppliedFilters } from "@src/modules/advanced_filters/components/advanced_filters";
import omit from "lodash.omit";
import { OrgTag } from "../org_tags";
import { OrgSavedView, OrgSavedViewInput } from "./types";

// // // //

/**
 * buildOrgSavedViewInput
 * Accepts `OrgSavedView` and returns an `OrgSavedViewInput` instance
 * This function is used when updating / deleting an `OrgSavedView`
 */
export function buildOrgSavedViewInput(props: {
    orgSavedView: OrgSavedView;
    organizationID: number;
}): OrgSavedViewInput {
    const { orgSavedView, organizationID } = props;

    // Returns OrgSavedViewInput
    return {
        ...omit(orgSavedView, ["__typename", "vendor"]),
        tags: orgSavedView.tags.map((orgTag: OrgTag) => orgTag.id),
        filters: stripTypenameFromAppliedFilters(
            orgSavedView.filters,
            organizationID
        ),
    };
}

import { InfoTooltip } from "@src/shared_modules/info_tooltip";
import { StateDot } from "@src/shared_modules/state_dot";
import * as React from "react";

// // // //

/**
 * StateTooltip
 * Displays info about the Application Status on the spend listing page
 * @returns
 */
export function StateTooltip() {
    return (
        <InfoTooltip>
            <div style={{ width: "320px" }}>
                <h3 className="tw-text-black font-size-16-px font-secondary-bold pb-10-px">
                    Application status
                </h3>
                <ul className="font-size-14-px px-10-px">
                    <li className="row py-15-px tw-m-0 border-bottom-grey">
                        <div className="col-md-5 pl-5-px tw-text-black font-secondary-bold">
                            <div className="tw-flex tw-items-center">
                                <StateDot status="live" />
                                <p className="tw-pl-4">Live</p>
                            </div>
                        </div>
                        <div className="col-md-7 tw-pl-0 tw-text-black font-secondary">
                            Application is active
                        </div>
                    </li>
                    <li className="row py-15-px tw-m-0 border-bottom-grey">
                        <div className="col-md-5 pl-5-px tw-text-black font-secondary-bold">
                            <div className="tw-flex tw-items-center">
                                <StateDot status="unconfirmed" />
                                <p className="tw-pl-4">Unconfirmed</p>
                            </div>
                        </div>
                        <div className="col-md-7 tw-pl-0 tw-text-black font-secondary">
                            Application needs confirmation to be tagged as
                            software
                        </div>
                    </li>
                    <li className="row py-15-px tw-m-0 border-bottom-grey">
                        <div className="col-md-5 pl-5-px tw-text-black font-secondary-bold">
                            <div className="tw-flex tw-items-center">
                                <StateDot status="inactive" />
                                <p className="tw-pl-4">Inactive</p>
                            </div>
                        </div>
                        <div className="col-md-7 tw-pl-0 tw-text-black font-secondary">
                            Application has been cancelled
                        </div>
                    </li>
                    <li className="row py-15-px tw-m-0">
                        <div className="col-md-5 pl-5-px tw-text-black font-secondary-bold">
                            <div className="tw-flex tw-items-center">
                                <StateDot status="archive" />
                                <p className="tw-pl-4">Archive</p>
                            </div>
                        </div>
                        <div className="col-md-7 tw-pl-0 tw-text-black font-secondary">
                            Hide application details
                        </div>
                    </li>
                </ul>
            </div>
        </InfoTooltip>
    );
}

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { Vendor } from "@src/shared_modules/related_vendors";
import get from "lodash.get";
import * as React from "react";
import { Query } from "react-apollo";
import { Filter } from "../../modules/advanced_filters/components/advanced_filter_editor";
import {
    buildVendorSearchVariables,
    VENDOR_SEARCH_QUERY,
    VendorSearchResponse,
    VendorSearchVariables,
} from "./graphql";

// // // //

/**
 * VendorSearchProps
 * @param props.query - The query used to search for Vendors
 * @param props.defaultFilters - Optional, supplemental filters to include in VendorSearch query
 * @param props.children - Fucntion that accepts a Vendor instance and a loading boolean
 */
interface VendorSearchProps {
    query: string;
    defaultFilters?: Filter[];
    children: (childProps: {
        vendors: Vendor[];
        loading: boolean;
    }) => React.ReactNode;
}

/**
 * VendorSearchLayout
 * Searches for Vendors via GQL using a `query` filter
 * @param props - see VendorSearchLayout
 */
export function VendorSearchLayout(
    props: VendorSearchProps & { user: UserState }
) {
    // Pulls user, query from props, defaults to true
    const { user, query, defaultFilters = [] } = props;

    // Return props.children if query is an empty string
    if (query === "") {
        return (
            <React.Fragment>
                {props.children({ loading: false, vendors: [] })}
            </React.Fragment>
        );
    }

    return (
        <Query<VendorSearchResponse, VendorSearchVariables>
            query={VENDOR_SEARCH_QUERY}
            variables={buildVendorSearchVariables({
                organizationID: user.organizationID,
                defaultFilters,
                query,
            })}
        >
            {({ loading, error, data }) => {
                // Safely pulls Vendors from response
                let vendors: Vendor[] | null = get(
                    data,
                    "vendors.listVendors.list.results",
                    null
                );

                // Ensure vendors value passed to props.children is not null
                if (vendors === null) {
                    vendors = [];
                }

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            vendors,
                            loading,
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export const VendorSearch: React.ComponentType<VendorSearchProps> = withUser(
    VendorSearchLayout
);

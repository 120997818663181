import { webConfig } from "@src/config";
import { captureMessage } from "@src/util/analytics";
import { SingletonRouter } from "next/router";
import { track as uncommonsTrack } from "./uncommons_track";

// // // //

/**
 * buildTrackFunction
 * Returns the `track` function from the uncommons with additional fields included into its `eventProps` argument
 * @param props.router - next router instance
 * @param props.routeCategoriesMap - mapping of routes to page categories
 * @param props.routeNamesMap - mapping of routes to route names
 */
export function buildTrackFunction(props: {
    router: SingletonRouter;
    routeCategoriesMap: { [key: string]: string[] };
    routeNamesMap: { [key: string]: string | undefined };
}): (eventName: string, eventProps: { [key: string]: any }) => void {
    const { router, routeCategoriesMap, routeNamesMap } = props;

    function trackWithCategories(
        eventName: string,
        eventProps: { [key: string]: any }
    ) {
        // Pull categories from routeCategoriesMap
        let categories: undefined | string[] =
            routeCategoriesMap[router.pathname];

        // Pull name from routeNamesMap
        let name: undefined | string = routeNamesMap[router.pathname];

        // If name is undefined, fallback to `eventName`
        // This is done to prevent a warning in Pendo
        if (name === undefined) {
            name = eventName;
        }

        // Handle scenario where categories is undefined
        if (categories === undefined) {
            categories = [];
            captureMessage("getTrackFunction - no categories defined", {
                extra: {
                    eventName,
                    pathname: router.pathname,
                },
            });
        }

        // Invoke `track` function from uncommons/analytics/track.js with eventProps + primary/secondary categories
        // NOTE - the `name` prop here is VERY important. Without this, we won't receive notifications when a new integration is setup.
        uncommonsTrack(eventName, {
            ...eventProps,
            name, // Include route name
            pathname: router.pathname, // Include router.pathname
            vendorID: router.query?.vendorID || undefined, // Include VendorID (defaults to undefined)
            primaryCategory: categories[0] || undefined, // Include primary + secondary categories
            secondaryCategory: categories[1] || undefined,
            environment: webConfig.appEnv, // Includes "development" | "staging" | "production" environment
        });
    }

    // Returns trackWithCategories
    return trackWithCategories;
}

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { useUserState } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import get from "lodash.get";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { WatchQueryFetchPolicy } from "apollo-client";
import { IntegrationContent } from "@src/schema/IntegrationContent";
import { IntegrationService } from "@src/schema/IntegrationService";
import { IntegrationInstance } from "@src/schema/IntegrationInstance";
import {
    IntegrationFetcherResponse,
    INTEGRATIONS_FETCHER_QUERY,
    IntegrationsFetcherVariables,
    queryFilters,
} from "./graphql";

// // // //

const dummyIntegrationContent: IntegrationContent = {
    credAuth: false,
    uniqueAuthRoute: false,
    authInstructions: "",
    description: "",
    helpLink: "",
    isCompliance: false,
    isSpend: false,
    isUsage: false,
    logoLink: "",
    name: "",
    site: "",
    slug: "",
    valueProps: [],
    isBeta: false,
    isLive: false,
    category: "",
    shortDescription: "",
    setupTime: 1,
    longSync: false,
    demoLink: "",
    internal: false,
    integrationType: "sync",
    __typename: "IntegrationContent",
};

interface Props {
    fetchPolicy?: WatchQueryFetchPolicy;
    children: (childProps: {
        loading: boolean;
        integrations: IntegrationService[];
        refetchIntegrations: () => void;
        getContent: (integrationServiceName: string) => IntegrationContent;
        getIntegration: (
            integrationServiceName: string
        ) => IntegrationService | undefined;
        getIntegrationInstance: (
            integrationInstanceID: string
        ) => IntegrationInstance | undefined;
        getIntegrationFromInstanceID: (
            integrationInstanceID: string
        ) => IntegrationService | undefined;
    }) => React.ReactNode;
}

export function IntegrationsFetcher(props: Props) {
    const user = useUserState();

    return (
        <Query<IntegrationFetcherResponse, IntegrationsFetcherVariables>
            query={INTEGRATIONS_FETCHER_QUERY}
            variables={queryFilters(user.organizationID)}
            fetchPolicy={props.fetchPolicy}
        >
            {({ loading, error, data, refetch }: QueryResult) => {
                let integrations: IntegrationService[] | null = get(
                    data,
                    "integrations.integrations",
                    []
                );
                if (integrations === null) {
                    integrations = [];
                }

                function getIntegration(integrationServiceName: string) {
                    let service = integrations.find(
                        (i) =>
                            i.service.toLowerCase() ===
                            integrationServiceName.toLowerCase()
                    );

                    if (service) {
                        return service;
                    }

                    service = integrations.find((i) =>
                        i.secondaryNames.includes(integrationServiceName)
                    );

                    return service;
                }

                function getIntegrationInstance(
                    integrationInstanceID: string
                ): IntegrationInstance | undefined {
                    let instance = undefined;

                    for (let i = 0; i < integrations.length; i++) {
                        if (instance !== undefined) {
                            break;
                        }
                        instance = integrations[i].instances.find(
                            (i) => i.id === integrationInstanceID
                        );
                    }

                    return instance;
                }

                function getIntegrationFromInstanceID(
                    integrationInstanceID: string
                ): IntegrationService | undefined {
                    return integrations.find((integration) => {
                        return integration.instances.some((instance) => {
                            return instance.id === integrationInstanceID;
                        });
                    });
                }

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            integrations,
                            refetchIntegrations: () => {
                                return refetch(
                                    queryFilters(user.organizationID)
                                );
                            },
                            getIntegration,
                            getIntegrationInstance,
                            getIntegrationFromInstanceID,
                            getContent: (serviceName) => {
                                const integration = getIntegration(serviceName);

                                if (integration === undefined) {
                                    captureMessage(
                                        `integrations fetcher failed to look up content for itnegration ${serviceName}`
                                    );
                                    return dummyIntegrationContent;
                                }

                                return integration.content;
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

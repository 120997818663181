import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import gql from "graphql-tag";
import { FilterVal } from "./FilterVal";

// // // //

/**
 * AppliedFilter is the filter type that we use to compose our complex filters
 * (this is taken from our existing nested_filters)
 */
export interface AppliedFilter {
    fieldName: string;
    expression: FilterExpression;
    val: FilterVal;
    filters: AppliedFilter[];
    __typename: "AppliedFilter";
}

// NOTE - we filter for applied filters *four* levels deep
export const APPLIED_FILTER_FRAGMENT = gql`
    fragment APPLIED_FILTER_FRAGMENT on AppliedFilter {
        fieldName
        expression
        val
        filters {
            fieldName
            expression
            val
            filters {
                fieldName
                expression
                val
                filters {
                    fieldName
                    expression
                    val
                    filters {
                        fieldName
                        expression
                        val
                    }
                }
            }
        }
    }
`;

import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { ApolloClient } from "apollo-client";
import get from "lodash.get";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    UPDATE_ORG_FIELD_MUTATION,
    UpdateOrgFieldMutationRequest,
    UpdateOrgFieldMutationResponse,
} from "./graphql";
import { OrganizationField, OrgFieldUpsertInput } from "./types";

// // // //

// Defines success message for update action
const UPDATE_SUCCESS_MESSAGE = "Successfully updated organization field";

/**
 * UpdateOrgFieldMutationProps
 * Props for UpdateOrgFieldMutation
 */
interface UpdateOrgFieldMutationProps {
    children: (childProps: {
        loading: boolean;
        client: ApolloClient<Object>;
        updateOrgField: (
            orgFieldToUpdate: OrgFieldUpsertInput
        ) => Promise<OrganizationField | null>;
    }) => React.ReactNode;
}

/**
 * UpdateOrgFieldMutationLayout
 * Updates the attributes and/or the name of an org field
 * @param props - see UpdateOrgFieldMutationProps
 */
export function UpdateOrgFieldMutationLayout(
    props: UpdateOrgFieldMutationProps & WithToasterProps
) {
    return (
        <Mutation<UpdateOrgFieldMutationResponse, UpdateOrgFieldMutationRequest>
            mutation={UPDATE_ORG_FIELD_MUTATION}
            onError={() => null}
        >
            {(commitMutation, { loading, error, client }) => {
                // Defines updateOrgField function
                function updateOrgField(
                    orgFieldToUpdate: OrgFieldUpsertInput
                ): Promise<OrganizationField | null> {
                    return new Promise((resolve) => {
                        return commitMutation({
                            variables: {
                                input: {
                                    ...orgFieldToUpdate,
                                },
                            },
                        }).then(
                            (response: {
                                data:
                                    | undefined
                                    | UpdateOrgFieldMutationResponse;
                            }) => {
                                // Pulls OrganizationField from response
                                const organizationField: null | OrganizationField = get(
                                    response,
                                    "data.updateOrgField",
                                    null
                                );

                                // Displays success toaster if organizationField isn't null
                                if (organizationField !== null) {
                                    props.addToaster({
                                        type: "success",
                                        text: UPDATE_SUCCESS_MESSAGE,
                                    });
                                }

                                // Resolves promise with OrganizationField | null
                                resolve(organizationField);
                            }
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handles error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {/* Renders props.children + passes updateOrgField function */}
                        {props.children({
                            loading,
                            client,
                            updateOrgField,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// // // //

export const UpdateOrgFieldMutation: React.ComponentType<UpdateOrgFieldMutationProps> = withToaster(
    UpdateOrgFieldMutationLayout
);

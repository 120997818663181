import classnames from "classnames";
import React from "react";
import { DragHandleProps } from "react-beautiful-dnd";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListItemDragHandleProps
 * `disableReorder` - boolean indicating whether or not this component is displayed (thus enabling re-ordering)
 * `dragHandleProps` - See `react-beautiful-dnd` docs
 */
interface FilterListItemDragHandleProps {
    disableReorder: boolean;
    dragHandleProps: DragHandleProps;
}

/**
 * FilterListItemDragHandle
 * Renders a handle for drag re-ordering of the ColumnListItem components
 * @param props - see `FilterListItemDragHandleProps`
 */
export function FilterListItemDragHandle(props: FilterListItemDragHandleProps) {
    const handleClassName = classnames({
        "d-flex flex-column justify-content-center mr-10-px": true,
        [styles.dragHandleWrapper]: true,
        [styles.dragHandlePlaceholder]: props.disableReorder,
    });

    return (
        <span className={handleClassName} {...props.dragHandleProps}>
            <i className="fa fa-fw fa-lg fa-arrows" />
        </span>
    );
}

import { trackModalLoad } from "@src/analytics/base";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModal, onLoad } from "./action";
import { ModalState } from "./reducer";
import styles from "./styles.module.css";

// // // //

interface CloseModalButtonProps {
    onClick: () => void;
}

export function CloseModalButton(props: CloseModalButtonProps) {
    return (
        <button
            type="button"
            className={`transparent-grey-sml-round-button ${styles.closeModalButton}`}
            onClick={props.onClick}
        >
            <i className="fa fa-times" />
            CLOSE
        </button>
    );
}

// // // //

interface State {
    modal: ModalState;
}

interface Action {
    type: string;
}

interface ModalProps {
    children?: any;
    name: string;
    onClickOutside?: () => void;
    style?: {};
    // onLoadAction should be an action creator that you want dispatch on the modal loading
    onLoadAction?: () => Action;
}

interface DispatchProps {
    onHide: () => void;
    onLoad: (loadFn: () => Action) => void;
}

export class Modal extends React.Component<
    ModalProps & ModalState & DispatchProps
> {
    public componentDidMount() {
        // we pass an empty string bec there is no prevName when the modal originally loads
        this.onLoad("");
    }

    public componentDidUpdate(prevProps: ModalState) {
        this.onLoad(prevProps.activeName);
    }

    public componentWillUnmount() {
        this.props.onHide();
    }

    public onLoad(prevName: string) {
        // check that the previous name doesn't equal the active name and that the active  name equals the name
        if (
            prevName !== this.props.activeName &&
            this.props.activeName === this.props.name
        ) {
            // There was a bug that caused this to be fired on every overview
            // page load to the next - master fixed on 04/06/17
            trackModalLoad(this.props.name);
            if (this.props.onLoadAction != null) {
                this.props.onLoad(this.props.onLoadAction);
            }
        }
    }

    public render() {
        if (
            this.props.name !== this.props.activeName ||
            this.props.activeName === ""
        ) {
            return null;
        }

        // we listen to a click on the intello modal background, and we
        // also listen for a click on the inside of the modal. that way we can
        //  only hide the modal when you click outside of the modal and not
        // within the modal itself
        /* eslint jsx-a11y/no-static-element-interactions: 0 */
        return (
            <div
                className={`${styles.intelloModal} ${this.props.name}-modal`}
                onClick={() => {
                    if (this.props.onClickOutside) {
                        this.props.onClickOutside();
                    }
                    this.props.onHide();
                }}
            >
                <div className={styles.intelloModalBody}>
                    <div
                        className={styles.intelloModalWrapper}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={this.props.style}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ modal }: State): ModalState {
    return {
        ...modal,
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators(
        {
            onHide: hideModal,
            onLoad,
        },
        dispatch
    );
}

export const ModalCC: React.ComponentType<ModalProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);

import { routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState } from "../types";

// // // //

export const usersState = (route: RouteState): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.users,
                query: {},
            },
            as: routes.users,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "users",
            },
        },
        enabled: true,
        active: route.nextPathname === routes.users,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Users" },
        },
    };
};

export const userNameState = (route: RouteState): BreadcrumbState => {
    const email: string = route.pathParams.userID;
    return {
        enabled: true,
        active: false,
        resolverProps: {
            type: DisplayResolverTypes.primaryIntegrationUser,
            params: { email },
        },
    };
};

export const userSpendState = (_route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: false,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Spend" },
        },
    };
};

export const userUsageState = (_route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: false,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Usage" },
        },
    };
};

export const userIndSpendState = (_route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Payments" },
        },
    };
};

export const userIndSpendChartState = (_route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Chart" },
        },
    };
};

import { routes, connectedAppsIndividualApplications } from "@src/routes";
import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState } from "../types";

// // // //

export const secureState = (_route: RouteState): BreadcrumbState => ({
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Secure" },
    },
});

export const vendorConnectedAppListingState = (
    route: RouteState
): BreadcrumbState => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);

    return {
        linkProps: {
            href: {
                pathname: routes.connectedAppsIndividualApplications,
                query: { vendorID: String(vendorID) },
            },
            as: connectedAppsIndividualApplications(vendorID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "connectedAppsIndividualApplications",
                vendorID,
            },
        },
        enabled: true,
        active:
            route.nextPathname === routes.connectedAppsIndividualApplications,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Connected Apps" },
        },
    };
};

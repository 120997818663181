import { routes } from "@src/routes";

// // // //

/**
 * routeNamesMap
 * Maps a Next.js pathname to an optional "name" value we can include in our analytics calls
 * NOTE - these values cannot be changed, as we depend them being constant
 * in order to receive notifications when a new integration is setup.
 */
export const routeNamesMap: {
    [key: string]: string | undefined;
} = {
    [routes.integrationIndividualSuccess]: "integration individual success",
    [routes.integrationIndividualDash]: "integration individual dashboard",
    [routes.integrationIndividualAuth]: "integration individual creds",
};

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import get from "lodash.get";
import * as React from "react";
import { Query } from "react-apollo";
import {
    buildOrgTagsFetcherRequest,
    ORG_TAGS_FETCHER_QUERY,
    OrgTagsFetcherRequest,
    OrgTagsFetcherResponse,
} from "./graphql";
import { OrgTag } from "./types";

// // // //

/**
 * OrgTagsFetcherLayout
 * Props for OrgTagsFetcher
 */
interface OrgTagsFetcherProps {
    children: (childProps: {
        loading: boolean;
        orgTags: OrgTag[];
    }) => React.ReactNode;
}

/**
 * OrgTagsFetcherLayout
 * Fetches OrgTags from the API
 * @param props - see OrgTagsFetcherProps
 */
export function OrgTagsFetcherLayout(
    props: OrgTagsFetcherProps & { user: UserState }
) {
    const { user } = props;

    return (
        <Query<OrgTagsFetcherResponse, OrgTagsFetcherRequest>
            query={ORG_TAGS_FETCHER_QUERY}
            variables={buildOrgTagsFetcherRequest({
                organizationID: user.organizationID,
            })}
        >
            {({ loading, error, data }) => {
                // Handle loading state
                if (loading) {
                    return props.children({
                        loading: true,
                        orgTags: [],
                    });
                }

                // Handle error state
                if (error) {
                    return <GraphQlErrorHandler error={error} />;
                }

                // Safely pulls OrgTags from response
                let orgTags: OrgTag[] | null = get(
                    data,
                    "workflows.orgTags.list.results",
                    null
                );

                // Handle empty API response
                if (orgTags === null) {
                    orgTags = [];
                }

                // Renders props.children
                return props.children({
                    loading: false,
                    orgTags,
                });
            }}
        </Query>
    );
}

// // // //

export const OrgTagsFetcher: React.ComponentType<OrgTagsFetcherProps> = withUser(
    OrgTagsFetcherLayout
);

import {
    FieldType,
    FilterExpression,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import {
    formatMoment,
    genMoment,
    INVALID_DATE_STRING,
} from "@src/util/date_util";
import { DateInputValue } from "./types";

// // // //

/**
 * isDateFieldType
 * Returns a boolean indicating whether or not the `fieldType` is used with the DateInput
 * @param fieldType - the FieldType being checked
 */
export function isDateFieldType(fieldType: FieldType): boolean {
    return [
        FieldType.advanced_date_picker,
        FieldType.advanced_past_date_picker,
    ].includes(fieldType);
}

/**
 * isDateFilterExpression
 * Returns a boolean indicating whether or not the `expression` param is used with the DateInput
 * @param expression - the FilterExpression that's being checked
 */
export function isDateFilterExpression(expression: FilterExpression): boolean {
    return [
        FilterExpression.adp_upe,
        FilterExpression.adp_pde,
        FilterExpression.adp_fde,
        FilterExpression.adp_ude,
        FilterExpression.adp_dde,
    ].includes(expression);
}

/**
 * isDateInputDataValid
 * Returns a boolean indicating whether or not the value/expression pair is valid
 * @param props.value - the value from DateInput component
 * @param props.expression - the expression associated with props.value
 */
export function isDateInputDataValid(props: {
    value: DateInputValue;
    expression: FilterExpression;
}): boolean {
    const { value, expression } = props;

    // Return false if expression isn't invalid for DateInput
    if (!isDateFilterExpression(expression)) {
        return false;
    }

    // Return false if value is NOT an array
    if (!Array.isArray(value)) {
        return false;
    }

    // Handle "Undefined" + "Defined" expressions
    if (
        [FilterExpression.adp_ude, FilterExpression.adp_dde].includes(
            expression
        )
    ) {
        // Ensure value is an empty array
        return value.length === 0;
    }

    // Handle "Fixed" expressions
    if (expression === FilterExpression.adp_fde) {
        // Handle Fixed with single date
        if (value.length === 1) {
            const fixedDate = value[0];
            return (
                typeof fixedDate === "string" &&
                formatMoment(genMoment(fixedDate)) !== INVALID_DATE_STRING
            );
        }

        // Handle Fixed with start + end date
        const startDate = value[0];
        const endDate = value[1];
        return (
            value.length === 2 &&
            typeof startDate === "string" &&
            typeof endDate === "string" &&
            formatMoment(genMoment(startDate)) !== INVALID_DATE_STRING &&
            formatMoment(genMoment(endDate)) !== INVALID_DATE_STRING &&
            genMoment(startDate) < genMoment(endDate)
        );
    }

    // Handle "Upcoming" + "Past" expressions
    // Ensure value is an array is numeric and length is either 1 or 2
    if (value.length === 1) {
        return (
            typeof value[0] === "number" && Number.isInteger(Number(value[0]))
        );
    }
    if (value.length === 2) {
        return (
            typeof value[0] === "number" &&
            typeof value[1] === "number" &&
            Number.isInteger(Number(value[0])) &&
            Number.isInteger(Number(value[1])) &&
            Number(value[0]) < Number(value[1])
        );
    }

    // Return false for scenarios not handled here
    return false;
}

import { purchaseOrderDetails, routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const purchaseOrderDetailsState = (route: RouteState) => {
    const { purchaseOrderID } = route.pathParams;
    return {
        linkProps: {
            href: {
                pathname: routes.purchaseOrderDetails,
                query: { purchaseOrderID },
            },
            as: purchaseOrderDetails(purchaseOrderID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "Purchase Order Details",
                purchaseOrderID,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.purchaseOrderDetails,
        resolverProps: {
            type: DisplayResolverTypes.purchaseOrder,
            params: { purchaseOrderID },
        },
    };
};

import { RouteState } from "@src/shared_modules/router/component";
import { BreadcrumbNode } from "./types";

// // // //

interface Props {
    /**
     * Route State
     */
    route: RouteState;
    /**
     * Collection that aggregates breadcrumb data until getBreadcrumbData reaches base case (highest level route)
     */
    breadcrumbNodes: BreadcrumbNode[];
    /**
     * This will be set to the "next" (i.e. parent) breadcrumb each time getBreadcrumbData is called
     */
    currentNode: BreadcrumbNode;
}

// Builds an array of Breadcrumbs recursively, starting with the current route and working up the route hierarchy
export function getBreadcrumbNodes(props: Props): BreadcrumbNode[] {
    const { route, breadcrumbNodes, currentNode } = props;

    // Add new breadcrumb data to the cache
    const aggregatedData: BreadcrumbNode[] = [currentNode, ...breadcrumbNodes];

    // Base case will return array of breadcrumb data
    if (currentNode.parent === null) {
        return aggregatedData;
    }

    // If breadcrumb has a parent property, invoke getBreadcrumbData with
    return getBreadcrumbNodes({
        route,
        breadcrumbNodes: aggregatedData,
        currentNode: currentNode.parent,
    });
}

import { OffsetPaginationInput } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";

/**
 * FORCE_REQUERY_PARAM
 * Defines the name of a param in RouteState.params that's used when we need specific
 * components that depend on FiltersInput state from the AdvancedFiltersProvider
 * to re-query for their data after some update. NOTE - a component __must__ implement its own
 * re-query logic when handling this param. See the `VendorUsersTable` component from UsageIndividual for more details
 */
export const FORCE_REQUERY_PARAM: string = "forceRequery";

/**
 * SHOW_OVERLAY_PARAM
 * Defines the name of a param in RouteState.params that's used to show/hide the `AdvancedFiltersEditorOverlay` component
 * See `AdvancedFilters` component for more details
 */
export const SHOW_OVERLAY_PARAM: string = "showFiltersOverlay";

// Defines default OffsetPaginationInput.page & OffsetPaginationInput.itemsPerPage
export const DEFAULT_SORT: string = "";
export const DEFAULT_DIRECTION: string = "";
export const DEFAULT_PAGE: number = 1;
export const DEFAULT_ITEMS_PER_PAGE: number = 25;

/**
 * DEFAULT_PAGINATION
 * Defines default OffsetPaginationInput value
 */
export const DEFAULT_PAGINATION: OffsetPaginationInput = {
    sort: DEFAULT_SORT,
    direction: DEFAULT_DIRECTION,
    page: DEFAULT_PAGE,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

import React from "react";

export function Auth(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="21"
            viewBox="0 0 17 21"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="currentColor"
                    fillRule="nonzero"
                    transform="translate(-27 -207)"
                >
                    <g transform="translate(27 207)">
                        <path d="M16.995 2.924v-.146a.875.875 0 00-.617-.836C16.314 1.922 8.744 0 8.5 0c-.06 0-.12.006-.18.018C8.277.028.76 1.9.623 1.942a.875.875 0 00-.618.836v.15C.008 5.116.012 9.112 0 11.278c-.011 2.16.81 4.13 2.44 5.854 1.435 1.518 3.232 2.739 5.494 3.73.12.058.328.138.566.138.167 0 .352-.044.544-.13 2.271-.991 4.076-2.214 5.515-3.738 1.631-1.724 2.452-3.694 2.44-5.855-.011-2.168-.007-6.165-.004-8.353zm-3.717 13.01c-1.239 1.31-2.8 2.378-4.78 3.263-1.973-.882-3.537-1.95-4.778-3.264-1.33-1.406-1.971-2.926-1.962-4.647.011-2.004.008-5.57.005-7.828C3.389 3.05 7.567 2.006 8.5 1.775c.952.235 5.112 1.274 6.736 1.682-.003 2.26-.006 5.823.005 7.83.01 1.72-.633 3.24-1.963 4.647zM12.13 6.317l-4.554 5.394-2.57-2.301a.883.883 0 00-1.242.065.872.872 0 00.066 1.235l3.246 2.907a.882.882 0 001.26-.088l5.14-6.086a.871.871 0 00-.108-1.233.881.881 0 00-1.238.107z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

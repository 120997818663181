import {
    trackAddOrgTag,
    trackRemoveOrgTag,
} from "@src/analytics/org_saved_views";
import { ContentLoader } from "@src/shared_modules/content_loader";
import { captureMessage } from "@src/util/analytics";
import classnames from "classnames";
import * as React from "react";
import ReactTags from "react-tag-autocomplete";
import { SaveOrgTagMutationResponse } from "./graphql";
import { OrgTagsFetcher } from "./OrgTagsFetcher";
import { SaveOrgTagMutation } from "./SaveOrgTagMutation";
import styles from "./styles.module.css";
import { OrgTag } from "./types";

// // // //

/**
 * InternalTag
 * Interface to describe the Tag used by the ReactTags component. Only used internally to this component.
 */
interface InternalTag {
    id: string;
    name: string;
}

/**
 * OrgTagsInput
 * Props for OrgTagsInputLayout
 */
interface OrgTagsInputLayoutProps {
    value: string[];
    orgTags: OrgTag[];
    onAddition: (tagToAdd: InternalTag) => void;
    onCreate: (name: string) => void;
    onRemove: (tagToRemove: InternalTag) => void;
}

/**
 * OrgTagsInputLayout
 * Implements the ReactTags component without any GQL components
 * Handles formatting
 * @param props - see OrgTagsInputLayoutProps
 */
export function OrgTagsInputLayout(props: OrgTagsInputLayoutProps) {
    const { value, orgTags } = props;

    // Builds suggestions from props.orgTags
    const suggestions: InternalTag[] = orgTags.map((orgTag) => {
        return {
            id: orgTag.id,
            name: orgTag.name,
        };
    });

    // Builds "tags" prop for <ReactTags />
    const selectedTags: InternalTag[] = suggestions.filter((tag) => {
        return value.includes(tag.id);
    });

    // Ensures currently selected OrgTags are not included in suggestions
    const filteredSugestions: InternalTag[] = suggestions.filter((tag) => {
        return !value.includes(tag.id);
    });

    return (
        <ReactTags
            placeholderText="Enter tags"
            tags={selectedTags}
            suggestions={filteredSugestions}
            allowNew={true}
            autofocus={true}
            minQueryLength={1}
            noSuggestionsText="+ Enter to Add New"
            classNames={{
                root: classnames(
                    "bg-white rounded-btn font-secondary font-size-14-px border-grey px-5-px",
                    styles.tagsInput
                ),
                selected: "d-inline",
                selectedTag: classnames(
                    "mr-5-px text-updated-black rounded-btn tw-bg-transparent outline-none",
                    styles.selectedTag
                ),
                search: classnames("d-inline-block", styles.tagsSearch),
                searchInput: classnames(
                    "m-0 p-0 border-none outline-none",
                    styles.tagsSearchInput
                ),
                suggestions: classnames(
                    "w-100 text-updated-black",
                    styles.tagsSuggestions
                ),
                suggestionActive: classnames(
                    "w-100 text-updated-black",
                    styles.tagsSuggestionActive
                ),
                suggestionDisabled: classnames(
                    "w-100 text-updated-black",
                    styles.tagsSuggestionDisabled
                ),
            }}
            onAddition={(tagToAdd: { id?: string; name: string }) => {
                // Selected an existing OrgTag? Invoke props.onAddition
                if (tagToAdd.id !== undefined) {
                    props.onAddition({
                        id: tagToAdd.id,
                        name: tagToAdd.name,
                    });
                    return;
                }

                // If tagToAdd doesn't have an ID, ensure that it's name doesn't on another suggestion
                // NOTE - we perform a case-insensitive check here beacuse the API enforces case sensitivity
                // NOTE - this is extra step is necessary beacuse it's possible to submit the name
                // of an existing InternalTag without directly selecting
                // it (along with its ID) from the list of available suggestions
                const matchedSuggestion:
                    | InternalTag
                    | undefined = suggestions.find(
                    (s) => s.name.toLowerCase() === tagToAdd.name.toLowerCase()
                );

                // If matched suggestion has been found defined -> ensure ID isn't already in props.value
                if (matchedSuggestion !== undefined) {
                    // Short-circuit function if matchedSuggestion.id is already in props.value
                    if (value.includes(matchedSuggestion.id)) {
                        return;
                    }

                    // Invoke props.onAddition with matchedSuggestion
                    props.onAddition(matchedSuggestion);

                    // Tracks the add event
                    trackAddOrgTag(tagToAdd.name);

                    // Short-circuits the execution
                    return;
                }

                // Tracks the add event
                trackAddOrgTag(tagToAdd.name);

                // Invoke props.onCreate for new OrgTag
                props.onCreate(tagToAdd.name);
            }}
            onDelete={(indexToRemove: number) => {
                // Locate the OrgTag ID to remove
                const tagToRemove: InternalTag | undefined =
                    selectedTags[indexToRemove];

                // If tagToRemove is undefined -> captureMessage and short-circuit
                if (tagToRemove === undefined) {
                    captureMessage(
                        "OrgTagsInput - cannot locate OrgTag to remove",
                        {
                            extra: {
                                indexToRemove,
                                selectedTags,
                            },
                        }
                    );
                    return;
                }

                // Tracks the remove event
                trackRemoveOrgTag(tagToRemove.name);

                // Remove the OrgTag
                props.onRemove(tagToRemove);
            }}
        />
    );
}

// // // //

/**
 * OrgTagsInputLoader
 * Handles the loading state inside OrgTagsInput
 */
export function OrgTagsInputLoader() {
    return (
        <ContentLoader>
            <div className={styles.tagsInputLoader}>Loading...</div>
        </ContentLoader>
    );
}

// // // //

/**
 * OrgTagsInput
 * Props for OrgTagsInput
 */
interface OrgTagsInputProps {
    value: string[];
    onChange: (updatedValue: string[]) => void;
}

/**
 * OrgTagsInput
 * Renders OrgTagsInputLayout wrapped in SaveOrgTagMutation and OrgTagsFetcher
 * Handles fetching OrgTag data + creating new OrgTag data from user input
 * @param props - see OrgTagsInputProps
 */
export function OrgTagsInput(props: OrgTagsInputProps) {
    const { value } = props;
    return (
        <SaveOrgTagMutation>
            {({ saveOrgTag }) => (
                <OrgTagsFetcher>
                    {({ loading: queryLoading, orgTags }) => {
                        // Handle OrgTagsFetcher loading state
                        if (queryLoading) {
                            return <OrgTagsInputLoader />;
                        }

                        // Returns the OrgTagsInputLayout
                        return (
                            <OrgTagsInputLayout
                                value={props.value}
                                orgTags={orgTags}
                                onAddition={(addedTag: InternalTag) => {
                                    // Adds addedTag.id to value + invokes props.onChange
                                    props.onChange([...value, addedTag.id]);
                                }}
                                onCreate={(newTagName: string) => {
                                    // Saves the new OrgTag
                                    saveOrgTag({
                                        name: newTagName,
                                        description: "",
                                    }).then(
                                        (result: {
                                            data:
                                                | SaveOrgTagMutationResponse
                                                | undefined;
                                        }) => {
                                            // Short-circuit callback if result is undefined
                                            if (result.data === undefined) {
                                                return;
                                            }

                                            // Add newly created OrgTag to value and invoke props.onChange
                                            props.onChange([
                                                ...value,
                                                result.data.saveOrgTag.id,
                                            ]);
                                        }
                                    );
                                }}
                                onRemove={(tagToRemove: InternalTag) => {
                                    // Removes tagToRemove.id from props.value
                                    const updatedValue = value.filter(
                                        (tagID) => tagID !== tagToRemove.id
                                    );
                                    // Invokes props.onChange
                                    props.onChange(updatedValue);
                                }}
                            />
                        );
                    }}
                </OrgTagsFetcher>
            )}
        </SaveOrgTagMutation>
    );
}

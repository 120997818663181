import { Button } from "@src/shared_modules/button";
import { EmailInput } from "@src/shared_modules/input";
import { Pill } from "@src/shared_modules/pill";
import { isValidEmail } from "@src/util/validators";
import * as React from "react";

// // // //

/**
 * excludeInvalidEmails
 * Accepts array of recipients and removes any values that aren't valid emails
 */
function excludeInvalidEmails(emails: string[]): string[] {
    return emails.filter((r) => {
        const isValid = isValidEmail(r);
        return isValid;
    });
}

/**
 * SyncStatusRecipientsForm
 * Input component to enter email addresses of integration sync status recipients
 * @param props.recipients - array of recipient emails
 * @param props.disabled - disable the form when update is in-progress
 * @param props.onSubmit - callback to update the set of recipient emails
 */
export function SyncStatusRecipientsForm(props: {
    recipients: string[];
    disabled: boolean;
    onSubmit: (updatedRecipients: string[]) => void;
    onCancel: () => void;
}) {
    const { disabled, onSubmit, onCancel } = props;
    const [newRecipient, setNewRecipient] = React.useState("");
    const [recipients, setRecipients] = React.useState(props.recipients);

    return (
        <React.Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    // Short-circuit if recipient is not a valid email address
                    if (!isValidEmail(newRecipient)) {
                        return;
                    }

                    // Short-circuit if recipient is already in list
                    if (recipients.includes(newRecipient)) {
                        return;
                    }

                    // Update recipients array
                    setRecipients([
                        ...excludeInvalidEmails(recipients),
                        newRecipient,
                    ]);

                    // Clear the TextInput
                    setNewRecipient("");
                }}
            >
                <EmailInput
                    value={newRecipient}
                    onChange={(updatedValue) => setNewRecipient(updatedValue)}
                    placeholder="Type and press enter to add an email address"
                />
            </form>

            <div className="d-flex flex-wrap tw-my-8 tw-min-h-[6rem] tw-gap-3">
                {/* Renders a <Pill /> for each recipient */}
                {recipients.map((r) => {
                    return (
                        <Pill
                            textClassName="tw-text-gray-3"
                            key={r}
                            label={r}
                            onClickRemove={() => {
                                const updatedRecipients = recipients.filter(
                                    (each) => each !== r
                                );
                                setRecipients(updatedRecipients);
                            }}
                        />
                    );
                })}
            </div>

            <div className="tw-mt-4 tw-flex tw-justify-end tw-gap-4 tw-items-center">
                <p className="tw-text-xl tw-text-gray-500">
                    To send notifications only to the creator of instances, do
                    not add any recipient emails.
                </p>
                <Button
                    label="Cancel"
                    disabled={disabled}
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                        onCancel();
                    }}
                />

                <Button
                    label="Save"
                    disabled={disabled}
                    size="sm"
                    onClick={() => {
                        onSubmit(recipients);
                    }}
                />
            </div>
        </React.Fragment>
    );
}

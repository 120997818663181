import * as React from "react";
import { connect } from "react-redux";
import { ListApi } from "@src/requests/list";
import { captureException } from "@src/util/analytics";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { buildQsFromObject } from "@src/util/route";
import classnames from "classnames";
import { Loader } from "../loader";
import { onFetch, results, updateItem, DispatchIntello } from "./action";
import { ListState } from "./reducer";
import styles from "./styles.module.css";
import { QueryParams } from "../../util/route/updateParams_util";

// // // //

export interface State {
    [key: string]: ListState<any>;
}

export type Direction = "asc" | "desc";

export interface FetchProps {
    modelType: string; // this modelType should correspond to the reducer name that's passed,
    children?: React.ReactNode;
    path: string;
    // useQs indicates whether the dashboard listing should use query strings when making a request -
    // by default this is true, but there are certain situations where we may just want to return all results
    useQs?: boolean;
    // showLoader indicates whether we should a loader while loading
    showLoader?: boolean;
}

export interface Props {
    list: ListState<any>;
}

export interface DispatchProps {
    onFetch: (
        qs: string,
        lastURLFetch: string | null,
        modelType: string,
        route: string,
        appName: string,
        useQs?: boolean,
        lastURLFetchTime?: number | null
    ) => void;
    results: (
        res: ListApi<any>,
        modelType: string,
        fetchTime?: number | null
    ) => void;
    updateItem: (
        id: string,
        idValue: any,
        value: any,
        modelType: string
    ) => void;
}

// Header is the header component that should be used in a list for an item that's not sortable
export function Header(props: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <h6 className={`d-flex ${styles.header} ${props.className || ""}`}>
            {props.children}
        </h6>
    );
}

// directionParam is the direction query param key
export const directionParam = "direction";
// sortParam is the sort param key
export const sortParam = "sort";

// reverseDirection - reverses the direction of the current sort and returns whether the current value is ascending or descending
export function reverseDirection(
    params: { [key: string]: any },
    defaultDirection: Direction
): [Direction, boolean, boolean] {
    let direction = params[directionParam];
    if (typeof direction !== "string") {
        direction = defaultDirection;
    }
    const isAsc = direction === "asc";
    direction = isAsc ? "desc" : "asc";
    return [direction, isAsc, !isAsc];
}

// EmptyQueryProps are the props that can be passed to the EmptyQuery component
interface EmptyQueryProps {
    header?: string;
    message?: string;
    className?: string;
}

// EmptyQuery is rendered by default when there are no results to render
export function EmptyQuery(props: EmptyQueryProps) {
    const {
        header = "No results found",
        message = "We cannot find any results matching your search.",
        className = "",
    } = props;
    return (
        <div
            className={classnames(
                "d-flex flex-column tw-rounded-xl bg-white w-100 mt-20-px tw-shadow py-60-px px-30-px",
                className
            )}
        >
            <span className={styles.emptySearchSvg}>
                <Icon type={IconTypes.Empty_Search} />
            </span>
            <h3 className={styles.emptyHeader}>{header}</h3>
            <p className={styles.emptyMessage}>{message}</p>
        </div>
    );
}

// mapStateToProps gets the list reducer we're interested in from the state and passes that into the list
export function mapStateToProps(state: State, ownProps: FetchProps): Props {
    const { modelType } = ownProps;
    // if the modelType isn't in the state then we throw an error
    if (!(modelType in state)) {
        captureException(
            new Error(
                `Asked for modelType ${modelType} that wasn't in the state`
            )
        );
    }

    return {
        list: state[modelType],
    };
}

// mapDispatchToProps generates the dispatch to props for the list component
export function mapDispatchToProps(dispatch: DispatchIntello): DispatchProps {
    return {
        onFetch: (...data) => {
            dispatch(
                onFetch({
                    qs: data[0],
                    lastURLFetch: data[1],
                    modelType: data[2],
                    route: data[3],
                    appName: data[4],
                    useQs: data[5],
                    lastURLFetchTime: data[6],
                })
            );
        },
        results: (...data) => {
            dispatch(results(...data));
        },
        updateItem: (...data) => {
            dispatch(updateItem(...data));
        },
    };
}

// FetchListing is a hoc that will fetch any listing result as well as inject that list into the passed component
export function fetchListing<P>(
    Component: React.ComponentType<any>
): React.ComponentType<any> {
    class List extends React.Component<
        P & DispatchProps & Props & FetchProps & { query: QueryParams },
        {}
    > {
        public componentDidMount() {
            this.fetch();
        }

        public componentDidUpdate() {
            this.fetch();
        }

        public fetch() {
            const qs = buildQsFromObject(this.props.query);
            this.props.onFetch(
                qs,
                this.props.list.lastURLFetch,
                this.props.modelType,
                this.props.path,
                this.props.list.appName,
                this.props.useQs == null ? true : this.props.useQs,
                this.props.list.lastURLFetchTime
            );
        }

        public render() {
            const showLoader =
                this.props.showLoader != null ? this.props.showLoader : true;
            if (this.props.list.loading && showLoader) {
                return <Loader />;
            }

            return <Component {...this.props} />;
        }
    }

    // @ts-ignore
    const ListCC = connect(mapStateToProps, mapDispatchToProps)(List);
    return ListCC;
}

import {
    FiltersInput,
    PaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import classnames from "classnames";
import * as React from "react";
import { DEFAULT_PAGE } from "./constants";
import { getURLWithFiltersInput } from "./getURLWithFiltersInput";
import { isCursorPaginationInput } from "./isCursorPaginationInput";
import styles from "./styles.module.css";

// // // //

/**
 * getFiltersInputWithItemsPerPage
 * Gets a FiltersInput instance with updated PaginationInput.itemsPerPage value
 * Resets PaginationInput.page so we always jump back to the first page of results
 * @param props.filtersInput - the currently applied FiltersInput
 * @param props.itemsPerPage - the itemsPerPage value being set on FiltersInput.pagination.itemsPerPage
 */
export function getFiltersInputWithItemsPerPage(props: {
    filtersInput: FiltersInput;
    itemsPerPage: number;
}): FiltersInput {
    const { filtersInput, itemsPerPage } = props;

    // Defines updated FiltersInput.pagination state w/ selected itemsPerPage value
    // And resets the previous state for PaginationInput.page
    const updatedPagination: PaginationInput = {
        ...filtersInput.pagination,
        page: DEFAULT_PAGE,
        itemsPerPage,
    };

    // Reset pagination.cursorInput when applicable
    if (isCursorPaginationInput(updatedPagination)) {
        updatedPagination.cursorInput = null;
    }

    // Defines + returns the updated FiltersInput state
    const updatedFiltersInput: FiltersInput = {
        ...filtersInput,
        pagination: {
            ...updatedPagination,
        },
    };

    return updatedFiltersInput;
}

/**
 * ItemsPerPageProps
 * @param filtersInput - `FiltersInput` instance passed down from the parent
 * @param itemsPerPageOptions - (optional) array of possible numbers of items per page
 * @param all - (optional) number of items per page to set when the `All` link is active
 */
interface ItemsPerPageProps {
    filtersInput: FiltersInput;
    itemsPerPageOptions?: number[];
    all?: number;
}
/**
 * ItemsPerPage
 * Renders the number of items per page switcher based on a given `FiltersInput` instance
 * @param props - see ItemsPerPageProps
 */
function ItemsPerPage(props: ItemsPerPageProps & { route: RouteState }) {
    const { filtersInput, route, itemsPerPageOptions = [10, 20], all } = props;

    // FiltersInput.pagination is nullable - return null if it's undefined
    if (!filtersInput.pagination) {
        return null;
    }

    return (
        <ul className="d-flex font-secondary font-size-14-px text-updated-black">
            <h5 className="font-secondary font-size-14-px">SHOW</h5>
            {itemsPerPageOptions.map((itemsPerPageOption) => {
                return (
                    <ItemsPerPageOption
                        key={itemsPerPageOption}
                        value={itemsPerPageOption}
                        filtersInput={filtersInput}
                        route={route}
                    />
                );
            })}
            {all !== undefined && (
                <ItemsPerPageOption
                    value={all}
                    filtersInput={filtersInput}
                    route={route}
                >
                    All
                </ItemsPerPageOption>
            )}
        </ul>
    );
}

/**
 * ItemsPerPageOptionProps
 * @param value - the number of items per page to set when this option is active
 * @param filtersInput - `FiltersInput` instance passed down from the `ItemsPerPage` component
 * @param route - RouteState from withRouter HOC
 * @param children - (optional) if passed, children are rendered instead of the `value` property
 */
interface ItemsPerPageOptionProps {
    value: number;
    filtersInput: FiltersInput;
    route: RouteState;
    children?: string;
}

/**
 * ItemsPerPageOption
 * Renders a single option inside the `ItemsPerPage` component
 * @param props - ItemsPerPageOptionProps
 */
function ItemsPerPageOption(props: ItemsPerPageOptionProps) {
    const { value, filtersInput, route, children } = props;

    // Don't wrap in a link if the option is currently active
    const { itemsPerPage } = filtersInput.pagination;
    if (itemsPerPage === value) {
        return (
            <li
                className={classnames({
                    "ml-10-px cursor-default": true,
                    [styles.itemsPerPageLink]: true,
                })}
            >
                {children || value}
            </li>
        );
    }

    // Defines the updated FiltersInput state with new itemsPerPage value
    const updatedFiltersInput: FiltersInput = getFiltersInputWithItemsPerPage({
        filtersInput,
        itemsPerPage: value,
    });

    // Gets the URL with the latest FiltersInput + pagination state
    const url: string = getURLWithFiltersInput({
        location: route.location,
        params: route.params,
        filtersInput: updatedFiltersInput,
    });

    return (
        <li>
            <LinkCC
                href={{
                    pathname: route.nextPathname,
                    query: route.pathParams,
                }}
                as={url}
                className={classnames({
                    "ml-10-px text-grey": true,
                    [styles.itemsPerPageLink]: true,
                })}
            >
                {children || value}
            </LinkCC>
        </li>
    );
}

export const ItemsPerPageCC: React.ComponentType<ItemsPerPageProps> = withRouter(
    ItemsPerPage
);

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import get from "lodash.get";
import * as React from "react";
import { Query } from "react-apollo";
import {
    buildOrgFieldQueryFilters,
    ORG_FIELD_QUERY,
    OrgFieldQueryFilters,
    OrgFieldQueryResponse,
} from "./graphql";
import { OrganizationField } from "./types";

// // // //

/**
 * OrgFieldFetcherLayout
 * Fetches the organization fields to render
 */
interface OrgFieldFetcherProps {
    children: (childProps: {
        loading: boolean;
        orgFields: OrganizationField[];
    }) => React.ReactNode;
}

/**
 * OrgFieldFetcherLayout
 * Fetches organization field for a given org and passes them to the children
 * @param props - see OrgFieldFetcherLayout
 */
export function OrgFieldFetcherLayout(
    props: OrgFieldFetcherProps & { user: UserState }
) {
    const { user } = props;

    return (
        <Query<OrgFieldQueryResponse, OrgFieldQueryFilters>
            query={ORG_FIELD_QUERY}
            variables={buildOrgFieldQueryFilters(user.organizationID)}
        >
            {({ loading, error, data }) => {
                let orgFields: OrganizationField[] | null = get(
                    data,
                    "spend.customFields",
                    null
                );

                if (orgFields === null) {
                    orgFields = [];
                }

                if (orgFields.length === 0 && loading === false) {
                    captureMessage("OrgFieldFetcher no org fields found", {});
                }

                // Renders props.children + error handler
                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({ loading, orgFields })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

export const OrgFieldFetcher: React.ComponentType<OrgFieldFetcherProps> = withUser(
    OrgFieldFetcherLayout
);

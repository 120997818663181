import { trackFilterApplied } from "@src/analytics/base";
import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { updateParams } from "@src/util/route";
import * as React from "react";
import { SearchInput } from "../input";

// // // //

/**
 * SearchFilter
 * Search bar that we use through search in the dashboard - it automatically updates the route as a user is typing.
 */
export function SearchFilter(props: { searchName: string; route: RouteState }) {
    const { route, searchName } = props;

    // handleUpdates handles the search filter update that can come from a change of the input
    function handleUpdate(updatedValue: string) {
        // Sanitizes the current value (defaults to empty string)
        const sanitizedValue = updatedValue.trim() !== "" ? updatedValue : "";

        // generate the params for the query that will come next
        const [params] = updateParams({
            params: route.params,
            key: searchName,
            val: sanitizedValue,
            replace: true,
        });

        // Delete params[props.searchName] if the
        // assigned value is an empty string, just to be safe
        if (sanitizedValue === "") {
            delete params[searchName];
        }

        // reset the page param when the filter is applied
        delete params.page;
        const url = buildUrlWithParams({
            location: route.location,
            params,
        });

        // Track the filter + value applied
        trackFilterApplied(searchName, value, url);

        // Update the current route
        route.updateRouteAction(url, route.nextPathname);
    }

    // Pull current value from props.route
    const searchVal = route.params[searchName];
    const value = typeof searchVal === "string" ? searchVal : "";

    return (
        <div className="tw-pr-3 last-child:tw-pr-0">
            <form
                className="tw-relative d-flex align-items-center input-wrapper"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdate(value);
                }}
            >
                <SearchInput
                    placeholder="Search"
                    value={value}
                    debounceTimeoutMs={300}
                    onChange={(val) => handleUpdate(val)}
                />
            </form>
        </div>
    );
}

export const SearchFilterCC = withRouter(SearchFilter);

import { IntegrationService } from "@src/schema/IntegrationService";
import { IntegrationsFetcher } from "@src/modules/integrations/components/integrations_fetcher";
import { captureMessage } from "@src/util/analytics";
import React from "react";

import { ResolverProps } from "./component";

// // // //

// Returns an integration service that maps to a given source
export function IntegrationResolver(props: ResolverProps<IntegrationService>) {
    const { source } = props.params;

    if (source === undefined) {
        captureMessage(`SourceResolver - params.source is undefined`);
        return null;
    }

    const { loadingPlaceholder = "...", errorFallback = null } = props;

    return (
        <IntegrationsFetcher>
            {({ loading, getIntegration }) => {
                const integration:
                    | IntegrationService
                    | undefined = getIntegration(source);

                if (props.children) {
                    return props.children({ value: integration, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (integration === undefined) {
                    captureMessage(
                        `Integration for source: ${source} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return (
                    <React.Fragment>{integration.content.name}</React.Fragment>
                );
            }}
        </IntegrationsFetcher>
    );
}

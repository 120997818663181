import classnames from "classnames";
import React from "react";

import { LinkCC } from "../router";
import { BreadcrumbState } from "./types";

// // // //

function TrailingChevron() {
    return (
        <i
            className={classnames(
                "fa fa-xs fa-chevron-right", // Font-awesome
                "tw-h-4 tw-w-4 tw-text-base", // Size
                "tw-text-gray-400 ", // Color
                "tw-mx-8  tw-mb-1" // Spacing
            )}
            aria-hidden="true"
        />
    );
}

export function Breadcrumb(props: {
    breadcrumb: BreadcrumbState;
    isLastBreadcrumb: boolean;
    children: React.ReactNode;
}) {
    const { breadcrumb, isLastBreadcrumb } = props;
    const { analytics = {} } = breadcrumb;

    // Render LinkCC breadcrumb
    if (!breadcrumb.active) {
        return (
            <div className="tw-flex tw-items-center tw-font-medium">
                {/* Render LinkCC IFF linkProps is present */}
                {breadcrumb.linkProps !== undefined && (
                    <LinkCC {...props.breadcrumb.linkProps} {...analytics}>
                        {props.children}
                    </LinkCC>
                )}

                {/* Render non-clickable breadcrumb if linkProps is NOT defined */}
                {breadcrumb.linkProps === undefined && (
                    <span className="tw-cursor-default">{props.children}</span>
                )}

                {/* Trailing Slash */}
                {!isLastBreadcrumb && <TrailingChevron />}
            </div>
        );
    }

    // Render non-clickable breadcrumb
    return (
        <div className="tw-flex tw-items-center tw-font-medium">
            <span
                className={classnames(
                    {
                        ["tw-text-gray-600"]: isLastBreadcrumb,
                        ["tw-text-gray-500"]: !isLastBreadcrumb,
                    },
                    "tw-cursor-default"
                )}
            >
                {props.children}
            </span>
            {!isLastBreadcrumb && <TrailingChevron />}
        </div>
    );
}

import { connectedAppsIndividualUsers, routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const vendorConnectedAppIndividualState = (route: RouteState) => {
    const { pathParams } = route;
    const vendorID = parseInt(pathParams.vendorID, 10);
    const { connectedAppID } = pathParams;

    return {
        linkProps: {
            href: {
                pathname: routes.connectedAppsIndividualUsers,
                query: { vendorID: String(vendorID), connectedAppID },
            },
            as: connectedAppsIndividualUsers(vendorID, connectedAppID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "connectedAppsIndividualUsers",
                vendorID,
                connectedAppID,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.connectedAppsIndividualUsers,
        resolverProps: {
            type: DisplayResolverTypes.connectedApp,
            params: { connectedAppID },
        },
    };
};

import { BreadcrumbNode } from "../types";
import { teamIndState } from "./state";
import { usageIndividualNode } from "../usageIndividual";

// // // //

export const teamIndNode: BreadcrumbNode = {
    getState: teamIndState,
    parent: usageIndividualNode,
};

import React from "react";

export function Usage(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="currentColor" transform="translate(-28 -227)">
                    <g transform="translate(28 227)">
                        <circle cx="8" cy="3.429" r="3.429"></circle>
                        <circle cx="12.571" cy="12.571" r="3.429"></circle>
                        <circle cx="3.429" cy="12.571" r="3.429"></circle>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { track } from "@src/analytics/track";

// // // //

enum DashboardEvents {
    closedNotification = "Closed Notification Bar",
    requestAccess = "Request Access Feature Flag",
}

export enum DashComponentNames {
    nav = "nav",
    navLogo = "nav-logo",
    secCompListing = "security-compliance-listing",
}

// trackRequestAccess tracks that a user requested access to a feature flag
export function trackRequestAccess(featureName: string, type: string) {
    track(DashboardEvents.requestAccess, {
        feature_name: featureName,
        type,
    });
}

// closedNotificationBar tracks that a user closed the notification bar
export function closedNotificationBar(name: string) {
    track(DashboardEvents.closedNotification, {
        name,
    });
}

import getConfig from "next/config";

interface WebConfig {
    staticHost: string;
    contentfulSpaceID: string;
    contentfulAccessToken: string;
    contentfulDashboardSpaceID: string;
    contentfulDashboardAccessToken: string;
    plaidEnv: string;
    launchDarklyKey: string;
    googleClientID: string;
    apiHost: string;
    doraHost: string;
    nemoHost: string;
    geltHost: string;
    gwHost: string;
    webHost: string;
    appHost: string;
    appEnv: "development" | "staging" | "production";
    env: "development" | "production";
    googleTagManagerID: string;
    pendoAnalyticsKey: string;
    datadogRumAppID: string;
    datadogRumClientToken: string;
    datadogRumServiceName: string;
}

// Gets publicRuntimeConfig from Next.js getConfig
const { publicRuntimeConfig } = getConfig();

// Pulls requisite values from publicRuntimeConfig
const {
    staticHost,
    contentfulSpaceID,
    contentfulAccessToken,
    contentfulDashboardSpaceID,
    contentfulDashboardAccessToken,
    plaidEnv,
    launchDarklyKey,
    googleClientID,
    apiHost,
    doraHost,
    geltHost,
    nemoHost,
    gwHost,
    webHost,
    appHost,
    appEnv,
    nodeEnv,
    googleTagManagerID,
    pendoAnalyticsKey,
    datadogRumAppID,
    datadogRumClientToken,
    datadogRumServiceName,
} = publicRuntimeConfig;

// Exports the webConfig
export const webConfig: WebConfig = {
    staticHost,
    contentfulSpaceID,
    contentfulAccessToken,
    contentfulDashboardSpaceID,
    contentfulDashboardAccessToken,
    plaidEnv,
    launchDarklyKey,
    googleClientID,
    apiHost,
    doraHost,
    geltHost,
    nemoHost,
    gwHost,
    webHost,
    appHost,
    appEnv,
    env: nodeEnv,
    googleTagManagerID,
    pendoAnalyticsKey,
    datadogRumAppID,
    datadogRumClientToken,
    datadogRumServiceName,
};

import React from "react";

export function FieldDropdown(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="15"
            viewBox="0 0 49 15"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <g
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(-245 -166)"
                >
                    <g transform="translate(245 166)">
                        <path d="M46.61 0H2.39C1.07 0 0 1.033 0 2.308v10.384C0 13.967 1.07 15 2.39 15h44.22c1.32 0 2.39-1.033 2.39-2.308V2.308C49 1.033 47.93 0 46.61 0zm1.195 12.692c0 .638-.535 1.154-1.195 1.154H2.39c-.66 0-1.195-.516-1.195-1.154V2.308c0-.638.535-1.154 1.195-1.154h44.22c.66 0 1.195.516 1.195 1.154v10.384zm-3.986-7.54a.563.563 0 01.084.813l-2.988 3.531a.606.606 0 01-.926 0L37 5.966a.563.563 0 01.09-.814.612.612 0 01.836.08l2.528 2.983 2.522-2.982a.612.612 0 01.843-.08zm-32.465-.537a.588.588 0 01-.598.577H5.378a.588.588 0 01-.598-.577c0-.318.268-.577.598-.577h5.378c.33 0 .598.259.598.577zm0 2.885a.588.588 0 01-.598.577H5.378A.588.588 0 014.78 7.5c0-.319.268-.577.598-.577h5.378c.33 0 .598.258.598.577zm0 2.885a.588.588 0 01-.598.577H5.378a.588.588 0 01-.598-.577c0-.319.268-.577.598-.577h5.378c.33 0 .598.258.598.577z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

/**
 * @name linkFromUrlQs
 * @description Builds a query string from an object. This allows us to easily meet
 * the way tornado constructs routes
 * @param {string} pathname
 * @param {string} queryString
 * @returns {string}
 */
// A utility method to generate a url from a path and a query string and ensure
// that we're not inlcuding a question mark twice
export function linkFromUrlQs(pathName: string, queryString: string): string {
    let qs: string = queryString;
    if (qs.startsWith("?")) {
        qs = qs.substring(1);
    }
    let path = pathName;
    if (path.endsWith("?")) {
        path = path.substring(path.length - 1, 0);
    }

    if (path.indexOf("?") >= 0) {
        return `${path}&${qs}`;
    }
    return `${path}?${qs}`;
}

import { genApiOpts, genApiUrl, request } from "@src/util/request_util";
import "isomorphic-fetch";
import { trackFormRes, trackFormSubmitted } from "../analytics/forms";

// // // //

type MethodType = "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS";

// submitForm submits a body to a given url and the form submission, form error or form successful depending on the outcome of the submission
export async function submitForm(
    url: string,
    body: { [key: string]: any },
    formName: string,
    method: MethodType,
    formHost: string
): Promise<any> {
    const submitTime = trackFormSubmitted(formName, url);

    return request(
        url,
        {
            method,
            body: JSON.stringify(body),
        },
        formHost
    )
        .then((response: Response) => response.json())
        .then((res: any) => trackFormRes(res, formName, submitTime, url));
}

// requestForm submits an api request using url encoded form data rather than json
export async function requestForm(
    url: string,
    data: { [key: string]: any },
    formName: string,
    method: MethodType,
    formHost: string
): Promise<any> {
    const form = new FormData();
    /* eslint guard-for-in: 0 */
    const keys = Object.keys(data);
    // the oauth integration endpoint expects form values so we generate form values for it
    for (const k of keys) {
        form.append(k, data[k]);
    }
    const submitTime = trackFormSubmitted(formName, url);
    const baseOpts = genApiOpts();
    return fetch(genApiUrl(url, formHost), {
        ...baseOpts,
        method,
        body: form,
        headers: new Headers({
            Accept: "application/json",
        }),
    })
        .then((response: Response) => response.json())
        .then((res: any) => trackFormRes(res, formName, submitTime, url));
}

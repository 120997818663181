import React from "react";
import { captureMessage } from "@src/util/analytics";
import { UsageRanks } from "@src/schema/UsageRank";
import { ResolverProps } from "./component";

// // // //

export const MAP_USAGE_RANK_TO_HUMAN_VALUE: { [key in UsageRanks]: string } = {
    [UsageRanks.direct]: "Direct",
    [UsageRanks.casb]: "CASB",
    [UsageRanks.sso]: "Single Sign On",
    [UsageRanks.desktop]: "Desktop",
    [UsageRanks.authentications]: "Third Party",
};

// Resolves human readable rank that corresponds to numberical usage rank value (i.e. 4000 => "Direct")
export function RankResolver(props: ResolverProps<string>) {
    const { usageRank } = props.params;

    if (usageRank === undefined) {
        captureMessage(`RankResolver - params.usageRank is undefined`);
        return null;
    }

    if (props.children) {
        return props.children({
            value: MAP_USAGE_RANK_TO_HUMAN_VALUE[usageRank],
            loading: false,
        });
    }

    return (
        <React.Fragment>
            {MAP_USAGE_RANK_TO_HUMAN_VALUE[usageRank]}
        </React.Fragment>
    );
}

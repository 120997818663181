import {
    FieldType,
    FilterExpression,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { genMoment, isDateStringValid } from "@src/util/date_util";
import { WeekInputValue } from "./types";

// // // //

/**
 * isWeekFieldType
 * Returns a boolean indicating whether or not the `fieldType` is used with the WeekInput
 * @param fieldType - the FieldType being checked
 */
export function isWeekFieldType(fieldType: FieldType): boolean {
    return FieldType.advanced_past_week_date_picker === fieldType;
}

/**
 * isWeekFilterExpression
 * Returns a boolean indicating whether or not the `expression` param is used with the WeekInput
 * @param expression - the FilterExpression that's being checked
 */
export function isWeekFilterExpression(expression: FilterExpression): boolean {
    return [FilterExpression.adp_pwe, FilterExpression.adp_fde].includes(
        expression
    );
}

/**
 * isWeekInputDataValid
 * Returns a boolean indicating whether or not the value/expression pair is valid
 * @param props.value - the value from WeekInput component
 * @param props.expression - the expression associated with props.value
 */
export function isWeekInputDataValid(props: {
    value: WeekInputValue;
    expression: FilterExpression;
}): boolean {
    const { value, expression } = props;

    // Return false if expression isn't invalid for WeekInput
    if (!isWeekFilterExpression(expression)) {
        return false;
    }

    // Return false if value is NOT an array
    if (!Array.isArray(value)) {
        return false;
    }

    // Return false if the array value is empty
    if (value.length === 0) {
        return false;
    }

    // Return false if the array value has more than 2 elements
    if (value.length > 2) {
        return false;
    }

    // Check the length of the array
    // This will determine whether or not the value is
    // the "Current Week" or a preset value (i.e. Last full week / 3 months / 6 months / 1 year)
    const arrayLength = value.length;

    // Is the length of the array 1?
    // Then -> the value must be an ISO formatted time string, or an integer
    if (arrayLength === 1) {
        // Pulls the first value from the array
        // NOTE: we use the `unknown` type here because it could be a number / string / undefined
        const arrayVal: unknown = value[0];

        // If the array's value is a number -> return true IFF it is also a positive integer
        if (typeof arrayVal === "number") {
            return Number.isInteger(arrayVal) && arrayVal > 0;
        }

        // Otherwise -> ensure the value is a valid ISO formatted time string
        return typeof arrayVal === "string" && isDateStringValid(arrayVal);
    }

    // Is the lenght of the array 2?
    // Then:
    // - ensure that both values are strings
    // - ensure that both values are valid dates
    // - ensure that the first date preceeds the second
    const startDateValue: unknown = value[0];
    const endDateValue: unknown = value[1];

    // Return false if either value isn't a string
    if (
        typeof startDateValue !== "string" ||
        typeof endDateValue !== "string"
    ) {
        return false;
    }

    // Return false if either value isn't a valid date
    if (
        isDateStringValid(startDateValue) === false ||
        isDateStringValid(endDateValue) === false
    ) {
        return false;
    }

    // Final check to ensure that startDate preceeds endDate
    return genMoment(startDateValue) < genMoment(endDateValue);
}

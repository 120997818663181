import { captureMessage } from "@src/util/analytics";
import * as pako from "pako";

// // // //

/**
 * decompressParam
 * Accepts a single compressed base64 string parameter and returns the decompressed version
 * Used to decompress a long string (usually stringified JSON) stored in RouteState.params
 * If there is an error decompressing the string, an empty string will be returned.
 * @param param - the string parameter that's being decompressed
 */
export function decompressParam(param: string): string {
    try {
        // Transform the base64 string into a numeric array so it can be passed into pako.inflate
        const arrayValue: number[] = atob(param)
            .split("")
            .map((c) => c.charCodeAt(0));

        // Inflates the numeric array and returns the result
        return pako.inflate(arrayValue, {
            to: "string",
        });
    } catch (e) {
        // If an error occurs for some reason, invoke captureMessage
        captureMessage("decompressParam - invalid param value", {
            extra: { param },
        });

        // Return empty string
        return "";
    }
}

export const coreBlue = "#005bc0";
export const coreRed = "#ef2c00";
export const blue = "#005bc0";
export const green = "#2acd85";
export const yellow = "#fdb249";
export const red = "#FF5050";
export const grey = "#b8b8b8";
export const white = "#ffffff";

// react-content-loader colors
export const contentLoaderPrimaryColor = "#f3f3f3";
export const contentLoaderSecondaryColor = "#ecebeb";

export const supportEmail: string = "support@intello.io";

// spaceRe is a regex to find any spaces
export const spaceRe = /\s+/;

export const genericErrorMessage =
    "We're experiencing some technical difficulties. The Intello engineering team is on it. Feel free to email support@intello.io for further assistance.";

// TODO: Need larger coin image with color
export const SAILPOINT_COLOR_COIN_URL =
    "/static/images/logo/sailpoint_color_coin_small.svg";

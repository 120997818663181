import {
    DateInputValue,
    getDateInputPreviewText,
    isDateFieldType,
} from "@src/modules/advanced_filters/components/advanced_filters/date_input";
import { VendorFetcher } from "@src/shared_modules/related_vendors";
import { captureMessage } from "@src/util/analytics";
import { formatMoment } from "@src/util/date_util";
import classnames from "classnames";
import isequal from "lodash.isequal";
import moment from "moment";
import React from "react";
import { isDeprecatedDateValue } from "../advanced_filters/date_input/isDeprecatedDateValue";
import { WeekInputValue } from "../advanced_filters/week_input";
import { getWeekInputPreviewText } from "../advanced_filters/week_input/getWeekInputPreviewText";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import { Tooltip } from "../../../../shared_modules/tooltip";
import {
    parseDynamicFilterValue,
    SIGN_MINUS,
    SIGN_PLUS,
} from "./DynamicFilterValueInput";
import {
    FieldType,
    FilterExpression,
    PossibleFilter,
    PossibleValue,
} from "./graphql";
import { isFilterExpressionDynamic } from "./isFilterExpressionDynamic";

// // // //

interface FilterValueProps {
    value: string | number | boolean | string[] | number[];
    className?: string;
    filterExpression: FilterExpression;
    possibleFilter: PossibleFilter;
}

/**
 * getFormattedDynamicFilterValue
 * Returns a human-readable dollar-formatted string
 * @param {number} value  - the value of a Filter instance coerced to a number
 */
export function getFormattedCurrencyValue(value: number): string {
    return `$${(value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
}

// getFormattedDateValue
// Returns either a human-reacable date, or an empty string
function getFormattedDateValue(value: string): string {
    let formattedValue = formatMoment(moment(value));

    // Check if the date format is invalid
    if (formattedValue === "Invalid date") {
        formattedValue = "";
    }

    // Returns the formattedValue
    return formattedValue;
}

// // // //

/**
 * getFormattedDynamicFilterValue
 * Returns a human-readable string for a dynamic Filter
 * @param value - the value of a Filter instance whose FilterExpression is dynamic
 */
export function getFormattedDynamicFilterValue(value: string): string {
    // Parses sign, days from dynamic filter value
    const [sign, days]: [string, number] = parseDynamicFilterValue(value);

    // Defines trailing text ("from now", "ago")
    const trailingTextMap: { [key: string]: string } = {
        [SIGN_PLUS]: "from now",
        [SIGN_MINUS]: "ago",
    };

    // Returns human-readable representation of dynamic filter value
    return `${String(days)} days ${trailingTextMap[sign]}`;
}

// // // //

/**
 * VendorValue
 * Fetches a Vendor by ID and renders the resulting Vendor name
 * @param props.value - the ID associated with the Vendor we're looking for
 * @param props.className - the className to apply to the
 */
export function VendorValue(props: {
    value: number;
    className: string;
    icon?: boolean;
}) {
    // Implements the `VendorFetcher` component to fetch a single Vendor by ID
    return (
        <VendorFetcher vendorID={props.value} includeRelatedVendors={false}>
            {({ vendor, loading }) => {
                // If loading, return an ellipsis until the Vendor loads
                if (loading) {
                    return <p className={props.className}>...</p>;
                }

                if (vendor === null) {
                    return null;
                }

                // Render the Vendor name
                return <p className={props.className}>{vendor.name}</p>;
            }}
        </VendorFetcher>
    );
}

/**
 * FilterValue
 * Renders different input elements corresponding to each FieldType
 */
export function FilterValue(props: FilterValueProps) {
    // Defines textClassName for <p> elements
    const { className = "" } = props;
    const textClassName = classnames({
        "whitespace-nowrap cursor-default": true,
        "text-success": props.possibleFilter.fieldType === FieldType.currency,
        [className]: className !== "",
    });

    // Return null if props.filterExpression is `isdefined` || `isundefined`
    if (
        [FilterExpression.isdefined, FilterExpression.isundefined].includes(
            props.filterExpression
        )
    ) {
        return null;
    }

    // Handles dynamic value for props.filterExpression
    if (isFilterExpressionDynamic(props.filterExpression)) {
        const stringValue: string = String(props.value);
        const formattedValue = getFormattedDynamicFilterValue(stringValue);
        return (
            <div className={textClassName}>
                <FeatureContent uniqueKey="filter-value">
                    {({ feature, loading }) => {
                        if (loading) {
                            return formattedValue;
                        }

                        return (
                            <Tooltip
                                placement="top"
                                tooltipContent={
                                    <p className="tw-p-3 font-size-12-px text-black font-secondary whitespace-nowrap">
                                        {feature.header}
                                    </p>
                                }
                            >
                                {({ setTriggerRef }) => (
                                    <span ref={setTriggerRef}>
                                        {formattedValue}
                                        <i className="fa fa-fw fa-info-circle ml-5-px" />
                                    </span>
                                )}
                            </Tooltip>
                        );
                    }}
                </FeatureContent>
            </div>
        );
    }

    // Handle FieldType used with DateInput
    if (isDateFieldType(props.possibleFilter.fieldType)) {
        // Gets the value being displayed from getDateInputPreviewText
        // NOTE - we invoke captureMessage in getDateInputPreview text if props.value isn't valid DateInputValue instance
        const formattedValue: string | null = getDateInputPreviewText({
            expression: props.filterExpression,
            value: props.value as DateInputValue,
        });

        // If formatttedValue is null -> captureMessage + return null
        // NOTE - this should _never_ happen, so we also invoke captureMessage if this occurs
        if (formattedValue === null) {
            // Handle deprecated date-picker values
            if (
                isDeprecatedDateValue({
                    value: props.value,
                    expression: props.filterExpression,
                })
            ) {
                return (
                    <p className={textClassName}>
                        {getFormattedDateValue(String(props.value))}
                    </p>
                );
            }

            // Capture message when invalid date value is detected
            captureMessage("isDateInputDataValid - value is not an array", {
                extra: {
                    value: props.value,
                    expression: props.filterExpression,
                },
            });

            // Returns null
            return null;
        }

        // Return formattedValue in styled <p>
        return <p className={textClassName}>{formattedValue}</p>;
    }

    // Handle WeekInput value
    if (
        props.possibleFilter.fieldType ===
        FieldType.advanced_past_week_date_picker
    ) {
        // Gets the value being displayed from getWeekInputPreviewText
        // NOTE - we invoke captureMessage in getDateInputPreview text if props.value isn't valid DateInputValue instance
        const formattedValue: string | null = getWeekInputPreviewText({
            expression: props.filterExpression,
            value: props.value as WeekInputValue,
        });

        // Return null if formattedValue isn't valid
        if (formattedValue === null) {
            return null;
        }

        // Return formattedValue in styled <p>
        return <p className={textClassName}>{formattedValue}</p>;
    }

    // Handles currency values
    if (props.possibleFilter.fieldType === FieldType.currency) {
        const dollars: string =
            typeof props.value === "number"
                ? getFormattedCurrencyValue(props.value)
                : getFormattedCurrencyValue(0);
        return <p className={textClassName}>{dollars}</p>;
    }

    // Handles numeric values
    if (
        [FieldType.number, FieldType.float, FieldType.integer].includes(
            props.possibleFilter.fieldType
        )
    ) {
        return (
            <p className={textClassName}>
                {Number(props.value).toLocaleString()}
            </p>
        );
    }

    // Handles text values
    if (props.possibleFilter.fieldType === FieldType.text) {
        return <p className={textClassName}>{props.value}</p>;
    }

    // Handles textArray values
    if (props.possibleFilter.fieldType === FieldType.textArray) {
        // Ensures value is an array before invoking value.join()
        if (Array.isArray(props.value)) {
            return <p className={textClassName}>{props.value.join(", ")}</p>;
        }

        // If for some reason the value is _not_ an array, simply render it in-line
        return <p className={textClassName}>{props.value}</p>;
    }

    // Handles date values
    if (
        [FieldType.date_picker, FieldType.date_range].includes(
            props.possibleFilter.fieldType
        )
    ) {
        return (
            <p className={textClassName}>
                {getFormattedDateValue(String(props.value))}
            </p>
        );
    }

    // Handles dropdown values
    if (
        props.possibleFilter.fieldType === FieldType.dropdown ||
        props.possibleFilter.fieldType === FieldType.boolean_dropdown ||
        props.possibleFilter.fieldType === FieldType.source_single_dropdown
    ) {
        // Finds associated `PossibleValue` associated with `props.value`
        const possibleValue:
            | PossibleValue
            | undefined = props.possibleFilter.possibleValues.find(
            (pv: PossibleValue) => isequal(pv.internalValue, props.value)
        );

        // Renders the raw props.value if possibleValue === undefined
        if (possibleValue === undefined) {
            return <p className={textClassName}>{props.value}</p>;
        }

        // Returns the possibleValue.humanValue
        return <p className={textClassName}>{possibleValue.humanValue}</p>;
    }

    // Handles vendor values
    if (props.possibleFilter.fieldType === FieldType.vendor) {
        return (
            <VendorValue
                icon
                value={parseInt(String(props.value), 10)}
                className={textClassName}
            />
        );
    }

    // Return null if `props.possibleFilter.fieldType` doesn't match one of the availabe input cases above
    // NOTE - this is here to prevent an invariant violation that occurs when this component doesn't return anything
    return null;
}

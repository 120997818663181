import moment from "moment";

// INVALID_DATE_STRING
// This is the string that's returned when `formatMoment` or `formatMomentISO` attempts to format an invalid date object
export const INVALID_DATE_STRING = "Invalid date";

// format a moment value to mm/dd/yyyy or another format if passed
export function formatMoment(
    date: moment.Moment | null,
    format?: string
): string | null {
    const dateFormat: string = format || "MM/DD/YY";
    if (date == null) {
        return null;
    }
    return date.format(dateFormat);
}

// format a moment value to a full iso string
export function formatMomentISO(date: moment.Moment | null): string | null {
    if (date == null) {
        return null;
    }
    return date.toISOString();
}

// genMoment generates a moment form a date string or a null value. you can use
// this when you def need to work with a non null moment object. if the date passed is null we return the default date
export function genMoment(date: string | null): moment.Moment {
    return date !== null ? moment(date) : moment();
}

// genNullMoment will generate a moment object or null depending on whether the date is null
export function genNullMoment(date: string | null): moment.Moment | null {
    return date != null ? moment(date) : null;
}

// getFormattedDateValue
// Returns either a formatted date to persist with the default filters, or an empty string
// NOTE - the filters expect dates in ISO format
export function getFormattedDateValue(value: string): string {
    let formattedValue = formatMomentISO(moment(value));

    // Check if the date format is invalid
    if (formattedValue === INVALID_DATE_STRING || formattedValue === null) {
        formattedValue = "";
    }

    // Returns the formattedValue
    return formattedValue;
}

/**
 * getHumanReadableDate
 * Accepts a value (string, null, or undefined) and returns a formatted timestamp, or a fallback string
 * @param props.value - the date string that's being formatted
 * @param props.fallback - (optional) fallback string when props.value isn't a valid date
 * @param props.format - (optional) date format to apply against props.value
 */
export function getHumanReadableDate(props: {
    value: string | null | undefined;
    fallback?: string;
    format?: string;
    utc?: boolean;
}): string {
    const { value, format = "MM/DD/YY", fallback = "", utc = false } = props;

    // If props.value is null, undefined, or empty -> return fallback
    if (value === null || value === undefined || value === "") {
        return fallback;
    }

    // Gets date as a moment.Moment instance
    let momentDate: moment.Moment | null = genNullMoment(value);

    // Convert the momentDate into UTC
    if (momentDate !== null && utc) {
        momentDate = momentDate.utc();
    }

    // Gets the formatted date
    const formattedDate: string = formatMoment(momentDate, format);

    // Return fallback if formattedDate is invalid
    if (formattedDate === INVALID_DATE_STRING) {
        return fallback;
    }

    // Return formatted date
    return formattedDate;
}

/**
 * Accepts a string and returns a boolean indicating whether or not the date string is valid
 */
export function isDateStringValid(
    value: string | number | null | undefined
): boolean {
    // Return false if the value is not a string
    if (typeof value !== "string") {
        return false;
    }

    // Return false if value is a string with a length less than 24
    // (ISO formatted strings are always 24 or 27 characters long)
    if (value.length < 24) {
        return false;
    }

    // Check if the result of getHumanReadableDate is an empty string
    // (see getHumanReadableDate for all the checks it performs)
    return getHumanReadableDate({ value }) !== "";
}

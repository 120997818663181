import { UsageRank } from "@src/schema/UsageRank";

export type DisplayResolverType =
    | "vendor"
    | "app_usage_summary"
    | "connected_app"
    | "primary_integration_user"
    | "purchase_order"
    | "usage_rank"
    | "integration"
    | "label"
    | "webhook"
    | "workflow"
    | "appInfo"
    | "report"
    | "user_report_result_bucket";

export enum DisplayResolverTypes {
    vendor = "vendor",
    connectedApp = "connected_app",
    appUsageSummary = "app_usage_summary",
    primaryIntegrationUser = "primary_integration_user",
    purchaseOrder = "purchase_order",
    usageRank = "usage_rank",
    integration = "integration",
    webhook = "webhook",
    workflow = "workflow",
    label = "label",
    appInfo = "appInfo",
    report = "report",
    userReportResultBucket = "user_report_result_bucket",
}

/**
 * DisplayResolverProps
 * Params for breadcrumbs display resolver
 */
export interface DisplayResolverProps {
    loadingPlaceholder?: string;
    errorFallback?: string;
    type: DisplayResolverType;
    params: {
        label?: string;
        vendorID?: number;
        appUsageSummaryID?: string;
        groupBucketID?: string; // TODO: Remove this
        groupSummaryID?: string; // TODO: Remove this
        groupAppID?: string;
        connectedAppID?: string;
        sourceAppID?: string;
        purchaseOrderID?: string;
        usageRank?: UsageRank;
        source?: string;
        webhookID?: string;
        workflowID?: string;
        email?: string;
        instanceID?: string;
        reportID?: string;
        appInfoID?: string;
    };
}

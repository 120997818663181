import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { captureMessage } from "@src/util/analytics";
import { getHumanReadableDate } from "@src/util/date_util";
import moment from "moment";
import {
    LAST_FULL_WEEK,
    THREE_MONTHS,
    SIX_MONTHS,
    ONE_YEAR,
} from "./constants";
import { isWeekInputDataValid } from "./isWeekInputDataValid";
import { WeekInputValue } from "./types";

// // // //

// Defines string constants for building preview text
const WEEK_OF_TEXT = "Week of";
const PAST_TEXT = "Past";
const WEEKS_TEXT = "weeks";
const LAST_FULL_WEEK_TEXT = "Last full week";
const THREE_MONTHS_TEXT = "3 months";
const SIX_MONTHS_TEXT = "6 months";
const ONE_YEAR_TEXT = "year";

/**
 * getWeekInputPreviewText
 * Accepts DateInputValue and returns human-readable expression summarizing the Date
 * @param props.value
 * @param props.expression
 */
export function getWeekInputPreviewText(props: {
    value: WeekInputValue;
    expression: FilterExpression;
}): string | null {
    const { value, expression } = props;

    // Return null if expression/value pair is invalid
    if (!isWeekInputDataValid({ value, expression })) {
        return null;
    }

    // Handle preset week values
    if (value.length === 1) {
        const val = value[0];
        if (val === LAST_FULL_WEEK) {
            return LAST_FULL_WEEK_TEXT;
        }
        if (val === THREE_MONTHS) {
            return `${PAST_TEXT} ${THREE_MONTHS_TEXT}`;
        }
        if (val === SIX_MONTHS) {
            return `${PAST_TEXT} ${SIX_MONTHS_TEXT}`;
        }
        if (val === ONE_YEAR) {
            return `The ${PAST_TEXT.toLowerCase()} ${ONE_YEAR_TEXT}`;
        }
        if (typeof val === "number") {
            return `${PAST_TEXT} ${val} ${WEEKS_TEXT}`;
        }
        if (typeof val === "string") {
            const dateString = moment(val).format("MMM DD");
            return `${WEEK_OF_TEXT} ${dateString}`;
        }

        // Invoke captureMessage + return null if single value not handled by the above
        captureMessage("getWeekInputPreviewText - unhandled value", {
            extra: {
                value,
            },
        });
        return null;
    }

    // Handle startDate <-> endDate range

    // Format start + end date values
    const startDate = getHumanReadableDate({
        value: String(value[0]),
        format: "MMM DD YYYY",
        utc: true,
    });
    const endDate = getHumanReadableDate({
        value: String(value[1]),
        format: "MMM DD YYYY",
        utc: true,
    });

    // Return string displaying range from start -> end date
    return `${startDate} - ${endDate}`;
}

import gql from "graphql-tag";
import { Content, CONTENT_FRAGMENT } from "./Content";
import { TableCellType } from "./TableCellType";

// // // //

/**
 * Defines available values for Column.defaultSortDirection
 */
export type ColumnSortDirection = "asc" | "desc" | "";
export enum ColumnSortDirections {
    asc = "asc",
    desc = "desc",
    none = "",
}

/**
 * Column represents a column that the list supports
 */
export interface Column {
    key: string;
    content: Content;
    sortable: boolean;
    defaultSortDirection: ColumnSortDirection;
    sortedByDefault: boolean;
    visibleByDefault: boolean;
    rowDataKeys: string[];
    type: TableCellType;
    __typename: "Column";
}

export const COLUMN_FRAGMENT = gql`
    fragment COLUMN_FRAGMENT on Column {
        key
        sortable
        defaultSortDirection
        sortedByDefault
        visibleByDefault
        rowDataKeys
        type
        content {
            ...CONTENT_FRAGMENT
        }
    }
    ${CONTENT_FRAGMENT}
`;

import * as React from "react";
import { Pagination as PaginationSchema } from "@src/schema/Pagination";

// // // //

type Pagination = Omit<PaginationSchema, "__typename">;

/**
 * PaginationSummary
 * Renders the pagination summary for a given `Pagination` instance
 * @param props - see Pagination
 */
export function PaginationSummary(props: Pagination) {
    const { rangeStart, rangeEnd, totalItems } = props;
    const commaFormattedTotal = totalItems?.toLocaleString() || "0";
    const commaFormattedStart = rangeStart?.toLocaleString() || "0";
    const commaFormattedEnd = rangeEnd?.toLocaleString() || "0";
    return (
        <div className="font-secondary font-size-14-px text-updated-black">
            SHOWING {commaFormattedStart} - {commaFormattedEnd} OF{" "}
            {commaFormattedTotal}
        </div>
    );
}

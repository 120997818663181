import { AppField } from "@src/modules/org_fields/components/application_fields_fetcher";
import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { ApolloClient } from "apollo-client";
import get from "lodash.get";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    APPLICATION_FIELDS_UPDATER_MUTATION,
    ApplicationFieldsUpdaterMutationInput,
    ApplicationFieldsUpdaterMutationResponse,
} from "./graphql";

// // // //

// Defines success message for update action
const UPDATE_SUCCESS_MESSAGE = "Successfully updated application fields";

/**
 * UpdateApplicationFieldsProps
 * Parameters for the updateApplicationFields function provided by the ApplicationFieldsUpdater component to props.children
 */
interface UpdateApplicationFieldsProps {
    appFields: AppField[];
}

/**
 * ApplicationFieldsUpdaterProps
 * @param successMessage - (optional) Message that can override UPDATE_SUCCESS_MESSAGE if needed
 * @param children - A function that:
 *          - accepts -> the loading state for SAVE_APP_FIELDS_MUTATION
 *          - accepts -> the Apollo Client
 *          - accepts -> a helper function to fire off SAVE_APP_FIELDS_MUTATION
 *          - returns -> React.ReactNode
 */
interface ApplicationFieldsUpdaterProps {
    successMessage?: string;
    children: (childProps: {
        loading: boolean;
        client: ApolloClient<Object>;
        updateApplicationFields: (
            props: UpdateApplicationFieldsProps
        ) => Promise<AppField[] | null>;
    }) => React.ReactNode;
}

/**
 * ApplicationFieldsUpdaterLayout
 * Wires up SAVE_APP_FIELDS_MUTATION and exposes the loading state and a helper function to `props.children`
 * @param props - see ApplicationFieldsUpdaterProps
 */
export function ApplicationFieldsUpdaterLayout(
    props: ApplicationFieldsUpdaterProps & {
        user: UserState;
    } & WithToasterProps
) {
    return (
        <Mutation<
            ApplicationFieldsUpdaterMutationResponse,
            ApplicationFieldsUpdaterMutationInput
        >
            mutation={APPLICATION_FIELDS_UPDATER_MUTATION}
            onError={() => null}
        >
            {(commitMutation, { loading, error, client }) => {
                // Defines function to assist invoking commitMutation
                function updateApplicationFields({
                    appFields,
                }: UpdateApplicationFieldsProps): Promise<AppField[] | null> {
                    return new Promise((resolve) => {
                        // Invokes commitMutation
                        return commitMutation({
                            variables: {
                                input: {
                                    orgID: props.user.organizationID,
                                    appFields,
                                },
                            },
                        }).then(
                            (response: {
                                data:
                                    | undefined
                                    | ApplicationFieldsUpdaterMutationResponse;
                            }) => {
                                // Pulls AppFields from response
                                const applicationFields:
                                    | null
                                    | AppField[] = get(
                                    response,
                                    "data.saveAppFields",
                                    null
                                );

                                // Displays success toaster if appFields isn't null
                                if (applicationFields !== null) {
                                    props.addToaster({
                                        type: "success",
                                        text:
                                            props.successMessage ||
                                            UPDATE_SUCCESS_MESSAGE,
                                    });
                                }

                                // Resolves promise with AppField[] | null
                                resolve(applicationFields);
                            }
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handles error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {/* Renders props.children + passes updateApplicationFields function */}
                        {props.children({
                            loading,
                            client,
                            updateApplicationFields,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

export const ApplicationFieldsUpdater: React.ComponentType<ApplicationFieldsUpdaterProps> = withToaster(
    withUser(ApplicationFieldsUpdaterLayout)
);

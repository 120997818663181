import {
    trackConfirmDeleteOrgSavedView,
    trackDeleteOrgSavedView,
    trackUpdateOrgSavedView,
} from "@src/analytics/org_saved_views";
import {
    Filters,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import * as React from "react";
import { buildOrgSavedViewInput } from "./buildOrgSavedViewInput";
import { ConfirmDeleteSavedView } from "./ConfirmDeleteSavedView";
import { OrgSavedViewForm } from "./OrgSavedViewForm";
import { OrgSavedViewModal } from "./OrgSavedViewModal";
import { OrgSavedView, OrgSavedViewsApi } from "./types";

// // // //

/**
 * EditOrgSavedViewModalProps
 * @param filters - See AdvancedFiltersProvider.props.children
 * @param filtersInput - Currently applied FiltersInput
 * @param orgSavedView - The OrgSavedView being edited
 * @param disabled - See OrgSavedViewForm.props.disabled
 * @param orgSavedViewsApi - See OrgSavedViewsApiProvider
 * @param onClose - callback to close the modal
 */
interface EditOrgSavedViewModalProps {
    filters: Filters;
    filtersInput: FiltersInput;
    orgSavedView: OrgSavedView;
    disabled: boolean;
    onClose: () => void;
    orgSavedViewsApi: OrgSavedViewsApi;
}

/**
 * EditOrgSavedViewModal
 * Renders the OrgSavedViewForm + OrgSavedViewModal for editing an *exiting* OrgSavedView
 * NOTE - this component will be updated to include the `SaveOrgSavedViewMutation` when it's available (currently in development)
 * @param props - see EditOrgSavedViewModalProps
 */
export function EditOrgSavedViewModalLayout(
    props: EditOrgSavedViewModalProps & { user: UserState }
) {
    const [showingDeleteConfirm, setShowingDeleteConfirm] = React.useState<
        boolean
    >(false);

    const {
        user,
        filters,
        filtersInput,
        orgSavedView,
        disabled,
        orgSavedViewsApi,
    } = props;

    // Defines flag indicating whether or not filtersInput has any user-defined filters
    const noFiltersInputPresent: boolean =
        filtersInput.defaults.length === 0 &&
        filtersInput.advanced.length === 0;

    return (
        <OrgSavedViewModal
            title="Edit Saved View"
            filters={filters}
            filtersInput={filtersInput}
            onClose={() => props.onClose()}
        >
            <div className="mt-15-px">
                {/* Show OrgSavedViewForm  */}
                {!showingDeleteConfirm && (
                    <React.Fragment>
                        <OrgSavedViewForm
                            name={orgSavedView.name}
                            description={orgSavedView.description}
                            disabled={disabled || noFiltersInputPresent}
                            vendorID={orgSavedViewsApi.vendorID}
                            tags={orgSavedView.tags.map((tag) => tag.id)}
                            onSubmit={(formData) => {
                                // Track trackUpdateOrgSavedView event
                                trackUpdateOrgSavedView();

                                // Invoke props.updateOrgSavedView with formData
                                orgSavedViewsApi
                                    .updateOrgSavedView({
                                        ...buildOrgSavedViewInput({
                                            orgSavedView,
                                            organizationID: user.organizationID,
                                        }),
                                        filters: filtersInput,
                                        ...formData,
                                    })
                                    .then(
                                        (
                                            savedOrgSavedView: OrgSavedView | null
                                        ) => {
                                            // Close the modal if props.createOrgSavedView resolved successfully
                                            if (savedOrgSavedView !== null) {
                                                props.onClose();
                                            }
                                        }
                                    );
                            }}
                        />

                        {/* Render button to render the ConfirmDeleteSavedView */}
                        <button
                            className="btn-md btn-alert font-secondary-bold font-size-14-px mt-10-px w-100"
                            onClick={() => {
                                // Track the click event
                                trackDeleteOrgSavedView();

                                // Shows the confirm-delete component
                                setShowingDeleteConfirm(true);
                            }}
                        >
                            Delete Saved View
                        </button>
                    </React.Fragment>
                )}

                {/* Show ConfirmDelete  */}
                {showingDeleteConfirm && (
                    <ConfirmDeleteSavedView
                        name={orgSavedView.name}
                        onCancel={() => {
                            setShowingDeleteConfirm(false);
                        }}
                        onConfirm={() => {
                            // Track the delete event
                            trackConfirmDeleteOrgSavedView();

                            // Invoke props.deleteOrgSavedView with props.orgSavedView
                            orgSavedViewsApi
                                .deleteOrgSavedView(orgSavedView)
                                .then(
                                    (
                                        deletedOrgSavedView: OrgSavedView | null
                                    ) => {
                                        // Close the modal if props.deleteOrgSavedView resolved successfully
                                        if (deletedOrgSavedView !== null) {
                                            props.onClose();
                                        }
                                    }
                                );
                        }}
                    />
                )}
            </div>
        </OrgSavedViewModal>
    );
}

// // // //

export const EditOrgSavedViewModal: React.ComponentType<EditOrgSavedViewModalProps> = withUser(
    EditOrgSavedViewModalLayout
);

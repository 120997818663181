import { routes } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

import { BreadcrumbState } from "../types";

// // // //

export const webhookNewState = (route: RouteState): BreadcrumbState => {
    return {
        linkProps: {
            href: {
                pathname: routes.settings.webhookNew,
                query: {},
            },
            as: routes.settings.webhookNew,
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: { breadcrumb: "settings.webhooksNew" },
        },
        enabled: true,
        active: route.nextPathname === routes.settings.webhookNew,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "New Webhook" },
        },
    };
};

import React from "react";

export function FieldTextarea(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="15"
            viewBox="0 0 49 15"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <g
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(-245 -383)"
                >
                    <g transform="translate(245 383)">
                        <path d="M46.61 0H2.39C1.07 0 0 1.033 0 2.308v10.384C0 13.967 1.07 15 2.39 15h44.22c1.32 0 2.39-1.033 2.39-2.308V2.308C49 1.033 47.93 0 46.61 0zm1.195 12.692c0 .638-.535 1.154-1.195 1.154H2.39c-.66 0-1.195-.516-1.195-1.154V2.308c0-.638.535-1.154 1.195-1.154h44.22c.66 0 1.195.516 1.195 1.154v10.384zM5.874 4.038v6.924a.588.588 0 01-.598.576.588.588 0 01-.597-.576V4.038c0-.318.267-.576.597-.576.33 0 .598.258.598.576z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

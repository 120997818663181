import { trackRequestAccess } from "@src/analytics/dashboard";
import { launchSupport } from "@src/shared_modules/client";
import * as React from "react";
import { ldClient } from "./client";

// // // //

// Defines type signature for props.children
export type AllFlags = Record<string, boolean>;
type ChildrenFunction = (allFlags: AllFlags) => React.ReactNode;

// Props are the props of the component
interface Props {
    // featureName is the feature name that we're requesting
    featureName: string;
    // type is whether if the feature isn't enabled a user requests access or if it's hidden
    type?: "hidden" | "request_access";
    children?: React.ReactNode | ChildrenFunction;
    desc?: string;
    // variation - boolean flag that forces the FeatureFlagCC component to render props.children
    // when the props.variation is equal to the value returned by ldClient.variation. This can
    // used with two FeatureFlagCC components to toggle between one component and another depending on the level of user's access
    variation?: boolean | undefined;
}

// FeatureFlag renders a component that should be rendered via a feature flag.
// The consuming component just needs to pass in the feature name and whether it should be hidden or not
export class FeatureFlagCC extends React.Component<Props> {
    public render() {
        // Pulls variation flag from this.props (default value is undefined)
        const { variation, type = "hidden" } = this.props;

        // if it's loading we return null, bec we don't know what to do yet
        // if (ldClient === null || FeatureFlag.loading) {
        if (ldClient === null) {
            return null;
        }

        // If props.children is a function -> invoke with allFlags and return result
        // NOTE - this bypasses the default logic inside the `FeatureFlagCC` component
        // This should be used judiciously as a buggy implementation could have unintended sideeffects
        if (typeof this.props.children === "function") {
            // Gets map of all flags from LDClient
            const allFlags: AllFlags = ldClient.allFlags();

            // Invokes props.children and assigns to result
            const result: React.ReactNode = (this.props
                .children as ChildrenFunction)(allFlags);

            // Renders the result
            return <React.Fragment>{result}</React.Fragment>;
        }

        const showFeature = ldClient.variation(this.props.featureName, false);

        // If props.variation isn't undefined, we check it's value against showFeature
        if (variation !== undefined) {
            // If showFeature === props.variation -> render props.children
            if (showFeature === variation) {
                return this.props.children;
            }

            // Otherwise, return null
            return null;
        }

        // if we need to show the feature just return the children
        if (showFeature) {
            return this.props.children;
        }
        if (!showFeature && type === "hidden") {
            // if the feature should be hidden if it's not enabled just return
            return null;
        }

        // otherwise we need to show the request access to the feature
        return (
            <button
                type="button"
                className="btn btn-md btn-primary font-secondary"
                onClick={() => {
                    trackRequestAccess(this.props.featureName, type);
                    launchSupport(
                        `Hi SailPoint, I want to request access to ${this.props.desc}`
                    );
                }}
            >
                Request Access
            </button>
        );
    }
}

import { BreadcrumbNode } from "../types";
import { vendorConnectedAppListingNode } from "../vendorConnectedAppListing";
import { vendorConnectedAppIndividualState } from "./state";

// // // //

export const vendorConnectedAppIndividualNode: BreadcrumbNode = {
    getState: vendorConnectedAppIndividualState,
    parent: vendorConnectedAppListingNode,
};

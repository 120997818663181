import { spendStatus, syncStatus, SyncStatus } from "@src/requests/oauth";
import { resetURLFetch } from "@src/shared_modules/list";
import { Action } from "@src/shared_modules/redux_provider/types";
import { Dispatch } from "redux";
import { modelType as spendListing } from "../../../payments/pages/spend_listing";

// // // //

export const UPDATE_SYNC_ACTION = "_integration_individual_syncing";

// UpdateSyncAction is the action that we publish when a sync is ocucring
export type UpdateSyncAction = {
    syncing: boolean;
    timeExpired: boolean;
} & Action;

// updateSyncAction updates the status of the current sync
export function updateSyncAction(syncing: boolean, timeExpired: boolean) {
    return {
        type: UPDATE_SYNC_ACTION,
        syncing,
        timeExpired,
    };
}

// NOTE - we use TypeScript `ReturnType` here beacuse `setTimeout` behaves differently depending on whether it's being run in-browser or in Node.js
// The TS `ReturnType<T>` constructs a type consisting of the return type of some function T - this ensures that we're typesafe during SSR + in-browser rendering
let timeoutID: null | NodeJS.Timeout | number;

// checkSyncStatus checks the syncStatus and continuously updates how long a sync takes
export function checkSyncStatus(
    service: string,
    isSpend: boolean,
    dispatch: Dispatch,
    startTime: number
) {
    // get the sync function request we should use which depends on whether we're syncing a usage or spend app (bec they request different apps)
    const syncFn = isSpend ? spendStatus : syncStatus;

    return syncFn(service).then((syncing: SyncStatus) => {
        if (timeoutID !== null) {
            clearTimeout(timeoutID as number);
        }
        const currentTime: number = new Date().getTime();
        const syncDifference: number = currentTime - startTime;
        const timeExpired: boolean = syncDifference < 26000;
        // if it's still syncing we want to continue syncing by waiting 500 milliseconds to check the sync
        if (syncing.syncing && !timeExpired) {
            timeoutID = setTimeout(
                checkSyncStatus,
                500,
                service,
                isSpend,
                dispatch,
                startTime
            );
            return true;
        }

        dispatch(updateSyncAction(false, !timeExpired));
        return null;
    });
}

// initCheckSync initializes checking the sync status of an app
export function initCheckSync(service: string, isSpend: boolean) {
    return (dispatch) => {
        // we need to reset the url fetch for the spend listing, usage listing, + compliance listing whenever there is an integration success.
        // that way if an integration didn't have the user leave the app they can still see the updated pages
        dispatch(resetURLFetch(spendListing));

        dispatch(updateSyncAction(true, false));
        // give the initial sync 4 seconds to kick-start with our asychronous architecture
        // get the start time of the sync checking if we've been checking for 26 seconds - bec of the time below we just need to return
        const startTime = new Date();
        timeoutID = setTimeout(
            checkSyncStatus,
            4000,
            service,
            isSpend,
            dispatch,
            startTime
        );
    };
}

import { EditorFilter, EditorFiltersInput } from "./graphql";

// // // //

/**
 * AdvancedFilterEditorActionTypes Enum
 * Encapsulates all available AdvancedFilterEditor action types
 */
export enum AdvancedFilterEditorActionTypes {
    addFilter = "ADD_FILTER",
    editFilter = "EDIT_FILTER",
    clearEditor = "CLEAR_EDITOR",
    removeFilter = "REMOVE_FILTER",
    updateFilter = "UPDATE_FILTER",
    reorderFilters = "REORDER_FILTERS",
    setFilters = "SET_FILTERS",
}

/**
 * AdvancedFilterEditorDefaultAction interface definition
 * Used for ADD_FILTER / REMOVE_FILTER / UPDATE_FILTER actions
 */
export interface AdvancedFilterEditorDefaultAction {
    type:
        | AdvancedFilterEditorActionTypes.addFilter
        | AdvancedFilterEditorActionTypes.removeFilter
        | AdvancedFilterEditorActionTypes.updateFilter;
    filter: EditorFilter;
}

/**
 * AdvancedFilterEditorEditFilterAction interface definition
 * Used for EDIT_FILTER action
 */
export interface AdvancedFilterEditorEditFilterAction {
    type: AdvancedFilterEditorActionTypes.editFilter;
    filter: EditorFilter;
    wrapNewFilterInAnd?: boolean;
    wrapNewFilterInOr?: boolean;
    destinationFilter?: EditorFilter | null;
}

/**
 * AdvancedFilterEditorClearFilterAction interface definition
 * Used for CLEAR_EDITOR action
 */
export interface AdvancedFilterEditorClearFilterAction {
    type: AdvancedFilterEditorActionTypes.clearEditor;
}

/**
 * AdvancedFilterEditorReorderAction
 * Used for REORDER_FILTERS action - note the inclusion of startIndex & endIndex props
 */
export interface AdvancedFilterEditorReorderAction {
    type: AdvancedFilterEditorActionTypes.reorderFilters;
    destinationFilterId: string;
    startIndex: number;
    endIndex: number;
}

/**
 * AdvancedFilterEditorSetFiltersAction
 * Used for REORDER_FILTERS action - note the inclusion of startIndex & endIndex props
 */
export interface AdvancedFilterEditorSetFiltersAction {
    type: AdvancedFilterEditorActionTypes.setFilters;
    editorFiltersInput: EditorFiltersInput;
}

/**
 * Exports the AdvancedFilterEditorAction as a union of the following action interfaces:
 * `AdvancedFilterEditorDefaultAction`
 * `AdvancedFilterEditorEditFilterAction`
 * `AdvancedFilterEditorClearFilterAction`
 * `AdvancedFilterEditorReorderAction`
 * `AdvancedFilterEditorSetFiltersAction`
 */
export type AdvancedFilterEditorAction =
    | AdvancedFilterEditorDefaultAction
    | AdvancedFilterEditorEditFilterAction
    | AdvancedFilterEditorClearFilterAction
    | AdvancedFilterEditorReorderAction
    | AdvancedFilterEditorSetFiltersAction;

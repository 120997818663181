import classnames from "classnames";
import React from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { FilterList } from "./FilterList";
import { FilterListExpressionBadge } from "./FilterListExpressionBadge";
import { FilterListItemDragHandle } from "./FilterListItemDragHandle";
import { FilterDropdown } from "./FilterListItemDropdown";
import { FilterListItemSummary } from "./FilterListItemSummary";
import { FilterListWhereBadge } from "./FilterListWhereBadge";
import {
    EditorFilter,
    FilterExpression,
    Filters,
    PossibleFilter,
} from "./graphql";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListItemProps
 * `filter`: The `Filter` rendered by this list item
 * `parentFilter`: The parent `Filter` of `props.filter`
 * `depth`: The "depth" at which the <FilterList /> is rendered. Used to limit depth of recursively nested filters
 * `index`: The numerical index of the `FilterListItem` in its respective `FilterList` - used to conditionally render specific UI elements
 * `openDropdownUp`: boolean flag dictating whether the FilterListItem dropdown opens upwards
 * `separatorFilterExpression`: The `FilterExpression` used to render the `FilterListExpressionBadge` between each FilterListItem
 * `possibleFilter`: The `PossibleFilter` instance associated with `props.filter`
 * `selectedQueryFilters`: The `Filters` instance used to populate the AdvancedFilterEditor. Used here to pass into nested <FilterList /> component
 * `editingFilter`: The `Filter` currently being editing in the `FilterForm` (used to change styles here)
 * `disableReorder`: Whether or not the list item can be re-ordered (i.e. if no sibling list items exist, re-ordering is disabled)
 * `provided`: See `react-beautiful-dnd` docs
 * `snapshot`: See `react-beautiful-dnd` docs
 * `editFilter`: function invoked to edit the `Filter` instance passed as a parameter
 * `removeFilter`: function invoked to remove the `Filter` instance passed as a parameter
 * `addOrFilter`: function invoked to add a nested _or_ adjacent `Filter.expression = "OR"` to the `Filter` instance passed as a parameter
 * `addAndFilter`: function invoked to add a nested _or_ adjacent `Filter.expression = "AND"` to the `Filter` instance passed as a parameter
 */
interface FilterListItemProps {
    filter: EditorFilter;
    parentFilter: EditorFilter | null;
    depth: number;
    index: number;
    openDropdownUp: boolean;
    separatorFilterExpression: FilterExpression | undefined;
    possibleFilter: PossibleFilter | undefined;
    selectedQueryFilters: Filters;
    editingFilter: EditorFilter | null;
    disableReorder: boolean;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    editFilter: (filter: EditorFilter) => void;
    removeFilter: (filter: EditorFilter) => void;
    addOrFilter: (parentFilter: EditorFilter | null) => void;
    addAndFilter: (parentFilter: EditorFilter | null) => void;
}

/**
 * FilterListItemSeparator
 * Renders the AND/OR separator between each FilterListItem - depends on props.separatorFilterExpression
 * @param props.children - The React.ReactNode that's wrapped by this component
 * @param props.filter - see `FilterListItemProps.filter`
 * @param props.index - see `FilterListItemProps.index`
 * @param props.parentFilter - see `FilterListItemProps.parentFilter`
 * @param props.separatorFilterExpression - see `FilterListItemProps.separatorFilterExpression`
 * @param props.inactive - whether or not the FilterListItemSeparator is rendered with `styles.filterListItemInactive`
 */
export function FilterListItemSeparator(props: {
    children: React.ReactNode;
    filter: EditorFilter;
    index: number;
    parentFilter: EditorFilter | null;
    separatorFilterExpression: FilterExpression | undefined;
    inactive: boolean;
}) {
    const {
        filter,
        separatorFilterExpression,
        parentFilter = null,
        inactive = false,
    } = props;

    // Defines className for the separator container
    const separatorContainerClassName = classnames({
        "d-flex flex-column": true,
        "ml-30-px": filter.expression === FilterExpression.or,
        [styles.filterListItemInactive]: inactive,
    });

    // If there's no parent filter AND props.index is zero => render the `WHERE` badge wrapped around `props.children`
    if (parentFilter === null && props.index === 0) {
        return (
            <div className={separatorContainerClassName}>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <FilterListWhereBadge />
                    </div>
                </div>
                {props.children}
            </div>
        );
    }

    // Renders a trailing `OR` FilterExpressionBadge when:
    // - parentFilter.expression is OR +
    // - parentFilter.filters has length === 1 +
    // - props.index 0 (the only nested Filter being rendered)
    // NOTE - this is done to provide some much-needed context when there's
    // only a single `Filter` nested in an OR expression. Without this addition,
    // there isn't an `OR` Expression badge anywhere, so it's not clear to end-users
    // that the filter they're looking at is nested inside an or expression
    if (
        parentFilter !== null &&
        parentFilter.expression === FilterExpression.or &&
        parentFilter.filters.length === 1 &&
        props.index === 0
    ) {
        // Returns the component tree wrapped around `props.children`
        return (
            <div className={separatorContainerClassName}>
                {props.children}
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <FilterListExpressionBadge
                            hideBottomStem
                            expression={separatorFilterExpression}
                        />
                    </div>
                </div>
            </div>
        );
    }

    // If no separatorFilterExpression => return props.children
    if (!separatorFilterExpression) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    // Returns the component tree wrapped around `props.children`
    return (
        <div className={separatorContainerClassName}>
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                    <FilterListExpressionBadge
                        expression={separatorFilterExpression}
                    />
                </div>
            </div>
            {props.children}
        </div>
    );
}

/**
 * FilterListItem
 * Renders an individual list item for a single `Filter`.
 * NOTE - this component also renders a nested `<FilterList />` component when `props.filter.filters` is populated.
 * @param props - see `FilterListItemProps`
 */
export function FilterListItem(props: FilterListItemProps) {
    const { filter } = props;

    // Defines a boolean indicating whether or props.editingFilter is the same as props.filter
    const inactiveWhileEditing: boolean =
        props.editingFilter !== null && props.editingFilter.id !== filter.id;

    // Defines a boolean indicating whether or not props.filter.expression = and/or
    const isAndOrExpression: boolean = [
        FilterExpression.and,
        FilterExpression.or,
    ].includes(props.filter.expression);

    // Defines activeOrInactiveClassName using inactiveWhileEditing
    const activeOrInactiveClassName = classnames(
        "d-flex align-items-center justify-content-between",
        {
            "mb-10-px": isAndOrExpression,
        }
    );

    // Define className for `<li>`
    const listItemClassName = classnames({
        "px-15-px py-15-px rounded-sm": true,
        "mt-15-px": props.index === 0 && props.parentFilter === null,
        "border-grey-border bg-white": !isAndOrExpression,
        "border-primary": filter.expression === FilterExpression.and,
        "border-tertiary": filter.expression === FilterExpression.or,
        [styles.filterListItemDragging]: props.snapshot.isDragging,
    });

    // Returns component tree for and/or filters
    if (isAndOrExpression) {
        return (
            <FilterListItemSeparator
                filter={filter}
                index={props.index}
                parentFilter={props.parentFilter}
                separatorFilterExpression={props.separatorFilterExpression}
                inactive={inactiveWhileEditing}
            >
                <li
                    ref={props.provided.innerRef}
                    className={listItemClassName}
                    {...props.provided.draggableProps}
                >
                    {filter.filters.length > 0 && (
                        <FilterList
                            filters={filter.filters}
                            parentFilter={filter}
                            depth={props.depth}
                            selectedQueryFilters={props.selectedQueryFilters}
                            editingFilter={props.editingFilter}
                            droppableID={filter.id}
                            editFilter={props.editFilter}
                            removeFilter={props.removeFilter}
                            addOrFilter={props.addOrFilter}
                            addAndFilter={props.addAndFilter}
                        />
                    )}
                </li>
            </FilterListItemSeparator>
        );
    }

    // Returns component tree for standard filters
    return (
        <FilterListItemSeparator
            filter={filter}
            index={props.index}
            parentFilter={props.parentFilter}
            separatorFilterExpression={props.separatorFilterExpression}
            inactive={inactiveWhileEditing}
        >
            <li
                ref={props.provided.innerRef}
                className={listItemClassName}
                {...props.provided.draggableProps}
            >
                <div className={activeOrInactiveClassName}>
                    <div className="d-flex flex-wrap align-items-center">
                        <FilterListItemSummary
                            filter={filter}
                            possibleFilter={props.possibleFilter}
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <FilterListItemDragHandle
                            disableReorder={
                                props.disableReorder ||
                                props.editingFilter !== null
                            }
                            dragHandleProps={props.provided.dragHandleProps}
                        />
                        <FilterDropdown
                            filter={filter}
                            parentFilter={props.parentFilter}
                            depth={props.depth}
                            openDropdownUp={props.openDropdownUp}
                            editFilter={props.editFilter}
                            removeFilter={props.removeFilter}
                            addOrFilter={props.addOrFilter}
                            addAndFilter={props.addAndFilter}
                            disabled={inactiveWhileEditing}
                        />
                    </div>
                </div>
            </li>
        </FilterListItemSeparator>
    );
}

import {
    CursorPagination,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import classnames from "classnames";
import React from "react";
import { Pagination } from "@src/schema/Pagination";
import { CursorPager } from "../../../../shared_modules/pagination";
import { isCursorPagination } from "./isCursorPagination";
import { ItemsPerPageCC } from "./ItemsPerPage";
import { PaginationSummary } from "./PaginationSummary";

// // // //

/**
 * AdvancedFiltersItemsPerPageProps
 * Props for the `AdvancedFiltersItemsPerPage` component
 * @param filtersInput - `FiltersInput` instance passed down from the parent
 * @param itemsPerPageOptions - (optional) array of possible numbers of items per page
 * @param all - (optional) number of items per page to set when the `All` link is active
 * @param itemCount - The number of items currently rendered on the page
 * @param pagination - current pagination state
 * @param className - (optional) class name to apply to the wrapper div
 * @param totalRows - (optional) totalRows count + loading state (used for CursorPager only)
 */
interface AdvancedFiltersItemsPerPageProps {
    filtersInput: FiltersInput;
    itemsPerPageOptions?: number[];
    all?: number;
    itemCount: number;
    pagination: Pagination | CursorPagination;
    className?: string;
    totalRows?: {
        count: number;
        loading: boolean;
    };
}

/**
 * AdvancedFiltersItemsPerPage
 * Renders items per page section for pages with advanced filters
 * @param props - see `AdvancedFiltersItemsPerPageProps`
 */
export function AdvancedFiltersItemsPerPage(
    props: AdvancedFiltersItemsPerPageProps
) {
    const { pagination, itemCount, filtersInput, totalRows } = props;
    let { itemsPerPageOptions = [25, 50, 100] } = props;

    // Defines className for wrapperDiv
    const className = classnames(
        "d-flex flex-row justify-content-between align-items-center",
        props.className
    );

    // If isCursorPagination -> Return component tree without PaginationSummary
    if (isCursorPagination(pagination)) {
        return (
            <div className={className}>
                <CursorPager
                    pagination={pagination}
                    itemCount={itemCount}
                    filtersInput={filtersInput}
                    totalRows={totalRows}
                />
                {itemsPerPageOptions.length > 0 && (
                    <ItemsPerPageCC
                        filtersInput={filtersInput}
                        itemsPerPageOptions={itemsPerPageOptions}
                        all={props.all}
                    />
                )}
            </div>
        );
    }

    // Removes 100 option if there are fewer than 100 items
    if (pagination.totalItems < 100) {
        itemsPerPageOptions = itemsPerPageOptions.filter((i) => i !== 100);
    }

    return (
        <div className={className}>
            <PaginationSummary {...(props.pagination as Pagination)} />
            {itemsPerPageOptions.length > 0 && (
                <ItemsPerPageCC
                    filtersInput={filtersInput}
                    itemsPerPageOptions={itemsPerPageOptions}
                    all={props.all}
                />
            )}
        </div>
    );
}

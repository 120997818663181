// Defines constants for preset upcoming/past values
export const THIRTY_DAYS: number = 30;
export const SIXTY_DAYS: number = 60;
export const NINETY_DAYS: number = 90;
export const SIX_MONTHS: number = 180;
export const ONE_YEAR: number = 365;

/**
 * DATE_PRESET_VALUES
 * Defines array of values encapsulating all the day presets
 * Used to determine if the user's input is a "preset" or a "custom" value
 */
export const DATE_PRESET_VALUES: number[] = [
    THIRTY_DAYS,
    SIXTY_DAYS,
    NINETY_DAYS,
    SIX_MONTHS,
    ONE_YEAR,
];

/**
 * DATE_PRESETS
 * Defines array of available preset ranges + labels for past/upcoming
 */
export const DATE_PRESETS: Array<{ label: string; value: number }> = [
    { label: "30 Days", value: THIRTY_DAYS },
    { label: "60 Days", value: SIXTY_DAYS },
    { label: "90 Days", value: NINETY_DAYS },
    { label: "6 Months", value: SIX_MONTHS },
    { label: "1 Year", value: ONE_YEAR },
];

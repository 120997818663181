import classnames from "classnames";
import * as React from "react";

// // // //

/**
 * CloseButton
 * Renders a close button that can be used whereever we need a styled close button
 * @param props
 */
export function CloseButton(props: { onRemove: () => void }) {
    return (
        <button
            type="button"
            aria-label="Close alert"
            className={classnames(
                "tw-text-inherit",
                "focus:tw-outline-none",
                "font-size-24-px border-none tw-bg-transparent"
            )}
            onClick={() => {
                props.onRemove();
            }}
        >
            <div>&times;</div>
        </button>
    );
}

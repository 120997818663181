import { PaginationInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";

// // // //

export const REPORT_ID_FIELD_NAME = "reportID";

// appInfoID filter is used by appUserReportResultBuckets
export const APPINFO_ID_FIELD_NAME = "appInfoID";

export const INTEGRATION_INSTANCE_COLUMN_KEY = "integration_instance";

/**
 * Define default pagination for UserReportResultBucketAdvancedFilters
 */
export const USER_REPORT_RESULT_BUCKET_LIST_DEFAULT_PAGINATION: PaginationInput = {
    ...DEFAULT_PAGINATION,
    sort: "terminated_user_count",
    direction: "desc",
};

import { webConfig } from "@src/config";
import { request } from "@src/util/request_util";

// ExportProps are the props needed to fetch an export to csv
interface ExportProps {
    query: string;
    variables: Object;
}

/**
 * fetchExport fetches a graphql query to export to csv
 * @param props
 */
export function fetchExport(props: ExportProps) {
    return new Promise<Blob>((resolve, reject) => {
        request(
            `graphql/query/csv-export`,
            {
                method: "POST",
                body: JSON.stringify(props),
            },
            webConfig.gwHost
        ).then(async (res: Response) => {
            // Handle successful response -> return res.blob()
            if (res.status === 200) {
                const blob = await res.blob();
                return resolve(blob);
            }

            // Pulls JSON error response from res
            const resJson: { code: number; error: string } = await res.json();

            // Reject for erroneous response
            return reject(resJson);
        });
    });
}

import { IntegrationService } from "@src/schema/IntegrationService";
import gql from "graphql-tag";

// // // //

// INTEGRATION_INDIVIDUAL_QUERY is the query for the integration individual page
export const INTEGRATIONS_FETCHER_QUERY = gql`
    query INTEGRATIONS_FETCHER_QUERY($filters: IntegrationsFilter!) {
        integrations {
            integrations(filters: $filters) {
                service
                content {
                    credAuth
                    uniqueAuthRoute
                    authInstructions
                    description
                    helpLink
                    integrationType
                    isCompliance
                    isSpend
                    isUsage
                    logoLink
                    name
                    site
                    slug
                    valueProps
                    isBeta
                    isLive
                    category
                    shortDescription
                    setupTime
                    longSync
                    demoLink
                    internal
                }
                instances {
                    id
                    userID
                    isActive
                    name
                    createdAt
                    status
                    lastFetch
                    service
                    deliveryOauth {
                        url
                        useJSON
                    }
                    user {
                        email
                    }
                    tokenStatus {
                        status
                        statusReason
                    }
                    extensions {
                        id
                        isActive
                        name
                        createdAt
                        status
                        authType
                        tokenStatus {
                            status
                            statusReason
                        }
                        user {
                            email
                        }
                    }
                }
                oauth {
                    url
                    useJSON
                }
                authFields {
                    type
                    name
                    placeholder
                }
                secondaryNames
            }
        }
    }
`;

export interface IntegrationFetcherResponse {
    integrations: {
        integrations: IntegrationService[];
        __typename: "Integrations";
    };
}

export interface IntegrationsFetcherVariables {
    filters: {
        orgID: number;
    };
}

export function queryFilters(orgID: number) {
    return {
        filters: { orgID },
    };
}

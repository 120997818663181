type ParamValue = string | number;

export interface QueryParams {
    [key: string]: ParamValue | ParamValue[];
}

interface UpdateParamsProps {
    params: QueryParams;
    key: string;
    val: ParamValue | ParamValue[];
    replace?: boolean;
}

/**
 * @name UpdateParams
 * @description UpdateParams updates a params for a given key and value.
 * If the key doesn't exist it adds to the params object.
 * If the key does exist it checks whether the val should be removed or added by checking whether it already exists in the params object -
 * We need to account for cases where the user is toggling sort parameters (i.e. the currently active sort param is 'names:asc', and the user de-selects 'name:asc')
 * Returns the updated query params.
 */
export function updateParams(
    updateParamsProps: UpdateParamsProps
): [QueryParams, boolean] {
    const { params, key, val, replace = false } = updateParamsProps;

    // If the key doesn't exist in the params object OR the replace flag is true we can just return a new object
    if (!(key in params) || replace) {
        return [
            {
                ...params,
                [key]: val,
            },
            false,
        ];
    }

    // Pull the existing value from params[key]
    const existingVal = params[key];
    const updatedParams = {
        ...params,
    };

    // Next we need to check whether the provided value prop exists in the queryParams object. Since params[key] can either be a primitive or an array,
    // we will have two separate checks.

    // If the vals match each other we need to remove the value from the object
    // This is done because clicking a DropdownItem for this params object will remove the currently applied filter
    if (val === existingVal) {
        delete updatedParams[key];
        return [updatedParams, true];
    }

    let vals = [];
    let isActive = false;
    // if it's an existing array we want to check if the value exists and delete it if it does or just add to the vals array.
    if (Array.isArray(existingVal)) {
        // we need to make a shallow copy of the underlying array so that we don't effect the existing elements
        vals = [...existingVal];
        const index = vals.indexOf(val);
        if (index < 0) {
            // if it doesn't exist we just add it
            vals.push(val);
        } else {
            // if it does exist we splice the val from the array
            vals.splice(index, 1);
            isActive = true;
        }
    } else {
        // otherwise we know it's a base value and we just create an array to work with
        vals = [existingVal, val];
    }

    // Update the value for `key` in `updatedParams` and return
    updatedParams[key] = vals;
    return [updatedParams, isActive];
}

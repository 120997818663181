import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import { PurchaseOrder } from "@src/schema/PurchaseOrder";
import { PurchaseOrdersFetcher } from "@src/modules/purchase_orders/components/purchase_order/list_fetcher/component";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import { ResolverProps } from "./component";

// // // //

export function PurchaseOrderResolverLayout(
    props: ResolverProps<PurchaseOrder> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const purchaseOrderID: string | undefined = props.params?.purchaseOrderID;

    if (purchaseOrderID === undefined) {
        captureMessage(
            "PurchaseOrderResolver - params.purchaseOrderID is undefined"
        );
        return null;
    }

    const filtersInput: FiltersInput = {
        organizationID: props.user.organizationID,
        defaults: [
            {
                fieldName: "id",
                val: purchaseOrderID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };

    return (
        <PurchaseOrdersFetcher filtersInput={filtersInput}>
            {({ loading, purchaseOrders }) => {
                const purchaseOrder:
                    | PurchaseOrder
                    | undefined = purchaseOrders.find(
                    (purchaseOrder: PurchaseOrder) =>
                        purchaseOrder?.id === purchaseOrderID
                );

                // Resolve Value
                if (props.children) {
                    return props.children({
                        value: purchaseOrder || null,
                        loading,
                    });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (purchaseOrder === undefined) {
                    captureMessage(
                        `PurchaseOrder - ${purchaseOrderID} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                // Render Breadcrumb
                return (
                    <React.Fragment>
                        {`Purchase Order - ${purchaseOrder.poNumber}`}
                    </React.Fragment>
                );
            }}
        </PurchaseOrdersFetcher>
    );
}

export const PurchaseOrderResolver: React.ComponentType<ResolverProps<
    PurchaseOrder
>> = withUser(PurchaseOrderResolverLayout);

import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";
import { ColumnSetting } from "./types";

// // // //

/**
 * ColumnListItemCheckbox
 * Renders a checkbox-style button for toggling visibility of a table column
 * @param columnSetting - the ColumnSetting whose `visible` property is being toggled
 * @param disabled - whether or not the button is disabled
 */
export function ColumnListItemCheckbox(props: {
    columnSetting: ColumnSetting;
    disabled: boolean;
}) {
    const { columnSetting, disabled } = props;

    const iconClassName = classnames({
        "fa fa-fw fa-lg": true,
        "fa-square-o text-grey": !columnSetting.visible,
        "fa-check-square text-blue": columnSetting.visible && !disabled,
        "fa-check-square text-grey": columnSetting.visible && disabled,
    });

    // Disable visible checkbox when:
    // - columnSetting.stickyLeft === true, OR
    // - columnSetting.allowToggleVisibility === false
    if (columnSetting.stickyLeft || !columnSetting.allowToggleVisibility) {
        return (
            <button
                disabled
                className={classnames("tw-invisible", styles.visibleCheckbox)}
            >
                <i className="fa fa-fw fa-lg fa-square" />
            </button>
        );
    }

    // Return checkbox
    return (
        <button disabled={disabled} className={styles.visibleCheckbox}>
            <i className={iconClassName} />
        </button>
    );
}

import { UserState, withUser } from "@src/shared_modules/user";
import gql from "graphql-tag";
import get from "lodash.get";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";

// // // //

/**
 * MasterTeam
 * Interface for describing all MasterTeam data in the UI
 */
export interface MasterTeam {
    id: number;
    uuid: string;
    name: string;
    hexColor: string;
    budget: number;
    initials: string;
}

/**
 * MasterTeamCache
 * Interface for describing all MasterTeam data in the Apollo InMemoryCache
 */
export interface MasterTeamCache extends MasterTeam {
    __typename: "MasterTeam";
}

/**
 * CachedMasterTeam
 * Defines type for MasterTeam instances in the cached data from the `WithTeams` HOC
 */
export type CachedMasterTeam = MasterTeam & { __typename: "MasterTeam" };

/**
 * WithTeamsCacheValue
 * Defines interface for Apollo InMemoryCache value for the WithTeams HOC
 */
export interface WithTeamsCacheValue {
    usage: {
        masterTeams: {
            results: CachedMasterTeam[];
            __typename: "MasterTeamList";
        };
        __typename: "Usage";
    };
}

/**
 * MASTER_TEAM_FRAGMENT
 * A GraphQL fragment used to query for fields on MasterTeam
 */
export const MASTER_TEAM_FRAGMENT = gql`
    fragment MASTER_TEAM_FRAGMENT on MasterTeam {
        id
        uuid
        name
        hexColor
        budget
        initials
    }
`;

// teamListQuery queries for the teams + the teams oveview data
export const masterTeamsQuery = gql`
    query masterTeamsQuery($teamsFilters: MasterTeamFilter) {
        usage {
            masterTeams(filters: $teamsFilters) {
                results {
                    ...MASTER_TEAM_FRAGMENT
                }
            }
        }
    }
    ${MASTER_TEAM_FRAGMENT}
`;

export function buildTeamsQuery(organizationID: number) {
    return {
        teamsFilters: {
            organizationID,
            page: 1,
            itemsPerPage: 1000, // we want to get all master teams
            sort: "name",
            direction: "asc",
        },
    };
}

// WithTeam renders a component with the teams injected into it
// NOTE - the return type of this function is React.ComponentType<any> because
// the `withUser` HOC doesn't retain the props type information of its child component
export function WithTeams<P extends any>(
    Component: React.ComponentType<P & { teams: MasterTeam[] }>
): React.ComponentType<any> {
    // List gets the list of teams for the table view
    function TeamQuery(props: { user: UserState } & P) {
        const vars = buildTeamsQuery(props.user.organizationID);
        return (
            <Query query={masterTeamsQuery} variables={vars}>
                {({ loading, error, data }: QueryResult) => {
                    if (loading) {
                        return null;
                    }
                    if (error) {
                        return null;
                    }

                    // Safely pulls results from GQL data
                    const teams = get(data, "usage.masterTeams.results", []);

                    return <Component {...props} teams={teams} />;
                }}
            </Query>
        );
    }
    return withUser(TeamQuery);
}

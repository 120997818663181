import { ModalPageCC } from "@src/shared_modules/modal_page";
import classnames from "classnames";
import * as React from "react";
import { Filters, FiltersInput } from "../advanced_filter_editor";
import { ReadOnlyFilterPills } from "../advanced_filters";
import styles from "./styles.module.css";

// // // //

/**
 * OrgSavedViewModalProps are the props of the `OrgSavedViewModal` component
 * @param filtersInput - FiltersInput instance passed down from AdvancedFiltersProvider
 * @param filters - The `defaults` and `advanced` filters used to render <ReadOnlyFilterPills />
 * @param title - The title for the modal
 * @param onClose - Callback fired off when the modal is closed
 */
export interface OrgSavedViewModalProps {
    filtersInput: FiltersInput;
    filters: Filters;
    title: string;
    onClose: () => void;
    children: any;
}

/**
 * OrgSavedViewModal
 * Renders the modal to create or update OrgSavedView
 * @param props - see `OrgSavedViewModalProps`
 */
export function OrgSavedViewModal(props: OrgSavedViewModalProps) {
    const { filtersInput } = props;

    // Defines flag indicating whether or not filtersInput has any user-defined filters
    const noFiltersInputPresent: boolean =
        filtersInput.defaults.length === 0 &&
        filtersInput.advanced.length === 0;

    return (
        <ModalPageCC
            background={null}
            onClose={() => props.onClose()}
            hideCloseButton
            className={styles.orgSavedViewModal}
        >
            <div className="d-flex flex-column">
                {/* Modal Header */}
                <div className="d-flex justify-content-between px-30-px pt-30-px">
                    <div className="d-flex flex-column">
                        <h3 className="font-primary-bold text-updated-black font-size-28-px pb-5-px">
                            {props.title}
                        </h3>
                        <p className="font-secondary text-grey-mid font-size-14-px">
                            Saved Views will appear in the ‘Saved Views’
                            dropdown
                        </p>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <button
                            onClick={() => props.onClose()}
                            className={classnames(
                                "outline-none border-none bg-white font-secondary font-size-12-px",
                                styles.dismissButton
                            )}
                        >
                            DISMISS
                            <i className="fa fa-fw fa-times pl-5-px" />
                        </button>
                    </div>
                </div>

                {/* Modal Content */}
                <div className="row d-flex mx-0 mt-10-px">
                    <div className="col-sm-7 px-30-px pt-20-px">
                        <p className="font-size-18-px text-updated-black font-primary">
                            Filter Preview
                        </p>

                        {/* Render ReadOnlyFilterPills  */}
                        {!noFiltersInputPresent && (
                            <ReadOnlyFilterPills
                                className="m-0 pt-10-px"
                                filters={props.filters}
                                filtersInput={props.filtersInput}
                            />
                        )}

                        {/* Render empty state */}
                        {noFiltersInputPresent && (
                            <div className="d-flex flex-column text-center mt-20-px mb-10-px px-30-px py-50-px border-grey-dim rounded">
                                <h2 className="text-grey-dim font-primary py-20-px">
                                    No Filters Defined
                                </h2>
                            </div>
                        )}
                    </div>
                    <div
                        className={classnames(
                            "col-sm-5 px-30-px pb-50-px pt-10-px mb-50-px",
                            styles.orgSavedViewModalChildren
                        )}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </ModalPageCC>
    );
}

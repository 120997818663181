import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { ApolloClient } from "apollo-client";
import get from "lodash.get";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    ARCHIVE_ORG_FIELD_MUTATION,
    ArchiveOrgFieldMutationRequest,
    ArchiveOrgFieldMutationResponse,
} from "./graphql";
import { OrgFieldUpsertInput } from "./types";

// // // //

// Defines success/error messages for archive action
const ARCHIVE_SUCCESS_MESSAGE = "Successfully archived organization field";
const ARCHIVE_ERROR_MESSAGE = "Could not archive organization field";

/**
 * ArchiveOrgFieldMutationProps
 * Props for ArchiveOrgFieldMutation
 */
interface ArchiveOrgFieldMutationProps {
    children: (childProps: {
        loading: boolean;
        client: ApolloClient<Object>;
        archiveOrgField: (
            orgFieldToArchive: OrgFieldUpsertInput
        ) => Promise<number | null>;
    }) => React.ReactNode;
}

/**
 * ArchiveOrgFieldMutationLayout
 * Archives the organization field at the specified ID
 * @param props - see ArchiveOrgFieldMutationProps
 */
export function ArchiveOrgFieldMutationLayout(
    props: ArchiveOrgFieldMutationProps & WithToasterProps
) {
    return (
        <Mutation<
            ArchiveOrgFieldMutationResponse,
            ArchiveOrgFieldMutationRequest
        >
            mutation={ARCHIVE_ORG_FIELD_MUTATION}
            onError={() => null}
        >
            {(commitMutation, { loading, error, client }) => {
                // Defines archiveOrgField function
                function archiveOrgField(
                    orgFieldToArchive: OrgFieldUpsertInput
                ): Promise<number | null> {
                    return new Promise((resolve) => {
                        return commitMutation({
                            variables: {
                                input: {
                                    ...orgFieldToArchive,
                                },
                            },
                        }).then(
                            (response: {
                                data:
                                    | undefined
                                    | ArchiveOrgFieldMutationResponse;
                            }) => {
                                // If mutation response is true, show success toaster
                                // and resolve promise with org field id
                                const success: boolean | null = get(
                                    response,
                                    "data.archiveOrgField",
                                    null
                                );

                                if (success === true) {
                                    props.addToaster({
                                        type: "success",
                                        text: ARCHIVE_SUCCESS_MESSAGE,
                                    });
                                    return resolve(orgFieldToArchive.id);
                                }

                                // Otherwise, shows error toaster and resolves promise with null
                                props.addToaster({
                                    type: "danger",
                                    text: ARCHIVE_ERROR_MESSAGE,
                                });
                                resolve(null);
                            }
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handles error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {/* Renders props.children + passes archiveOrgField function */}
                        {props.children({
                            loading,
                            client,
                            archiveOrgField,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// // // //

export const ArchiveOrgFieldMutation: React.ComponentType<ArchiveOrgFieldMutationProps> = withToaster(
    ArchiveOrgFieldMutationLayout
);

import { NumberInput } from "@src/shared_modules/input";
import { Emdash } from "@src/shared_modules/punctuation";
import { RadioButton } from "@src/shared_modules/radio_button";
import classnames from "classnames";
import * as React from "react";
import { SIXTY_DAYS, THIRTY_DAYS } from "./constants";
import styles from "./styles.module.css";
import { DateInputValue } from "./types";

// // // //

// Keycode Constants
// Used to ignore +/- inputs propagating in <NumberInput />
const KEYCODE_MINUS = 189;
const KEYCODE_PLUS = 187;

/**
 * isPlusOrMinusKey
 * Returns a boolean indicating whether the most recent keypress was a plus or minus key
 * Prevents undesirable signage from being included in the <NumberInput />
 * @param keyCode - the keyCode from the NumberInput.props.onKeydown event handler
 */
function isPlusOrMinusKey(keyCode: number): boolean {
    return [KEYCODE_PLUS, KEYCODE_MINUS].includes(keyCode);
}

// // // //

/**
 * sanitizeNumberValue
 * Ensures the numberValue param is a positive integer
 * @param numberValue - the number being transformed into a positive integer
 */
function sanitizeNumberValue(
    numberValue: number | string | null
): number | null {
    // Return null if numberValue is null
    if (numberValue === null) {
        return null;
    }

    // Return null if numberValue is an empty string
    if (typeof numberValue === "string" && numberValue === "") {
        return null;
    }

    // Return positive integer value
    return Math.abs(Math.floor(Number(numberValue)));
}

// // // //

// Defines default value + placeholder for single-day range NumberInput
const DEFAULT_DAYS_VALUE: number = THIRTY_DAYS;

/**
 * CustomDateRangeInput
 * Renders a component that allows the user to select either:
 * - a single number of days (past or upcoming)
 * - a range between two numbers of days (past or upcoming)
 * @param props.value - the current DateInput value
 * @param props.onChange - callback invoked to update DateInput.state.value
 */
export function CustomDateRangeInput(props: {
    value: DateInputValue;
    onChange: (updatedValue: DateInputValue) => void;
}) {
    const { value } = props;

    // Defines a flag indicating whether or not the "range" (vs "value") inputs are active
    const rangeActive: boolean = value.length === 2;

    // Defines an array to ensure that only valid values are passed into NumberInput.props.value
    const sanitizedValue: Array<number | null> = [];

    // Checks value[0]
    if (typeof value[0] === "number") {
        sanitizedValue.push(sanitizeNumberValue(Number(value[0])));
    } else {
        sanitizedValue.push(null);
    }

    // Checks value[1]
    if (typeof value[1] === "number") {
        sanitizedValue.push(sanitizeNumberValue(Number(value[1])));
    } else {
        sanitizedValue.push(null);
    }

    return (
        <div
            className={classnames(styles.customDateRange, "mt-10-px pl-15-px")}
        >
            {/* Renders single "Range-start" input */}
            <div className="d-flex flex-row align-items-center">
                <RadioButton
                    active={!rangeActive}
                    label="Value"
                    className={classnames(
                        "mr-10-px",
                        styles.customDateRangeRadioButton
                    )}
                    onClick={() => {
                        if (!rangeActive) {
                            return;
                        }
                        props.onChange([DEFAULT_DAYS_VALUE]);
                    }}
                />
                <div
                    className="d-flex align-items-center ml-20-px"
                    role="button"
                    onClick={() => {
                        // Allow switching to "value" (instead of range) by clicking the <NumberInput /> component
                        if (!rangeActive) {
                            return;
                        }
                        props.onChange([DEFAULT_DAYS_VALUE]);
                    }}
                >
                    <NumberInput
                        min={0}
                        step={1}
                        disabled={rangeActive}
                        className={styles.customDateRangeNumberInput}
                        placeholder={String(DEFAULT_DAYS_VALUE)}
                        value={!rangeActive ? sanitizedValue[0] : null}
                        onChange={(updatedValue) => {
                            // If the value is null -> invoke props.onChange with empty array
                            if (updatedValue === null) {
                                props.onChange([]);
                                return;
                            }

                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([sanitizeNumberValue(updatedValue)]);
                        }}
                        onBlur={(e) => {
                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([
                                sanitizeNumberValue(e.currentTarget.value),
                            ]);
                        }}
                        onKeyDown={(e) => {
                            // Ensures that +/- keystrokes aren't included in <NumberInput />
                            if (isPlusOrMinusKey(e.keyCode)) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    />
                    <p className="d-flex pl-5-px">days</p>
                </div>
            </div>

            {/* Renders "Range" inputs */}
            <div className="d-flex flex-row align-items-center mt-10-px">
                <RadioButton
                    active={rangeActive}
                    label="Range"
                    className={classnames(
                        "mr-10-px",
                        styles.customDateRangeRadioButton
                    )}
                    onClick={() => {
                        if (rangeActive) {
                            return;
                        }
                        props.onChange([THIRTY_DAYS, SIXTY_DAYS]);
                    }}
                />
                <div
                    className="d-flex align-items-center ml-20-px"
                    role="button"
                    onClick={() => {
                        // Allow switching to "range" (instead of value) by clicking the <NumberInput /> components
                        if (rangeActive) {
                            return;
                        }
                        props.onChange([THIRTY_DAYS, SIXTY_DAYS]);
                    }}
                >
                    <NumberInput
                        min={0}
                        step={1}
                        disabled={!rangeActive}
                        className={styles.customDateRangeNumberInput}
                        placeholder={"30"}
                        value={rangeActive ? sanitizedValue[0] : null}
                        onChange={(updatedVal) => {
                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([
                                sanitizeNumberValue(updatedVal),
                                sanitizedValue[1],
                            ]);
                        }}
                        onBlur={(e) => {
                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([
                                sanitizeNumberValue(e.currentTarget.value),
                                sanitizedValue[1],
                            ]);
                        }}
                        onKeyDown={(e) => {
                            // Ensures that +/- keystrokes aren't included in <NumberInput />
                            if (isPlusOrMinusKey(e.keyCode)) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    />
                    <div className="mx-10-px">
                        <Emdash />
                    </div>
                    <NumberInput
                        min={0}
                        step={1}
                        disabled={!rangeActive}
                        className={styles.customDateRangeNumberInput}
                        placeholder={"60"}
                        value={rangeActive ? sanitizedValue[1] : null}
                        onChange={(updatedVal) => {
                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([
                                sanitizedValue[0],
                                sanitizeNumberValue(updatedVal),
                            ]);
                        }}
                        onBlur={(e) => {
                            // Ensures updatedValue is a positive integer and invoke props.onChange
                            props.onChange([
                                sanitizedValue[0],
                                sanitizeNumberValue(e.currentTarget.value),
                            ]);
                        }}
                        onKeyDown={(e) => {
                            // Ensures that +/- keystrokes aren't included in <NumberInput />
                            if (isPlusOrMinusKey(e.keyCode)) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    />
                    <p className="d-flex pl-5-px">days</p>
                </div>
            </div>
        </div>
    );
}

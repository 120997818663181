import { request } from "@src/util/request_util";
import { Pagination } from "@src/schema/Pagination";

// FiltersV2 are second iteration of filter generation
export type FiltersV2 = { [key: string]: string[] };

// ListApi is the type that we render for a list api request
export interface ListApi<ResultType> {
    pagination: Pagination;
    total: number;
    filters: FiltersV2;
    sorts: string[];
    results: ResultType[];
}

// getList gets a list from the server
export function getList(
    search: string,
    app: string,
    route: string
): Promise<ListApi<any>> {
    // we only want to add the question mark for the query parameters if it's not already part of the search string
    let params = search;
    // if the params doesn't have a question mark in it for whatever reason we add it
    if (params.indexOf("?") === -1) {
        params = `?${params}`;
    }
    // if the route already has the param delimiter we swap out the first character for an ampersand to
    // join it with the route - we can do this bec the above ensures that the params always has a question mark in it
    if (route.indexOf("?") >= 0) {
        params = `&${params.slice(1)}`;
    }
    const url = route + params;
    return request(url, {}, app).then((res) => res.json());
}

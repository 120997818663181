import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { WebhookListFetcher } from "@src/modules/webhooks/components/list_fetcher/component";
import { Webhook } from "@src/schema/Webhook";
import { ResolverProps } from "./component";
import { DEFAULT_PAGINATION } from "../../modules/advanced_filters/components/advanced_filters/constants";

// // // //

// Takes a webhookID and resolves a webhook to props.children or–if !props.children - resolves to the webhook name
export function WebhookResolverLayout(
    props: ResolverProps<Webhook> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const webhookID: string | undefined = props.params?.webhookID;

    if (webhookID === undefined) {
        captureMessage("WebhookResolver - params.webhookID is undefined");
        return null;
    }

    const { organizationID } = props.user;

    // Define filters for WebhookListFetcher
    const filtersInput: FiltersInput = buildWebhookFetcherFiltersInput({
        webhookID,
        organizationID,
    });

    return (
        <WebhookListFetcher filtersInput={filtersInput}>
            {({ loading, webhooks }) => {
                const webhook: Webhook | undefined = webhooks[0];

                if (props.children) {
                    return props.children({ value: webhook, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (webhook === undefined) {
                    captureMessage(`Webhook - ${webhookID} not found`);
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return <React.Fragment>{webhook.name}</React.Fragment>;
            }}
        </WebhookListFetcher>
    );
}

export const WebhookResolver: React.ComponentType<ResolverProps<
    Webhook
>> = withUser(WebhookResolverLayout);

/**
 * Function that builds the FiltersInput for the WebhookListFetcher
 */
export function buildWebhookFetcherFiltersInput(props: {
    webhookID: string;
    organizationID: number;
}): FiltersInput {
    const { webhookID, organizationID } = props;
    return {
        organizationID,
        defaults: [
            {
                fieldName: "id",
                val: webhookID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };
}

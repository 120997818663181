import { BreadcrumbNode } from "../types";
import { webhooksNode } from "../webhooks";
import { webhookIndividualState } from "./state";

// // // //

export const webhookIndividualNode: BreadcrumbNode = {
    getState: webhookIndividualState,
    parent: webhooksNode,
};

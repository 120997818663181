import gql from "graphql-tag";
import {
    APPLIED_ADVANCED_FILTER_FRAGMENT,
    AppliedAdvancedFilter,
} from "./AppliedAdvancedFilter";
import { APPLIED_FILTER_FRAGMENT, AppliedFilter } from "./AppliedFilter";
import { Pagination, PAGINATION_FRAGMENT } from "./Pagination";

// // // //

/**
 * AppliedFilter is the type of the saved filter input
 */
export interface AppliedFilters {
    defaults: AppliedFilter[];
    advanced: AppliedAdvancedFilter[];
    pagination: Pagination;
    __typename: "AppliedFilters";
}

export const APPLIED_FILTERS_FRAGMENT = gql`
    fragment APPLIED_FILTERS_FRAGMENT on AppliedFilters {
        defaults {
            ...APPLIED_FILTER_FRAGMENT
        }
        advanced {
            ...APPLIED_ADVANCED_FILTER_FRAGMENT
        }
        pagination {
            ...PAGINATION_FRAGMENT
        }
    }
    ${APPLIED_FILTER_FRAGMENT}
    ${APPLIED_ADVANCED_FILTER_FRAGMENT}
    ${PAGINATION_FRAGMENT}
`;

import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { buildQsFromObject } from "@src/util/route";
import classnames from "classnames";
import * as React from "react";
import { MANAGE_COLUMNS_PARAM } from "./constants";

// // // //

/**
 * ManageColumnsButtonProps
 * @param route - RouteState from withRouter HOC
 * @param className - (optional) Additional className applied to the <LinkCC /> component
 */
interface ManageColumnsButtonProps {
    route: RouteState;
    className?: string;
}

/**
 * ManageColumnsButton
 * Renders a button to display the `ManageColumnsModal` component by adding RouteState.params[MANAGE_COLUMNS_PARAM] to the current route
 * @param props - see ManageColumnsButtonProps
 */
export function ManageColumnsButtonLayout(props: ManageColumnsButtonProps) {
    const { route, className = "" } = props;

    // Constructs link to current page with `RouteState.params[MANAGE_COLUMNS_PARAM]` defined
    const updatedParams = { ...route.params, [MANAGE_COLUMNS_PARAM]: true };
    const manageColumnsUrl: string = `${route.location}${buildQsFromObject(
        updatedParams
    )}`;

    // Renders styled LinkCC component
    return (
        <LinkCC
            href={{
                pathname: route.nextPathname,
                query: route.pathParams,
            }}
            as={manageColumnsUrl}
            className={classnames({
                [className]: className !== "",
            })}
        >
            Manage Columns
        </LinkCC>
    );
}

export const ManageColumnsButton = withRouter(ManageColumnsButtonLayout);

import * as React from "react";
import { Vendor } from "./graphql";
import { hasRelatedVendors } from "./hasRelatedVendors";
import { RelatedVendorsButton } from "./RelatedVendorsButton";
import { RelatedVendorsList } from "./RelatedVendorsList";
import { RelatedVendorsModal } from "./RelatedVendorsModal";
import { IndividualPageType } from "./types";

// // // //

interface RelatedVendorsModalButtonProps {
    vendor: Vendor;
    className?: string;
    individualPage: IndividualPageType;
}

/**
 * RelatedVendorsModalButton
 * Responsible for hiding/showing the RelatedVendorsModal
 * @param props - see `RelatedVendorsModalButtonProps`
 */
export function RelatedVendorsModalButton(
    props: RelatedVendorsModalButtonProps
) {
    const { vendor, individualPage, className = "" } = props;
    const [showingModal, showModal] = React.useState<boolean>(false);

    // Defines flags used to determine if this component should simply return null
    const { hasParent, hasChildren, hasSiblings, isParent } = hasRelatedVendors(
        vendor
    );

    // If there is no parent, no children, and siblings data -> return null
    if (!hasParent && !hasChildren && !hasSiblings) {
        return null;
    }

    // Determines which Vendor is the parentVendor
    // This dictates which vendor is passed into the RelatedVendorsButton + RelatedVendorsModal + RelatedVendorsList
    let parentVendor: Vendor | undefined = vendor.parent;
    if (isParent) {
        parentVendor = vendor;
    }

    // If parentVendor is undefined -> return null;
    if (parentVendor === undefined) {
        return null;
    }

    return (
        <React.Fragment>
            {/* Render the RelatedVendorsButton, wired up to showModal */}
            <RelatedVendorsButton
                vendor={parentVendor}
                className={className}
                onClick={() => {
                    showModal(true);
                }}
            />

            {/* Show the RelatedVendorsModal + RelatedVendorsList */}
            {showingModal && (
                <RelatedVendorsModal
                    vendor={parentVendor}
                    onClose={() => {
                        showModal(false);
                    }}
                >
                    <RelatedVendorsList
                        vendor={vendor}
                        individualPage={individualPage}
                        onClose={() => {
                            showModal(false);
                        }}
                    />
                </RelatedVendorsModal>
            )}
        </React.Fragment>
    );
}

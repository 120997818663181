import classNames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * Loader
 * Renders a loading indicator component
 */
export function Loader() {
    return (
        <div
            className={classNames(
                styles.loaderWrapper,
                "d-flex justify-content-center"
            )}
        >
            <span className={styles.loader}>
                <span className={styles.loaderInner} />
            </span>
        </div>
    );
}

import nextRouter from "next/router";
import { buildTrackFunction } from "./buildTrackFunction";
import { routeCategoriesMap } from "./routeCategoriesMap";
import { routeNamesMap } from "./routeNamesMap";

// // // //

export const track = buildTrackFunction({
    router: nextRouter,
    routeCategoriesMap,
    routeNamesMap,
});

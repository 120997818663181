import { datadogRum } from "@datadog/browser-rum";
import { webConfig } from "@src/config";

// // // //

/**
 * initDatadogRum
 * Renders Datadog RUM initialization via <script> tag
 */
export function initDatadogRum() {
    const {
        datadogRumServiceName = "",
        datadogRumClientToken = "",
        datadogRumAppID = "",
    } = webConfig;

    // Short-circuit if SSR, or the requisite environment variables aren't found
    if (
        typeof window === "undefined" ||
        !datadogRumAppID.length ||
        !datadogRumClientToken.length ||
        !datadogRumServiceName.length
    ) {
        return null;
    }

    // Initialize Datadog RUM
    datadogRum.init({
        applicationId: datadogRumAppID,
        clientToken: datadogRumClientToken,
        site: "datadoghq.com",
        service: datadogRumServiceName,
        version: "1.0.0",
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
}

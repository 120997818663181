import { AppInfo } from "@src/schema/AppInfo";
import { ApplicationUsageSummary } from "@src/schema/ApplicationUsageSummary";
import { ConnectedApp } from "@src/schema/ConnectedApp";
import { IntegrationService } from "@src/schema/IntegrationService";
import { PrimaryIntegrationUser } from "@src/schema/PrimaryIntegrationUser";
import { PurchaseOrder } from "@src/schema/PurchaseOrder";
import { Report } from "@src/schema/Report";
import { AppUserReportResultBucket } from "@src/schema/AppUserReportResultBucket";
import { Webhook } from "@src/schema/Webhook";
import { Workflow } from "@src/schema/Workflow";
import { Vendor } from "@src/shared_modules/related_vendors";
import * as React from "react";
import { captureMessage } from "../../util/analytics/captureMessage";
import { AppInfoResolver } from "./AppInfoResolver";
import { AppUsageSummaryResolver } from "./AppUsageSummaryResolver";
import { ConnectedAppResolver } from "./ConnectedAppResolver";
import { IntegrationResolver } from "./IntegrationResolver";
import { LabelResolver } from "./LabelResolver";
import { PrimaryIntegrationUserResolver } from "./PrimaryIntegrationUserResolver";
import { PurchaseOrderResolver } from "./PurchaseOrderResolver";
import { RankResolver } from "./RankResolver";
import { ReportResolver } from "./ReportResolver";
import { DisplayResolverProps, DisplayResolverTypes } from "./types";
import { UserReportResultBucketResolver } from "./UserReportResultBucketResolver";
import { VendorResolver } from "./VendorResolver";
import { WebhookResolver } from "./WebhookResolver";
import { WorkflowResolver } from "./WorkflowResolver";

// // // //

// The possible values a resolver can return
type ValueType =
    | string
    | null
    | Vendor
    | ApplicationUsageSummary
    | ConnectedApp
    | PurchaseOrder
    | PrimaryIntegrationUser
    | IntegrationService
    | Webhook
    | Workflow
    | AppInfo
    | Report
    | AppUserReportResultBucket;

export interface ResolverProps<T = ValueType> extends DisplayResolverProps {
    children?: (childProps: {
        loading: boolean;
        value: T;
    }) => React.ReactElement;
}

type DisplayResolverMap = {
    [key in DisplayResolverTypes]: React.ComponentType<ResolverProps>;
};

export function DisplayResolver<T extends ValueType>(props: ResolverProps<T>) {
    const { type } = props;

    // Maps a display resolver type to a Resolver Component
    const resolverMap: DisplayResolverMap = {
        [DisplayResolverTypes.label]: LabelResolver,
        [DisplayResolverTypes.vendor]: VendorResolver,
        [DisplayResolverTypes.appUsageSummary]: AppUsageSummaryResolver,
        [DisplayResolverTypes.connectedApp]: ConnectedAppResolver,
        [DisplayResolverTypes.purchaseOrder]: PurchaseOrderResolver,
        [DisplayResolverTypes.usageRank]: RankResolver,
        [DisplayResolverTypes.integration]: IntegrationResolver,
        [DisplayResolverTypes.webhook]: WebhookResolver,
        [DisplayResolverTypes.workflow]: WorkflowResolver,
        [DisplayResolverTypes.primaryIntegrationUser]: PrimaryIntegrationUserResolver,
        [DisplayResolverTypes.appInfo]: AppInfoResolver,
        [DisplayResolverTypes.report]: ReportResolver,
        [DisplayResolverTypes.userReportResultBucket]: UserReportResultBucketResolver,
    };

    const ResolverComponent:
        | React.ComponentType<ResolverProps<ValueType>>
        | undefined = resolverMap[type];

    if (ResolverComponent === undefined) {
        captureMessage(
            `Resolver component of type ${type} is not defined in resolver map`
        );
        return null;
    }

    return <ResolverComponent {...props} />;
}

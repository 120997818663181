import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { formatMoment, genMoment } from "@src/util/date_util";
import { ONE_YEAR, SIX_MONTHS } from "./constants";
import { isDateInputDataValid } from "./isDateInputDataValid";
import { DateInputValue } from "./types";

// // // //

// Defines string constants for building preview text
const PAST_TEXT = "Past";
const NEXT_TEXT = "Next";
const DAYS_TEXT = "days";
const SIX_MONTHS_TEXT = "6 months";
const ONE_YEAR_TEXT = "year";

/**
 * getDateInputPreviewText
 * Accepts DateInputValue and returns human-readable expression summarizing the Date
 * @param props.value
 * @param props.expression
 */
export function getDateInputPreviewText(props: {
    value: DateInputValue;
    expression: FilterExpression;
}): string | null {
    const { value, expression } = props;

    // Return null if expression, or expression/value pair is invalid
    if (!isDateInputDataValid({ value, expression })) {
        return null;
    }

    // Handle "Undefined" expression
    if (expression === FilterExpression.adp_ude) {
        return "Undefined date";
    }

    // Handle "Defined" expression
    if (expression === FilterExpression.adp_dde) {
        return "Defined date";
    }

    // Handle "Fixed" expressions
    if (expression === FilterExpression.adp_fde) {
        // Handle Fixed with single date
        if (value.length === 1) {
            return formatMoment(genMoment(String(value[0])));
        }

        // Handle Fixed with start + end date
        const startDate = formatMoment(genMoment(String(value[0])));
        const endDate = formatMoment(genMoment(String(value[1])));

        // Returns ISO formatted date range
        return `${startDate} - ${endDate}`;
    }

    // Handle "Upcoming" + "Past"
    let PAST_OR_NEXT = PAST_TEXT;
    if (expression === FilterExpression.adp_upe) {
        PAST_OR_NEXT = NEXT_TEXT;
    }

    // Handle value with length 1
    if (value.length === 1) {
        // Handle 6-months + 1-year values
        if (value[0] === SIX_MONTHS) {
            return `${PAST_OR_NEXT} ${SIX_MONTHS_TEXT}`;
        }
        if (value[0] === ONE_YEAR) {
            return `The ${PAST_OR_NEXT.toLowerCase()} ${ONE_YEAR_TEXT}`;
        }

        // Handle all other values
        return `${PAST_OR_NEXT} ${value[0]} ${DAYS_TEXT}`;
    }

    // Handle value with length === 2
    return `${PAST_OR_NEXT} ${value[0]} ${DAYS_TEXT} - ${PAST_OR_NEXT} ${value[1]} ${DAYS_TEXT}`;
}

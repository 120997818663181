import * as React from "react";
import { Tooltip, TooltipPlacement } from "@src/shared_modules/tooltip";
import classNames from "classnames";
import styles from "./styles.module.css";

// // // //

/**
 * InfoTooltipProps
 * Represents the props of the InfoTooltip component
 * `children` - tooltip content
 * `placement` - position for the <HoverTooltip> component
 */
interface InfoTooltipProps {
    children: React.ReactNode;
    placement?: TooltipPlacement;
}

/**
 * InfoTooltip
 * Renders a `TooltipIcon` with tooltip content displayed on hover
 */
export function InfoTooltip(props: InfoTooltipProps) {
    return (
        <Tooltip
            placement={props.placement}
            tooltipContent={
                <div
                    className="tw-px-3 font-secondary-bold font-size-12-px line-height-23-px text-grey-mid text-left tw-normal-case"
                    style={{ minWidth: "200px" }}
                >
                    {props.children}
                </div>
            }
        >
            {({ setTriggerRef }) => (
                <div ref={setTriggerRef} className="ml-5-px tw-cursor-help">
                    <div
                        className={classNames(
                            styles.tooltipIcon,
                            "font-primary-heavy font-size-12-px tw-flex tw-items-center tw-justify-center"
                        )}
                    >
                        i
                    </div>
                </div>
            )}
        </Tooltip>
    );
}

import { Pagination } from "@src/schema/Pagination";
import { Webhook } from "@src/schema/Webhook";
import { WEBHOOKS_LIST_FRAGMENT } from "@src/schema/WebhooksList";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import gql from "graphql-tag";

export const WEBHOOK_LIST_FETCHER_QUERY = gql`
    query WEBHOOK_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        workflows {
            webhooks {
                list(filters: $filters) {
                    ...WEBHOOKS_LIST_FRAGMENT
                }
            }
        }
    }
    ${WEBHOOKS_LIST_FRAGMENT}
`;

export interface WebhookListFetcherResponse {
    workflows: {
        webhooks: {
            list: {
                results: Webhook[];
                pagination: Pagination;
                __typename: "WebhooksList";
            };
            __typename: "WebhooksListing";
        };
        __typename: "Workflows";
    };
}

export interface WebhookListFetcherVariables {
    filters: FiltersInput;
}

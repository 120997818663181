import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { Query } from "react-apollo";
import {
    INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY,
    SyncStatusRecipientsFetcherResponse,
    SyncStatusRecipientsFetcherVariables,
} from "./graphql";

// // // //

interface Props {
    organizationID: number;
    children: (childProps: {
        loading: boolean;
        recipients: string[];
    }) => React.ReactNode;
}

/**
 * @name SyncStatusRecipientsFetcher
 * @description Fetches Integration Sync Status recipients via GQL
 * @param {Props} props
 */
export function SyncStatusRecipientsFetcher(props: Props) {
    const { organizationID } = props;
    // IMPORTANT - we include `cache-and-network` fetchPolicy here to ensure that the Apollo Client
    // knows to render the updated data after we invoke `refetch`. This is necessary to handle a known bug in the Apollo Client
    // where a query dispatched without any changes in variables data will cause the loading state to hang if the fetchPolicy is `cache-only`.
    return (
        <Query<
            SyncStatusRecipientsFetcherResponse,
            SyncStatusRecipientsFetcherVariables
        >
            query={INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY}
            fetchPolicy="network-only"
            variables={{ orgID: organizationID }}
        >
            {({ loading, error, data }) => {
                // Safely pulls recipients from data + assigns default value
                const recipients: string[] =
                    data?.organization?.syncNotificationRecipients || [];

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            recipients,
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

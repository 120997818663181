import classnames from "classnames";
import * as React from "react";

// // // //

interface Props {
    // text is the text that is used for the header
    text?: string;
    // alignRight should be explicitly set to false if we don't want the header items to be right-aligned
    alignRight?: boolean;
    children?: React.ReactNode;
    className?: string;
    // Necessary for properly rendering wrapped inline filters
    hasMinHeight?: boolean;
    // wrapperClassName is the additional class name that can be passed. it is applied to the wrapper div to provide more flexibility, e.g. we can limit content width
    wrapperClassName?: string;
    bgTransparent?: boolean;
}

/**
 * DashboardListHeader is the top level header that we use for the dashboard
 */
export function DashboardListHeader(props: Props) {
    const {
        alignRight = true,
        hasMinHeight = true,
        bgTransparent = false,
    } = props;
    const headerClassName = classnames(
        {
            "tw-bg-white": !bgTransparent,
            "tw-pl-6": bgTransparent, // NOTE - part of the AdvancedFiltersV2 Layout
        },
        "tw-flex",
        "border-t-none border-l-none border-r-none tw-border-solid tw-border tw-border-gray-border",
        props.className,
        {
            "tw-min-h-[83px]": hasMinHeight,
        }
    );

    const wrapperClass = classnames("tw-flex tw-grow px-30-px", {
        [props.wrapperClassName]: props.wrapperClassName,
    });

    const childrenContainerClass = classnames("tw-flex tw-grow", {
        "tw-justify-end": alignRight,
    });

    return (
        <header className={headerClassName}>
            <div className={wrapperClass}>
                {props.text && (
                    <h1 className="tw-flex tw-items-center font-size-28-px font-primary-bold text-updated-black py-20-px pr-30-px">
                        {props.text}
                    </h1>
                )}
                <div className={childrenContainerClass}>{props.children}</div>
            </div>
        </header>
    );
}

import { trackStateUpdate } from "@src/analytics/applications";
import { AppFieldFetcher } from "@src/modules/org_fields/pages/custom_fields_upload/AppFieldFetcher";
import { AppField } from "@src/modules/org_fields/components/application_fields_fetcher";
import { ApplicationFieldsUpdater } from "@src/modules/org_fields/components/application_fields_updater";
import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import { updateItem } from "@src/shared_modules/list";
import { OrgFieldFetcher } from "@src/modules/org_fields/components/org_fields";
import {
    modelType,
    SpendInfo,
} from "@src/modules/payments/pages/spend_listing/reducer";
import { StateDot } from "@src/shared_modules/state_dot";
import { captureMessage } from "@src/util/analytics";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// // // //

/**
 * AppStateProps
 * Props for the AppState component
 * @param onClick - (optional) callback fired off when the button rendered by the AppState component is clicked
 */
interface AppStateProps {
    appState: string;
    onClick?: (string) => void;
}

/**
 * AppState
 * Renders a button that holds an application state and reports to the parent when clicked
 * @param props - see AppStateProps
 */
export function AppState(props: AppStateProps) {
    return (
        <button
            className="border-none tw-bg-transparent d-flex align-items-center outline-none py-12-px px-15-px w-100"
            onClick={props.onClick}
        >
            <StateDot status={props.appState} />
            <p className="pl-10-px font-size-16-px">{props.appState}</p>
        </button>
    );
}

/**
 * AppStateDropdownProps
 * Props for the AppStateDropdown component
 * @param app - The application tile that's using StateDropdown component, we need to update the state of the dropdown within that tile
 * @param activeAppState - The current active application state
 * @param possibleAppStates - The possible application states (dropdown menu)
 * @param appId - The current application id
 */
interface AppStateDropdownProps {
    app: SpendInfo;
    activeAppState: string;
    possibleAppStates: string[];
    appID: number;
}

// ListDispatchProps just pieces together the list updateItem method to the component
interface ListDispatchProps {
    updateItem: (
        id: string,
        idValue: any,
        value: any,
        modelType: string
    ) => void;
}

/**
 * AppStateDropdownLayoutProps
 * Props required by the AppStateDropdownLayout component
 * @param appFieldID - the ID of the AppField that contains the state of the current application
 * @param orgFieldID - the ID of the State OrgField
 */
interface AppStateDropdownLayoutProps {
    appFieldID: number;
    orgFieldID: number;
}

/**
 * StateDropdown
 * Renders the current state of an application and the possible states (using AppState component).
 * When a click happens in the child component it calls the onSave method which submit it to the api and shows a toast message
 * @param props - see AppStateDropdownProps
 */
export function AppStateDropdownLayout(
    props: AppStateDropdownProps &
        AppStateDropdownLayoutProps &
        ListDispatchProps
) {
    return (
        <ApplicationFieldsUpdater>
            {({ loading, updateApplicationFields }) => (
                <Dropdown>
                    <AppState appState={props.activeAppState} />
                    {props.possibleAppStates.map((state: string) => (
                        <DropdownListItem
                            active={
                                state.toLocaleLowerCase() ===
                                props.activeAppState.toLocaleLowerCase()
                            }
                            key={`state-${props.appID}-${state}`}
                        >
                            <AppState
                                appState={state}
                                onClick={() => {
                                    trackStateUpdate(
                                        props.appID,
                                        props.activeAppState,
                                        state
                                    );

                                    // In case the user select the already active state there's no need to do anything
                                    if (
                                        state === props.activeAppState ||
                                        loading
                                    ) {
                                        return;
                                    }

                                    // Construct the application field object to update
                                    const appField: AppField = {
                                        id: props.appFieldID,
                                        orgFieldID: props.orgFieldID,
                                        appID: props.appID,
                                        data: [state],
                                    };

                                    updateApplicationFields({
                                        appFields: [appField],
                                    }).then((appFields) => {
                                        // Don't update the application state on mutation error
                                        if (appFields === null) {
                                            return;
                                        }

                                        // Update the cached application state in redux
                                        props.updateItem(
                                            "id",
                                            props.appID,
                                            {
                                                ...props.app,
                                                state,
                                            },
                                            modelType
                                        );
                                    });
                                }}
                            />
                        </DropdownListItem>
                    ))}
                </Dropdown>
            )}
        </ApplicationFieldsUpdater>
    );
}

/**
 * appStateDropdownMapDispatchToProps
 * This function binds updateItem to the listing updateItem, so we can use the update method.
 * It is just used to update the local state of the current listing item.
 * NOTE - it can be removed when we migrate the SpendListing page to use GraphQL
 */
function appStateDropdownMapDispatchToProps(dispatch): ListDispatchProps {
    return bindActionCreators(
        {
            updateItem,
        },
        dispatch
    );
}

/**
 * StateDropdown
 * Passes the ID of the State org field and app field to the AppStateDropdownLayout component
 * @param props - see AppStateDropdownProps
 */
function StateDropdown(props: AppStateDropdownProps & ListDispatchProps) {
    return (
        <OrgFieldFetcher>
            {({ orgFields, loading: loadingOrgFields }) => {
                // Retur null if orgFields are loading
                if (loadingOrgFields) {
                    return null;
                }

                // Find the State org field returned by the API
                const stateOrgField = orgFields.find(
                    (orgField) => orgField.name === "State"
                );

                // Don't render anything if the State org field was not returned by the API
                if (stateOrgField === undefined) {
                    captureMessage("StateDropdown - State OrgField not found");
                    return null;
                }

                // Render AppStateDropdownLayout and pass the ID of the State org field
                return (
                    <AppFieldFetcher
                        filters={{
                            orgFieldIDList: [stateOrgField.id],
                        }}
                    >
                        {({ loading, appFields }) => {
                            // Render null if AppFieldFetcher is loading
                            if (loading) {
                                return null;
                            }

                            // Find the State app field returned by the API
                            // corresponding to the appID in SpendInfo
                            const stateAppField = appFields.find(
                                (appField) =>
                                    appField.orgFieldID === stateOrgField.id &&
                                    appField.appID === props.appID
                            );

                            // Don't render anything if the State app field was not returned by the API
                            // Capture message with vendor + appID + current app state
                            if (stateAppField === undefined) {
                                captureMessage(
                                    "StateDropdown - State AppField not found",
                                    {
                                        extra: {
                                            appID: props.appID,
                                            state: props.app.state,
                                            vendor: props.app.vendor.name,
                                        },
                                    }
                                );
                                return null;
                            }

                            return (
                                <AppStateDropdownLayout
                                    app={props.app}
                                    activeAppState={props.activeAppState}
                                    possibleAppStates={props.possibleAppStates}
                                    appID={props.appID}
                                    appFieldID={stateAppField.id}
                                    orgFieldID={stateOrgField.id}
                                    updateItem={props.updateItem}
                                />
                            );
                        }}
                    </AppFieldFetcher>
                );
            }}
        </OrgFieldFetcher>
    );
}

export const AppStateDropdown: React.ComponentType<AppStateDropdownProps> = connect(
    null,
    appStateDropdownMapDispatchToProps
)(StateDropdown);

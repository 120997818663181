import { WatchQueryFetchPolicy } from "apollo-boost";
import { FORCE_REQUERY_PARAM } from "./constants";

/**
 * getFetchPolicyFromRouteParams
 * Defines the fetchPolicy for <Query /> depending on RouteState.params[FORCE_REQUERY_PARAM]
 * If the FORCE_REQUERY_PARAM is defined, we set use `cache-and-network` - otherwise, we use the default `cache-first`
 * @param params - RouteState.params
 */
export function getFetchPolicyFromRouteParams(params: {
    [key: string]: string;
}): WatchQueryFetchPolicy {
    if (params[FORCE_REQUERY_PARAM]) {
        return "cache-and-network";
    }
    return "cache-first";
}

import React from "react";

export function DangerV2(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#EE483B"
                    fillRule="nonzero"
                    transform="translate(-119 -313)"
                >
                    <g transform="translate(119 313)">
                        <path d="M11.986 18.146c.38 0 .771-.154 1.04-.426.285-.29.432-.656.444-1.058a1.498 1.498 0 00-.424-1.04 1.448 1.448 0 00-.48-.317 1.402 1.402 0 00-.984-.07 1.5 1.5 0 00-.632.367c-.272.271-.458.668-.443 1.057.012.385.147.75.416 1.03.287.305.654.435 1.063.457.304.017.656-.15.867-.358.213-.213.372-.56.358-.866-.015-.316-.118-.645-.358-.867a1.334 1.334 0 00-.867-.358c-.01 0-.02 0-.03-.002l.326.044a.287.287 0 01-.078-.022l.291.122a.289.289 0 01-.046-.027l.247.191a.307.307 0 01-.056-.056l.19.247c-.009-.015-.019-.03-.026-.046l.122.291a.47.47 0 01-.022-.078l.044.325c-.002-.036-.002-.02 0-.056l-.044.326a.287.287 0 01.022-.079c-.041.098-.083.196-.122.292.007-.017.017-.032.027-.047l-.191.248a.307.307 0 01.056-.057l-.247.191c.015-.01.03-.02.046-.027-.098.042-.195.084-.291.123a.47.47 0 01.078-.022l-.325.044c.036-.003.02-.003.056 0l-.326-.044c.03.005.052.01.079.022l-.292-.123a.288.288 0 01.047.027c-.084-.063-.164-.127-.248-.19.022.017.04.034.057.056-.064-.084-.128-.164-.191-.248.01.015.02.03.027.047a25.441 25.441 0 01-.123-.292.47.47 0 01.022.079l-.044-.326c.003.037.003.02 0 .056l.044-.325a.287.287 0 01-.022.078l.123-.291c-.008.017-.018.031-.027.046l.19-.247a.307.307 0 01-.056.056c.084-.064.164-.127.248-.19a.288.288 0 01-.047.026c.098-.042.196-.083.292-.122a.47.47 0 01-.079.022l.326-.044-.03.002c-.313 0-.643.137-.866.358-.213.213-.373.56-.358.867.015.315.118.644.358.866.242.218.538.358.869.358zM10.762 6.594v6.436c0 .314.137.644.357.867.213.213.56.372.867.358.316-.015.644-.118.867-.358.22-.24.358-.534.358-.867v-2.172-3.465-.799c0-.313-.138-.644-.358-.867-.213-.213-.56-.372-.867-.357-.316.014-.644.117-.867.357-.218.24-.357.534-.357.867z"></path>
                        <path d="M21.525 11.99c0 .49-.034.976-.098 1.461l.044-.325c-.127.928-.375 1.839-.737 2.703l.122-.291c-.36.855-.83 1.66-1.396 2.395l.191-.247c-.57.737-1.231 1.4-1.969 1.969.084-.064.164-.128.248-.191a10.897 10.897 0 01-2.395 1.395l.291-.122c-.864.362-1.775.61-2.704.737l.326-.044c-.97.13-1.957.13-2.926 0l.325.044a10.793 10.793 0 01-2.703-.737l.291.122c-.855-.36-1.66-.83-2.395-1.395l.247.19c-.737-.57-1.4-1.231-1.969-1.968l.191.247a10.897 10.897 0 01-1.396-2.395c.042.098.084.196.123.291a10.793 10.793 0 01-.737-2.703l.044.325c-.13-.97-.13-1.956 0-2.926l-.044.326c.127-.929.374-1.84.737-2.704l-.123.291c.36-.854.83-1.66 1.396-2.395-.063.083-.127.164-.19.248.57-.738 1.231-1.401 1.968-1.97l-.247.192a10.897 10.897 0 012.395-1.396l-.291.122c.864-.362 1.775-.61 2.703-.737l-.325.044c.97-.13 1.956-.13 2.926 0l-.326-.044c.929.127 1.84.375 2.704.737l-.291-.122c.854.36 1.66.83 2.395 1.396-.084-.064-.164-.128-.248-.191.738.57 1.401 1.231 1.97 1.969l-.192-.248c.566.735 1.034 1.54 1.396 2.395l-.122-.291c.362.864.61 1.775.737 2.704l-.044-.326c.064.487.095.974.098 1.464 0 .314.137.644.357.867.213.213.561.372.867.358.316-.015.644-.118.867-.358.22-.24.36-.534.358-.867a11.897 11.897 0 00-2.158-6.857c-.37-.519-.774-1.021-1.217-1.481a12.835 12.835 0 00-1.43-1.25c-.955-.732-2.02-1.285-3.147-1.7A11.85 11.85 0 0012.417.01a11.977 11.977 0 00-3.703.443 11.96 11.96 0 00-5.907 3.83 12.73 12.73 0 00-1.11 1.548A12.825 12.825 0 00.849 7.57C.38 8.708.126 9.919.033 11.147c-.096 1.239.022 2.505.313 3.712a11.812 11.812 0 001.379 3.326 11.95 11.95 0 002.253 2.719c.889.788 1.886 1.467 2.97 1.956.59.267 1.193.502 1.815.676.644.179 1.298.29 1.962.365 1.241.145 2.505.064 3.727-.184a11.71 11.71 0 003.382-1.266 11.93 11.93 0 002.794-2.162 11.868 11.868 0 002.055-2.9c.539-1.082.936-2.238 1.12-3.433.1-.654.168-1.31.168-1.972 0-.313-.137-.644-.357-.867-.213-.213-.561-.372-.867-.357-.316.015-.644.117-.867.357-.218.245-.355.54-.355.872z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { AppField } from "@src/modules/vendors/pages/vendor_listing_v1/types";
import gql from "graphql-tag";

// /// // //

/**
 * AppFieldFilters
 * The filters accepted by the APP_FIELDS_QUERY
 * @param vendorID - the VendorID to which the queried AppFields belong
 * @param orgFieldIDList - the list of OrgField IDs from which the AppFields are generated
 */
export interface AppFieldFilters {
    vendorID?: number;
    orgFieldIDList?: number[];
}

/**
 * AppFieldQueryVariables
 * Query variables interface for APP_FIELDS_QUERY
 */
export interface AppFieldQueryVariables {
    filters: AppFieldFilters & { orgID: number };
}

/**
 * AppFieldQueryResponse
 * Reponse interface for APP_FIELDS_QUERY
 */
export interface AppFieldQueryResponse {
    spend: {
        applicationFields: AppField[];
    };
}

/**
 * APP_FIELDS_QUERY
 * GQL query to reqeust AppFields from the API
 */
export const APP_FIELDS_QUERY = gql`
    query APP_FIELDS_QUERY($filters: AppFieldFilters!) {
        spend {
            applicationFields(filters: $filters) {
                data
                id
                appID
                orgFieldID
            }
        }
    }
`;

/**
 * buildAppFieldFilters
 * Builds an `AppFieldQueryVariables` instance for the APP_FIELDS_QUERY
 * @param props.orgID - value from UserState.organizationID
 * @param props.vendorID - the VendorID to which the queried AppFields belong
 * @param props.orgFieldIDList - the list of OrgField IDs from which the AppFields are generated
 */
export function buildAppFieldFilters(props: {
    orgID: number;
    vendorID?: number;
    orgFieldIDList?: number[];
}): AppFieldQueryVariables {
    const { orgID, vendorID, orgFieldIDList } = props;
    return {
        filters: {
            orgID,
            vendorID,
            orgFieldIDList,
        },
    };
}

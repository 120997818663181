import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchTooltips } from "./actions";
import { TooltipsState } from "./reducer";

/**
 * OwnProps
 * Represents the own props of the Tooltips component
 * `children` - nested elements that use will the loaded tooltips
 * `tag` - the value of the `tag` field in contentful that is used for fetching header tooltips
 */
interface OwnProps {
    children: React.ReactNode;
    tag: string;
}

/**
 * StateProps
 * Represents the props that are injected into the Tooltips component by redux connect() function
 */
interface StateProps {
    loaded: boolean;
}

/**
 * DispatchProps
 * Represents the props that are injected into the Tooltips component by redux connect() function
 */
interface DispatchProps {
    fetchTooltips: (tableKey: string) => void;
}

/**
 * Tooltips
 * Loads the tooltips from contentful into Redux state
 */
function Tooltips(props: OwnProps & StateProps & DispatchProps) {
    const { loaded, tag, children } = props;

    // dispatches the fetchTooltips() action only on first render and only if the tooltip tag was provided
    // useEffect() with an empty array as its second argument replaces componentDidMount() from class components
    React.useEffect(() => {
        // don't fetch the tooltips if already started loading them
        if (loaded) {
            return;
        }
        if (tag) {
            props.fetchTooltips(tag);
        }
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
}

/**
 * mapStateToProps
 * Checks the Redux `tooltips` store to see if the tooltips associated with props.tag were loaded
 * and provides the `loaded` property to the Tooltips component
 */
function mapStateToProps(
    { tooltips }: { tooltips: TooltipsState },
    ownProps: OwnProps
): StateProps {
    const { tag } = ownProps;
    const loaded = tooltips.loadedTooltips.has(tag);
    return {
        loaded,
    };
}

/**
 * mapDispatchToProps
 * Provides the `fetchTooltips` function to the Tooltips component
 */
function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators(
        {
            fetchTooltips,
        },
        dispatch
    );
}

export const FetchTooltips = connect(
    mapStateToProps,
    mapDispatchToProps
)(Tooltips);

import * as React from "react";

import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import { PreviewComponentProps } from "../types";

import styles from "../styles.module.css";

// DropdownButtonProps represents the props that should be passed to the DropdownButton component
interface DropdownButtonProps {
    value: string;
    onClick: () => void;
}

// DropdownButton renders the button inside the DropdownPreview
function DropdownButton(props: DropdownButtonProps) {
    return (
        <button
            className={`d-flex align-items-center ${styles.dropdownButton}`}
            onClick={props.onClick}
        >
            {props.value.length > 0 ? props.value : "Select a value"}
        </button>
    );
}

// DropdownPreviewState represents the state of the DropdownPreview component
interface DropdownPreviewState {
    value: string;
}

// DropdownPreview renders the dropdown preview
export class DropdownPreview extends React.Component<
    PreviewComponentProps,
    DropdownPreviewState
> {
    public static getDerivedStateFromProps(
        props: PreviewComponentProps,
        state: DropdownPreviewState
    ) {
        // reset the dropdown if the selected value was deleted from possible values list
        if (!props.values || !props.values.includes(state.value)) {
            return { value: "" };
        }
        return null;
    }

    constructor(props: PreviewComponentProps) {
        super(props);
        this.state = {
            value: "",
        };
        this.onClick = this.onClick.bind(this);
    }

    public onClick(value: string) {
        this.setState({ value });
    }

    public render() {
        const values = ["", ...(this.props.values || [])];
        // remove empty and duplicate field values
        const filteredValues = [...new Set(values.filter((value) => value))];
        return (
            <Dropdown>
                <DropdownButton
                    value={this.state.value}
                    onClick={() => {
                        this.onClick("");
                    }}
                />
                {filteredValues.map((value: string) => (
                    <DropdownListItem
                        active={value === this.state.value}
                        key={`dropdown-item-${value || "not-set"}`}
                        searchText={value}
                    >
                        <DropdownButton
                            value={value}
                            onClick={() => this.onClick(value)}
                        />
                    </DropdownListItem>
                ))}
            </Dropdown>
        );
    }
}

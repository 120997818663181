import { CoreEvents } from "@src/analytics/events";
import { Link } from "@src/shared_modules/link";
import { parseQsToObject } from "@src/util/route";
import { getLocationAndQsFromLink } from "@src/util/route/getLocationAndQsFromLink_util";
import memoize from "lodash.memoize";
import { useRouter } from "next/router";
import * as React from "react";
import { LinkProps } from "./types";

// // // //

// Defines memoized versions of getLocationAndQsFromLink & parseQsToObject
const getLocationAndQsFromLinkMemo = memoize(getLocationAndQsFromLink);
const parseQsToObjectMemo = memoize(parseQsToObject);

// BaseRouter = {
//     route: string;
//     pathname: string;
//     query: ParsedUrlQuery;
//     asPath: string;
// };

// RouteState
// NOTE - we use the `any` type for values in `params` and `pathParams` to mitigate a TS error that crops up when using `ParsedUrlQuery`
// Specifically, the error is a result of the `ParsedUrlQuery` type defined as `{ [key: string]: string | string[] }`
// This creates problems when accessing/manipulating data from `props.route` supplied via the `withRouter` HOC.
// i.e. A function call like, `parseInt(props.route.params.page, 10)` throws an error because `parseInt` can only accept a string,
// but `props.route.params.page` is typed as `string | string[]`. Using the `any` type for values in params/pathParams prevents this error.
export interface RouteState {
    qs: string;
    fullUrl: string;
    nextPathname: string;
    params: { [key: string]: any };
    pathParams: { [key: string]: any };
    location: string;
    updateRouteAction: (link: string, pathname?: string) => void;
}

// withRouter is a simple hoc that injects the router props into the component
export function withRouter(Component) {
    return (props) => {
        const router = useRouter();

        // Pulls location and QS from router.asPath
        // @ts-ignore
        const [loc, qs] = getLocationAndQsFromLinkMemo(router.asPath);

        // Parses query string to object
        const params = parseQsToObjectMemo(qs);

        // Assembles router passed in as `props.route`
        const backwardsRouter: RouteState = {
            qs,
            fullUrl: router.asPath,
            params,
            nextPathname: router.pathname,
            location: loc,
            pathParams: router.query,
            updateRouteAction: (href: string, pathname?: string) => {
                // Updates router history with pathname + href
                // This is typically done when updating a filter on a list/individual page
                if (pathname) {
                    router.push(pathname, href);
                    return;
                }

                // Updates router history for non-dynamic routes
                router.push(href, href);
            },
        };

        return <Component {...props} route={backwardsRouter} />;
    };
}

// // // //

export type LinkCCProps = LinkProps & { activeMatchQs?: boolean };

/**
 * LinkCC
 * Link component used for routing between pages internal to the web app
 * Links to external resources should use the `Anchor` component
 */
export const LinkCC: React.ComponentType<LinkCCProps> = withRouter(
    (props: LinkCCProps & { route: RouteState }) => {
        // If props.as then we want to use it - otherwise use string from props.href (if is not an object) OR href.pathname
        const hrefValue: string =
            props.as ||
            (typeof props.href === "string" ? props.href : props.href.pathname);

        // Maps the LinkProps to `next/link` component props
        const updatedProps = {
            ...props,
            href: hrefValue,
        };

        // Defines existingClassName & activeClassName constants
        const existingClassName: string = props.className;
        const activeClassName: string = "active";

        // Isolates the location of props.href
        const [hrefLocation] = getLocationAndQsFromLinkMemo(hrefValue);
        const [routerLocation] = getLocationAndQsFromLinkMemo(
            props.route.fullUrl
        );

        // check if the path is the same path as the url,
        // and if we only want to match based on query strings we ensure that there is a full url match
        // NOTE - this logic was taken from `uncommons/shared_modules/route/component.tsx`
        if (
            (routerLocation === hrefValue || routerLocation === hrefLocation) &&
            (!props.activeMatchQs || hrefValue === props.route.fullUrl)
        ) {
            updatedProps.className = `${existingClassName} ${activeClassName}`;
        }

        // Add default analytics props if there are none already
        const {
            eventProps = { page: props.route.nextPathname },
            eventName = CoreEvents.linkClicked,
        } = props;

        updatedProps.eventName = eventName;
        updatedProps.eventProps = eventProps;

        // Deletes route & activeMatchQs from updatedProps (not a valid props for <Link />)
        delete updatedProps.route;
        delete updatedProps.activeMatchQs;

        // Returns a Link component with our updated props
        return <Link {...updatedProps} />;
    }
);

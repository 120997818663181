import * as React from "react";
import { Vendor } from "./graphql";
import { hasRelatedVendors } from "./hasRelatedVendors";
import { RelatedVendorsModalButton } from "./RelatedVendorsModalButton";
import { IndividualPageType } from "./types";

// // // //

/**
 * TileRelatedVendorsProps
 * @property vendor - the Vendor whose related vendors are being rendered
 * @property individualPage - The IndividualPageType where each RelatedVendor will direct
 */
interface TileRelatedVendorsProps {
    vendor: Vendor;
    individualPage: IndividualPageType;
}

// // // //

/**
 * TileRelatedVendors
 * Renders the RelatedVendors + RelatedVendorsModalButton components to be rendered in the VendorTile component on the listing pages
 * @param props - see TileRelatedVendorsProps
 */
export function TileRelatedVendors(props: TileRelatedVendorsProps) {
    const { vendor, individualPage } = props;

    // Defines flags used to determine if this component should simply return null
    const { hasParent, hasChildren, hasSiblings } = hasRelatedVendors(vendor);

    // If there is no parent, no children, and siblings data -> return null
    if (!hasParent && !hasChildren && !hasSiblings) {
        return null;
    }

    // Renders RelatedVendorItems (includes props.vendor)
    return (
        <div className="d-flex align-items-center px-10-px mt-10-px mb-15-px w-100">
            <RelatedVendorsModalButton
                vendor={vendor}
                individualPage={individualPage}
            />
        </div>
    );
}

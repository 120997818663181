import { Pagination, PAGINATION_FRAGMENT } from "@src/schema/Pagination";
import gql from "graphql-tag";
import { Report, REPORT_FRAGMENT } from "./Report";

// // // //

/**
 * ReportList represents a list of Report objects w/ pagination
 */
export interface ReportList {
    results: Report[];
    pagination: Pagination;
    __typename: "ReportList";
}

export const REPORT_LIST_FRAGMENT = gql`
    fragment REPORT_LIST_FRAGMENT on ReportList {
        results {
            ...REPORT_FRAGMENT
        }
        pagination {
            ...PAGINATION_FRAGMENT
        }
    }
    ${REPORT_FRAGMENT}
    ${PAGINATION_FRAGMENT}
`;

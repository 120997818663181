import { DropdownSelect } from "@src/shared_modules/dropdown_select";
import { Dispatch } from "react";
import { InputSize } from "@src/shared_modules/input";
import classnames from "classnames";
import * as React from "react";
import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { WeekInputValue, CustomDateRangeState } from "./types";
import { getReducerInitialState, dateRangeReducer } from "./reducer";
import { DateRangeAction, DateRangeActionTypes } from "./actions";
import { isCustomDateRangeInputValid } from "./isCustomDateRangeInputValid";

// // // //

/**
 * Renders a pair of controlled inputs for week + year values
 */
function YearAndWeekInput(props: {
    title: string;
    description: string;
    disabled: boolean;
    year: number;
    yearOptions: any[];
    week: string;
    weekOptions: any[];
    onChangeYear: (updatedYear: number) => void;
    onChangeWeek: (updatedWeek: string) => void;
}) {
    const {
        title,
        description,
        disabled,
        year,
        yearOptions,
        week,
        weekOptions,
    } = props;
    const active = !disabled;

    return (
        <div
            className={classnames(
                "d-flex flex-column align-items-start ml-15-px mt-15-px",
                {
                    "text-grey-mid": active,
                    "text-grey-dim": !active,
                }
            )}
        >
            <p className={classnames("font-secondary-bold")}>
                {title} <span className="font-secondary">({description})</span>
            </p>

            <div className="d-flex mt-5-px tw-w-96 tw-gap-2">
                <DropdownSelect
                    value={year}
                    options={yearOptions}
                    size={InputSize.lg}
                    placeholder="Year"
                    className="mr-10-px"
                    onChange={(yearVal: number) => {
                        props.onChangeYear(yearVal);
                    }}
                />

                <DropdownSelect
                    value={week}
                    options={weekOptions}
                    size={InputSize.lg}
                    placeholder="Week"
                    onChange={(weekVal: string) => {
                        props.onChangeWeek(weekVal);
                    }}
                />
            </div>
        </div>
    );
}

/**
 * Renders a component that allows the user to select start + end week date strings
 * @param props.value - the current WeekInputValue
 * @param props.onChange - callback invoked to update WeekInputValue
 */
export function CustomWeekRangeInput(props: {
    value: WeekInputValue;
    onChange: (updatedValue: WeekInputValue) => void;
}) {
    const { value } = props;

    const [state, dispatch]: [
        CustomDateRangeState,
        Dispatch<DateRangeAction>
    ] = React.useReducer(
        dateRangeReducer,
        { value, expression: FilterExpression.adp_fde },
        getReducerInitialState
    );

    const {
        startYear,
        startWeek,
        startYearOptions,
        startWeekOptions,
        endYear,
        endWeek,
        endYearOptions,
        endWeekOptions,
    } = state;

    // Invoke useEffect callback when year or week values change
    React.useEffect(() => {
        // Defines the updated value using startWeek + endWeek
        const updatedValue: WeekInputValue = [state.startWeek, state.endWeek];

        // Invoke props.onChange if updatedValue is valid
        if (
            isCustomDateRangeInputValid({
                value: updatedValue,
                expression: FilterExpression.adp_fde,
            })
        ) {
            props.onChange([state.startWeek, state.endWeek]);
            return;
        }

        // Invoke props.onChange with an empty array for invalid values
        props.onChange([]);
    }, [state.startYear, state.endYear, state.startWeek, state.endWeek]);

    return (
        <div className="d-flex flex-column align-items-start">
            <YearAndWeekInput
                title="1. Start week"
                description="week starting on a sunday"
                disabled={false}
                year={startYear}
                week={startWeek}
                weekOptions={startWeekOptions}
                yearOptions={startYearOptions}
                onChangeYear={(updatedStartYear) => {
                    dispatch({
                        type: DateRangeActionTypes.setStartYear,
                        year: updatedStartYear,
                    });
                }}
                onChangeWeek={(updatedStartWeek) => {
                    dispatch({
                        type: DateRangeActionTypes.setStartWeek,
                        week: updatedStartWeek,
                    });
                }}
            />

            <YearAndWeekInput
                title="2. End week"
                description="week ending on Saturday"
                disabled={
                    endYearOptions.length === 0 && endWeekOptions.length === 0
                }
                year={endYear}
                week={endWeek}
                weekOptions={endWeekOptions}
                yearOptions={endYearOptions}
                onChangeYear={(updatedEndYear) => {
                    dispatch({
                        type: DateRangeActionTypes.setEndYear,
                        year: updatedEndYear,
                    });
                }}
                onChangeWeek={(updatedEndWeek) => {
                    dispatch({
                        type: DateRangeActionTypes.setEndWeek,
                        week: updatedEndWeek,
                    });
                }}
            />
        </div>
    );
}

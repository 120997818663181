import { webConfig } from "@src/config";
import { createClient, EntryCollection } from "contentful";
// // // //

// ContentfulQuery is an interface for constructing queries passed into the `getContentfulEntries` function
export interface ContentfulQuery {
    content_type: string;
    [key: string]: string;
}

// Props sent into the Contenful.createClient function
interface ContentfulProps {
    space: string;
    accessToken: string;
    host?: string;
}

// getContentfulDashboardEntries returns contentful entries from the dashboard space by a given query
export function getContentfulDashboardEntries<T>(
    query: ContentfulQuery
): Promise<EntryCollection<T>> {
    // Defines contentfulProps for createClient()
    const contentfulProps: ContentfulProps = {
        // This is the space ID. A space is like a project folder in Contentful terms
        space: webConfig.contentfulDashboardSpaceID,
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: webConfig.contentfulDashboardAccessToken,
    };

    const isProd = webConfig.appEnv === "production";

    // Handle host change in production
    // TASK - `contentfulProps.host` should be managed in configuration
    // https://app.clubhouse.io/intello/story/4941/contentfulprops-host-should-be-managed-in-environment
    if (!isProd) {
        contentfulProps.host = "preview.contentful.com";
    }

    // Creates Contentful client and fetches entries corresponding to query
    const contentful = createClient(contentfulProps);
    return contentful.getEntries(query);
}

import { trackSuggestedFiltersDropdownOpen } from "@src/analytics/advanced_filters";
import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import classnames from "classnames";
import * as React from "react";
import dropdownStyles from "@src/shared_modules/dropdown_select/styles.module.css";
import { SuggestedFilter } from "./graphql";
import styles from "./styles.module.css";
import { SuggestedFilterLink } from "./SuggestedFilterLink";

// // // //

/**
 * SuggestedFilterDropdownProps
 * @param suggestedFilters - The array of SuggestedFilters
 */
interface SuggestedFilterDropdownProps {
    suggestedFilters: SuggestedFilter[];
}

const buttonClassName = classnames(
    "w-100 text-updated-black whitespace-nowrap",
    dropdownStyles.dropdownButton,
    dropdownStyles.dropdownButtonSm
);

const itemClassname = classnames(buttonClassName, styles.dropdownButton);

/**
 * SuggestedFilterDropdown
 * Renders a Dropdown of SuggestedFilterLink components
 * @param props - see SuggestedFilterDropdownProps
 */
export function SuggestedFilterDropdown(props: SuggestedFilterDropdownProps) {
    const { suggestedFilters } = props;

    // Return null if no suggestedFilters are defined
    if (suggestedFilters.length === 0) {
        return null;
    }

    return (
        <Dropdown
            dropdownListWrapperClassName={styles.dropdownListWrapperClassName}
        >
            <button
                className={buttonClassName}
                onClick={trackSuggestedFiltersDropdownOpen}
            >
                Suggested Filters
            </button>
            {suggestedFilters.map((sf) => {
                return (
                    <DropdownListItem
                        key={`integration-sf-link-${sf.id}`}
                        active={false}
                        className={itemClassname}
                        searchText={sf.content.name}
                    >
                        <SuggestedFilterLink
                            suggestedFilter={sf}
                            className={styles.dropdownLink}
                        >
                            <div className="d-flex flex-column text-updated-black py-5-px px-5-px">
                                <p className="font-size-14-px">
                                    {sf.content.name}
                                </p>
                            </div>
                        </SuggestedFilterLink>
                    </DropdownListItem>
                );
            })}
        </Dropdown>
    );
}

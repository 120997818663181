import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import get from "lodash.get";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    UPDATE_ORG_FIELD_ORDER_MUTATION,
    UpdateOrgFieldOrderMutationRequest,
    UpdateOrgFieldOrderMutationResponse,
} from "./graphql";
import { OrganizationField } from "./types";

// // // //

// Defines success message for update action
const UPDATE_SUCCESS_MESSAGE = "Successfully updated organization fields order";

/**
 * UpdateOrgFieldOrderMutationProps
 * Props for UpdateOrgFieldOrderMutation
 */
interface UpdateOrgFieldOrderMutationProps {
    children: (childProps: {
        loading: boolean;
        updateOrgFieldOrder: (
            orgFieldIDs: number[]
        ) => Promise<OrganizationField[] | null>;
    }) => React.ReactNode;
}

/**
 * UpdateOrgFieldOrderMutationLayout
 * Updates the order of org fields
 * @param props - see UpdateOrgFieldOrderMutationProps
 */
export function UpdateOrgFieldOrderMutationLayout(
    props: UpdateOrgFieldOrderMutationProps & {
        user: UserState;
    } & WithToasterProps
) {
    return (
        <Mutation<
            UpdateOrgFieldOrderMutationResponse,
            UpdateOrgFieldOrderMutationRequest
        >
            mutation={UPDATE_ORG_FIELD_ORDER_MUTATION}
            onError={() => null}
        >
            {(commitMutation, { loading, error }) => {
                // Defines updateOrgFieldOrder function
                function updateOrgFieldOrder(
                    orgFieldIDs: number[]
                ): Promise<OrganizationField[] | null> {
                    return new Promise((resolve) => {
                        return commitMutation({
                            variables: {
                                input: {
                                    orgID: props.user.organizationID,
                                    orgFieldIDs,
                                },
                            },
                        }).then(
                            (response: {
                                data:
                                    | undefined
                                    | UpdateOrgFieldOrderMutationResponse;
                            }) => {
                                // Pulls OrganizationField[] from response
                                const organizationFields:
                                    | null
                                    | OrganizationField[] = get(
                                    response,
                                    "data.updateOrgFieldOrder",
                                    null
                                );

                                // Displays success toaster if organizationFields isn't null
                                if (organizationFields !== null) {
                                    props.addToaster({
                                        type: "success",
                                        text: UPDATE_SUCCESS_MESSAGE,
                                    });
                                }

                                // Resolves promise with OrganizationField | null
                                resolve(organizationFields);
                            }
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handles error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {/* Renders props.children + passes updateOrgFieldOrder function */}
                        {props.children({
                            loading,
                            updateOrgFieldOrder,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// // // //

export const UpdateOrgFieldOrderMutation: React.ComponentType<UpdateOrgFieldOrderMutationProps> = withToaster(
    withUser(UpdateOrgFieldOrderMutationLayout)
);

import { faCheck, faSort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import { PossibleValue } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { InputSize } from "@src/shared_modules/input";
import { IntegrationsFetcher } from "@src/modules/integrations/components/integrations_fetcher";
import { INTEGRATION_ICON_OVERRIDES } from "@src/modules/integrations/components/integrations_fetcher/consts";
import { FadeInOut } from "@src/shared_modules/transitions/FadeInOut";
import classNames from "classnames";
import React from "react";

// // // //

interface Props {
    value: string;
    placeholder: string;
    possibleValues: PossibleValue[];
    size?: InputSize;
    onChange: (updatedVal: string) => void;
}

interface PossibleValueWithIcon extends PossibleValue {
    icon: string;
}

/**
 * SortIcon
 * Renders sort icon for Listbox.Button + loading placeholder
 */
function SortIcon() {
    return (
        <span className="tw-ml-3 tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
            <FontAwesomeIcon
                size="sm"
                fixedWidth
                className="tw-h-5 tw-w-5 tw-text-gray-400"
                icon={faSort}
            />
        </span>
    );
}

/**
 * OptionContent
 * Renders content for selected item + each ListboxOption
 */
function OptionContent(props: {
    size: InputSize;
    selected?: boolean;
    option: PossibleValueWithIcon;
}) {
    const { option, size, selected = false } = props;
    const { icon, humanValue } = option;
    return (
        <React.Fragment>
            <img
                src={icon}
                alt={`${humanValue} Logo`}
                className={classNames("tw-flex-shrink-0 tw-rounded", {
                    "tw-h-8 tw-w-8": size === InputSize.md,
                    "tw-h-10 tw-w-10": size === InputSize.lg,
                })}
            />
            <span
                className={classNames("tw-ml-3 tw-block tw-truncate", {
                    "tw-font-medium": selected,
                    "tw-font-normal": !selected,
                    "tw-text-xl": size === InputSize.md,
                    "tw-text-2xl": size === InputSize.lg,
                })}
            >
                {humanValue}
            </span>
        </React.Fragment>
    );
}

function ListboxOption(props: {
    option: PossibleValueWithIcon;
    size: InputSize;
}) {
    const { option, size } = props;
    return (
        <Listbox.Option
            className={({ active }) =>
                classNames(
                    "tw-cursor-pointer tw-select-none tw-relative tw-py-2 tw-pl-5 tw-pr-9",
                    {
                        "tw-bg-gray-200": active,
                        "tw-text-gray-900": !active,
                        "tw-py-5": size === InputSize.lg,
                    }
                )
            }
            value={option}
        >
            {({ selected, active }) => (
                <React.Fragment>
                    <div className="tw-flex tw-items-center">
                        <OptionContent
                            size={size}
                            option={option}
                            selected={selected}
                        />
                    </div>

                    {selected && (
                        <span
                            className={classNames(
                                "tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4",
                                {
                                    "tw-text-black": active,
                                    "tw-text-blue-600": !active,
                                }
                            )}
                        >
                            <FontAwesomeIcon
                                size="sm"
                                fixedWidth
                                className="tw-h-5 tw-w-5"
                                icon={faCheck}
                            />
                        </span>
                    )}
                </React.Fragment>
            )}
        </Listbox.Option>
    );
}

/**
 * getButtonClassname
 * Define classNames for ListBox.Button + loading placeholder
 */
function getButtonClassname(props: {
    disabled: boolean;
    size: InputSize;
}): string {
    const { disabled, size } = props;
    return classNames(
        "tw-relative tw-bg-white tw-border tw-border-gray-border tw-rounded-md tw-text-left",
        "focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500",
        "tw-pl-5 tw-pr-10",
        {
            "tw-cursor-not-allowed": disabled,
            "tw-cursor-pointer": !disabled,
            "tw-py-2 tw-min-w-[16rem]": size === InputSize.md,
            "tw-py-6 tw-w-full font-size-16-px": size === InputSize.lg,
        }
    );
}

/**
 * SourceSingleDropdown
 * Dropdown to filter by a single source Integration
 */
export function SourceSingleDropdown(props: Props) {
    const {
        value,
        possibleValues,
        placeholder = "Source",
        size = InputSize.md,
        onChange = () => {},
    } = props;

    return (
        <IntegrationsFetcher>
            {({ loading, getIntegration }) => {
                // Render loading placeholder while fetching Integrations
                if (loading) {
                    return (
                        <button
                            disabled
                            className={getButtonClassname({
                                disabled: true,
                                size,
                            })}
                        >
                            <span className="tw-text-medium-gray">
                                {placeholder}
                            </span>
                            <SortIcon />
                        </button>
                    );
                }

                // Update options to include integration logo
                const normalizedOptions: Array<PossibleValueWithIcon> = possibleValues
                    .map((pv) => {
                        const integration = getIntegration(
                            pv.internalValue as string
                        );
                        return {
                            ...pv,
                            icon:
                                INTEGRATION_ICON_OVERRIDES[
                                    pv.internalValue as string
                                ] || integration?.content.logoLink,
                        };
                    })
                    .sort((a, b) =>
                        a.humanValue.toLowerCase() < b.humanValue.toLowerCase()
                            ? -1
                            : 1
                    );

                // Lookup selected item in normalizedOptions
                const selected = normalizedOptions.find(
                    (i) => i.internalValue === value
                );

                return (
                    <Listbox
                        value={selected}
                        onChange={(updated) => {
                            if (onChange) {
                                onChange(updated.internalValue as string);
                            }
                        }}
                    >
                        {({ open }) => (
                            <React.Fragment>
                                <div className="tw-relative">
                                    <Listbox.Button
                                        className={getButtonClassname({
                                            disabled: false,
                                            size,
                                        })}
                                    >
                                        <span className="tw-flex tw-items-center">
                                            {selected && (
                                                <OptionContent
                                                    size={size}
                                                    option={selected}
                                                />
                                            )}
                                            {!selected && (
                                                <span className="tw-text-medium-gray">
                                                    {placeholder}
                                                </span>
                                            )}
                                        </span>
                                        <SortIcon />
                                    </Listbox.Button>

                                    <FadeInOut show={open} duration={100}>
                                        <Listbox.Options
                                            className={classNames(
                                                "tw-absolute tw-z-200 tw-w-full tw-max-h-96 tw-bg-white tw-shadow-lg tw-rounded-md tw-text-base tw-overflow-auto",
                                                "tw-mt-1 tw-py-1",
                                                "tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none"
                                            )}
                                        >
                                            {normalizedOptions.map((opt) => (
                                                <ListboxOption
                                                    option={opt}
                                                    size={size}
                                                    key={
                                                        opt.internalValue as string
                                                    }
                                                />
                                            ))}
                                        </Listbox.Options>
                                    </FadeInOut>
                                </div>
                            </React.Fragment>
                        )}
                    </Listbox>
                );
            }}
        </IntegrationsFetcher>
    );
}

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";
import {
    ApplicationUsageSummaryList,
    APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT,
} from "@src/schema/ApplicationUsageSummaryList";

// // // //

/**
 * APP_USAGE_SUMMARY_LIST_FETCHER_QUERY
 * Query for ApplicationUsageSummary data
 */
export const APP_USAGE_SUMMARY_LIST_FETCHER_QUERY = gql`
    query APP_USAGE_SUMMARY_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        usage {
            applicationUsageSummaries {
                list(filters: $filters) {
                    ...APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT
                }
            }
        }
    }
    ${APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT}
`;

/**
 * AppUsageSummaryListFetcherResponse
 * Response interface for APP_USAGE_SUMMARY_LIST_FETCHER_QUERY
 */
export interface AppUsageSummaryListFetcherResponse {
    usage: {
        applicationUsageSummaries: {
            list: ApplicationUsageSummaryList;
            __typename: "ApplicationUsageSummaries";
        };
        __typename: "Usage";
    };
}

/**
 * AppUsageSummaryListFetcherVariables
 * Variables interface for APP_USAGE_SUMMARY_LIST_FETCHER_QUERY
 */
export interface AppUsageSummaryListFetcherVariables {
    filters: FiltersInput;
}

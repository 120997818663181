import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import { ConnectedAppsListFetcher } from "@src/modules/connected_apps/components/connected_app/list_fetcher";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { ConnectedApp } from "@src/schema/ConnectedApp";
import { ResolverProps } from "./component";
import { DEFAULT_PAGINATION } from "../../modules/advanced_filters/components/advanced_filters/constants";

// // // //

/**
 * Function that builds the FiltersInput for the ConnectedAppResolver
 */
export function buildConnectedAppResolverFiltersInput(props: {
    connectedAppID: string;
    organizationID: number;
}): FiltersInput {
    const { connectedAppID, organizationID } = props;
    return {
        organizationID,
        defaults: [
            {
                fieldName: "id",
                val: connectedAppID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };
}

export function ConnectedAppResolverLayout(
    props: ResolverProps<ConnectedApp> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const connectedAppID: string | undefined = props.params?.connectedAppID;

    if (connectedAppID === undefined) {
        captureMessage(
            "ConnectedAppResolver - params.connectedAppID is undefined"
        );
        return null;
    }

    const { organizationID } = props.user;

    const filtersInput: FiltersInput = buildConnectedAppResolverFiltersInput({
        connectedAppID,
        organizationID,
    });

    return (
        <ConnectedAppsListFetcher filtersInput={filtersInput}>
            {({ loading, connectedApps }) => {
                const connectedApp:
                    | ConnectedApp
                    | undefined = connectedApps.find(
                    (connectedApp: ConnectedApp) =>
                        connectedApp?.id === connectedAppID
                );

                if (props.children) {
                    return props.children({ value: connectedApp, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (connectedApp === undefined) {
                    captureMessage(
                        `ConnectedApp - ${connectedAppID} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return (
                    <React.Fragment>
                        {connectedApp.sourceAppName}
                    </React.Fragment>
                );
            }}
        </ConnectedAppsListFetcher>
    );
}

export const ConnectedAppResolver: React.ComponentType<ResolverProps<
    ConnectedApp
>> = withUser(ConnectedAppResolverLayout);

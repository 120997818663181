import classnames from "classnames";
import * as React from "react";

/**
 * RadioButtonProps are the props of the RadioButton component
 * @param active - whether or not the radio button is active
 * @param onClick - callback fired off when the radio button or its label is clicked
 * @param label - radio button label text
 * @param labelClassName - (optional) class name to apply to the label instead of its default styles
 * @param className - (optional) class name to apply to the wrapper div
 */
interface RadioButtonProps {
    active: boolean;
    onClick: () => void;
    label: string;
    labelClassName?: string;
    className?: string;
}

/**
 * RadioButton
 * Renders a radio button with a label
 * @param props - see RadioButtonProps
 */
export function RadioButton(props: RadioButtonProps) {
    return (
        <div className={props.className}>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick();
                }}
                className={classnames(
                    "tw-flex tw-items-center", // Flex
                    "tw-bg-transparent", // Background
                    "tw-p-0", // Padding
                    "tw-border-none tw-outline-none" // Border & Outline
                )}
            >
                <div
                    className={classnames(
                        "tw-flex tw-items-center tw-justify-center", // Flex
                        "tw-h-[18px] tw-w-[18px]", // Size
                        "tw-border tw-border-solid tw-rounded-full tw-border-gray-dim" // Border
                    )}
                >
                    {props.active && (
                        <div
                            className={classnames(
                                "tw-bg-updated-black", // Background
                                "tw-h-[12px] tw-w-[12px]", // Size
                                "tw-rounded-full" // Border Radius
                            )}
                        />
                    )}
                </div>
                {props.label !== "" && (
                    <span
                        className={classnames({
                            "tw-pl-[15px] tw-text-gray-mid font-size-14-px font-secondary": !props.labelClassName,
                            [props.labelClassName]: props.labelClassName,
                        })}
                    >
                        {props.label}
                    </span>
                )}
            </button>
        </div>
    );
}

import {
    FieldType,
    Filter,
    FiltersInput,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { isDateFieldType } from "@src/modules/advanced_filters/components/advanced_filters/date_input";
import { getDefaultExpression } from "./getDefaultExpression";
import { isWeekFieldType } from "./week_input";

// // // //

/**
 * getFilterFromPossibleFilter
 * Finds a 'Filter' inside a 'FiltersInput' instance corresponding to the `PossibleFilter`
 * passed as props.possibleFilter. Returns undefined in no filter is found.
 * @param props.possibleFilter - The PossibleFilter whose corresponding `Filter` we're trying to locate in `props.filtersInput`
 * @param props.filtersInput - The `FiltersInput` instance whose `defaults
 */
export function getFilterFromPossibleFilter(props: {
    possibleFilter: PossibleFilter;
    filtersInput: FiltersInput;
}): Filter | undefined {
    const { filtersInput, possibleFilter } = props;

    // Returns the result of filtersInput.defaults.find (Filter | undefined)
    return filtersInput.defaults.find((f: Filter) => {
        // Find a date_range `Filter` by checking:
        // - `f.fieldName` matches `PossibleFilter.key`, and
        // - `f.expression` is in `PossibleFilter.expressions`
        if (possibleFilter.fieldType === FieldType.date_range) {
            return (
                f.fieldName === possibleFilter.key &&
                possibleFilter.expressions.includes(f.expression)
            );
        }

        // Handle advanced_date_picker + advanced_past_date_picker + week_date_picker
        // NOTE - this must be handled separately beacuse the defaultExpression here isn't necessarily representitive of the PossibleFilter's default behavior
        if (
            isDateFieldType(possibleFilter.fieldType) ||
            isWeekFieldType(possibleFilter.fieldType)
        ) {
            return f.fieldName === possibleFilter.key;
        }

        // NOTE - this must be handled separately beacuse the defaultExpression here isn't necessarily representitive of the PossibleFilter's default behavior
        if (
            [FieldType.number, FieldType.float, FieldType.integer].includes(
                possibleFilter.fieldType
            )
        ) {
            return f.fieldName === possibleFilter.key;
        }

        // Find a `Filter` by checking:
        // - `f.fieldName` matches `PossibleFilter.key`, and
        // - `f.expression` matches the defaultExpression for `PossibleFilter`
        return (
            f.fieldName === possibleFilter.key &&
            f.expression === getDefaultExpression(possibleFilter)
        );
    });
}

import { TableCellTypes } from "@src/schema/TableCellType";

import { Column, ColumnSetting } from "./types";

// // // // //

/**
 * mapTableCellTypeToDefaultWidth
 * Maps each TableCellType to a default Column width
 */
export const mapTableCellTypeToDefaultWidth: {
    [key in TableCellTypes]: number;
} = {
    [TableCellTypes.text]: 200,
    [TableCellTypes.textArray]: 200,
    [TableCellTypes.textExpandable]: 200,
    [TableCellTypes.state]: 200,
    [TableCellTypes.name]: 200,
    [TableCellTypes.notes]: 200,
    [TableCellTypes.boolean]: 200,
    [TableCellTypes.checkbox]: 150,
    [TableCellTypes.checklist]: 150,
    [TableCellTypes.rbacRoles]: 200,
    [TableCellTypes.number]: 150,
    [TableCellTypes.integer]: 150,
    [TableCellTypes.float]: 150,
    [TableCellTypes.vendor]: 200,
    [TableCellTypes.source]: 150,
    [TableCellTypes.gdprStatus]: 200,
    [TableCellTypes.date]: 150,
    [TableCellTypes.downloadLink]: 200,
    [TableCellTypes.gdprStatus]: 200,
    [TableCellTypes.datetime]: 150,
    [TableCellTypes.privileges]: 150,
    [TableCellTypes.price]: 150,
    [TableCellTypes.email]: 200,
    [TableCellTypes.integrationStatus]: 150,
    [TableCellTypes.integrationInstance]: 200,
    [TableCellTypes.integrationUser]: 200,
    [TableCellTypes.masterUser]: 200,
    [TableCellTypes.gdprStatus]: 200,
    [TableCellTypes.checklist]: 200,
    [TableCellTypes.rbacRoles]: 250,
    [TableCellTypes.downloadLink]: 200,
    [TableCellTypes.switch]: 200,
    [TableCellTypes.rank]: 200,
    [TableCellTypes.percentageBar]: 200,
    [TableCellTypes.percentage]: 200,
    [TableCellTypes.frequencyCategory]: 400,
};

/**
 * buildDefaultColumnSetting
 * Accepts a single `Column` and returns an associated `ColumnSetting` instance
 * @param props.column - A single `Column` instance
 */
export function buildDefaultColumnSetting(props: {
    column: Column;
}): ColumnSetting {
    const { column } = props;
    return {
        key: column.key,
        width: mapTableCellTypeToDefaultWidth[column.type] || 300,
        visible: column.visibleByDefault,
        stickyLeft: false,
        allowToggleVisibility: true,
    };
}

/**
 * buildDefaultColumnSettings
 * Accepts an array of `Column` instances and returns an array of `ColumnSetting` instances, one for each `Column`
 * @param props.columns - Array of `Column` instances
 */
export function buildDefaultColumnSettings(props: {
    columns: Column[];
}): ColumnSetting[] {
    const { columns } = props;

    // Defines columnSettings from props.columns
    const columnSettings: ColumnSetting[] = columns.map(
        (col: Column): ColumnSetting => {
            return buildDefaultColumnSetting({ column: col });
        }
    );

    // Returns columnSettings
    return columnSettings;
}

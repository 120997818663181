import { AppField } from "@src/modules/org_fields/components/application_fields_fetcher";
import gql from "graphql-tag";

// // // //
// ApplicationFieldsUpdater Mutation

/**
 * ApplicationFieldsUpdaterMutationResponse
 * GQL mutation response for APPLICATION_FIELDS_UPDATER_MUTATION
 */
export interface ApplicationFieldsUpdaterMutationResponse {
    saveAppFields: AppField[];
}

/**
 * ApplicationFieldsUpdaterMutationInput
 * GQL mutation input for APPLICATION_FIELDS_UPDATER_MUTATION
 */
export interface ApplicationFieldsUpdaterMutationInput {
    input: {
        orgID: number;
        appFields: AppField[];
    };
}

/**
 * APPLICATION_FIELDS_UPDATER_MUTATION
 * Mutation for application fields
 */
export const APPLICATION_FIELDS_UPDATER_MUTATION = gql`
    mutation($input: SaveAppFieldInput!) {
        saveAppFields(input: $input) {
            data
            id
            appID
            orgFieldID
        }
    }
`;

import { BreadcrumbNode } from "../types";
import { vendorUsageAppListingState } from "./state";
import { usageIndividualNode } from "../usageIndividual";

// // // //

export const vendorUsageAppListingNode: BreadcrumbNode = {
    getState: vendorUsageAppListingState,
    parent: usageIndividualNode,
};

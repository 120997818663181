import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initUserAction } from "./actions";
import { UserState } from "./reducer";
import { UserLoading } from "./UserLoading";

// // // //

// Props are the props to render
type Props = {
    children: React.ReactNode;
} & UserState;

// DispatchProps is the dispatch props methods
interface DispatchProps {
    initUserAction: () => void;
}

// State is the state of the map to props
interface State {
    user: UserState;
}

// LoadUser is meant to be wrapped around the main component you want to render,
// this way we have the user information before we continue to render the rest of the application
// and anything that relies on user information down stream we can utilize
class LoadUser extends React.Component<Props & DispatchProps, {}> {
    public render() {
        if (this.props.loading) {
            return (
                <div>
                    <UserLoading />
                </div>
            );
        }

        return this.props.children;
    }

    public componentDidMount() {
        // Invoke initUserAction
        this.props.initUserAction();
    }
}

function mapStateToProps({ user }: State): UserState {
    return {
        ...user,
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators({ initUserAction }, dispatch);
}

export const LoadUserCC = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadUser);

import { trackFilterApplied } from "@src/analytics/base";
import { NumberInput } from "@src/shared_modules/input";
import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { updateParams } from "@src/util/route";
import * as React from "react";
import { QueryParams } from "@src/util/route/updateParams_util";

// // // //

interface OwnProps {
    // name is the query key
    name: string;
}

// getQueryParam gets the value of a query param based on a prefix - "start" or "end"
export function getQueryParam(
    params: QueryParams,
    key: string,
    prefix: string
): string | null {
    const queryValue = params[key];
    if (queryValue == null) {
        return null;
    }
    const values = Array.isArray(queryValue) ? queryValue : [queryValue];
    let value: string | null = null;
    values.forEach((val) => {
        if (typeof val !== "string") {
            return;
        }
        // check if the value starts with a given prefix. we are not using startsWith() here because it is not supported in ie
        if (val.lastIndexOf(`${prefix}:`, 0) === 0) {
            value = val.replace(`${prefix}:`, "");
        }
    });
    return value;
}

// NumberRangeFilter is a filter component that allows a user to filter by min and max numbers and allows the consumer of the filter to set the values of those numbers
export class NumberRangeFilter extends React.Component<
    OwnProps & { route: RouteState }
> {
    public updateRoute(min: string | null, max: string | null) {
        const values: string[] = [];
        if (min) {
            values.push(`start:${min}`);
        }
        if (max) {
            values.push(`end:${max}`);
        }
        const [params] = updateParams({
            params: this.props.route.params,
            key: this.props.name,
            val: values,
            replace: true,
        });
        // reset the page param when the filter is applied
        delete params.page;
        const url = buildUrlWithParams({
            location: this.props.route.location,
            params,
        });
        trackFilterApplied(this.props.name, { start: min, end: max }, url);
        this.props.route.updateRouteAction(url, this.props.route.nextPathname);
    }

    public render() {
        const min = getQueryParam(
            this.props.route.params,
            this.props.name,
            "start"
        );
        const max = getQueryParam(
            this.props.route.params,
            this.props.name,
            "end"
        );

        // NumberInput component accepts a number or null, so we build NumberInput values here
        const inputMin = min ? parseInt(min, 10) : null;
        const inputMax = max ? parseInt(max, 10) : null;

        return (
            <form className="flex-row align-items-center pr-10-px">
                <NumberInput
                    placeholder="Min"
                    value={inputMin}
                    onChange={(updatedMin: number | null) =>
                        this.updateRoute(String(Number(updatedMin)), max)
                    }
                />
                <span className="flex-shrink-0 tw-h-px tw-w-2.5 tw-bg-black tw-mx-4" />
                <NumberInput
                    placeholder="Max"
                    value={inputMax}
                    onChange={(updatedMax: number | null) =>
                        this.updateRoute(min, String(Number(updatedMax)))
                    }
                />
            </form>
        );
    }
}

export const NumberRangeFilterCC = withRouter(NumberRangeFilter);

import { track } from "@src/analytics/track";
import {
    FilterExpression,
    FilterVal,
} from "../modules/advanced_filters/components/advanced_filter_editor/graphql";

// // // //

enum AdvancedFiltersEvents {
    removePill = "Remove AdvancedFilter Pill",
    clearFilters = "Clear AdvancedFilters",
    filtersApplied = "Apply AdvancedFilters",
    openOverlay = "Open AdvancedFiltersOverlay",
    resetColumnSettings = "Reset Column Settings",
    applyColumnSettings = "Apply Column Settings",
    resizeColumnSetting = "Resize Column Setting",
    saveColumnSettings = "Save Column Settings",
    createAdvancedFilter = "Create Advanced Filter",
    editAdvancedFilter = "Edit Advanced Filter",
    updateAdvancedFilter = "Update Advanced Filter",
    removeAdvancedFilter = "Remove Advanced Filter",
}

export enum SuggestedFiltersEvents {
    suggestedFilterLinkClick = "SuggestedFilterLink Clicked",
    suggestedWorkflowLinkClick = "SuggestedWorkflowLink Clicked",
    openDropdown = "SuggestedFilterDropdown Opened",
}

/**
 * trackRemoveAdvancedFilterPill
 * Tracks when the user clicks removes an AdvancedFilterPill
 */
export function trackRemoveAdvancedFilterPill() {
    track(AdvancedFiltersEvents.removePill, {});
}

/**
 * trackClearFilters
 * Tracks when the user clicks the `Clear Filters` button
 */
export function trackClearFilters() {
    track(AdvancedFiltersEvents.clearFilters, {});
}

/**
 * trackAdvancedFiltersApplied
 * Tracks when the user clicks the `Apply Filters` button in the `AdvancedFiltersEditorOverlay` component
 */
export function trackAdvancedFiltersApplied() {
    track(AdvancedFiltersEvents.filtersApplied, {});
}

/**
 * trackAdvancedFiltersOpenOverlay
 * Tracks when the user clicks the `Advanced Filters` button to open the `AdvancedFiltersEditorOverlay` component
 */
export function trackAdvancedFiltersOpenOverlay() {
    track(AdvancedFiltersEvents.openOverlay, {});
}

/**
 * trackResetColumnSettings
 * Tracks when the user clicks the `ClearColumnSettingsButton`
 */
export function trackResetColumnSettings() {
    track(AdvancedFiltersEvents.resetColumnSettings, {});
}

/**
 * trackApplyColumnSettings
 * Tracks when the user clicks the `Update Table` button
 */
export function trackApplyColumnSettings() {
    track(AdvancedFiltersEvents.applyColumnSettings, {});
}

/**
 * trackResizeColumnSetting
 * Tracks when the user resizes a column
 */
export function trackResizeColumnSetting() {
    track(AdvancedFiltersEvents.resizeColumnSetting, {});
}

/**
 * trackSaveColumnSettings
 * Tracks when the user clicks the `Save As Default` button
 */
export function trackSaveColumnSettings() {
    track(AdvancedFiltersEvents.saveColumnSettings, {});
}

/**
 * trackCreateAdvancedFilter
 * Tracks when the user creates an advanced filter
 */
export function trackCreateAdvancedFilter(
    expression: FilterExpression,
    fieldName: string,
    val: FilterVal
) {
    track(AdvancedFiltersEvents.createAdvancedFilter, {
        expression,
        fieldName,
        val,
    });
}

/**
 * trackEditAdvancedFilter
 * Tracks when the user edits an advanced filter
 */
export function trackEditAdvancedFilter(
    expression: FilterExpression,
    fieldName: string,
    val: FilterVal
) {
    track(AdvancedFiltersEvents.editAdvancedFilter, {
        expression,
        fieldName,
        val,
    });
}

/**
 * trackUpdateAdvancedFilter
 * Tracks when the user updates an advanced filter
 */
export function trackUpdateAdvancedFilter(
    expression: FilterExpression,
    fieldName: string,
    val: FilterVal
) {
    track(AdvancedFiltersEvents.updateAdvancedFilter, {
        expression,
        fieldName,
        val,
    });
}

/**
 * trackRemoveAdvancedFilter
 * Tracks when the user removes an advanced filter
 */
export function trackRemoveAdvancedFilter(
    expression: FilterExpression,
    fieldName: string,
    val: FilterVal
) {
    track(AdvancedFiltersEvents.removeAdvancedFilter, {
        expression,
        fieldName,
        val,
    });
}

/**
 * trackSuggestedFiltersDropdownOpen
 * Tracks when the user opens the `SuggestedFilterDropdown`
 */
export function trackSuggestedFiltersDropdownOpen() {
    track(SuggestedFiltersEvents.openDropdown, {});
}

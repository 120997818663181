import * as React from "react";
import { Tooltip, TooltipPlacements } from "@src/shared_modules/tooltip";
import classNames from "classnames";
import styles from "./styles.module.css";

// // // //

interface MasterTeam {
    id: number;
    name: string;
    hexColor: string;
    budget: number;
    initials: string;
}

/**
 * TeamsCircles
 * Render labeled circles for an array of MasterTeams
 */
export function TeamsCircles(props: { teams: MasterTeam[] | null }) {
    if (props.teams === null) {
        return (
            <ul className="tw-flex tw-z-10 flex-wrap">
                <div />
            </ul>
        );
    }

    return (
        <ul className="tw-flex tw-z-10 flex-wrap">
            {props.teams.map((team: MasterTeam) => {
                if (team == null || team.name == null) {
                    return null;
                }
                const style = {
                    color: "#fff",
                    backgroundColor: "",
                };
                if (team.hexColor != null && team.hexColor.length > 0) {
                    style.backgroundColor = team.hexColor;
                } else {
                    // If no color is set for the team, set it black
                    style.backgroundColor = "#000";
                }

                /* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
                return (
                    <li
                        key={`team-item-${team.name}`}
                        className={styles.nameCircle}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={style}
                    >
                        <Tooltip
                            placement={TooltipPlacements.top}
                            tooltipContent={
                                <p
                                    className={classNames(
                                        "tw-p-3",
                                        styles.teamNameTooltip
                                    )}
                                >
                                    {team.name}
                                </p>
                            }
                        >
                            {({ setTriggerRef }) => (
                                <span ref={setTriggerRef}>{team.initials}</span>
                            )}
                        </Tooltip>
                    </li>
                );
            })}
        </ul>
    );
}

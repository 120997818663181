import { Header as WrappedHeader } from "@src/shared_modules/list";
import { InfoTooltip } from "@src/shared_modules/info_tooltip";
import * as React from "react";
import { connect } from "react-redux";
import { Tooltips, TooltipsState } from "../tooltips";

// // // //

/**
 * StateProps
 * The props that are injected into Header component by redux connect()
 */
interface StateProps {
    tooltips: Tooltips;
}

/**
 * HeaderProps
 * The props of the `Header` component
 * `className` - (optional) class name that is applied to the header
 * `text` - header text
 * `tooltipKey` - (optional) the value of the `key` field in contentful that is used for fetching the tooltip
 */
interface HeaderProps {
    className?: string;
    text: string;
    tooltipKey?: string;
}

/**
 * Header
 * Renders a dashboard listing column header. Wraps the `Header` component from `the_uncommons` and adds tooltip support
 */
export function Header(props: HeaderProps & StateProps) {
    const { className, text, tooltipKey, tooltips } = props;

    // show the tooltip if the tooltipKey property was passed and the text for that key exists
    const showTooltip: boolean =
        tooltipKey && tooltips[tooltipKey] !== undefined;

    return (
        <WrappedHeader className={className}>
            {text}
            {showTooltip && <InfoTooltip>{tooltips[tooltipKey]}</InfoTooltip>}
        </WrappedHeader>
    );
}

/**
 * mapStateToProps
 * Injects the tooltips from Redux `tooltips` state into the Header component
 */
function mapStateToProps({
    tooltips,
}: {
    tooltips: TooltipsState;
}): StateProps {
    return {
        tooltips: tooltips.tooltips,
    };
}

export const ColumnHeader: React.ComponentType<HeaderProps> = connect(
    mapStateToProps
)(Header);

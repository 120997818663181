import { captureMessage } from "../analytics";

/**
 * @name getLocationAndQsFromLink
 * @description Parses Location and query string from link by splitting URI from query params
 * @param {string} link
 * @returns {string[]} string array containing location and query string
 */
export function getLocationAndQsFromLink(link: string): [string, string] {
    if (link === undefined) {
        captureMessage("getLocationAndQsFromLink - link param is undefined");
    }
    const [loc, qs = ""]: string[] = link?.split("?") || ["/", ""];
    return [loc, qs];
}

import { BreadcrumbNode } from "../types";
import {
    rolesState,
    roleAssignmentsState,
    rolesIndividualState,
    roleAssignmentsIndividualState,
    accessControlState,
    accessControlStaticVendorState,
} from "./state";
import { vendorListingNode } from "../vendorListing";
import { connectedAppsListingState } from "../connected_apps";

// // // //

// NOTE - this can be deleted when we launch the overview page for all users
const connectedAppsListingNode: BreadcrumbNode = {
    getState: connectedAppsListingState,
    parent: null,
};

const accessControlNode: BreadcrumbNode = {
    getState: accessControlState,
    parent: connectedAppsListingNode,
};

// routes.globalRoles
export const rolesNode: BreadcrumbNode = {
    getState: rolesState,
    parent: accessControlNode,
};

// routes.globalRoleAssignments
export const roleAssignmentsNode: BreadcrumbNode = {
    getState: roleAssignmentsState,
    parent: accessControlNode,
};

// placeholder for <vendorName> that points to a /vendors parent
const accessControlStaticVendorNode: BreadcrumbNode = {
    getState: accessControlStaticVendorState,
    parent: vendorListingNode,
};

// placeholder node for /access-control on for individual pages
const accessControlStaticNode: BreadcrumbNode = {
    getState: accessControlState,
    parent: accessControlStaticVendorNode,
};

// routes.rolesIndividual
export const rolesIndividualNode: BreadcrumbNode = {
    getState: rolesIndividualState,
    parent: accessControlStaticNode,
};

// routes.roleAssignmentsIndividual
export const roleAssignmentsIndividualNode: BreadcrumbNode = {
    getState: roleAssignmentsIndividualState,
    parent: accessControlStaticNode,
};

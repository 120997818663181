import { logout } from "@src/requests/users";
import { authRedirect } from "@src/routes";
import { captureException } from "@src/util/analytics";
import * as React from "react";

// // // //

function redirectToHomePage() {
    window.location.assign(authRedirect());
}

/**
 * Renders a button to logout the user + redirect to homepage
 */
export function onClickLogout() {
    logout()
        .then(() => {
            redirectToHomePage();
        })
        .catch((error) => {
            redirectToHomePage();
            captureException(error);
        });
}

/**
 * Renders a button to logout the user + redirect to homepage
 */
export function LogoutButton() {
    return (
        <button
            className="btn-lg btn-primary tw-w-full"
            onClick={(e) => {
                e.preventDefault();
                onClickLogout();
            }}
        >
            Logout
        </button>
    );
}

import { logout } from "@src/requests/users";
import { publicRoutes } from "@src/routes";
import { captureException } from "@src/util/analytics";

export function logoutUser() {
    return () => {
        // Redirect the user to the home page regardless of logout API success / failur
        logout()
            .then(() => {
                window.location.assign(publicRoutes.home);
            })
            .catch((error) => {
                window.location.assign(publicRoutes.home);
                captureException(error);
            });
    };
}

import React from "react";

export function FieldChecklist(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="29"
            viewBox="0 0 33 29"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <g
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(-257 -266)"
                >
                    <g transform="translate(257 266)">
                        <path d="M33 3.944c0 .256-.2.464-.446.464H12.041a.455.455 0 01-.446-.464c0-.256.2-.464.446-.464h20.513c.246 0 .446.208.446.464zM12.04 15.08h10.703a.455.455 0 00.446-.464c0-.256-.2-.464-.446-.464H12.041a.455.455 0 00-.446.464c0 .256.2.464.446.464zm14.27 9.744H12.04a.455.455 0 00-.445.464c0 .256.2.464.446.464h14.27a.455.455 0 00.446-.464c0-.256-.2-.464-.446-.464zM0 6.032v-4.64C0 .623.599 0 1.338 0h4.46c.738 0 1.337.623 1.337 1.392v4.64c0 .769-.599 1.392-1.338 1.392h-4.46C.6 7.424 0 6.801 0 6.032zm.892 0c0 .256.2.464.446.464h4.46a.455.455 0 00.445-.464v-4.64c0-.256-.2-.464-.446-.464h-4.46a.455.455 0 00-.445.464v4.64zm1.962-.863a.44.44 0 00.343.167.443.443 0 00.348-.209l1.485-2.32a.475.475 0 00-.146-.606.434.434 0 00-.594.096l-1.155 1.8-.361-.464a.435.435 0 00-.629-.06.478.478 0 00-.058.654l.767.942zm4.504 7.127v4.64c0 .769-.599 1.392-1.338 1.392H1.56c-.738 0-1.337-.623-1.337-1.392v-4.64c0-.769.599-1.392 1.338-1.392h4.46c.738 0 1.337.623 1.337 1.392zm-.892 0c0-.256-.2-.464-.446-.464H1.56a.455.455 0 00-.445.464v4.64c0 .256.2.464.446.464h4.46a.455.455 0 00.445-.464v-4.64zm-1.93.928l-1.156 1.8-.36-.464a.435.435 0 00-.63-.06.478.478 0 00-.058.654l.745.928a.44.44 0 00.343.167.443.443 0 00.348-.209l1.485-2.32a.474.474 0 00-.175-.535.432.432 0 00-.543.039zm2.822 9.744v4.64C7.358 28.377 6.76 29 6.02 29H1.56c-.738 0-1.337-.623-1.337-1.392v-4.64c0-.769.599-1.392 1.338-1.392h4.46c.738 0 1.337.623 1.337 1.392zm-.892 0c0-.256-.2-.464-.446-.464H1.56a.455.455 0 00-.445.464v4.64c0 .256.2.464.446.464h4.46a.455.455 0 00.445-.464v-4.64z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { trackResetColumnSettings } from "@src/analytics/advanced_filters";
import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import classnames from "classnames";
import omit from "lodash.omit";
import * as React from "react";
import {
    COLUMN_SETTINGS_PARAM,
    COMPRESSED_COLUMN_SETTINGS_PARAM,
} from "./constants";

// // // //

/**
 * ClearColumnSettingsButtonProps
 * @param props.route - RouteState from withRouter HOC
 * @param props.className - (optional) Additional className applied to the <LinkCC /> component
 */
interface ClearColumnSettingsButtonProps {
    route: RouteState;
    className?: string;
}

/**
 * ClearColumnSettingsButton
 * Used to clear the local ColumnSetting[] state in Route.params[COLUMN_SETTINGS_PARAM] and Route.params[COMPRESSED_COLUMN_SETTINGS_PARAM]
 * @param props - see ClearColumnSettingsButtonProps
 */
export function ClearColumnSettingsButtonLayout(
    props: ClearColumnSettingsButtonProps
) {
    const { route, className = "" } = props;

    // Return null IFF route.params[COLUMN_SETTINGS_PARAM] and route.params[COMPRESSED_COLUMN_SETTINGS_PARAM] are empty
    if (
        !route.params[COLUMN_SETTINGS_PARAM] &&
        !route.params[COMPRESSED_COLUMN_SETTINGS_PARAM]
    ) {
        return null;
    }

    // Constructs link to current page without `RouteState.params[COLUMN_SETTINGS_PARAM]` defined
    const updatedParams = omit(route.params, [
        COLUMN_SETTINGS_PARAM,
        COMPRESSED_COLUMN_SETTINGS_PARAM,
    ]);
    const resetColumnSettingsUrl = buildUrlWithParams({
        location: route.location,
        params: updatedParams,
    });

    // Renders styled button
    return (
        <button
            onClick={() => {
                trackResetColumnSettings();
                props.route.updateRouteAction(
                    resetColumnSettingsUrl,
                    props.route.nextPathname
                );
            }}
            className={classnames({
                [className]: className !== "",
            })}
        >
            <i className="fa fa-fw fa-undo mr-5-px" />
            Reset Table
        </button>
    );
}

export const ClearColumnSettingsButton = withRouter(
    ClearColumnSettingsButtonLayout
);

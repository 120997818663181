import classnames from "classnames";
import { ButtonProps } from "./component";

// // // //

// Props are defined as a subset of ButtonProps
type Props = Pick<
    ButtonProps,
    "variant" | "size" | "disabled" | "block" | "iconConfig"
>;

/**
 * getButtonClassName
 * Returns collection of classnames for a styled button
 * Abstracted from Button component so that buttons and non-button elements (e.g. links) can share the same styles
 */
export function getButtonClassName(props: Props): string {
    const {
        variant = "primary", // variant defaults to "primary"
        size = "md", // size defaults to medium
        disabled = false, // state defaults to enabled
        iconConfig = null,
        block = false, // display block defaults to false
    } = props;

    // Define classnames for variant "primary"
    const variantPrimaryClassNames = classnames(
        "!tw-text-white", // text (!important to override <a> element styles, which may be wrapped by these classes)
        "tw-border-solid tw-border-primary", // border
        "tw-bg-primary", // background
        {
            ["hover:tw-border-primary-light hover:!tw-text-white hover:tw-bg-primary-light"]: !disabled, // hover state
        }
    );

    // Define classnames for variant "primary-outline"
    const variantPrimaryOutlineClassNames = classnames(
        "!tw-text-primary", // text (!important to override <a> element styles, which may be wrapped by these classes)
        "tw-border-solid tw-border-primary", // border
        "tw-bg-transparent", // background
        {
            ["hover:tw-bg-primary hover:!tw-text-white"]: !disabled, // hover state
        }
    );

    // Define classnames for variant "secondary"
    const variantSecondaryClassNames = classnames(
        "!tw-text-gray-700", // text (!important to override <a> element styles, which may be wrapped by these classes)
        "tw-border-solid tw-border-gray-300", // border
        "tw-bg-white", // background
        {
            ["hover:!tw-text-gray-700 hover:tw-bg-gray-50"]: !disabled, // hover state
        }
    );

    const baseClassNames = classnames(
        "tw-text-center", // Text alignment
        "tw-font-semibold", // Font Weight
        "tw-tracking-wide", // Letter Spacing
        "hover:!tw-no-underline", // No underline (!important overrides link styling)
        "tw-rounded-xl", // Border radius
        "tw-border", // Border Style
        "tw-font-openSans", // Font Family
        "tw-transition-all", // Transition
        {
            // Size
            ["font-size-11-px tw-py-2 tw-px-6"]: size === "xs",
            ["font-size-13-px tw-py-3 tw-px-6"]: size === "sm",
            ["font-size-14-px tw-py-4 tw-px-8"]: size === "md",
            ["tw-text-2xl tw-py-6 tw-px-12"]: size === "lg",
            // full-width
            ["tw-w-full"]: block,
            // Box Shadow on hover
            ["hover:tw-shadow-md tw-cursor-pointer"]: !disabled,
            // Disabled
            ["tw-cursor-not-allowed tw-opacity-50"]: disabled,
        }
    );

    // With icon styles
    const iconClassNames = classnames({
        ["tw-flex tw-justify-center tw-items-center tw-gap-4"]: true,
        ["tw-flex-row-reverse"]: iconConfig?.position === "left",
    });

    // All button classes
    const buttonClassNames = classnames(
        // Default classes
        baseClassNames,
        {
            // Primary Variant
            [variantPrimaryClassNames]: variant === "primary",
            // Primary Outline Variant
            [variantPrimaryOutlineClassNames]: variant === "primary-outline",
            // Secondary Variant
            [variantSecondaryClassNames]: variant === "secondary",
            // With Icon
            [iconClassNames]: iconConfig !== null,
        }
    );

    return buttonClassNames;
}

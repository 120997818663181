import { Toaster } from "@src/shared_modules/toaster";
import * as React from "react";
import { connect } from "react-redux";
import { buildRemoveToasterAction } from "./actions";
import { GlobalToasterState } from "./reducer";

// // // //

export interface GlobalToasterProps {
    type: "primary" | "success" | "warning" | "danger";
    text: string;
    removable?: boolean;
    duration?: number;
}

// Dispatch props passed in from Redux connect(...)
interface DispatchProps {
    removeToaster: () => void;
}

// State props passed in from Redux connect(...)
interface StateProps {
    toaster: GlobalToasterProps | null;
}

/**
 * GlobalToasterLayout
 * Renders a <Toaster /> when REDUX STATE CHANGES
 * @param props.route - RouteState from withRouter HOC
 * @param props.children - child React.ReactNode passed into this component
 */
export function GlobalToasterLayout(props: StateProps & DispatchProps) {
    if (props.toaster === null) {
        return null;
    }

    // Defines default values for duration, removable
    const { type, text, duration = 5, removable = true } = props.toaster;

    return (
        <Toaster
            type={type}
            text={text}
            duration={duration}
            removable={removable}
            onRemove={() => {
                props.removeToaster();
            }}
        />
    );
}

// // // //

// Maps globalToaster state to props
function mapStateToProps({
    globalToaster,
}: {
    globalToaster: GlobalToasterState;
}): StateProps {
    return {
        toaster: globalToaster.toaster,
    };
}

// Maps removeToaster function to props
function mapDispatchToProps(dispatch): DispatchProps {
    return {
        removeToaster: () => {
            dispatch(buildRemoveToasterAction());
        },
    };
}

// Defines GlobalToasterLayout with Redux connect(...)
export const GlobalToaster = connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalToasterLayout);

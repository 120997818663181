import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { Query } from "react-apollo";
import { ApplicationUsageSummary } from "@src/schema/ApplicationUsageSummary";
import { ApplicationUsageSummaryList } from "@src/schema/ApplicationUsageSummaryList";
import { Pagination } from "@src/schema/Pagination";
import {
    APP_USAGE_SUMMARY_LIST_FETCHER_QUERY,
    AppUsageSummaryListFetcherResponse,
    AppUsageSummaryListFetcherVariables,
} from "./graphql";

// // // //

const EMPTY_LIST: ApplicationUsageSummaryList = {
    results: [],
    pagination: {
        pageCount: 0,
        rangeStart: 0,
        rangeEnd: 0,
        page: 1,
        totalItems: 0,
        itemsPerPage: 25,
        sort: "",
        direction: "",
        __typename: "Pagination",
    },
    __typename: "ApplicationUsageSummaryList",
};

interface Props {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        appUsageSummaries: ApplicationUsageSummary[];
    }) => React.ReactNode;
}

/**
 * @name AppUsageSummaryListFetcher
 * @description Fetches the app usage summary data via GQL using AdvancedFilters
 * @param {Props} props
 */
export function AppUsageSummaryListFetcher(props: Props) {
    return (
        <Query<
            AppUsageSummaryListFetcherResponse,
            AppUsageSummaryListFetcherVariables
        >
            query={APP_USAGE_SUMMARY_LIST_FETCHER_QUERY}
            variables={{
                filters: props.filtersInput,
            }}
            fetchPolicy="cache-and-network" // Ensures Apollo cache updates
        >
            {({ loading, error, data }) => {
                // Safely pulls ApplicationUsageSummaryList from data + assigns default value
                const list: ApplicationUsageSummaryList =
                    data?.usage?.applicationUsageSummaries?.list || EMPTY_LIST;

                // Safely pulls ApplicationUsageSummary[] + Pagination from ApplicationUsageSummaryList
                const appUsageSummaries: ApplicationUsageSummary[] =
                    list?.results || [];
                const pagination: Pagination =
                    list?.pagination || EMPTY_LIST.pagination;

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            appUsageSummaries,
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

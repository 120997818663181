import {
    spendAppName,
    SpendListMetadata,
    SpendListResult,
} from "@src/requests/applications";
import { genListing, ListState, ResultsAction } from "@src/shared_modules/list";
import { formatMoment, genMoment } from "@src/util/date_util";
import get from "lodash.get";
import moment from "moment";

export type SpendInfo = {
    states: string[];
} & SpendListResult;

export interface SpendListSupplement {
    metadata: SpendListMetadata;
}

export type SpendListState = ListState<SpendInfo> & SpendListSupplement;

// results process results from the api by converting time values to formatted moment values
export function results(
    state: SpendListState,
    action: ResultsAction
): SpendListState {
    const res = action.results;
    // Safely pull results from res
    const actionResults = get(res, "results", []);
    // Safely pull states from res
    const states = get(res, "filters.states", []);
    const apps: SpendInfo[] = actionResults.map((app: SpendListResult) => ({
        ...app,
        renewalDate:
            app.renewalDate != null
                ? moment.utc(app.renewalDate).format("MM/DD/YY")
                : null,
        spend: {
            ...app.spend,
            lastPaymentDate: formatMoment(genMoment(app.spend.lastPaymentDate)),
        },
        states: states || [],
    }));
    return {
        ...state,
        ...action.results,
        loading: false,
        results: apps,
    };
}

export const modelType = "spendListing";
export const spendListing = genListing<SpendListSupplement, SpendInfo>(
    modelType,
    {
        metadata: {
            totalSpend: "$0",
            convertedSpend: null,
            appsWithSpend: 0,
            appsWithNoSpend: 0,
        },
    },
    spendAppName,
    results
);

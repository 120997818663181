import "@src/config"; // NOTE - we import @src/config here to ensure that `setConfig` is invoked before the initStore function
import { featureContent } from "@src/shared_modules/feature_content";
import { globalToaster } from "@src/shared_modules/global_toaster";
import { modal } from "@src/shared_modules/modal";
import { tooltips } from "@src/shared_modules/tooltips";
import { user } from "@src/shared_modules/user/reducer";
import { integrationSuccess } from "@src/modules/integrations/pages/individual_auth_success";
import { spendListing } from "@src/modules/payments/pages/spend_listing";
import { vendorBulkEdit } from "@src/modules/vendors/pages/vendor_listing_edit";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

// // // //

// initStore initializes the redux store for the app
export function initStore(initialState: any = {}) {
    const reducer = combineReducers({
        spendListing,
        modal,
        user,
        integrationSuccess,
        vendorBulkEdit,
        tooltips,
        globalToaster,
        featureContent,
    });

    // depending on where we're initializing the app we may want to pass in the initial state
    let store = null;
    if (initialState != null) {
        store = createStore(reducer, initialState, applyMiddleware(thunk));
    } else {
        store = createStore(reducer, applyMiddleware(thunk));
    }

    // Returns the Redux store
    return store;
}

import { OrganizationField } from "@src/modules/org_fields/components/org_fields";
import { AppField } from "../../vendor_listing_v1/types";

// // // //

// Redux Action Types
export const SET_BULK_DATA_ITEM = "SET_BULK_DATA_ITEM";
export const UNSET_BULK_DATA_ITEM = "UNSET_BULK_DATA_ITEM";
export const DROP_BULK_DATA = "DROP_BULK_DATA";

// // // //

interface AddItemPayload {
    appField: AppField;
    orgField: OrganizationField;
    data: string[];
}

interface RemoveItemPayload {
    appField: AppField;
}

export interface Action {
    type:
        | typeof SET_BULK_DATA_ITEM
        | typeof UNSET_BULK_DATA_ITEM
        | typeof DROP_BULK_DATA;
    addPayload: AddItemPayload;
    removePayload: RemoveItemPayload;
}

// // // //
// Action creator functions

// addItem
// Add an edited item to state
export function addItem(item: AddItemPayload) {
    return {
        type: SET_BULK_DATA_ITEM,
        addPayload: item,
    };
}

// removeItem
// Remove an item's changes from state
export function removeItem(item: RemoveItemPayload) {
    return {
        type: UNSET_BULK_DATA_ITEM,
        removePayload: item,
    };
}

// dropChangesAction
// Drops all changes in state
export function dropChangesAction() {
    return {
        type: DROP_BULK_DATA,
    };
}

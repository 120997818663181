import classnames from "classnames";
import * as React from "react";
import { InputSize } from "../input";
import styles from "./styles.module.css";

// // // //

/**
 * Props for the component
 */
interface Props {
    /**
     * The HTML ID of the checkbox `<input />`
     */
    id: string;
    /**
     * Sets the `name` property on the checkbox `<input />`
     */
    name: string;
    /**
     * Value in the checkbox
     */
    checked: boolean;
    /**
     * callback when Toggle changes
     */
    onChange: (toggle: boolean) => void;
    /**
     * enables click events bubbling, which is disabled by default
     */
    allowPropagation?: boolean;
    /**
     * determines the size of the Toggle
     */
    size?: InputSize;
}

/**
 * Renders a Toggle Checkbox
 */
export function Toggle(props: Props) {
    const { id: htmlFor, name, checked, onChange, size = InputSize.md } = props;
    return (
        <div className={styles.toggleWrapper}>
            <input
                className={styles.toggleInput}
                type="checkbox"
                name={name}
                id={htmlFor}
                checked={checked}
                onChange={(e) => {
                    e.stopPropagation();
                    onChange(e.currentTarget.checked);
                }}
            />
            <label
                htmlFor={htmlFor}
                className={classnames(styles.toggle, {
                    [styles.sm]: size === InputSize.sm,
                })}
            />
        </div>
    );
}

/**
 * Renders a red Toggle Checkbox
 */
export function WarningToggle(props: Props) {
    return (
        <div className={styles.redToggle}>
            <Toggle {...props} />
        </div>
    );
}

/**
 * Renders a Checkbox with custom on/off labels
 */
export function LabeledToggle(
    props: Props & {
        values: [string, string];
    }
) {
    return (
        <div className="d-flex align-items-center">
            <p className={styles.toggleText}>{props.values[0]}</p>
            <div className={styles.labeledToggleWrapper}>
                <Toggle {...props} />
            </div>
            <p className={styles.toggleText}>{props.values[1]}</p>
        </div>
    );
}

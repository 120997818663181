import { routes, webhookIndividual } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const webhookIndividualState = (route: RouteState) => {
    const { webhookID } = route.pathParams;

    return {
        linkProps: {
            href: {
                pathname: routes.settings.webhookIndividual,
                query: { webhookID: String(webhookID) },
            },
            as: webhookIndividual(webhookID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "webhookIndividual",
                webhookID,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.settings.webhookIndividual,
        resolverProps: {
            type: DisplayResolverTypes.webhook,
            params: { webhookID },
        },
    };
};

import { Report } from "@src/schema/Report";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import React from "react";
import {
    EXCLUDE_ARCHIVED_FILTER,
    ID_FIELD_NAME,
} from "@src/modules/terminated_user_report/components/report/advanced_filters/constants";
import { DEFAULT_PAGINATION } from "../../modules/advanced_filters/components/advanced_filters/constants";
import { ReportListFetcher } from "../../modules/terminated_user_report/components/report/list_fetcher";
import { ResolverProps } from "./component";

// // // //

// Takes a reportID and resolves a report to props.children or–if !props.children - resolves to the report name
export function ReportResolverLayout(
    props: ResolverProps<Report> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const reportID: string | undefined = props.params?.reportID;

    if (reportID === undefined) {
        captureMessage("ReportResolver - params.reportID is undefined");
        return null;
    }

    const { organizationID } = props.user;

    // Define filters for ReportListFetcher
    const filtersInput: FiltersInput = buildFiltersInput({
        reportID,
        organizationID,
    });

    return (
        <ReportListFetcher filtersInput={filtersInput}>
            {({ loading, reportList }) => {
                const report: Report | undefined = reportList[0];

                if (props.children) {
                    return props.children({ value: report, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (report === undefined) {
                    captureMessage(`Report - ${reportID} not found`);
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return <React.Fragment>{report.name}</React.Fragment>;
            }}
        </ReportListFetcher>
    );
}

export const ReportResolver: React.ComponentType<ResolverProps<
    Report
>> = withUser(ReportResolverLayout);

/**
 * Function that builds the FiltersInput for the ReportListFetcher
 */
export function buildFiltersInput(props: {
    reportID: string;
    organizationID: number;
}): FiltersInput {
    const { reportID, organizationID } = props;
    return {
        organizationID,
        defaults: [
            {
                fieldName: ID_FIELD_NAME,
                val: reportID,
                expression: FilterExpression.eq,
                filters: [],
            },
            EXCLUDE_ARCHIVED_FILTER,
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };
}

import { trackAdvancedFiltersOpenOverlay } from "@src/analytics/advanced_filters";
import {
    FilterExpression,
    Filters,
    FiltersInput,
    FilterVal,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { RouteState } from "@src/shared_modules/router";
import { buildQsFromObject } from "@src/util/route";
import omit from "lodash.omit";
import React from "react";
import { AdvancedFiltersEditorOverlay } from "./AdvancedFiltersEditorOverlay";
import { SHOW_OVERLAY_PARAM } from "./constants";
import { DefaultFilters } from "./DefaultFilters";
import { SuggestedFilter } from "./suggested_filters/graphql";

// // // //

/**
 * AdvancedFiltersEditorOverlayProps
 * @param filters - The `defaults` and `advanced` filters used to populate the AdvancedFilterEditor
 * @param route - The `RouteState` from `withRouter` HOC
 * @param defaultPossibleFilters - Array of `PossibleFilter` instances from the `defaults` property on `Filters`. Passed into `DefaultFilters`
 * @param filtersInput - The user's filter state
 * @param setFiltersInput - Updates the entire `props.filtersInput` object
 * @param setDefaultFilterValue - Sets the value for a single Filter in `props.filtersInput.defaults`
 */
interface AdvancedFiltersProps {
    filters: Filters;
    route: RouteState;
    defaultPossibleFilters: PossibleFilter[];
    filtersInput: FiltersInput;
    suggestedFilters: SuggestedFilter[];
    setFiltersInput: (updatedFiltersInput: FiltersInput) => void;
    enableFiltersOverlay?: boolean;
    setDefaultFilterValue: (params: {
        possibleFilter: PossibleFilter;
        val: FilterVal;
        expression: FilterExpression;
    }) => void;
}

/**
 * AdvancedFilters
 * Renders the layout for AdvancedFilters UI used on the listing and individual pages
 * @param props - see AdvancedFiltersProps
 */
export function AdvancedFilters(props: AdvancedFiltersProps) {
    const { route, enableFiltersOverlay = true } = props;

    // Return null if `RouteState.params[SHOW_OVERLAY_PARAM] is falsey
    const showingOverlay: boolean =
        route.params[SHOW_OVERLAY_PARAM] !== undefined;

    // Constructs link to current page WITH `RouteState.params[SHOW_OVERLAY_PARAM]`
    const showOverlayRoute: string = `${route.location}${buildQsFromObject({
        ...route.params,
        [SHOW_OVERLAY_PARAM]: true,
    })}`;

    // Constructs link to current page WITHOUT `RouteState.params[SHOW_OVERLAY_PARAM]`
    const updatedParams = omit(route.params, [SHOW_OVERLAY_PARAM]);
    const defaultRoute: string = `${route.location}${buildQsFromObject(
        updatedParams
    )}`;

    if (showingOverlay && enableFiltersOverlay) {
        return (
            <AdvancedFiltersEditorOverlay
                filters={props.filters}
                filtersInput={props.filtersInput}
                suggestedFilters={props.suggestedFilters}
                onSave={(updatedFiltersInput: FiltersInput) => {
                    // Sets the FiltersInput state in the URL with the state from the AdvancedFiltersEditorOverlay + hides the overlay
                    props.setFiltersInput(updatedFiltersInput);
                }}
                onCancel={() => {
                    // Hides the overlay
                    route.updateRouteAction(defaultRoute, route.nextPathname);
                }}
            />
        );
    }

    return (
        <div className="row d-flex align-items-center flex-nowrap justify-content-between flex-grow-1">
            <div className="d-flex flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                    <DefaultFilters
                        filtersInput={props.filtersInput}
                        defaultPossibleFilters={props.defaultPossibleFilters}
                        setDefaultFilterValue={({
                            possibleFilter,
                            val,
                            expression,
                        }) => {
                            props.setDefaultFilterValue({
                                possibleFilter,
                                val,
                                expression,
                            });
                        }}
                    />
                </div>
                {/* TODO - hide this conditionally */}
                {enableFiltersOverlay && (
                    <div className="tw-flex tw-justify-end tw-items-center">
                        <button
                            className="btn btn-sm btn-primary whitespace-nowrap font-secondary-bold"
                            onClick={() => {
                                trackAdvancedFiltersOpenOverlay();
                                // Opens the overlay
                                route.updateRouteAction(
                                    showOverlayRoute,
                                    route.nextPathname
                                );
                            }}
                        >
                            Advanced Filters
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

import { BreadcrumbNode } from "../types";
import { appFieldIndividualNode } from "../appFieldIndividual";
import { usageState } from "../usage";
import { usageIndividualApplicationsState } from "./state";

// // // //
export const usageIndividualNode: BreadcrumbNode = {
    getState: usageState,
    parent: appFieldIndividualNode,
};

export const usageIndividualApplicationsNode: BreadcrumbNode = {
    getState: usageIndividualApplicationsState,
    parent: usageIndividualNode,
};

import React from "react";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListEmptyState
 * Renders an empty state when there are no filters defined in `defaults` or `additional`
 */
export function FilterListEmptyState() {
    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                <div
                    className={`text-center mt-20-px mb-10-px px-30-px py-50-px border-grey-dim rounded ${styles.filterListEmptyState}`}
                >
                    <h2 className="text-grey-dim font-primary py-20-px">
                        <FeatureContent uniqueKey="filter-list-empty-state">
                            {({ feature, loading }) => {
                                if (loading) {
                                    return null;
                                }

                                return feature.header;
                            }}
                        </FeatureContent>
                    </h2>
                </div>
            </div>
        </div>
    );
}

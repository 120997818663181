import { captureMessage } from "@src/util/analytics";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Feature } from "../../requests/content";
import { fetchFeature } from "./actions";
import { FeatureContentState } from "./reducer";

/**
 * OwnProps
 * Represents the own props of the `FetchFeatureContent` component
 * @param uniqueKey - The value of the `uniqueKey` field in contentful that is used for fetching the feature
 * @param children - A function that accepts a `Feature` and returns a React.ReactNode
 * @param fallbackFeature - (optional) Fallback feature that is passed to children when the feature with the given `uniqueKey` doesn't exist its shape is different
 */
interface OwnProps {
    uniqueKey: string;
    children: (props: {
        feature: Feature;
        loading: boolean;
    }) => React.ReactNode;
    fallbackFeature?: Feature;
}

/**
 * StateProps
 * Represents the props that are injected into the `FetchFeatureContent` component by redux connect() function
 */
interface StateProps {
    loaded: boolean;
    feature: Feature | null | undefined;
}

/**
 * DispatchProps
 * Represents the props that are injected into the `FetchFeatureContent` component by redux connect() function
 */
interface DispatchProps {
    fetchFeature: (key: string) => void;
}

export function FetchFeatureContent(
    props: OwnProps & StateProps & DispatchProps
) {
    const { loaded, children, uniqueKey, fallbackFeature } = props;
    let { feature } = props;
    // Dispatches the fetchFeature() action only on first render
    // useEffect() with an empty array as its second argument replaces componentDidMount() from class components
    React.useEffect(() => {
        // Don't fetch the feature if already started loading it
        if (loaded) {
            return;
        }
        props.fetchFeature(uniqueKey);
    }, []);

    // feature is undefined until it has finished loading
    const loading = feature === undefined;

    // the feature was not found in contentful
    if (feature === null) {
        // if there is no fallback feature, return null
        if (fallbackFeature === undefined) {
            return null;
        }

        // if the fallback feature is defined, pass it to children()
        feature = fallbackFeature;
    }

    // the feature was found in contentful but its shape is different from fallbackFeature
    if (
        !loading &&
        feature !== null &&
        fallbackFeature !== undefined &&
        feature.features.length !== fallbackFeature.features.length
    ) {
        captureMessage(
            "FeatureContent error - Contentful response does not match shape of props.fallbackFeature",
            { extra: { uniqueKey: props.uniqueKey } }
        );

        // pass the fallback feature to children()
        feature = fallbackFeature;
    }

    // Renders props.children with feature and loading flag
    return <React.Fragment>{children({ feature, loading })}</React.Fragment>;
}

/**
 * mapStateToProps
 * Checks the Redux `feature content` store to see if the features associated with props.uniqueKey were loaded
 * and provides the `loaded` property to the FetchFeatureContent component
 */
function mapStateToProps(
    { featureContent }: { featureContent: FeatureContentState },
    ownProps: OwnProps
): StateProps {
    const { uniqueKey } = ownProps;
    const loaded = featureContent.loadedFeatureKeys.has(uniqueKey);
    const feature = featureContent.features[uniqueKey];
    return {
        feature,
        loaded,
    };
}

/**
 * mapDispatchToProps
 * Provides the `fetchFeature` function to the FetchFeatureContent component
 */
function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators(
        {
            fetchFeature,
        },
        dispatch
    );
}

export const FeatureContent: React.FunctionComponent<OwnProps> = connect(
    mapStateToProps,
    mapDispatchToProps
)(FetchFeatureContent);

import { Pagination } from "@src/schema/Pagination";
import { Report } from "@src/schema/Report";
import { ReportList } from "@src/schema/ReportList";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { WatchQueryFetchPolicy } from "apollo-boost";
import * as React from "react";
import { Query } from "react-apollo";
import {
    ReportListFetcherResponse,
    ReportListFetcherVariables,
    REPORT_LIST_FETCHER_QUERY,
} from "./graphql";

// // // //

const EMPTY_LIST: ReportList = {
    results: [],
    pagination: {
        pageCount: 0,
        rangeStart: 0,
        rangeEnd: 0,
        page: 1,
        totalItems: 0,
        itemsPerPage: 25,
        sort: "",
        direction: "",
        __typename: "Pagination",
    },
    __typename: "ReportList",
};

interface Props {
    filtersInput: FiltersInput;
    fetchPolicy?: WatchQueryFetchPolicy;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        reportList: Report[];
        refetchReportList: () => void;
    }) => React.ReactNode;
}

/**
 * @name ReportListFetcher
 * @description Fetches ReportList via GQL using AdvancedFilters
 * @param {Props} props
 */
export function ReportListFetcher(props: Props) {
    const { fetchPolicy = "cache-and-network" } = props;

    // IMPORTANT - we include `cache-and-network` fetchPolicy here to ensure that the Apollo Client
    // knows to render the updated data after we invoke `refetch`. This is necessary to handle a known bug in the Apollo Client
    // where a query dispatched without any changes in variables data will cause the loading state to hang if the fetchPolicy is `cache-only`.
    return (
        <Query<ReportListFetcherResponse, ReportListFetcherVariables>
            query={REPORT_LIST_FETCHER_QUERY}
            fetchPolicy={fetchPolicy}
            variables={{
                filters: props.filtersInput,
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls ReportList from data + assigns default value
                const list: ReportList =
                    data?.usage?.reports?.list || EMPTY_LIST;

                // Safely pulls Report[] + Pagination from ReportList
                const reportList: Report[] = list?.results || [];
                const pagination: Pagination =
                    list?.pagination || EMPTY_LIST.pagination;

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            reportList,
                            refetchReportList: () => {
                                refetch();
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

import { Feature, loadFeature } from "@src/requests/content";
import { Dispatch } from "redux";

// define action types associated with the `feature content` reducer
export enum FeatureContentActionTypes {
    LOADING_ACTION = "__feature_content_loading",
    RESULTS_ACTION = "__feature_content_results",
}

/**
 * fetchFeature
 * Action creator function that dispatches a LOADING_ACTION, fetches a feature,
 * and finally dispatches the RESULTS_ACTION
 * @param key - the unique key of the feature
 */
export function fetchFeature(key: string) {
    return async (dispatch: Dispatch) => {
        dispatch(loadingAction(key));
        const feature: Feature = await loadFeature(key);
        dispatch(resultsAction(key, feature));
    };
}

/**
 * ResultsAction interface definition
 * Used for RESULTS_ACTION actions
 */
export interface ResultsAction {
    key: string;
    result: Feature;
    type: FeatureContentActionTypes.RESULTS_ACTION;
}

/**
 * LoadingAction interface definition
 * Used for LOADING_ACTION actions
 */
export interface LoadingAction {
    key: string;
    type: FeatureContentActionTypes.LOADING_ACTION;
}

/**
 * FeatureContentAction
 * A union type of the `ResultsAction` & `LoadingAction` action types
 */
export type FeatureContentAction = ResultsAction | LoadingAction;

/**
 * resultsAction
 * Action creator function for ResultsAction
 * @param key - the unique key of the feature
 * @param result - the feature received from the api
 */
export function resultsAction(key: string, result: Feature): ResultsAction {
    return {
        type: FeatureContentActionTypes.RESULTS_ACTION,
        key,
        result,
    };
}

/**
 * loadingAction
 * Action creator function for LoadingAction
 * @param key - the unique key of the feature
 */
export function loadingAction(key: string): LoadingAction {
    return {
        type: FeatureContentActionTypes.LOADING_ACTION,
        key,
    };
}

import { BreadcrumbNode } from "../types";
import { connectedAppsOverviewState, connectedAppsListingState } from "./state";

// // // //

// "Secure" breadcrumb text
export const secureNode: BreadcrumbNode = {
    getState: connectedAppsOverviewState,
    parent: null,
};

// routes.connectedAppsListing
export const connectedAppsListingNode: BreadcrumbNode = {
    getState: connectedAppsListingState,
    parent: secureNode,
};

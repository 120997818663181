import ImageLoader from "@src/shared_modules/image_loader";
import { LinkCC } from "@src/shared_modules/router";
import classnames from "classnames";
import * as React from "react";

import { getIndividualPageLinkProps } from "./getIndividualPageLinkProps";
import { Vendor } from "./graphql";
import { RelatedVendors } from "./RelatedVendors";
import styles from "./styles.module.css";
import { IndividualPageType } from "./types";

// // // //

/**
 * RelatedVendorsListItem
 * Renders a single item in RelatedVendorsList
 * @param props.vendor - the Vendor for the list item
 * @param props.isParent - a boolean dictating whether or not props.vendor is the parent vendor
 */
function RelatedVendorsListItem(props: {
    vendor: Vendor;
    isParent: boolean;
    individualPage: IndividualPageType;
}) {
    const { vendor, individualPage, isParent } = props;

    const linkCCProps = getIndividualPageLinkProps({
        vendorID: vendor.id,
        individualPage,
    });

    return (
        <LinkCC
            className={classnames(
                "d-flex flex-row align-items-center py-5-px px-10-px",
                styles.relatedVendorsListItem
            )}
            as={linkCCProps.as}
            href={{
                pathname: linkCCProps.pathname,
                query: { vendorID: String(vendor.id) },
            }}
        >
            <ImageLoader
                src={vendor.logo}
                alt={`${vendor.name} Logo`}
                className="tw-max-h-12 tw-rounded-md"
            />
            <p className="ml-15-px text-updated-black font-secondary-bold font-size-14-px">
                {vendor.name}
            </p>
            {isParent && (
                <div className="font-size-11-px ml-15-px bg-background-main text-updated-black font-secondary rounded px-5-px py-5-px">
                    Parent
                </div>
            )}
        </LinkCC>
    );
}

/**
 * RelatedVendorsListProps
 * @param props.vendor - the Vendor whose related vendors are being rendered
 * @param props.children - a function invoked once-per-vendor (including parent, current, siblings, children)
 * @param props.onClose - a that should close the RelatedVendorsModal when clicked (this is necessary because a route change won't always force the modal to close)
 */
interface RelatedVendorsListProps {
    vendor: Vendor;
    individualPage: IndividualPageType;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * RelatedVendorsList
 * Renders a list of related vendors
 * @param props - see RelatedVendorsListProps
 */
export function RelatedVendorsList(props: RelatedVendorsListProps) {
    const { vendor, individualPage } = props;

    // Renders list of related vendors
    return (
        <div className="d-flex flex-column w-100">
            <div
                className={classnames(
                    "d-flex flex-row align-items-center py-10-px px-10-px font-size-11-px font-secondary text-updated-black",
                    styles.relatedVendorsListHeader
                )}
            >
                Vendor
            </div>
            <div role="button" onClick={props.onClose}>
                <RelatedVendors vendor={vendor}>
                    {(childProps) => (
                        <RelatedVendorsListItem
                            vendor={childProps.vendor}
                            isParent={childProps.isParent}
                            individualPage={individualPage}
                        />
                    )}
                </RelatedVendors>
            </div>
        </div>
    );
}

import classnames from "classnames";
import * as React from "react";
import { InputSize } from "./types";
import { useDebouncedInput } from "./useDebouncedInputHook";

// // // //

/**
 * NumberInputProps
 * `value` - the numerical value modeled by the `<input />` element. Also accepts a null value when the input should be empty
 * `min` - the minimum value accepted by `<input type="number" />`
 * `max` - the maximum value accepted by `<input type="number" />`
 * `step` - the numeric step value when imcrementing/decrementing with arrow keys
 * `integersOnly` - boolean flag to only allow integers
 * `placeholder` - (optional) value for by the `<input />`'s placeholder attribute
 * `className` - (optional) additional className to attach to the `<input />` element
 * `disabled` - (options) dictates whether or not the `<input />` element is disabled
 * `size` - (optional) prop to dictate the sizing CSS of the `<input />` element. Defaults to `InputSize.md`
 * `debounceTimeoutMs` - (optional) dictates the amount of time (in ms) to debounce user input.
 * `onChange` - function invoked when a change event is detected on the `<input />` element. Accepts `number` parameter values only.
 * `onBlur` - (optional) callback fired on `blur` event
 * `onKeyDown` - (optional) callback fired on `keyDown` event
 */
interface NumberInputProps {
    value: number | null;
    min?: number;
    max?: number;
    step?: number;
    integersOnly?: boolean;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    size?: InputSize;
    debounceTimeoutMs?: number;
    onChange: (updatedVal: number | null) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * NumberInput
 * Renders an <input /> for editing numeric values
 * NOTE - mix/max properties derived from Postgres INTEGER type
 * (4 bytes -> -2,147,483,648 / +2,147,483,647)
 * @param props - see `NumberInputProps`
 */
export function NumberInput(props: NumberInputProps) {
    // Track the current inputValue internally to this component
    const [inputValue, setInputValue] = React.useState<number | null>(
        props.value
    );

    // Re-sets the internally used `inputValue` in the event that `props.value` changes
    // This prevents an occurance where `inputValue` is not updated to the latest value passed as `props.value`
    // when this input's value is being controlled by its parent component
    React.useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    // Defines a function to invoke props.onChange with the useDebouncedInput hook
    const setValueFunction = useDebouncedInput<number | null>({
        debounceTimeoutMs: props.debounceTimeoutMs,
        onChange: props.onChange,
    });

    // Pulls `size` prop, defaults to `md`
    const {
        size = InputSize.md,
        className = "",
        value,
        min,
        max,
        step,
        integersOnly = false,
    } = props;

    // If the value is null, replace it with an empty string and pass to the input element
    let inputElementValue: number | string = inputValue;
    if (value === null) {
        inputElementValue = "";
    }

    return (
        <input
            type="number"
            min={min !== undefined ? String(min) : "-2147483648"}
            max={max !== undefined ? String(max) : "2147483647"}
            step={step ? String(step) : undefined}
            placeholder={props.placeholder}
            value={inputElementValue}
            disabled={props.disabled}
            className={classnames({
                ["form-input"]: true,
                ["font-secondary"]: true,
                ["form-input-sm"]: size === InputSize.sm,
                ["form-input-md"]: size === InputSize.md,
                ["form-input-lg"]: size === InputSize.lg,
                [className]: className !== "",
            })}
            onChange={(e) => {
                // Pulls the stringified value from e.currentTarget.value
                const rawValue: string = e.currentTarget.value;

                // Set value as `null` if the rawValue is an empty string
                // NOTE - this is done  because Number("") is zero
                // Without this check, the user would not be able to clear a number input
                if (rawValue === "") {
                    setInputValue(null);
                    setValueFunction(null);
                    return;
                }

                // Cases rawValue as a number
                let updatedValue: number = Number(rawValue);

                // Coerce to integer using Math.floor IFF props integersOnly
                if (integersOnly) {
                    updatedValue = Math.floor(updatedValue);
                }

                // Update value
                setInputValue(updatedValue);
                setValueFunction(updatedValue);
            }}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
        />
    );
}

import { BreadcrumbNode } from "../types";
import { vendorUsageAppIndState } from "./state";
import { vendorUsageAppListingNode } from "../vendorUsageAppListing";

// // // //

export const vendorUsageAppIndNode: BreadcrumbNode = {
    getState: vendorUsageAppIndState,
    parent: vendorUsageAppListingNode,
};

import * as React from "react";
import Transition from "react-transition-group/Transition";
import { Alert, AlertProps } from "@src/shared_modules/alert";
import styles from "./styles.module.css";

// // // //

const animationDuration = 500;

// NOTE: this toaster implementation was heavily inspired by: https://evergreen.surge.sh/components/toaster

// ToasterProps represents the props that should be passed to the IntegrationTile
export interface ToasterProps extends AlertProps {
    duration?: number;
}

// Toaster is used to show toaster (alerts) on the bottom right of the screen.
// The toasts will close themselves when the close button is clicked, or after a timeout — the default is 5 seconds.
export class Toaster extends React.Component<
    ToasterProps,
    { isShown: boolean }
> {
    public timeoutID: ReturnType<typeof setTimeout>;

    constructor(props: ToasterProps) {
        super(props);

        // the timeout id is the timeout id of the component
        this.timeoutID = null;
        this.state = {
            isShown: true,
        };
        // we bind these functions so we can pass them directions
        this.close = this.close.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    // when the component mounts we set a timer so that we can set the transition to not be present after a certain number of seconds
    public componentDidMount() {
        this.startCloseTimer();
    }

    // if the text of the component changes we want to clear the component and then restart the timer so that it can continue showing on screen
    public componentDidUpdate(prevProps: ToasterProps) {
        if (this.props.text !== prevProps.text) {
            this.clearCloseTimer();
            this.startCloseTimer();
        }
    }

    // when the component unmounts we need to clear the time
    public componentWillUnmount() {
        this.clearCloseTimer();
    }

    // when the user enters the toaster we clear the timer so they can read
    public handleMouseEnter() {
        this.clearCloseTimer();
    }

    // when the user leaves the toaster we restart the timer
    public handleMouseLeave() {
        this.startCloseTimer();
    }

    public render() {
        return (
            <Transition
                appear
                unmountOnExit
                timeout={animationDuration}
                in={this.state.isShown}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onExited={() => {
                    // we need to wait till the transition has completed before we can remove it
                    if (this.props.onRemove != null) {
                        this.props.onRemove();
                    }
                }}
            >
                {(state) => (
                    <div data-state={state} className={styles.toaster}>
                        <Alert
                            text={this.props.text}
                            removable={this.props.removable}
                            type={this.props.type}
                            onRemove={this.close}
                            className="tw-shadow-xl"
                        />
                    </div>
                )}
            </Transition>
        );
    }

    private clearCloseTimer() {
        // we need this for the flow check
        if (this.timeoutID == null) {
            return;
        }
        clearTimeout(this.timeoutID);
        this.timeoutID = null;
    }

    private startCloseTimer() {
        const duration = this.props.duration != null ? this.props.duration : 5;
        this.timeoutID = setTimeout(() => {
            this.close();
        }, duration * 1000);
    }

    // close removes the toaster from the screen
    private close() {
        if (this.timeoutID == null) {
            return;
        }

        clearTimeout(this.timeoutID);
        this.setState({
            isShown: false,
        });
    }
}

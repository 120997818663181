import {
    trackBaseLinkClicked,
    trackLinkClicked,
} from "@src/analytics/trackLinkClicked";
import * as React from "react";
import { LinkProps } from "./types";

// // // //

function trackLinkClick(props: LinkProps) {
    if (
        "eventName" in props &&
        props.eventName != null &&
        props.eventName.length > 0
    ) {
        trackLinkClicked(
            props.eventName,
            props.href,
            props.componentName,
            props.eventProps
        );
    }
    trackBaseLinkClicked(props.href, props.componentName);
}

// Flag indicating whether or not this component is being rendered on the server
const isServer = typeof window === "undefined";

interface AnchorProps {
    href: string;
    children?: React.ReactNode;
    className?: string;
    target?: string;
}

interface EventProps {
    eventName?: string;
    eventProps?: { [key: string]: any };
}

const nonAnchorProps: Set<string> = new Set(["eventName", "eventProps"]);

/**
 * Anchor
 * Component used when linking to external links
 * @param props - @see AnchorProps @see EventProps
 */
export function Anchor(props: AnchorProps & EventProps) {
    // Removes props that can't be passed to Anchor <a>
    const anchorProps: AnchorProps = Object.keys(props).reduce(
        (updatedProps: AnchorProps, prop: string) => {
            if (!nonAnchorProps.has(prop)) {
                updatedProps[prop] = props[prop];
            }
            return updatedProps;
        },
        { href: props.href }
    );

    return (
        <a
            {...anchorProps}
            onClick={(e) => {
                // Track link-click event
                trackLinkClick(props);

                // If props.target === "_blank" -> manually open link in new tab
                // NOTE - this is necessary due to how Next.js overrides default <a> behavior
                if (!isServer && props.target && props.target === "_blank") {
                    // Prevent default open-in-new-tab behavior
                    // NOTE - this is done so the browser doesn't open up the page TWICE
                    e.preventDefault();

                    // Opens props.href with target="_blank" and rel="noreferrer"
                    // Doc: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Window_features
                    window.open(props.href, "_blank", "noreferrer");
                }
            }}
            role="link"
            tabIndex={0}
        >
            {props.children}
        </a>
    );
}

import classnames from "classnames";
import * as React from "react";

// // // //

interface HeaderItemProps {
    // type is the type of header item to display - all this does is change the color of the number.
    // success - green, primary - navy primary, info - core blue, warning - orange, danger - red, dark - updated black. when no type is passed, the color is black
    type?: "success" | "primary" | "info" | "warning" | "danger" | "dark";
    // number is the number to display
    number: string | number;
    // description is the description under the number
    description?: string;
    // noLeftBorder should be passed if we need to hide the left border and remove left padding
    noLeftBorder?: boolean;
    // noRightBorder should be passed if we need to hide the right border and remove right padding
    noRightBorder?: boolean;
    // transparent should be set to true if we want the number to be semi-transparent
    transparent?: boolean;
    // bold should be passed if the number should be bold
    bold?: boolean;
    // className is a custom class name that can be passed
    className?: string;
}

/**
 * HeaderItem
 * Displays a single value inside the DashboardListHeader
 * includes value, description, and border behavior
 */
export function HeaderItem(props: HeaderItemProps) {
    const {
        bold,
        type,
        noLeftBorder,
        noRightBorder,
        transparent,
        number,
        description,
    } = props;
    const className = classnames(
        "tw-flex tw-flex-col tw-items-center tw-justify-center",
        "border-t-none border-b-none tw-border-solid tw-border tw-border-gray-border py-10-px px-20-px",
        {
            "font-secondary": !bold,
            "font-secondary-bold": bold,
            "text-success": type === "success",
            "text-primary": type === "primary",
            "text-tertiary": type === "info",
            "text-secondary": type === "warning",
            "text-alert": type === "danger",
            "text-black": type === "dark",
            "border-l-none": noLeftBorder,
            "border-r-none": noRightBorder,
            [props.className]: props.className,
        }
    );

    const numberClassName = classnames("pb-5-px font-size-24-px", {
        "tw-opacity-50": transparent,
    });
    return (
        <h3 className={className}>
            <p className={numberClassName}>
                {typeof number === "number" ? number.toLocaleString() : number}
            </p>
            {description && (
                <p className="font-secondary tw-text-black font-size-12-px text-grey-mid">
                    {description}
                </p>
            )}
        </h3>
    );
}

import { BreadcrumbNode } from "../types";
import {
    suggestedWorkflowsState,
    terminatedUserReportIndividualConfigureState,
    terminatedUserReportIndividualState,
    terminatedUserReportListState,
    terminatedUserReportNewState,
    terminatedUsersReportBucketIndividualState,
    workflowDestinationsState,
    workflowEditState,
    workflowIndState,
    workflowListState,
    workflowNewState,
} from "./state";

// // // //

// routes.workflows
export const workflowListNode: BreadcrumbNode = {
    getState: workflowListState,
    parent: null,
};

// routes.workflowNew
export const workflowNewNode: BreadcrumbNode = {
    getState: workflowNewState,
    parent: workflowListNode,
};

// Resolved crumb that does not map to a particular route
// Placeholder crumb for routes.workflowEdit
const workflowIndNode: BreadcrumbNode = {
    getState: workflowIndState,
    parent: workflowListNode,
};

// routes.workflowEdit
export const workflowEditNode: BreadcrumbNode = {
    getState: workflowEditState,
    parent: workflowIndNode,
};

// routes.workflowDestinations
export const workflowDestinationsNode: BreadcrumbNode = {
    getState: workflowDestinationsState,
    parent: workflowListNode,
};

// routes.suggestedWorkflows
export const suggestedWorkflowsNode: BreadcrumbNode = {
    getState: suggestedWorkflowsState,
    parent: workflowListNode,
};

// routes.terminatedUsers
export const terminatedUserReportListNode: BreadcrumbNode = {
    getState: terminatedUserReportListState,
    parent: workflowListNode,
};

// routes.terminatedUsersReportNew
export const terminatedUserReportNewNode: BreadcrumbNode = {
    getState: terminatedUserReportNewState,
    parent: terminatedUserReportListNode,
};

// routes.terminatedUsersReportIndividual
export const terminatedUsersReportIndividualNode: BreadcrumbNode = {
    getState: terminatedUserReportIndividualState,
    parent: terminatedUserReportListNode,
};

// routes.terminatedUsersReportIndividualConfigure
export const terminatedUsersReportIndividualConfigureNode: BreadcrumbNode = {
    getState: terminatedUserReportIndividualConfigureState,
    parent: terminatedUsersReportIndividualNode,
};

// routes.terminatedUsersReportIndividualConfigure
export const terminatedUsersReportBucketIndividualNode: BreadcrumbNode = {
    getState: terminatedUsersReportBucketIndividualState,
    parent: terminatedUsersReportIndividualNode,
};

import {
    faChevronLeft,
    faChevronRight,
    faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    CursorDirection,
    CursorPagination,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import classNames from "classnames";
import * as React from "react";
import { getURLWithFiltersInput } from "../../modules/advanced_filters/components/advanced_filters/getURLWithFiltersInput";
import { LinkCC, RouteState, withRouter } from "../router";

// // // //

/**
 * cursorButtonClassName
 * Generates the className prop for the CursorButton
 */
function cursorButtonClassName({ enabled }: { enabled: boolean }): string {
    return classNames(
        "tw-inline-flex tw-justify-center tw-items-center", // Flex
        "tw-p-2 tw-w-12 tw-h-12 tw-shadow-sm", // Size + Shadow
        "tw-text-primary tw-transition-colors", // Text + Transition
        "tw-border tw-border-primary tw-rounded-full", // Border
        "focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary", // Focus
        {
            "hover:tw-bg-primary hover:tw-text-white": enabled,
            "tw-cursor-not-allowed tw-opacity-50": !enabled,
        }
    );
}

/**
 * CursorButton
 * Renders prev/next buttons for the CursorPager
 */
function CursorButton(props: {
    enabled: boolean;
    url: string;
    href: {
        pathname: string;
        query: {};
    };
    type: "prev" | "next";
}) {
    const { enabled, url, href, type } = props;

    // Build for prev/next button
    const icon = (
        <FontAwesomeIcon
            size="sm"
            fixedWidth
            className="tw-flex"
            icon={type === "prev" ? faChevronLeft : faChevronRight}
        />
    );

    // Render as <div> if not enabled
    if (!enabled) {
        return <div className={cursorButtonClassName({ enabled })}>{icon}</div>;
    }

    // Render LinkCC when enabled
    return (
        <LinkCC
            className={cursorButtonClassName({ enabled })}
            as={url}
            href={href}
        >
            {icon}
        </LinkCC>
    );
}

/**
 * CursorPagerProps
 * @param pagination - the `CursorPagination` state
 * @param itemCount - the count of items currently being rendered
 * @param filtersInput - the current `FiltersInput` state
 * @param totalRows - the number of total rows
 */
interface CursorPagerProps {
    pagination: CursorPagination;
    itemCount: number;
    filtersInput: FiltersInput;
    totalRows?: {
        count: number;
        loading: boolean;
    };
}

/**
 * CursorPager
 * Renders prev + next links for cursor-based pagination
 * @param props - see `CursorPaginationProps`
 */
export function CursorPagerLayout(
    props: CursorPagerProps & { route: RouteState }
) {
    const {
        filtersInput,
        itemCount,
        pagination,
        route,
        totalRows = null,
    } = props;
    const { cursor } = pagination;

    // Defines variables to store prev/next URLs
    let prevUrl: string = "";
    let nextUrl: string = "";

    // Defines flags to conditionally show prev + next buttons
    const showPrev: boolean = cursor !== null && cursor.startPosition !== "";
    const showNext: boolean =
        cursor !== null && itemCount === pagination.itemsPerPage;

    // Generate URLs for prev/next links
    if (showPrev) {
        // Gets the URL with the latest FiltersInput + pagination state
        prevUrl = getURLWithFiltersInput({
            location: route.location,
            params: route.params,
            filtersInput: {
                ...filtersInput,
                pagination: {
                    ...filtersInput.pagination,
                    cursorInput: {
                        direction: CursorDirection.prev,
                        position: cursor.startPosition,
                    },
                },
            },
        });
    }

    // Generate URLs for prev/next links
    if (showNext) {
        // Gets the URL with the latest FiltersInput + pagination state
        nextUrl = getURLWithFiltersInput({
            location: route.location,
            params: route.params,
            filtersInput: {
                ...filtersInput,
                pagination: {
                    ...filtersInput.pagination,
                    cursorInput: {
                        direction: CursorDirection.next,
                        position: cursor.endPosition,
                    },
                },
            },
        });
    }

    // Render the prev/next links
    return (
        <div className="tw-flex tw-items-center tw-gap-6">
            {/* Render "Prev" button */}
            <CursorButton
                enabled={showPrev}
                url={prevUrl}
                href={{
                    pathname: route.nextPathname,
                    query: route.pathParams,
                }}
                type={"prev"}
            />

            {/* Render "Next" button */}
            <CursorButton
                enabled={showNext}
                url={nextUrl}
                href={{
                    pathname: route.nextPathname,
                    query: route.pathParams,
                }}
                type={"next"}
            />

            {/* Render TotalRows */}
            {totalRows !== null && (
                <>
                    {!totalRows.loading && (
                        <p className="font-size-16-px">
                            {totalRows.count.toLocaleString()} Results
                        </p>
                    )}
                    {totalRows.loading && (
                        <FontAwesomeIcon
                            icon={faSpinnerThird}
                            spin
                            className="tw-h-8 tw-w-8 tw-ml-4"
                        />
                    )}
                </>
            )}
        </div>
    );
}

// // // //

export const CursorPager: React.ComponentType<CursorPagerProps> = withRouter(
    CursorPagerLayout
);

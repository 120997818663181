import * as React from "react";
import { Anchor } from "@src/shared_modules/router";
import { captureException } from "@src/util/analytics";
import { supportEmail } from "@src/util/consts";
import { FullscreenModal } from "../fullscreen_modal";

// // // //

const textClassName =
    "text-navy font-secondary-bold pb-20-px font-size-18-px line-height-24-px text-center";

export function Err() {
    return (
        <FullscreenModal>
            <p className={textClassName}>
                We&#39;re experiencing some technical difficulties. The
                SailPoint SaaS Management engineering team is on it.
            </p>

            <p className={textClassName}>
                Feel free to email{" "}
                <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>{" "}
                for further assistance. In the meantime click refresh to refresh
                the page.
            </p>

            <Anchor
                className="btn-md btn-primary font-secondary-bold w-100 tw-flex tw-justify-center"
                href={window.location.href}
                eventProps={{
                    error_boundary: true,
                }}
            >
                Refresh Page
            </Anchor>
        </FullscreenModal>
    );
}

interface Props {
    children?: React.ReactNode;
}

interface State {
    error: any;
}

/**
 * ErrorBoundary
 * ErrorBoundary is an error boundary that we render whenever an error occurs in the app.
 * It renders a full-screen modal with a cta to refresh the current page.
 * @param props - see Props
 */
export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null };
    }

    public componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error });
        captureException(error, { extra: errorInfo });
    }

    public render() {
        if (this.state.error) {
            return <Err />;
        }

        return this.props.children;
    }
}

import gql from "graphql-tag";

// // // //

/**
 * Content represents nested content
 */
export interface Content {
    key: string;
    name: string;
    description: string;
    __typename: "Content";
}

export const CONTENT_FRAGMENT = gql`
    fragment CONTENT_FRAGMENT on Content {
        key
        name
        description
    }
`;

export enum FieldType {
    currency = "currency",
    dropdown = "dropdown",
    boolean_dropdown = "boolean_dropdown",
    date_picker = "date_picker",
    date_range = "date_range",
    text = "text",
    textArray = "text_array",
    email = "email",
    number = "number",
    float = "float",
    integer = "integer",
    multi_dropdown = "multi_dropdown",
    vendor = "vendor",
    hidden = "hidden",
    button = "button",
    advanced_date_picker = "advanced_date_picker",
    advanced_past_date_picker = "advanced_past_date_picker",
    advanced_past_week_date_picker = "advanced_past_week_date_picker",
    source_single_dropdown = "source_single_dropdown",
}

export enum FilterExpression {
    eq = "eq",
    gt = "gt",
    lt = "lt",
    neq = "neq",
    gte = "gte",
    lte = "lte",
    like = "like",
    nlike = "nlike",
    ilike = "ilike",
    nilike = "nilike",
    ddlt = "ddlt",
    ddlte = "ddlte",
    ddgt = "ddgt",
    ddgte = "ddgte",
    icontains = "icontains",
    nicontains = "nicontains",
    isdefined = "isdefined",
    isundefined = "isundefined",
    and = "and",
    or = "or",
    adp_upe = "adp_upe", // Relative Date -> Upcoming
    adp_pde = "adp_pde", // Relative Date -> Past
    adp_pwe = "adp_pwe", // Relative Date -> Past (in weeks)
    adp_fde = "adp_fde", // Date -> Fixed Range
    adp_ude = "adp_ude", // Date -> Undefined
    adp_dde = "adp_dde", // Date -> Defined
    empty = "",
}

/**
 * mapFilterExpressionToHumanValue
 * Maps each FilterExpression to a human-readable value
 */
export const mapFilterExpressionToHumanValue: {
    [key in FilterExpression]: string;
} = {
    [FilterExpression.eq]: "Equals",
    [FilterExpression.gt]: "Greater Than",
    [FilterExpression.lt]: "Less Than",
    [FilterExpression.neq]: "Not Equals",
    [FilterExpression.gte]: "Greater Than or Equal To",
    [FilterExpression.lte]: "Less Than or Equal To",
    [FilterExpression.ilike]: "Like",
    [FilterExpression.nilike]: "Not Like",
    [FilterExpression.like]: "Like",
    [FilterExpression.nlike]: "Not Like",
    [FilterExpression.ddlt]: "Less Than",
    [FilterExpression.ddlte]: "Less Than or Equal To",
    [FilterExpression.ddgt]: "Greater Than",
    [FilterExpression.ddgte]: "Greater Than or Equal To",
    [FilterExpression.icontains]: "Contains",
    [FilterExpression.nicontains]: "Does Not Contain",
    [FilterExpression.isdefined]: "Is Defined",
    [FilterExpression.isundefined]: "Is Not Defined",
    [FilterExpression.and]: "And",
    [FilterExpression.or]: "Or",
    [FilterExpression.adp_upe]: "Upcoming",
    [FilterExpression.adp_pde]: "Past",
    [FilterExpression.adp_pwe]: "Past (in weeks)",
    [FilterExpression.adp_fde]: "Fixed Date",
    [FilterExpression.adp_ude]: "Undefined",
    [FilterExpression.adp_dde]: "Defined",
    [FilterExpression.empty]: "",
};

export type FilterVal = string | number | boolean | string[] | number[];

/**
 * CursorDirection
 * Defines values for CursorInput.direction
 * -1 for the previous page, 1 for the next page, 0 is invalid input
 */
export enum CursorDirection {
    prev = -1,
    next = 1,
}

/**
 * CursorInput
 * The last position of the cursor, used to calculate the first record of the new page dynamically, because data could
 * have changed between page load and request for the next page. To move forward a page, position should be the ID
 * of the LAST item that the client was shown in a list. To move backward, position should be the ID of the FIRST
 * item that the client was shown in a list.
 * @property position - the last position of the cursor
 * @property direction - the direction of the cursor
 */
export interface CursorInput {
    position: null | string;
    direction: CursorDirection;
}

/**
 * CursorPaginationInput
 * Defines the value for FiltersInput.pagination when using cursor-based pagination
 * @property page - the page number (required by the API, but not used)
 * @property itemsPerPage - the number of items returned per-page
 * @property sort - the property being sorted
 * @property direction - the direction of the sort being applied
 * @property cursor - see `CursorInput`
 */
export interface CursorPaginationInput {
    page: number;
    itemsPerPage: number;
    sort: string;
    direction: string;
    cursorInput: CursorInput;
}

/**
 * Cursor
 * Used to communicate the location of the client in the paged result set
 * @property startPosition - The id of the first item in this page of the dataset
 * @property endPosition - The id of the last item in this page of the dataset
 */
export interface Cursor {
    startPosition: string;
    endPosition: string;
}

/**
 * CursorPagination
 * The value returned by the API encapsulating the pagination + cursor state
 */
export interface CursorPagination {
    itemsPerPage: number;
    sort: string;
    direction: string;
    cursor: Cursor;
}

/**
 * OffsetPaginationInput
 * Handles seamlessly paginating over items
 * `itemsPerPage` - the number of items per page to return
 * `page` - the page to query for
 * `sort` - the sort to apply
 * `direction` - the direction of the sort
 */
export interface OffsetPaginationInput {
    itemsPerPage: number;
    page: number;
    sort: string;
    direction: string;
}

/**
 * PaginationInput
 * Defines a type union of the two different pagination input interfaces
 */
export type PaginationInput = OffsetPaginationInput | CursorPaginationInput;

/**
 * Filter
 * The filter type that we use to compose our advanced filters
 * `fieldName` - field name to filter
 * `expression` - filter expression to apply
 * `val` - value to apply to the filter
 * `filters` - list of filters to apply
 */
export interface Filter {
    fieldName: string;
    expression: FilterExpression;
    val: FilterVal;
    filters: Filter[];
}

/**
 * EditorFilter
 * A `Filter` interface with `id` and `source` properties that are used internally by the `AdvancedFiltersEditor`
 * NOTE - the `id` attribute is not expected by GraphQL, it's used exclusively to aid managing UI state
 * `id` - the unique (client-side generated) ID for the `Filter` instance
 * `source` - the ID of the AdvancedFilter to which this filter belongs
 */
export interface EditorFilter extends Filter {
    id: string;
    source: string;
    filters: EditorFilter[];
}

/**
 * AdvancedFilterInput
 * The advanced filter input to apply
 * `key` should match the key that we return for defaults
 * `filters` should be the filters that we want to apply on this advanced filter input
 */
export interface AdvancedFilterInput {
    key: string;
    filters: Filter[];
}

/**
 * EditorAdvancedFilterInput
 * An `AdvancedFilterInput` interface that's been changed to maintain its filters as `EditorFilter[]` instead of `Filter[]`
 */
export interface EditorAdvancedFilterInput {
    key: string;
    filters: EditorFilter[];
}

/**
 * FiltersInput
 * The type that we save for an input of filters to apply
 * `orgID` - the organization id to apply
 * `pagination` - the pagination to apply
 * `advanced` - the advaned filters to apply
 * `defaults` - the default filters to apply
 */
export interface FiltersInput {
    organizationID: number;
    pagination?: PaginationInput;
    advanced: AdvancedFilterInput[];
    defaults: Filter[];
}

/**
 * EditorFiltersInput
 * A `FiltersInput` interface used by the `AdvancedFiltersEditor`
 * Identical to FiltersInput, but instead of `Filter[]` it uses `EditorFilter[]`
 * Note that `pagination` data is not encapsulated here because it's not used by the `AdvancedFiltersEditor`
 */
export interface EditorFiltersInput {
    organizationID: number;
    advanced: EditorAdvancedFilterInput[];
    defaults: EditorFilter[];
}

// // // //
// TimeSeriesFilters

/**
 * TimeSeriesMinMax
 * Defines interface to encapsulate the min + max values for TimeSeriesFilters
 * Used below to derive TimeSeriesFilters interface
 */
interface TimeSeriesMinMax {
    min: string;
    max: string;
}

/**
 * TimeSeriesFilters
 * Defines interface to encapsulate either min, max, or both values
 * Prevents an empty object from being recognized as a valid TimeSeriesFilters instance
 */
export type TimeSeriesFilters =
    | TimeSeriesMinMax
    | Pick<TimeSeriesMinMax, "min">
    | Pick<TimeSeriesMinMax, "max">;

/**
 * TimeSeriesFiltersInput
 * Extends FiltersInput with an additional timeSeries property
 * Used to query TimeSeries data with AdvancedFilters
 */
export interface TimeSeriesFiltersInput extends FiltersInput {
    timeSeries: TimeSeriesFilters;
}

// // // //

export interface Content {
    name: string;
    description: string;
}

export interface QueryDestination {
    name: string;
    content: Content;
}

export interface QueryDetailsObject {
    key: string;
    value: string;
}

export interface QueryDetails {
    defaults: QueryDetailsObject[];
}

export interface PossibleValue {
    humanValue: string;
    internalValue: string | number | boolean | string[] | number[];
    description?: string;
}

export interface PossibleFilter {
    key: string;
    content: Content;
    fieldType: FieldType;
    expressions: FilterExpression[];
    possibleValues: PossibleValue[];
    defaultExpression: FilterExpression;
    showInline?: boolean;
}

export interface AdvancedFilter {
    key: string;
    content: Content;
    filters: PossibleFilter[];
}

export interface Filters {
    defaults: PossibleFilter[];
    advanced: AdvancedFilter[];
}

// // // //
// AppliedFilters + AppliedFilter + AppliedAdvancedFilter + AppliedPagination
// NOTE - the distinction between `Filters` and `AppliedFilters` may seem arbitrary, but we need this for two important reasons.
// 1. This distinction makes it much easier to manually interface with the Apollo InMemoryCache without introducing type-related errors
// 2. This distinction makes our frontend types congruent with the API

/**
 * AppliedFilter
 * AppliedFilter is the SAVED Filter
 */
export interface AppliedFilter {
    fieldName: string;
    expression: FilterExpression;
    val: FilterVal;
    filters: AppliedFilter[];
    __typename: "AppliedFilter";
}

/**
 * AppliedAdvancedFilter
 * AppliedAdvancedFilter is the SAVED AdvancedFilterInput
 */
export interface AppliedAdvancedFilter {
    key: string;
    filters: AppliedFilter[];
    __typename: "AppliedAdvancedFilter";
}

/**
 * AppliedOffsetPaginationInput
 * The SAVED OffsetPaginationInput on a Workflow or SuggestedFilter
 */
export interface AppliedOffsetPaginationInput extends OffsetPaginationInput {
    __typename: "Pagination";
}

/**
 * AppliedCursorPaginationInput
 * The SAVED CursorPaginationInput on a Workflow or SuggestedFilter
 */
export interface AppliedCursorPaginationInput extends CursorPaginationInput {
    __typename: "CursorPaginationInput";
}

/**
 * AppliedPagination
 * Union of the applied offset + cursor pagination input interfaces
 */
export type AppliedPagination =
    | AppliedOffsetPaginationInput
    | AppliedCursorPaginationInput;

/**
 * AppliedFilters
 * AppliedFilterInput is the type of the SAVED FiltersInput
 */
export interface AppliedFilters {
    defaults: AppliedFilter[];
    advanced: AppliedAdvancedFilter[];
    pagination: AppliedPagination;
    __typename: "AppliedFilters";
}

/**
 * StormbreakerAttribute
 * Defines values accepted by AdvancedFiltersProvider.props.stormbreakerAttributes
 */
export enum StormbreakerAttribute {
    appInfo = "appInfo",
    accessControl = "accessControl",
    applicationUser = "applicationUser",
    reconciliationHistory = "reconciliationHistory",
    vendorRolesUploadHistory = "vendorRolesUploadHistory",
    individual = "individual",
    listing = "listing",
    payments = "payments",
    roleAssignments = "roleAssignments",
    roles = "roles",
    spend = "spend",
    spendChart = "spendChart",
    usage = "usage",
    users = "users",
    suggestedWorkflows = "suggestedWorkflows",
    vendors = "vendors",
    vendorAppsSummary = "vendorAppsSummary",
    auditLogs = "auditLogs",
    workflow = "workflow",
    timeseries = "timeseries",
    connectedAppsUsers = "connectedAppsUsers",
    connectedAppsApplications = "connectedAppsApplications",
    groupSummary = "groupSummary",
    purchaseOrders = "purchaseOrders",
    purchaseOrderLineItems = "purchaseOrderLineItems",
    purchaseOrderDetails = "purchaseOrderDetails",
    applicationUsageSummary = "applicationUsageSummary",
    report = "report",
}

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { empty as EMPTY_PURCHASE_ORDER_LIST } from "@src/schema/data/PurchaseOrderList";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import React from "react";
import { Query } from "react-apollo";
import { PurchaseOrder } from "@src/schema/PurchaseOrder";
import { PurchaseOrderList } from "@src/schema/PurchaseOrderList";
import { Pagination } from "@src/schema/Pagination";
import {
    PURCHASE_ORDERS_FETCHER_QUERY,
    PurchaseOrdersFetcherResponse,
    PurchaseOrdersFetcherVariables,
} from "./graphql";

// // // //

interface PurchaseOrderFetcherProps {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        purchaseOrders: PurchaseOrder[];
        refetchPurchaseOrders: () => void;
    }) => React.ReactNode;
}

/**
 * @name PurchaseOrdersFetcher
 * @description Fetches the PurchaseOrder data via GQL using AdvancedFilters
 * @param PurchaseOrderFetcherProps
 */
export function PurchaseOrdersFetcher(props: PurchaseOrderFetcherProps) {
    return (
        <Query<PurchaseOrdersFetcherResponse, PurchaseOrdersFetcherVariables>
            query={PURCHASE_ORDERS_FETCHER_QUERY}
            variables={{
                filters: props.filtersInput,
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls PurchaseOrderList from response
                const list: PurchaseOrderList =
                    data?.procurement?.purchaseOrders?.list ||
                    EMPTY_PURCHASE_ORDER_LIST;

                // Safely pulls purchaseOrders + pagination from list
                const pagination: Pagination = list.pagination;
                let purchaseOrders: PurchaseOrder[] = list.results;

                // Handle null value for PurchaseOrder[]
                if (purchaseOrders === null) {
                    purchaseOrders = [];
                }

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            purchaseOrders,
                            refetchPurchaseOrders: () => {
                                return refetch({ filters: props.filtersInput });
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

import { FeatureContent } from "@src/shared_modules/feature_content";
import { Loader } from "@src/shared_modules/loader";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * ConfirmDeleteSavedViewProps are the props of the ConfirmDeleteSavedView component
 * @param name - name of the saved filter that is going to be deleted
 * @param onConfirm - callback fired when the user confirms the delete saved view action
 * @param onCancel - callback fired when the user confirms the delete saved view action
 */
interface ConfirmDeleteSavedViewProps {
    name: string;
    onConfirm: () => void;
    onCancel: () => void;
}

/**
 * ConfirmDeleteSavedView
 * Used to confirm or cancel saved view deletion
 * @param props - see `ConfirmDeleteSavedViewProps`
 */
export function ConfirmDeleteSavedView(props: ConfirmDeleteSavedViewProps) {
    return (
        <FeatureContent uniqueKey="confirm-delete-saved-view-header-and-message">
            {(headerContent) => (
                <FeatureContent uniqueKey="confirm-delete-saved-view-confirm-button">
                    {(confirmContent) => (
                        <FeatureContent uniqueKey="confirm-delete-saved-view-cancel-button">
                            {(cancelContent) => {
                                // Return loading state if any of the content is still loading
                                if (
                                    headerContent.loading ||
                                    confirmContent.loading ||
                                    cancelContent.loading
                                ) {
                                    return (
                                        <div
                                            className={
                                                "d-flex flex-column justify-content-center"
                                            }
                                        >
                                            <Loader />
                                        </div>
                                    );
                                }

                                // Return component TSX
                                return (
                                    <div className={"d-flex flex-column"}>
                                        <p className="text-center font-secondary font-size-18-px text-grey-mid pb-5-px">
                                            {headerContent.feature.header}
                                        </p>
                                        <h4 className="text-center font-secondary-bold font-size-18-px text-grey-mid pb-20-px">
                                            {props.name}
                                        </h4>
                                        <p className="text-center pb-30-px font-secondary font-size-14-px text-grey-mid ">
                                            {headerContent.feature.description}
                                        </p>

                                        <button
                                            onClick={() => {
                                                props.onConfirm();
                                            }}
                                            className={classnames(
                                                "btn btn-md btn-alert font-secondary-bold font-size-14-px line-height-19-px",
                                                styles.confirmDeleteSavedViewButton
                                            )}
                                        >
                                            {confirmContent.feature.header}
                                        </button>
                                        <button
                                            onClick={() => {
                                                props.onCancel();
                                            }}
                                            className={classnames(
                                                "btn btn-md btn-stroked-grey font-secondary-bold font-size-14-px line-height-19-px text-grey-mid mt-15-px",
                                                styles.cancelDeleteSavedViewButton
                                            )}
                                        >
                                            {cancelContent.feature.header}
                                        </button>
                                    </div>
                                );
                            }}
                        </FeatureContent>
                    )}
                </FeatureContent>
            )}
        </FeatureContent>
    );
}

import React from "react";
import { Vendor, VendorFetcher } from "@src/shared_modules/related_vendors";
import { captureMessage } from "@src/util/analytics";
import { ResolverProps } from "./component";

// // // //

export function VendorResolver(props: ResolverProps<Vendor>) {
    const vendorID: number | undefined = props.params?.vendorID;

    if (vendorID === undefined) {
        captureMessage("VendorResolver - params.vendorID is undefined");
        return null;
    }

    const { loadingPlaceholder = "...", errorFallback = null } = props;

    return (
        <VendorFetcher vendorID={vendorID} includeRelatedVendors={false}>
            {({ vendor, loading }) => {
                if (props.children) {
                    return props.children({ value: vendor, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (vendor === null || vendor === undefined) {
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return <React.Fragment>{vendor.name}</React.Fragment>;
            }}
        </VendorFetcher>
    );
}

import { CoreEvents, DashboardEvents } from "./events";
import { track } from "./track";

// FilterValue is the possible value type for different filters
type FilterValue =
    | string
    | string[]
    | { start: string | null; end: string | null };

// FilterProps contains the fields that are tracked
interface FilterProps {
    filter_val: FilterValue;
    filter_url: string;
    filter_type: string;
    action: "applied" | "removed";
}
export function filterAppliedProps(
    filterType: string,
    filterVal: FilterValue,
    filterUrl: string
): FilterProps {
    return {
        filter_val: filterVal,
        filter_url: filterUrl,
        action: "applied",
        filter_type: filterType,
    };
}

export function filterRemovedProps(
    filterType: string,
    filterVal: FilterValue,
    filterUrl: string
): FilterProps {
    return {
        filter_val: filterVal,
        filter_url: filterUrl,
        action: "removed",
        filter_type: filterType,
    };
}

export function trackFilterApplied(
    filterType: string,
    filterVal: FilterValue,
    filterURL: string
) {
    const props: FilterProps = filterAppliedProps(
        filterType,
        filterVal,
        filterURL
    );
    track(DashboardEvents.filterApplied, props);
}

interface ModalLoadProps {
    name: string;
}
// trackModalLoad tracks that a modal was loaded
export function trackModalLoad(name: string) {
    const props: ModalLoadProps = {
        name,
    };
    track(CoreEvents.modalLoaded, props);
}

// trackModalClosed tracks that a modal was closed
export function trackModalClosed(name: string) {
    const props: ModalLoadProps = {
        name,
    };
    track(CoreEvents.modalClosed, props);
}

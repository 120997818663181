import React from "react";

// // // //

export function UsageRankCASB(props?: { className?: string; label: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={props.className}
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <path
                stroke="currentColor"
                fill="currentColor"
                d="M466.5 83.69l-192-80.01C269.6 1.656 261.3 0 256.1 0C250.8 0 242.5 1.656 237.6 3.688l-192 80.01C27.69 91.08 16 108.6 16 127.1C16 385.2 205.2 512 255.1 512C307.1 512 496 383.8 496 127.1C496 108.6 484.3 91.08 466.5 83.69zM256.1 48.03C256.1 48.02 256.2 48.03 256.1 48.03L256.1 48.03zM255.1 464C163.5 425.5 64.06 303.9 64.06 128.1c0-.05 0 .05 0 0l190.8-79.64c.332-.1016 .7422-.3021 1.275-.3997L448 127.1C448 330.5 322.4 436.3 255.1 464zM200 208c0 22.3 13.13 41.4 32 50.41V328C232 341.3 242.8 352 256 352s24-10.75 24-24V258.4c18.87-9.008 32-28.11 32-50.41c0-30.93-25.07-56-56-56S200 177.1 200 208z"
            />
        </svg>
    );
}

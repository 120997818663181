import gql from "graphql-tag";

// // // //

export interface Vendor {
    id: number;
    category: string;
    description: null | string;
    domain: string;
    gdprLink: null | string;
    logo: string;
    name: string;
    nameAliases: string[];
    pricingLink: null | string;
    privacyLink: null | string;
    state: string;
    tldVendor: boolean;
    tosLink: null | string;
    verified: boolean;
    children?: Vendor[] | null;
    siblings?: Vendor[] | null;
    parent?: Vendor | null;
    __typename: "Vendor";
}

export const VENDOR_FRAGMENT = gql`
    fragment VENDOR_FRAGMENT on Vendor {
        id
        category
        description
        domain
        gdprLink
        logo
        name
        nameAliases
        pricingLink
        privacyLink
        state
        tldVendor
        tosLink
        verified
    }
`;

import React from "react";

export function Success(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#2DC8A8"
                    fillRule="nonzero"
                    transform="translate(-119 -203)"
                >
                    <g transform="translate(95 176)">
                        <g transform="translate(24 27)">
                            <path d="M12 24C5.388 24 0 18.612 0 12S5.388 0 12 0s12 5.388 12 12-5.388 12-12 12zm0-21.33c-5.143 0-9.33 4.187-9.33 9.33s4.187 9.33 9.33 9.33 9.33-4.187 9.33-9.33S17.144 2.67 12 2.67z"></path>
                            <path d="M10.629 17.339a1.24 1.24 0 01-.931-.392l-3.967-3.943a1.345 1.345 0 010-1.886 1.345 1.345 0 011.885 0l3.013 3.013 5.73-5.731a1.345 1.345 0 011.886 0 1.345 1.345 0 010 1.886l-6.686 6.66c-.27.246-.612.393-.93.393z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { Caret } from "@src/shared_modules/caret";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import {
    Direction,
    directionParam,
    reverseDirection,
    sortParam,
} from "@src/shared_modules/list";
import { InfoTooltip } from "@src/shared_modules/info_tooltip";
import { buildQsFromObject, updateParams } from "@src/util/route";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { QueryParams } from "../../util/route/updateParams_util";
import { Tooltips, TooltipsState } from "../tooltips";

// // // //

// getHeaderLinkVars
// returns variables required for rendering a header link. it was easier to
// extract this logic into a function instead of composing react components
export function getHeaderLinkVars(
    params: QueryParams,
    defaultDirection: Direction,
    sort: string,
    isDefault: boolean
): [string, boolean, boolean, boolean] {
    let updatedParams = { ...params };
    // whether the current is active
    let isActive: boolean = false;
    // the direction we'll use in the query parameters
    let direction: Direction = defaultDirection;
    // whether the current direction is ascending
    let isAsc: boolean = false;
    // whether the current direction is descending
    let isDesc: boolean = false;
    // if the sort is already in the parameters or if the sort isn't set but the current link is the default then we just reverse the direction
    if (
        sort === params[sortParam] ||
        (params[sortParam] == null && isDefault)
    ) {
        isActive = true;
        // we only need to reverse directions if we're dealing with the active sort
        [direction, isAsc, isDesc] = reverseDirection(params, defaultDirection);
    }

    // if the sorts aren't equal replace it
    if (sort !== params[sortParam]) {
        const updateParameters = {
            params,
            key: sortParam,
            val: sort,
            replace: true,
        };
        [updatedParams] = updateParams(updateParameters);
    }

    // if the direction aren't equal replace it
    if (params[directionParam] !== direction) {
        [updatedParams] = updateParams({
            params: updatedParams,
            key: directionParam,
            val: direction,
            replace: true,
        });
    }

    // build the query string
    const qs: string = buildQsFromObject(updatedParams);

    return [qs, isActive, isAsc, isDesc];
}

/**
 * StateProps
 * The props that are injected into HeaderLink component by redux connect()
 */
interface StateProps {
    tooltips: Tooltips;
}

// OwnProps are props that have to be passed directly to the header link own props
interface OwnProps {
    text: string;
    sort: string;
    className: string;
    defaultDirection: Direction;
    default: boolean;
    tooltipKey?: string;
}

// HeaderLink renders a header link that allows a user to sort by the header parameter.
// The HeaderLink doesn't do any checks that the sort parameter is supported by the server, that's totally up to the user to handle.
// If the sort already exist in the params it'll update the sort to be desc.
export function HeaderLink(
    props: OwnProps & StateProps & { route: RouteState }
) {
    const { route, defaultDirection, sort, tooltips, tooltipKey } = props;
    const { params, location } = route;
    const [qs, isActive, isAsc, isDesc] = getHeaderLinkVars(
        params,
        defaultDirection,
        sort,
        props.default
    );

    // show the tooltip if the tooltipKey property was passed and the text for that key exists
    const showTooltip: boolean =
        tooltipKey && tooltips[tooltipKey] !== undefined;

    return (
        <span className={props.className}>
            <LinkCC
                href={{
                    pathname: props.route.nextPathname,
                    query: {
                        ...props.route.pathParams,
                        ...params,
                    },
                }}
                as={`${location}${qs}`}
                className={classNames(
                    "tw-flex tw-items-center font-size-12-px font-secondary-bold line-height-16-px tw-uppercase tw-transition-colors no-underline",
                    {
                        "tw-text-medium-grey hover:tw-text-navy-primary": !isActive,
                        "tw-text-navy-primary": isActive,
                    }
                )}
            >
                <Caret isAsc={isAsc} isDesc={isDesc} />
                {props.text}
                {showTooltip && (
                    <InfoTooltip>{tooltips[tooltipKey]}</InfoTooltip>
                )}
            </LinkCC>
        </span>
    );
}

/**
 * mapStateToProps
 * Injects the tooltips from Redux `tooltips` state into the HeaderLink component
 */
function mapStateToProps({
    tooltips,
}: {
    tooltips: TooltipsState;
}): StateProps {
    return {
        tooltips: tooltips.tooltips,
    };
}

export const HeaderLinkCC: React.ComponentType<OwnProps> = withRouter(
    connect(mapStateToProps)(HeaderLink)
);

import { BreadcrumbNode } from "../types";
import {
    integrationIndividualAuthState,
    integrationIndividualDashState,
    integrationsState,
} from "./state";

// // // //

export const integrationsNode: BreadcrumbNode = {
    getState: integrationsState,
    parent: null,
};

export const integrationIndividualDashNode: BreadcrumbNode = {
    getState: integrationIndividualDashState,
    parent: integrationsNode,
};

export const integrationIndividualAuthNode: BreadcrumbNode = {
    getState: integrationIndividualAuthState,
    parent: integrationIndividualDashNode,
};

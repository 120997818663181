import gql from "graphql-tag";

import {
    OrganizationField,
    OrgFieldOrderInput,
    OrgFieldUpsertInput,
} from "./types";

// // // //

/*
 * ORG_FIELD_QUERY
 * Used to fetch the OrgFields by the OrgFieldFetcher component
 */
export const ORG_FIELD_QUERY = gql`
    query GET_ORG_FIELDS_QUERY($filters: OrgFieldFilters!) {
        spend {
            customFields(filters: $filters) {
                id
                organizationID
                name
                fieldType
                inputType
                attributes
                editable
                archived
                filterable
                isSortable
            }
        }
    }
`;

/**
 * OrgFieldQueryFilters
 * Variables interface for ORG_FIELD_QUERY
 */
export interface OrgFieldQueryFilters {
    filters: {
        orgID: number;
        isArchived: boolean;
    };
}

/**
 * OrgFieldQueryResponse
 * Response interface for ORG_FIELD_QUERY
 */
export interface OrgFieldQueryResponse {
    spend: {
        customFields: OrganizationField[];
    };
}

/**
 * buildOrgFieldQueryFilters
 * Builds the GQL query variables for ORG_FIELD_QUERY
 * @param orgID - User's organizationID
 */
export function buildOrgFieldQueryFilters(orgID: number): OrgFieldQueryFilters {
    return {
        filters: {
            orgID,
            isArchived: false,
        },
    };
}

// // // //
// ArchiveOrgFieldMutation mutation

/**
 * ARCHIVE_ORG_FIELD_MUTATION
 * Mutation for ArchiveOrgFieldMutation component
 */
export const ARCHIVE_ORG_FIELD_MUTATION = gql`
    mutation ARCHIVE_ORG_FIELD_MUTATION($input: OrgFieldUpsertInput!) {
        archiveOrgField(input: $input)
    }
`;

/**
 * ArchiveOrgFieldMutationRequest
 * Request interface for ArchiveOrgFieldMutation
 */
export interface ArchiveOrgFieldMutationRequest {
    input: OrgFieldUpsertInput;
}

/**
 * ArchiveOrgFieldMutationResponse
 * Response interface for ArchiveOrgFieldMutation
 */
export interface ArchiveOrgFieldMutationResponse {
    archiveOrgField: boolean;
}

// // // //
// UpdateOrgFieldMutation mutation

/**
 * UPDATE_ORG_FIELD_MUTATION
 * Mutation for UpdateOrgFieldMutation component
 */
export const UPDATE_ORG_FIELD_MUTATION = gql`
    mutation UPDATE_ORG_FIELD_MUTATION($input: OrgFieldUpsertInput!) {
        updateOrgField(input: $input) {
            id
            organizationID
            name
            fieldType
            inputType
            attributes
            editable
            archived
            filterable
            isSortable
        }
    }
`;

/**
 * UpdateOrgFieldMutationRequest
 * Request interface for UpdateOrgFieldMutation
 */
export interface UpdateOrgFieldMutationRequest {
    input: OrgFieldUpsertInput;
}

/**
 * UpdateOrgFieldMutationResponse
 * Response interface for UpdateOrgFieldMutation
 */
export interface UpdateOrgFieldMutationResponse {
    updateOrgField: OrganizationField;
}

// // // //
// CreateOrgFieldMutation mutation

/**
 * CREATE_ORG_FIELD_MUTATION
 * Mutation for CreateOrgFieldMutation component
 */
export const CREATE_ORG_FIELD_MUTATION = gql`
    mutation CREATE_ORG_FIELD_MUTATION($input: OrgFieldUpsertInput!) {
        insertOrgField(input: $input) {
            id
            organizationID
            name
            fieldType
            inputType
            attributes
            editable
            archived
            filterable
            isSortable
        }
    }
`;

/**
 * CreateOrgFieldMutationRequest
 * Request interface for CreateOrgFieldMutation
 */
export interface CreateOrgFieldMutationRequest {
    input: OrgFieldUpsertInput;
}

/**
 * CreateOrgFieldMutationResponse
 * Response interface for CreateOrgFieldMutation
 */
export interface CreateOrgFieldMutationResponse {
    insertOrgField: OrganizationField;
}

/**
 * UPDATE_ORG_FIELD_ORDER_MUTATION
 * Mutation for UpdateOrgFieldOrderMutation component
 */
export const UPDATE_ORG_FIELD_ORDER_MUTATION = gql`
    mutation UPDATE_ORG_FIELD_ORDER_MUTATION($input: OrgFieldOrderInput!) {
        updateOrgFieldOrder(input: $input) {
            id
            organizationID
            name
            fieldType
            inputType
            attributes
            editable
            archived
            filterable
            isSortable
        }
    }
`;

/**
 * UpdateOrgFieldOrderMutationRequest
 * Request interface for UpdateOrgFieldOrderMutation
 */
export interface UpdateOrgFieldOrderMutationRequest {
    input: OrgFieldOrderInput;
}

/**
 * UpdateOrgFieldOrderMutationResponse
 * Response interface for UpdateOrgFieldOrderMutation
 */
export interface UpdateOrgFieldOrderMutationResponse {
    updateOrgFieldOrder: OrganizationField[];
}

// // // //
// Apollo Cache Update

export type OrgFieldCache = OrganizationField & {
    __typename: "OrganizationField";
};

/*
 * OrgFieldsCacheValue
 * Defines interface for Apollo InMemoryCache value for ORG_FIELD_QUERY
 */
export interface OrgFieldsCacheValue {
    spend: {
        customFields: OrgFieldCache[];
        __typename: "Spend";
    };
}

import { captureMessage } from "@src/util/analytics";
import * as React from "react";
import { ColumnList } from "./ColumnList";
import { ColumnListEmpty } from "./ColumnListEmpty";
import { ManageColumnsHeader } from "./ManageColumnsHeader";
import { Column, ColumnSetting } from "./types";

// // // //

/**
 * ManageColumnsProps
 * @param columns - The array of `Column` instances
 * @param columnSettings - The array of user `ColumnSetting` instances
 * @param disabled - Whether or not to disable the apply + submit buttons + reordering (true while saving ColumnSettings)
 * @param onChange - The array of user `ColumnSetting` instances
 * @param onClickApply - Callback to apply the current callback settings
 * @param onClickSave - Callback to save the current callback settings
 */
interface ManageColumnsProps {
    columns: Column[];
    columnSettings: ColumnSetting[];
    disabled: boolean;
    onChange: (updatedColumnSettings: ColumnSetting[]) => void;
    onClickApply: () => void;
    onClickSave: () => void;
}

/**
 * ManageColumns
 * Manages ColumnSetting + Column filter state
 * Renders ManageColumnsHeader, ColumnList, and ColumnListEmpty components
 * @param props - see ManageColumnsProps
 */
export function ManageColumns(props: ManageColumnsProps) {
    const { columns, columnSettings, disabled } = props;
    const [filter, setFilter] = React.useState<string>("");

    // Defines filteredColumnSettings
    // Filters for columnSettings by checking if there's a column whose content includes the filter
    // NOTE - we filter on columnSettings _first_ so we can retain the correct drag/drop order. This is
    // because order is determined by `columnSettings` - we never change the order of props.columns`
    const filteredColumnSettings: ColumnSetting[] = columnSettings.filter(
        (cs) => {
            // Finds the `Column` associated with the `ColumnSetting`
            const associatedColumn: Column | undefined = columns.find(
                (c) => c.key === cs.key
            );

            // If associatedColumn is undefined -> captureMessage + return false
            // NOTE - this _really_ should never happen as this component expects
            // props.columns is populated 1-to-1 with props.columns, so we captureMessage
            // because it's an indication that something has _actually_ gone wrong.
            if (associatedColumn === undefined) {
                captureMessage(
                    "ManageColumns - no Column found for columnSetting"
                );
                return false;
            }

            // Returns true/false if the associated column's content matches filter
            const filterLC = filter.toLowerCase();
            const name = associatedColumn.content.name.toLowerCase();
            const description = associatedColumn.content.description.toLowerCase();
            return name.includes(filterLC) || description.includes(filterLC);
        }
    );

    return (
        <div className="bg-white">
            <ManageColumnsHeader
                disabled={disabled}
                columnSearchFilter={filter}
                onSearchFilterChange={setFilter}
                onClickSave={props.onClickSave}
                onClickApply={props.onClickApply}
            />

            {/* Render ColumnList */}
            {filteredColumnSettings.length > 0 && (
                <ColumnList
                    columns={columns}
                    columnSettings={filteredColumnSettings}
                    disableReorder={disabled || filter !== ""}
                    disableToggle={disabled}
                    onChange={props.onChange}
                />
            )}

            {/* Render ColumnListEmpty */}
            {filteredColumnSettings.length === 0 && (
                <ColumnListEmpty
                    clearFilter={() => {
                        setFilter("");
                    }}
                />
            )}
        </div>
    );
}

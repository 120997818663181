import {
    AdvancedFilterInput,
    AppliedAdvancedFilter,
    AppliedFilter,
    AppliedFilters,
    Filter,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import {
    SuggestedFilter,
    SuggestedFilterState,
} from "@src/modules/advanced_filters/components/advanced_filters/suggested_filters/graphql";
import { DEFAULT_PAGINATION } from "../constants";

// // // //

/**
 * addTypenameToFilter
 * Removes the __typename from an `AppliedFilter` instance returned from GQL and returns a standard `Filter`
 * @param filter - the `AppliedFilter` from which the Apollo __typename field is being removed
 */
function addTypenameToFilter(filter: Filter): AppliedFilter {
    const nestedFilters = filter.filters || [];
    return {
        val: filter.val,
        expression: filter.expression,
        fieldName: filter.fieldName,
        filters: nestedFilters.map(addTypenameToFilter),
        __typename: "AppliedFilter",
    };
}

/**
 * buildSuggestedFilterFromFiltersInput
 * Accepts FiltersInput + queryName + queryDestination and returns a SuggestedFilter
 * Used by the AdvancedFiltersProvider to construct a SuggestedFilter that can be imported into the WorkflowEditor
 * @param props.filtersInput - The FiltersInput assigned to SuggestedFilter.filter
 * @param props.queryDestination - the queryDestination property on the resulting SuggestedFilter
 * @param props.queryName - the queryName property on the resulting SuggestedFilter
 */
export function buildSuggestedFilterFromFiltersInput(props: {
    filtersInput: FiltersInput;
    queryDestination: string;
    queryName: string;
}): SuggestedFilter {
    const { filtersInput, queryDestination, queryName } = props;

    // Defines AppliedFilters from props.filtersInput
    const appliedFilters: AppliedFilters = {
        defaults: filtersInput.defaults.map(addTypenameToFilter),
        advanced: filtersInput.advanced.map(
            (afi: AdvancedFilterInput): AppliedAdvancedFilter => {
                const nestedFilters = afi.filters || [];
                return {
                    key: afi.key,
                    filters: nestedFilters.map(addTypenameToFilter),
                    __typename: "AppliedAdvancedFilter",
                };
            }
        ),
        pagination: {
            ...DEFAULT_PAGINATION,
            sort: filtersInput.pagination.sort,
            direction: filtersInput.pagination.direction,
            __typename: "Pagination",
        },
        __typename: "AppliedFilters",
    };

    // Return SuggestedFilter built from appliedFilters + queryDestination + queryName
    // NOTE - organizationID has an arbitrary placeholder of 0 - this is replaced with the user's actual organizationID when it's loaded in the WorkflowEditor
    return {
        id: "",
        organizationID: 0,
        name: "",
        filters: appliedFilters,
        queryName,
        queryDestination,
        state: SuggestedFilterState.active,
        attributes: [],
        vendorID: null,
        vendor: null,
        content: {
            name: "",
            description: "",
        },
        labels: {
            defaults: [],
        },
    };
}

import {
    Filter,
    FilterExpression,
    FilterVal,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { getDefaultExpression } from "./getDefaultExpression";

// // // //

/**
 * buildFilterFromPossibleFilter
 * Builds a 'Filter' from a `PossibleFilter` instance
 * Used to create a new `Filter` for a `PossibleFilter` when no other is defined
 * @param props.possibleFilter - The PossibleFilter whose corresponding `Filter` we're building
 */
export function buildFilterFromPossibleFilter(props: {
    possibleFilter: PossibleFilter;
}): Filter {
    const { possibleFilter } = props;

    // Gets the value for Filter.expression
    const defaultExpression = getDefaultExpression(possibleFilter);

    // Assign correct default value of empty array if possibleFilter.defaultExpression is icontains/nicontains
    let defaultValue: FilterVal = "";
    if (
        [FilterExpression.icontains, FilterExpression.nicontains].includes(
            defaultExpression
        )
    ) {
        defaultValue = [];
    }

    return {
        fieldName: possibleFilter.key,
        expression: defaultExpression,
        val: defaultValue,
        filters: [],
    };
}

import classnames from "classnames";
import * as React from "react";
import { SearchIcon } from "../search_icon";
import styles from "./styles.module.css";

interface SingleChild {
    children: React.ReactNode;
}

type DropdownListItemProps = {
    active: boolean;
    // search text is the text where the keyword is searched for. if it doesn't contain the keyword, the item is not displayed.
    // This property is used only by the DropdownList component, so the eslint check below had to be disabled
    // eslint-disable-next-line react/no-unused-prop-types
    searchText?: string;
    className?: string;
    activeClassName?: string;
} & SingleChild;

/**
 * @description An individual dropdown list item that's used to render the dropdown list
 * @param {DropdownListItemProps} props
 */
export function DropdownListItem(props: DropdownListItemProps) {
    const { children, active, className = "", activeClassName = "" } = props;
    return (
        <li
            className={classnames({
                [styles.dropdownItem]: className === "",
                [styles.dropdownItemActive]: active && activeClassName === "",
                [className]: className !== "",
                [activeClassName]: active && activeClassName !== "",
            })}
        >
            {children}
        </li>
    );
}

// DropdownSearchBarProps are the props of the DropdownSearchBar component
interface DropdownSearchBarProps {
    onChangeKeyword: (s: string) => void;
    keyword: string;
    searchBarWrapperClassName?: string;
}

/**
 * @description Renders the search bar inside the dropdown
 * @param {DropdownSearchBarProps} props
 */
function DropdownSearchBar(props: DropdownSearchBarProps) {
    const {
        onChangeKeyword,
        keyword,
        searchBarWrapperClassName = styles.searchBarWrapper,
    } = props;
    return (
        <div
            className={classnames(
                searchBarWrapperClassName,
                "d-flex align-items-center"
            )}
        >
            <span className="pl-10-px">
                <SearchIcon />
            </span>
            <input
                onClick={(e) => {
                    // this is so that it doesn't propagate to the dropdown handler
                    e.stopPropagation();
                }}
                onChange={(e) => {
                    onChangeKeyword(e.currentTarget.value);
                }}
                value={keyword}
                type="text"
                placeholder="Search..."
                className={`font-secondary font-size-14-px py-8-px px-10-px border-none ${styles.searchBarInput}`}
            />
        </div>
    );
}

// EmptySearchProps are the props that should be passed to the EmptySearch component
interface EmptySearchProps {
    onClearKeyword: () => void;
    className?: string;
}

/**
 * @description Renders the empty search message inside the dropdown
 * @param {EmptySearchProps} props
 */
function EmptySearch(props: EmptySearchProps) {
    const { onClearKeyword, className = styles.emptySearchText } = props;
    return (
        <div className={classnames(styles.emptySearch, className)}>
            <p className="pb-5-px font-secondary font-size-14-px">
                No results found
            </p>
            <button
                className={classnames(
                    `p-0-px font-secondary font-size-11-px`,
                    styles.clearKeyword,
                    className
                )}
                onClick={(e) => {
                    onClearKeyword();
                    // this is so that it doesn't propagate to the dropdown handler and the dropdown stays open
                    e.stopPropagation();
                }}
                type="button"
            >
                clear query
            </button>
        </div>
    );
}

// Dropdown - your first component will be wrapped as the active dropdown item, the second component should be an array of dropdown list items that you want to render
export interface DropdownProps {
    children: React.ReactNode;
    // showSearchCount specifies the minimum number of options required to show the search bar. defaults to 5. null value hides the search bar
    showSearchCount?: number | null;
    className?: string;
    caretClassName?: string;
    emptySearchClassName?: string;
    activeWrapperClassName?: string;
    dropdownListWrapperClassName?: string;
    dropdownButtonOpenClassName?: string;
    searchBarWrapperClassName?: string;
    // openDirection - optional. Specifies the direction the dropdown menu opens (defaults to "down")
    openDirection?: string;
}

interface DropdownState {
    open: boolean;
    keyword: string;
}

// Dropdown openDirection prop constants
const OPEN_DIRECTION_UP = "up";
const OPEN_DIRECTION_DOWN = "down";

/**
 * @description Renders the body of the <Dropdown /> component - the parent component is only responsible for handling global mousedown events
 * This component will re-render anytime the props change This separation exists so the parent is
 * @param props.open - Whether or not the <Dropdown/> is open
 * @param props.setOpen - Callback to set value for props.open
 * @param props.keyword - the keyword used to filter dropdown options
 * @param props.setKeyword - Callback to set value for props.keyword
 * @param props.showSearchCount - See DropdownProps
 * @param props.openDirection - See DropdownProps
 * @param props.children - See DropdownProps
 */
function DropdownBody(props: {
    open: boolean;
    keyword: string;
    setOpen: (updatedOpen: boolean) => void;
    setKeyword: (updatedKeyword: string) => void;
    showSearchCount?: number | null;
    openDirection?: string;
    caretClassName?: string;
    emptySearchClassName?: string;
    searchBarWrapperClassName?: string;
    activeWrapperClassName?: string;
    dropdownListWrapperClassName?: string;
    dropdownButtonOpenClassName?: string;
    searchText?: string;
    children: React.ReactNode;
}) {
    const {
        open,
        keyword,
        setKeyword,
        emptySearchClassName,
        searchBarWrapperClassName,
        activeWrapperClassName = "border-grey bg-white",
        caretClassName = "text-grey-dim font-size-14-px",
        dropdownListWrapperClassName = `bg-white ${styles.dropdownGreyBorder}`,
        dropdownButtonOpenClassName,
    } = props;

    const {
        openDirection = OPEN_DIRECTION_DOWN,
        showSearchCount = 5,
        children,
    } = props;

    // find all dropdown items with searchText attribute set
    const items = children[1].filter(
        (item) => typeof item.props.searchText !== "undefined"
    );

    // filter

    // all items should have searchText set
    const searchable =
        showSearchCount !== null &&
        items.length >= showSearchCount &&
        items.length === children[1].length;

    // Defines array of visibleItems - default value is children[1]
    let visibleItems: React.ReactNode[] = children[1];

    // Filters for visibleItems based on keyword state
    // Only filters IFF searchable is true, and a keyword is defined
    if (keyword !== "" && searchable) {
        visibleItems = children[1].filter((c) => {
            const { searchText } = c.props;

            // Forces searchText casting as string for type-safety
            return String(searchText)
                .toLowerCase()
                .includes(keyword.toLowerCase());
        });
    }

    // Defines the JSX for the dropdown button
    const dropdownButton = (
        <div
            className={classnames({
                [activeWrapperClassName]: true,
                [styles.activeWrapper]: true,
                [dropdownButtonOpenClassName]: open,
                [styles.activeWrapperOpen]:
                    open && openDirection === OPEN_DIRECTION_DOWN,
                [styles.activeWrapperOpenUp]:
                    open && openDirection === OPEN_DIRECTION_UP,
            })}
            onClick={(e) => {
                // if the dropdown list is inside a link component we want to stop propogation and stop the default behavior of the browser
                // (if it keeps propogating our click handler will be triggered for the link route and the default browser link behavior will be triggered),
                // so we prevent both
                e.stopPropagation();
                e.preventDefault();

                props.setOpen(!open);
                setKeyword("");
            }}
            role="presentation"
        >
            {children[0]}
            <i
                className={classnames(`fa fa-caret-down`, caretClassName)}
                aria-hidden="true"
            />
        </div>
    );

    // Defines the JSX for the dropdown list
    const dropdownList = (
        <div
            className={classnames(
                styles.dropdownListWrapper,
                dropdownListWrapperClassName,
                {
                    [styles.slideIn]: open,
                    [styles.dropdownListWrapperUp]:
                        openDirection === OPEN_DIRECTION_UP,
                }
            )}
            onClick={(e) => {
                // if the dropdown list is inside a link component we want to stop propogation and stop the default behavior of the browser
                // (if it keeps propogating our click handler will be triggered for the link route and the default browser link behavior will be triggered)
                e.stopPropagation();
                e.preventDefault();
                props.setOpen(false);
            }}
            role="presentation"
        >
            <div>
                {searchable && (
                    <DropdownSearchBar
                        keyword={keyword}
                        onChangeKeyword={setKeyword}
                        searchBarWrapperClassName={searchBarWrapperClassName}
                    />
                )}
                <ul className={styles.dropdownList}>{visibleItems}</ul>
                {visibleItems.length === 0 && (
                    <EmptySearch
                        onClearKeyword={() => {
                            setKeyword("");
                        }}
                        className={emptySearchClassName}
                    />
                )}
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {openDirection === OPEN_DIRECTION_DOWN ? (
                <React.Fragment>
                    {dropdownButton}
                    {dropdownList}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {dropdownList}
                    {dropdownButton}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

// Dropdown is a component to display an item and when you click on the item it shows a list of other items.
// The Dropdown component only expects two children please see the types above.
// If you click anywhere on the page outside of the dropdown it will auto-close
export class Dropdown extends React.Component<DropdownProps, DropdownState> {
    public ref?: HTMLDivElement;

    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            open: false,
            keyword: "",
        };

        this.onMouseDown = this.onMouseDown.bind(this);
    }

    public componentDidMount() {
        document.addEventListener("mousedown", this.onMouseDown);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMouseDown);
    }

    public render() {
        const { open, keyword } = this.state;
        const {
            className = "",
            caretClassName,
            emptySearchClassName,
            activeWrapperClassName,
            dropdownListWrapperClassName,
            dropdownButtonOpenClassName,
            searchBarWrapperClassName,
            openDirection,
            children,
            showSearchCount,
        } = this.props;

        return (
            <div
                ref={(ref) => {
                    this.ref = ref;
                }}
                className={classnames({
                    [className]: className !== "",
                    [styles.dropdownWrapper]: true,
                })}
            >
                <DropdownBody
                    open={open}
                    caretClassName={caretClassName}
                    emptySearchClassName={emptySearchClassName}
                    activeWrapperClassName={activeWrapperClassName}
                    searchBarWrapperClassName={searchBarWrapperClassName}
                    dropdownListWrapperClassName={dropdownListWrapperClassName}
                    dropdownButtonOpenClassName={dropdownButtonOpenClassName}
                    keyword={keyword}
                    openDirection={openDirection}
                    showSearchCount={showSearchCount}
                    setKeyword={(updatedKeyword) => {
                        this.setState((prevState) => ({
                            open: prevState.open,
                            keyword: updatedKeyword,
                        }));
                    }}
                    setOpen={(updatedOpen) => {
                        this.setState((prevState) => ({
                            open: updatedOpen,
                            keyword: prevState.keyword,
                        }));
                    }}
                >
                    {children}
                </DropdownBody>
            </div>
        );
    }

    private onMouseDown(e: Event) {
        // close the dropdown on click outside
        // this check was required by flow
        if (!(e.target instanceof Node)) {
            return;
        }
        const isInsideDropdown = this.ref && this.ref.contains(e.target);
        if (!isInsideDropdown) {
            this.setState({ open: false, keyword: "" });
        }
    }
}

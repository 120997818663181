import React from "react";
import { Placement } from "@popperjs/core";

// // // //

// Export alias of PopperJS.Placement for use throughout the dashboard
export type TooltipPlacement = Placement;

/**
 * TooltipPlacements
 * Enum of TooltipPlacement values
 */
export enum TooltipPlacements {
    top = "top",
    bottom = "bottom",
    right = "right",
    left = "left",
    topStart = "top-start",
    topEnd = "top-end",
    bottomStart = "bottom-start",
    bottomEnd = "bottom-end",
    rightStart = "right-start",
    rightEnd = "right-end",
    leftStart = "left-start",
    leftEnd = "left-end",
    auto = "auto",
    autoStart = "auto-start",
    autoEnd = "auto-end",
}

/**
 * Render props for Tooltip.props.children
 * Used to support implementations a different general-purpose abstraction ontop of the Tooltip component
 */
export type TooltipRenderProps = (childProps: {
    setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}) => React.ReactNode;

import classnames from "classnames";
import * as React from "react";
import { Icon, IconTypes } from "@src/shared_modules/icon";

// // // //

// The possible types of alerts to be displayed
export enum AlertTypes {
    primary = "primary",
    success = "success",
    warning = "warning",
    danger = "danger",
}

// Props are the props that the alert accepts
export interface AlertProps {
    // type is the type of alert to display - all this does is change the color that's show. success - green, warning - yellow, danger - red
    type: keyof typeof AlertTypes;
    // removable whether the alert is removable and to display the remove icon
    removable: boolean;
    // text is the text to display
    text: React.ReactNode;
    // onRemove is called when the user clicks the remove icon
    onRemove?: () => void;
    // className is an optional className applied to the top-level Alert div
    className?: string;
}

// typeToSvg is a mapping of an alert type to the corresponding svg
const typeToSvg = {
    [AlertTypes.primary]: <Icon type={IconTypes.Primary_Arrow} />,
    [AlertTypes.success]: <Icon type={IconTypes.Success} />,
    [AlertTypes.warning]: <Icon type={IconTypes.Warning_V2} />,
    [AlertTypes.danger]: <Icon type={IconTypes.Danger_V2} />,
};

// typeToBackgroundColor - maps props.type to background color
const typeToBackgroundColor = {
    [AlertTypes.primary]: "bg-primary",
    [AlertTypes.success]: "bg-success",
    [AlertTypes.warning]: "bg-secondary",
    [AlertTypes.danger]: "bg-alert",
};

// typeToTextColor - maps props.type to text color
const typeToTextColor = {
    [AlertTypes.primary]: "text-primary",
    [AlertTypes.success]: "text-success",
    [AlertTypes.warning]: "text-secondary",
    [AlertTypes.danger]: "text-alert",
};

// typeToTextColor - maps props.type to border color
const typeToBorderColor = {
    [AlertTypes.primary]: "border-primary",
    [AlertTypes.success]: "border-success",
    [AlertTypes.warning]: "border-secondary",
    [AlertTypes.danger]: "border-alert",
};

// Alert is an alert component to display an alert on the page
export function Alert(props: AlertProps) {
    const { type, className = "" } = props;
    const svg = typeToSvg[type];
    return (
        <div className="d-block">
            <div
                role="alert"
                className={classnames(
                    className,
                    "tw-min-h-[80px]", // height
                    typeToBorderColor[type], // border-color
                    "rounded-sm !tw-border-l-[12px]", // border
                    "d-flex align-items-center bg-white tw-overflow-hidden"
                )}
            >
                <div className="d-flex align-items-center justify-content-between py-10-px px-20-px w-100">
                    <div className="d-flex align-items-center">
                        <span className="tw-w-10 tw-h-10">{svg}</span>
                        <p
                            className={`font-size-14-px font-secondary-bold ml-15-px ${typeToTextColor[type]}`}
                        >
                            {props.text}
                        </p>
                    </div>

                    {/* Render remove button IFF props.removable */}
                    {props.removable ? (
                        <div className="ml-10-px">
                            <button
                                className={classnames(
                                    "tw-rounded-full tw-outline-none tw-border-none", // Border
                                    "tw-opacity-90 hover:tw-opacity-100", // Opacity
                                    "tw-h-10 tw-w-10", // Sizing
                                    typeToBackgroundColor[props.type],
                                    "font-size-12-px text-white d-flex justify-content-center align-items-center"
                                )}
                                type="button"
                                onClick={(e) => {
                                    if (props.onRemove == null) {
                                        return;
                                    }
                                    // Use stopPropagation to prevent click events to close the Alert
                                    // from bubbling up into parent component onClick handlers
                                    e.stopPropagation();
                                    props.onRemove();
                                }}
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

import { Icon, IconTypes } from "@src/shared_modules/icon";
import styles from "./styles.module.css";

// // // //

interface Props {
    checked: boolean;
    onClick?: () => void;
    tabIndex?: number;
}

// CheckBox renders a checkbox
export function CheckBox(props: Props) {
    // we want onClick to be an empty function by default
    const { checked, onClick = () => {}, tabIndex = 0 } = props;
    return (
        <div
            role="checkbox"
            aria-checked={checked}
            onClick={onClick}
            className={`d-flex align-items-center justify-content-center ${styles.checkBox}`}
            tabIndex={tabIndex}
        >
            {checked ? (
                <Icon type={IconTypes.Checkmark} className={styles.checkMark} />
            ) : null}
        </div>
    );
}

import { Alert, AlertTypes } from "@src/shared_modules/alert";
import React from "react";
import {
    isDateFieldType,
    isDateFilterExpression,
} from "../advanced_filters/date_input";
import { isDeprecatedDateValue } from "../advanced_filters/date_input/isDeprecatedDateValue";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import { EditorFilter, FilterExpression, PossibleFilter } from "./graphql";
import { isFilterExpressionDynamic } from "./isFilterExpressionDynamic";

// // // //

/**
 * DynamicDateDeprecationAlert
 * Renders a deprecation alert when a user proceeds to edit an
 * existing filter that uses the deprecated dynamic date expressions
 * NOTE - this is only displayed when the user is:
 * - editing an existing filter, AND
 * - that filter uses a deprecated dynamic date expression, AND
 * - the associated PossibleFilter uses the new DateInput expressions, AND
 * - the presently selected FilterExpression is a deprecated dynamic date expression
 */
export function DynamicDateDeprecationAlert(props: {
    filter: EditorFilter;
    currentExpression: FilterExpression;
    possibleFilter: PossibleFilter | undefined;
}) {
    const { filter, currentExpression, possibleFilter } = props;
    // Defines a flag indicating whether or not to display a deprecation notice for the old dynamic date filters
    let renderDynamicDateDeprecationNotice: boolean = false;

    // Sets renderDynamicDateDeprecationNotice based on props
    if (
        possibleFilter &&
        isFilterExpressionDynamic(filter.expression) &&
        isFilterExpressionDynamic(currentExpression) &&
        possibleFilter.expressions.some(isDateFilterExpression)
    ) {
        renderDynamicDateDeprecationNotice = true;
    }

    // Render deprecation notice if date value is deprecated
    if (
        possibleFilter &&
        isDateFieldType(possibleFilter.fieldType) &&
        isDeprecatedDateValue({
            value: filter.val,
            expression: currentExpression,
        })
    ) {
        renderDynamicDateDeprecationNotice = true;
    }

    // Render null if renderDynamicDateDeprecationNotice is false
    if (renderDynamicDateDeprecationNotice === false) {
        return null;
    }

    // Renders the alert w/ content fetched from Contentful
    return (
        <FeatureContent uniqueKey="dynamic-date-deprecation-alert">
            {({ loading, feature }) => {
                if (loading) {
                    return null;
                }

                return (
                    <div className="mb-25-px">
                        <Alert
                            removable
                            type={AlertTypes.warning}
                            text={feature.header}
                        />
                    </div>
                );
            }}
        </FeatureContent>
    );
}

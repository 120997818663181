import * as React from "react";
import Hotkeys from "react-hot-keys";

// // // //

/**
 * Hotkey
 * Listens to keydown keyboard events and executes a callback when the provided key combination is detected
 * @param keyname - The combination to listen for (i.e. "shift+a")
 * @param onKeyDown - The callback to execute - accepts the KeyboardEvent that was fired off
 */
export function Hotkey(props: {
    keyname: string;
    onKeyDown: (keyboardEvent: KeyboardEvent) => void;
}) {
    return (
        // @ts-ignore
        <Hotkeys
            keyName={props.keyname}
            onKeyDown={(_: string, keyboardEvent: KeyboardEvent) => {
                props.onKeyDown(keyboardEvent);
            }}
        />
    );
}

import { publicRoutes } from "@src/routes";
import { Anchor } from "@src/shared_modules/router";
import * as React from "react";
import styles from "./trial_over_modal.module.css";

// // // //

/**
 * TrialOverModal
 * Renders a modal informing the user that their trial has expired
 */
export function TrialOverModal() {
    return (
        <section className={styles.modal}>
            <div
                className={`d-flex justify-content-center align-items-center ${styles.bodyWrapper}`}
            >
                <div className={`${styles.body}`}>
                    <h1 className={styles.header}>Your Trial Has Ended</h1>
                    <p className={styles.description}>
                        Upgrade now to unlock full visibility into your SaaS
                        apps
                    </p>
                    <ul
                        className={`d-flex flex-wrap justify-content-center ${styles.valuePropList}`}
                    >
                        <li
                            className={`d-flex align-items-center ${styles.valueProp}`}
                        >
                            <span className={styles.dot} />
                            Reduce Spend
                        </li>
                        <li
                            className={`d-flex align-items-center ${styles.valueProp}`}
                        >
                            <span className={styles.dot} />
                            Manage Licenses
                        </li>
                        <li
                            className={`d-flex align-items-center ${styles.valueProp}`}
                        >
                            <span className={styles.dot} />
                            Protect Your Data
                        </li>
                    </ul>
                    <Anchor
                        href={publicRoutes.scheduleDemo}
                        className={`btn-lg btn-primary font-secondary d-block ${styles.demoLink}`}
                        target="_blank"
                    >
                        Schedule a Demo Now
                    </Anchor>
                </div>
            </div>
        </section>
    );
}

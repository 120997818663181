import React from "react";

export function UsageRankDesktop(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="16"
            viewBox="0 0 19 16"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            >
                <g
                    stroke="currentColor"
                    strokeWidth="2"
                    transform="translate(-559 -435)"
                >
                    <g transform="translate(560 436)">
                        <path d="M1.12 10.64V1.12C1.12.501 1.622 0 2.24 0h12.32c.62 0 1.12.501 1.12 1.12v9.52M10.33 10.64a2.222 2.222 0 01-3.859 0H0v1.12A2.24 2.24 0 002.24 14h12.32a2.24 2.24 0 002.24-2.24v-1.12h-6.47z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { gql } from "graphql-tag";

// // // //

/**
 * Query for Integration Sync Status recipients
 */
export const INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY = gql`
    query INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY($orgID: Int64!) {
        organization(orgID: $orgID) {
            syncNotificationRecipients
        }
    }
`;

/**
 * Response interface for INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY
 */
export interface SyncStatusRecipientsFetcherResponse {
    organization: {
        syncNotificationRecipients: string[];
        __typename: "Organization";
    };
}

/**
 * Variables interface for INTEGRATION_SYNC_STATUS_RECIPIENTS_QUERY
 */
export interface SyncStatusRecipientsFetcherVariables {
    orgID: number;
}

import { captureMessage } from "@src/util/analytics";
import * as pako from "pako";

// // /// //

/**
 * compressParam
 * Accepts a single string parameter and return a compressed version.
 * Used to compress a long string (usually stringified JSON) to be stored in RouteState.params
 * If an error occurs for some reason, an empty string will be returned.
 * @param param - the string parameter that's being compressed
 */
export function compressParam(param: string): string {
    try {
        // Deflates param -> transforms into Uint8Array
        const deflatedValue: Uint8Array = pako.deflate(param);

        // Transforms the Uint8Array into a base64 string and returns
        // This step is necessary because we can't serialize a Unit8Array into the RouteState.params
        return btoa(String.fromCharCode.apply(null, deflatedValue));
    } catch (e) {
        // If an error occurs for some reason, invoke captureMessage
        captureMessage("compressParam - invalid param value", {
            extra: { param },
        });

        // Return empty string
        return "";
    }
}

import { Breadcrumbs } from "@src/shared_modules/breadcrumbs";
import {
    LinkCC,
    LinkCCProps,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { MaintenanceBannerComponent } from "@src/shared_modules/maintenance_banner";
import { TrialNotificationQuery } from "@src/shared_modules/nav";
import { StickyTop } from "@src/shared_modules/sticky";
import classnames from "classnames";
import * as React from "react";
import { FeatureFlagCC, FeatureNames } from "@src/shared_modules/feature_flag";
import styles from "./styles.module.css";

// // // //

export function SideNavPageLayout(
    props: { children: React.ReactNode } & { route: RouteState }
) {
    return (
        <React.Fragment>
            <MaintenanceBannerComponent />
            <TrialNotificationQuery />
            <FeatureFlagCC featureName={FeatureNames.all}>
                {(allFlags) => (
                    <Breadcrumbs route={props.route} featureFlags={allFlags} />
                )}
            </FeatureFlagCC>
            {props.children}
        </React.Fragment>
    );
}

export const SideNavPage: React.ComponentType<{
    children: React.ReactNode;
}> = withRouter(SideNavPageLayout);

// // // //

interface SettingsSubNavProps {
    fullWidth?: boolean;
    sidebarClassName?: string;
    children: [React.ReactElement, React.ReactElement];
}

// SettingsSubNav is the navigation for the settings pages
export function SideNavLayout(props: SettingsSubNavProps) {
    const className = classnames({
        "d-flex flex-column flex-grow-1": true,
        [styles.navBodyFullWidth]: props.fullWidth,
    });

    const sidebarClassName = classnames(
        "d-flex flex-column px-15-px",
        styles.sidebarWrapper,
        {
            [props.sidebarClassName]: props.sidebarClassName !== undefined,
        }
    );

    return (
        <div className="d-flex">
            <StickyTop className={sidebarClassName} name="usage-list-sticky">
                <div
                    className={`d-flex flex-column flex-grow-1 justify-content-between ${styles.sidebarContent}`}
                >
                    <React.Fragment>{props.children[0]}</React.Fragment>
                </div>
            </StickyTop>
            <div className={className}>
                <React.Fragment>{props.children[1]}</React.Fragment>
            </div>
        </div>
    );
}

// SideNavGroupProps are the props of the SideNavGroup component
interface SideNavGroupProps {
    header?: string;
    icon?: React.ReactNode;
    children: React.ReactNode;
}

// SideNavGroup is a group of side nav links
export function SideNavGroup(props: SideNavGroupProps) {
    const { children, icon, header = "" } = props;
    return (
        <div className="d-flex flex-column pb-20-px">
            {header !== "" && (
                <div className="d-flex flex-column">
                    <h4
                        className={`text-updated-black font-size-12-px font-primary-heavy pl-5-px d-flex align-items-center ${styles.groupHeader}`}
                    >
                        {icon && (
                            <div className={styles.iconWrapper}>{icon}</div>
                        )}
                        <div className="py-10-px">{header}</div>
                    </h4>
                </div>
            )}
            {children}
        </div>
    );
}

// SideNavLinkProps are the props of the SideNavLink component
interface SideNavLinkProps extends LinkCCProps {
    active?: boolean; // active allows to pass the active state of the link manually
}

// SideNavLink is a link inside the side navigation
export function SideNavLink(props: SideNavLinkProps) {
    const { active, className, ...linkProps } = props;
    const linkClass = classnames(
        "font-size-14-px d-flex align-items-center px-5-px rounded",
        {
            [styles.link]: true,
            [styles.linkActive]: active,
            className,
        }
    );
    return (
        <h4 className="pb-5-px d-flex flex-column">
            <LinkCC className={linkClass} {...linkProps}>
                {props.children}
            </LinkCC>
        </h4>
    );
}

import classnames from "classnames";
import * as React from "react";

// // // //

/**
 * DateInputButton
 * Renders a button used by the `DateInput` component for selecting presets (30/60/90/365/180/custom) or upcoming/past/fixed
 * @param props.label - The label rendered inside the <button />
 * @param props.active - Whether or not the button is active
 * @param props.onClick - Callback fired when the button is clicked
 * @param props.className - Optional className applied to the <button />
 */
export function DateInputButton(props: {
    label: string;
    active: boolean;
    onClick: () => void;
    className?: string;
}) {
    const { label, onClick, active, className = "" } = props;
    return (
        <button
            onClick={onClick}
            className={classnames(
                "px-10-px py-5-px rounded mx-5-px",
                "tw-outline-none tw-border-none",
                {
                    "bg-primary text-white": active,
                    "tw-bg-transparent text-grey-mid": !active,
                    [className]: className !== "",
                }
            )}
        >
            {label}
        </button>
    );
}

import { Loader } from "@src/shared_modules/loader";
import * as React from "react";
import { Provider } from "react-redux";
import { initStore } from "./store";

// // // //

// Flag indicating whether or not this component is being rendered on the server
const isServer = typeof window === "undefined";

// getOrCreateStore decides whether we're getting or creating a store
export function getOrCreateStore(initialState) {
    // Always make a new store if server, otherwise state is shared between requests
    if (isServer) {
        return {};
    }

    // Initializes the store using an empty object for initialState
    return initStore(initialState);
}

// Initializes the store using an empty object for initialState
const reduxStore = getOrCreateStore({});

// // // //

// Returns a Redux <Provider> component with requisite store prop
// This enables redux within our SPA code - taken and adapted from here:
// https://github.com/zeit/next.js/tree/canary/examples/with-redux
export function ReduxProvider(props: { children: React.ReactNode }) {
    // Implement useState + useEffect hook to prevent Next.js hydration error
    // Doc: https://nextjs.org/docs/messages/react-hydration-error
    const [ssr, setSsr] = React.useState(true);
    React.useEffect(() => setSsr(false), []); // Invoked when page hydrates in the browser

    // Return <Loader />` component component is rendered on the server
    if (ssr) {
        return <Loader />;
    }

    // Returns the Redux provider wrapped around props.children
    return <Provider store={reduxStore}>{props.children}</Provider>;
}

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { Query } from "react-apollo";
import { ConnectedApp } from "@src/schema/ConnectedApp";
import { ConnectedAppsList } from "@src/schema/ConnectedAppsList";
import { Pagination } from "@src/schema/Pagination";
import {
    CONNECTED_APPS_LIST_FETCHER_QUERY,
    ConnectedAppsListFetcherResponse,
    ConnectedAppsListFetcherVariables,
} from "./graphql";

// // // //

const EMPTY_LIST: ConnectedAppsList = {
    results: [],
    pagination: {
        pageCount: 0,
        rangeStart: 0,
        rangeEnd: 0,
        page: 1,
        totalItems: 0,
        itemsPerPage: 25,
        sort: "",
        direction: "",
        __typename: "Pagination",
    },
    __typename: "ConnectedAppsList",
};

interface Props {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        pagination: Pagination;
        connectedApps: ConnectedApp[];
        refetchConnectedApps: () => void;
    }) => React.ReactNode;
}

/**
 * @name ConnectedAppUserListFetcher
 * @description Fetches ConnectedAppsList via GQL using AdvancedFilters
 * @param {Props} props
 */
export function ConnectedAppsListFetcher(props: Props) {
    // IMPORTANT - we include `cache-and-network` fetchPolicy here to ensure that the Apollo Client
    // knows to render the updated data after we invoke `refetch`. This is necessary to handle a known bug in the Apollo Client
    // where a query dispatched without any changes in variables data will cause the loading state to hang if the fetchPolicy is `cache-only`.
    return (
        <Query<
            ConnectedAppsListFetcherResponse,
            ConnectedAppsListFetcherVariables
        >
            query={CONNECTED_APPS_LIST_FETCHER_QUERY}
            fetchPolicy="cache-and-network"
            variables={{
                filters: props.filtersInput,
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls ConnectedAppsList from data + assigns default value
                const list: ConnectedAppsList =
                    data?.connectedAppsService?.connectedApps?.list ||
                    EMPTY_LIST;

                // Safely pulls ConnectedApp[] + Pagination from ConnectedAppsList
                const connectedApps: ConnectedApp[] = list?.results || [];
                const pagination: Pagination =
                    list?.pagination || EMPTY_LIST.pagination;

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination,
                            connectedApps,
                            refetchConnectedApps: () => {
                                refetch();
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import { AppInfo } from "@src/schema/AppInfo";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { AppInfoListFetcher } from "@src/modules/usage/components/app_info/list_fetcher";
import {
    INSTANCE_ID_FIELD_NAME,
    SOURCE_APPLICATION_ID_FIELD_NAME,
} from "@src/modules/usage/components/app_info/advanced_filters/constants";
import { ResolverProps } from "./component";

// // // //

export function AppInfoResolverLayout(
    props: ResolverProps<AppInfo> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const instanceID: string | undefined = props.params?.instanceID;
    const sourceAppID: string | undefined = props.params?.sourceAppID;

    if (instanceID === undefined) {
        captureMessage("AppInfoResolver - params.instanceID is undefined");
        return null;
    }

    if (sourceAppID === undefined) {
        captureMessage("AppInfoResolver - params.sourceAppID is undefined");
        return null;
    }

    const { organizationID } = props.user;
    const filtersInput: FiltersInput = {
        organizationID,
        defaults: [
            {
                fieldName: INSTANCE_ID_FIELD_NAME,
                val: instanceID,
                expression: FilterExpression.eq,
                filters: [],
            },
            {
                fieldName: SOURCE_APPLICATION_ID_FIELD_NAME,
                val: sourceAppID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            sort: "",
            direction: "",
            page: 1,
            itemsPerPage: 25,
        },
    };

    return (
        <AppInfoListFetcher filtersInput={filtersInput}>
            {({ loading, appInfoList }) => {
                if (props.children) {
                    return props.children({ value: appInfoList[0], loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (!appInfoList.length) {
                    captureMessage(`AppInfo - not found`);
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return (
                    <React.Fragment>
                        {appInfoList[0].applicationName}
                    </React.Fragment>
                );
            }}
        </AppInfoListFetcher>
    );
}

export const AppInfoResolver: React.ComponentType<ResolverProps<
    AppInfo
>> = withUser(AppInfoResolverLayout);

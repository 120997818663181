import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";

// // // //

export const spendState = (_route: RouteState) => {
    return {
        enabled: true,
        active: true, // This will always be active since it is not a link
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Spend" },
        },
    };
};

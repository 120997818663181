import { MODAL_ACTION, ModalAction } from "./action";

export interface ModalState {
    activeName: string;
}

const defaultState: ModalState = {
    activeName: "",
};

function modalUpdate(_: ModalState, action: ModalAction): ModalState {
    return {
        activeName: action.name,
    };
}

export function modal(
    state: ModalState = defaultState,
    action: ModalAction
): ModalState {
    switch (action.type) {
        case MODAL_ACTION:
            return modalUpdate(state, action);
        default:
            return state;
    }
}

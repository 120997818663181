import { EventProps } from "@src/analytics/uncommons_track";
import ImageLoader from "@src/shared_modules/image_loader";
import { LinkCC } from "@src/shared_modules/router";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

interface Props {
    children: React.ReactNode;
    href: string | { pathname: string; query: {} };
    as?: string;
    eventName: string;
    eventProps: EventProps;
    name: string;
    category: string | null;
    logo: string;
    className?: string;
    footerComponent?: React.ReactNode;
}

// VendorTile renders a vendor tile that should be on a listing page
export function VendorTile(props: Props) {
    /* eslint jsx-a11y/alt-text: 0 */
    return (
        <li
            className={classnames(
                "col-12", // columns
                "tw-bg-white", // background
                "tw-relative", // position
                "tw-mt-8", // spacing
                "tw-rounded-xl border-grey-border", // border
                "tw-transition-all hover:tw-shadow-md" // hover state
            )}
        >
            <LinkCC
                href={props.href}
                as={props.as}
                className={`row d-flex align-items-center link ${
                    styles.tileContent
                } ${props.className || ""}`}
                eventName={props.eventName}
                eventProps={props.eventProps}
            >
                <div className="col-md-3 d-flex align-items-center tw-gap-8">
                    <div className="tw-w-24">
                        <ImageLoader
                            src={props.logo}
                            alt={`${props.name} logo`}
                            className="tw-rounded-md tw-max-h-12 tw-max-w-full tw-mr-6"
                        />
                    </div>
                    <div className="tw-flex tw-flex-col">
                        <h3 className={styles.name}>{props.name}</h3>
                        {props.category != null ? (
                            <p className={styles.category}>{props.category}</p>
                        ) : null}
                    </div>
                </div>
                {props.children}
            </LinkCC>
            {/* Renders props.footerComponent, if available */}
            {props.footerComponent && props.footerComponent}
        </li>
    );
}

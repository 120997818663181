import * as React from "react";

// // // //

/**
 * ColumnListEmptyProps
 * @param clearFilter - Clears the current Column filter
 */
interface ColumnListEmptyProps {
    clearFilter: () => void;
}

/**
 * ColumnListEmpty
 * Renders empty state for ColumnList - supports clearing the applied Column filter
 * @param props - see ColumnListEmptyProps
 */
export function ColumnListEmpty(props: ColumnListEmptyProps) {
    return (
        <ul>
            <li className="px-20-px py-20-px d-flex flex-column align-items-center font-size-14-px">
                <i className="fa fa-fw fa-lg fa-search mb-10-px" />
                No Columns found
                <small className="text-grey-mid mt-10-px font-size-12-px">
                    No results match the search criteria
                </small>
                <button
                    className="mt-10-px btn-link text-teriary font-size-14-px"
                    onClick={props.clearFilter}
                >
                    Clear Filter
                </button>
            </li>
        </ul>
    );
}

import { DashComponentNames } from "@src/analytics/dashboard";
import {
    appListing,
    integrationsCASB,
    integrationsMine,
    integrationsSecure,
    integrationsSpend,
    integrationsSSO,
    integrationsUsage,
    routes,
} from "@src/routes";
import { FeatureFlagCC, FeatureNames } from "@src/shared_modules/feature_flag";
import { LeftNavLayout } from "@src/shared_modules/left_nav";
import { MaintenanceBannerComponent } from "@src/shared_modules/maintenance_banner/component";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { SettingsNavLayout } from "@src/shared_modules/settings_nav";
import { UnauthorizedModalCC } from "@src/shared_modules/unauthorized_modal";
import { LoadUserCC } from "@src/shared_modules/user";
import classNames from "classnames";
import * as React from "react";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { Breadcrumbs } from "../breadcrumbs";
import { TrialNotificationQuery } from "./plan_trial_component";
import styles from "./styles.module.css";
import { IntegrationsModeFeatureFlag } from "../integrations_mode_feature_flag";

// // // //

interface NavLinkProps {
    name: string;
    text: string;
    route: string;
    svg: React.ReactNode;
    active?: boolean;
    className?: string;
}

function NavLink(props: NavLinkProps) {
    return (
        <LinkCC
            className={classNames(
                "tw-flex tw-flex-col tw-items-center font-primary-heavy",
                styles.navLink,
                {
                    [styles.navLinkActive]: props.active,
                },
                props.className
            )}
            href={props.route}
            componentName={props.name}
        >
            <div className={styles.navLinkIcon}>{props.svg}</div>
            {props.text}
        </LinkCC>
    );
}

// DashboardNavProps represents the props that should be passed to the DashboardNav component
export interface DashboardNavProps {
    // overviewActive should be set to true if we want the overview link to be highlighted
    overviewActive: boolean;
    // settingsActive should be set to true if we want the settings link to be highlighted
    settingsActive: boolean;
    // spendActive should be set to true if we want the "Spend" link to be highlighted
    spendActive: boolean;
    // complianceActive should be set to true if we want the "Compliance" link to be highlighted
    complianceActive: boolean;
    // vendorsActive should be set to true if we want the "Vendors" link to be highlighted
    vendorsActive: boolean;
    // usageActive should be set to true if we want the "Usage" link to be highlighted
    usageActive: boolean;
    // integrationsActive should be set to true if we want the "Integrations" link to be highlighted
    integrationsActive: boolean;
    // workflowsActive should be set to true if we want the "Workflows" link to be highlighted
    workflowsActive: boolean;
}

// renders the left-hand dashboard nav
export function DashboardNav(props: DashboardNavProps) {
    return (
        <nav
            className={`${styles.dashboardNav} d-flex flex-column flex-shrink-0 justify-content-between`}
        >
            <div>
                <div className={styles.logoWrapper}>
                    <LinkCC
                        className="d-flex align-items-center justify-content-center py-20-px px-10-px"
                        href={routes.overview}
                        componentName={DashComponentNames.navLogo}
                    >
                        <div className="tw-w-20 tw-transition-transform hover:tw-scale-105">
                            <Icon type={IconTypes.Sailpoint_White_Coin} />
                        </div>
                    </LinkCC>
                </div>
                <IntegrationsModeFeatureFlag>
                    <>
                        <NavLink
                            name={DashComponentNames.nav}
                            route={routes.overview}
                            svg={<Icon type={IconTypes.Dashboard} />}
                            text="Overview"
                            active={props.overviewActive}
                        />
                        <NavLink
                            name={DashComponentNames.nav}
                            route={routes.vendorListing}
                            svg={<Icon type={IconTypes.Applications} />}
                            text="Vendors"
                            active={props.vendorsActive}
                        />

                        <FeatureFlagCC featureName={FeatureNames.all}>
                            {(allFlags) => {
                                const usageNavRoute: string =
                                    allFlags[FeatureNames.enableUsageV2] ===
                                    true
                                        ? routes.usageApplications
                                        : routes.usage;
                                return (
                                    <NavLink
                                        name={DashComponentNames.nav}
                                        route={usageNavRoute}
                                        svg={<Icon type={IconTypes.Usage} />}
                                        text="Usage"
                                        active={props.usageActive}
                                    />
                                );
                            }}
                        </FeatureFlagCC>

                        <NavLink
                            name={DashComponentNames.secCompListing}
                            route={routes.connectedAppsListing}
                            svg={<Icon type={IconTypes.Auth} />}
                            text="Secure"
                            active={props.complianceActive}
                        />

                        <NavLink
                            name={DashComponentNames.nav}
                            route={appListing()}
                            svg={<Icon type={IconTypes.Spend} />}
                            text="Spend"
                            active={props.spendActive}
                        />
                    </>
                </IntegrationsModeFeatureFlag>
                <NavLink
                    name={DashComponentNames.nav}
                    route={routes.integrations}
                    svg={<Icon type={IconTypes.Integrations} />}
                    text="Integrations"
                    active={props.integrationsActive}
                />

                {/* Workflows are now known as Reports */}
                <IntegrationsModeFeatureFlag>
                    <NavLink
                        name={DashComponentNames.nav}
                        route={routes.workflowList}
                        svg={<Icon type={IconTypes.Workflows} />}
                        text="Reports"
                        active={props.workflowsActive}
                        className="text-center"
                    />
                </IntegrationsModeFeatureFlag>
            </div>

            <IntegrationsModeFeatureFlag variation={false}>
                <NavLink
                    name={DashComponentNames.nav}
                    route={routes.manageOrganizationFields}
                    svg={<Icon type={IconTypes.Settings} />}
                    text="Settings"
                    active={props.settingsActive}
                />
            </IntegrationsModeFeatureFlag>

            <IntegrationsModeFeatureFlag variation={true}>
                <NavLink
                    name={DashComponentNames.nav}
                    route={routes.settings.users}
                    svg={<Icon type={IconTypes.Settings} />}
                    text="Settings"
                    active={props.settingsActive}
                />
            </IntegrationsModeFeatureFlag>
        </nav>
    );
}

export interface NavLayoutProps {
    route: RouteState;
    children: React.ReactNode;
    settingsActive?: boolean;
}

// NavLayout
// Top-level layout used on all application pages (except /settings and auth pages)
export function NavLayout(props: NavLayoutProps) {
    // Add to `nonFullWidthPages` to opt out of full page layout
    const nonFullWidthPages: string[] = [
        routes.settings.customFields,
        routes.settings.demo,
        routes.settings.editOrgField,
        routes.settings.editTeam,
        routes.settings.fieldUpload,
        routes.settings.newOrgField,
        routes.settings.newTeam,
        routes.settings.saml,
        routes.settings.set_team_department,
        routes.settings.teams,
    ];

    // Handles pages where the "Spend" nav link should be highlighted
    const spendPages: string[] = [
        routes.appListing,
        routes.paymentListing,
        routes.spendIndividual,
        routes.spendIndividualChart,
        routes.userIndividualSpend,
        routes.userIndividualSpendChart,
        routes.purchaseOrderListing,
        routes.purchaseOrderDetails,
        routes.purchaseOrderVendorListing,
        routes.purchaseOrderVendorDetails,
    ];

    // Handles pages where the "Compliance" / "Secure" nav link should be highlighted
    const compliancePages: string[] = [
        routes.globalRoles,
        routes.globalRoleAssignments,
        routes.roleAssignmentsIndividual,
        routes.rolesIndividual,
        routes.connectedAppsListing,
        routes.connectedAppsIndividualUsers,
        routes.connectedAppsIndividualApplications,
    ];

    // Handles pages where the "Vendors" nav link should be highlighted
    const vendorsPages: string[] = [
        routes.vendorListing,
        routes.vendorListingManageColumns,
        routes.vendorListingManageFilters,
        routes.appFieldIndividual,
    ];

    // Handles pages where the "Usage" nav link should be highlighted
    const usagePages: string[] = [
        routes.usage,
        routes.usageApplications,
        routes.usageIndividual,
        routes.usageIndividualColumnSettings,
        routes.teamIndividual,
        routes.usageSuggestedFilters,
        routes.usageSavedViews,
        routes.userIndividualUsage,
        routes.vendorUsageAppListing,
        routes.vendorUsageAppIndividual,
        routes.vendorUsageAppIndividualTimeseries,
    ];

    // Handles pages where the "Integrations" nav link should be highlighted
    const integrationsPages: string[] = [
        routes.integrations,
        integrationsUsage,
        integrationsSpend,
        integrationsSecure,
        integrationsSSO,
        integrationsCASB,
        integrationsMine,
        routes.integrationIndividualDash,
        routes.integrationIndividualAuth,
        routes.integrationIndividualSuccess,
    ];

    // Handles pages where the "Reports" nav link should be highlighted
    const workflowsPages: string[] = [
        routes.workflowNew,
        routes.workflowEdit,
        routes.workflowList,
        routes.suggestedWorkflows,
        routes.workflowDestinations,
        routes.terminatedUsersReportList,
        routes.terminatedUsersReportIndividual,
        routes.terminatedUsersReportBucketIndividual,
        routes.terminatedUsersReportNew,
        routes.terminatedUsersReportIndividualConfigure,
    ];

    // Pages where Sidebar is not rendered
    const noSidebarPages: string[] = [
        routes.overview,
        routes.vendorListing,
        routes.vendorListingManageColumns,
        routes.vendorListingManageFilters,
        routes.teams,
        routes.users,
    ];

    // Define flags for sidebar + background color + fullWidth layout settings
    const noSidebar: boolean = noSidebarPages.includes(
        props.route.nextPathname
    );
    const isFullWidth: boolean = !nonFullWidthPages.includes(
        props.route.nextPathname
    );

    // Define flags for active DashboardNav items
    const spendActive: boolean = spendPages.includes(props.route.nextPathname);
    const complianceActive: boolean = compliancePages.includes(
        props.route.nextPathname
    );
    const vendorsActive: boolean = vendorsPages.includes(
        props.route.nextPathname
    );
    const usageActive: boolean = usagePages.includes(props.route.nextPathname);
    const integrationsActive: boolean = integrationsPages.includes(
        props.route.nextPathname
    );
    const workflowsActive: boolean = workflowsPages.includes(
        props.route.nextPathname
    );

    return (
        <UnauthorizedModalCC allowedStates={["admin", "live"]}>
            <LoadUserCC>
                <LeftNavLayout
                    className="d-flex flex-column flex-grow-1"
                    navItems={
                        <DashboardNav
                            settingsActive={props.settingsActive}
                            spendActive={spendActive}
                            complianceActive={complianceActive}
                            vendorsActive={vendorsActive}
                            usageActive={usageActive}
                            integrationsActive={integrationsActive}
                            workflowsActive={workflowsActive}
                            overviewActive={
                                props.route.nextPathname === routes.overview
                            }
                        />
                    }
                >
                    <React.Fragment>
                        <div
                            className={classNames("bg-background-main", {
                                [styles.dashboardWrapperFullWidth]: true,
                            })}
                        >
                            {props.settingsActive ? (
                                <SettingsNavLayout fullWidth={isFullWidth}>
                                    {props.children}
                                </SettingsNavLayout>
                            ) : (
                                <React.Fragment>
                                    {noSidebar && (
                                        <React.Fragment>
                                            <MaintenanceBannerComponent />
                                            <TrialNotificationQuery />
                                            <FeatureFlagCC
                                                featureName={FeatureNames.all}
                                            >
                                                {(allFlags) => (
                                                    <Breadcrumbs
                                                        route={props.route}
                                                        featureFlags={allFlags}
                                                    />
                                                )}
                                            </FeatureFlagCC>
                                        </React.Fragment>
                                    )}
                                    <div
                                        className={classNames({
                                            [styles.dashboardWrapper]: !isFullWidth,
                                            "d-flex flex-column flex-grow-1": isFullWidth,
                                        })}
                                    >
                                        {props.children}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                </LeftNavLayout>
            </LoadUserCC>
        </UnauthorizedModalCC>
    );
}

export const DashboardNavLayout = withRouter(NavLayout);

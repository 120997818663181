import { InputSize } from "@src/shared_modules/input";
import { DatePicker } from "@src/shared_modules/datepicker";
import { getFormattedDateValue } from "@src/util/date_util";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";
import { DateInputValue } from "./types";

// // // //

/**
 * getFixedDateValue
 * Accepts start + end strings and returns an acceptable value for DateInput
 * @param props.start - the start of the Fixed Date
 * @param props.end - the end of the Fixed Date
 */
export function getFixedDateValue(props: {
    start: string;
    end: string;
}): [string] | [string, string] {
    const { start, end } = props;

    // Get formatted start + end values
    const startValue = getFormattedDateValue(start);
    const endValue = getFormattedDateValue(end);

    // If neither startValue nor endValue are defined -> return ["", ""]
    if (startValue === "" && endValue === "") {
        return ["", ""];
    }

    // If startValue is defined, and endValue is NOT -> return [startValue]
    if (startValue !== "" && endValue === "") {
        return [startValue];
    }

    // If endValue is defined, and startValue is NOT -> return ["", endValue]
    if (startValue === "" && endValue !== "") {
        return ["", endValue];
    }

    // If startValue equals endValue -> return [startValue]
    if (startValue === endValue) {
        return [startValue];
    }

    // If startValue is GREATER than endValue -> flip and return [endValue, startValue]
    // NOTE - this works because ISO dates sort legicographically (i.e. like normal strings)
    if (startValue > endValue) {
        return [endValue, startValue];
    }

    // Return both startValue and endValue
    return [startValue, endValue];
}

// // // //

/**
 * FixedDateInput
 * Renders requisite inputs for modeling a fixed date range
 * @param props
 */
export function FixedDateInput(props: {
    value: DateInputValue;
    setValue: (updatedValue: DateInputValue) => void;
}) {
    const { value } = props;

    // Safely defines start + end date values
    let startDate: string = "";
    let endDate: string = "";

    // Defines start + end date from props.value
    if (value[0] && typeof value[0] === "string") {
        startDate = String(value[0]);
    }

    if (value[1] && typeof value[1] === "string") {
        endDate = String(value[1]);
    }

    return (
        <div
            className={classnames(
                "d-flex align-items-center flex-wrap mt-10-px",
                styles.inputContainer
            )}
        >
            <div className="d-flex flex-grow-1 justify-content-center mt-2">
                <DatePicker
                    size={InputSize.lg}
                    hideIcon={false}
                    ignoreStateValue
                    date={startDate}
                    id="date-input-start"
                    numberOfMonths={1}
                    placeholder="Start date"
                    onChange={(updatedStartDate) => {
                        props.setValue(
                            getFixedDateValue({
                                start: updatedStartDate,
                                end: endDate,
                            })
                        );
                    }}
                />
            </div>

            <div className="d-flex flex-grow-1 justify-content-center mt-2">
                <DatePicker
                    size={InputSize.lg}
                    hideIcon={false}
                    ignoreStateValue
                    date={endDate}
                    id="date-input-end"
                    numberOfMonths={1}
                    placeholder="End date"
                    onChange={(updatedEndValue) => {
                        props.setValue(
                            getFixedDateValue({
                                start: startDate,
                                end: updatedEndValue,
                            })
                        );
                    }}
                />
            </div>
        </div>
    );
}

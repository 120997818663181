import { captureMessage } from "@src/util/analytics";
import * as React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from "react-beautiful-dnd";
import { ColumnListItem } from "./ColumnListItem";
import { sortColumnSettings } from "./sortColumnSettings";
import { Column, ColumnSetting } from "./types";

// // // //

// Helper function for re-ordering columns
// Acceps an array, the index of the element that's going to move,
// and that element's destination index. Returns the re-ordered array.
export function reorder<T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] {
    const ordered: T[] = Array.from(list);
    const [removed] = ordered.splice(startIndex, 1);
    ordered.splice(endIndex, 0, removed);
    return ordered;
}

/**
 * ColumnListProps
 * @param columns - The array of `Column` instances
 * @param columnSettings - The array of user `ColumnSetting` instances
 * @param disableReorder - Dictates whether or not columnSettings may be reordered
 * @param disableToggle - Dictates whether or not columnSettings may be toggled
 * @param onChange - callback invoked when columSettings change (reorder or toggle visible)
 */
interface ColumnListProps {
    columns: Column[];
    columnSettings: ColumnSetting[];
    disableReorder: boolean;
    disableToggle: boolean;
    onChange: (updatedColumnSettings: ColumnSetting[]) => void;
}

/**
 * ColumnList
 * Renders drag+drop list of editable ColumnSetting[] data
 * @param props - see ColumnListProps
 */
export function ColumnList(props: ColumnListProps) {
    const { columns, columnSettings, disableReorder, disableToggle } = props;

    return (
        <DragDropContext
            onDragEnd={(result: DropResult) => {
                // Dropped outside the list
                if (!result.destination) {
                    return;
                }

                // Re-order the columns
                let reorderedColumnSettings: ColumnSetting[] = reorder<
                    ColumnSetting
                >(
                    columnSettings,
                    result.source.index,
                    result.destination.index
                );

                // Sort again based on ColumnSetting.stickyLeft and ColumnSetting.expandableRowToggler
                reorderedColumnSettings = sortColumnSettings({
                    columnSettings: reorderedColumnSettings,
                });

                // Updates the state
                props.onChange(reorderedColumnSettings);
            }}
        >
            <Droppable droppableId="advanced-filters-manage-columns">
                {(droppableProvided: DroppableProvided) => (
                    <ul
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                    >
                        {columnSettings.map((columnSetting, index) => {
                            // Finds columnSetting associated with Column
                            const column: Column | undefined = columns.find(
                                (c) => c.key === columnSetting.key
                            );

                            // NOTE - this component assumes that props.columns is populated 1-to-1 with props.columns,
                            // so we captureMessage + return null here if the associated Column isn't found
                            if (!column) {
                                captureMessage(
                                    "AdvancedFilters - ColumnList -> Column not found"
                                );
                                return null;
                            }

                            return (
                                <Draggable
                                    key={column.content.name}
                                    draggableId={column.content.name}
                                    index={index}
                                >
                                    {(
                                        provided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot
                                    ) => (
                                        <ColumnListItem
                                            column={column}
                                            columnSetting={columnSetting}
                                            provided={provided}
                                            snapshot={snapshot}
                                            disableReorder={disableReorder}
                                            disableToggle={disableToggle}
                                            onChange={(
                                                updatedColumSetting: ColumnSetting
                                            ) => {
                                                // Defines updatedColumnSettings
                                                const updatedColumnSettings: ColumnSetting[] = columnSettings.map(
                                                    (c) => {
                                                        if (
                                                            c.key ===
                                                            updatedColumSetting.key
                                                        ) {
                                                            return updatedColumSetting;
                                                        }
                                                        return c;
                                                    }
                                                );

                                                // Invokes props.onChange with updatedColumnSettings
                                                props.onChange(
                                                    updatedColumnSettings
                                                );
                                            }}
                                        />
                                    )}
                                </Draggable>
                            );
                        })}
                        {droppableProvided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}

import classnames from "classnames";
import * as React from "react";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { Vendor } from "./graphql";

// // // //

interface RelatedVendorsButtonProps {
    vendor: Vendor;
    onClick: () => void;
    className?: string;
}

/**
 * RelatedVendorsButton
 * Responsible for rendering the button used to open the RelatedVendorsModal component
 * Can be styled through props.className
 * @param props - see `RelatedVendorsButtonProps`
 */
export function RelatedVendorsButton(props: RelatedVendorsButtonProps) {
    const { vendor, className = "" } = props;
    const [hovered, setHovered] = React.useState<boolean>(false);
    return (
        <button
            className={classnames(
                "btn-md btn-stroked-primary font-secondary-bold font-size-12-px px-15-px",
                {
                    [className]: className !== "",
                }
            )}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                // Stop propagation of click event since this component is
                // rendered inside <UsageListItem /> (which is all wrapped in LinkCC)
                e.preventDefault();
                e.stopPropagation();

                props.onClick();
            }}
            onMouseEnter={() => {
                setHovered(true);
            }}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            <div className="d-flex align-items-center">
                {!hovered && <Icon type={IconTypes.Vendor_Family_Primary} />}
                {hovered && <Icon type={IconTypes.Vendor_Family_White} />}
                <p className="ml-10-px">Family: {vendor.name}</p>
            </div>
        </button>
    );
}

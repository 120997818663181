import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { OrgTag, OrgTagInput } from "./types";

// // // //
// OrgTagsFetcher interfaces + query

/**
 * OrgTagsFetcherRequest
 * Request interface for OrgTagsFetcher
 */
export interface OrgTagsFetcherRequest {
    filters: FiltersInput;
}

/**
 * OrgTagsFetcherResponse
 * Response interface for OrgTagsFetcher
 */
export interface OrgTagsFetcherResponse {
    workflows: {
        orgTags: {
            list: {
                results: OrgTag[];
            };
        };
    };
}

/**
 * ORG_TAGS_FETCHER_QUERY
 * Query for OrgTagsFetcher
 */
export const ORG_TAGS_FETCHER_QUERY = gql`
    query ORG_TAGS_FETCHER_QUERY($filters: FiltersInput!) {
        workflows {
            orgTags {
                list(filters: $filters) {
                    results {
                        id
                        name
                        description
                        organizationID
                        totalOrgSavedViews
                    }
                }
            }
        }
    }
`;

/**
 * buildOrgTagsFetcherRequest
 * Builds OrgTagsFetcherRequest for OrgTagsFetcher
 */
export function buildOrgTagsFetcherRequest(props: {
    organizationID: number;
}): OrgTagsFetcherRequest {
    const { organizationID } = props;
    return {
        filters: {
            defaults: [],
            advanced: [],
            organizationID,
            pagination: {
                page: 1,
                itemsPerPage: 1000, // Fetch _all_ OrgTags
                sort: "name",
                direction: "asc",
            },
        },
    };
}

// // // //
// SaveOrgTagMutation mutation

/**
 * SAVE_ORG_TAG_MUTATION
 * Mutation for SaveOrgTagMutation component
 */
export const SAVE_ORG_TAG_MUTATION = gql`
    mutation SAVE_ORG_TAG_MUTATION($input: OrgTagInput!) {
        saveOrgTag(input: $input) {
            id
            name
            description
            organizationID
            totalOrgSavedViews
        }
    }
`;

/**
 * SaveOrgTagMutationRequest
 * Request interface for OrgTagsFetcher
 */
export interface SaveOrgTagMutationRequest {
    input: OrgTagInput;
}

/**
 * SaveOrgTagMutationResponse
 * Request interface for OrgTagsFetcher
 */
export interface SaveOrgTagMutationResponse {
    saveOrgTag: OrgTag;
}

// // // //
// DeleteOrgTagMutation mutation

/**
 * DELETE_ORG_TAG_MUTATION
 * Mutation for DeleteOrgTagMutation component
 */
export const DELETE_ORG_TAG_MUTATION = gql`
    mutation DELETE_ORG_TAG_MUTATION($input: OrgTagInput) {
        deleteOrgTag(input: $input) {
            id
            name
            description
            organizationID
            totalOrgSavedViews
        }
    }
`;

// // // //
// Apollo Cache Update

/**
 * OrgTagFetcherCacheValue
 * Defines interface for Apollo InMemoryCache value for ORG_TAGS_FETCHER_QUERY
 * Used by the SaveOrgTagMutation to update the Apollo cache without refetching data
 */
export interface OrgTagFetcherCacheValue {
    workflows: {
        orgTags: {
            list: {
                results: Array<OrgTag & { __typename: "OrgTag" }>;
                __typename: "OrgTagsList";
            };
            __typename: "OrgTagsListing";
        };
        __typename: "Workflows";
    };
}

/**
 * buildOrgTagFetcherCacheValue
 * Helper function to build an OrgTagFetcherCacheValue instance from an array of OrgTag[]
 * @param orgTags
 */
export function buildOrgTagFetcherCacheValue(
    orgTags: Array<OrgTag & { __typename: "OrgTag" }>
): OrgTagFetcherCacheValue {
    return {
        workflows: {
            orgTags: {
                list: {
                    results: orgTags,
                    __typename: "OrgTagsList",
                },
                __typename: "OrgTagsListing",
            },
            __typename: "Workflows",
        },
    };
}

/**
 * addOrgTagToApolloCache
 * Adds a newly created OrgTag to the Apollo cahce. Used by the `SaveOrgTagMutation`
 * @param props.cache - the Apollo InMemoryCache
 * @param props.organizationID - the orgID of the current user
 * @param props.newOrgTag - the new OrgTag being added to Apollo InMemoryCache
 */
export function addOrgTagToApolloCache(props: {
    cache: InMemoryCache;
    organizationID: number;
    orgTag: OrgTag;
}): void {
    const { cache, organizationID, orgTag } = props;

    // Defines variables for readQuery + writeQuery
    const variables: OrgTagsFetcherRequest = buildOrgTagsFetcherRequest({
        organizationID,
    });

    // Reads the ORG_TAGS_FETCHER_QUERY GQL query data from the cache
    const cachedData = cache.readQuery<OrgTagFetcherCacheValue>({
        query: ORG_TAGS_FETCHER_QUERY,
        variables,
    });

    // Attempt to find the OrgTag in the Apollo cache
    // This step is necessary when _updating_ an existing OrgTag
    const isNew: boolean = cachedData.workflows.orgTags.list.results.every(
        (tag) => tag.id !== orgTag.id
    );

    // If orgTag is new -> insert into the Apollo cache and return
    // Updates the cache using cache.writeQuery
    if (isNew) {
        props.cache.writeQuery<OrgTagFetcherCacheValue>({
            query: ORG_TAGS_FETCHER_QUERY,
            variables,
            data: buildOrgTagFetcherCacheValue([
                ...cachedData.workflows.orgTags.list.results,
                {
                    ...orgTag,
                    __typename: "OrgTag",
                },
            ]),
        });
        return;
    }

    // Updates cachedData for insertion back into the Apollo cache
    props.cache.writeQuery<OrgTagFetcherCacheValue>({
        query: ORG_TAGS_FETCHER_QUERY,
        variables,
        data: buildOrgTagFetcherCacheValue(
            cachedData.workflows.orgTags.list.results.map((tag) => {
                if (tag.id === orgTag.id) {
                    return {
                        ...tag,
                        name: orgTag.name,
                        description: orgTag.description,
                    };
                }
            })
        ),
    });
}

import {
    AdvancedFilterInput,
    AppliedAdvancedFilter,
    AppliedFilter,
    AppliedFilters,
    Filter,
    FiltersInput,
    OffsetPaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";

// // // //

/**
 * stripTypenameFromAppliedFilter
 * Removes the __typename from an `AppliedFilter` instance returned from GQL and returns a standard `Filter`
 * @param filter - the `AppliedFilter` from which the Apollo __typename field is being removed
 */
export function stripTypenameFromAppliedFilter(filter: AppliedFilter): Filter {
    const nestedFilters = filter.filters || [];
    return {
        val: filter.val,
        expression: filter.expression,
        fieldName: filter.fieldName,
        filters: nestedFilters.map((f: AppliedFilter) =>
            stripTypenameFromAppliedFilter(f)
        ),
    };
}

/**
 * stripTypenameFromAppliedFilters
 * Removes the __typename from an `AppliedFilters` instance returned from GQL and returns a standard `FiltersInput`
 * @param appliedFilters - the `AppliedFilters` instance from which the Apollo __typename field is being removed
 * @param organizationID - the `User.organizationID` that's required to define a valid `FiltersInput` instance
 */
export function stripTypenameFromAppliedFilters(
    appliedFilters: AppliedFilters,
    organizationID: number
): FiltersInput {
    // Assigns default values in case defaults/advanced are null/undefined
    const defaults = appliedFilters.defaults || [];
    const advanced = appliedFilters.advanced || [];

    const filtersInput: FiltersInput = {
        organizationID,
        defaults: defaults.map((f: AppliedFilter) =>
            stripTypenameFromAppliedFilter(f)
        ),
        advanced: advanced.map(
            (afi: AppliedAdvancedFilter): AdvancedFilterInput => {
                const nestedFilters = afi.filters || [];
                return {
                    key: afi.key,
                    filters: nestedFilters.map((f: AppliedFilter) =>
                        stripTypenameFromAppliedFilter(f)
                    ),
                };
            }
        ),
    };

    // Apply pagination, if it's defined
    if (appliedFilters.pagination) {
        filtersInput.pagination = {
            page: (appliedFilters.pagination as OffsetPaginationInput).page,
            itemsPerPage: appliedFilters.pagination.itemsPerPage,
            sort: appliedFilters.pagination.sort,
            direction: appliedFilters.pagination.direction,
        };
    }

    // Return filtersInput
    return filtersInput;
}

import {
    ContentfulQuery,
    getContentfulDashboardEntries,
} from "@src/requests/content/contentful";
import { Tooltips } from "@src/shared_modules/tooltips";
import { SidebarData } from "@src/modules/usage/components/usage_sidebar/UsageSidebar";
import { SetTeamDepartmentPageState } from "@src/modules/teams/pages/set_team_department/component";
import { Entry, EntryCollection } from "contentful";
import get from "lodash.get";
import { MaintenanceBannerState } from "../shared_modules/maintenance_banner/component";

// // // //

// Asset represents asset fields returned by contentful
interface Asset {
    title: string;
    description: string;
    file: {
        url: string;
        details: any;
        fileName: string;
        contentType: string;
    };
}

// EmptyOverviewTile represents the empty overview tile returned by contentful
export interface RawEmptyOverviewTile {
    icon: Entry<Asset>;
    text: string;
    integrations: Array<Entry<Asset>>;
    linkText: string;
}

// EmptyOverviewTile represents the normalized empty overview tile
export interface EmptyOverviewTile {
    iconSrc: string;
    iconTitle: string;
    text: string;
    integrations: Array<{
        name: string;
        slug: string;
        logoLink: string;
    }>;
    linkText: string;
}

// RawIntegrationFeature is an integration feature returned by contentful
export interface RawIntegrationFeature {
    name: string;
    logo: Entry<Asset>;
    description: string;
    location: string;
    image: Entry<Asset>;
    fieldName: string;
    slug: string;
}

// IntegrationFeature is a normalized integration feature
export interface IntegrationFeature {
    name: string;
    slug: string;
    logoSrc: string;
    description: string;
    location: string;
    imageSrc: string | null;
    fieldName: string;
}

// RawFeature is a feature returned by contentful
interface RawFeature {
    header: string;
    description: string;
    image: Entry<Asset>;
    tag: string;
    key: string;
    features?: Array<Entry<RawFeature>>;
}

// Feature is a normalized feature
export interface Feature {
    header: string;
    description: string;
    imageSrc: string;
    features: Feature[];
}

// RawFeatureGroup is a feature group returned by contentful
export interface RawFeatureGroup {
    name: string;
    header: string;
    description: string;
    features: Array<Entry<RawFeature>>;
}

// FeatureGroup represents a normalized feature group
export interface FeatureGroup {
    name: string;
    header: string;
    description: string;
    features: Feature[];
}

// // // //

// "Caches" the `MaintenanceBanner` data fetched from Contentful
let cachedMaintenanceBanner: MaintenanceBannerState = {
    title: "",
    message: "",
    visible: false,
};

// Fetch the `MaintenanceBanner` content
export async function loadMaintenanceBanner(): Promise<MaintenanceBannerState> {
    // Returns cachedMaintenanceBanner, if it's already been defined
    if (cachedMaintenanceBanner.title !== "") {
        return cachedMaintenanceBanner;
    }

    try {
        // Fetch the data one-time-only
        const query: ContentfulQuery = {
            content_type: "maintenanceBanner",
        };
        const response: EntryCollection<MaintenanceBannerState> = await getContentfulDashboardEntries<
            MaintenanceBannerState
        >(query);

        cachedMaintenanceBanner = response.items[0].fields;
        return cachedMaintenanceBanner;
    } catch (e) {
        // Return empty object in case of failed contentful call
        return cachedMaintenanceBanner;
    }
}

// loadEmptyOverviewTile loads the content of an empty overview tile from contentful by its name
export async function loadEmptyOverviewTile(
    name: string
): Promise<EmptyOverviewTile> {
    try {
        const query: ContentfulQuery = {
            content_type: "emptyOverviewTile",
            "fields.name": name,
        };
        const response = await getContentfulDashboardEntries<
            RawEmptyOverviewTile
        >(query);

        // Safely pulls fields from repsonse
        const rawFields: RawEmptyOverviewTile = get(
            response,
            "items[0].fields",
            {}
        );

        // Safely pulls data from rawFields
        const { text, linkText } = rawFields;
        const iconSrc = get(rawFields, "icon.fields.file.url", "");
        const iconTitle = get(rawFields, "icon.fields.title", "");
        const integrations = get(rawFields, "integrations", []);

        return {
            text,
            linkText,
            iconSrc,
            iconTitle,
            integrations: integrations.map((integration) => ({
                name: integration.fields.title,
                slug: integration.fields.description,
                logoLink: integration.fields.file.url,
            })),
        };
    } catch (e) {
        return {
            iconSrc: "",
            iconTitle: "",
            text: "",
            integrations: [],
            linkText: "",
        };
    }
}

// // // //

export const EMPTY_USAGE_LISTING_PAGE_DATA: SidebarData = {
    integrationTypes: [],
    sidebarAboutSectionTitle: "",
    sidebarAboutSectionBody: "",
    sidebarAboutSectionLink: "",
    modalTitle: "",
    modalText: "",
    modalCloseButtonText: "",
    modalMoreInfoButtonText: "",
    modalItems: [],
    allRanksLabel: "",
    allRanksDescription: "",
};

// "Caches" the `cachedUsagePageCopy` data fetched from Contentful
let cachedUsagePageCopy: any;

// Fetch the `UsageListingPage` content and
// cache the result to prevent reduntant calls to Contentful
export async function loadUsageListingPage(): Promise<SidebarData> {
    // Returns cachedUsagePageCopy, if it's already been defined
    if (cachedUsagePageCopy) {
        return cachedUsagePageCopy;
    }

    try {
        const query = {
            content_type: "usageListingPage",
        };
        const entries = await getContentfulDashboardEntries(query);
        cachedUsagePageCopy = entries.items[0].fields;
        cachedUsagePageCopy.integrationTypes = cachedUsagePageCopy.integrationTypes.map(
            (i) => i.fields
        );
        cachedUsagePageCopy.modalItems = cachedUsagePageCopy.modalItems.map(
            (i) => ({ ...i.fields, image: i.fields.image.fields.file.url })
        );
        return cachedUsagePageCopy;
    } catch (e) {
        return EMPTY_USAGE_LISTING_PAGE_DATA;
    }
}

/**
 * normalizeFeature
 * Normalizes a raw feature received from contentful
 * @param feature - The raw feature to normalize
 */
function normalizeFeature(feature: Entry<RawFeature>): Feature {
    return {
        header: get(feature, "fields.header", ""),
        description: get(feature, "fields.description", ""),
        imageSrc: get(feature, "fields.image.fields.file.url", ""),
        features: get(feature, "fields.features", []).map(normalizeFeature),
    };
}

// loadFeatureGroups loads and normalizes a feature group by its name
export async function loadFeatureGroup(name: string): Promise<FeatureGroup> {
    const query = {
        content_type: "featureGroup",
        "fields.name": name,
    };
    const featureGroup = (
        await getContentfulDashboardEntries<RawFeatureGroup>(query)
    ).items[0];
    const features: Array<Entry<RawFeature>> = get(
        featureGroup,
        "fields.features",
        []
    );
    return {
        ...featureGroup.fields,
        features: features.map(normalizeFeature),
    };
}

// Default Response for loadSetTeamDepartmentPage fallback
export const DEFAULT_EMPTY_SET_TEAM_DEPARTMENT_PAGE_CONTENT: SetTeamDepartmentPageState = {
    features: [],
    featuresHeader: "",
    integrationFeatures: {
        name: {
            name: "",
            slug: "",
            logoSrc: "",
            description: "",
            location: "",
            imageSrc: "",
            fieldName: "",
        },
    },
    loading: false,
};

// loadSetTeamDepartmentPage loads the content for set team department page
export async function loadSetTeamDepartmentPage(): Promise<
    SetTeamDepartmentPageState
> {
    try {
        // send the requests in parallel
        const featureGroupResponse = loadFeatureGroup(
            "teamIntegrationSettings"
        );
        const integrationFeaturesResponse = getContentfulDashboardEntries<
            RawIntegrationFeature
        >({
            content_type: "integrationFeature",
            "fields.tag": "teamIntegrationSettings",
        });
        // await the responses
        const featureGroup = await featureGroupResponse;
        const integrationFeatures = await integrationFeaturesResponse;
        const normalizedIntegrationFeatures = integrationFeatures.items.reduce(
            (result, integrationFeature) => {
                result[integrationFeature.fields.name] = {
                    name: integrationFeature.fields.name,
                    slug: integrationFeature.fields.slug,
                    logoSrc: integrationFeature.fields.logo.fields.file.url,
                    description: integrationFeature.fields.description,
                    location: integrationFeature.fields.location,
                    imageSrc: integrationFeature.fields.image
                        ? integrationFeature.fields.image.fields.file.url
                        : null,
                    fieldName: integrationFeature.fields.fieldName,
                };
                return result;
            },
            {}
        );
        return {
            features: featureGroup.features,
            featuresHeader: featureGroup.header,
            integrationFeatures: normalizedIntegrationFeatures,
            loading: false,
        };
    } catch (e) {
        return DEFAULT_EMPTY_SET_TEAM_DEPARTMENT_PAGE_CONTENT;
    }
}

/**
 * loadTableTooltips
 * Loads header tooltips for a table by its name
 * @param tableName - the name of the table, should be set in the `tag` field in contentful
 */
export async function loadTableTooltips(tableName: string): Promise<Tooltips> {
    try {
        const featuresResponse = getContentfulDashboardEntries<RawFeature>({
            content_type: "feature",
            "fields.tag": tableName,
        });
        const features = (await featuresResponse).items;
        return features.reduce((results, feature) => {
            if (feature.fields.key !== undefined) {
                results[feature.fields.key] = feature.fields.description;
            }
            return results;
        }, {});
    } catch (e) {
        // Return empty object in event of failed call
        return {};
    }
}

/**
 * loadFeature
 * Loads and normalizes a feature from contentful by its unique key
 * @param uniqueKey - the unique key to search for
 */
export async function loadFeature(uniqueKey: string): Promise<Feature> {
    try {
        const featureResponse = getContentfulDashboardEntries<RawFeature>({
            content_type: "feature",
            "fields.uniqueKey": uniqueKey,
        });
        const feature = (await featureResponse).items[0];

        // Return empty feature if feature not found
        if (feature === undefined) {
            return {
                header: "",
                description: "",
                imageSrc: "",
                features: [],
            };
        }

        // Return normalized feature
        return normalizeFeature(feature);
    } catch (e) {
        return {
            header: "",
            description: "",
            imageSrc: "",
            features: [],
        };
    }
}

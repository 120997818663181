import React from "react";

export function PrimaryArrow(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#005bc0"
                    fillRule="nonzero"
                    transform="translate(-119 -83)"
                >
                    <g transform="translate(95 56)">
                        <g transform="translate(24 27)">
                            <path d="M4.396 12c0 .505.404.91.91.91h6.088v3.183c0 .328.177.631.48.808a.83.83 0 00.43.101.91.91 0 00.504-.151l6.367-4.093a.958.958 0 00.43-.783.9.9 0 00-.43-.783l-6.367-4.068a.89.89 0 00-.934-.025.905.905 0 00-.48.808v3.184H5.305a.906.906 0 00-.91.909zm8.842-2.425L17.002 12l-3.764 2.425v-4.85z"></path>
                            <path d="M0 12c0 6.619 5.381 12 12 12s12-5.381 12-12S18.619 0 12 0 0 5.381 0 12zm1.844 0C1.844 6.392 6.392 1.844 12 1.844c5.608 0 10.156 4.548 10.156 10.156 0 5.608-4.548 10.156-10.156 10.156-5.608 0-10.156-4.548-10.156-10.156z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import React from "react";

export function Workflows(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#FFF"
                    fillRule="nonzero"
                    transform="translate(-36 -483)"
                >
                    <g transform="translate(36 483)">
                        <path d="M14.37 10.737h-1.194a.187.187 0 01-.188-.188V7.447c0-.104.084-.188.188-.188h1.193a3.63 3.63 0 10-3.63-3.63v1.193a.187.187 0 01-.188.187H7.449a.188.188 0 01-.188-.187V3.628a3.63 3.63 0 10-3.63 3.631h1.193c.104 0 .188.084.188.188v3.103a.187.187 0 01-.188.187H3.631a3.63 3.63 0 103.63 3.63v-1.193c0-.103.084-.187.188-.187h3.102c.104 0 .188.084.188.188v1.193a3.63 3.63 0 103.63-3.631zm-1.381-7.109a1.38 1.38 0 111.38 1.381h-1.193a.187.187 0 01-.188-.187V3.628zm-10.739 0a1.38 1.38 0 112.761 0v1.194a.188.188 0 01-.187.187H3.631c-.762 0-1.38-.618-1.381-1.38zm2.761 10.74a1.38 1.38 0 11-1.38-1.381h1.193c.104 0 .188.084.188.188v1.193zm2.25-6.921c0-.104.084-.188.188-.188h3.102c.104 0 .188.084.188.188v3.103a.187.187 0 01-.188.187H7.449a.187.187 0 01-.188-.188V7.447zm7.108 8.303a1.383 1.383 0 01-1.38-1.38v-1.195c0-.104.083-.188.187-.188h1.193a1.38 1.38 0 110 2.76v.003z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import {
    FieldType,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * DateRangeSeparatorProps
 * @param index - The user's filter state
 * @param possibleFilters - Array of `PossibleFilter` instances from the `defaults` property on `Filters
 * @param className - (optional) className to style the `<span/>`
 */
interface DateRangeSeparatorProps {
    index: number;
    possibleFilters: PossibleFilter[];
    className?: string;
}

/**
 * shouldRenderDateRangeSeparator
 * Returns a boolean flag indicating whether or not to render a separator between two `FilterFormValueInput`
 * Checks the `PossibleFilter` located at `possibleFilters[index] and checks
 * - possibleFilters[i] is a `date_range`
 * - possibleFilters[i + 1] is ALSO a `date_range`
 * - possibleFilters[i] && possibleFilters[i+1] is share the same value for their `key` property
 * If these three checks pass, we know that the we should render a separator JXS element between
 * the pair of linked `FilterFormValueInput` components we render below.
 * @param props.index - index of `props.possibleFilters` we're inspecting
 * @param props.possibleFilters - array of PossibleFilter instances to check
 */
export function shouldRenderDateRangeSeparator(
    props: DateRangeSeparatorProps
): boolean {
    const { index, possibleFilters } = props;

    // Defines currentPossibleFilter and nextPossibleFilter
    const currentPossibleFilter: PossibleFilter = possibleFilters[index];
    const nextPossibleFilter: PossibleFilter | undefined =
        possibleFilters[index + 1];

    // Return false if nextPossibleFilter is undefined
    if (!nextPossibleFilter) {
        return false;
    }

    // Checks:
    // possibleFilters[i] is a `date_range`
    // possibleFilters[i + 1] is ALSO a `date_range`
    // possibleFilters[i] && possibleFilters[i+1] is share the same value for their `key` property
    return (
        currentPossibleFilter.fieldType === FieldType.date_range &&
        nextPossibleFilter.fieldType === FieldType.date_range &&
        currentPossibleFilter.key === nextPossibleFilter.key
    );
}

/**
 * DateRangeSeparator
 * @param props - see `DateRangeSeparatorProps`
 */
export function DateRangeSeparator(props: DateRangeSeparatorProps) {
    const { className = "" } = props;
    if (shouldRenderDateRangeSeparator(props)) {
        return (
            <span
                className={classnames({
                    "bg-grey-dim": true,
                    [styles.dateRangeSeparator]: true,
                    [className]: className !== "",
                })}
            />
        );
    }
    return null;
}

import { isDateFieldType } from "@src/modules/advanced_filters/components/advanced_filters/date_input";
import { FilterExpressionSymbol } from "@src/modules/advanced_filters/components/advanced_filters/FilterExpressionSymbol";
import classnames from "classnames";
import React from "react";
import { isWeekFieldType } from "../advanced_filters/week_input/isWeekInputDataValid";
import { FilterValue } from "./FilterValue";
import {
    EditorFilter,
    Filter,
    FilterExpression,
    PossibleFilter,
} from "./graphql";
import { PossibleFilterContent } from "./PossibleFilterContent";
import styles from "./styles.module.css";

// // // //

/**
 * FilterListItemSummary
 * Displays a filter's fieldName / expression / val
 * @param props.filter - a single `Filter` instance
 * @param props.possibleFilter - the `PossibleFilter` corresponding to `props.filter` - is `undefined` when `props.filter.expression` is and/or
 */
export function FilterListItemSummary(props: {
    filter: Filter | EditorFilter;
    possibleFilter: PossibleFilter | undefined;
}) {
    const { filter, possibleFilter } = props;

    // Render null for Filter.expression == and / or
    if (
        [FilterExpression.and, FilterExpression.or].includes(filter.expression)
    ) {
        return null;
    }

    // Defines className for summary badges
    const badgeClassName = classnames(
        "border-grey-border background-white px-10-px py-10-px",
        [styles.circularBadge]
    );

    // Defines flag indicating whether or not the FilterExpression is used with the DateInput/WeekInput
    const isDate: boolean =
        isDateFieldType(possibleFilter.fieldType) ||
        isWeekFieldType(possibleFilter.fieldType);

    // Render fieldName/expression/value
    return (
        <React.Fragment>
            <div className={badgeClassName}>
                <PossibleFilterContent
                    possibleFilter={possibleFilter}
                    tooltipPosition="right"
                />
            </div>

            {/* Only render FilterExpressionSymbol for non-date Filters */}
            {!isDate && (
                <div className={classnames(badgeClassName, "mx-10-px")}>
                    <FilterExpressionSymbol
                        className="font-size-12-px text-black font-secondary whitespace-nowrap cursor-default"
                        filterExpression={filter.expression}
                        tooltip
                    />
                </div>
            )}

            {/* Only render FilterValue IFF filter.expression is NOT isdefined || isundefined */}
            {![
                FilterExpression.isdefined,
                FilterExpression.isundefined,
            ].includes(filter.expression) && (
                <div
                    className={classnames(badgeClassName, {
                        "ml-10-px": isDate,
                    })}
                >
                    <FilterValue
                        className="font-size-12-px text-black font-secondary-bold whitespace-nowrap"
                        value={filter.val}
                        filterExpression={filter.expression}
                        possibleFilter={possibleFilter}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

import { routes } from "@src/routes";
import {
    roleAssignmentsIndividualNode,
    roleAssignmentsNode,
    rolesIndividualNode,
    rolesNode,
} from "./access_control";
import { apiKeysNode } from "./apiKeys";
import { appInfoNode } from "./appFieldIndividual";
import { appListingNode } from "./appListing";
import { connectedAppsListingNode } from "./connected_apps";
import {
    integrationIndividualAuthNode,
    integrationIndividualDashNode,
    integrationsNode,
} from "./integrations";
import { paymentListingNode } from "./paymentListing";
import { purchaseOrderDetailsNode } from "./purchaseOrderDetails";
import { purchaseOrdersNode } from "./purchaseOrders";
import { purchaseOrderVendorDetailsNode } from "./purchaseOrderVendorDetails";
import { purchaseOrderVendorListingNode } from "./purchaseOrderVendorListing";
import {
    accessControlReconciliationNode,
    accessControlVendorRoleUploadsNewNode,
    accessControlVendorRoleUploadsNode,
    settingsAuditLogNode,
    settingsCustomFieldsEditNode,
    settingsCustomFieldsNewNode,
    settingsCustomFieldsNode,
    settingsPaymentUploadsNode,
    settingsSAMLNode,
    settingsSetTeamDepartmentNode,
    settingsTeamsNode,
    settingsUsersNode,
} from "./settings";
import { spendIndPaymentsNode } from "./spendInd";
import { spendIndChartNode } from "./spendIndChart";
import { teamIndNode } from "./teamInd";
import { BreadcrumbNode } from "./types";
import {
    usageApplicationsNode,
    usageOverviewNode,
    usageSavedViewsNode,
    usageSuggestedFiltersNode,
} from "./usage";
import { usageIndividualApplicationsNode } from "./usageIndividual";
import {
    userIndSpendChartNode,
    userIndSpendNode,
    usersNode,
    userUsageNode,
} from "./users";
import { vendorConnectedAppIndividualNode } from "./vendorConnectedAppIndividual";
import { vendorConnectedAppListingNode } from "./vendorConnectedAppListing";
import { vendorUsageAppIndNode } from "./vendorUsageAppInd";
import { vendorUsageAppIndTimeseriesNode } from "./vendorUsageAppIndTimeseries";
import { vendorUsageAppListingNode } from "./vendorUsageAppListing";
import { webhookIndividualNode } from "./webhookIndividual";
import { webhooksNewNode } from "./webhookNew";
import { webhooksNode } from "./webhooks";
import {
    suggestedWorkflowsNode,
    terminatedUserReportListNode,
    terminatedUserReportNewNode,
    terminatedUsersReportIndividualNode,
    terminatedUsersReportIndividualConfigureNode,
    workflowDestinationsNode,
    workflowEditNode,
    workflowListNode,
    workflowNewNode,
    terminatedUsersReportBucketIndividualNode,
} from "./workflows";

// // // //

/**
 * RouteToCrumb shape
 * Enforces explicit addition of new breadcrumb routes
 */
export type RouteToBreadcrumbMap = {
    // Usage
    [routes.usage]: BreadcrumbNode;
    [routes.usageApplications]: BreadcrumbNode;
    [routes.usageSuggestedFilters]: BreadcrumbNode;
    [routes.usageSavedViews]: BreadcrumbNode;
    [routes.usageIndividual]: BreadcrumbNode;
    [routes.teamIndividual]: BreadcrumbNode;
    [routes.vendorUsageAppListing]: BreadcrumbNode;
    [routes.vendorUsageAppIndividual]: BreadcrumbNode;
    [routes.vendorUsageAppIndividualTimeseries]: BreadcrumbNode;
    // Connected Apps
    [routes.connectedAppsListing]: BreadcrumbNode;
    [routes.connectedAppsIndividualApplications]: BreadcrumbNode;
    [routes.connectedAppsIndividualUsers]: BreadcrumbNode;
    // App Fields (applications/[vendorID]/fields#[ref])
    [routes.appFieldIndividual]: BreadcrumbNode;
    // Spend
    [routes.appListing]: BreadcrumbNode;
    [routes.spendIndividual]: BreadcrumbNode;
    [routes.spendIndividualChart]: BreadcrumbNode;
    [routes.paymentListing]: BreadcrumbNode;
    // Purchase Orders
    [routes.purchaseOrderListing]: BreadcrumbNode;
    [routes.purchaseOrderVendorListing]: BreadcrumbNode;
    [routes.purchaseOrderDetails]: BreadcrumbNode;
    [routes.purchaseOrderVendorDetails]: BreadcrumbNode;
    // Settings
    [routes.settings.users]: BreadcrumbNode;
    [routes.settings.paymentUploads]: BreadcrumbNode;
    [routes.settings.teams]: BreadcrumbNode;
    [routes.settings.newTeam]: BreadcrumbNode;
    [routes.settings.editTeam]: BreadcrumbNode;
    [routes.settings.saml]: BreadcrumbNode;
    [routes.settings.auditLog]: BreadcrumbNode;
    [routes.settings.customFields]: BreadcrumbNode;
    [routes.settings.newOrgField]: BreadcrumbNode;
    [routes.settings.editOrgField]: BreadcrumbNode;
    [routes.settings.set_team_department]: BreadcrumbNode;
    [routes.settings.accessControlReconciliation]: BreadcrumbNode;
    [routes.settings.accessControlVendorRolesUploadsListing]: BreadcrumbNode;
    [routes.settings.accessControlVendorRolesUploadsNew]: BreadcrumbNode;
    [routes.settings.apiKeys]: BreadcrumbNode;
    // Webhooks
    [routes.settings.webhooks]: BreadcrumbNode;
    [routes.settings.webhookNew]: BreadcrumbNode;
    [routes.settings.webhookIndividual]: BreadcrumbNode;
    // Integrations
    [routes.integrations]: BreadcrumbNode;
    [routes.integrationIndividualDash]: BreadcrumbNode;
    [routes.integrationIndividualAuth]: BreadcrumbNode;
    // Workflows
    [routes.workflowList]: BreadcrumbNode;
    [routes.workflowNew]: BreadcrumbNode;
    [routes.workflowEdit]: BreadcrumbNode;
    [routes.workflowDestinations]: BreadcrumbNode;
    [routes.suggestedWorkflows]: BreadcrumbNode;
    // Reports - Terminated Users
    [routes.terminatedUsersReportList]: BreadcrumbNode;
    [routes.terminatedUsersReportNew]: BreadcrumbNode;
    [routes.terminatedUsersReportIndividual]: BreadcrumbNode;
    [routes.terminatedUsersReportIndividualConfigure]: BreadcrumbNode;
    [routes.terminatedUsersReportBucketIndividual]: BreadcrumbNode;
    // Access Control
    [routes.globalRoles]: BreadcrumbNode;
    [routes.globalRoleAssignments]: BreadcrumbNode;
    [routes.rolesIndividual]: BreadcrumbNode;
    [routes.roleAssignmentsIndividual]: BreadcrumbNode;
    // Users
    [routes.users]: BreadcrumbNode;
    [routes.userIndividualUsage]: BreadcrumbNode;
    [routes.userIndividualSpend]: BreadcrumbNode;
    [routes.userIndividualSpendChart]: BreadcrumbNode;
};

/**
 * Map of route.nextPathname to a BreadcrumbNode
 */
export const breadcrumbNodeMap: RouteToBreadcrumbMap = {
    // Usage
    [routes.usage]: usageOverviewNode,
    [routes.usageApplications]: usageApplicationsNode,
    [routes.usageSuggestedFilters]: usageSuggestedFiltersNode,
    [routes.usageSavedViews]: usageSavedViewsNode,
    [routes.usageIndividual]: usageIndividualApplicationsNode,
    [routes.teamIndividual]: teamIndNode,
    [routes.vendorUsageAppListing]: vendorUsageAppListingNode,
    [routes.vendorUsageAppIndividual]: vendorUsageAppIndNode,
    [routes.vendorUsageAppIndividualTimeseries]: vendorUsageAppIndTimeseriesNode,
    // Connected Apps
    [routes.connectedAppsListing]: connectedAppsListingNode,
    [routes.connectedAppsIndividualApplications]: vendorConnectedAppListingNode,
    [routes.connectedAppsIndividualUsers]: vendorConnectedAppIndividualNode,
    // App Fields
    [routes.appFieldIndividual]: appInfoNode,
    // Spend
    [routes.appListing]: appListingNode,
    [routes.spendIndividual]: spendIndPaymentsNode,
    [routes.spendIndividualChart]: spendIndChartNode,
    [routes.paymentListing]: paymentListingNode,
    // Purchase Orders
    [routes.purchaseOrderListing]: purchaseOrdersNode,
    [routes.purchaseOrderVendorListing]: purchaseOrderVendorListingNode,
    [routes.purchaseOrderDetails]: purchaseOrderDetailsNode,
    [routes.purchaseOrderVendorDetails]: purchaseOrderVendorDetailsNode,
    // Settings
    [routes.settings.users]: settingsUsersNode,
    [routes.settings.paymentUploads]: settingsPaymentUploadsNode,
    [routes.settings.teams]: settingsTeamsNode,
    [routes.settings.newTeam]: settingsTeamsNode,
    [routes.settings.editTeam]: settingsTeamsNode,
    [routes.settings.saml]: settingsSAMLNode,
    [routes.settings.auditLog]: settingsAuditLogNode,
    [routes.settings.customFields]: settingsCustomFieldsNode,
    [routes.settings.newOrgField]: settingsCustomFieldsNewNode,
    [routes.settings.editOrgField]: settingsCustomFieldsEditNode,
    [routes.settings.set_team_department]: settingsSetTeamDepartmentNode,
    [routes.settings
        .accessControlReconciliation]: accessControlReconciliationNode,
    [routes.settings
        .accessControlVendorRolesUploadsListing]: accessControlVendorRoleUploadsNode,
    [routes.settings
        .accessControlVendorRolesUploadsNew]: accessControlVendorRoleUploadsNewNode,
    [routes.settings.apiKeys]: apiKeysNode,
    // Webhooks
    [routes.settings.webhooks]: webhooksNode,
    [routes.settings.webhookNew]: webhooksNewNode,
    [routes.settings.webhookIndividual]: webhookIndividualNode,
    // Integrations
    [routes.integrations]: integrationsNode,
    [routes.integrationIndividualDash]: integrationIndividualDashNode,
    [routes.integrationIndividualAuth]: integrationIndividualAuthNode,
    // Workflows
    [routes.workflowList]: workflowListNode,
    [routes.workflowNew]: workflowNewNode,
    [routes.workflowEdit]: workflowEditNode,
    [routes.workflowDestinations]: workflowDestinationsNode,
    [routes.suggestedWorkflows]: suggestedWorkflowsNode,
    // Reports - Terminated Users
    [routes.terminatedUsersReportList]: terminatedUserReportListNode,
    [routes.terminatedUsersReportNew]: terminatedUserReportNewNode,
    [routes.terminatedUsersReportIndividual]: terminatedUsersReportIndividualNode,
    [routes.terminatedUsersReportIndividualConfigure]: terminatedUsersReportIndividualConfigureNode,
    [routes.terminatedUsersReportBucketIndividual]: terminatedUsersReportBucketIndividualNode,
    // Access Control
    [routes.globalRoles]: rolesNode,
    [routes.globalRoleAssignments]: roleAssignmentsNode,
    [routes.rolesIndividual]: rolesIndividualNode,
    [routes.roleAssignmentsIndividual]: roleAssignmentsIndividualNode,
    // Users
    [routes.users]: usersNode,
    [routes.userIndividualUsage]: userUsageNode,
    [routes.userIndividualSpend]: userIndSpendNode,
    [routes.userIndividualSpendChart]: userIndSpendChartNode,
};

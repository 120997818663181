import * as React from "react";
import { ColumnSetting } from "./types";

// // // //

/**
 * ManageColumnsStateProps
 * @param props.columnSettings - The array of user `ColumnSetting` instances
 * @param props.children - Function that accepts `unsavedColumnSettings` + `setColumnSettings` and returns React.ReactNode
 */
interface ManageColumnsStateProps {
    columnSettings: ColumnSetting[];
    children: (childProps: {
        unsavedColumnSettings: ColumnSetting[];
        setColumnSettings: (updatedColumnSettings: ColumnSetting[]) => void;
    }) => React.ReactNode;
}

/**
 * ManageColumnsState
 * A small wrapper that exposes a React.useState hook for storing unsaved ColumnSetting[] data used by the ManageColumns component
 * @param props - see ManageColumnsStateProps
 */
export function ManageColumnsState(props: ManageColumnsStateProps) {
    // Store the local ColumnSetting[] state
    const [columnSettings, setColumnSettings] = React.useState<ColumnSetting[]>(
        props.columnSettings
    );

    // Resets columnSettings state when props.columnSettings change
    React.useEffect(() => {
        setColumnSettings(props.columnSettings);
    }, [props.columnSettings]);

    // Render props.children
    return (
        <React.Fragment>
            {props.children({
                unsavedColumnSettings: columnSettings,
                setColumnSettings,
            })}
        </React.Fragment>
    );
}

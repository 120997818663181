import { BreadcrumbNode } from "../types";
import { spendIndChartState } from "./state";
import { spendIndNode } from "../spendInd";

// // // //

export const spendIndChartNode: BreadcrumbNode = {
    getState: spendIndChartState,
    parent: spendIndNode,
};

import { Dispatch } from "redux";
import { getList, ListApi } from "@src/requests/list";
import { captureException } from "@src/util/analytics";
export const LOADING_ACTION = "__dashboard__listing_loading";
export const RESULTS_ACTION = "__dashboard__listing_results";
export const RESET_URL_ACTION = "__dashboard__listing_reset";
export const ADD_ITEM_ACTION = "__dashboard__listing_add_item";
export const UPDATE_ITEM_ACTION = "__dashboard__listing_update_item";
export const DELETE_ITEM_ACTION = "__dashboard__listing_delete_item";

interface Action {
    type: string;
}

type GetState = () => any;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type PromiseAction = Promise<Action>;
// DispatchIntello is a custom type that we use for dispatching
// we need to give it a custom name or else it conflicts with third party type libaries
export type DispatchIntello = (
    action: Action | ThunkAction | PromiseAction
) => any;

// ResultsAction is the action of loading the results page
export type ResultsAction = {
    results: ListApi<any>;
    modelType: string;
    // fetchTime stores the time when we started this fetch. it is used to avoid race conditions when fetching.
    // if passed, it is compared to the lastURLFetchTime in the reducer to make sure this is the last request sent.
    // if there were newer fetches, the results are ignored. it can be set to null if we don't need that check
    fetchTime: number | null;
} & Action;

export type ResetURLAction = {
    modelType: string;
} & Action;

// LoadingAction is the action we publish to update the loading state of a reducer
export interface LoadingAction {
    loading: boolean;
    type: string;
}

// DashListLoading is the action we publish to indicate a listing is loading
export type DashListLoading = {
    modelType: string;
    // href is the full path + query string that we sent for this last model
    href: string;
    // fetchTime is the time when the loading started
    fetchTime: number;
} & LoadingAction;

export type AddItemAction<R> = {
    modelType: string;
    item: R;
} & Action;

export type UpdateItemAction<R> = {
    modelType: string;
    // idKey is the name of the field that will be used as an id
    idKey: string;
    // idValue is the value of the idKey field that we will search for
    idValue: any;
    item: R;
} & Action;

export type DeleteItemAction = {
    modelType: string;
    idKey: string;
    idValue: any;
} & Action;

// loading generates a dashboard loading action
function loading(
    load: boolean,
    modelType: string,
    href: string,
    currentTime: number
): DashListLoading {
    return {
        type: LOADING_ACTION,
        loading: load,
        modelType,
        href,
        fetchTime: currentTime,
    };
}

// results generates a dashboard results action
export function results(
    res: ListApi<any>,
    modelType: string,
    fetchTime?: number | null
): ResultsAction {
    const fetchTimeValue = typeof fetchTime === "number" ? fetchTime : null;
    return {
        type: RESULTS_ACTION,
        results: res,
        modelType,
        fetchTime: fetchTimeValue,
    };
}

// onFetch gets a list to render to a reducer
export function onFetch(props: {
    qs: string;
    lastURLFetch: string | null;
    modelType: string;
    route: string;
    appName: string;
    useQs?: boolean;
    lastURLFetchTime?: number | null;
}): ThunkAction {
    const {
        qs,
        lastURLFetch,
        modelType,
        route,
        appName,
        useQs = false,
        lastURLFetchTime = null,
    } = props;
    return (dispatch) => {
        const href = useQs ? route + qs : route;
        // currentTime is the current time we use this to check that we haven't gone longer
        // than 1 hour without submitting an api call for this list
        const currentTime = Date.now();
        const oneHour = 1000 * 60 * 60;
        // get the last fetch time - if there wasn't a last fetch time then we use the current time
        const lastFetchTime =
            lastURLFetchTime != null
                ? currentTime - lastURLFetchTime
                : currentTime;
        // if the current route request is equal to the last url we fetched for this model
        // then we just return and if the model hasn't been requested in the past hour
        if (href === lastURLFetch && lastFetchTime < currentTime + oneHour) {
            return;
        }

        dispatch(loading(true, modelType, href, currentTime));
        /* eslint-disable-next-line consistent-return */
        return getList(qs, appName, route)
            .then((res: ListApi<any>) => {
                // if the results is returned as null which can sometimes happen from the api we set it to an array
                if (res.results == null) {
                    res.results = [];
                }
                // we need to reset the scroll position - right now this doesn't happen in the routing library, but probably should
                if (typeof window !== "undefined") {
                    window.scroll(0, 0);
                }
                return dispatch(results(res, modelType, currentTime));
            })
            .catch((e) => {
                captureException(e);
            });
    };
}

// resetURLFetch resets the url that was fetched for a given model type,
// this way if another reducer needs to ensure that a list is using a fresh result it can.
// For example, when a user successfully authenticates with an integration we need to ensure the usage, compliance,
// + spend lists send new requests bec there might be new data
export function resetURLFetch(modelType: string): ResetURLAction {
    return {
        type: RESET_URL_ACTION,
        modelType,
    };
}

// updateItem updates the item in the results list for a given model
export function updateItem(
    idKey: string,
    idValue: any,
    item: any,
    modelType: string
): UpdateItemAction<any> {
    return {
        type: UPDATE_ITEM_ACTION,
        idKey,
        idValue,
        item,
        modelType,
    };
}

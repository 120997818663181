import {
    LOADING_ACTION,
    LoadingAction,
    RESULTS_ACTION,
    ResultsAction,
    Tooltips,
} from "./actions";

/**
 * TooltipsState
 * Represents the state used by the tooltips reducer
 * `loadedTooltips` - a set of table names that we have sent tooltip requests for. This prevents fetching tooltips twice for the same table
 * `tooltips` - a mapping of tooltip keys to their text. Keys are composed of table name and the column name
 */
export interface TooltipsState {
    loadedTooltips: Set<string>;
    tooltips: Tooltips;
}

/**
 * defaultState
 * Defines the default state for the reducer
 */
export const defaultState: TooltipsState = {
    loadedTooltips: new Set<string>([]),
    tooltips: {},
};

/**
 * loading
 * Adds the table to the set of tables that we have sent api requests for
 */
export function loading(
    state: TooltipsState,
    action: LoadingAction
): TooltipsState {
    const { tableName } = action;
    const { loadedTooltips } = state;
    return {
        ...state,
        loadedTooltips: new Set([...Array.from(loadedTooltips), tableName]),
    };
}

/**
 * results
 * Saves the tooltips received from the api to the state
 */
export function results(
    state: TooltipsState,
    action: ResultsAction
): TooltipsState {
    const { result } = action;
    return {
        ...state,
        tooltips: { ...state.tooltips, ...result },
    };
}

/**
 * tooltips
 * Represents the tooltips reducer which stores header tooltips
 */
export function tooltips(
    state: TooltipsState = defaultState,
    action: any
): TooltipsState {
    switch (action.type) {
        case LOADING_ACTION:
            return loading(state, action);
        case RESULTS_ACTION:
            return results(state, action);
        default:
            return state;
    }
}

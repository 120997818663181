import * as React from "react";
import styles from "./styles.module.css";

// HoverProps are the properties that need to be passed to a tool tip
interface HoverProps {
    children?: React.ReactNode;
    tooltip: React.ReactNode;
    // forceShow should be set to true if we want a tooltip to be always visible (even without hover)
    forceShow?: boolean;
}

// HoverTooltipProps are the tooltip props
type HoverTooltipProps = {
    // position is where we should position the tooltip
    position: string;
} & HoverProps;

/**
 * @description Displays a tooltip on hover depending on the position passed
 * @param {HoverTooltipProps} props
 */
export function HoverTooltip(props: HoverTooltipProps) {
    return (
        <div
            className={`${styles.tooltipWrapper} ${styles[props.position]} ${
                props.forceShow ? styles.show : ""
            }`}
        >
            {props.children}
            <div className={`d-flex flex-column ${styles.tooltipContainer}`}>
                <div className={styles.tooltipInnerContainer}>
                    <div className={styles.tooltipInnerWrapper}>
                        {props.tooltip}
                    </div>
                    <div className={styles.triangle} />
                </div>
            </div>
        </div>
    );
}

/**
 * @description Displays a tooltip above the element on hover
 * @param {HoverProps} props
 */
export function TopHoverTooltip(props: HoverProps) {
    return <HoverTooltip {...props} position="top" />;
}

/**
 * @description Displays a tooltip at the bottom of an element on hover
 * @param {HoverProps} props
 */
export function BottomHoverTooltip(props: HoverProps) {
    return <HoverTooltip {...props} position="bottom" />;
}

/**
 * @description Displays a tooltip to the right of an element on hover
 * @param {HoverProps} props
 */
export function RightHoverTooltip(props: HoverProps) {
    return <HoverTooltip {...props} position="right" />;
}

import {
    Cursor,
    CursorPagination,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { Pagination } from "@src/schema/Pagination";

// // // //

/**
 * isCursor
 * Accepts a cursor objkect and returns a type predicate indicating whether
 * or note the cursor object is a valid Cursor instance.
 * @param cursor - the object that's being verified as a Cursor
 */
export function isCursor(cursor: any): cursor is Cursor {
    // Return true if cursor is null (technically a valid value from the API)
    if (cursor === null) {
        return true;
    }

    // Verify startPosition + endPosition
    return (
        cursor !== undefined &&
        // Verify "cursor.startPosition"
        cursor.startPosition !== undefined &&
        typeof cursor.startPosition === "string" &&
        // Verify "cursor.endPosition"
        cursor.endPosition !== undefined &&
        typeof cursor.endPosition === "string"
    );
}

/**
 * isCursorPagination
 * Accepts a pagination and returns a type predicate indicating whether
 * or not the pagination parameters is a valid CursorPagination instance.
 * Performs a shallow check on the `pagination` parameter to ensure validity.
 * @param pagination - the object that's being verified as a
 */
export function isCursorPagination(
    pagination: Pagination | CursorPagination
): pagination is CursorPagination {
    // Coerces pagination type as CursorPagination
    // Prevents TS errors when accessing `pagination.cursor`, since cursor is undefined on the standard pagination.
    const value = pagination as CursorPagination;

    return (
        // // // //
        // NOTE - these checks have been commented out
        // temporarily until we completely phase-out offset-based pagination on the EA Events page

        // Verify absence of "page"
        // (pagination as Pagination).page === undefined &&
        // Verify absence of "pageCount"
        // (pagination as Pagination).pageCount === undefined &&
        // Verify absence of "totalItems"
        // (pagination as Pagination).totalItems === undefined &&
        // Verify absence of "rangeStart"
        // (pagination as Pagination).rangeStart === undefined &&
        // Verify absence of "rangeEnd"
        // (pagination as Pagination).rangeEnd === undefined &&

        // // // //

        // Verify "itemsPerPage"
        value.itemsPerPage !== undefined &&
        typeof value.itemsPerPage === "number" &&
        // Verify "sort"
        value.sort !== undefined &&
        typeof value.sort === "string" &&
        // Verify "direction"
        value.direction !== undefined &&
        typeof value.direction === "string" &&
        ["", "asc", "desc"].includes(value.direction) &&
        // Verify "cursor"
        isCursor(value.cursor)
    );
}

import { empty as EMPTY_PAGINATION } from "@src/schema/data/Pagination";
import { Webhook } from "@src/schema/Webhook";
import { WebhooksList } from "@src/schema/WebhooksList";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { ApolloQueryResult } from "apollo-client";
import React from "react";
import { Query } from "react-apollo";

import { Pagination } from "@src/schema/Pagination";
import {
    WEBHOOK_LIST_FETCHER_QUERY,
    WebhookListFetcherResponse,
    WebhookListFetcherVariables,
} from "./graphql";

interface Props {
    filtersInput: FiltersInput;
    children: (childProps: {
        loading: boolean;
        webhooks: Webhook[];
        pagination: Pagination;
        refetchWebhooks: () => Promise<
            ApolloQueryResult<WebhookListFetcherResponse>
        >;
    }) => React.ReactNode;
}

const EMPTY_LIST: WebhooksList = {
    results: [],
    pagination: EMPTY_PAGINATION,
    __typename: "WebhooksList",
};

/**
 * @name WebhookListFetcher
 * @description Fetches webhook data via GQL using Advanced Filters
 * @param {Props} props
 */
export function WebhookListFetcher(props: Props) {
    const variables = {
        filters: props.filtersInput,
    };

    return (
        <Query<WebhookListFetcherResponse, WebhookListFetcherVariables>
            query={WEBHOOK_LIST_FETCHER_QUERY}
            variables={variables}
            fetchPolicy="cache-and-network"
        >
            {({ loading, error, data, refetch }) => {
                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            pagination:
                                data?.workflows?.webhooks?.list?.pagination ||
                                EMPTY_PAGINATION,
                            webhooks:
                                data?.workflows?.webhooks?.list?.results ||
                                EMPTY_LIST.results,
                            refetchWebhooks: async () => refetch(),
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

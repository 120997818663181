import { DropdownOption } from "@src/shared_modules/dropdown_select";

// // // //

/**
 * returns an integer for the the current calendar year
 */
export function getCurrentYear(): number {
    return new Date().getFullYear();
}

/**
 * Gets years (in integers) from baseYear -> to -> currentYear (inclusive).
 * i.e. a `baseYear` value of `2020` returns `[2020, 2021, 2022]`
 */
function getYears(baseYear: number): number[] {
    const currentYear = getCurrentYear();
    const length = currentYear - baseYear + 1;
    return Array.from({ length }, (_v, i) => baseYear + i);
}

/**
 * gets array of DropdownOption[] values for startYear + endYear dropdowns
 */
export function getYearOptions(baseYear: number): DropdownOption[] {
    return getYears(baseYear)
        .reverse()
        .map((y) => {
            return { humanValue: `${y}`, internalValue: y };
        });
}

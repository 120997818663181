import { BreadcrumbNode } from "../types";
import { webhooksNode } from "../webhooks";
import { webhookNewState } from "./state";

// // // //

export const webhooksNewNode: BreadcrumbNode = {
    getState: webhookNewState,
    parent: webhooksNode,
};

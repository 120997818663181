import { captureMessage } from "@src/util/analytics";
import React from "react";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
} from "react-beautiful-dnd";
import { FilterListItem } from "./FilterListItem";
import {
    AdvancedFilter,
    EditorFilter,
    Filter,
    FilterExpression,
    Filters,
    PossibleFilter,
} from "./graphql";

// // // //

/**
 * FilterListProps
 * `filters`: The `EditorFilter[]` array rendered by this component
 * `parentFilter`: The parent filter of the array of AdvancedFilters that's being rendered
 * `depth`: The "depth" at which the <FilterList /> is rendered. Used to limit depth of recursively nested filters
 * `selectedQueryFilters`: The `Filters` instance used to populate the AdvancedFilterEditor. Used here to provide the `PossibleFilter` to the `FilterListItem`
 * `editingFilter`: The `EditorFilter` currently being editing in the `FilterForm` (used to change styles in FilterListItem)
 * `droppableID`: The parent filter of the array of AdvancedFilters that's being rendered
 * `editFilter`: see `FilterListItemProps` annotation
 * `removeFilter`: see `FilterListItemProps` annotation
 * `addOrFilter`: see `FilterListItemProps` annotation
 * `addAndFilter`: see `FilterListItemProps` annotation
 */
interface FilterListProps {
    filters: EditorFilter[];
    parentFilter: EditorFilter | null;
    depth: number;
    selectedQueryFilters: Filters;
    editingFilter: EditorFilter | null;
    droppableID: string;
    editFilter: (filter: EditorFilter) => void;
    removeFilter: (filter: EditorFilter) => void;
    addOrFilter: (parentFilter: EditorFilter | null) => void;
    addAndFilter: (parentFilter: EditorFilter | null) => void;
}

/**
 * findPossibleFilterFromFilter
 * Finds the `PossibleFilter` instance associated with a @params.filter
 * @param props.filter - the `Filter` whose associated PossibleFilter we're searching for
 * @param props.filters - the `Filters` object encapsulating the `defaults` and `advanced` PossibleFilter instances
 */
export function findPossibleFilterFromFilter(props: {
    filter: EditorFilter;
    filters: Filters;
}): PossibleFilter | undefined {
    const { filter, filters } = props;

    // Returns undefined for Filters with expression = `AND` || `OR` (no matching PossibleFilter)
    if (
        [FilterExpression.and, FilterExpression.or].includes(filter.expression)
    ) {
        return undefined;
    }

    // Returns the PossibleFilter in props.filters.defaults associated with props.filter
    if (filter.source === "defaults") {
        return filters.defaults.find(
            (pf: PossibleFilter) => pf.key === filter.fieldName
        );
    }

    // Finds the AdvancedFilter asociated with `props.filter`
    const advancedFilter: AdvancedFilter | undefined = filters.advanced.find(
        (af: AdvancedFilter) => af.key === filter.source
    );

    // Return `undefined` in advancedFilter is not found
    if (!advancedFilter) {
        return undefined;
    }

    // Returns the PossibleFilter in advancedFilter.filters asociated with props.filter
    return advancedFilter.filters.find(
        (pf: PossibleFilter) => pf.key === filter.fieldName
    );
}

/**
 * getOpenDropdownUp
 * Defines FilterListItem.props.openDropdownUp
 * @param props.filters - see FilterListProps.filters
 * @param props.parentFilter - see FilterListProps.parentFilter
 * @param props.index - the index of the current FilterListItem being rendered
 */
export function getOpenDropdownUp(props: {
    filters: Filter[];
    parentFilter: Filter | null;
    index: number;
}): boolean {
    const { filters, parentFilter, index } = props;

    // Defines flag indicating that the parentFilter is defined and is an OR statement
    const isParentFilterOrStatement: boolean =
        parentFilter && parentFilter.expression === FilterExpression.or;

    // Defines flag for FilterListItems nested in an `OR` statement
    // props.openDropdownUp should be true when:
    // - parentFilter is an OR statement
    // - parentFilter.filters only has ONE Filter
    // - index === 0
    const renderUpForNestedFilters: boolean =
        isParentFilterOrStatement && filters.length === 1 && index === 0;

    // Short-circuit function if isParentFilterOrStatement
    if (isParentFilterOrStatement) {
        return renderUpForNestedFilters;
    }

    // Defines flag for non-nested filters
    // props.openDropdownUp should be true when index
    // is the last element in an array of Filters with MORE than one element
    const renderUpForNonNestedFilters: boolean =
        index === filters.length - 1 && index !== 0;

    // Returns renderUpForNonNestedFilters
    return renderUpForNonNestedFilters;
}

/**
 * getFilterListItemSeparatorExpression
 * Returns the `FilterExpression` corresponding to the type of separator / divider that's rendered between each `FilterListItem`
 * The result of this function is passed into the `FilterListItem` to dictate whether
 * or not, and if so, how, the separator / divider between the FilterListItem and it's preceeding component is rendered.
 *
 * Example:
 *
 *      FilterListItem
 *      |
 *    ...................  <--- Dictates if AND / OR is rendered here. The dotted line indicates the boundary of the FilterListItem component (PENDING FUTURE PR)
 *    . and             .
 *    . |               .
 *    . FilterListItem  .
 *    ...................
 *      |
 *      OR
 *      |
 *      FilterListItem
 *
 * @param props.index - the index (0+) of the `Filter` in `props.filters` whose separator we're calculating
 * @param props.filters - the `Filter[]` that's currently being rendered in the FilterList component
 * @param props.parentFilter - The parent filter of the array of AdvancedFilters that's being rendered
 */
export function getFilterListItemSeparatorExpression(props: {
    index: number;
    filters: EditorFilter[];
    parentFilter: Filter | null;
}): FilterExpression | undefined {
    const { index, parentFilter } = props;
    const filtersLength = props.filters.length;

    // Skip separator if the Filter[] has only one element
    if (filtersLength === 1) {
        return undefined;
    }

    // Skip separator if there's no previous filter in the list
    if (index === 0) {
        return undefined;
    }

    // Render `OR` separator
    // We only want to render the `OR` separator between FilterListItem components nested inside a Filter where `{ expression: or }`
    if (parentFilter && parentFilter.expression === FilterExpression.or) {
        return FilterExpression.or;
    }

    // Render `AND` as default separator if we didn't skip or return `OR`
    return FilterExpression.and;
}

/**
 * FilterList
 * Renders a drag/drop list of filters for the Vendor table
 * Management of column re-ordering happens in the parent component via the onDragEnd prop
 * @param props - see `FilterListProps`
 */
export function FilterList(props: FilterListProps) {
    return (
        <Droppable droppableId={props.droppableID} type={props.droppableID}>
            {(droppableProvided: DroppableProvided) => (
                <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                >
                    {props.filters.map((f, index) => {
                        // Finds the PossibleFilter associated with each Filter
                        const possibleFilter:
                            | PossibleFilter
                            | undefined = findPossibleFilterFromFilter({
                            filter: f,
                            filters: props.selectedQueryFilters,
                        });

                        // Handle case where the associated PossibleFilter isn't found && f.expression !== AND, OR
                        if (
                            possibleFilter === undefined &&
                            ![
                                FilterExpression.and,
                                FilterExpression.or,
                            ].includes(f.expression)
                        ) {
                            // Captures message including filter.fieldName + filter.source
                            captureMessage(
                                "FilterList - no PossibleFilter returned from findPossibleFilterFromFilter",
                                {
                                    extra: {
                                        fieldName: f.fieldName,
                                        source: f.source,
                                    },
                                }
                            );

                            // Returns null, instead of <FilterListItem />
                            return null;
                        }

                        // Defines the FilterExpression passed into each FilterListItem as props.separatorFilterExpression
                        const separatorFilterExpression:
                            | FilterExpression
                            | undefined = getFilterListItemSeparatorExpression({
                            index,
                            filters: props.filters,
                            parentFilter: props.parentFilter,
                        });

                        // Defines FilterListItem.props.openDropdownUp
                        const openDropdownUp: boolean = getOpenDropdownUp({
                            filters: props.filters,
                            parentFilter: props.parentFilter,
                            index,
                        });

                        return (
                            <Draggable
                                key={f.id}
                                draggableId={f.id}
                                index={index}
                            >
                                {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                ) => (
                                    <FilterListItem
                                        filter={f}
                                        openDropdownUp={openDropdownUp}
                                        parentFilter={props.parentFilter}
                                        depth={props.depth}
                                        index={index}
                                        separatorFilterExpression={
                                            separatorFilterExpression
                                        }
                                        possibleFilter={possibleFilter}
                                        selectedQueryFilters={
                                            props.selectedQueryFilters
                                        }
                                        editingFilter={props.editingFilter}
                                        disableReorder={
                                            props.filters.length < 2
                                        }
                                        provided={provided}
                                        snapshot={snapshot}
                                        editFilter={props.editFilter}
                                        removeFilter={props.removeFilter}
                                        addOrFilter={props.addOrFilter}
                                        addAndFilter={props.addAndFilter}
                                    />
                                )}
                            </Draggable>
                        );
                    })}
                    {droppableProvided.placeholder}
                </ul>
            )}
        </Droppable>
    );
}

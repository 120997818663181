import React from "react";

export function Teams(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="18"
            viewBox="0 0 23 18"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#BDBDBD" transform="translate(-16 -189)">
                    <g transform="translate(16 189)">
                        <g transform="translate(7.962 3.6)">
                            <g>
                                <path d="M11.104 3.654c0 2.018-1.687 3.654-3.767 3.654S3.57 5.672 3.57 3.654 5.257 0 7.337 0s3.767 1.636 3.767 3.654z"></path>
                                <path d="M9.642 7.573c2.923.937 5.033 3.609 5.033 6.759H0c0-3.152 2.111-5.825 5.037-6.76"></path>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M10.587 3.654c0 2.018-1.608 3.654-3.592 3.654-1.983 0-3.591-1.636-3.591-3.654S5.012 0 6.995 0c1.984 0 3.592 1.636 3.592 3.654z"></path>
                                <path d="M9.193 7.573c2.786.937 4.798 3.609 4.798 6.759H0c0-3.152 2.013-5.825 4.802-6.76"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

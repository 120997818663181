import { loadTableTooltips } from "@src/requests/content";
import { Action } from "@src/shared_modules/redux_provider/types";
import { Dispatch } from "redux";

// define action types associated with the `tooltips` reducer
export const LOADING_ACTION = "__tooltips_loading";
export const RESULTS_ACTION = "__tooltips_results";

/**
 * fetchTooltips
 * Action creator function that dispatches a LOADING_ACTION, fetches table header tooltips,
 * and finally dispatches the RESULTS_ACTION
 * @param tableName - the name of the table
 */
export function fetchTooltips(tableName: string) {
    return async (dispatch: Dispatch) => {
        dispatch(loadingAction(tableName));
        const tooltips = await loadTableTooltips(tableName);
        dispatch(resultsAction(tableName, tooltips));
    };
}

/**
 * Tooltips
 * Represents the tooltips mapping received from the api. Defined here and not in `reducer.ts` so that we don't have a circular dependency
 */
export interface Tooltips {
    [columnKey: string]: string;
}

/**
 * ResultsAction interface definition
 * Used for RESULTS_ACTION actions
 */
export type ResultsAction = {
    tableName: string;
    result: Tooltips;
} & Action;

/**
 * LoadingAction interface definition
 * Used for LOADING_ACTION actions
 */
export type LoadingAction = {
    tableName: string;
} & Action;

/**
 * resultsAction
 * Action creator function for ResultsAction
 * @param tableName - the name of the table
 * @param result - the mapping of tooltip keys to their text received from the api
 */
function resultsAction(tableName: string, result: Tooltips): ResultsAction {
    return {
        type: RESULTS_ACTION,
        tableName,
        result,
    };
}

/**
 * loadingAction
 * Action creator function for LoadingAction
 * @param tableName - the name of the table
 */
function loadingAction(tableName: string): LoadingAction {
    return {
        type: LOADING_ACTION,
        tableName,
    };
}

import { FiltersV2 } from "@src/requests/list";
import { Pagination } from "@src/schema/Pagination";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import {
    ADD_ITEM_ACTION,
    AddItemAction,
    DashListLoading,
    DELETE_ITEM_ACTION,
    DeleteItemAction,
    LOADING_ACTION,
    RESET_URL_ACTION,
    RESULTS_ACTION,
    ResultsAction,
    UPDATE_ITEM_ACTION,
    UpdateItemAction,
} from "./action";

const defaultPagination: Pagination = {
    page: 0,
    pageCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    rangeStart: 0,
    rangeEnd: 0,
    sort: DEFAULT_PAGINATION.sort,
    direction: DEFAULT_PAGINATION.direction,
    __typename: "Pagination",
};

// ListApi is the type that we render for a list api request
export interface ListApi<ResultType> {
    pagination: Pagination;
    total: number;
    filters: FiltersV2;
    sorts: string[];
    results: ResultType[];
}

// ListState is the state that represents a list state for the dashboard listing
export type ListState<ResultType> = {
    // loading indicates whether the list is currently loading
    loading: boolean;
    // appName is the name of the app that we send a request to for the current list
    appName: string;
    // lastURLFetch is the url of the last fetch that we sent for this reducer -
    // by saving this value in the state it allows us to not have to send subsequent requests if we have the state for that reducer
    // if we already have the state for that url locally
    lastURLFetch: string | null;
    // lastURLFetchTime is the last time the url was fetched, that way we can determine if a user has just left a tab open and the api can refresh itself
    lastURLFetchTime: number | null;
} & ListApi<ResultType>;

type Action<ResultType> = DashListLoading &
    ResultsAction &
    AddItemAction<ResultType> &
    UpdateItemAction<ResultType>;

// genListing generates a listing reducer for a given modelType. it'll automatically update the loading of the reducer.
// if you don't need to update individual properties of the results it'll also automatically handle returning results correctly.
// Note: we expect `modelType` to match the same value as the key that the model will be in the reducer.
// It also accepts an optional updateFn which allows you to hook into the actions the reducer receives.
// This is useful in situations like the spend listing where the reducer needs to list into individual property updates
export function genListing<ListStateSupplement, ResultType>(
    modelType: string,
    defaultVals: ListStateSupplement,
    appName: string,
    resultsFn?: (
        state: ListState<ResultType> & ListStateSupplement,
        action: ResultsAction
    ) => ListState<ResultType> & ListStateSupplement,
    updateFn?: (
        state: ListState<ResultType> & ListStateSupplement,
        action: any
    ) => ListState<ResultType> & ListStateSupplement
): (
    state: ListState<ResultType> & ListStateSupplement,
    action: Action<ResultType>
) => ListState<ResultType> & ListStateSupplement {
    // NOTE: this should be type casted
    const d: ListState<ResultType> & ListStateSupplement = {
        loading: true,
        appName,
        lastURLFetch: null,
        lastURLFetchTime: null,
        total: 0,
        filters: {},
        sorts: [],
        results: [],
        pagination: defaultPagination,
        ...defaultVals,
    };

    // NOTE: we embed the functions inside this function or else flow complains about the type `S` to us and i'd prefer to keep better typing

    // loading set whether the current list is loading
    function loading(
        state: ListState<ResultType> & ListStateSupplement,
        action: DashListLoading
    ): ListState<ResultType> & ListStateSupplement {
        return {
            // without first passing the default vals flow will complain, this shouldn't actually be an issue for the object spread bec it will just be overriden by flow
            ...defaultVals,
            ...state,
            loading: action.loading,
            lastURLFetch: action.href,
            lastURLFetchTime: action.fetchTime,
        };
    }

    // generate the results for the reducer. if a specific resultsFn was passed (bec the user needs to augement the state for whatever reason) we call that instead
    function results(
        state: ListState<ResultType> & ListStateSupplement,
        action: ResultsAction
    ) {
        if (resultsFn != null) {
            return resultsFn(state, action);
        }
        // ignore the results of previous fetch if we already started a new one
        if (
            action.fetchTime &&
            state.lastURLFetchTime &&
            action.fetchTime < state.lastURLFetchTime
        ) {
            return state;
        }
        return {
            // without first passing the default vals flow will complain, this shouldn't actually be an issue for the object spread bec it will just be overriden by flow
            ...defaultVals,
            ...state,
            ...action.results,
            loading: false,
        };
    }

    // resetURL resets the url fetch url and time. this way other actions can say that a list may need to reset itself
    function resetURL(
        state: ListState<ResultType> & ListStateSupplement = d
    ): ListState<ResultType> & ListStateSupplement {
        return {
            ...defaultVals,
            ...state,
            loading: true,
            lastURLFetch: null,
            lastURLFetchTime: null,
        };
    }

    // addItem adds new items to the results list
    function addItem(
        state: ListState<ResultType> & ListStateSupplement,
        action: AddItemAction<ResultType>
    ): ListState<ResultType> & ListStateSupplement {
        return {
            ...state,
            results: [...state.results, action.item],
        };
    }

    // updateItem updates an item in the results list
    function updateItem(
        state: ListState<ResultType> & ListStateSupplement,
        action: UpdateItemAction<ResultType>
    ): ListState<ResultType> & ListStateSupplement {
        return {
            ...state,
            results: state.results.map((item) =>
                item[action.idKey] === action.idValue ? action.item : item
            ),
        };
    }

    // deleteItem deletes an item from the results list
    function deleteItem(
        state: ListState<ResultType> & ListStateSupplement,
        action: DeleteItemAction
    ): ListState<ResultType> & ListStateSupplement {
        return {
            ...state,
            results: state.results.filter(
                (item) => item[action.idKey] !== action.idValue
            ),
        };
    }

    // return the reducer that we will use
    return (
        state: ListState<ResultType> & ListStateSupplement = d,
        action: Action<ResultType>
    ): ListState<ResultType> & ListStateSupplement => {
        if (action.modelType !== modelType) {
            return updateFn != null ? updateFn(state, action) : state;
        }

        switch (action.type) {
            case LOADING_ACTION:
                return loading(state, action);
            case RESULTS_ACTION:
                return results(state, action);
            case RESET_URL_ACTION:
                return resetURL(state);
            case ADD_ITEM_ACTION:
                return addItem(state, action);
            case UPDATE_ITEM_ACTION:
                return updateItem(state, action);
            case DELETE_ITEM_ACTION:
                return deleteItem(state, action);
            default:
                return updateFn != null ? updateFn(state, action) : state;
        }
    };
}

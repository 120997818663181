import React from "react";

export function Lock(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="97"
            height="81"
            viewBox="0 0 97 81"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-692 -246)">
                    <g transform="translate(455 246)">
                        <g transform="translate(237)">
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M77.826 39.13a2.174 2.174 0 110-4.347 2.174 2.174 0 010 4.347zm0-1.739a.435.435 0 100-.87.435.435 0 000 .87z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M29.13 4.348a2.174 2.174 0 110-4.348 2.174 2.174 0 010 4.348zm0-1.74a.435.435 0 100-.869.435.435 0 000 .87z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M94.348 38.26a2.174 2.174 0 110-4.347 2.174 2.174 0 010 4.348zm0-1.738a.435.435 0 100-.87.435.435 0 000 .87z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M89.565 60.87a4.348 4.348 0 110-8.696 4.348 4.348 0 010 8.696zm0-1.74a2.609 2.609 0 100-5.217 2.609 2.609 0 000 5.217z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M3.478 50.435a3.478 3.478 0 110-6.957 3.478 3.478 0 010 6.957zm0-1.74a1.74 1.74 0 100-3.478 1.74 1.74 0 000 3.479z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M84.348 20.87h2.609v-2.61h-2.61v2.61zm-.87-4.348h4.348c.48 0 .87.39.87.87v4.347c0 .48-.39.87-.87.87h-4.348a.87.87 0 01-.87-.87v-4.348c0-.48.39-.87.87-.87z"
                                transform="rotate(45 85.652 19.565)"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M14.783 71.304h2.608v-2.608h-2.608v2.608zm-.87-4.347h4.348c.48 0 .87.389.87.87v4.347c0 .48-.39.87-.87.87h-4.348a.87.87 0 01-.87-.87v-4.348c0-.48.39-.87.87-.87z"
                                transform="rotate(-45 16.087 70)"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M54.783 66.087v1.74h1.739v-1.74h-1.74zm-.87-1.74h3.478c.48 0 .87.39.87.87v3.479c0 .48-.39.87-.87.87h-3.478a.87.87 0 01-.87-.87v-3.479c0-.48.39-.87.87-.87z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M13.043 29.565h2.61v-2.608h-2.61v2.608zm-.87-4.348h4.349c.48 0 .87.39.87.87v4.348c0 .48-.39.87-.87.87h-4.348a.87.87 0 01-.87-.87v-4.348c0-.48.39-.87.87-.87z"
                                transform="rotate(45 14.348 28.26)"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M11.522 11.522l.435.435-.435-.435zm0 .435l.435-.435-.435.435zm-.218-1.522h.87c.48 0 .87.39.87.87v.869c0 .48-.39.87-.87.87h-.87a.87.87 0 01-.87-.87v-.87c0-.48.39-.87.87-.87zM79.348 73.26l.435.436-.435-.435zm0 .436l.435-.435-.435.435zm-.218-1.522H80c.48 0 .87.39.87.87v.869c0 .48-.39.87-.87.87h-.87a.87.87 0 01-.87-.87v-.87c0-.48.39-.87.87-.87z"
                            ></path>
                            <path
                                fill="#539EFF"
                                fillOpacity="0.4"
                                fillRule="nonzero"
                                d="M71.304 5.217h.87v-.87h-.87v.87zm-.87-2.608h2.61c.48 0 .869.389.869.87v2.608c0 .48-.39.87-.87.87h-2.608a.87.87 0 01-.87-.87V3.478c0-.48.39-.87.87-.87z"
                                transform="rotate(45 71.74 4.783)"
                            ></path>
                            <path
                                stroke="#539EFF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M33.913 24.348c0-7.684 5.36-13.044 13.044-13.044C54.64 11.304 60 16.664 60 24.348v15.05l5.217-.023V24.348c0-10.085-8.175-18.261-18.26-18.261-10.086 0-18.261 8.176-18.261 18.26v4.785l5.217-.023v-4.761z"
                            ></path>
                            <circle
                                cx="46.957"
                                cy="55.652"
                                r="24.348"
                                fill="#FFF"
                                stroke="#539EFF"
                                strokeWidth="2"
                            ></circle>
                            <path
                                fill="#FFF"
                                stroke="#539EFF"
                                strokeWidth="2"
                                d="M45.364 57.96a4.35 4.35 0 011.593-8.395 4.348 4.348 0 011.592 8.395l.581 4.649h-4.347l.58-4.65z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

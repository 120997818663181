import React from "react";

export function Dashboard(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#B2B2B2" transform="translate(-18 -16)">
                    <g transform="translate(18 16)">
                        <g>
                            <path d="M0 0H8V6H0z"></path>
                            <path d="M10 0H18V6H10z"></path>
                            <path d="M0 8H8V14H0z"></path>
                            <path d="M10 8H18V14H10z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

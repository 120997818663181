import { RouteState, withRouter } from "@src/shared_modules/router";
import styles from "@src/shared_modules/modal/styles.module.css";
import classNames from "classnames";
import * as React from "react";

interface Props {
    // children renders the body of the modal
    children: React.ReactNode;
    // background is the background of the page that should be rendered behind the modal.
    // This is beneficial when you want to display another page behind the modal itself (like we do on the integrations within the dashboard)
    background: React.ReactNode;
    // defaultRoute is the route that we should navigate to when a user closes the modal
    defaultRoute?: string;
    // asRoute is a value that you pass in that we use as the route to route back to
    pathname?: string;
    className?: string;
    // rightDrawer should be set to true if the modal should be displayed as a right drawer
    rightDrawer?: boolean;
    // hideCloseButton should be set to true if the close button should be hidden
    hideCloseButton?: boolean;
    // onClose is an optional callback that is called when clicking on the backdrop or close button instead of route.updateRouteAction
    onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

// ModalPage renders a modal via a link. In contrast to our normal modal, the purpose of this is to render
// the modal in such a way that when you click close instead of closing the modal we navigate to the default route.
// This allows us to have dedicated routes as a modal and to close the modal via a route
export class ModalPage extends React.Component<Props & { route: RouteState }> {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    public componentDidMount() {
        // we add the overflow hidden to the body so that the document body so that the rest of the doc doesn't scroll when the modal is rendered
        if (document.body !== null) {
            document.body.style.overflow = "hidden";
        }
    }

    public componentWillUnmount() {
        // remove the overflow hidden
        if (document.body !== null) {
            document.body.style.overflow = "initial";
        }
    }

    // onClose is called when a user clicks on the background or close button
    public onClose(e: React.MouseEvent<HTMLElement>) {
        // if props.onClose was passed, only call props.onClose and return
        if (this.props.onClose) {
            this.props.onClose(e);
            return;
        }

        // otherwise, update the route to the default route
        if (
            this.props.pathname !== undefined &&
            this.props.defaultRoute !== undefined
        ) {
            this.props.route.updateRouteAction(
                this.props.defaultRoute,
                this.props.pathname
            );
            return;
        }

        this.props.route.updateRouteAction(this.props.defaultRoute);
    }

    public render() {
        // we listen to a click on the intello modal background, and we
        // also listen for a click on the inside of the modal. that way we can
        //  only hide the modal when you click outside of the modal and not
        // within the modal itself
        /* eslint jsx-a11y/no-static-element-interactions: 0 */
        const { className = "" } = this.props;
        return (
            <div>
                {this.props.background}
                <div className={styles.modal}>
                    <div
                        className={styles.background}
                        onClick={(e) => this.onClose(e)}
                    />
                    <section
                        className={classNames({
                            [styles.modalContent]: true,
                            [styles.rightDrawer]: this.props.rightDrawer,
                            [className]: className,
                        })}
                    >
                        {!this.props.hideCloseButton && (
                            <button
                                className={styles.close}
                                type="button"
                                onClick={(e) => this.onClose(e)}
                            >
                                +
                            </button>
                        )}
                        {this.props.children}
                    </section>
                </div>
            </div>
        );
    }
}

// ModalPageCC is the connected modal page component
export const ModalPageCC: React.ComponentType<Props> = withRouter(ModalPage);

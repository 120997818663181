import { Vendor } from "./graphql";

// // // //

/**
 * hasRelatedVendors
 * Returns an object indicating if a vendor has any related vendors (parent / sibling / children)
 * @param vendor - the Vendor instance whose related vendors are being examined
 */
export function hasRelatedVendors(
    vendor: Vendor
): {
    hasParent: boolean;
    hasChildren: boolean;
    hasSiblings: boolean;
    isParent: boolean;
} {
    // Defines flags used to determine if this component should simply return null
    const hasParent: boolean =
        vendor.parent !== null && vendor.parent !== undefined;

    const hasChildren: boolean =
        Array.isArray(vendor.children) && vendor.children.length > 0;

    const hasSiblings: boolean =
        Array.isArray(vendor.siblings) && vendor.siblings.length > 0;

    const isParent: boolean =
        hasParent === false && hasSiblings === false && hasChildren === true;

    return { hasParent, hasChildren, hasSiblings, isParent };
}

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import get from "lodash.get";
import * as React from "react";
import { Query } from "react-apollo";
import {
    buildVendorFetcherVariables,
    Vendor,
    VENDOR_FETCHER_RELATED_QUERY,
    VENDOR_FETCHER_SINGLE_QUERY,
    VendorFetcherResponse,
    VendorFetcherVariables,
} from "./graphql";

// // // //

/**
 * VendorFetcherLayout
 * Fetches the a single Vendor via GQL using a `vendorID` filter
 * Can optionally include parent + siblings + children data in query
 * @param props.vendorID - The ID of the Vendor we're querying for
 * @param includeRelatedVendors - (optional) If true -> include parent + siblings + children data in query
 * @param children - Fucntion that accepts a Vendor instance and a loading boolean
 */
interface VendorFetcherProps {
    vendorID: number;
    includeRelatedVendors?: boolean;
    children: (childProps: {
        vendor: Vendor | null;
        loading: boolean;
    }) => React.ReactNode;
}

/**
 * VendorFetcherLayout
 * Fetches the a single Vendor via GQL using a `vendorID` filter
 * Can optionally include parent + siblings + children data in query
 * @param props - see VendorFetcherLayout
 */
export function VendorFetcherLayout(
    props: VendorFetcherProps & { user: UserState }
) {
    // Pulls user, vendorID, includeRelatedVendors from props, defaults to true
    const { user, vendorID, includeRelatedVendors = true } = props;

    // Choose which query to use -> query for parent + siblings + children if includeRelatedVendors is true
    let GQL_QUERY = VENDOR_FETCHER_SINGLE_QUERY;
    if (includeRelatedVendors) {
        GQL_QUERY = VENDOR_FETCHER_RELATED_QUERY;
    }

    return (
        <Query<VendorFetcherResponse, VendorFetcherVariables>
            query={GQL_QUERY}
            variables={buildVendorFetcherVariables({
                organizationID: user.organizationID,
                vendorID,
            })}
        >
            {({ loading, error, data }) => {
                // Handle loading state
                if (loading) {
                    return props.children({
                        vendor: null,
                        loading: true,
                    });
                }

                // Handle error state
                if (error) {
                    return <GraphQlErrorHandler error={error} />;
                }

                // Safely pulls Vendor from response
                const vendor: Vendor | null = get(
                    data,
                    "vendors.listVendors.list.results[0]",
                    null
                );

                // If vendor is NOT found -> capture the error and pass null to props.children()
                if (vendor === null) {
                    captureMessage("VendorFetcher - no vendor found", {
                        extra: {
                            vendorID,
                        },
                    });
                }

                // Renders props.children
                return props.children({
                    vendor,
                    loading: false,
                });
            }}
        </Query>
    );
}

export const VendorFetcher: React.ComponentType<VendorFetcherProps> = withUser(
    VendorFetcherLayout
);

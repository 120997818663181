import { Price } from "../Price";

// // // //

const numericPrice: number = 99.99;

export const price01: Price = {
    formatted: `$${numericPrice}`,
    raw: numericPrice * 100,
    __typename: "Price",
};

import { buildQsFromObject } from "@src/util/route";
import { FORCE_REQUERY_PARAM } from "./constants";

/**
 * buildUrlWithFetchPolicy
 * Defines a URL that includes FORCE_REQUERY_PARAM
 * This is done to ensure that a component knows to re-query for the latest data to reflect a change made elsewhere in the app
 * @param props.location - the URL location
 * @param props.routeParams - the RouteState.params to be included in the URL
 */
export function buildUrlWithFetchPolicy(props: {
    location: string;
    routeParams: { [key: string]: string };
}): string {
    const { location, routeParams } = props;

    return `${location}${buildQsFromObject({
        ...routeParams,
        [FORCE_REQUERY_PARAM]: true,
    })}`;
}

/**
 * buildUrlWithoutFetchPolicy
 * Defines a URL that does NOT include FORCE_REQUERY_PARAM
 * This is done to ensure that we don't accidentally pass around URLs that erroneously include the FORCE_REQUERY_PARAM
 * @param props.location - the URL location
 * @param props.routeParams - the RouteState.params to be included in the URL
 */
export function buildUrlWithoutFetchPolicy(props: {
    location: string;
    routeParams: { [key: string]: string };
}): string {
    const { location, routeParams } = props;

    // Defines updated RouteState.params
    const updatedRouteParams = {
        ...routeParams,
    };

    // Deletes the FORCE_REQUERY_PARAM from updatedRouteParams
    delete updatedRouteParams[FORCE_REQUERY_PARAM];

    // If updatedRouteParams is empty -> simply return props.location
    // This is done to prevent a trailing `?` at the end
    // of the URL, which would trigger unnecessary re-renders
    if (Object.keys(updatedRouteParams).length === 0) {
        return props.location;
    }

    // Returns the URL without the FORCE_REQUERY_PARAM
    return `${location}${buildQsFromObject({
        ...updatedRouteParams,
    })}`;
}

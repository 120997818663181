import { genericErrorMessage } from "@src/util/consts";
import { Alert, AlertTypes } from "@src/shared_modules/alert";
import { Toaster } from "@src/shared_modules/toaster";
import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";
import * as React from "react";

// // // //

/**
 * stripGqlTextFromErrorMessage
 * Accepts `ApolloError.message` and returns a human-redable error message without the "GraphQL Error: " prefix
 * @param errorMessage - message that's being stripped of "GraphQL Error: " prefix
 */
export function stripGqlTextFromErrorMessage(errorMessage: string): string {
    return errorMessage.replace("GraphQL error: ", "");
}

/**
 * buildErrorMessageFromApolloError
 * Accepts an `ApolloError` and returns a human-readable string that's rendered by GraphQLErrorHandler
 * @param error - ApolloError that's being
 */
export function buildErrorMessageFromApolloError(error: ApolloError): string {
    // Returns the single GraphQL error message
    if (error.message) {
        return stripGqlTextFromErrorMessage(error.message);
    }

    // Returns all the GraphQL errors concatenated together
    return error.graphQLErrors.map((e: GraphQLError) => e.message).join("\n");
}

// // // //

/**
 * GraphQlErrorHandlerProps
 * @param error - ApolloError being displayed, or null
 * @param componentType (optional) - Determines the component rendered by GraphQLErrorHandler (either <Alert /> or <Toaster />)
 * @param removable - (optional) boolean indicating that the alert / toaster can be removed by the user (defaults to `true`)
 * @param onRemove (optional) - Callback method to clear the error
 */
interface GraphQlErrorHandlerProps {
    error: ApolloError | null | string;
    componentType?: "toaster" | "alert";
    removable?: boolean;
    onRemove?: () => void;
}

/**
 * GraphQlErrorHandler
 * Normalizes a human-readable error message from an `ApolloError` instance
 * and renders the result in either a <Toaster /> or <Alert />
 * @param props - See GraphQlErrorHandlerProps
 */
export function GraphQlErrorHandler(props: GraphQlErrorHandlerProps) {
    // Pulls values from props, assigns defaults
    const { error, removable = true, componentType = "toaster" } = props;

    // Returns null if no error state is present
    if (error === null) {
        return null;
    }

    let errorMessage: string = "";
    if (typeof error === "string") {
        errorMessage = error;
    } else if (typeof error === "undefined") {
        errorMessage = genericErrorMessage;
    } else {
        // Defines errorMessage from props.error
        errorMessage = buildErrorMessageFromApolloError(error);
    }
    // if there is an empty string passed in we don't render
    if (errorMessage.length === 0) {
        return null;
    }

    // Return <Toaster /> if `error` is defined
    if (componentType === "toaster") {
        return (
            <Toaster
                type="danger"
                removable={removable}
                text={errorMessage}
                onRemove={() => {
                    if (props.onRemove) {
                        props.onRemove();
                    }
                }}
            />
        );
    }

    // Return <Alert /> if `error` is defined && componentType !== "toaster"
    return (
        <Alert
            type={AlertTypes.danger}
            text={errorMessage}
            removable={removable}
            onRemove={() => {
                if (props.onRemove) {
                    props.onRemove();
                }
            }}
        />
    );
}

import { Feature } from "@src/requests/content";
import {
    FeatureContentAction,
    FeatureContentActionTypes,
    LoadingAction,
    ResultsAction,
} from "./actions";

/**
 * FeatureContentState
 * Represents the state used by the feature content reducer
 * `loadedFeatureKeys` - a set of feature uniqueKeys that we have sent requests for. This prevents fetching features twice for the same key
 * `features` - a mapping of feature uniqueKeys to their content. null values represent features that were not found in contentful
 */
export interface FeatureContentState {
    loadedFeatureKeys: Set<string>;
    features: { [key: string]: Feature | null };
}

/**
 * defaultState
 * Defines the default state for the reducer
 */
export const defaultState: FeatureContentState = {
    loadedFeatureKeys: new Set<string>([]),
    features: {},
};

/**
 * loading
 * Adds the feature key to the set of feature keys that we have sent api requests for
 */
export function loading(
    state: FeatureContentState,
    action: LoadingAction
): FeatureContentState {
    const { key } = action;
    const { loadedFeatureKeys } = state;
    return {
        ...state,
        loadedFeatureKeys: new Set([...Array.from(loadedFeatureKeys), key]),
    };
}

/**
 * results
 * Saves the feature received from the api to the state
 */
export function results(
    state: FeatureContentState,
    action: ResultsAction
): FeatureContentState {
    const { result, key } = action;
    return {
        ...state,
        features: { ...state.features, [key]: result },
    };
}

/**
 * featureContent
 * Represents the feature content reducer
 */
export function featureContent(
    state: FeatureContentState = defaultState,
    action: FeatureContentAction
): FeatureContentState {
    switch (action.type) {
        case FeatureContentActionTypes.LOADING_ACTION:
            return loading(state, action);
        case FeatureContentActionTypes.RESULTS_ACTION:
            return results(state, action);
        default:
            return state;
    }
}

// Defines constants for preset upcoming/past values in weeks
export const LAST_FULL_WEEK: number = 1;
export const FOUR_WEEKS: number = 4;
export const THREE_MONTHS: number = 13;
export const SIX_MONTHS: number = 26;
export const ONE_YEAR: number = 52;

/**
 * WEEK_PRESET_VALUES
 * Defines array of values encapsulating all the week presets
 * Used to determine if the user's input is a "preset" or a "custom" value
 */
export const WEEK_PRESET_VALUES: number[] = [
    LAST_FULL_WEEK,
    FOUR_WEEKS,
    THREE_MONTHS,
    SIX_MONTHS,
    ONE_YEAR,
];

/**
 * DATE_PRESETS
 * Defines array of available preset ranges + labels for WeekInput
 */
export const WEEK_PRESETS: Array<{ label: string; value: number }> = [
    { label: "Last full week", value: LAST_FULL_WEEK },
    { label: "4 weeks", value: FOUR_WEEKS },
    { label: "3 mo", value: THREE_MONTHS },
    { label: "6 mo", value: SIX_MONTHS },
    { label: "1Y", value: ONE_YEAR },
];

import { GlobalToasterProps } from "./component";

// // // //

// GlobalToasterActionTypes Enum
export enum GlobalToasterActionTypes {
    addToaster = "ADD_TOASTER",
    removeToaster = "REMOVE_TOASTER",
}

/**
 * AddToasterAction
 * Reducer action that corresponds to the `addToaster` action type
 */
export interface AddToasterAction {
    type: GlobalToasterActionTypes.addToaster;
    toaster: GlobalToasterProps;
}

/**
 * RemoveToasterAction
 * Reducer action that corresponds to the `removeToaster` action type
 */
export interface RemoveToasterAction {
    type: GlobalToasterActionTypes.removeToaster;
}

/**
 * GlobalToasterAction
 * A union type of the `AddToasterAction` & `RemoveToasterAction` action types
 */
export type GlobalToasterAction = AddToasterAction | RemoveToasterAction;

// // // //
// Action creator functions

/**
 * buildAddToasterAction
 * Builds an `AddToasterAction` Redux action
 * @param toaster - see GlobalToasterProps
 */
export function buildAddToasterAction(
    toaster: GlobalToasterProps
): AddToasterAction {
    return {
        type: GlobalToasterActionTypes.addToaster,
        toaster,
    };
}

/**
 * buildRemoveToasterAction
 * Builds a `RemoveToasterAction` Redux action
 */
export function buildRemoveToasterAction(): RemoveToasterAction {
    return {
        type: GlobalToasterActionTypes.removeToaster,
    };
}

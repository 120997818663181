import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import { PrimaryIntegrationUsersFetcher } from "@src/modules/usage/components/primary_integration_user/list_fetcher";
import { PrimaryIntegrationUser } from "@src/schema/PrimaryIntegrationUser";
import { ResolverProps } from "./component";

// // // //

export function PrimaryIntegrationUserResolverLayout(
    props: ResolverProps<PrimaryIntegrationUser> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const email: string | undefined = props.params?.email;

    if (email === undefined) {
        captureMessage(
            "PrimaryIntegrationUserResolver - params.email is undefined"
        );
        return null;
    }

    const filtersInput: FiltersInput = {
        organizationID: props.user.organizationID,
        defaults: [
            {
                fieldName: "email",
                val: email,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };

    return (
        <PrimaryIntegrationUsersFetcher filtersInput={filtersInput}>
            {({ loading, primaryIntegrationUsers }) => {
                const primaryIntegrationUser:
                    | PrimaryIntegrationUser
                    | undefined = primaryIntegrationUsers.find(
                    (primaryIntegrationUser: PrimaryIntegrationUser) =>
                        primaryIntegrationUser?.email === email
                );

                // Resolve Value
                if (props.children) {
                    return props.children({
                        value: primaryIntegrationUser || null,
                        loading,
                    });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (primaryIntegrationUser === undefined) {
                    captureMessage(
                        `PrimaryIntegrationUser - ${email} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                // Render Breadcrumb
                return (
                    <React.Fragment>
                        {primaryIntegrationUser.email}
                    </React.Fragment>
                );
            }}
        </PrimaryIntegrationUsersFetcher>
    );
}

export const PrimaryIntegrationUserResolver: React.ComponentType<ResolverProps<
    PrimaryIntegrationUser
>> = withUser(PrimaryIntegrationUserResolverLayout);

import { HorizontalDivider } from "@src/shared_modules/horizontal_divider";
import classnames from "classnames";
import get from "lodash.get";
import * as React from "react";

import { SuggestedFilter, SuggestedFilterLabel } from "./graphql";
import styles from "./styles.module.css";

// // // //

/**
 * SuggestedFilterCardProps
 * @param suggestedFilter - The SuggestedFilter for which the LinkCC is being rendered
 */
interface SuggestedFilterCardProps {
    suggestedFilter: SuggestedFilter;
    className?: string;
    children: React.ReactNode;
}

/**
 * SuggestedFilterCard
 * Renders a card for a single SuggestedFilter
 * @param props - see SuggestedFilterCardProps
 */
export function SuggestedFilterCard(props: SuggestedFilterCardProps) {
    const { suggestedFilter, className = "", children } = props;

    // Safely pulls labels from suggestedFilter
    const suggestedFilterLabels: SuggestedFilterLabel[] =
        get(suggestedFilter, "labels.defaults", []) || [];

    // Defines string array of labels to render in a comma-separated list
    const labels: string[] = suggestedFilterLabels.map(
        (sfLabel) => sfLabel.content.name
    );

    // Defines flag indicating whether or not the footer should be rendered
    const shouldRenderFooter: boolean =
        labels.length > 0 || suggestedFilter.vendor !== null;

    return (
        <div
            className={classnames(
                "pt-30-px pb-20-px px-30-px bg-white border-grey-border rounded",
                {
                    [className]: className !== "",
                }
            )}
        >
            <div className="row">
                <div className="col-sm-12">
                    <p className="font-secondary-bold font-size-14-px text-updated-black">
                        {suggestedFilter.name}
                    </p>
                </div>
            </div>

            <div className="row mt-10-px">
                <div className="col-sm-8 d-flex flex-column">
                    <p className="font-secondary font-size-14-px text-grey-mid line-height-19-px">
                        {suggestedFilter.content.description}
                    </p>
                </div>
                <div className="col-sm-4 d-flex flex-column align-items-end">
                    {children}
                </div>
            </div>

            {shouldRenderFooter && (
                <React.Fragment>
                    <HorizontalDivider className="my-15-px" />

                    <div
                        className={classnames(
                            "d-flex align-items-center justify-content-between",
                            styles.footer
                        )}
                    >
                        {/* Renders SuggestedFilter.vendor */}
                        <div className="d-flex">
                            {suggestedFilter.vendor && (
                                <div className="d-flex align-items-center">
                                    <img
                                        className="tw-max-h-12 tw-rounded-md"
                                        src={suggestedFilter.vendor.logo}
                                    />
                                    <p className="font-secondary-bold font-size-14-px text-updated-black ml-10-px">
                                        {suggestedFilter.vendor.name}
                                    </p>
                                </div>
                            )}
                        </div>

                        {/* Renders SuggestedFilter.labels */}
                        {labels.length > 0 && (
                            <div className="d-flex align-items-center font-size-12-px text-updated-black">
                                <p className="font-secondary-bold pr-5-px">
                                    Labels:
                                </p>
                                <p
                                    className={classnames(
                                        "font-secondary",
                                        styles.tags
                                    )}
                                >
                                    {labels.join(", ")}
                                </p>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

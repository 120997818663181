/**
 * MANAGE_COLUMNS_PARAM
 * Defines a constant for the RouteState.params key used to show/hide the `ManageColumnsModal` component
 */
export const MANAGE_COLUMNS_PARAM = "manage_columns";

/**
 * COLUMN_SETTINGS_PARAM
 * Defines a constant for the RouteState.params key used to store the local `ColumnSettings` array
 */
export const COLUMN_SETTINGS_PARAM = "column_settings";

/**
 * COMPRESSED_COLUMN_SETTINGS_PARAM
 * Defines a constant for the RouteState.params key used to store the compressed local `ColumnSettings` array
 */
export const COMPRESSED_COLUMN_SETTINGS_PARAM = "columnsettings";

/**
 * USER_DASHBOARD_SETTING_COLUMNS_SETTINGS_KEY
 * The key inside UserDashboardSettings.value to which the user's ColumnSetting[] data is assigned
 */
export const USER_DASHBOARD_SETTING_COLUMNS_SETTINGS_KEY: string =
    "column-settings";

import gql from "graphql-tag";
import { Pagination } from "@src/schema/Pagination";
import { Webhook } from "./Webhook";

// // // //

export interface WebhooksList {
    results: Webhook[];
    pagination: Pagination;
    __typename: "WebhooksList";
}

export const WEBHOOKS_LIST_FRAGMENT = gql`
    fragment WEBHOOKS_LIST_FRAGMENT on WebhooksList {
        results {
            id
            organizationID
            name
            url
            insecureSSL
            hasSecret
            isActive
            createdAt
        }
        pagination {
            pageCount
            rangeStart
            rangeEnd
            page
            totalItems
            itemsPerPage
            sort
            direction
        }
    }
`;

import { webConfig } from "@src/config";
import "isomorphic-fetch";

// genApiUrl generates a url to submit via a url + app
export function genApiUrl(
    url: string,
    app: string = webConfig.apiHost
): string {
    // if we get a route that already has http afixed to it we just let go through otherwise we attach an app
    if (url.startsWith("http")) {
        return url;
    }
    return `${app}/${url}`;
}

export function genApiHeaders() {
    // there is no Header on the server so by doingthe window check we can check if we're issuing a request on the server
    if (typeof window === "undefined") {
        return {};
    }
    return new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
    });
}

interface RequestOptions {
    mode: "cors" | "navigate" | "no-cors" | "same-origin";
    credentials: "include" | "omit" | "same-origin";
    cache:
        | "default"
        | "force-cache"
        | "no-cache"
        | "no-store"
        | "only-if-cached"
        | "reload";
}

export function genApiOpts(): RequestOptions {
    return {
        mode: "cors",
        credentials: "include",
        cache: "no-cache",
    };
}

// request sends a request using fetch to an api
export function request(
    url: string,
    apiOpts?: RequestInit,
    app: string = webConfig.apiHost
) {
    const baseOpts = genApiOpts();
    const updatedHeaders: RequestInit = {
        headers: genApiHeaders(),
        ...baseOpts,
        ...apiOpts,
    };

    return fetch(genApiUrl(url, app), updatedHeaders);
}

import { RouteState } from "@src/shared_modules/router/component";
import React from "react";
import { SyncStatusRecipientsModal } from "@src/modules/integrations/components/sync_status_recipients_modal";
import { routes } from "@src/routes";
import { getBreadcrumbNodes } from "./getBreadcrumbNodes";
import { breadcrumbNodeMap } from "./breadcrumbRoutes";
import { BreadcrumbNode, BreadcrumbState } from "./types";
import { Breadcrumb } from "./Breadcrumb";
import { DisplayResolver } from "../display_resolver/component";

// // // //

export function Breadcrumbs(props: {
    route: RouteState;
    featureFlags: Record<string, boolean>;
}) {
    const { route, featureFlags } = props;

    if (breadcrumbNodeMap[route.nextPathname] === undefined) {
        return null;
    }

    const nodes: BreadcrumbNode[] = getBreadcrumbNodes({
        route,
        breadcrumbNodes: [],
        currentNode: breadcrumbNodeMap[route.nextPathname],
    });

    let crumbs: BreadcrumbState[] = nodes.map((crumb: BreadcrumbNode) =>
        crumb.getState(route, featureFlags)
    );

    // Filter for enabled breadcrumbs
    crumbs = crumbs.filter((crumb) => crumb.enabled);

    return (
        <nav
            className="bg-white tw-top-0 tw-z-50 tw-flex tw-shadow"
            aria-label="Breadcrumb"
        >
            <div className="tw-max-w-screen-xl tw-w-full tw-flex tw-justify-between">
                <ol className="tw-px-7 tw-py-7 tw-flex tw-sm:px-6 tw-lg:px-8 tw-text-2xl tw-m-0">
                    {crumbs.map((crumb, index) => (
                        <Breadcrumb
                            breadcrumb={crumb}
                            key={index}
                            isLastBreadcrumb={index === crumbs.length - 1}
                        >
                            <DisplayResolver {...crumb.resolverProps} />
                        </Breadcrumb>
                    ))}
                </ol>
                {/* NOTE - this only renders on the IntegrationListing page */}
                {route.nextPathname === routes.integrations && (
                        <SyncStatusRecipientsModal />
                    )}
            </div>
        </nav>
    );
}

import {
    APPINFO_ID_FIELD_NAME,
    REPORT_ID_FIELD_NAME,
} from "@src/modules/terminated_user_report/components/app_user_report_bucket/advanced_filters/constants";
import { UserReportResultBucketListFetcher } from "@src/modules/terminated_user_report/components/app_user_report_bucket/list_fetcher";
import { AppUserReportResultBucket } from "@src/schema/AppUserReportResultBucket";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { useUserState } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import React from "react";
import { DEFAULT_PAGINATION } from "../../modules/advanced_filters/components/advanced_filters/constants";
import { ResolverProps } from "./component";

// // // //

// Takes a reportID + appInfoID and resolves a bucket to props.children or–if !props.children - resolves to the
// application name that the bucket pertains to
export function UserReportResultBucketResolver(
    props: ResolverProps<AppUserReportResultBucket>
) {
    const userState = useUserState();

    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const appInfoID: string | undefined = props.params?.appInfoID;

    if (appInfoID === undefined) {
        captureMessage(
            "UserReportResultBucketResolver - params.appInfoID is undefined"
        );
        return null;
    }

    const reportID: string | undefined = props.params?.reportID;

    if (reportID === undefined) {
        captureMessage(
            "UserReportResultBucketResolver - params.reportID is undefined"
        );
        return null;
    }

    // Define filters for UserReportResultBucketListFetcher
    const filtersInput: FiltersInput = buildFiltersInput({
        reportID,
        appInfoID,
        organizationID: userState.organizationID,
    });

    return (
        <UserReportResultBucketListFetcher filtersInput={filtersInput}>
            {({ loading, userReportResultBuckeList }) => {
                const bucket: AppUserReportResultBucket | undefined =
                    userReportResultBuckeList[0];

                if (props.children) {
                    return props.children({ value: bucket, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (bucket === undefined) {
                    captureMessage(
                        `UserReportResultBucket - ${appInfoID} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return (
                    <React.Fragment>
                        {bucket?.appInfo?.applicationName}
                    </React.Fragment>
                );
            }}
        </UserReportResultBucketListFetcher>
    );
}

/**
 * Function that builds the FiltersInput for the UserReportResultBucketListFetcher
 */
export function buildFiltersInput(props: {
    reportID: string;
    appInfoID: string;
    organizationID: number;
}): FiltersInput {
    const { reportID, appInfoID, organizationID } = props;
    return {
        organizationID,
        defaults: [
            {
                fieldName: REPORT_ID_FIELD_NAME,
                val: reportID,
                expression: FilterExpression.eq,
                filters: [],
            },
            {
                fieldName: APPINFO_ID_FIELD_NAME,
                val: appInfoID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            ...DEFAULT_PAGINATION,
        },
    };
}

import { track } from "@src/analytics/track";
import {
    OrganizationFieldAttributes,
    OrganizationFieldInputType,
    OrganizationFieldType,
} from "../requests/custom_fields";

// // // //

// customFields are the custom fields events to track
enum CustomFieldsEvents {
    dragField = "Custom Field Dragged",
    saveFieldOrder = "Custom Field Save Org Field Order",
    clickFieldType = "Custom Field Type Clicked",
    addFieldValue = "Custom Field Value Added",
    saveField = "Custom Field Updated/Created",
    clickDeleteField = "Custom Field Delete Button Clicked",
    saveAppField = "Save application fields",
}

// trackDragField just tracks that a user dragged a field
export function trackDragField() {
    track(CustomFieldsEvents.dragField, {});
}

// trackSaveOrgFieldOrder tracks that a user is saving the org field order
export function trackSaveOrgFieldOrder() {
    track(CustomFieldsEvents.saveFieldOrder, {});
}

// trackClickFieldType tracks that a field type was clicked on the edit org field page
export function trackClickFieldType(fieldType: string) {
    track(CustomFieldsEvents.clickFieldType, { fieldType });
}

// trackAddFieldValue tracks that a field value was added on the edit org field page
export function trackAddFieldValue() {
    track(CustomFieldsEvents.addFieldValue, {});
}

// trackSaveField tracks that a field was updated/created
export function trackSaveField(
    id: number,
    name: string,
    fieldType: OrganizationFieldType,
    inputType: OrganizationFieldInputType,
    attributes: OrganizationFieldAttributes,
    editable: boolean,
    filterable: boolean
) {
    track(CustomFieldsEvents.saveField, {
        id,
        name,
        fieldType,
        inputType,
        attributes,
        editable,
        filterable,
    });
}

// trackClickDeleteField tracks that a user clicked the delete field button
export function trackClickDeleteField(id: number) {
    track(CustomFieldsEvents.clickDeleteField, { id });
}

// trackSaveAppField tracks that a user tried to
export function trackSaveAppField(vendorID: number) {
    track(CustomFieldsEvents.saveAppField, {
        vendor_id: vendorID,
    });
}

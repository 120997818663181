import { BulkData } from "../../vendor_listing_v1/types";
import {
    Action,
    DROP_BULK_DATA,
    SET_BULK_DATA_ITEM,
    UNSET_BULK_DATA_ITEM,
} from "./actions";

// // // //

// handleSetBulkData
// Handles state change for SET_BULK_DATA_ITEM action
// Tracks the change for an individual value in state.bulkData
function handleSetBulkData(state: BulkData = {}, action: Action) {
    const { addPayload } = action;

    // Assigns the field's changes to bulkData
    return {
        ...state,
        [addPayload.appField.id]: {
            id: addPayload.appField.id,
            appID: addPayload.appField.appID,
            orgFieldID: addPayload.orgField.id,
            data: addPayload.data,
        },
    };
}

// handleUnsetBulkData
// Handles state change for UNSET_BULK_DATA_ITEM action
// Removes a tracked change for an individual value in state.bulkData
function handleUnsetBulkData(state: BulkData = {}, action: Action) {
    const appFieldId = action.removePayload.appField.id;

    // Checks if there are any changes stored for the appField
    // Remove changes if they exist
    if (state[appFieldId] !== undefined) {
        const removeItemState = { ...state };
        delete removeItemState[appFieldId];
        return { ...removeItemState };
    }
    return state;
}

// handleDropBulkData
// Drops all changes in state
function handleDropBulkData() {
    return {};
}

// // // //

// vendorBulkEdit reducer definition
export function vendorBulkEdit(state: BulkData = {}, action: Action) {
    switch (action.type) {
        case SET_BULK_DATA_ITEM:
            return handleSetBulkData(state, action);
        case UNSET_BULK_DATA_ITEM:
            return handleUnsetBulkData(state, action);
        case DROP_BULK_DATA:
            return handleDropBulkData();
        default:
            return state;
    }
}

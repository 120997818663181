import { Transition } from "@headlessui/react";
import classnames from "classnames";
import * as React from "react";
import ReactDom from "react-dom";

// // // //

/**
 * Modal
 * General-purpose Modal component
 */
export function Modal(props: {
    children: React.ReactNode;
    show: boolean;
    allowClose?: boolean;
    size?: "md" | "lg";
    onHide?: () => void;
}) {
    const { show, allowClose = true, size = "md" } = props;

    return (
        <ModalPortal>
            <Transition.Root show={show} as={React.Fragment}>
                <div className="tw-fixed tw-z-[100] tw-inset-0 tw-overflow-y-auto">
                    <div className="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0"
                            enterTo="tw-opacity-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100"
                            leaveTo="tw-opacity-0"
                        >
                            <div
                                className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                    if (allowClose && props.onHide) {
                                        props.onHide();
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "esc" && props.onHide) {
                                        props.onHide();
                                    }
                                }}
                            />
                        </Transition.Child>

                        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                        <span
                            className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>

                        {/* Modal panel, show/hide based on modal state. */}
                        <Transition.Child
                            as={React.Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <div
                                className={classnames(
                                    "tw-inline-block tw-align-bottom tw-bg-white tw-text-black tw-rounded-2xl tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle",
                                    {
                                        "sm:tw-max-w-lg sm:tw-w-full":
                                            size === "md",
                                        "tw-max-w-4xl tw-w-full": size === "lg",
                                    }
                                )}
                                role="dialog"
                                aria-modal="true"
                                aria-labelledby="modal-headline"
                            >
                                {props.children}
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Transition.Root>
        </ModalPortal>
    );
}

// // // //

/**
 * ModalPortal
 * Implements a React Portal to render modals in a component outside the parent component's DOM tree
 * This allows modals to be positioned _anywhere_ on the screen without needing to worry about Z-Index or Overflow CSS conflicts/collisions
 * **IMPORTANT** - a <div> with the ID `modal-container` **MUST** be present in both Storybook and Next.js for this to work correctly.
 * Doc: https://reactjs.org/docs/portals.html
 * TODO - ensure #modal-container is present in both Next.js + Storybook
 */
function ModalPortal(props: { children: React.ReactNode }) {
    // Get the DOM node where the portal will render props.children
    const domNode = document.getElementById("modal-container");

    // Return null if dom element isn't present
    // (this is mostly a fail-safe for Storybook)
    if (!domNode) {
        return null;
    }

    // Render props.children inside the React Portal
    return ReactDom.createPortal(props.children, domNode);
}

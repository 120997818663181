import { track } from "./track";

// // // //

enum SupportEvents {
    launched = "Support Chat Launched",
}

export function trackSupportLaunched(message: string) {
    track(SupportEvents.launched, { message });
}

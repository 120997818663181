import { PurchaseOrder } from "../PurchaseOrder";
import { price01 } from "./Price";

// // // //

export const purchaseOrder01: PurchaseOrder = {
    id: "340af7e0-1701-5703-a2b2-fa576e978d9b",
    orgID: 0,
    source: "coupa",
    instance: "1",
    createdAt: "2018-02-20T18:50:39.194619Z",
    updatedAt: "2020-02-20T18:50:39.194619Z",
    internalId: "1",
    currency: "1",
    sourceCreatedAt: "2018-02-20T18:50:39.194619Z",
    createdBy: "",
    paymentMethod: "credit card",
    poNumber: "01234",
    requester: "alex",
    status: "live",
    transmission_status: null,
    metadata: null,
    additionalData: {},
    price: null,
    vendorID: 2000,
    vendor: {
        id: 2000,
        logo: "https://logo.clearbit.com/confluence.com",
        name: "Confluence",
        domain: "confluence.com",
        __typename: "Vendor",
    },
    __typename: "PurchaseOrder",
};

export const purchaseOrder02: PurchaseOrder = {
    id: "7392b653-c242-550c-ba26-2d1a7ce30fba",
    orgID: 0,
    source: "coupa",
    instance: "1",
    createdAt: "2018-02-20T18:50:39.194619Z",
    updatedAt: "2020-02-20T18:50:39.194619Z",
    internalId: "1",
    currency: "1",
    sourceCreatedAt: "2018-02-20T18:50:39.194619Z",
    createdBy: "",
    paymentMethod: "credit card",
    poNumber: "01234",
    requester: "alex",
    status: "live",
    transmission_status: null,
    metadata: null,
    additionalData: {},
    price: price01,
    vendorID: 2000,
    vendor: {
        id: 2000,
        logo: "https://logo.clearbit.com/confluence.com",
        name: "Confluence",
        domain: "confluence.com",
        __typename: "Vendor",
    },
    __typename: "PurchaseOrder",
};

import { CoreEvents } from "@src/analytics/events";
import {
    trackBaseLinkClicked,
    trackLinkClicked,
} from "@src/analytics/trackLinkClicked";
import { captureMessage } from "@src/util/analytics";
import NextLink from "next/link";
import * as React from "react";

// // // //

export interface LinkProps {
    href: string;
    prefetch?: boolean;
    children?: React.ReactNode;
    eventName?: string;
    eventProps?: { [key: string]: any };
    componentName?: string;
    [key: string]: any;
}

function trackLinkClick(props: LinkProps) {
    if (typeof props.href !== "string") {
        // this should never happen
        captureMessage(
            "core_js/modules/link/component.trackLinkClick received non-string href"
        );
        return;
    }

    const {
        href,
        eventName = CoreEvents.linkClicked, // add default event name
        eventProps = {}, // add default event props
        componentName = "",
    } = props;

    trackLinkClicked(eventName, href, componentName, eventProps);

    trackBaseLinkClicked(href, componentName);
}

// Link is a wrapper around the next/link that automatically adds tracking to the link component
export function Link(props: LinkProps) {
    const { href, children } = props;

    // we are setting the passHref attribute of NextLink to pass href prop to children
    return (
        <NextLink
            href={href}
            passHref
            tabIndex={0}
            className={props.className}
            onClick={() => {
                trackLinkClick({ ...props, href });
            }}
        >
            {children}
        </NextLink>
    );
}

import { BreadcrumbNode } from "../types";
import {
    userIndSpendChartState,
    userIndSpendState,
    userNameState,
    userSpendState,
    usersState,
    userUsageState,
} from "./state";

// // // //

export const usersNode: BreadcrumbNode = {
    getState: usersState,
    parent: null,
};

const userNameNode: BreadcrumbNode = {
    getState: userNameState,
    parent: usersNode,
};

export const userUsageNode: BreadcrumbNode = {
    getState: userUsageState,
    parent: userNameNode,
};

const userSpendNode: BreadcrumbNode = {
    getState: userSpendState,
    parent: userNameNode,
};

export const userIndSpendNode: BreadcrumbNode = {
    getState: userIndSpendState,
    parent: userSpendNode,
};

export const userIndSpendChartNode: BreadcrumbNode = {
    getState: userIndSpendChartState,
    parent: userSpendNode,
};

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { getURLWithFiltersInput } from "@src/modules/advanced_filters/components/advanced_filters/getURLWithFiltersInput";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { buildQsFromObject } from "@src/util/route";
import * as React from "react";

// // // //

interface PageProps {
    page: number;
    keyVal?: string;
    children?: any;
    filtersInput?: FiltersInput;
}

type PaginationProps = {
    maxPages?: number;
    pageCount: number;
} & PageProps;

export function genPaginationRange(
    page: number,
    pageCount: number,
    maxPages: number
): number[] {
    const pagesFromMiddle: number = Math.floor(maxPages / 2);
    const startNum: number = Math.max(2, page - pagesFromMiddle);
    const endNum: number = Math.min(pageCount, startNum + maxPages);
    const paginationRange: number[] = [];

    for (let i: number = startNum; i < endNum; i += 1) {
        paginationRange.push(i);
    }

    return paginationRange;
}

function Page({
    page,
    keyVal = `page-${page}`,
    children,
    route,
    filtersInput,
}: PageProps & { route: RouteState }) {
    // generate the query string for the link
    let url: string = "";

    // Handle advanced filters - must update route.params.filters.pagination.page
    if (filtersInput) {
        // Updates the `filtersInput.pagination.page` value
        const updatedFiltersInput = {
            ...filtersInput,
            pagination: {
                ...filtersInput.pagination,
                page,
            },
        };

        // Gets the URL with the latest FiltersInput + pagination state
        url = getURLWithFiltersInput({
            location: route.location,
            params: route.params,
            filtersInput: updatedFiltersInput,
        });
    } else {
        // Handle standard pagination
        // Builds query string with route.params + page
        const qs: string = buildQsFromObject({
            ...route.params,
            page,
        });

        // Defines URL using route.location + latest query string
        url = `${route.location}${qs}`;
    }

    // Defines content to render inside the LinkCSS
    const content = children || page?.toLocaleString() || "0";

    return (
        <li className="tw-py-0 tw-px-1.5" key={keyVal}>
            <LinkCC
                href={{
                    pathname: route.nextPathname,
                    query: route.pathParams,
                }}
                as={url}
                // the children indicate that we're using the chevron so we don't want the page class name in that case
                className={
                    children
                        ? ""
                        : "text-grey-dim border-grey-dim !tw-border-t-0 !tw-border-l-0 !tw-border-r-0 no-underline hover:tw-text-blue-400"
                }
            >
                {content}
            </LinkCC>
        </li>
    );
}

function ActivePage(props: { page: number; children?: any }) {
    const { page, children } = props;
    const content = children || page?.toLocaleString() || "0";

    return (
        <li className="tw-py-0 tw-px-1.5" key={`active-page-${page}`}>
            <span className="text-navy border-navy !tw-border-t-0 !tw-border-l-0 !tw-border-r-0">
                {content}
            </span>
        </li>
    );
}

function PaginationMiddle({
    page,
    pageCount,
    maxPages,
    route,
    filtersInput,
}: {
    maxPages: number;
} & PaginationProps & { route: RouteState }) {
    // if there are only 2 pages we don't reneder any middle pages
    if (pageCount === 2) {
        return null;
    }

    const midPaginationRange: number[] = genPaginationRange(
        page,
        pageCount,
        maxPages
    );

    // if the first page in the range is 2 then we don't want to render the dots for the first page
    // if the last page in the range is one less than the page count we don't want to render the dots because it goes up to the last page
    return (
        <span className="tw-flex">
            {midPaginationRange[0] === 2 ? null : (
                <li className="tw-py-0 tw-px-1.5">...</li>
            )}
            {midPaginationRange.map((pageNum: number) => {
                const key = `pagination-middle-${pageNum}`;
                return page === pageNum ? (
                    <ActivePage key="pagination-middle-active" page={page} />
                ) : (
                    <Page
                        page={pageNum}
                        key={key}
                        route={route}
                        filtersInput={filtersInput}
                    />
                );
            })}
            {midPaginationRange[midPaginationRange.length - 1] ===
            pageCount - 1 ? null : (
                <li className="tw-py-0 tw-px-1.5">...</li>
            )}
        </span>
    );
}

export function Pagination(props: PaginationProps & { route: RouteState }) {
    const { page, pageCount, maxPages = 4, route, filtersInput } = props;

    // if page count is equal to 1 there is no pagination
    if (pageCount === 0 || pageCount === 1) {
        return null;
    }

    return (
        <ul className="tw-flex tw-items-center tw-py-3 tw-px-0 text-grey-dim">
            <h5 className="font-size-14-px text-navy text-uppercase tw-pr-3">
                Page
            </h5>
            {page === 1 ? (
                <ActivePage page={1} />
            ) : (
                <Page page={1} route={route} filtersInput={filtersInput} />
            )}
            <PaginationMiddle
                {...{
                    page,
                    pageCount,
                    maxPages,
                    route,
                    filtersInput,
                }}
            />
            {page === pageCount ? (
                <ActivePage page={page} />
            ) : (
                <Page
                    page={pageCount}
                    route={route}
                    filtersInput={filtersInput}
                />
            )}
        </ul>
    );
}

export const PaginationCC: React.ComponentType<PaginationProps> = withRouter(
    Pagination
);

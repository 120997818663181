import React from "react";

export function ConnectedAppsTileWarning(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <path
                d="M19.9375 3.60938L11.5375 0.171875C11.3187 0.0859375 10.9688 0 10.75 0C10.4875 0 10.1375 0.0859375 9.91875 0.171875L1.51875 3.60938C0.73125 3.95312 0.25 4.68359 0.25 5.5C0.25 16.5859 8.51875 22 10.7063 22C12.9812 22 21.25 16.5 21.25 5.5C21.25 4.68359 20.725 3.95312 19.9375 3.60938ZM9.7 6.53125C9.7 5.97266 10.1375 5.5 10.75 5.5C11.3187 5.5 11.8 5.97266 11.8 6.53125V11.3438C11.8 11.9453 11.3187 12.375 10.75 12.375C10.1375 12.375 9.7 11.9453 9.7 11.3438V6.53125ZM10.75 16.5C9.9625 16.5 9.35 15.8984 9.35 15.125C9.35 14.3945 9.9625 13.75 10.75 13.75C11.4937 13.75 12.15 14.3945 12.15 15.125C12.15 15.8984 11.4937 16.5 10.75 16.5Z"
                fill="#FFC257"
            />
        </svg>
    );
}

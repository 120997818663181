import React from "react";

export function SailpointWhiteCoin(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 344.19 334.88"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g>
                <g>
                    <path
                        d="M227.34 194.15L194.81 0 0 242.84 235.5 242.84 227.34 194.15z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M227.04 119.77L247.66 242.84 344.19 242.84 209.73 16.43 227.04 119.77z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M22.73 254.84L250.93 334.88 237.51 254.84 22.73 254.84z"
                        fill="#fff"
                    ></path>
                    <path
                        d="M262.54 331.51L340.12 254.84 249.68 254.84 262.54 331.51z"
                        fill="#fff"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

import React from "react";

export function ConnectedAppsTileDanger(props?: {
    className?: string;
    label: string;
}): JSX.Element {
    return (
        <svg
            className={props.className}
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g clipPath="url(#clip0_100_284)">
                <path
                    d="M22 15.0924C22.003 14.5367 21.838 13.9924 21.5256 13.5277L13.5292 1.34399C13.2562 0.931754 12.8816 0.592819 12.4397 0.358132C11.9978 0.123446 11.5026 0.000488281 10.9994 0.000488281C10.4963 0.000488281 10.0011 0.123446 9.55921 0.358132C9.1173 0.592819 8.74271 0.931754 8.46965 1.34399L0.473297 13.5056C0.184652 13.9465 0.0219729 14.4547 0.0020738 14.9775C-0.0178253 15.5003 0.105777 16.0189 0.360107 16.4795C0.615362 16.9409 0.99427 17.3261 1.4564 17.5939C1.91852 17.8617 2.44652 18.0021 2.98405 18.0001H18.9953C19.5327 18.0041 20.061 17.8645 20.5228 17.5964C20.9846 17.3284 21.3624 16.9421 21.6151 16.4795C21.8654 16.0578 21.998 15.5796 22 15.0924Z"
                    fill="#EE483B"
                />
                <path
                    d="M12.5 13.4771H9.5V15.9998H12.5V13.4771Z"
                    fill="white"
                />
                <path d="M12.5 5H9.5V11.7283H12.5V5Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_100_284">
                    <rect width="22" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

import {
    FieldType,
    FilterExpression,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { getDefaultDateRangeExpression } from "./getDefaultDateRangeExpression";

// // // //

/**
 * getDefaultExpression
 * Gets a default `FilterExpression` for a particular `PossibleFilter`
 * NOTE - this is likely a temporary crutch until we
 * have `PossibleFilter.defaultExpression` implemented through the entire stack
 * @param possibleFilter - the PossibleFilter whose default `FilterExpression` we're returning
 */
export function getDefaultExpression(
    possibleFilter: PossibleFilter
): FilterExpression {
    // Return possibleFilter.expressions[0] IFF fieldType is `date_range`
    // NOTE - this will be removed when we return TWO `date_range` filters from the APi
    if (possibleFilter.fieldType === FieldType.date_range) {
        return getDefaultDateRangeExpression(possibleFilter.expressions);
    }

    // Otherwise, return FilterExpression.eq
    return possibleFilter.defaultExpression;
}

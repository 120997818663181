import React from "react";

export function UsageRankThirdParty(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="22"
            viewBox="0 0 18 22"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            >
                <g
                    stroke="currentColor"
                    strokeWidth="2"
                    transform="translate(-559 -366)"
                >
                    <g transform="translate(560 367)">
                        <path d="M5.2 13h0A5.2 5.2 0 010 7.8V5.2a1.3 1.3 0 011.3-1.3h7.8a1.3 1.3 0 011.3 1.3v2.6A5.2 5.2 0 015.2 13z"></path>
                        <path d="M2.6 3.9L2.6 0"></path>
                        <path d="M7.8 3.9L7.8 0"></path>
                        <path d="M5.2 13v2.6a2.6 2.6 0 002.6 2.6h0a2.6 2.6 0 002.6-2.6h0A2.6 2.6 0 0113 13h0a2.6 2.6 0 012.6 2.6v3.9"></path>
                        <path d="M3.9 6.5L6.5 6.5"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import { spendIndNode } from "../spendInd";
import { BreadcrumbNode } from "../types";
import { purchaseOrderVendorListingState } from "./state";

// // // //

export const purchaseOrderVendorListingNode: BreadcrumbNode = {
    getState: purchaseOrderVendorListingState,
    parent: spendIndNode,
};

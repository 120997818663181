import { appSpendChartInd, routes } from "@src/routes";
import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const spendIndChartState = (route: RouteState) => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);
    return {
        linkProps: {
            href: {
                pathname: routes.spendIndividualChart,
                query: {
                    vendorID: String(vendorID),
                },
            },
            as: appSpendChartInd(vendorID),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "App Spend Chart Individual",
                vendorID,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.spendIndividualChart,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Chart" },
        },
    };
};

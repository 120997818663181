import {
    FieldType,
    Filter,
    FilterExpression,
    FiltersInput,
    FilterVal,
    PossibleFilter,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { FilterFormValueInput } from "@src/modules/advanced_filters/components/advanced_filter_editor/FilterFormValueInput";
import * as React from "react";
import { InputSize } from "../../../../shared_modules/input";
import { buildFilterFromPossibleFilter } from "./buildFilterFromPossibleFilter";
import { DateRangeSeparator } from "./DateRangeSeparator";
import { getFilterFromPossibleFilter } from "./getFilterFromPossibleFilter";

// // // //

/**
 * DefaultFiltersProps
 * @param filtersInput - The user's filter state
 * @param defaultPossibleFilters - Array of `PossibleFilter` instances from the `defaults` property on `Filters`
 * @param setDefaultFilterValue - Sets the value for a single Filter in `props.filtersInput.defaults`
 */
interface DefaultFiltersProps {
    filtersInput: FiltersInput;
    defaultPossibleFilters: PossibleFilter[];
    setDefaultFilterValue: (params: {
        possibleFilter: PossibleFilter;
        val: FilterVal;
        expression: FilterExpression;
    }) => void;
}

/**
 * DefaultFilters
 * Renders the layout for AdvancedFilters UI used on the listing and individual pages
 * @param props - see DefaultFiltersProps
 */
export function DefaultFilters(props: DefaultFiltersProps) {
    // Only render when PossibleFilter.showInline === true
    const inlineFilters: PossibleFilter[] = props.defaultPossibleFilters.filter(
        (p) => p.showInline === true
    );

    return (
        <div className="tw-flex tw-flex-wrap tw-gap-6 tw-items-center tw-py-6">
            {inlineFilters.map(
                (possibleFilter: PossibleFilter, index: number) => {
                    // Finds the `Filter` in filtersInput.default associated with `possibleFilter`
                    let filter:
                        | Filter
                        | undefined = getFilterFromPossibleFilter({
                        possibleFilter,
                        filtersInput: props.filtersInput,
                    });

                    // If no filter is defined, we define a new one using buildFilterFromPossibleFilter
                    if (!filter) {
                        filter = buildFilterFromPossibleFilter({
                            possibleFilter,
                        });
                    }

                    // Return null if possibleFilter.fieldType == hidden
                    if (possibleFilter.fieldType === FieldType.hidden) {
                        return null;
                    }

                    // Return the styled FilterFormValueInput + DateRangeSeparator
                    return (
                        <React.Fragment
                            key={`${possibleFilter.key}-${String(index)}`}
                        >
                            <div className="tw-flex">
                                <FilterFormValueInput
                                    inline
                                    hideDatepickerIcon
                                    selectedFilter={possibleFilter}
                                    debounceTimeoutMs={500}
                                    size={InputSize.md}
                                    placeholder={possibleFilter.content.name}
                                    value={filter.val}
                                    filterExpression={filter.expression}
                                    onChange={(updatedFilterData: {
                                        val: FilterVal;
                                        expression: FilterExpression;
                                    }) => {
                                        props.setDefaultFilterValue({
                                            possibleFilter,
                                            ...updatedFilterData,
                                        });
                                    }}
                                />
                            </div>

                            {/* Renders DateRangeSeparator component */}
                            <DateRangeSeparator
                                className="mr-15-px" // NOTE - this should match the margin CSS at line 105
                                possibleFilters={inlineFilters}
                                index={index}
                            />
                        </React.Fragment>
                    );
                }
            )}
        </div>
    );
}

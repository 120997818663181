import gql from "graphql-tag";

/**
 * PrimaryIntegrationUser
 * PrimaryIntegrationUser centrally ties together a user across many different integrations
 */
export interface PrimaryIntegrationUser {
    name: string;
    email: string;
    organizationID: number;
    __typename: "PrimaryIntegrationUser";
}

export const PRIMARY_INTEGRATION_USER_FRAGMENT = gql`
    fragment PRIMARY_INTEGRATION_USER_FRAGMENT on PrimaryIntegrationUser {
        name
        email
        organizationID
    }
`;

import { FeatureContent } from "@src/shared_modules/feature_content";
import { Loader } from "@src/shared_modules/loader";
import * as React from "react";

import { Vendor } from "./graphql";
import { hasRelatedVendors } from "./hasRelatedVendors";
import { RelatedVendorsModalButton } from "./RelatedVendorsModalButton";
import { IndividualPageType } from "./types";
import { VendorFetcher } from "./VendorFetcher";

// // // //

/**
 * mapPageTypeToFeature
 * Maps each IndividualPageType to a Feature in Contentful that can be accepted by FeatureContent.props.uniqueKey
 */
const mapPageTypeToFeature: { [key in IndividualPageType]: string } = {
    [IndividualPageType.info]: "individual-page-related-vendors-v2-info",
    [IndividualPageType.spend]: "individual-page-related-vendors-v2-spend",
    [IndividualPageType.spendChart]:
        "individual-page-related-vendors-v2-spend-chart",
    [IndividualPageType.usage]: "individual-page-related-vendors-v2-usage",
    [IndividualPageType.teamUsage]:
        "individual-page-related-vendors-v2-team-usage",
    [IndividualPageType.roles]: "individual-page-related-vendors-v2-roles",
    [IndividualPageType.roleAssignments]:
        "individual-page-related-vendors-v2-role-assignments",
    [IndividualPageType.connectedAppsApplications]:
        "individual-page-related-vendors-v2-connected-apps-applications",
    [IndividualPageType.connectedAppsUsers]:
        "individual-page-related-vendors-v2-connected-apps-users",
    [IndividualPageType.purchaseOrders]:
        "individual-page-related-vendors-v2-purchase-orders",
};

/**
 * IndividualPageRelatedVendorsProps
 * @property vendor - the Vendor whose related vendors are being rendered
 * @property individualPage - The IndividualPageType where each RelatedVendor will direct
 */
interface IndividualPageRelatedVendorsProps {
    vendor: Vendor;
    individualPage: IndividualPageType;
}

// // // //

/**
 * IndividualPageRelatedVendors
 * Renders the RelatedVendors + RelatedVendorItem components to be rendered in the VendorTile component on the listing pages
 * @param props - see IndividualPageRelatedVendorsProps
 */
export function IndividualPageRelatedVendors(
    props: IndividualPageRelatedVendorsProps
) {
    const { vendor, individualPage } = props;

    // Defines flags used to determine if this component should simply return null
    const { hasParent, hasChildren, hasSiblings } = hasRelatedVendors(vendor);

    // Return null if there is no parent, children, or siblings data
    if (!hasParent && !hasChildren && !hasSiblings) {
        return null;
    }

    // Renders RelatedVendorItems (includes props.vendor)
    return (
        <FeatureContent uniqueKey={mapPageTypeToFeature[individualPage]}>
            {({ loading, feature }) => {
                if (loading) {
                    return <Loader />;
                }
                return (
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                            <p className="font-secondary-bold text-updated-black font-size-16-px">
                                {feature.header}
                            </p>
                            <p className="font-secondary text-updated-black font-size-14-px mt-15-px">
                                {`${vendor.name} ${feature.description}`}
                            </p>
                        </div>
                        <div className="d-flex flex-column">
                            <RelatedVendorsModalButton
                                vendor={vendor}
                                individualPage={individualPage}
                                className="ml-30-px"
                            />
                        </div>
                    </div>
                );
            }}
        </FeatureContent>
    );
}

// // // //

/**
 * IndividualPageRelatedVendorsCC
 * Renders a IndividualPageRelatedVendors wrapped inside a VendorFetcher + styled <div /> with a <HorizontalDivider />
 * Used to quickly drop the IndividualPageRelatedVendors component on the individual pages
 * @param props.vendorID - See VendorFetcher props
 * @param props.individualPage - See IndividualPageRelatedVendorsProps
 */
export function IndividualPageRelatedVendorsCC(props: {
    vendorID: number;
    individualPage: IndividualPageType;
}) {
    return (
        <VendorFetcher vendorID={props.vendorID}>
            {({ loading, vendor }) => {
                if (loading || vendor === null) {
                    return null;
                }

                // Defines flags used to determine if this component should return null
                const {
                    hasParent,
                    hasChildren,
                    hasSiblings,
                } = hasRelatedVendors(vendor);

                // Return null if there is no parent, children, or siblings data
                if (!hasParent && !hasChildren && !hasSiblings) {
                    return null;
                }

                return (
                    <div className="d-flex flex-row px-30-px py-30-px bg-white border-bottom-grey">
                        <IndividualPageRelatedVendors
                            vendor={vendor}
                            individualPage={props.individualPage}
                        />
                    </div>
                );
            }}
        </VendorFetcher>
    );
}

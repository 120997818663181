import { AppliedFilters } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import get from "lodash.get";
import { SuggestedFilter } from "./graphql";

// // // //

/**
 * isAppliedFilters
 * Accepts `any` and returns a type predicate indicating whether
 * or not the appliedFilters parameter is a valid AppliedFilters instance.
 * @param appliedFilters
 */
function isAppliedFilters(
    appliedFilters: any
): appliedFilters is AppliedFilters {
    // Alias appliedFilters as "af" for short
    const af = appliedFilters;

    // Pulls defaults + advanced + pagination
    const defaults = get(af, "defaults", null);
    const advanced = get(af, "advanced", null);
    const pagination = get(af, "pagination", null);

    // Return false if pagination is null
    if (pagination === null) {
        return false;
    }

    // Return false if BOTH defaults + advanced are null
    if (defaults === null && advanced === null) {
        return false;
    }

    // Returns false if neither value is an array
    // NOTE - we could introspect defaults and advanced, but it's reasonably safe to
    // assume at this point that it's a valid AppliedFilters instance
    return Array.isArray(defaults) || Array.isArray(advanced);
}

/*
 * isSuggestedFilter
 * Accepts `any` variable and returns a type predicate indicating whether
 * or not the suggestedFilter parameter is a valid SuggestedFilter instance.
 * Performs a shallow check on the `suggestedFilter` parameter to ensure validity.
 * @param suggestedFilter - the object we're verifying as a valid SuggestedFilter instance
 * Doc: https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
 * NOTE - this omits checks for organizationID because we will
 * often remove it intentionally when passing it around on the FE
 */
export function isSuggestedFilter(
    suggestedFilter: any
): suggestedFilter is SuggestedFilter {
    // Alias suggestedFilter as "sf" for short
    const sf = suggestedFilter;

    // Safely pulls `filters` value from SuggestedFilter.filters, assigns default value of null
    const filters: any = get(sf, "filters", null);

    return (
        sf !== null &&
        sf !== undefined &&
        filters !== null &&
        typeof (sf as SuggestedFilter).name === "string" &&
        typeof (sf as SuggestedFilter).queryName === "string" &&
        typeof (sf as SuggestedFilter).queryDestination === "string" &&
        Array.isArray((sf as SuggestedFilter).attributes) &&
        isAppliedFilters(filters)
    );
}

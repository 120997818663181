import {
    AppliedFilters,
    Content,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";

// // // //

/**
 * SuggestedFilterLabel
 * Describes a name + content object used to navigate between UsageSuggestedFilters pages
 */
export interface SuggestedFilterLabel {
    name: string;
    filterCount: number;
    content: Content;
    cta: Content;
}

/**
 * SuggestedFilterState
 * The possible state of the suggested filter
 */
export enum SuggestedFilterState {
    active = "active",
    inactive = "inactive",
    wip = "wip",
}

/**
 * SuggestedFilter
 * Interface for describing a single SuggestedFilter
 */
export interface SuggestedFilter {
    id: string;
    organizationID: number;
    name: string;
    filters: AppliedFilters;
    queryName: string;
    queryDestination: string;
    content: Content;
    state: SuggestedFilterState;
    attributes: string[];
    vendorID: number | null;
    vendor: null | {
        name: string;
        logo: string;
    };
    labels: {
        defaults: SuggestedFilterLabel[];
    };
}

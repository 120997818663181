import {
    buildUrlWithParams,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { formatMoment, genNullMoment } from "@src/util/date_util";
import { updateParams } from "@src/util/route";
import moment from "moment";
import * as React from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { QueryParams } from "../../util/route/updateParams_util";

// // // //

interface OwnProps {
    // startName is the query key for the start date
    startName: string;
    // endName is the query key for the end date
    endName: string;
    // defaultStart is the default date to use to start
    defaultStart: moment.Moment | null;
    // labelText specifies the text for the label
    labelText?: string;
}

interface FilterState {
    // focusedInput represents the focused input on the page
    focusedInput: string | null;
    // the start date to use
    startDate: moment.Moment | null;
    // the end date to use
    endDate: moment.Moment | null;
}

// updateDateParam updates the parameters based on the original date, the new date and the query param key.
// the date only gets updated if the original date doesn't equal the new date (this way if for example the
// end date is changed but the start date remains the same we don't accidentally remove the start date or
// vice versa). Please add a test for this
function updateDateParam(
    originalDate: moment.Moment | null,
    newDate: moment.Moment | null,
    key: string,
    params: QueryParams
) {
    if (
        // if both the original date and the new one are equal to null, we shouldn't update the query
        // either because otherwise it would work incorrectly when typing inside the input
        (originalDate === null && newDate === null) ||
        (originalDate != null &&
            newDate != null &&
            originalDate.isSame(newDate))
    ) {
        return params;
    }

    const [updatedParams] = updateParams({
        params,
        key,
        // null value is represented by the "null" string in the query
        val: newDate !== null ? formatMoment(newDate) : "null",
        replace: true,
    });
    return updatedParams;
}

// getParamDate gets the moment date of a query param. it returns the provided default value if there
// is no such param, null if it exists but is empty, or a moment in other cases
export function getParamDate(
    params: QueryParams,
    key: string,
    defaultValue: moment.Moment | null
): moment.Moment | null {
    const val = params[key];
    if (typeof val !== "string") {
        return defaultValue;
    }

    return genNullMoment(val !== "null" ? val : null);
}

// DateRangeFilter is a filter component that allows a user to filter by dates and allows the consumer
// of the filter to set the values of those dates
export class DateRangeFilter extends React.Component<
    OwnProps & { route: RouteState },
    FilterState
> {
    // getDerivedStateFromProps is responsible for handling external updates of query params.
    // i.e. when a filter was reset from the active filters list
    public static getDerivedStateFromProps(
        props: OwnProps & { route: RouteState },
        state: FilterState
    ) {
        const startDate = getParamDate(
            props.route.params,
            props.startName,
            props.defaultStart
        );
        const endDate = getParamDate(
            props.route.params,
            props.endName,
            moment()
        );
        let startDateChanged = true;
        let endDateChanged = true;
        // check if the start date has changed
        if (startDate === null && state.startDate === null) {
            startDateChanged = false;
        }
        if (
            startDate !== null &&
            state.startDate !== null &&
            startDate.isSame(state.startDate)
        ) {
            startDateChanged = false;
        }
        // check if the end date has changed
        if (endDate === null && state.endDate === null) {
            endDateChanged = false;
        }
        if (
            endDate !== null &&
            state.endDate !== null &&
            endDate.isSame(state.endDate)
        ) {
            endDateChanged = false;
        }
        // don't need to update the state if none of the dates changed
        if (!startDateChanged && !endDateChanged) {
            return null;
        }
        const updatedState = {};
        if (startDateChanged) {
            // @ts-ignore
            updatedState.startDate = startDate;
        }
        if (endDateChanged) {
            // @ts-ignore
            updatedState.endDate = endDate;
        }
        return updatedState;
    }

    constructor(props: OwnProps & { route: RouteState }) {
        super(props);

        // we set the dates in the states for two reasons, 1. it leads to a faster feedback loop than the
        // route updating and then updating the date based on the route (there's a lot conversion back and
        // forth with moment objects) 2. and more important this allows users to type in the date, otherwise
        // we don't allow a null date to be set in the params which makes it that a user can't type - with
        // typing 1 becomes even more important from a performance perspective
        const startDate = getParamDate(
            props.route.params,
            props.startName,
            props.defaultStart
        );
        const endDate = getParamDate(
            props.route.params,
            props.endName,
            moment()
        );

        this.state = {
            // set the default end date + start dates
            focusedInput: null,
            startDate,
            endDate,
        };
    }

    public render() {
        // the proptypes below are from the react airbnb documentation that i found helpful to have inline
        return (
            <div className={`dateInput d-flex align-items-center`}>
                {this.props.labelText && (
                    <h6 className="title">{this.props.labelText}</h6>
                )}
                <div className={`dateWrapper d-flex align-items-center`}>
                    <DateRangePicker
                        customInputIcon={
                            <Icon
                                type={IconTypes.Field_Date_Picker}
                                className="datePickerIcon"
                            />
                        }
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="date-range-filter-start" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="date-range-filter-end" // PropTypes.string.isRequired,
                        customArrowIcon={<span className="dash" />}
                        onDatesChange={({ startDate, endDate }) => {
                            this.setState({
                                startDate,
                                endDate,
                            });

                            const { route } = this.props;

                            // generate the params for the start date
                            let params = updateDateParam(
                                this.state.startDate,
                                startDate,
                                this.props.startName,
                                route.params
                            );

                            // generate the params for the end date
                            params = updateDateParam(
                                this.state.endDate,
                                endDate,
                                this.props.endName,
                                params
                            );

                            // reset the page param when the filter is applied
                            delete params.page;
                            const href = buildUrlWithParams({
                                params,
                                location: route.location,
                            });
                            route.updateRouteAction(href, route.nextPathname);
                        }} // PropTypes.func.isRequired,
                        hideKeyboardShortcutsPanel
                        enableOutsideDays
                        isOutsideRange={() => false}
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                            this.setState({
                                focusedInput,
                            })
                        } // PropTypes.func.isRequired,
                    />
                    <i
                        className={`fa fa-caret-down text-grey-mid caret`}
                        aria-hidden="true"
                        onClick={() => {
                            this.setState({ focusedInput: "endDate" });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export const DateRangeFilterCC = withRouter(DateRangeFilter);

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";
import {
    ConnectedAppsList,
    CONNECTED_APPS_LIST_FRAGMENT,
} from "@src/schema/ConnectedAppsList";

// // // //

/**
 * Query for ConnectedAppUser data
 */
export const CONNECTED_APPS_LIST_FETCHER_QUERY = gql`
    query CONNECTED_APPS_LIST_FETCHER_QUERY($filters: FiltersInput!) {
        connectedAppsService {
            connectedApps {
                list(filters: $filters) {
                    ...CONNECTED_APPS_LIST_FRAGMENT
                }
            }
        }
    }
    ${CONNECTED_APPS_LIST_FRAGMENT}
`;

/**
 * Response interface for CONNECTED_APPS_LIST_FETCHER_QUERY
 */
export interface ConnectedAppsListFetcherResponse {
    connectedAppsService: {
        connectedApps: {
            list: ConnectedAppsList;
            __typename: "ConnectedAppsListing";
        };
        __typename: "ConnectedApps";
    };
}

/**
 * Variables interface for CONNECTED_APPS_LIST_FETCHER_QUERY
 */
export interface ConnectedAppsListFetcherVariables {
    filters: FiltersInput;
}

import { trackSupportLaunched } from "@src/analytics/support";

// launchSupport launches a support window on the site
export function launchSupport(message: string) {
    if (typeof window === "undefined" || !("Intercom" in window)) {
        return;
    }

    trackSupportLaunched(message);
    window.Intercom("showNewMessage", message);
}

import * as React from "react";

// Props are the sticky nav props
interface Props {
    // the sticky nav should always have children to render upon (otherwise you don't need a sticky nav)
    children: React.ReactNode;
    // className is the class name to apply to the sticky nav which allows yout to override the styling applied by the sticky nav
    className: string;
    // name is a unique name that should be assigned to the sticky nav - we use this to seamlessly recalculate the heights of sticky navs
    name: string;
}

// RecalcFn is a function that's meant to recalculate the height of sticky navs - it includes the removedHeight so that
type RecalcFn = (removedHeight: number) => void;

/**
 * @description Facilitates the sticky tops recalculating their height in particular if a
 * sticky element loads because of * an api call (like the * sticky element to show a trial) or it is removed from the
 * page and other sticky items need to slide up (like the when the trial is *removed and the other sticky items * need to slide up),
 * recalculate's job is to ensure all the sticky navs have their top's adjusted when an element is added or removed from the page.
 * This was just way * * easier to do that doing it via redux or some state management (especially bec this isn't global but isolated to the sticky nav)
 */
class Recalculate {
    /**
     * @description Stores a key to recalculate function to call, this makes easy and fast to remove items from recalculate
     */
    private recalcFn: { [key: string]: RecalcFn };

    constructor() {
        this.recalcFn = {};
    }

    /**
     * @description Adds a recalculate function to be called
     */
    public addRecalc(fn: RecalcFn, key: string) {
        this.recalcFn[key] = fn;
        this.callRecalc(0);
    }

    /**
     * @description Removes a function to recalculate it's height by a key
     */
    public removeFn(key: string, top: number) {
        delete this.recalcFn[key];
        // anytime we remove an item we have to recalculate it's height
        this.callRecalc(top);
    }

    /**
     * @description Calls recalculate on all the functions in the recalc mappin
     */
    private callRecalc(removedHeight: number) {
        Object.keys(this.recalcFn).map((key) =>
            this.recalcFn[key](removedHeight)
        );
    }
}

/**
 * @description Instance of recalculate that every StickyTop calls, by having one instance we ensure that sticky top is always
 * using the same instance to recalculate all items
 */
const recalc = new Recalculate();

/**
 * @description Component that automatically allows you to stack sticky items at the top of the page on top of each other
 * without any of them needing to know about each other, which allows them to seamlessly stack on top of each
 */
export class StickyTop extends React.Component<
    Props,
    {
        /**
         * @description Ensures that the top of sticky nav is set relative from the top of the page (this is updated when the component mounts,
         * as well as when any other sticky top component is mounted or removed)
         */
        top: number;
        /**
         * @description Height of the element; This is needed so that when the element is removed it can tell the Recalculate instance how tall
         * this current component is so the other sticky component can adjust themselves accordingly
         */
        height: number;
    }
> {
    public el?: HTMLDivElement;

    constructor(props: Props) {
        super(props);

        this.state = {
            top: 0,
            height: 0,
        };

        // since we add te recalcTop method to the recalc list we need to ensure that this is bound correctly
        this.recalcTop = this.recalcTop.bind(this);
    }

    /**
     * @description Recalculate all items so you want to make sure that it's rendered on the screen
     */
    public componentDidMount() {
        recalc.addRecalc(this.recalcTop, this.props.name);
    }

    /**
     * @description Reset recalc function when component unmounts
     */
    public componentWillUnmount() {
        recalc.removeFn(this.props.name, this.state.height);
    }

    public render() {
        const style: React.CSSProperties = {
            position: "sticky",
            top: this.state.top,
            // we set the zIndex very high so that nothing overlaps it
            zIndex: 100,
        };
        return (
            <div
                className={this.props.className}
                style={style}
                ref={(el) => {
                    this.el = el;
                }}
            >
                {this.props.children}
            </div>
        );
    }

    /**
     * @description Recalculates the top of the element and updates state accordingly
     */
    private recalcTop(removedHeight: number) {
        if (this.el == null) {
            return;
        }

        const { el } = this;
        this.setState({
            top: el.getBoundingClientRect().top - removedHeight,
            height: el.offsetHeight,
        });
    }
}

import {
    trackAddFieldValue,
    trackClickFieldType,
    trackSaveField,
} from "@src/analytics/custom_fields";
import {
    OrganizationFieldInputType,
    OrganizationFieldType,
} from "@src/requests/custom_fields";
import { RadioButton } from "@src/shared_modules/radio_button";
import { UserState, withUser } from "@src/shared_modules/user";
import * as React from "react";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { ChecklistPreview } from "./build_previews/ChecklistPreview";
import { DatePickerPreview } from "./build_previews/DatePickerPreview";
import { DropdownPreview } from "./build_previews/DropdownPreview";
import { TextareaPreview } from "./build_previews/TextareaPreview";
import { ToggleSwitchPreview } from "./build_previews/ToggleSwitchPreview";
import { isOrgFieldFilterable } from "./isOrgFieldFilterable";
import styles from "./styles.module.css";
import {
    OrganizationField,
    OrgFieldUpsertInput,
    PreviewComponentProps,
} from "./types";

// // // //
// BuildOptions represents the options that are used to configure the build component
interface BuildOptions {
    // input type parameter expected by the api
    inputType?: OrganizationFieldInputType;
    // if a field can have multiple input types, we should use the following option instead of the above
    inputTypes?: Array<{
        name: string;
        value: OrganizationFieldInputType;
    }>;
    // field type parameter expected by the api
    fieldType: OrganizationFieldType;
    // this should be set to false if we don't need to render the list of possible field values
    showFieldValues?: boolean;
    // this should be set to true if it we don't need to add/remove possible field values
    fixedFieldValuesNumber?: boolean;
    // default values are set when the user switches to this field type from another one
    defaultFieldValues?: string[];
}

// PreviewOptions represents the options that are used to configure the preview component
interface PreviewOptions {
    // type of the component to be used for rendering the preview section
    component: React.ComponentType<PreviewComponentProps>;
    // this should be set to true if we need the preview component to be to placed to the right of its label (and not below it)
    singleRow?: boolean;
}

// FieldType represents a field type in fieldTypes array
interface FieldType {
    name: string;
    svg: React.ReactNode;
    buildOptions: BuildOptions;
    previewOptions?: PreviewOptions;
}

// fieldTypes is an array defining the properties of possible field types
const fieldTypes: FieldType[] = [
    {
        name: "Dropdown",
        svg: <Icon type={IconTypes.Field_Dropdown} />,
        buildOptions: {
            showFieldValues: true,
            fieldType: "dropdown",
            inputType: "list",
        },
        previewOptions: { component: DropdownPreview },
    },
    {
        name: "Date Picker",
        svg: <Icon type={IconTypes.Field_Date_Picker} />,
        buildOptions: {
            fieldType: "date_picker",
            inputType: "date",
        },
        previewOptions: { component: DatePickerPreview },
    },
    {
        name: "Checklist",
        svg: <Icon type={IconTypes.Field_Checklist} />,
        buildOptions: {
            showFieldValues: true,
            fieldType: "checklist",
            inputType: "list",
        },
        previewOptions: { component: ChecklistPreview },
    },
    {
        name: "Toggle Switch",
        svg: <Icon type={IconTypes.Field_Toggle} />,
        buildOptions: {
            fieldType: "switch",
            inputType: "list",
            showFieldValues: true,
            fixedFieldValuesNumber: true,
            defaultFieldValues: ["No", "Yes"],
        },
        previewOptions: { component: ToggleSwitchPreview, singleRow: true },
    },
    {
        name: "Textarea",
        svg: <Icon type={IconTypes.Field_Textarea} />,
        buildOptions: {
            fieldType: "text_area",
            inputTypes: [
                { name: "Number", value: "number" },
                { name: "Single line", value: "string" },
                { name: "Multi-line area", value: "multiline_string" },
            ],
        },
        previewOptions: { component: TextareaPreview },
    },
];

// FieldTypeListProps represents the props that should be passed to the FieldTypes component
interface FieldTypeListProps {
    selected: number | null;
    onSelect: (a: number) => void;
}

// FieldTypesList renders the list of field types
function FieldTypesList({ selected, onSelect }: FieldTypeListProps) {
    return (
        <div className={`col-3 d-flex flex-column ${styles.fieldTypes}`}>
            <h2 className={`row ${styles.sectionHeader}`}>
                Select Your Field Type
            </h2>
            {fieldTypes.map(({ name, svg }, fieldTypeIndex) => (
                <button
                    key={name}
                    onClick={() => {
                        trackClickFieldType(fieldTypes[fieldTypeIndex].name);
                        onSelect(fieldTypeIndex);
                    }}
                    className={`row align-items-center flex-nowrap ${
                        styles.fieldType
                    } ${
                        fieldTypeIndex === selected
                            ? styles.selectedFieldType
                            : ""
                    }`}
                >
                    <span
                        className={`d-flex align-items-center justify-content-center ${styles.fieldTypeIcon}`}
                    >
                        {svg}
                    </span>
                    <span className={`col ${styles.fieldTypeName}`}>
                        {name}
                    </span>
                </button>
            ))}
        </div>
    );
}

/**
 * FieldValuesProps
 * Props for FieldValues component
 */
interface FieldValuesProps {
    showFieldValues?: boolean;
    fixedFieldValuesNumber: boolean;
    onChangeFieldValue: (a: number, b: string) => void;
    onDeleteFieldValue: (a: number) => void;
    onAddFieldValue: () => void;
    fieldValues: string[];
}

// renders the field values editing section
function FieldValues(props: FieldValuesProps) {
    const {
        showFieldValues,
        fixedFieldValuesNumber,
        onChangeFieldValue,
        onDeleteFieldValue,
        onAddFieldValue,
        fieldValues,
    } = props;

    if (!showFieldValues) {
        return null;
    }

    return (
        <div className="pt-30-px pr-30-px">
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label>Field Values</label>
            <ul>
                {fieldValues.map((value, index) => (
                    <li
                        // we use the array index below bec that's all the available data we have to cause something to be unique
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`field-value-${index}`}
                        className={`${styles.fieldValue} d-flex`}
                    >
                        <input
                            /* we want the newly created empty input to be focused automatically */
                            /* eslint-disable-next-line jsx-a11y/no-autofocus */
                            autoFocus={!value}
                            className={`col-10 ${styles.fieldValueInput}`}
                            name={`field-input-${index}`}
                            type="text"
                            value={value}
                            onChange={(e) =>
                                onChangeFieldValue(index, e.target.value)
                            }
                        />
                        <div className="col-2 d-flex">
                            {!fixedFieldValuesNumber && (
                                <button
                                    className={styles.deleteFieldValueButton}
                                    onClick={(
                                        e: React.MouseEvent<HTMLElement>
                                    ) => {
                                        // we are checking the below condition to prevent handling simulated clicks caused by
                                        // pressing the `enter` key inside the field value inputs
                                        // @ts-ignore - prevents the `property "detail" does not exist on MouseEvent` error
                                        if (e.detail === 0) {
                                            return;
                                        }
                                        e.preventDefault();
                                        onDeleteFieldValue(index);
                                    }}
                                >
                                    +
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
            {!fixedFieldValuesNumber && (
                <div className={styles.addNewFieldValueButtonWrapper}>
                    <button
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            // @ts-ignore - prevents the `property "detail" does not exist on MouseEvent` error
                            if (e.detail === 0) {
                                return;
                            }
                            e.preventDefault();
                            trackAddFieldValue();
                            onAddFieldValue();
                        }}
                        className={styles.addNewFieldValueButton}
                    >
                        + Add A New Variable
                    </button>
                </div>
            )}
        </div>
    );
}

/**
 * InputTypesProps
 * Props for InputTypes component
 */
interface InputTypesProps {
    inputTypes: Array<{
        name: string;
        value: OrganizationFieldInputType;
    }>;
    onChangeInputType: (a: OrganizationFieldInputType) => void;
    inputType: OrganizationFieldInputType | null;
}

// renders the input type selection
function InputTypes(props: InputTypesProps) {
    const { inputTypes, onChangeInputType, inputType } = props;

    if (!inputTypes) {
        return null;
    }

    return (
        <div className="pt-30-px pr-30-px pb-15-px">
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label>Type</label>
            <div className="d-flex flex-column">
                {inputTypes.map(({ name, value }) => (
                    <RadioButton
                        key={name}
                        className="pt-15-px"
                        active={inputType === value}
                        label={name}
                        labelClassName="pl-15-px font-size-16-px text-black"
                        onClick={() => {
                            onChangeInputType(value);
                        }}
                    />
                ))}
            </div>
        </div>
    );
}

// BuildProps represents the props that should be passed to the Build component
type BuildProps = {
    id: number;
    fieldTypeName: string | null;
    inputType: OrganizationFieldInputType | null;
    name: string;
    onChangeInputType: (a: OrganizationFieldInputType) => void;
    onChangeName: (a: string) => void;
    fieldValues: string[];
    onChangeFieldValue: (a: number, b: string) => void;
    onDeleteFieldValue: (a: number) => void;
    onAddFieldValue: () => void;
    onSubmit: (orgFieldUpsertInput: OrgFieldUpsertInput) => void;
    loading: boolean;
} & BuildOptions;

// Build renders the build section
function Build(props: BuildProps & { user: UserState }) {
    const {
        id,
        name,
        fieldTypeName,
        onChangeName,
        inputType,
        inputTypes,
        fieldValues,
        fieldType,
        onSubmit,
        onChangeInputType,
        showFieldValues,
        fixedFieldValuesNumber,
        onChangeFieldValue,
        onAddFieldValue,
        onDeleteFieldValue,
        user,
    } = props;
    return (
        <div className={`col-4 ${styles.build}`}>
            {!fieldTypeName ? (
                <div className={styles.empty}>
                    Select a field type to start building
                </div>
            ) : (
                [
                    <h2 key="header" className={`row ${styles.sectionHeader}`}>
                        Build Field
                    </h2>,
                    <form
                        key="form"
                        onSubmit={(e) => {
                            e.preventDefault();

                            // Disable saving a field without a name
                            if (!props.name) {
                                return;
                            }

                            // Track the save event
                            trackSaveField(
                                id,
                                name,
                                fieldType,
                                inputType,
                                fieldValues,
                                true,
                                true
                            );

                            // Builds the OrgFieldUpsertInput for org field mutations
                            const orgFieldUpsertInput: OrgFieldUpsertInput = {
                                id,
                                attributes: fieldValues,
                                archived: false,
                                orgID: user.organizationID,
                                name,
                                fieldType,
                                inputType,
                                editable: true,
                                filterable: isOrgFieldFilterable({
                                    fieldType,
                                }),
                            };

                            onSubmit(orgFieldUpsertInput);
                        }}
                    >
                        <label htmlFor="name">Name</label>
                        <input
                            name="name"
                            id="name"
                            type="text"
                            placeholder="Name The Field Here"
                            value={name}
                            onChange={(e) => {
                                onChangeName(e.target.value);
                            }}
                        />
                        <FieldValues
                            showFieldValues={showFieldValues}
                            fixedFieldValuesNumber={fixedFieldValuesNumber}
                            onChangeFieldValue={onChangeFieldValue}
                            onDeleteFieldValue={onDeleteFieldValue}
                            onAddFieldValue={onAddFieldValue}
                            fieldValues={fieldValues}
                        />
                        <InputTypes
                            inputTypes={inputTypes}
                            inputType={inputType}
                            onChangeInputType={onChangeInputType}
                        />
                        <div
                            className={`d-flex flex-column ${styles.buildFormSubmitButtonWrapper}`}
                        >
                            <button
                                type="submit"
                                className="btn btn-lg btn-primary font-secondary mb-20-px"
                                disabled={props.loading}
                            >
                                {props.loading
                                    ? "Saving"
                                    : `Save ${fieldTypeName}`}
                            </button>
                        </div>
                    </form>,
                ]
            )}
        </div>
    );
}

const BuildOrgField: React.ComponentType<BuildProps> = withUser(Build);

// PreviewProps represents the props that are passed to the Preview component
type PreviewProps = {
    name: string;
    fieldValues: string[];
    inputType: OrganizationFieldInputType | null;
} & PreviewOptions;

// Preview renders the preview section
function Preview(props: PreviewProps) {
    const FieldPreview = props.component;
    return (
        <div className={`col-5 ${styles.preview}`}>
            {FieldPreview ? (
                [
                    <h2 key="header" className={`row ${styles.sectionHeader}`}>
                        Preview
                    </h2>,
                    <div
                        key="preview"
                        className={`d-flex ${
                            props.singleRow
                                ? "align-items-center"
                                : "flex-column"
                        }`}
                    >
                        <div
                            className={`${styles.previewLabel} ${
                                props.singleRow
                                    ? styles.singleRowPreviewLabel
                                    : ""
                            }`}
                        >
                            {props.name}
                        </div>
                        <FieldPreview
                            values={props.fieldValues}
                            inputType={props.inputType}
                        />
                    </div>,
                ]
            ) : (
                <div className={styles.empty}>No preview available yet</div>
            )}
        </div>
    );
}

// OrgFieldFormState represents the state of the OrgFieldForm component
interface OrgFieldFormState {
    id: number;
    fieldTypeIndex: number | null;
    inputType: OrganizationFieldInputType | null;
    fieldName: string;
    fieldValues: string[];
}

/**
 * OrgFieldFormProps
 * Props of the OrgFieldForm component
 * @param orgField - (optional) OrgField to edit
 * @param onSubmit - callback fired off when the user clicks the save button
 * @param loading - whether or not to render the loading state
 */
interface OrgFieldFormProps {
    orgField?: OrganizationField;
    onSubmit: (orgFieldUpsertInput: OrgFieldUpsertInput) => void;
    loading: boolean;
}

// OrgFieldForm renders the form to edit an organization field
export class OrgFieldForm extends React.Component<
    OrgFieldFormProps,
    OrgFieldFormState
> {
    constructor(props: OrgFieldFormProps) {
        super(props);

        const { orgField } = this.props;

        // set the state to empty values when we are adding a new field
        if (!orgField) {
            this.state = {
                id: 0,
                fieldTypeIndex: null,
                inputType: null,
                fieldName: "",
                fieldValues: [],
            };
            return;
        }

        this.state = {
            id: orgField.id,
            inputType: orgField.inputType,
            fieldName: orgField.name,
            fieldValues: orgField.attributes || [],
            fieldTypeIndex: fieldTypes.findIndex(
                (fieldType) =>
                    fieldType.buildOptions.fieldType === orgField.fieldType
            ),
        };
    }

    public selectFieldType(fieldTypeIndex: number) {
        const { defaultFieldValues, inputType, inputTypes } = fieldTypes[
            fieldTypeIndex
        ].buildOptions;
        // reset fields on field type change and set default field values if required by selected type
        const fieldValues = defaultFieldValues || [];
        // save the input type that corresponds to the selected field type. if multiple input types are allowed, use the first one
        const inpType = inputType || (inputTypes ? inputTypes[0].value : null);
        this.setState({
            fieldTypeIndex,
            fieldValues,
            inputType: inpType,
        });
    }

    public setInputType(inputType: OrganizationFieldInputType) {
        this.setState({ inputType });
    }

    public setFieldName(fieldName: string) {
        this.setState({ fieldName });
    }

    public setFieldValue(index: number, value: string) {
        this.setState({
            fieldValues: this.state.fieldValues.map((oldValue, oldIndex) =>
                oldIndex === index ? value : oldValue
            ),
        });
    }

    public addFieldValue() {
        this.setState({
            fieldValues: [...this.state.fieldValues, ""],
        });
    }

    public deleteFieldValue(index: number) {
        this.setState({
            fieldValues: this.state.fieldValues.filter((_, i) => i !== index),
        });
    }

    public render() {
        const selectedFieldType =
            this.state.fieldTypeIndex !== null
                ? fieldTypes[this.state.fieldTypeIndex]
                : null;
        const fieldTypeName = selectedFieldType ? selectedFieldType.name : null;
        // @ts-ignore
        const { buildOptions, previewOptions } = selectedFieldType || {};
        return (
            <section
                className={`tw-bg-white tw-rounded-lg mx-30-px mt-30-px border-grey-border d-flex flex-column ${styles.body}`}
            >
                <h1 className="text-navy font-secondary-bold font-size-32-px p-20-px">
                    {this.state.id ? "Edit" : "Create New"} Field
                </h1>
                <div className="d-flex h-100">
                    <FieldTypesList
                        selected={this.state.fieldTypeIndex}
                        onSelect={(fieldType) =>
                            this.selectFieldType(fieldType)
                        }
                    />
                    <BuildOrgField
                        id={this.state.id}
                        fieldTypeName={fieldTypeName}
                        inputType={this.state.inputType}
                        name={this.state.fieldName}
                        fieldValues={this.state.fieldValues}
                        onChangeInputType={(inputType) =>
                            this.setInputType(inputType)
                        }
                        onChangeName={(name) => this.setFieldName(name)}
                        onChangeFieldValue={(index, value) =>
                            this.setFieldValue(index, value)
                        }
                        onDeleteFieldValue={(index) =>
                            this.deleteFieldValue(index)
                        }
                        onAddFieldValue={() => {
                            this.addFieldValue();
                        }}
                        loading={this.props.loading}
                        onSubmit={this.props.onSubmit}
                        {...buildOptions}
                    />
                    <Preview
                        name={this.state.fieldName}
                        fieldValues={this.state.fieldValues}
                        inputType={this.state.inputType}
                        {...previewOptions}
                    />
                </div>
            </section>
        );
    }
}

import React from "react";

export function Spend(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19"
            viewBox="0 0 11 19"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M6.027 1.386h.189c.714 0 1.445.122 2.195.368a5.32 5.32 0 011.921 1.081L8.589 4.788a3.53 3.53 0 00-2.562-1.134v4.2l.084.021a23.61 23.61 0 011.732.525 5.9 5.9 0 011.512.766c.442.316.798.711 1.072 1.187.273.476.409 1.071.409 1.785 0 .7-.126 1.32-.378 1.858a4.22 4.22 0 01-1.029 1.386c-.434.386-.941.69-1.522.914a7.306 7.306 0 01-1.88.441v1.512H4.893v-1.491a7.683 7.683 0 01-2.656-.42A5.615 5.615 0 010 14.889l1.911-1.953c.336.476.77.84 1.302 1.092a5.003 5.003 0 001.68.462v-4.431l-.105-.021a20.541 20.541 0 01-1.68-.556 5.274 5.274 0 01-1.418-.798 3.592 3.592 0 01-.976-1.197C.476 7.017.357 6.433.357 5.732c0-.63.126-1.197.378-1.701a4.301 4.301 0 011.008-1.313c.42-.37.903-.668 1.449-.892a5.941 5.941 0 011.701-.42V0h1.134v1.386zm-1.134 2.31c-.49.084-.927.29-1.313.62-.385.329-.577.759-.577 1.291 0 .322.052.581.157.777.106.196.242.364.41.504.168.14.367.255.599.346.23.092.472.193.724.305V3.696zM6.027 14.49c.294-.056.57-.143.83-.263.259-.119.486-.265.682-.44.196-.176.353-.385.473-.63.119-.246.178-.522.178-.83 0-.308-.066-.567-.2-.777a1.751 1.751 0 00-.514-.525 3.59 3.59 0 00-.693-.357 16.357 16.357 0 00-.756-.273v4.095z"
                ></path>
            </g>
        </svg>
    );
}

import { FeatureNames, useFeatureFlag } from "../feature_flag";
import { VerifyEmailLock } from "./auth";
import { SailpointColorCoinSmall, SailpointWhiteCoin } from "./branding";
import {
    ConnectedAppsTileDanger,
    ConnectedAppsTilePrimary,
    ConnectedAppsTileWarning,
} from "./connected_apps";
import { ConnectedAppsTileInfo } from "./connected_apps/ConnectedAppsTileInfo";
import {
    Checkmark,
    Danger,
    DangerV2,
    Download,
    EmptySearch,
    EmptyUsage,
    Lock,
    PrimaryArrow,
    Success,
    User,
    Warning,
    WarningV2,
} from "./core";
import { Favicon } from "./favicon";
import {
    Applications,
    Auth,
    Dashboard,
    Integrations,
    Settings,
    Spend,
    Teams,
    Usage,
    Workflows,
} from "./navigation";
import {
    FieldChecklist,
    FieldDatePicker,
    FieldDropdown,
    FieldTextarea,
    FieldToggle,
} from "./org_fields";
import {
    CursorQuestion,
    VendorFamilyPrimary,
    VendorFamilyWhite,
} from "./related_vendors";
import {
    UsageRankDesktop,
    UsageRankDirect,
    UsageRankSSO,
    UsageRankThirdParty,
} from "./usage_rank";
import { UsageRankCASB } from "./usage_rank/UsageRankCASB";
import { UsageRankSSOV2 } from "./usage_rank/UsageRankSSOV2";

// // // //

/**
 * IconTypes represent the set of all SVG icons used throughout the dashboard
 * Icons should be organized by context (e.g. Usage, Connected Apps) or functionality (e.g. Navigation, Branding)
 * 'Core' icons are used across multiple pages/modules
 */
export enum IconTypes {
    // Core
    Checkmark = "checkmark",
    Danger = "danger",
    Danger_V2 = "danger-v2",
    Empty_Search = "empty-search",
    Lock = "lock",
    Primary_Arrow = "primary-arrow",
    Success = "success",
    User = "user",
    Warning = "warning",
    Warning_V2 = "warning-v2",
    Download = "download",
    Empty_Usage = "empty-usage",

    // Favicon
    Favicon = "favicon",

    // Branding
    Sailpoint_Color_Coin_Small = "sailpoint-color-coin-small",
    Sailpoint_White_Coin = "sailpoint-white-coin",

    // Navigation
    Applications = "applications",
    Auth = "auth",
    Dashboard = "dashboard",
    Integrations = "integrations",
    Settings = "settings",
    Spend = "spend",
    Teams = "teams",
    Usage = "usage",
    Workflows = "workflows",

    // Usage Ranks
    Usage_Rank_Third_Party = "usage-rank-third-party",
    Usage_Rank_Desktop = "usage-rank-desktop",
    Usage_Rank_Direct = "usage-rank-direct",
    Usage_Rank_CASB = "usage-rank-casb",
    Usage_Rank_SSO = "usage-rank-sso",
    Usage_Rank_SSOV2 = "usage-rank-sso-v2", // NOTE - remove this when we archive the enableNewSsoRankIcon Feature Flag

    // Org Fields
    Field_Checklist = "field-checklist",
    Field_Date_Picker = "field-date-picker",
    Field_Dropdown = "field-dropdown",
    Field_Textarea = "field-textarea",
    Field_Toggle = "field-toggle",

    // Auth
    Verify_Email_Lock = "verify-email-lock",

    // Related Vendors
    Vendor_Family_Primary = "vendor-family-primary",
    Vendor_Family_White = "vendor-family-white",
    Cursor_Question = "cursor-question",

    // Connected Apps
    Connected_Apps_Tile_Danger = "connected-apps-tile-danger",
    Connected_Apps_Tile_Primary = "connected-apps-tile-primary",
    Connected_Apps_Tile_Warning = "connected-apps-tile-warning",
    Connected_Apps_Tile_Info = "connected-apps-tile-info",
}

/**
 * Map of all IconComponents
 * key is an IconType & value is a React FC that returns an SVG
 */
const iconComponents: {
    [key in IconTypes]: React.FunctionComponent<{
        className?: string;
        label: string;
    }>;
} = {
    // Core
    [IconTypes.Checkmark]: Checkmark,
    [IconTypes.Danger]: Danger,
    [IconTypes.Danger_V2]: DangerV2,
    [IconTypes.Empty_Search]: EmptySearch,
    [IconTypes.Lock]: Lock,
    [IconTypes.Primary_Arrow]: PrimaryArrow,
    [IconTypes.Success]: Success,
    [IconTypes.User]: User,
    [IconTypes.Warning]: Warning,
    [IconTypes.Warning_V2]: WarningV2,
    [IconTypes.Download]: Download,
    [IconTypes.Empty_Usage]: EmptyUsage,

    // Favicon
    [IconTypes.Favicon]: Favicon,

    // Branding
    [IconTypes.Sailpoint_White_Coin]: SailpointWhiteCoin,
    [IconTypes.Sailpoint_Color_Coin_Small]: SailpointColorCoinSmall,

    // Navigation
    [IconTypes.Applications]: Applications,
    [IconTypes.Auth]: Auth,
    [IconTypes.Dashboard]: Dashboard,
    [IconTypes.Integrations]: Integrations,
    [IconTypes.Settings]: Settings,
    [IconTypes.Spend]: Spend,
    [IconTypes.Teams]: Teams,
    [IconTypes.Usage]: Usage,
    [IconTypes.Workflows]: Workflows,

    // Usage Ranks
    [IconTypes.Usage_Rank_Third_Party]: UsageRankThirdParty,
    [IconTypes.Usage_Rank_Direct]: UsageRankDirect,
    [IconTypes.Usage_Rank_CASB]: UsageRankCASB,
    [IconTypes.Usage_Rank_SSO]: UsageRankSSO,
    [IconTypes.Usage_Rank_Desktop]: UsageRankDesktop,
    [IconTypes.Usage_Rank_SSOV2]: UsageRankSSOV2, // TODO - swap this out with the newer icon

    // Org Fields
    [IconTypes.Field_Checklist]: FieldChecklist,
    [IconTypes.Field_Date_Picker]: FieldDatePicker,
    [IconTypes.Field_Dropdown]: FieldDropdown,
    [IconTypes.Field_Textarea]: FieldTextarea,
    [IconTypes.Field_Toggle]: FieldToggle,

    // Auth
    [IconTypes.Verify_Email_Lock]: VerifyEmailLock,

    // Related Vendors
    [IconTypes.Vendor_Family_Primary]: VendorFamilyPrimary,
    [IconTypes.Vendor_Family_White]: VendorFamilyWhite,
    [IconTypes.Cursor_Question]: CursorQuestion,

    // Connected Apps
    [IconTypes.Connected_Apps_Tile_Warning]: ConnectedAppsTileWarning,
    [IconTypes.Connected_Apps_Tile_Primary]: ConnectedAppsTilePrimary,
    [IconTypes.Connected_Apps_Tile_Danger]: ConnectedAppsTileDanger,
    [IconTypes.Connected_Apps_Tile_Info]: ConnectedAppsTileInfo,
};

export function Icon(props: { type: IconTypes; className?: string }) {
    const enableNewSsoIcon = useFeatureFlag(FeatureNames.enableNewSsoRankIcon);

    const { type: originalType, className = "" } = props;

    // Render updated SSO Icon if FeatureNames.enableNewSsoRankIcon is turned on
    // TODO - remove this once we release the new SSO Rank Icon
    const type =
        enableNewSsoIcon && originalType === IconTypes.Usage_Rank_SSO
            ? IconTypes.Usage_Rank_SSOV2
            : originalType;

    const IconComponent = iconComponents[type];
    return <IconComponent className={className} label={`${type} icon`} />;
}

import classnames from "classnames";
import * as React from "react";

interface MoreFiltersButtonProps {
    active: boolean; // Whether the button is active
    text?: string; // The text rendered inside the button
    onClick: () => void;
}

// MoreFiltersButton renders a button with some specific set of styles. For example it is used in more filters buttons
export function MoreFiltersButton(props: MoreFiltersButtonProps) {
    const { text = "More Filters", active, onClick } = props;
    const className = classnames(
        "btn-sm font-secondary-bold font-size-12-px tw-rounded-sm",
        "tw-mr-4 tw-h-[34px] tw-whitespace-nowrap tw-flex tw-items-center",
        {
            "btn-primary": active,
            "btn-stroked-primary": !active,
        }
    );
    return (
        <button className={className} onClick={onClick}>
            <span className="tw-tracking-normal">{text}</span>
        </button>
    );
}

import React from "react";

export function FieldDatePicker(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <g
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(-258 -214)"
                >
                    <g transform="translate(258 214)">
                        <g>
                            <path d="M21.174 2.158h-2.002V.156h-1.335v2.002H7.162V.156H5.827v2.002h-2A3.34 3.34 0 00.49 5.494v16.014a3.34 3.34 0 003.336 3.336h17.348a3.34 3.34 0 003.336-3.337V5.495a3.34 3.34 0 00-3.336-3.336zm2.002 19.35a2.003 2.003 0 01-2.001 2H3.826a2.003 2.003 0 01-2.001-2V8.83h21.351v12.678zm0-14.012H1.824V5.494c0-1.104.897-2.001 2.001-2.001h2.001v1.334h1.337V3.493h10.676v1.334h1.335V3.493h2.001c1.104 0 2.001.897 2.001 2v2.003z"></path>
                            <path d="M11.165 15.502h2.669c.736 0 1.335-.598 1.335-1.335V11.5c0-.737-.599-1.335-1.335-1.335h-2.669c-.736 0-1.335.598-1.335 1.335v2.668c.001.737.6 1.335 1.335 1.335zm0-4.003h2.669l.001 2.668h-2.67V11.5zM17.837 15.502h2.67c.735 0 1.334-.598 1.334-1.335V11.5c0-.737-.599-1.335-1.335-1.335h-2.669c-.736 0-1.334.598-1.334 1.335v2.668c0 .737.6 1.335 1.334 1.335zm0-4.003h2.67v2.668h-2.67V11.5zM4.494 22.175h2.668c.737 0 1.336-.599 1.336-1.335v-2.669c0-.736-.6-1.335-1.335-1.335h-2.67c-.736 0-1.334.599-1.334 1.335v2.669c0 .736.599 1.335 1.335 1.335zm0-4.004h2.668l.002 2.669h-2.67v-2.669zM11.165 22.175h2.669c.736 0 1.335-.599 1.335-1.335v-2.669c0-.736-.599-1.335-1.335-1.335h-2.669c-.736 0-1.335.599-1.335 1.335v2.669c.001.736.6 1.335 1.335 1.335zm0-4.004h2.669l.001 2.669h-2.67v-2.669zM17.837 22.175h2.67c.735 0 1.334-.599 1.334-1.335v-2.669c0-.736-.599-1.335-1.335-1.335h-2.669c-.736 0-1.334.599-1.334 1.335v2.669c0 .736.6 1.335 1.334 1.335zm0-4.004h2.67v2.669h-2.67v-2.669z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

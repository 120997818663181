import {
    Filters,
    FiltersInput,
    PaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { ActionsDropdown } from "@src/modules/advanced_filters/components/advanced_filters/ActionsDropdown";
import {
    DashboardListHeader,
    DashboardOverviewHeader,
} from "@src/shared_modules/dashboard_list_header";
import { Export } from "@src/shared_modules/list_hamburger";
import classnames from "classnames";
import * as React from "react";
import { Pagination } from "@src/schema/Pagination";
import { ClearColumnSettingsButton } from "./manage_columns";
import { ManageColumnsButton } from "./manage_columns/ManageColumnsButton";
import { SuggestedWorkflowButton } from "./suggested_filters";
import { SuggestedFilter } from "./suggested_filters/graphql";
import { OrgSavedView, OrgSavedViewsApi } from "../org_saved_views";
import { OrgSavedViewDropdown } from "../org_saved_views/OrgSavedViewDropdown";
import { SuggestedFilterDropdown } from "./suggested_filters/SuggestedFilterDropdown";

// // // //

/**
 * AdvancedFiltersHeader
 * Renders `DashboardOverviewHeader` component with total count of items + CSV Export button + Manage Columns button + SuggestedWorkflowButton
 * @param props.label - label passed into `DashboardOverviewHeader.props.description`
 * @param props.pagination - (optional) pagination data returned from GQL
 * @param props.filters - Filters from AdvancedFiltersProvider
 * @param props.filtersInput - FiltersInput from AdvancedFiltersProvider
 * @param props.filtersInputForPills - FiltersInput from AdvancedFiltersProvider, passed into OrgSavedViewDropdown
 * @param props.suggestedFilters - Array of SuggestedFilter passed to SuggestedFilterDropdown
 * @param props.suggestedWorkflow - SuggestedFilter used to send current FiltersInput state to WorkflowEngine
 * @param props.orgSavedViews - OrgSavedView[] from AdvancedFiltersProvider
 * @param props.orgSavedViewsApi - OrgSavedViewApi from AdvancedFiltersProvider
 * @param props.csvExportQuery - Query passed into `Export` component
 * @param props.children - (optional) child React.ReactNode that may be passed in and rendered inside the DashboardListHeader / DashboardOverviewHeader
 */
export function AdvancedFiltersHeader(props: {
    label: string;
    pagination?: Pagination;
    filters: Filters;
    children?: React.ReactNode;
    filtersInput: FiltersInput;
    filtersInputForPills: FiltersInput;
    enableManageColumns?: boolean;
    orgSavedViews: OrgSavedView[];
    suggestedFilters: SuggestedFilter[];
    suggestedWorkflow: SuggestedFilter | null;
    orgSavedViewsApi: OrgSavedViewsApi;
    csvExportProps?: {
        query: any;
        filenamePrefix: string;
        pagination?: PaginationInput;
    };
}) {
    // Pulls props + assigns default values
    const {
        label,
        filters,
        filtersInput,
        filtersInputForPills,
        orgSavedViews,
        orgSavedViewsApi,
        suggestedFilters,
        suggestedWorkflow,
        enableManageColumns = true,
        csvExportProps = null,
        pagination = null,
        children = null,
    } = props;

    // Gets total count of items
    let totalCount: number = 0;
    if (pagination !== null) {
        totalCount = pagination.totalItems;
    }

    // Define default value for CSV Export pagination
    let csvPaginationInput: PaginationInput = {
        ...filtersInput.pagination,
        page: 1,
        itemsPerPage: 100000,
    };

    // Override default csvPaginationInput
    if (csvExportProps !== null && csvExportProps.pagination !== undefined) {
        csvPaginationInput = csvExportProps.pagination;
    }

    // Defines content rendered inside the DashboardOverviewHeader
    let headerContent: React.ReactNode = null;

    // Return null if no items are rendered in <ActionsDropdown />
    if (
        suggestedFilters.length === 0 &&
        orgSavedViews.length === 0 &&
        csvExportProps === null &&
        enableManageColumns === false
    ) {
        headerContent = null;
    } else {
        // Defines flag indicating whether or not the ActionsDropdown has any items
        const hasActionDropdown: boolean =
            csvExportProps !== null ||
            enableManageColumns ||
            suggestedWorkflow !== null;

        headerContent = (
            <div className="d-flex w-100 align-items-center justify-content-end">
                {/* Render ClearColumnSettingsButton IFF enableManageColumnsFF */}
                {enableManageColumns && (
                    <ClearColumnSettingsButton className="btn-sm btn-stroked-primary font-secondary mr-10-px line-height-19-px" />
                )}

                {/* Render OrgSavedViewDropdown */}
                <div
                    className={classnames({
                        "pr-10-px": hasActionDropdown,
                    })}
                >
                    {/* Render OrgSavedViewDropdown, if orgSavedViewsApi.disabled is false */}
                    {!orgSavedViewsApi.disabled && (
                        <OrgSavedViewDropdown
                            filters={filters}
                            filtersInput={filtersInputForPills}
                            suggestedFilters={suggestedFilters}
                            orgSavedViews={orgSavedViews}
                            orgSavedViewsApi={orgSavedViewsApi}
                        />
                    )}

                    {/* Otherwise, render SuggestedFilterDropdown */}
                    {orgSavedViewsApi.disabled && (
                        <SuggestedFilterDropdown
                            suggestedFilters={suggestedFilters}
                        />
                    )}
                </div>

                {/* Render ActionsDropdown w/ ManageColumnsButton + ClearColumnSettingsButton */}
                <ActionsDropdown>
                    {({ buttonClassName }) => {
                        const dropdownItems = [];

                        // Includes "CSV Export" button
                        if (csvExportProps !== null) {
                            dropdownItems.push(
                                <Export
                                    filenamePrefix={
                                        csvExportProps.filenamePrefix
                                    }
                                    showIcon={false}
                                    buttonClassName={buttonClassName}
                                    query={csvExportProps.query}
                                    variables={{
                                        filters: {
                                            ...filtersInput,
                                            pagination: {
                                                ...csvPaginationInput,
                                            },
                                        },
                                    }}
                                />
                            );
                        }

                        // Includes "Manage Columns" button IFF enableManageColumnsFF + enableManageColumns
                        if (enableManageColumns) {
                            dropdownItems.push(
                                <ManageColumnsButton
                                    className={buttonClassName}
                                />
                            );
                        }

                        // Include SuggestedWorkflowLink IFF props.suggestedWorkflow isn't null
                        if (suggestedWorkflow !== null) {
                            dropdownItems.push(
                                <SuggestedWorkflowButton
                                    className={buttonClassName}
                                    suggestedFilter={suggestedWorkflow}
                                />
                            );
                        }

                        // Returns dropdown items
                        return dropdownItems;
                    }}
                </ActionsDropdown>
            </div>
        );
    }

    // If pagination is null -> render
    if (pagination === null) {
        return (
            <DashboardListHeader text={label}>
                {children}
                {headerContent}
            </DashboardListHeader>
        );
    }

    // Render DashboardOverviewHeader
    return (
        <DashboardOverviewHeader value={totalCount} description={label}>
            {children}
            {headerContent}
        </DashboardOverviewHeader>
    );
}

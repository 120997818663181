import { useDispatch } from "react-redux";
import { buildAddToasterAction } from "./actions";
import { GlobalToasterProps } from "./component";

// // // //

/**
 * useToaster
 * Returns an `addToaster` function to add a GlobalToaster
 */
export function useToaster() {
    const dispatch = useDispatch();

    function addToaster(toaster: GlobalToasterProps) {
        dispatch(buildAddToasterAction(toaster));
    }

    return addToaster;
}

import { client } from "@src/requests/apollo";
import { routes } from "@src/routes";
import { onClickLogout } from "@src/shared_modules/logout/component";
import { Action } from "@src/shared_modules/redux_provider/types";
import { identifyUser } from "@src/util/analytics";
import gql from "graphql-tag";
import get from "lodash.get";
import { Dispatch } from "redux";
import { initLdClient } from "../feature_flag/client";
import { INTEGRATIONS_FETCHER_QUERY } from "../../modules/integrations/components/integrations_fetcher/graphql";
import { getOrgID } from "./getOrgID";
import { UserApi } from "./types";

export const USER_RESULT = "__user__result";
export const USER_ERR_RESULT = "__user__err__result";
export const USER_LOADING = "__user__loading";

// LoadingAction is the action we publish to update the loading state of a reducer
interface LoadingAction {
    loading: boolean;
    type: string;
}

function userLoading(loading: boolean): LoadingAction {
    return {
        type: USER_LOADING,
        loading,
    };
}

function userErrResult(): Action {
    return {
        type: USER_ERR_RESULT,
    };
}

export type UserAction = {
    user: UserApi;
} & Action;

function userResult(user: UserApi): UserAction {
    return {
        type: USER_RESULT,
        user,
    };
}

/**
 * CURRENT_USER_QUERY
 * GQL query to fetch the current user
 */
const CURRENT_USER_QUERY = gql`
    query CURRENT_USER_QUERY {
        currentUser {
            id
            pw
            organizationID
            email
            name
            createdAt
            createdTimestamp
            state
            organization {
                id
                domain
                clientView
            }
            assignedRoles {
                id
                organizationID
                name
                description
                createdAt
                state
            }
        }
    }
`;

/**
 * getCurrentUser
 * Fetch the current user from GQL by invoking client.query(...) directly
 */
function getCurrentUser() {
    return new Promise<UserApi | null>((resolve, reject) => {
        return client
            .query({
                query: CURRENT_USER_QUERY,
                variables: {},
            })
            .then(({ data }) => {
                // Safely pull the currentUser from the GQL response
                // null value indicates user is not authenticated
                const currentUser: UserApi | null = get(
                    data,
                    "currentUser",
                    null
                );

                // Resolves the promise with the currentUser value
                return resolve(currentUser);
            })
            .catch(reject);
    });
}

/**
 * prefetchIntegrations
 * Preloads the INTEGRATIONS_FETCHER_QUERY since it's used *everywhere* in the app
 */
function prefetchIntegrations(orgID) {
    return new Promise<void>((resolve, reject) => {
        return client
            .query({
                query: INTEGRATIONS_FETCHER_QUERY,
                variables: {
                    filters: { orgID },
                },
            })
            .then(() => {
                // Resolves the promise with the currentUser value
                return resolve();
            })
            .catch(reject);
    });
}

export function initUserAction() {
    // Initialize third-party integrations with user details
    return (dispatch: Dispatch) => {
        dispatch(userLoading(true));

        getCurrentUser()
            .then((currentUserResponse: UserApi | null) => {
                // NOTE: i'm still having issues with disjoin unions in flow
                if (currentUserResponse === null) {
                    // if there's an error in the user api call (inactive, or not logged) we log them out and redirect them back to
                    // the home page - reason we need to log them out is we want to ensure that we have a clean cookie slate
                    onClickLogout();
                    return;
                }

                // Modify the UserApi response to use the correct orgID when clientView is enabled
                const orgID = getOrgID(currentUserResponse);
                const currentUser: UserApi = {
                    ...currentUserResponse,
                    organizationID: orgID,
                    organization: {
                        ...currentUserResponse.organization,
                        id: orgID,
                    },
                };

                // Initialize LaunchDarkly client before setting user state
                initLdClient({
                    userID: currentUser.id,
                    organizationID: currentUser.organizationID,
                }).then(() => {
                    // Pre-load INTEGRATIONS_FETCHER_QUERY
                    prefetchIntegrations(currentUser.organizationID)
                        .then(() => {
                            // Update the global UserState
                            dispatch(userResult(currentUser));

                            // Initialize Pendo + Intercom
                            identifyUser(currentUser);
                        })
                        .catch(() => {
                            dispatch(userLoading(false));
                            dispatch(userErrResult());
                        });
                });
            })
            // If LaunchDarkly fails to initialize, use user-auth error UX
            .catch(() => {
                dispatch(userLoading(false));
                dispatch(userErrResult());
            });
    };
}

// initRedirectAuth issues a request to the user endpoint and if it return - redirects the user to the dashboard
export function initRedirectAuth() {
    return (dispatch: Dispatch) => {
        dispatch(userLoading(true));

        getCurrentUser()
            .then((currentUser: UserApi | null) => {
                // NOTE: i'm still having issues with disjoin unions in flow
                if (currentUser === null) {
                    dispatch(userLoading(false));
                    dispatch(userErrResult());
                    return;
                }

                // if the user is signed in redirect them
                window.location.href = routes.overview;
            })
            .catch(() => {
                dispatch(userLoading(false));
                dispatch(userErrResult());
            });
    };
}

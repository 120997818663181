import gql from "graphql-tag";
import {
    OauthIntegration,
    OAUTH_INTEGRATION_FRAGMENT,
} from "./OauthIntegration";
import { UsageRank } from "./UsageRank";
import { Vendor, VENDOR_FRAGMENT } from "./Vendor";

// // // //

/**
 * AppInfoState
 * The possible values of the state of the app info
 *  live - the state of a app that's live in the integration
 *  inactive - the state of a app that's inactive
 *  deleted_instance - the value that's set when the integration was associated with the app was deleted
 *  deleted - that state that we assign when an app is deleted
 */
type AppInfoState = "live" | "inactive" | "deleted_instance" | "deleted";
export enum AppInfoStates {
    live = "live",
    inactive = "inactive",
    deletedInstance = "deleted_instance",
    deleted = "deleted",
}

/**
 * AppInfo contains usage data for the application of the GroupApp
 */
export interface AppInfo {
    id: string;
    organizationID: number;
    sourceApplicationID: string;
    source: string;
    rank: UsageRank;
    instanceID: string;
    applicationName: string;
    label: null | string;
    state: AppInfoState;
    createdAt: string;
    updatedAt: null | string;
    vendorID: null | number;
    userCount: null | number;
    vendor: Vendor;
    oauthIntegration: null | OauthIntegration;
    __typename: "AppInfo";
}

export const APP_INFO_FRAGMENT = gql`
    fragment APP_INFO_FRAGMENT on AppInfo {
        id
        sourceApplicationID
        source
        rank
        instanceID
        applicationName
        label
        state
        createdAt
        updatedAt
        vendorID
        userCount
        oauthIntegration {
            ...OAUTH_INTEGRATION_FRAGMENT
        }
        vendor {
            ...VENDOR_FRAGMENT
        }
    }
    ${VENDOR_FRAGMENT}
    ${OAUTH_INTEGRATION_FRAGMENT}
`;

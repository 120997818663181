import { SearchIcon } from "@src/shared_modules/search_icon/component";
import classnames from "classnames";
import * as React from "react";
import { TextInput, TextInputProps } from "./TextInput";
import { InputSize } from "./types";

// // // //

/**
 * SearchInput
 * Wraps the `TextInput` component with a wrapper encapsulating the `SearchIcon`
 * @param props - see `SearchInputProps` and `TextInputProps`
 */
export function SearchInput(props: TextInputProps) {
    // Pulls `size` prop, defaults to `md`
    const { size = InputSize.md, className = "" } = props;

    return (
        <div
            className={classnames(
                "d-flex align-items-center w-100",
                "tw-relative"
            )}
        >
            <span
                className={classnames("tw-pl-6", "tw-absolute", {
                    ["tw-scale-[.85]"]: size === InputSize.sm,
                    ["tw-scale-[1.15]"]: size === InputSize.lg,
                })}
            >
                <SearchIcon />
            </span>
            <TextInput
                placeholder={props.placeholder}
                value={props.value}
                size={props.size}
                disabled={props.disabled}
                debounceTimeoutMs={props.debounceTimeoutMs}
                focusOnRender={props.focusOnRender}
                onKeyDown={props.onKeyDown}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                className={classnames({
                    ["!tw-pl-[38px]"]: size === InputSize.sm,
                    ["!tw-pl-[42px]"]: size === InputSize.md,
                    ["!tw-pl-[46px]"]: size === InputSize.lg,
                    [className]: className !== "",
                })}
                onChange={(updatedQuery: string) =>
                    props.onChange(updatedQuery)
                }
            />
        </div>
    );
}

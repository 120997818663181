import { Alert, AlertTypes } from "@src/shared_modules/alert";
import { RouteState, withRouter } from "@src/shared_modules/router";
import { Toaster } from "@src/shared_modules/toaster";
import get from "lodash.get";
import * as React from "react";

// // // //

/**
 * GlobalErrorHandler
 * Renders an error <Toaster /> when route.params.error_msg is defined
 * @param props.route - RouteState from withRouter HOC
 * @param props.children - child React.ReactNode passed into this component
 */
export function GlobalErrorHandlerLayout(props: {
    route: RouteState;
    children: React.ReactNode;
}) {
    // Pulls error_msg from RouteState.params, assigns null default value
    const errorParam: string = get(props.route, "params.error_msg", null);

    // Defines flag indicating whether or not to render the error <Toaster />
    const renderToaster: boolean = errorParam !== null && errorParam !== "";

    return (
        <React.Fragment>
            {/* Renders props.children */}
            {props.children}

            {/* Renders error <Toaster /> */}
            {renderToaster && (
                <Toaster type="danger" removable text={errorParam} />
            )}
        </React.Fragment>
    );
}

export const GlobalErrorHandler: React.ComponentType<{
    children: React.ReactNode;
}> = withRouter(GlobalErrorHandlerLayout);

// // // //

/**
 * GlobalErrorAlert
 * Renders an error <Alert /> when route.params.error_msg is defined
 * @param props.route - RouteState from withRouter HOC
 */
export function GlobalErrorAlertLayout(props: {
    route: RouteState;
    className?: string;
}) {
    // Pulls className from props, assigns default value of ""
    const { className = "" } = props;

    // Pulls error_msg from RouteState.params, assigns null default value
    const errorParam: string = get(props.route, "params.error_msg", null);

    // Defines flag indicating whether or not to render the error <Toaster />
    const renderAlert: boolean = errorParam !== null && errorParam !== "";

    // Return null if no error param is found
    if (!renderAlert) {
        return null;
    }

    // Render <Alert /> inside a styles div IFF props.className
    if (className) {
        return (
            <div className={className}>
                <Alert
                    type={AlertTypes.danger}
                    text={errorParam}
                    removable={false}
                />
            </div>
        );
    }

    // Render <Alert /> component
    return (
        <Alert type={AlertTypes.danger} text={errorParam} removable={false} />
    );
}

export const GlobalErrorAlert: React.ComponentType<{
    className?: string;
}> = withRouter(GlobalErrorAlertLayout);

import { webConfig } from "@src/config";
import { supportEmail } from "@src/util/consts";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import "isomorphic-fetch";
import get from "lodash.get";

// // // //

export const HTTP_CODE_TOO_MANY_REQUESTS: number = 429;
export const ERROR_MESSAGE_429: string = `We’re seeing suspicious behaviour with that Intello account. Please try again later or contact our support team at ${supportEmail}`;
export const ERROR_MESSAGE_FAILED_REQUEST: string = `We're experiencing some technical diffculties. Please try again later or contact our support team at ${supportEmail}`;

/**
 * hasTooManyRequests
 * Accepts an HTTP status code and reutrns a boolean indicating whether or not the request HTTP code is 429
 * @param statusCode
 */
export function hasTooManyRequests(statusCode: number | null): boolean {
    return statusCode === HTTP_CODE_TOO_MANY_REQUESTS;
}

// Defines HTTP Link
const httpLink = createHttpLink({
    credentials: "include",
    fetchOptions: {
        mode: "cors",
        cache: "no-cache",
    },
    uri: `${webConfig.gwHost}/graphql/auth/query`,
});

// Defines errorLink
const errorLink = onError(({ response, networkError }) => {
    // NOTE - we use `lodash.get` to safely access the `statusCode` property on networkError
    // This is done because networkError is typed as `Error | ServerError | ServerParseError`,
    // but the `statusCode` property only exists on `ServerError` and `ServerParseError`
    const statusCode: number | null = get(networkError, "statusCode", null);

    // Handle 429 Too Many Requests Error
    // Redirect to the login page & displays error message
    // NOTE - this occurs when Sqreen blocks ther user's email, but not their IP
    if (hasTooManyRequests(statusCode)) {
        window.location.assign(
            `${window.location.protocol}//${window.location.host}${
                window.location.pathname
            }?error_msg=${encodeURIComponent(ERROR_MESSAGE_429)}`
        );
        return;
    }

    // Render error message when request fails without status code
    // NOTE - this occurs when Sqreen blocks the user's IP address
    if (response === undefined) {
        window.location.assign(
            `${window.location.protocol}//${window.location.host}${
                window.location.pathname
            }?error_msg=${encodeURIComponent(ERROR_MESSAGE_FAILED_REQUEST)}`
        );
    }
});

// Composes Apollo link from errorLink + httpLink
// NOTE - order of link array is significant (httpLink must always come last)
// Doc: https://www.apollographql.com/docs/link/overview/#terminating-links
const link = ApolloLink.from([errorLink, httpLink]);

// Exports authClient
export const authClient = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

import gql from "graphql-tag";
import { AppInfo, APP_INFO_FRAGMENT } from "./AppInfo";

// // // //

/**
 * AppUserReportResultBucket represents a single report result bucket
 */
export interface AppUserReportResultBucket {
    organizationID: number;
    reportID: string;
    resultCount: number;
    sourceApplicationID: string;
    appInfo: AppInfo;
    __typename: "AppUserReportResultBucket";
}

/**
 * APP_USER_REPORT_RESULT_BUCKET_FRAGMENT
 * Fragment for querying fields on UserReportResultBucket
 */
export const APP_USER_REPORT_RESULT_BUCKET_FRAGMENT = gql`
    fragment APP_USER_REPORT_RESULT_BUCKET_FRAGMENT on AppUserReportResultBucket {
        organizationID
        reportID
        resultCount
        sourceApplicationID
        appInfo {
            ...APP_INFO_FRAGMENT
        }
    }
    ${APP_INFO_FRAGMENT}
`;

import { TableCellType } from "@src/schema/TableCellType";
import { Content } from "@src/modules/advanced_filters/components/advanced_filter_editor";

// // // //

/**
 * ColumnSortDirection
 * Defines available values for Column.defaultSortDirection
 */
export enum ColumnSortDirection {
    asc = "asc",
    desc = "desc",
    none = "",
}

/**
 * Column
 * The data returned by the GQL API to describe a single table column
 * NOTE - this interface is going to change significantly - what's here matches what's currently returned by the API
 * @property key - the unique key for this column (must be unique to parent Column[])
 * @property content - the Content object describing the column (name + description)
 * @property sortable - Whether or not the column is sortable
 * @property visibleByDefault - the uniqye
 * @property type - Type of cell rendered for values in this column
 * @property rowDataKeys - Keys corresponding to the values inside rowData that we want to display
 * @property sortedByDefault - Whether or not the Column is sorted by default
 * @property defaultSortDirection - The default sort applied to this column
 */
export interface Column {
    key: string;
    content: Content;
    sortable: boolean;
    visibleByDefault: boolean;
    type: TableCellType;
    rowDataKeys: string[];
    sortedByDefault: boolean;
    defaultSortDirection: ColumnSortDirection;
}

/**
 * ColumnSetting
 * Stores the user's settings for a single Column
 * @property key - unique value corresponding to Column.key
 * @property visible - whether or not the Column is visible
 * @property width - the width of the Column in PX
 * @property stickyLeft - whether the column is stickyLeft
 * @property allowToggleVisibility - whether the visibility of the column can be changed
 */
export interface ColumnSetting {
    key: string;
    visible: boolean;
    width: number;
    stickyLeft: boolean;
    allowToggleVisibility: boolean;
}

/**
 * Columns
 * Defines the top-level Columns object returned by the API
 */
export interface Columns {
    defaults: Column[];
}

// // // //

/**
 * isColumnSetting
 * Accepts `any` variable and returns a type predicate indicating whether
 * or not the columnSetting parameter is a valid ColumnSetting instance.
 * Performs a shallow check on the `columnSetting` parameter to ensure validity.
 * @param columnSetting - the object we're verifying as a valid columnSetting instance
 * Doc: https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
 */
export function isColumnSetting(
    columnSetting: any
): columnSetting is ColumnSetting {
    return (
        columnSetting !== null &&
        columnSetting !== undefined &&
        typeof (columnSetting as ColumnSetting).width === "number" &&
        typeof (columnSetting as ColumnSetting).visible === "boolean" &&
        typeof (columnSetting as ColumnSetting).key === "string" &&
        typeof (columnSetting as ColumnSetting).stickyLeft === "boolean" &&
        typeof (columnSetting as ColumnSetting).allowToggleVisibility ===
            "boolean"
    );
}

import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import gql from "graphql-tag";
import { PURCHASE_ORDER_FRAGMENT } from "@src/schema/PurchaseOrder";
import { PAGINATION_FRAGMENT } from "@src/schema/Pagination";
import { PurchaseOrderList } from "@src/schema/PurchaseOrderList";

// // // //

/**
 * PURCHASE_ORDERS_FETCHER_QUERY
 * Used to fetch the PurchaseOrder data from the API
 */
export const PURCHASE_ORDERS_FETCHER_QUERY = gql`
    query PURCHASE_ORDERS_FETCHER_QUERY($filters: FiltersInput!) {
        procurement {
            purchaseOrders {
                list(filters: $filters) {
                    results {
                        ...PURCHASE_ORDER_FRAGMENT
                    }
                    pagination {
                        ...PAGINATION_FRAGMENT
                    }
                }
            }
        }
    }
    ${PURCHASE_ORDER_FRAGMENT}
    ${PAGINATION_FRAGMENT}
`;

/**
 * PurchaseOrdersFetcherResponse
 * Response interface for PURCHASE_ORDERS_FETCHER_QUERY
 */
export interface PurchaseOrdersFetcherResponse {
    procurement: {
        purchaseOrders: {
            list: PurchaseOrderList;
            __typename: "PurchaseOrders";
        };
        __typename: "Procurement";
    };
}

/**
 * PurchaseOrdersFetcherVariables
 * Variables interface for PURCHASE_ORDERS_FETCHER_QUERY
 */
export interface PurchaseOrdersFetcherVariables {
    filters: FiltersInput;
}

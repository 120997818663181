import React from "react";
import { captureMessage } from "@src/util/analytics";
import { ResolverProps } from "./component";

// // // //

export function LabelResolver(props: ResolverProps<string>) {
    if (props.params.label === undefined) {
        captureMessage(`Breadcrumbs LabelResolver - params.label is undefined`);
        return null;
    }

    if (props.children) {
        return props.children({ value: props.params.label, loading: false });
    }

    return <React.Fragment>{props.params.label}</React.Fragment>;
}

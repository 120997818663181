import { spendNode } from "../spend";
import { BreadcrumbNode } from "../types";
import { appListingState } from "./state";

// // // //

export const appListingNode: BreadcrumbNode = {
    getState: appListingState,
    parent: spendNode,
};

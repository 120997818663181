import React from "react";
import { UserState, withUser } from "@src/shared_modules/user";
import { captureMessage } from "@src/util/analytics";
import { ApplicationUsageSummary } from "@src/schema/ApplicationUsageSummary";
import {
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { DEFAULT_DATE_RANGE_FILTER } from "@src/modules/usage/components/application_usage_summary/advanced_filters/constants";
import { AppUsageSummaryListFetcher } from "@src/modules/usage/components/application_usage_summary/list_fetcher";
import { ResolverProps } from "./component";

// // // //

export function AppUsageSummaryResolverLayout(
    props: ResolverProps<ApplicationUsageSummary> & { user: UserState }
) {
    const { loadingPlaceholder = "...", errorFallback = null } = props;

    const appUsageSummaryID: string | undefined =
        props.params?.appUsageSummaryID;

    if (appUsageSummaryID === undefined) {
        captureMessage(
            "AppUsageSummaryResolver - params.appUsageSummaryID is undefined"
        );
        return null;
    }

    const { organizationID } = props.user;
    const filtersInput: FiltersInput = {
        organizationID,
        defaults: [
            DEFAULT_DATE_RANGE_FILTER,
            {
                fieldName: "id",
                val: appUsageSummaryID,
                expression: FilterExpression.eq,
                filters: [],
            },
        ],
        advanced: [],
        pagination: {
            sort: "",
            direction: "",
            page: 1,
            itemsPerPage: 25,
        },
    };

    return (
        <AppUsageSummaryListFetcher filtersInput={filtersInput}>
            {({ loading, appUsageSummaries }) => {
                const appUsageSummary:
                    | ApplicationUsageSummary
                    | undefined = appUsageSummaries.find(
                    (appSummary: ApplicationUsageSummary) =>
                        appSummary?.id === appUsageSummaryID
                );

                if (props.children) {
                    return props.children({ value: appUsageSummary, loading });
                }

                if (loading) {
                    return (
                        <React.Fragment>{loadingPlaceholder}</React.Fragment>
                    );
                }

                if (appUsageSummary === undefined) {
                    captureMessage(
                        `Application Usage Summary for id - ${appUsageSummaryID} not found`
                    );
                    return <React.Fragment>{errorFallback}</React.Fragment>;
                }

                return (
                    <React.Fragment>
                        {appUsageSummary.appInfo.applicationName}
                    </React.Fragment>
                );
            }}
        </AppUsageSummaryListFetcher>
    );
}

export const AppUsageSummaryResolver: React.ComponentType<ResolverProps<
    ApplicationUsageSummary
>> = withUser(AppUsageSummaryResolverLayout);

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { getFormattedDateValue } from "@src/util/date_util";

// // // //

/**
 * isDeprecatedDateValue
 * Accepts a Filter.val and Filter.expression and returns a boolean indicating whether or not the pair
 * belongs to a deprecated Date FieldType (FieldType.date_picker and FieldType.date_range)
 * NOTE - the check against FieldType occurs outside this function - this just checks the value and expression
 * This check ensures:
 * - value is a string
 * - value is a valid date
 * - expression is a non-dynamic FilterExpression that was previously used with FieldType.date_picker and FieldType.date_range
 */
export function isDeprecatedDateValue(props: {
    value: any;
    expression: FilterExpression;
}): boolean {
    return (
        typeof props.value === "string" &&
        getFormattedDateValue(props.value) !== "" &&
        [
            FilterExpression.gt,
            FilterExpression.gte,
            FilterExpression.lt,
            FilterExpression.lte,
            FilterExpression.eq,
            FilterExpression.neq,
        ].includes(props.expression)
    );
}

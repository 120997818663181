import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import * as React from "react";
import { FetchResult, Mutation } from "react-apollo";
import { DELETE_ORG_TAG_MUTATION } from "./graphql";
import { OrgTag, OrgTagInput } from "./types";

// // // //

/**
 * DeleteOrgTagMutationProps
 * Props for DeleteOrgTagMutation
 */
interface DeleteOrgTagMutationProps {
    children: (childProps: {
        loading: boolean;
        deleteOrgTag: (orgTagToDelete: OrgTag) => Promise<void | FetchResult>;
    }) => React.ReactNode;
}

/**
 * DeleteOrgTagMutation
 * Delete a single OrgTag
 * @param props - see DeleteOrgTagMutationProps
 */
export function DeleteOrgTagMutation(props: DeleteOrgTagMutationProps) {
    return (
        <Mutation<OrgTag, OrgTagInput> mutation={DELETE_ORG_TAG_MUTATION}>
            {(commitMutation, { loading, error }) => {
                // Handle error state
                if (error) {
                    return <GraphQlErrorHandler error={error} />;
                }

                // Renders props.children + passes deleteOrgTag function
                return props.children({
                    loading,
                    deleteOrgTag: (orgTagToDelete: OrgTag) => {
                        return commitMutation({
                            variables: {
                                ...orgTagToDelete,
                            },
                        });
                    },
                });
            }}
        </Mutation>
    );
}

import gql from "graphql-tag";
import { Pagination, PAGINATION_FRAGMENT } from "@src/schema/Pagination";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import {
    PrimaryIntegrationUser,
    PRIMARY_INTEGRATION_USER_FRAGMENT,
} from "@src/schema/PrimaryIntegrationUser";

// // // //

/**
 * PrimaryIntegrationUsersFetcherResponse
 * Response variables for PRIMARY_INTEGRATION_USERS_FETCHER_QUERY
 */
export interface PrimaryIntegrationUsersFetcherResponse {
    usage: {
        primaryIntegrationUsers: {
            list: {
                results: PrimaryIntegrationUser[];
                pagination: Pagination;
                __typename: "PrimaryIntegrationUserList";
            };
            __typename: "PrimaryIntegrationUsers";
        };
        __typename: "Usage";
    };
}

/**
 * PrimaryIntegrationUsersFetcherVariables
 * Request variables for PRIMARY_INTEGRATION_USERS_FETCHER_QUERY
 */
export interface PrimaryIntegrationUsersFetcherVariables {
    filters: FiltersInput;
}

/**
 * PRIMARY_INTEGRATION_USERS_FETCHER_QUERY
 * GQL query for the PriamryIntegrationUsersFetcher component
 */
export const PRIMARY_INTEGRATION_USERS_FETCHER_QUERY = gql`
    query PRIMARY_INTEGRATION_USERS_FETCHER_QUERY($filters: FiltersInput!) {
        usage {
            primaryIntegrationUsers {
                list(filters: $filters) {
                    results {
                        ...PRIMARY_INTEGRATION_USER_FRAGMENT
                    }
                    pagination {
                        ...PAGINATION_FRAGMENT
                    }
                }
            }
        }
    }
    ${PRIMARY_INTEGRATION_USER_FRAGMENT}
    ${PAGINATION_FRAGMENT}
`;

/**
 * FeatureNames
 * An enum of values for `FeatureFlagCC.props.featureName`
 */
export enum FeatureNames {
    advancedFiltersResizeColumns = "advanced-filters-resize-columns",
    all = "all",
    customFieldsUploads = "custom-fields-uploads",
    deleteAllIntegrationUsers = "delete-all-integration-users",
    departmentTeams = "department-teams",
    demoSettings = "demo-settings",
    enableAccessControl = "enable-access-control",
    enableAccessControlReconciliation = "enable-access-control-reconciliation",
    enableAuditLog = "enable-audit-log",
    enableVendorRolesUploads = "enable-vendor-role-uploads",
    enableVendorUserModalMetadata = "enable-vendor-user-modal-metadata",
    googleOauthReduceScope = "google-oauth-reduce-scope",
    hideRevokePermissionsButton = "hide-revoke-permissions-button",
    paymentTaggingOnPaymentListing = "payment-tagging-on-payment-listing",
    plaidEnabled = "plaid-enabled",
    paymentTaggingOnSpendIndividual = "payment-tagging-on-spend-individual",
    teamUsage = "team_usage",
    teamUsageExportCsv = "team-usage-export-csv",
    usageListingExportCsv = "usage_listing_export_csv",
    vendorListingV2 = "vendor-listing-v2",
    workflowDeleteAction = "workflow_delete_action",
    workflowEngineTeamsDelivery = "workflow-engine-teams-delivery",
    disableTrialOverNotification = "disable-trial-over-notification",
    enableApiKeysPage = "enable-api-keys-page",
    enableUsageV2 = "enable-usage-v2",
    enableTerminatedUsersReport = "enable-terminated-users-report",
    enableCasbRank = "enable-casb-ui",
    enableNewAppsDetectedTile = "enable-new-apps-detected-tile",
    enableNewSsoRankIcon = "enable-new-sso-rank-icon",
    enableUsageAdditiveFilters = "enable-usage-additive-filters",
    enableIntegrationsOnlyMode = "enable-integrations-only-mode",
}

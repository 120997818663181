import * as React from "react";

/**
 * EmojiCodes
 * An enum of HTML numbers for emoji components
 */
enum EmojiHtmlNumbers {
    rocketShip = "&#x1F680",
    hourGlass = "&#x23F3",
    tada = "&#127881",
}

// RocketShip renders the rocket ship emoji
export function RocketShip() {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: EmojiHtmlNumbers.rocketShip,
            }}
        />
    );
}

// HourGlass renders the hour glass emoji
export function HourGlass() {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: EmojiHtmlNumbers.hourGlass,
            }}
        />
    );
}

// Tada renders the tada emoji
export function Tada(props: { className?: string }) {
    return (
        <span
            className={props.className}
            dangerouslySetInnerHTML={{
                __html: EmojiHtmlNumbers.tada,
            }}
        />
    );
}

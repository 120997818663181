import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";

// // // //

/**
 * getDefaultDateRangeExpression
 * Accepts a Array<FilterExpression> and returns the preferred defaultExpression
 * for a PossibleFilter where fieldType is FieldType.date_range.
 * Prioritizes GTE + LTE over GT + LT expressions.
 * Returns expressions[0] is no prioritized expression is found
 * @param expressions - the Array<FilterExpression> we're pulling the defaultExpression from
 */
export function getDefaultDateRangeExpression(expressions: FilterExpression[]) {
    if (expressions.includes(FilterExpression.gte)) {
        return FilterExpression.gte;
    }
    if (expressions.includes(FilterExpression.gt)) {
        return FilterExpression.gt;
    }
    if (expressions.includes(FilterExpression.lte)) {
        return FilterExpression.lte;
    }
    if (expressions.includes(FilterExpression.lt)) {
        return FilterExpression.lt;
    }
    if (expressions.includes(FilterExpression.eq)) {
        return FilterExpression.eq;
    }

    // Return the first available expression if none are found that match GT/GTE/LT/LTE
    return expressions[0];
}

import gql from "graphql-tag";
import { UserDashboardSetting, UserDashboardSettingValue } from "./types";

// // // //

/**
 * USER_DASHBOARD_SETTINGS_MUTATION
 * Mutation for UserDashboardSettings
 */
export const USER_DASHBOARD_SETTINGS_MUTATION = gql`
    mutation USER_DASHBOARD_SETTINGS_MUTATION(
        $input: UserDashboardSetttingInput!
    ) {
        userDashboardSettings(input: $input) {
            id
            component
            value
        }
    }
`;

/**
 * UserDashboardSettingsMutationInput
 * GQL mutation input for USER_DASHBOARD_SETTINGS_MUTATION
 */
export interface UserDashboardSettingsMutationInput {
    input: {
        component: string;
        value: UserDashboardSettingValue;
    };
}

/**
 * UserDashboardSettingsMutationResponse
 * GQL mutation response for USER_DASHBOARD_SETTINGS_MUTATION
 */
export interface UserDashboardSettingsMutationResponse {
    userDashboardSettings: UserDashboardSetting;
}

/**
 * buildUserDashboardSettingsMutationInput
 * Builds input for USER_DASHBOARD_SETTINGS_MUTATION
 * @param props.componentName - the name of the component for which the settings are being saved
 * @param props.value - the value of the UserDashboardSetting that's being persisted
 */
export function buildUserDashboardSettingsMutationInput(props: {
    componentName: string;
    value: UserDashboardSettingValue;
}): UserDashboardSettingsMutationInput {
    return {
        input: {
            component: props.componentName,
            value: props.value,
        },
    };
}

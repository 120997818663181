import { routes, vendorUsageAppListing } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const vendorUsageAppListingState = (route: RouteState) => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);

    return {
        linkProps: {
            href: {
                pathname: routes.vendorUsageAppListing,
                query: { vendorID: String(vendorID) },
            },
            as: vendorUsageAppListing({ vendorID }),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: { breadcrumb: "vendorUsageAppListing", vendorID },
        },
        enabled: true,
        active: route.nextPathname === routes.vendorUsageAppListing,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "All Applications" },
        },
    };
};

import {
    Filter,
    FilterExpression,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import {
    Vendor,
    VENDOR_FIELDS_FRAGMENT,
} from "@src/shared_modules/related_vendors";
import gql from "graphql-tag";

// // // //

export interface VendorSearchVariables {
    filters: FiltersInput;
}

export interface VendorSearchResponse {
    vendors: {
        listVendors: {
            list: {
                results: Vendor[];
            };
        };
    };
}

/**
 * VENDOR_SEARCH_QUERY
 * Used to search for Vendors
 */
export const VENDOR_SEARCH_QUERY = gql`
    query VENDOR_SEARCH_QUERY($filters: FiltersInput!) {
        vendors {
            listVendors {
                list(filters: $filters) {
                    results {
                        ...vendorFields
                    }
                }
            }
        }
    }
    ${VENDOR_FIELDS_FRAGMENT}
`;

/**
 * buildVendorSearchVariables
 * Builds VendorSearchVariables for VENDOR_SEARCH_QUERY
 * @param props.query - the ID of the Vendor we're querying for
 * @param props.defaultFilters - additional Filters to include in FiltersInput.defaults
 * @param props.organizationID - value from UserState.organizationID
 */
export function buildVendorSearchVariables(props: {
    query: string;
    defaultFilters: Filter[];
    organizationID: number;
}): VendorSearchVariables {
    const { query, defaultFilters, organizationID } = props;

    return {
        filters: {
            defaults: [
                ...defaultFilters,
                {
                    fieldName: "query",
                    val: query,
                    expression: FilterExpression.ilike,
                    filters: [],
                },
                {
                    fieldName: "state",
                    val: "live",
                    expression: FilterExpression.eq,
                    filters: [],
                },
            ],
            advanced: [],
            organizationID,
            pagination: {
                sort: "ranking",
                direction: "asc",
                page: 1,
                itemsPerPage: 15,
            },
        },
    };
}

// NOTE - all these CSS imports are global to the application
import "@src/css/bootstrap-grid.css";
import "@src/css/tailwind.css";

// Import global utility CSS
import "@src/css/core/background-color.css";
import "@src/css/core/border-color.css";
import "@src/css/core/border-radius.css";
import "@src/css/core/buttons.css";
import "@src/css/core/color.css";
import "@src/css/core/cursor.css";
import "@src/css/core/font-color.css";
import "@src/css/core/font-size.css";
import "@src/css/core/font-weight.css";
import "@src/css/core/font-open-sans.css";
import "@src/css/core/font-roboto.css";
import "@src/css/core/font-brandon-grotesque.css";
import "@src/css/core/form.css";
import "@src/css/core/global.css";
import "@src/css/core/line-height.css";
import "@src/css/core/sizing.css";
import "@src/css/core/spacing.css";
import "@src/css/core/text-alignment.css";
import "@src/css/base.css";

// Import global datepicker + table styles
import "@src/modules/advanced_filters/components/advanced_filters_table/styles-global.css";
import "@src/shared_modules/date_range_filter/global-date-range-filter-styles.css";
import "@src/shared_modules/table/styles-global.css";
import "@src/shared_modules/datepicker/global-datepicker-styles.css";

import { client } from "@src/requests/apollo";
import { authClient } from "@src/requests/apollo-auth";
import {
    publicRoutes,
    routes,
    settingsRoutes,
    unauthenticatedRoutes,
} from "@src/routes";
import { ErrorBoundary } from "@src/shared_modules/error_boundary";
import { GlobalErrorHandler } from "@src/shared_modules/global_error_handler";
import { GlobalToaster } from "@src/shared_modules/global_toaster";
import { DashboardNavLayout } from "@src/shared_modules/nav";
import { ReduxProvider } from "@src/shared_modules/redux_provider";
import { RedirectAuthDashboardCC } from "@src/shared_modules/user";
import App from "next/app";
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { initDatadogRum } from "@src/util/analytics/initDatadogRum";

// // // //

// Initialize Datadog RUM
initDatadogRum();

// Defines renderWithoutLayoutRoutes to be rendered without DashboardNav or SettingsNavLayout for users who ARE authenticated
const renderWithoutLayoutRoutes: string[] = [routes.workflowIntegrationSuccess];

export default class extends App {
    public static async getInitialProps({ Component, ctx }) {
        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        return { pageProps };
    }

    public render() {
        const { Component, pageProps, router } = this.props;

        // Render status page
        if (router.pathname === publicRoutes.status) {
            return <Component {...pageProps} />;
        }

        // Returns page layout for non-authenticated users
        // NOTE: Passes `authClient` to `ApolloProvider`
        if (unauthenticatedRoutes.includes(router.pathname)) {
            return (
                <ErrorBoundary>
                    <ReduxProvider>
                        <React.Fragment>
                            {/* @ts-ignore */}
                            <ApolloProvider client={authClient}>
                                <RedirectAuthDashboardCC>
                                    <Component {...pageProps} />
                                </RedirectAuthDashboardCC>
                            </ApolloProvider>
                            <GlobalToaster />
                        </React.Fragment>
                    </ReduxProvider>
                </ErrorBoundary>
            );
        }

        // Returns page layout for authenticated users to view a page without the <DashboardNavLayout />
        if (renderWithoutLayoutRoutes.includes(router.pathname)) {
            return (
                <ErrorBoundary>
                    <ReduxProvider>
                        <React.Fragment>
                            {/* @ts-ignore */}
                            <ApolloProvider client={client}>
                                <GlobalErrorHandler>
                                    <Component {...pageProps} />
                                </GlobalErrorHandler>
                            </ApolloProvider>
                            <GlobalToaster />
                        </React.Fragment>
                    </ReduxProvider>
                </ErrorBoundary>
            );
        }

        // Returns the application layout for logged-in users
        // NOTE: Passes standard `client` prop to `ApolloProvider`
        // Passes `settingsActive` prop indicating whether or not the route
        // should be rendered with the `<SettingsNavLayout />`
        return (
            <ErrorBoundary>
                <ReduxProvider>
                    <React.Fragment>
                        {/* @ts-ignore */}
                        <ApolloProvider client={client}>
                            <GlobalErrorHandler>
                                <DashboardNavLayout
                                    settingsActive={settingsRoutes.includes(
                                        router.pathname
                                    )}
                                >
                                    <Component {...pageProps} />
                                </DashboardNavLayout>
                            </GlobalErrorHandler>
                        </ApolloProvider>
                        <GlobalToaster />
                    </React.Fragment>
                </ReduxProvider>
            </ErrorBoundary>
        );
    }
}

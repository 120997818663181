import { UrlParamsValue } from "@src/shared_modules/router";
import { UserDashboardSetting } from "@src/modules/app/components/user_dashboard_settings";
import { buildColumnSettingsComponentName as getComponentName } from "./buildColumnSettingsComponentName";
import { buildDefaultColumnSettings } from "./buildDefaultColumnSettings";
import { USER_DASHBOARD_SETTING_COLUMNS_SETTINGS_KEY as SETTINGS_KEY } from "./constants";
import { parseLocalColumnSettingsMemo } from "./parseLocalColumnSettings";
import { syncColumnSettings } from "./syncColumnSettings";
import { Column, ColumnSetting, isColumnSetting } from "./types";

// // // //

/**
 * getCurrentColumnSettings
 * Checks RouteState.params and UserDashboardSettings for
 * available ColumnSetting[] data for the current user
 * Returns default ColumnSetting[] if user state is found
 * @param params
 */
export function getCurrentColumnSettings(params: {
    columns: Column[];
    componentName: string;
    routeParams: UrlParamsValue;
    userDashboardSettings: UserDashboardSetting[];
}): ColumnSetting[] {
    const {
        columns,
        routeParams,
        componentName,
        userDashboardSettings,
    } = params;

    // First, pulls ColumnSetting[] from RouteState.params
    // if settings are is present, parseLocalColumnSettingsMemo will return an empty array
    const columnSettings: ColumnSetting[] = parseLocalColumnSettingsMemo({
        routeParams,
    });

    // If local settings are found in the URL -> they take precedence
    if (columnSettings.length > 0) {
        return syncColumnSettings({ columns, columnSettings });
    }

    // If no ColumSetting[] data is found in RouteState.params, check the UserDashboardSetting data
    // Finds a UserDashboardSetting where the `component` matches the result of `buildColumnSettingsComponentName`
    const matchedSetting:
        | UserDashboardSetting
        | undefined = userDashboardSettings.find(
        (setting) => setting.component === getComponentName({ componentName })
    );

    // If...
    //   1. A matching UserDashboardSetting exists, and
    //   2. A matching value exists on the UserDashboardSetting, and
    //   3. That value is an array, and
    //   4. Every element in that array is a ColumnSetting instance
    // Then:
    //   Sync the user's saved column settings with params.columns and return
    if (
        matchedSetting &&
        matchedSetting.value[SETTINGS_KEY] &&
        Array.isArray(matchedSetting.value[SETTINGS_KEY]) &&
        Array.from(matchedSetting.value[SETTINGS_KEY]).every(isColumnSetting)
    ) {
        return syncColumnSettings({
            columns,
            columnSettings: matchedSetting.value[SETTINGS_KEY],
        });
    }

    // Builds the default ColumnSetting[] and return
    return buildDefaultColumnSettings({ columns });
}

import { BreadcrumbNode } from "../types";
import { vendorUsageAppIndTimeseriesState } from "./state";
import { vendorUsageAppIndNode } from "../vendorUsageAppInd/node";

// // // //

export const vendorUsageAppIndTimeseriesNode: BreadcrumbNode = {
    getState: vendorUsageAppIndTimeseriesState,
    parent: vendorUsageAppIndNode,
};

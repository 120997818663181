import { captureException } from "@src/util/analytics";

// // // //

type ParamValue = string | number;

export interface QueryParams {
    [key: string]: ParamValue | ParamValue[];
}

/**
 * @name parseQsToObject
 * @description Parse a given query string to an object. It converts boolean strings to
 * booleans. NOTE: right now this won't handle arrays or objects very well, we
 * haven't had to deal with them yet, when we do we'll add support.
 * @param {str} qs
 * @returns {QueryParams}
 */
export function parseQsToObject(qs: string): QueryParams {
    const qsStrippedQuestion: string = qs.replace("?", "");
    const qp: QueryParams = {};

    if (!qsStrippedQuestion) {
        return qp;
    }

    // Attempt to decode each URI component
    // We wrap this all in a try/catch block because a malformed query string
    // will create an unhandled exception. Not particularly an issue with primitive
    // key/value pairs, but stringified JSON values need to be decoded more carefully
    try {
        const splitQs: string[] = qsStrippedQuestion.split("&");
        splitQs.forEach((query: string) => {
            const splitQuery: string[] = query.split("=");
            // the query key needs to be decoded because it can contain special characters
            const queryKey: string = decodeURIComponent(splitQuery[0]);
            // valArr allows us to generate a new array of all values that were split by the equal so we can join them together
            const valArr = splitQuery.slice(1);
            const queryVal: string = valArr.join("=");
            let updatedQueryVal: string | boolean | number = queryVal;

            // we are not parsing true and false values to boolean because the filters rely on this behavior
            updatedQueryVal = decodeURIComponent(queryVal);
            // let newVals: ParamValue[] = [];
            if (queryKey in qp) {
                const existingVal: ParamValue | ParamValue[] = qp[queryKey];
                // We have come across exactly one other identical key
                if (!Array.isArray(existingVal)) {
                    qp[queryKey] = [existingVal, updatedQueryVal];
                }

                // We have come across two or more identical keys (and therefore already created an array)
                if (Array.isArray(existingVal)) {
                    const newVals: ParamValue[] = [
                        ...existingVal,
                        updatedQueryVal,
                    ];
                    qp[queryKey] = newVals;
                }
            } else {
                // We have not yet encountered this key
                qp[queryKey] = updatedQueryVal;
            }
        });

        return qp;
    } catch (e) {
        // Captures the exception so we know when this happens
        captureException(e);

        // Return empty object if qs is malformed
        return {};
    }
}

import { webConfig } from "@src/config";
import { request } from "@src/util/request_util";

// // // //

// Makes a request to the auth server to log out the current user
export function logout(): Promise<any[]> {
    return Promise.all([
        request("logout"),
        request(`${webConfig.gwHost}/auth/logout`),
    ]).then((responses) => responses.map((r) => r.json()));
}

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { genMoment } from "@src/util/date_util";
import {
    DateRangeAction,
    DateRangeActionTypes,
    DateRangeWeekAction,
    DateRangeYearAction,
} from "./actions";
import { getWeekdays, getRemainingWeekOptions } from "./getWeekdays";
import { getCurrentYear, getYearOptions } from "./getYears";
import { WeekInputValue, CustomDateRangeState } from "./types";
import { isCustomDateRangeInputValid } from "./isCustomDateRangeInputValid";

// // // //

/**
 * The lowest available option for startYear + endYear
 */
const BASELINE_YEAR: number = 2017;

/**
 * getReducerInitialState
 * Function get construct the initial state provided to the dateRangeReducer function
 */
export function getReducerInitialState(props: {
    value: WeekInputValue;
    expression: FilterExpression;
}): CustomDateRangeState {
    const { value, expression } = props;

    // Parse reducer state if value + expression pair is valid
    if (isCustomDateRangeInputValid({ value, expression })) {
        // Pull startWeek + endWeek from WeekInputValue
        const startWeek = String(value[0]);
        const endWeek = String(value[1]);

        // Get start + end date moment objects
        const startDate = genMoment(startWeek);
        const endDate = genMoment(endWeek);

        // Get year values from moment objects
        const startYear = startDate.get("year");
        const endYear = endDate.get("year");

        // Return initial CustomDateRangeState
        return {
            startYear,
            startWeek,
            endYear,
            endWeek,
            startYearOptions: getYearOptions(BASELINE_YEAR),
            startWeekOptions: getWeekdays(startYear),
            endYearOptions: getYearOptions(startYear),
            endWeekOptions: getRemainingWeekOptions({
                startYear: startYear,
                endYear: endYear,
                startWeek: startWeek,
            }),
        };
    }

    // Return default DateRangeState
    const currentYear = getCurrentYear();
    return {
        startYear: currentYear,
        startWeek: null,
        endYear: null,
        endWeek: null,
        startYearOptions: getYearOptions(BASELINE_YEAR),
        startWeekOptions: getWeekdays(currentYear),
        endYearOptions: getYearOptions(currentYear),
        endWeekOptions: [],
    };
}

/**
 * Invoked when setting the `startYear` state
 */
export function setStartYear(
    state: CustomDateRangeState,
    action: DateRangeYearAction
): CustomDateRangeState {
    return {
        ...state,
        startYear: action.year,
        startWeek: null,
        endYear: null,
        endWeek: null,
        startWeekOptions: getWeekdays(action.year),
        endYearOptions: getYearOptions(action.year),
        endWeekOptions: [],
    };
}

/**
 * Invoked when setting the `startWeek` state
 */
export function setStartWeek(
    state: CustomDateRangeState,
    action: DateRangeWeekAction
): CustomDateRangeState {
    let endWeek: string | null = state.endWeek;

    // If updated startWeek is GREATER than endWeek -> set endWeek to null
    if (
        state.startYear === state.endYear &&
        genMoment(state.startWeek) > genMoment(endWeek)
    ) {
        endWeek = null;
    }

    return {
        ...state,
        startWeek: action.week,
        endWeek,
        endYearOptions: getYearOptions(state.startYear),
        endWeekOptions: getRemainingWeekOptions({
            startYear: state.startYear,
            endYear: state.startYear,
            startWeek: action.week,
        }),
    };
}

/**
 * Invoked when setting the `endYear` state
 */
export function setEndYear(
    state: CustomDateRangeState,
    action: DateRangeYearAction
): CustomDateRangeState {
    return {
        ...state,
        endYear: action.year,
        endWeek: null,
        endWeekOptions: getRemainingWeekOptions({
            startYear: state.startYear,
            endYear: action.year,
            startWeek: state.startWeek,
        }),
    };
}

/**
 * Invoked when setting the `endWeek` state
 */
export function setEndWeek(
    state: CustomDateRangeState,
    action: DateRangeWeekAction
): CustomDateRangeState {
    return {
        ...state,
        endWeek: action.week,
    };
}

/**
 * Reducer for the CustomDateRangeInput component
 */
export function dateRangeReducer(
    state: CustomDateRangeState,
    action: DateRangeAction
): CustomDateRangeState {
    switch (action.type) {
        case DateRangeActionTypes.setStartYear:
            return setStartYear(state, action);
        case DateRangeActionTypes.setStartWeek:
            return setStartWeek(state, action);
        case DateRangeActionTypes.setEndYear:
            return setEndYear(state, action);
        case DateRangeActionTypes.setEndWeek:
            return setEndWeek(state, action);
    }
}

import "react-popper-tooltip/dist/styles.css";
import { usePopperTooltip } from "react-popper-tooltip";
import React from "react";
import classnames from "classnames";
import styles from "./styles.module.css";
import { TooltipPlacement, TooltipRenderProps } from "./types";
import { TooltipPortal } from "./TooltipPortal";

// // // //

interface Props {
    placement?: TooltipPlacement;
    offset?: [number, number];
    children: TooltipRenderProps;
    tooltipContent: React.ReactNode;
    tooltipWrapperClassName?: string;
    visible?: boolean;
}

/**
 * Tooltip
 * A general-purpose, mostly-unstyled Tooltip component
 * Doc: https://github.com/mohsinulhaq/react-popper-tooltip
 */
export function Tooltip(props: Props) {
    const { offset = [0, 10] } = props;

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: props.placement,
        offset,
        visible: props.visible, // NOTE - You can force visibility using props.visible
    });

    return (
        <React.Fragment>
            <React.Fragment>{props.children({ setTriggerRef })}</React.Fragment>
            <TooltipPortal>
                {visible && (
                    <div
                        ref={setTooltipRef}
                        {...getTooltipProps({
                            className: classnames(
                                "tooltip-container tw-bg-white",
                                styles.tooltipContainer,
                                props.tooltipWrapperClassName
                            ),
                        })}
                    >
                        <div
                            {...getArrowProps({
                                className: classnames(
                                    "tooltip-arrow",
                                    "tw-border-white"
                                ),
                            })}
                        />
                        {props.tooltipContent}
                    </div>
                )}
            </TooltipPortal>
        </React.Fragment>
    );
}

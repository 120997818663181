import gql from "graphql-tag";
import { ApplicationUsageSummary } from "./ApplicationUsageSummary";
import { Pagination } from "./Pagination";

// // // //

export interface ApplicationUsageSummaryList {
    results: ApplicationUsageSummary[];
    pagination: Pagination;
    __typename: "ApplicationUsageSummaryList";
}

// Uncomment after nested fragments issue resolved
// export const APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT = gql`
//     fragment APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT on ApplicationUsageSummaryList {
//         results {
//             ...APPLICATION_USAGE_SUMMARY_FRAGMENT
//         }
//         pagination {
//             ...PAGINATION_FRAGMENT
//         }
//     }
//     ${APPLICATION_USAGE_SUMMARY_FRAGMENT}
//     ${PAGINATION_FRAGMENT}
// `;

// NOTE - we do NOT have support for nested GQL fragments right now,
// So we've flattened this query here so we can keep pushing forward
// When we have support this can be deleted and the above can be uncommented
export const APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT = gql`
    fragment APPLICATION_USAGE_SUMMARY_LIST_FRAGMENT on ApplicationUsageSummaryList {
        results {
            id
            appID
            source
            rank
            activeUsers
            totalLiveUsers
            utilization
            displayRank
            displayUtilization
            appInfo {
                id
                sourceApplicationID
                source
                rank
                instanceID
                applicationName
                label
                state
                createdAt
                updatedAt
                vendorID
                userCount
                vendor {
                    id
                    category
                    description
                    domain
                    gdprLink
                    logo
                    name
                    nameAliases
                    pricingLink
                    privacyLink
                    state
                    tldVendor
                    tosLink
                    verified
                }
                oauthIntegration {
                    name
                    id
                    isActive
                    createdAt
                    status
                    lastFetch
                    tokenStatus {
                        status
                        statusReason
                    }
                }
            }
            spendOverview {
                appID
                vendorID
                lastPaymentDate
                lastPurchaseAmount
                totalDisplay
                convertedTotal {
                    formatted
                    raw
                }
                lastConvertedAmount {
                    formatted
                    raw
                }
            }
            vendor {
                id
                category
                description
                domain
                gdprLink
                logo
                name
                nameAliases
                pricingLink
                privacyLink
                state
                tldVendor
                tosLink
                verified
                parent {
                    id
                    category
                    description
                    domain
                    gdprLink
                    logo
                    name
                    nameAliases
                    pricingLink
                    privacyLink
                    state
                    tldVendor
                    tosLink
                    verified
                }
                children {
                    id
                    category
                    description
                    domain
                    gdprLink
                    logo
                    name
                    nameAliases
                    pricingLink
                    privacyLink
                    state
                    tldVendor
                    tosLink
                    verified
                }
                siblings {
                    id
                    category
                    description
                    domain
                    gdprLink
                    logo
                    name
                    nameAliases
                    pricingLink
                    privacyLink
                    state
                    tldVendor
                    tosLink
                    verified
                }
            }
        }
        pagination {
            pageCount
            rangeStart
            rangeEnd
            page
            totalItems
            itemsPerPage
            sort
            direction
        }
    }
`;

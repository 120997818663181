import { track } from "@src/analytics/track";

// // // //

export enum OrgSavedViewsEvents {
    applyOrgSavedView = "Apply OrgSavedView",
    createOrgSavedView = "Create OrgSavedView",
    updateOrgSavedView = "Update OrgSavedView",
    deleteOrgSavedView = "Delete OrgSavedView",
    confirmDeleteOrgSavedView = "Confirm Delete OrgSavedView",
    addOrgTag = "Add OrgTag",
    removeOrgTag = "Remove OrgTag",
    clickDropdownNew = "OrgSavedViewsDropdown - Click New Saved View button",
    clickDropdownEdit = "OrgSavedViewsDropdown - Click Edit Org Saved View button",
    clickPillsNew = "Pills - Click Save OrgSavedView Button",
    clickPillsEdit = "Pills - Click Edit OrgSavedView Button",
}

/**
 * trackCreateOrgSavedView
 * Tracks when the user submits the NewOrgSavedViewModal
 */
export function trackCreateOrgSavedView() {
    track(OrgSavedViewsEvents.createOrgSavedView, {});
}

/**
 * trackUpdateOrgSavedView
 * Tracks when the user submits the EditOrgSavedViewModal
 */
export function trackUpdateOrgSavedView() {
    track(OrgSavedViewsEvents.updateOrgSavedView, {});
}

/**
 * trackDeleteOrgSavedView
 * Tracks when the user begins to delete an OrgSavedView
 */
export function trackDeleteOrgSavedView() {
    track(OrgSavedViewsEvents.deleteOrgSavedView, {});
}

/**
 * trackConfirmDeleteOrgSavedView
 * Tracks when the user commits the `DeleteOrgSavedViewMutation`
 */
export function trackConfirmDeleteOrgSavedView() {
    track(OrgSavedViewsEvents.confirmDeleteOrgSavedView, {});
}

/**
 * trackAddOrgTag
 * Tracks when the user adds an OrgTag to OrgSavedView.tags
 */
export function trackAddOrgTag(tagName: string) {
    track(OrgSavedViewsEvents.addOrgTag, { tagName });
}

/**
 * trackRemoveOrgTag
 * Tracks when the user removes an OrgTag from OrgSavedView.tags
 */
export function trackRemoveOrgTag(tagName: string) {
    track(OrgSavedViewsEvents.removeOrgTag, { tagName });
}

/**
 * trackClickDropdownNew
 * Tracks when the user clicks the `Save Currently Applied Filter` button the OrgSavedViewsDropdown
 */
export function trackClickDropdownNew() {
    track(OrgSavedViewsEvents.clickDropdownNew, {});
}

/**
 * trackClickDropdownEdit
 * Tracks when the user clicks the edit button the OrgSavedViewsDropdown
 */
export function trackClickDropdownEdit() {
    track(OrgSavedViewsEvents.clickDropdownEdit, {});
}

/**
 * trackClickPillsNew
 * Tracks when the user clicks the SaveOrgSavedView button in AdvancedFilterPills
 */
export function trackClickPillsNew() {
    track(OrgSavedViewsEvents.clickPillsNew, {});
}

/**
 * trackClickPillsEdit
 * Tracks when the user clicks the edit button in AdvancedFilterPills
 */
export function trackClickPillsEdit() {
    track(OrgSavedViewsEvents.clickPillsEdit, {});
}

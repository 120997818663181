import { decompressParam } from "@src/shared_modules/router";
import { captureMessage } from "@src/util/analytics";
import memoize from "lodash.memoize";
import {
    COLUMN_SETTINGS_PARAM,
    COMPRESSED_COLUMN_SETTINGS_PARAM,
} from "./constants";
import { ColumnSetting, isColumnSetting } from "./types";

// // // //

/**
 * parseColumnSettingsJson
 * Parses ColumnSettings state from JSON string pulled from RouteState.params
 * @param columnSettingsJson - The JSON string being parsed
 */
function parseColumnSettingsJson(columnSettingsJson: string): ColumnSetting[] {
    // If the string was decoded successfully, attempt to parse the string as JSON
    // NOTE - we define this using the `any` type because we don't know whether or not
    // the value defined in decodedColumnSettingsString matches our expectations
    const unvalidatedColumnSettings: any = JSON.parse(columnSettingsJson);

    // Returns [] if unvalidatedColumnSettings is NOT an Array
    if (!Array.isArray(unvalidatedColumnSettings)) {
        return [];
    }

    // Verifies that every ColumnSetting is valid using the isColumnSetting type guard
    const areColumnSettingsValid: boolean = unvalidatedColumnSettings.every(
        (c: any) => isColumnSetting(c)
    );

    // Returns empty array if there are any invalid ColumnSetting instances detected
    if (!areColumnSettingsValid) {
        return [];
    }

    // Casts the unvalidatedColumnSettings[] into ColumnSetting[] for return type-safety
    const validatedColumnSettings: ColumnSetting[] = unvalidatedColumnSettings.map(
        (c: any): ColumnSetting => {
            return {
                key: c.key,
                visible: c.visible,
                width: c.width,
                stickyLeft: c.stickyLeft,
                allowToggleVisibility: c.allowToggleVisibility,
            };
        }
    );

    // Returns the validated ColumnSetting[]
    return validatedColumnSettings;
}

/**
 * parseLocalColumnSettings
 * Parses local ColumnSettings[] from RouteState.params and returns the result
 * Returns empty array if:
 *  - params.column_settings is absent
 *  - params.column_settings is invalid
 *  - params.column_settings is corrupted
 */
export function parseLocalColumnSettings(props: {
    routeParams: { [key: string]: string };
}): ColumnSetting[] {
    const { routeParams } = props;

    // Pulls ColumnSettings state from routeParams[COLUMN_SETTINGS_PARAM]
    const columnSettingsStateJson: string | undefined =
        routeParams[COLUMN_SETTINGS_PARAM];

    // Pull compressed ColumnSettings state from routeParams[COMPRESSED_COLUMN_SETTINGS_PARAM]
    const columnSettingsStateCompressed: string | undefined =
        routeParams[COMPRESSED_COLUMN_SETTINGS_PARAM];

    // Returns empty array if columnSettingsStateJson and columnSettingsStateCompressed are undefined
    if (!columnSettingsStateJson && !columnSettingsStateCompressed) {
        return [];
    }

    // Attempt to decode the ColumnSettings state
    try {
        // Decompresses and returns the parsed + validated ColumnSetting[]
        if (columnSettingsStateCompressed) {
            return parseColumnSettingsJson(
                decompressParam(
                    decodeURIComponent(columnSettingsStateCompressed)
                )
            );
        }

        // Decodes and returns the parsed + validated ColumnSetting[]
        return parseColumnSettingsJson(
            decodeURIComponent(columnSettingsStateJson)
        );
    } catch (e) {
        // Capture message in the event
        // NOTE - we don't capture the `RouteState.params` because Datadog will automatically capture that for us
        captureMessage(
            "parseLocalColumnSettings - error parsing RouteState.params"
        );

        // Returns empty array if any errors were detected
        return [];
    }
}

// // // //

// Exports memoized version of parseLocalColumnSettings
// NOTE - we use `typeof` here to ensure that our memoized function has the same type signature as our original
export const parseLocalColumnSettingsMemo: typeof parseLocalColumnSettings = memoize(
    parseLocalColumnSettings
);

import { faEnvelope, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@src/shared_modules/button";
import { Loader } from "@src/shared_modules/loader";
import { Modal } from "@src/shared_modules/modal/ModalV2";
import { useUserState } from "@src/shared_modules/user";
import * as React from "react";
import { SyncStatusRecipientsFetcher } from "../sync_status_recipients_fetcher";
import { SyncStatusRecipientsUpdater } from "../sync_status_recipients_updater";
import { SyncStatusRecipientsForm } from "./SyncStatusRecipientsForm";

// // // //

/**
 * SyncStatusRecipientsModal
 * Modal with form to update the set of global Integration sync status recipients
 */
export function SyncStatusRecipientsModal() {
    const [showModal, setShowModal] = React.useState(false);
    const { organizationID } = useUserState();

    return (
        <React.Fragment>
            <Button
                size="sm"
                variant="primary"
                label="Manage Recipients"
                className="tw-whitespace-nowrap tw-my-4 tw-mr-8"
                iconConfig={{
                    icon: (
                        <FontAwesomeIcon
                            className="tw-w-8"
                            fixedWidth
                            icon={faEnvelope}
                        />
                    ),
                    position: "left",
                }}
                onClick={() => setShowModal(true)}
            />
            <Modal
                show={showModal}
                size="lg"
                onHide={() => setShowModal(false)}
            >
                <div className="tw-p-10 tw-min-h-[270px] tw-flex tw-gap-8">
                    <div className="tw-flex tw-flex-col">
                        <FontAwesomeIcon
                            className="tw-h-20 tw-text-primary-3"
                            icon={faInfoCircle}
                        />
                    </div>
                    <div className="tw-flex tw-flex-col tw-justify-between">
                        <div className="tw-flex tw-flex-col">
                            <p className="tw-text-4xl tw-font-normal tw-mb-4 tw-text-gray-900">
                                Recipient Notification
                            </p>
                            <p className="tw-text-2xl tw-mb-4 tw-text-gray-500">
                                Please add the recipients that will receive
                                notifications about integration updates.
                            </p>
                        </div>

                        <div className="tw-flex tw-flex-col">
                            <SyncStatusRecipientsUpdater>
                                {({
                                    loading: updating,
                                    updateSyncStatusRecipients,
                                }) => (
                                    <SyncStatusRecipientsFetcher
                                        organizationID={organizationID}
                                    >
                                        {({ loading, recipients }) => {
                                            if (loading) {
                                                return <Loader />;
                                            }

                                            return (
                                                <SyncStatusRecipientsForm
                                                    disabled={updating}
                                                    recipients={recipients}
                                                    onSubmit={(
                                                        updatedRecipients
                                                    ) => {
                                                        updateSyncStatusRecipients(
                                                            {
                                                                recipients: updatedRecipients,
                                                            }
                                                        ).then(() => {
                                                            // Close out modal
                                                            setShowModal(false);
                                                        });
                                                    }}
                                                    onCancel={() =>
                                                        setShowModal(false)
                                                    }
                                                />
                                            );
                                        }}
                                    </SyncStatusRecipientsFetcher>
                                )}
                            </SyncStatusRecipientsUpdater>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

enum ServicesWithOverrides {
    dropbox = "dropbox",
    okta = "okta",
    salesforce = "salesforce",
    servicenow = "servicenow",
    slack = "slack",
    slackEnterprise = "slack-enterprise",
    pingIdentity = "ping-identity",
    workday = "workday",
    webex = "webex",
    trello = "trello",
    zoom = "zoom",
    zendesk = "zendesk",
    wrike = "wrike",
    onelogin = "onelogin",
    coupa = "coupa",
    docusign = "docusign",
    gitlab = "gitlab",
    ringcentral = "ringcentral",
    netskope = "netskope",
}

/**
 * INTEGRATION_ICON_OVERRIDES
 * This is a stop-gap to override the default IntegrationService icons with versions that look better at small sizes
 * This will be replaced when the API adds support IntegrationContent.logoUrlSmall, which will be managed in Contentful
 */
export const INTEGRATION_ICON_OVERRIDES: {
    [key in ServicesWithOverrides]: string;
} = {
    [ServicesWithOverrides.dropbox]: "https://logo.clearbit.com/dropbox.com",
    [ServicesWithOverrides.okta]:
        "https://images.ctfassets.net/30txq7qy6s8o/3mWKHFfeiOtiNN6jwqYgD/98778fbae569b7ac11f098880c37e52f/okta-logo-small.png?h=250",
    [ServicesWithOverrides.salesforce]:
        "https://images.ctfassets.net/30txq7qy6s8o/5Qs1JxcCBk0ulQl8stoHlV/46d808f55f151bc1a902b5b2d9bf3c7b/salesforce-logo.svg",
    [ServicesWithOverrides.servicenow]:
        "https://images.ctfassets.net/30txq7qy6s8o/Z4Tmpv0FM8KMLL9FihdKQ/3c59c9067f7bd24bb68afbabe0a814f4/servicenow-logo.png?h=250",
    [ServicesWithOverrides.slack]: "https://logo.clearbit.com/slack.com",
    [ServicesWithOverrides.slackEnterprise]:
        "https://logo.clearbit.com/slack.com",
    [ServicesWithOverrides.pingIdentity]:
        "https://images.ctfassets.net/r94iek8mbu1h/3BjDDrKw4Iiv5IIuLMASka/997b1cf59784f50141fb69a3a394ba07/Ping-Identity_Logo.png",
    [ServicesWithOverrides.workday]:
        "https://images.ctfassets.net/30txq7qy6s8o/1KntY8Tfce3OXokIhwrQhO/0316c179a6f4d612264256643011caad/workday-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.zoom]:
        "https://images.ctfassets.net/30txq7qy6s8o/OdmaxG9aoCTJ7qAZ8EkPo/8cc7825d1aec3ed2a4d607df81fac25a/zoom-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.webex]:
        "https://images.ctfassets.net/30txq7qy6s8o/2MyscRRJyohDdorzTNgd1e/64e34f9558500f472b8c425fff09de4f/webex-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.trello]:
        "https://images.ctfassets.net/30txq7qy6s8o/19Zuq7XBGnBog9nJKZFRrk/8406a7188295ad54de29415d2ee429f6/trello-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.ringcentral]:
        "https://images.ctfassets.net/30txq7qy6s8o/5c05og7co7o5PKTkOlc2sv/2778f2ef4c752bf95017cd86d5e59f89/ringcentral-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.onelogin]:
        "https://images.ctfassets.net/30txq7qy6s8o/5aXFp6ndhG4gB1mIVpoo0c/ee6839d7af24e59cee7a609cab188abe/onelogin-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.coupa]:
        "https://images.ctfassets.net/30txq7qy6s8o/5yrFbVZUTjUAC15fxYoN5x/65e8632d48067b8f2cb55484f5fa3472/coupa-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.gitlab]:
        "https://app.contentful.com/spaces/30txq7qy6s8o/assets/3bjS7lsw7bITvHJ68hQbQD",
    [ServicesWithOverrides.wrike]:
        "https://images.ctfassets.net/30txq7qy6s8o/4Op4rYa1KCTOLEYFWKCQAu/51f63202ec4d27454a42ec93a18617c0/wrike-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.docusign]:
        "https://images.ctfassets.net/30txq7qy6s8o/2fPdbe0mnmuPNavH7JtkgN/130b49ecba62823d767e91b269b6a877/docusign-small.png?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.zendesk]:
        "https://images.ctfassets.net/30txq7qy6s8o/5Fk9i2TUqzNpXHgZRvDXhS/620a55f715c24ae494ec8bf9a311e073/zendesk-small.jpeg?w=30&h=30&fit=thumb",
    [ServicesWithOverrides.netskope]:
        "https://images.ctfassets.net/30txq7qy6s8o/56wwNBqXrIOn8pPghdCi5P/da1a5cae828920a995127f702e80e262/netskope-small.png?w=30&h=30&fit=thumb",
};

import { BreadcrumbNode } from "../types";
import { appFieldIndividualState, appInfoState } from "./state";
import { vendorListingNode } from "../vendorListing";

// // // //

export const appFieldIndividualNode: BreadcrumbNode = {
    getState: appFieldIndividualState,
    parent: vendorListingNode,
};

export const appInfoNode: BreadcrumbNode = {
    getState: () => appInfoState,
    parent: appFieldIndividualNode,
};

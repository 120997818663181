import { FilterExpression } from "./graphql";

/**
 * isFilterExpressionDynamic
 * Returns a boolean indicating whether or not the `expression` param is a dynamic FilterExpression
 * @param expression - the FilterExpression that's being checked
 */
export function isFilterExpressionDynamic(
    expression: FilterExpression
): boolean {
    return [
        FilterExpression.ddlt,
        FilterExpression.ddlte,
        FilterExpression.ddgt,
        FilterExpression.ddgte,
    ].includes(expression);
}

import classnames from "classnames";
import * as React from "react";
import { DragHandleProps } from "react-beautiful-dnd";
import styles from "./styles.module.css";

// // // //

function DragHandleDotsRow() {
    return (
        <span className={`d-flex flex-row ${styles.dragHandleRow}`}>
            <span className={styles.dragHandleDot} />
            <span className={styles.dragHandleDot} />
        </span>
    );
}

// // // //

interface ColumnListItemDragHandleProps {
    disableReorder: boolean;
    dragHandleProps: DragHandleProps | null | undefined;
}

// ColumnListItemDragHandle
// Renders a handle for drag re-ordering of the ColumnListItem components
export function ColumnListItemDragHandle(props: ColumnListItemDragHandleProps) {
    const handleClassName = classnames({
        "d-flex flex-column": true,
        [styles.dragHandleWrapper]: true,
        [styles.dragHandlePlaceholder]: props.disableReorder,
    });

    return (
        <span className={handleClassName} {...props.dragHandleProps}>
            <DragHandleDotsRow />
            <DragHandleDotsRow />
            <DragHandleDotsRow />
        </span>
    );
}

import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import { InMemoryCache } from "apollo-cache-inmemory";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    addOrgTagToApolloCache,
    SAVE_ORG_TAG_MUTATION,
    SaveOrgTagMutationRequest,
    SaveOrgTagMutationResponse,
} from "./graphql";

// // // //

/**
 * SaveOrgTagProps
 * Parameters for the saveOrgTag function provided by the SaveOrgTagMutation component to props.children
 * NOTE - include `id` property to update an existing OrgTag
 */
interface SaveOrgTagProps {
    id?: string;
    name: string;
    description: string;
}

/**
 * SaveOrgTagMutationLayout
 * Props for SaveOrgTagMutation
 */
interface SaveOrgTagMutationProps {
    children: (childProps: {
        loading: boolean;
        saveOrgTag: (
            props: SaveOrgTagProps
        ) => Promise<{ data: SaveOrgTagMutationResponse | undefined }>;
    }) => React.ReactNode;
}

/**
 * SaveOrgTagMutationLayout
 * Creates or Updates a single OrgTag
 * @param props - see SaveOrgTagMutationProps
 */
export function SaveOrgTagMutationLayout(
    props: SaveOrgTagMutationProps & { user: UserState }
) {
    const { user } = props;

    return (
        <Mutation<SaveOrgTagMutationResponse, SaveOrgTagMutationRequest>
            mutation={SAVE_ORG_TAG_MUTATION}
            onError={() => null}
            update={(
                cache: InMemoryCache,
                result: { data: undefined | SaveOrgTagMutationResponse }
            ) => {
                // Short-circuit callback if result.data isn't defined
                if (!result.data) {
                    return null;
                }

                // Add the new/updated OrgTag to the Apollo cache
                addOrgTagToApolloCache({
                    cache,
                    organizationID: user.organizationID,
                    orgTag: result.data.saveOrgTag,
                });
            }}
        >
            {(commitMutation, { loading, error }) => {
                // Defines saveOrgTag function
                // Returns Promise<MutationResult> so downstream components can await the resolution of the mutation
                function saveOrgTag(
                    saveOrgTagProps: SaveOrgTagProps
                ): Promise<{ data: SaveOrgTagMutationResponse | undefined }> {
                    return new Promise((resolve) => {
                        // Invokes commitMutation
                        return commitMutation({
                            variables: {
                                input: {
                                    ...saveOrgTagProps,
                                    organizationID: user.organizationID,
                                },
                            },
                        }).then(
                            (response: {
                                data: undefined | SaveOrgTagMutationResponse;
                            }) => resolve(response)
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handle error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {props.children({
                            loading,
                            saveOrgTag,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// // // //

export const SaveOrgTagMutation: React.ComponentType<SaveOrgTagMutationProps> = withUser(
    SaveOrgTagMutationLayout
);

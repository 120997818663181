import { buildQsFromObject } from "@src/util/route";
import memoize from "lodash.memoize";

// // // //

// Defines memoized version of buildQsFromObject
const buildQsFromObjectMemo = memoize(buildQsFromObject);

/**
 * UrlParamsValue
 * Type used to describe raw URL parameters pulled from the URL
 */
export interface UrlParamsValue {
    [key: string]: string;
}

/**
 * buildUrlWithParams
 * Builds a URL with a queryString derived from props.params
 * @param props.location - the location for the URL
 * @param props.params - the object that's being turned into a query string (using buildQsFromObject)
 */
export function buildUrlWithParams(props: {
    location: string;
    params: { [key: string]: any };
}): string {
    const { location, params } = props;

    // Returns location without _any_ search params if there are none defined
    // This prevents a stray trailing "?" on the URL string
    if (Object.keys(params).length === 0) {
        return location;
    }

    // Returns complete URL with encoded params from buildQsFromObjectMemo
    return `${location}${buildQsFromObjectMemo(params)}`;
}

import { webConfig } from "@src/config";

export interface Currency {
    raw: number;
    formatted: string;
}

// spendAppName is the app that we use to fetch spend data
export const spendAppName = webConfig.doraHost;

// SpendOverview is the overview that we return about spend in listings
interface SpendOverview {
    vendorID: number;
    app_id: number;
    // this is a date time that we'll need to parse
    lastPaymentDate: string | null;
    // lastPurchaseAmount is the last purchase amount with a dollar sign to display
    lastPurchaseAmount: string;
    totalDisplay: string;
    convertedTotal: null | Currency;
    lastConvertedAmount: null | Currency;
}

interface MasterTeam {
    id: number;
    name: string;
    hexColor: string;
    budget: number;
    initials: string;
}

// VendorList is the limited vendor properties that we return for listing pages
type VendorList = {
    id: number;
    name: string;
    domain: string;
    logo: string;
    category: string;
};

// SpendListResult is a single app row for spend pages
export interface SpendListResult {
    vendor: VendorList;
    spend: SpendOverview;
    // this is a date value we'll have to parse
    renewalDate: string | null;
    state: string;
    id: number;
    teams: MasterTeam[] | null;
}

export interface SpendListMetadata {
    totalSpend: string;
    convertedSpend: Currency | null;
    appsWithSpend: number;
    appsWithNoSpend: number;
}

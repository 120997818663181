import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { routes } from "@src/routes";
import { BreadcrumbEvents } from "../analytics";
import { BreadcrumbState, GetBreadcrumbState } from "../types";

// // // //

// Factory for building settings routes (all of which are non links, meaning they are active)
function buildSettingsState(label: string): GetBreadcrumbState {
    return (_route: RouteState): BreadcrumbState => ({
        enabled: true,
        active: true, // Always active because this will not be a link
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label },
        },
    });
}
// /settings
export const settingsState: GetBreadcrumbState = buildSettingsState("Settings");
// /settings/users
export const settingsUsersState: GetBreadcrumbState = buildSettingsState(
    "Users"
);
// settings/teams
export const settingsTeamsState: GetBreadcrumbState = buildSettingsState(
    "Teams"
);
// /settings/saml
export const settingsSAMLState: GetBreadcrumbState = buildSettingsState("SAML");
// /settings/audit-log
export const settingsAuditLogState: GetBreadcrumbState = buildSettingsState(
    "Audit Log"
);
// /settings/payment-uploads
export const settingsPaymentUploadsState: GetBreadcrumbState = buildSettingsState(
    "Payment Uploads"
);

// /settings/fields
export const settingsCustomFieldsState = (
    route: RouteState
): BreadcrumbState => ({
    linkProps: {
        href: {
            pathname: routes.settings.customFields,
            query: {},
        },
        as: routes.settings.customFields,
    },
    analytics: {
        eventName: BreadcrumbEvents.clickBreadcrumb,
        eventProps: {
            breadcrumb: "settings.customFields",
        },
    },
    enabled: true,
    active: route.nextPathname === routes.settings.customFields,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Custom Fields" },
    },
});

// /settings/fields/new
export const settingsCustomFieldsNewState: GetBreadcrumbState = buildSettingsState(
    "New"
);
// /settings/fields/edit
export const settingsCustomFieldsEditState: GetBreadcrumbState = buildSettingsState(
    "Edit"
);

// /settings/set-team-department
export const settingsSetTeamDepartmentState: GetBreadcrumbState = buildSettingsState(
    "Set Teams Usage"
);

// Placeholder state that serves as parent to /reconciliation and /vendor-roles-uploads
export const accessControlState: GetBreadcrumbState = buildSettingsState(
    "Access Control"
);

// /settings/access-control/reconciliation
export const accessControlReconciliationState: GetBreadcrumbState = buildSettingsState(
    "Reconciliation"
);

// /settings/access-control/vendor-roles-uploads
export const accessControlVendorRoleUploadsState = (
    route: RouteState
): BreadcrumbState => ({
    linkProps: {
        href: {
            pathname: routes.settings.accessControlVendorRolesUploadsListing,
            query: {},
        },
        as: routes.settings.accessControlVendorRolesUploadsListing,
    },
    analytics: {
        eventName: BreadcrumbEvents.clickBreadcrumb,
        eventProps: {
            breadcrumb: "settings.accessControlVendorRolesUploadsListing",
        },
    },
    enabled: true,
    active:
        route.nextPathname ===
        routes.settings.accessControlVendorRolesUploadsListing,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Manual Uploads" },
    },
});

// /settings/access-control/vendor-role-uploads/new
export const accessControlVendorRoleUploadsNewState: GetBreadcrumbState = buildSettingsState(
    "New"
);

import { filterAppliedProps, filterRemovedProps } from "@src/analytics/base";
import { DashboardEvents } from "@src/analytics/events";
import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import {
    buildUrlWithParams,
    LinkCC,
    RouteState,
    withRouter,
} from "@src/shared_modules/router";
import { updateParams } from "@src/util/route";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

// DropdownTileProps are the props of a component that is passed to the Tile property of the FilterDropdown component
export interface DropdownTileProps {
    name: string;
    href: string;
    isActive: boolean;
    filterVal: string;
    route: RouteState;
}

interface Props {
    // key is the filter key that should be used
    name: string;
    filters: string[];
    // title is the title of the app - if there are no multiple options and there is nothing selected this will be the value that shows
    title: string;
    // multiple indicates whether we can have multiple options at once - if not we update the urls with a single option
    multiple: boolean;
    // Tile is the component that will be rendered inside the DropdownListItem instead of the default DropdownLink
    Tile?: React.ComponentType<DropdownTileProps>;
}

// DropdownLink is the dropdown link to use
export function DropdownLink(
    props: {
        route: RouteState;
        children: React.ReactNode;
        className?: string;
    } & DropdownTileProps
) {
    // if the filter is active then we're tracking it's removal
    const propsFn = props.isActive ? filterRemovedProps : filterAppliedProps;
    const { className = "" } = props;
    const linkClass = classnames(
        "d-flex align-items-center font-secondary font-size-14-px px-15-px text-grey-mid whitespace-nowrap",
        {
            [styles.filter]: true,
            [className]: className !== "",
        }
    );

    return (
        <LinkCC
            className={linkClass}
            href={{
                pathname: props.route.nextPathname,
                query: props.route.pathParams,
            }}
            as={props.href}
            eventName={DashboardEvents.filterApplied}
            eventProps={propsFn(props.name, props.filterVal, props.href)}
        >
            {props.children}
        </LinkCC>
    );
}

export function FilterDropdown(props: Props & { route: RouteState }) {
    let { title } = props;
    // Tile is either equal to props.Tile or DropdownLink
    const { Tile = DropdownLink } = props;
    const selected = props.route.params[props.name];
    if (!props.multiple && typeof selected === "string") {
        title = selected;
    }
    return (
        <div className={styles.filterWrapper}>
            <Dropdown>
                <p
                    className={`d-flex align-items-center font-secondary font-size-14-px px-15-px text-grey-mid ${styles.filter}`}
                >
                    {title}
                </p>
                {props.filters.map((filterVal: string) => {
                    const [updatedParams, isActive] = updateParams({
                        params: props.route.params,
                        key: props.name,
                        val: filterVal,
                        replace: !props.multiple,
                    });
                    // reset the page param when the filter is applied
                    delete updatedParams.page;

                    // Build the href for location + params
                    const href = buildUrlWithParams({
                        location: props.route.location,
                        params: updatedParams,
                    });

                    return (
                        <DropdownListItem
                            active={isActive}
                            key={`filter-${props.name}-${filterVal}`}
                            searchText={filterVal}
                        >
                            <Tile
                                isActive={isActive}
                                href={href}
                                route={props.route}
                                name={props.name}
                                filterVal={filterVal}
                            >
                                {filterVal}
                            </Tile>
                        </DropdownListItem>
                    );
                })}
            </Dropdown>
        </div>
    );
}

export const FilterDropdownCC: React.ComponentType<Props> = withRouter(
    FilterDropdown
);

import classnames from "classnames";
import * as React from "react";

// // // // //

/**
 * Renders a button with a description inside DashboardListHeader
 */
export function HeaderButton(props: {
    descriptionLines: [string, string];
    buttonText: string;
    className?: string;
    onClick: () => void;
}) {
    const { className, buttonText, descriptionLines, onClick } = props;
    return (
        <div
            className={classnames("tw-flex tw-items-center p-20-px", className)}
        >
            <div className="font-secondary font-size-12-px line-height-18-px pr-20-px">
                <p>{descriptionLines[0]}</p>
                <p>{descriptionLines[1]}</p>
            </div>
            <button
                onClick={onClick}
                className="btn-sm btn-stroked-primary font-secondary px-20-px"
            >
                {buttonText}
            </button>
        </div>
    );
}

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { isWeekInputDataValid } from "./isWeekInputDataValid";
import { WeekInputValue } from "./types";

// // // //

/**
 * Returns boolean if WeekInputValue encapsulates valid base-state for the CustomDateRangeInput component
 */
export function isCustomDateRangeInputValid(props: {
    value: WeekInputValue;
    expression: FilterExpression;
}): boolean {
    const { value, expression } = props;
    return (
        isWeekInputDataValid({ value, expression }) &&
        typeof value[0] === "string" &&
        typeof value[1] === "string"
    );
}

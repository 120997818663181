import React from "react";

export function Checkmark(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            width="29"
            height="23"
            viewBox="0 0 29 23"
        >
            <g fill="#57d693" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    fill="#57d693"
                    d="M9.478 22.844L.174 13.538a.59.59 0 010-.835l3.284-3.286a.59.59 0 01.835 0l5.603 5.603L24.742.172a.595.595 0 01.84.005l3.25 3.321a.59.59 0 01-.005.83L10.314 22.844a.595.595 0 01-.836 0z"
                ></path>
            </g>
        </svg>
    );
}

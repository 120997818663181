import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import classnames from "classnames";
import isequal from "lodash.isequal";
import * as React from "react";
import { InputSize } from "../input";
import styles from "./styles.module.css";

// // // //

/**
 * AcceptedValueType
 * Defines a type union that represents what types are accepted by `<DropdownSelect />`
 */
type AcceptedValue = string | number | boolean | string[] | number[];

/**
 * DropdownOption
 * Defines the types accepted by the DropdownSelect component
 * Values are represented as { humanValue, internalValue } objects. The `humanValue` property
 * is the human-readable label, and the `internalValue` property is either
 * the string or number internalValue, i.e. `{ humanValue: "One", internalValue: 1 }`
 */
export interface DropdownOption {
    humanValue: string | React.ReactNode;
    internalValue: AcceptedValue;
    description?: string;
}

/**
 * DropdownSelectProps
 * Props accepted by the DropdownSelect component
 * `value` - the current value modeled by <DrodownSelect />
 * `onChange` - function invoked when the user selects a value.
 * `options` - an array of available options - see DropdownOption type for more details.
 * `disableSearch` - optional - disables the search feature in the Dropdown component
 * `placeholder` - optional - the text displayed when no value is selected
 * `className` - optional - a className to apply alongside `styles.dropdownWrapper`
 * `size` - optional - the size applied to the <DropdownButton /> components rendered here. Defaults to `md`.
 */
export interface DropdownSelectProps {
    value: AcceptedValue | null;
    onChange: (v: AcceptedValue | null) => void;
    options: DropdownOption[];
    disableSearch?: boolean;
    placeholder?: string;
    className?: string;
    size?: InputSize;
}

/**
 * DropdownButtonProps
 * Props passed to the DropdownButton component
 * `value` - the value associated with the `DropdownButton`
 * `onClick` - function prop that sets the value in the `DropdownSelect`
 * `children` - content nested inside the `DropdownButton`
 * `size` - the size of `DropdownButton` (see `InputSize` enum)
 * `placeholder` - boolean indicating whether or not the `DropdownButton` in question is
 *                 being used to display a placeholder value. Results in CSS color change.
 */
interface DropdownButtonProps {
    value: AcceptedValue | null;
    onClick: (v: AcceptedValue | null) => void;
    children: React.ReactNode;
    size: InputSize;
    placeholder?: boolean;
}

// // // //

/**
 * DropdownButton
 * Renders the button inside each <DropdownListItem />
 * @param props - see `DropdownButtonProps`
 */
export function DropdownButton(props: DropdownButtonProps) {
    const buttonClassName = classnames({
        "w-100": true,
        [styles.dropdownButton]: true,
        [styles.dropdownButtonSm]: props.size === InputSize.sm,
        [styles.dropdownButtonMd]: props.size === InputSize.md,
        [styles.dropdownButtonLg]: props.size === InputSize.lg,
        "text-grey-dim": props.placeholder,
    });

    return (
        <button
            type="button"
            className={buttonClassName}
            onClick={() => props.onClick(props.value)}
        >
            {props.children}
        </button>
    );
}

// // // //

/**
 * DropdownSelect
 * A component used to create simple `<select />`-style
 * components using the `<Dropdown />` component from uncommons.
 * See `dropdown_select_test.tsx` for usage examples.
 * @param props - see `DropdownSelectProps`
 */
export function DropdownSelect(props: DropdownSelectProps) {
    // Pulls size, disableSearch, field select options
    const { options, disableSearch = false, size = InputSize.md } = props;

    // Defines placeholder from props, or default value
    const { placeholder = "Select a value", className = "" } = props;

    // Finds the selectedOption, if available
    const selectedOption: DropdownOption | undefined = options.find((opt) => {
        // Compare array and non-array types using lodash.isequal
        return isequal(opt.internalValue, props.value);
    });

    // Gets the value displayed by the `<Dropdown />` in its "closed" state
    // This will either be the humanValue associated with props.value, or the placeholder
    const selectedValueLabel: string | React.ReactNode =
        selectedOption === undefined ? placeholder : selectedOption.humanValue;

    // Defines className for <Dropdown /> component
    const dropdownClassName: string = classnames({
        "w-100": true,
        [className]: className !== "",
    });

    // Returns the component
    return (
        <Dropdown
            className={dropdownClassName}
            showSearchCount={disableSearch ? null : undefined}
        >
            <DropdownButton
                placeholder={
                    selectedValueLabel === placeholder &&
                    selectedOption === undefined
                }
                size={size}
                value={props.value}
                onClick={() => null}
            >
                <p className={classnames("whitespace-nowrap", styles.label)}>
                    {selectedValueLabel}
                </p>
            </DropdownButton>
            {options.map(
                (
                    opt: DropdownOption
                ): React.ReactComponentElement<typeof DropdownListItem> => {
                    // Pulls label, value. and description from each DropdownOption
                    const label: string | React.ReactNode = opt.humanValue;
                    const value: AcceptedValue = opt.internalValue;
                    const { description = "" } = opt;

                    // Defines unique `key` for each DropdownListItem
                    let uniqueKey: string = "";
                    if (Array.isArray(value)) {
                        uniqueKey = value.join("-");
                    } else {
                        uniqueKey = String(value);
                    }

                    // Transforms uniqueKey into lowercase + strips whitespace
                    // Pre-pends with `dropdown-item-` prefix
                    uniqueKey = uniqueKey.toLowerCase().replace(/\s/g, "-");
                    uniqueKey = `dropdown-item-${uniqueKey}`;

                    // Returns the DropdownListItem associated with each DropdownOption
                    return (
                        <DropdownListItem
                            active={value === props.value}
                            key={uniqueKey}
                            searchText={typeof label === "string" && label}
                        >
                            <DropdownButton
                                size={size}
                                value={value}
                                onClick={props.onChange}
                            >
                                <React.Fragment>
                                    <p
                                        className={classnames(
                                            "whitespace-nowrap text-updated-black",
                                            styles.label
                                        )}
                                    >
                                        {label}
                                    </p>
                                    {/* Renders DropdownOptions.description IFF defined */}
                                    {opt.description && (
                                        <p
                                            className={classnames(
                                                "text-grey-mid font-secondary",
                                                styles.description
                                            )}
                                        >
                                            {description}
                                        </p>
                                    )}
                                </React.Fragment>
                            </DropdownButton>
                        </DropdownListItem>
                    );
                }
            )}
        </Dropdown>
    );
}

import { BreadcrumbNode } from "../types";
import { paymentListingState } from "./state";
import { spendNode } from "../spend";

// // // //

export const paymentListingNode: BreadcrumbNode = {
    getState: paymentListingState,
    parent: spendNode,
};

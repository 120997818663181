import { client } from "@src/requests/apollo";
import { buildUrlWithParams, RouteState } from "@src/shared_modules/router";
import {
    UserDashboardSetting,
    UserDashboardSettingsMutation,
} from "@src/modules/app/components/user_dashboard_settings";
import { captureMessage } from "@src/util/analytics";
import { InMemoryCache } from "apollo-cache-inmemory";
import get from "lodash.get";
import omit from "lodash.omit";
import * as React from "react";
import { updateCachedUserDashboardSettings } from "../updateCachedUserDashboardSettings";
import { buildColumnSettingsComponentName as getComponentName } from "./buildColumnSettingsComponentName";
import {
    COLUMN_SETTINGS_PARAM,
    COMPRESSED_COLUMN_SETTINGS_PARAM,
    MANAGE_COLUMNS_PARAM,
    USER_DASHBOARD_SETTING_COLUMNS_SETTINGS_KEY as SETTINGS_KEY,
} from "./constants";
import { ColumnSetting } from "./types";

// // // //

/**
 * SaveColumnSettingsMutationProps
 * @param componentName - See AdvancedFiltersProvider
 * @param filtersQuery - See AdvancedFiltersProvider.props.filtersQuery
 * @param children - Fucntion that accepts an object with `savingColumnSettings` boolean + `saveColumnSettings` function and returns a React.ReactNode.
 */
interface SaveColumnSettingsMutationProps<T2> {
    componentName: string;
    route: RouteState;
    filtersQuery: {
        query: any;
        variables: T2;
        dataKey: string;
    };
    children: (childProps: {
        savingColumnSettings: boolean;
        saveColumnSettings: (params: {
            columnSettings: ColumnSetting[];
        }) => Promise<void>;
    }) => React.ReactNode;
}

/**
 * SaveColumnSettingsMutation
 * Fetches the `Filters` instance data from GQL and passes it into the `AdvancedFiltersLayout` component
 * @param props - see SaveColumnSettingsMutationProps
 */
export function SaveColumnSettingsMutation<T1, T2>(
    props: SaveColumnSettingsMutationProps<T2>
) {
    // Assigns defaults prop values
    const { route, componentName, filtersQuery } = props;

    return (
        <UserDashboardSettingsMutation successMessage="Successfully updated column settings">
            {({ savingUserDashboardSettings, saveUserDashboardSettings }) => {
                // Defines an intermediary function for saving ColumnSettings
                // Inserts updated value into Apollo cache after successful save (prevents us from re-querying at the AdvancedFiltersProvider level)
                function saveColumnSettings(params: {
                    columnSettings: ColumnSetting[];
                }): Promise<void> {
                    // Invokes saveUserDashboardSettings using w/ props.componentName + params.columnSettings
                    return saveUserDashboardSettings({
                        componentName: getComponentName({ componentName }),
                        value: {
                            [SETTINGS_KEY]: params.columnSettings,
                        },
                    }).then((response) => {
                        // Pulls new/updated UserDashboardSetting from response
                        const userDashboardSetting: UserDashboardSetting | null = get(
                            response,
                            "data.userDashboardSettings",
                            null
                        );

                        // If userDashboardSetting is not defined -> captureMessage + short-circuit function execution
                        if (userDashboardSetting === null) {
                            captureMessage(
                                "SaveColumnSettingsMutation - invalid response",
                                {
                                    extra: {
                                        response,
                                    },
                                }
                            );
                            return;
                        }

                        // Inserts the UserDashboardSetting into the Apollo cache
                        // NOTE - we cast the ApolloClient.cache as `InMemoryCache` to prevent a library-level type mismatch
                        // Because we are only using a limited subset of methods on the cache (readQuery + writeQuery),
                        // We don't need to be concerned about potential issues arising from this type mismatch
                        updateCachedUserDashboardSettings<T1, T2>({
                            cache: client.cache as InMemoryCache,
                            query: filtersQuery.query,
                            variables: filtersQuery.variables,
                            userDashboardSetting,
                        });

                        // Updates route.state to close ManageColumnsModal (MANAGE_COLUMNS_PARAM
                        //  + and clear local ColumnSettings state (COLUMN_SETTINGS_PARAM + COMPRESSED_COLUMN_SETTINGS_PARAM)
                        const updatedParams = omit(props.route.params, [
                            MANAGE_COLUMNS_PARAM,
                            COLUMN_SETTINGS_PARAM,
                            COMPRESSED_COLUMN_SETTINGS_PARAM,
                        ]);

                        // Defines URL with updated params
                        const url = buildUrlWithParams({
                            location: route.location,
                            params: updatedParams,
                        });

                        // Updates route.location
                        props.route.updateRouteAction(
                            url,
                            props.route.nextPathname
                        );
                    });
                }

                // Returns props.children w/ saveColumnSettings + savingDefaultFilters
                return props.children({
                    saveColumnSettings,
                    savingColumnSettings: savingUserDashboardSettings,
                });
            }}
        </UserDashboardSettingsMutation>
    );
}

import { SuggestedFiltersEvents } from "@src/analytics/advanced_filters";
import { stripTypenameFromAppliedFilters } from "@src/modules/advanced_filters/components/advanced_filters";
import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import { withUser } from "@src/shared_modules/user";
import { UserState } from "@src/shared_modules/user";
import classnames from "classnames";
import * as React from "react";
import { getURLWithFiltersInput } from "../getURLWithFiltersInput";
import { SuggestedFilter } from "./graphql";

// // // //

/**
 * SuggestedFilterLinkProps
 * @param suggestedFilter - The SuggestedFilter for which the LinkCC is being rendered
 * @param children - Child content to render inside the LinkCC
 * @param className - (optional) Classname to be applied instead of default `btn-outline-primary`
 * @param pathname - (optional) - Alternative pathname applied by the LinkCC
 * @param location - (optional) - Alternative location applied by the LinkCC
 */
interface SuggestedFilterLinkProps {
    suggestedFilter: SuggestedFilter;
    className?: string;
    children: React.ReactNode;
    pathname?: string;
    location?: string;
}

/**
 * SuggestedFilterLink
 * Renders a LinkCC that applies SuggestedFilter.filters to the current URL
 * @param props - see SuggestedFilterLinkProps
 */
export function SuggestedFilterLinkLayout(
    props: SuggestedFilterLinkProps & { route: RouteState; user: UserState }
) {
    const {
        suggestedFilter,
        route,
        user,
        className = "",
        pathname = null,
        location = null,
    } = props;

    // Defines URL to current location with SuggestedFilters.filters applied
    const url = getURLWithFiltersInput({
        location: location || route.location,
        params: route.params,
        filtersInput: stripTypenameFromAppliedFilters(
            suggestedFilter.filters,
            user.organizationID
        ),
    });

    return (
        <LinkCC
            className={classnames({
                [className]: className !== "",
            })}
            href={{
                pathname: pathname || route.nextPathname,
                query: route.pathParams,
            }}
            as={url}
            eventName={SuggestedFiltersEvents.suggestedFilterLinkClick}
            eventProps={{ suggestedFilterID: suggestedFilter.id }}
        >
            {props.children}
        </LinkCC>
    );
}

export const SuggestedFilterLink: React.ComponentType<SuggestedFilterLinkProps> = withRouter(
    withUser(SuggestedFilterLinkLayout)
);

import { captureMessage } from "@src/util/analytics";
import React from "react";
import {
    FilterExpression,
    mapFilterExpressionToHumanValue,
} from "../advanced_filter_editor/graphql";
import { Tooltip } from "../../../../shared_modules/tooltip";

// // // //

/*
 * mapFilterExpressionToSymbol
 * Maps each FilterExpression to a React.ReactNode
 * NOTE - we map to a `React.ReactNode` because simply mapping to `string` would not
 * correctly render HTML entities & unicode expressions without using `dangerouslySetInnerHTML`
 * HTML unicode reference:
 * https://www.w3schools.com/charsets/ref_utf_math.asp
 */
export const mapFilterExpressionToSymbol: {
    [key in FilterExpression]: React.ReactNode | null;
} = {
    [FilterExpression.eq]: <React.Fragment>{"="}</React.Fragment>,
    [FilterExpression.neq]: <React.Fragment>&ne;</React.Fragment>,
    [FilterExpression.gt]: <React.Fragment>{">"}</React.Fragment>,
    [FilterExpression.lt]: <React.Fragment>{"<"}</React.Fragment>,
    [FilterExpression.gte]: <React.Fragment>&ge;</React.Fragment>,
    [FilterExpression.lte]: <React.Fragment>&le;</React.Fragment>,
    [FilterExpression.like]: <React.Fragment>&isin;</React.Fragment>,
    [FilterExpression.nlike]: <React.Fragment>&notin;</React.Fragment>,
    [FilterExpression.ilike]: <React.Fragment>&isin;</React.Fragment>,
    [FilterExpression.nilike]: <React.Fragment>&notin;</React.Fragment>,
    [FilterExpression.ddgt]: <React.Fragment>{">"}</React.Fragment>,
    [FilterExpression.ddlt]: <React.Fragment>{"<"}</React.Fragment>,
    [FilterExpression.ddgte]: <React.Fragment>&ge;</React.Fragment>,
    [FilterExpression.ddlte]: <React.Fragment>&le;</React.Fragment>,
    [FilterExpression.icontains]: <React.Fragment>&isin;</React.Fragment>,
    [FilterExpression.nicontains]: <React.Fragment>&notin;</React.Fragment>,
    [FilterExpression.isdefined]: <React.Fragment>&#8707;</React.Fragment>,
    [FilterExpression.isundefined]: <React.Fragment>&#8708;</React.Fragment>,
    [FilterExpression.adp_upe]: null,
    [FilterExpression.adp_pde]: null,
    [FilterExpression.adp_pwe]: null,
    [FilterExpression.adp_fde]: null,
    [FilterExpression.adp_ude]: null,
    [FilterExpression.adp_dde]: null,
    [FilterExpression.and]: null,
    [FilterExpression.or]: null,
    [FilterExpression.empty]: null,
};

/**
 * FilterExpressionSymbol
 * Renders a "symbol" for a single value in the FilterExpression enum
 * @param props.filterExpression - the FilterExpression for which the corresponding symbol is being rendered
 * @param props.className - (optional) a className to style the `<p>` that wraps the
 * @param props.tooltip - (optional) boolean flag indicating whether or not to render a TopHoverTooltip around the symbol
 */
export function FilterExpressionSymbol(props: {
    filterExpression: FilterExpression;
    className?: string;
    tooltip?: boolean;
}) {
    const { filterExpression, className = "", tooltip = "" } = props;

    // Defines a reference to the React.ReactNode associated with props.filterExpression
    const symbolNode: React.ReactNode | null =
        mapFilterExpressionToSymbol[filterExpression];

    // If there's no symbol associated with props.filterExpression, return null
    // We return null for `and`, `or`, and `empty` FilterExpression values
    if (symbolNode === null) {
        return null;
    }

    // If for some reason symbolNode is undefined, we capture a message
    if (symbolNode === undefined) {
        captureMessage(
            "FilterExpressionSymbol - invalid FilterExpression value",
            { extra: { filterExpression } }
        );
    }

    // If props.tooltip is NOT defined, just return <p>
    if (tooltip === "") {
        return <p className={className}>{symbolNode}</p>;
    }

    // Wraps the symbol in a tooltip
    return (
        <div className="d-flex flex-column">
            <Tooltip
                placement="top"
                tooltipContent={
                    <p className="tw-p-3 font-size-12-px text-black font-secondary whitespace-nowrap">
                        {mapFilterExpressionToHumanValue[filterExpression]}
                    </p>
                }
            >
                {({ setTriggerRef }) => (
                    <p ref={setTriggerRef} className={className}>
                        {symbolNode}
                    </p>
                )}
            </Tooltip>
        </div>
    );
}

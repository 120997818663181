import { webConfig } from "@src/config";
import { unauthenticatedRoutes } from "@src/routes";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import "isomorphic-fetch";
import get from "lodash.get";

const ERROR_MESSAGE_401: string =
    "Your session has expired. Please re-enter your login credentials";

// we use the upload link to automatically be able handle file uploads via graphql
const httpLink = createUploadLink({
    credentials: "include",
    fetchOptions: {
        mode: "cors",
        cache: "no-cache",
    },
    uri: `${webConfig.gwHost}/graphql/query`,
});

// shouldRedirectLogin returns whether an error should redirect to the login page
export function shouldRedirectLogin(statusCode: number | null): boolean {
    if (statusCode !== 401) {
        return false;
    }
    if (typeof window === "undefined") {
        return false;
    }
    return !unauthenticatedRoutes.includes(window.location.pathname);
}

const errorLink = onError(({ networkError }) => {
    // NOTE - we use `lodash.get` to safely access the `statusCode` property on networkError
    // This is done because networkError is typed as `Error | ServerError | ServerParseError`,
    // but the `statusCode` property only exists on `ServerError` and `ServerParseError`
    const statusCode: number | null = get(networkError, "statusCode", null);

    // Handle 401 Unauthorized Error
    // Redirect to the login page & displays error message
    if (shouldRedirectLogin(statusCode)) {
        window.location.assign(
            `/login?error_msg=${encodeURIComponent(ERROR_MESSAGE_401)}`
        );
    }
});

// Composes Apollo link from errorLink + httpLink
// NOTE - order of link array is significant (httpLink must always come last)
// Doc: https://www.apollographql.com/docs/link/overview/#terminating-links
const link = ApolloLink.from([errorLink, httpLink]);

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    connectToDevTools: webConfig.env !== "production",
});

import * as React from "react";

// // // //

/**
 * ApplyDateButton
 * Renders the "Apply Date Filter" button used to trigger the
 * @param props.onClick - Callback fired off when the <button/> is clicked
 */
export function ApplyDateButton(props: {
    disabled: boolean;
    onClick: () => void;
}) {
    const { disabled } = props;
    return (
        <button
            className="btn btn-md btn-primary font-secondary-bold w-100 mt-10-px d-flex justify-content-center"
            onClick={props.onClick}
            disabled={disabled}
        >
            Apply Date Filter
        </button>
    );
}

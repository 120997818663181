import {
    AdvancedFilterEditor,
    Filters,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import classnames from "classnames";
import React from "react";
import styles from "./styles.module.css";
import { SuggestedFilter } from "./suggested_filters/graphql";

// // // //

/**
 * AdvancedFiltersEditorOverlayProps
 * @param filters - The `defaults` and `advanced` filters used to populate the AdvancedFilterEditor
 * @param filtersInput - The user's filter state
 * @param onSave - callback to hide the overlay and persist changes
 * @param onCancel - callback to hide the overlay and discard changes
 */
interface AdvancedFiltersEditorOverlayProps {
    filters: Filters;
    filtersInput: FiltersInput;
    suggestedFilters: SuggestedFilter[];
    onSave: (updatedFiltersInput: FiltersInput) => void;
    onCancel: () => void;
}

/**
 * AdvancedFiltersEditorOverlay
 * Renders the <AdvancedFilterEditor />, toggles hide/show
 * @param props - see AdvancedFiltersEditorOverlayProps
 */
export function AdvancedFiltersEditorOverlay(
    props: AdvancedFiltersEditorOverlayProps
) {
    const [filtersInput, setFiltersInput] = React.useState<FiltersInput>(
        props.filtersInput
    );

    // Defines flag indicating whether or not the `FiltersInput` is empty
    // Used to set `disable` attribute on `Apply Filters` button
    const isFiltersInputEmpty: boolean =
        filtersInput.defaults.length === 0 &&
        filtersInput.advanced.length === 0;

    // Defines listContainerClassName
    // NOTE - we need to "shift" the list container down by 20px when there are values present
    // so the top of the list aligns with the top of the "Add Additional Filters" button
    const listContainerClassName: string = classnames({
        "col-sm-6": true,
        "mt-20-px pt-25-px mb-40-px": !isFiltersInputEmpty,
    });

    return (
        <div className="d-flex w-100">
            <div
                className={classnames(
                    styles.advancedFiltersEditorOverlay,
                    "d-flex flex-row flex-grow-1 pt-50-px"
                )}
            >
                {/* Overlay Wrapper */}
                <div
                    className={classnames(
                        "row d-flex w-100 pb-50-px",
                        styles.advancedFiltersEditorOverlayScroll
                    )}
                >
                    <div className="col-sm-12">
                        {/* Overlay Header */}
                        <div
                            className={classnames(
                                "row d-flex align-items-center mb-20-px pb-20-px bg-white",
                                styles.advancedFiltersEditorOverlayHeader
                            )}
                        >
                            <div className="col-lg-8">
                                <p className="font-size-32-px text-updated-black font-primary-bold">
                                    Advanced Filters
                                </p>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-end">
                                <button
                                    className="btn btn-lg btn-primary mr-2 whitespace-nowrap font-secondary-bold"
                                    disabled={isFiltersInputEmpty}
                                    onClick={() => {
                                        props.onSave(filtersInput);
                                    }}
                                >
                                    Apply Filters
                                </button>
                                <button
                                    className="btn btn-lg btn-stroked-primary whitespace-nowrap font-secondary-bold"
                                    onClick={props.onCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>

                        {/* Overlay body */}
                        <div className="row d-flex">
                            <div className="col-lg-12 mt-20-px">
                                <p className="font-size-18-px text-updated-black font-primary">
                                    Filter Preview
                                </p>
                                <AdvancedFilterEditor
                                    wrapperClassName={
                                        "row d-flex flex-row-reverse py-5-px"
                                    }
                                    formContainerClassName={"col-sm-6 mt-10-px"}
                                    listContainerClassName={
                                        listContainerClassName
                                    }
                                    formMessageClassName={"d-none"}
                                    filtersInput={filtersInput}
                                    selectedQueryFilters={props.filters}
                                    suggestedFilters={props.suggestedFilters}
                                    onChange={(
                                        updatedFiltersInput: FiltersInput
                                    ) => {
                                        setFiltersInput(updatedFiltersInput);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

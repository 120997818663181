import classnames from "classnames";
import React from "react";
import { FocusProvider } from "../focus_provider";
import styles from "./styles.module.css";

// // // //

/**
 * ButtonDropdownProps
 * `children` - array of React.ReactNode elements, each of which is rendered as an item in `ButtonDropdown`
 * `buttonClassName` - (optional) className for the <button> element that shows/hides the dropdown menu
 * `alignRight` - (optional) boolean indicating that the dropdown menu should align to the right-side of the button
 * `icon` - (optional) a FontAwesome icon to display on the button. Defaults to `fa-caret-down`
 * `openUp` - (optional) makes it so the ButtonDropdown opens upwards
 * `disabled` - (optional) whether or not the <button /> for the dropdown has the `disabled` attribute (will prevent dropdown from opening)
 */
interface ButtonDropdownProps {
    children: React.ReactNode[];
    buttonClassName?: string;
    alignRight?: boolean;
    icon?: string;
    openUp?: boolean;
    disabled?: boolean;
}

/**
 * ButtonDropdown
 * Renders a simple dropdown for an array of buttons
 * @param props - see `ButtonDropdownProps`
 */
export function ButtonDropdown(props: ButtonDropdownProps) {
    const {
        buttonClassName = "",
        icon = "fa-caret-down",
        openUp = false,
        disabled = false,
    } = props;

    // Filters props.children to only include elements of type React.ReactNode
    // This ensures that artifacts from any elements conditionally excluded
    // from props.children don't render empty <li.dropdownItem /> elements
    const filteredChildren: React.ReactNode[] = props.children.filter(
        (ch: any) => ch as React.ReactNode
    );

    return (
        <FocusProvider className={styles.dropdown}>
            {({ focused, setFocused }) => (
                <React.Fragment>
                    <button
                        disabled={disabled}
                        className={classnames({
                            [buttonClassName]: buttonClassName !== "",
                        })}
                        onClick={() => setFocused(true)}
                    >
                        <i className={`fa fa-fw ${icon}`} />
                    </button>
                    <ul
                        className={classnames({
                            [styles.dropdownMenu]: true,
                            [styles.dropdownMenuOpenDown]: !openUp,
                            [styles.dropdownMenuOpenUp]: openUp,
                            [styles.dropdownMenuShow]: focused,
                            [styles.dropdownMenuRight]: props.alignRight,
                        })}
                    >
                        {filteredChildren.map((ch, index) => {
                            return (
                                <li
                                    key={index}
                                    className={styles.dropdownItem}
                                    onClick={() => setFocused(false)}
                                >
                                    {ch}
                                </li>
                            );
                        })}
                    </ul>
                </React.Fragment>
            )}
        </FocusProvider>
    );
}

import { FilterExpression } from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import classnames from "classnames";
import * as React from "react";
import { trackClickApplyDateButton } from "./analytics";
import { ApplyDateButton } from "../date_input/ApplyDateButton";
import { RelativeWeekInput } from "./RelativeWeekInput";
import { WeekInputValue } from "./types";
import { isWeekInputDataValid } from "./isWeekInputDataValid";

// // // //

/**
 * DateInputProps
 * @param props.value - the current value for the DateInput
 * @param props.expression - the current expression for the DateInput
 * @param props.availableExpressions - array of available FilterExpressions
 * @param props.inline - whether or not the DateInput is being rendered by DateInputInline
 * @param props.className - optional className applied to the DateInput's wrapper <div>
 * @param props.onChange - callback function invoked when a change event occurs, updates value + filterExpression
 */
export interface WeekInputProps {
    value: WeekInputValue;
    expression: FilterExpression;
    availableExpressions: FilterExpression[];
    inline?: boolean;
    className?: string;
    onChange: (updatedVal: {
        value: WeekInputValue;
        expression: FilterExpression;
    }) => void;
}

/**
 * WeekInput
 * Date input for AdvancedFilters - supports Past/Future/Fixed dates
 * @param props - see `DateInputProps`
 */
export function WeekInput(props: WeekInputProps) {
    const { inline = false, expression, className = "" } = props;

    // Setup hook to internally store selected value
    const [value, setValue] = React.useState<WeekInputValue>(props.value);

    // Setup React.useEffect for updating EXTERNAL value + expression props when INTERNAL state changes
    React.useEffect(() => {
        // Short-circuit this callback when props.inline is TRUE
        // When props.inline is TRUE, we render the ApplyDateButton component to handle props.onChange
        if (props.inline === true) {
            return;
        }

        // Set the updated expression
        // NOTE - we only set expression = adp_pwe for single number values
        let updatedExpression = FilterExpression.adp_fde;
        if (typeof value[0] === "number") {
            updatedExpression = FilterExpression.adp_pwe;
        }

        // Invoke props.onChange when the value changes
        props.onChange({ value, expression: updatedExpression });
    }, [value]);

    return (
        <div
            className={classnames({
                [className]: className !== "",
            })}
        >
            <div className="px-15-px">
                <RelativeWeekInput value={value} setValue={setValue} />

                {/* NOTE - we only render ApplyDateButton when props.inline is true */}
                {inline === true && (
                    <ApplyDateButton
                        disabled={!isWeekInputDataValid({ value, expression })}
                        onClick={() => {
                            trackClickApplyDateButton();
                            props.onChange({ value, expression });
                        }}
                    />
                )}
            </div>
        </div>
    );
}

import React from "react";

export function Download(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="14"
            viewBox="0 0 11 14"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#5F6060"
                    fillRule="nonzero"
                    transform="translate(-513 -165)"
                >
                    <g transform="translate(513 165)">
                        <path d="M6.17023256 8.065L6.17023256 1.77635684e-15 4.63534884 1.77635684e-15 4.63534884 8.065 1.08465116 4.595 -2.90826794e-14 5.655 5.4027907 10.935 10.8055814 5.655 9.72093023 4.595z"></path>
                        <path d="M0.287 12.5H10.520000000000001V14H0.287z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import React from "react";

export function EmptySearch(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="105"
            height="100"
            viewBox="0 0 105 100"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-587 -510)">
                    <g transform="translate(315 510)">
                        <g transform="translate(273)">
                            <g stroke="#539EFF" transform="translate(0 14.804)">
                                <path
                                    strokeWidth="2"
                                    d="M84.196 29.607c0 16.353-13.256 29.608-29.606 29.608-16.353 0-29.609-13.255-29.609-29.608C24.981 13.255 38.237 0 54.59 0c16.35 0 29.606 13.255 29.606 29.607h0z"
                                ></path>
                                <path
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M55.514 5.551c-9.206 0-17.205 5.172-21.249 12.77m-1.903 4.732a24.075 24.075 0 00-.903 6.554"
                                ></path>
                                <path
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                    d="M34.234 50.888l-5.552 5.551"
                                ></path>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M0 77.8344347L25.0961169 52.7383178 31.4579439 59.1001448 6.36182701 84.1962617z"
                                ></path>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M0 77.8344347L25.0961169 52.7383178 31.4579439 59.1001448 6.36182701 84.1962617z"
                                ></path>
                            </g>
                            <path
                                fill="#DFDFDF"
                                fillRule="nonzero"
                                d="M30.284 5.199c.225.188.43.4.612.633.182-.233.388-.445.613-.633-.225-.188-.43-.4-.613-.633a4.193 4.193 0 01-.612.633zM31.82 1.85c0 1.352 1.006 2.424 2.214 2.424 1.234 0 1.234 1.85 0 1.85-1.208 0-2.214 1.072-2.214 2.423 0 1.234-1.85 1.234-1.85 0 0-1.35-1.005-2.423-2.214-2.423-1.234 0-1.234-1.85 0-1.85 1.209 0 2.214-1.072 2.214-2.424 0-1.233 1.85-1.233 1.85 0z"
                            ></path>
                            <path
                                fill="#BDBDBD"
                                fillRule="nonzero"
                                d="M98.751 55.161c.225.188.43.4.613.634.182-.233.387-.446.612-.634-.225-.188-.43-.4-.612-.633a4.193 4.193 0 01-.613.633zm1.538-3.348c0 1.351 1.005 2.423 2.214 2.423 1.233 0 1.233 1.85 0 1.85-1.21 0-2.214 1.073-2.214 2.424 0 1.233-1.85 1.233-1.85 0 0-1.351-1.006-2.423-2.215-2.423-1.233 0-1.233-1.85 0-1.85 1.21 0 2.214-1.073 2.214-2.424 0-1.234 1.85-1.234 1.85 0z"
                            ></path>
                            <path
                                fill="#BDBDBD"
                                fillRule="nonzero"
                                d="M15.15 19.975l.049.05.049-.05a3.263 3.263 0 01-.05-.05 3.263 3.263 0 01-.048.05zm.974-2.396c0 .825.605 1.47 1.32 1.47 1.234 0 1.234 1.85 0 1.85-.715 0-1.32.646-1.32 1.47 0 1.234-1.85 1.234-1.85 0 0-.824-.605-1.47-1.32-1.47-1.234 0-1.234-1.85 0-1.85.715 0 1.32-.645 1.32-1.47 0-1.233 1.85-1.233 1.85 0z"
                            ></path>
                            <path
                                fill="#DFDFDF"
                                fillRule="nonzero"
                                d="M95.645 35.704l.05.05.048-.05a3.263 3.263 0 01-.049-.05 3.264 3.264 0 01-.049.05zm.974-2.396c0 .825.605 1.47 1.32 1.47 1.234 0 1.234 1.85 0 1.85-.715 0-1.32.646-1.32 1.47 0 1.234-1.85 1.234-1.85 0 0-.824-.605-1.47-1.32-1.47-1.234 0-1.234-1.85 0-1.85.715 0 1.32-.645 1.32-1.47 0-1.233 1.85-1.233 1.85 0z"
                            ></path>
                            <path
                                fill="#BDBDBD"
                                fillRule="nonzero"
                                d="M82.692 3.32l.049.05.049-.05a3.263 3.263 0 01-.05-.05 3.263 3.263 0 01-.048.05zm.974-2.395c0 .825.605 1.47 1.32 1.47 1.234 0 1.234 1.85 0 1.85-.715 0-1.32.646-1.32 1.47 0 1.234-1.85 1.234-1.85 0 0-.824-.605-1.47-1.32-1.47-1.234 0-1.234-1.85 0-1.85.715 0 1.32-.645 1.32-1.47 0-1.233 1.85-1.233 1.85 0z"
                            ></path>
                            <path
                                fill="#BDBDBD"
                                fillRule="nonzero"
                                d="M47.863 83.844c.225.188.43.4.613.633.182-.233.387-.445.612-.633-.225-.188-.43-.4-.612-.634a4.193 4.193 0 01-.613.634zm1.538-3.349c0 1.351 1.005 2.423 2.214 2.423 1.234 0 1.234 1.85 0 1.85-1.209 0-2.214 1.073-2.214 2.424 0 1.234-1.85 1.234-1.85 0 0-1.351-1.006-2.423-2.215-2.423-1.233 0-1.233-1.85 0-1.85 1.21 0 2.214-1.073 2.214-2.424 0-1.233 1.85-1.233 1.85 0z"
                                transform="matrix(1 0 0 -1 0 167.687)"
                            ></path>
                            <path
                                fill="#DFDFDF"
                                fillRule="nonzero"
                                d="M15.15 56.059l.049.05.049-.05a3.263 3.263 0 01-.05-.05 3.264 3.264 0 01-.048.05zm.974-2.395c0 .824.605 1.47 1.32 1.47 1.234 0 1.234 1.85 0 1.85-.715 0-1.32.645-1.32 1.47 0 1.233-1.85 1.233-1.85 0 0-.825-.605-1.47-1.32-1.47-1.234 0-1.234-1.85 0-1.85.715 0 1.32-.646 1.32-1.47 0-1.234 1.85-1.234 1.85 0z"
                                transform="matrix(1 0 0 -1 0 112.117)"
                            ></path>
                            <path
                                fill="#DFDFDF"
                                fillRule="nonzero"
                                d="M75.29 80.115l.049.05.049-.05a3.263 3.263 0 01-.049-.05 3.264 3.264 0 01-.049.05zm.974-2.395c0 .824.605 1.47 1.32 1.47 1.234 0 1.234 1.85 0 1.85-.715 0-1.32.645-1.32 1.47 0 1.234-1.85 1.234-1.85 0 0-.825-.605-1.47-1.32-1.47-1.234 0-1.234-1.85 0-1.85.715 0 1.32-.646 1.32-1.47 0-1.234 1.85-1.234 1.85 0z"
                                transform="matrix(1 0 0 -1 0 160.23)"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

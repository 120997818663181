import classnames from "classnames";
import * as React from "react";

// // // //

export function SearchIcon() {
    return (
        <span className="tw-inline-flex tw-flex-col">
            <span
                className={classnames(
                    "tw-h-[14px] tw-w-[14px]", // Size
                    "tw-border tw-border-solid tw-border-medium-grey tw-rounded-full", // Border
                    "tw-inline-block" // Display
                )}
            />
            <span
                className={classnames(
                    "tw-self-end", // Flex
                    "tw-bg-medium-grey", // Background
                    "tw-h-[8px] tw-w-px", // Size
                    "tw-inline-block", // Display
                    "tw-mt-[-2px]" // Margin
                )}
                style={{ transform: "rotateZ(-35deg)" }}
            />
        </span>
    );
}

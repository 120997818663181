import { spendNode } from "../spend";
import { BreadcrumbNode } from "../types";
import { purchaseOrdersState } from "./state";

// // // //

export const purchaseOrdersNode: BreadcrumbNode = {
    getState: purchaseOrdersState,
    parent: spendNode,
};

import { routes, vendorUsageAppIndividual } from "@src/routes";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbEvents } from "../analytics";

// // // //

export const vendorUsageAppIndState = (route: RouteState) => {
    const vendorID = parseInt(route.pathParams.vendorID, 10);
    const { instanceID, sourceApplicationID } = route.pathParams;
    return {
        linkProps: {
            href: {
                pathname: routes.vendorUsageAppIndividual,
                query: {
                    vendorID: String(vendorID),
                    instanceID,
                    sourceApplicationID,
                },
            },
            as: vendorUsageAppIndividual({
                vendorID,
                instanceID,
                sourceApplicationID,
            }),
        },
        analytics: {
            eventName: BreadcrumbEvents.clickBreadcrumb,
            eventProps: {
                breadcrumb: "vendorUsageAppInd",
                vendorID,
                instanceID,
                sourceApplicationID,
            },
        },
        enabled: true,
        active: route.nextPathname === routes.vendorUsageAppIndividual,
        resolverProps: {
            type: DisplayResolverTypes.appInfo,
            params: { instanceID, sourceAppID: sourceApplicationID },
        },
    };
};

import { ColorLogo } from "@src/shared_modules/logo";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

interface FrameProps {
    children: React.ReactNode;
    showLogo?: boolean;
}

/**
 * Frame
 * Renders a frame with SailPoint logo around its children.
 * It also defines default styles for h1, h2, and p elements it contains.
 */
export function Frame(props: FrameProps) {
    return (
        <div className="tw-w-full tw-min-h-full">
            <div className={styles.frame}>
                {props.showLogo == null || props.showLogo ? (
                    <div className="tw-mb-10">
                        <ColorLogo />
                    </div>
                ) : null}
                <div className="tw-bg-white tw-rounded-xl border-grey-border tw-shadow-lg pt-50-px pb-50-px px-30-px">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

import { trackSuggestedFiltersDropdownOpen } from "@src/analytics/advanced_filters";
import {
    trackClickDropdownEdit,
    trackClickDropdownNew,
} from "@src/analytics/org_saved_views";
import {
    Filters,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { SuggestedFilter } from "@src/modules/advanced_filters/components/advanced_filters/suggested_filters";
import { SuggestedFilterLink } from "@src/modules/advanced_filters/components/advanced_filters/suggested_filters";
import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import { RouteState, withRouter } from "@src/shared_modules/router";
import classnames from "classnames";
import * as React from "react";
import dropdownStyles from "@src/shared_modules/dropdown_select/styles.module.css";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import { EditOrgSavedViewModal } from "./EditOrgSavedViewModal";
import { findOrgSavedView } from "./findOrgSavedView";
import { NewOrgSavedViewModal } from "./NewOrgSavedViewModal";
import { OrgSavedViewLink } from "./OrgSavedViewLink";
import styles from "./styles.module.css";
import { OrgSavedView, OrgSavedViewsApi } from "./types";

// // // //

/**
 * DropdownToggleButton
 * Renders the <button> to open/close the OrgSavedViewDropdown
 * @param props.appliedOrgSavedView - the OrgSavedView currently applied, or null
 */
export function DropdownToggleButton(props: {
    appliedOrgSavedView: OrgSavedView | null;
}) {
    const { appliedOrgSavedView } = props;
    const isAppliedViewActive: boolean = appliedOrgSavedView !== null;

    return (
        <button
            className={classnames(
                "text-updated-black whitespace-nowrap",
                dropdownStyles.dropdownButton,
                dropdownStyles.dropdownButtonSm,
                styles.dropdownToggleButton
            )}
            onClick={trackSuggestedFiltersDropdownOpen}
        >
            {/* Render inactive state */}
            {!isAppliedViewActive && (
                <p
                    className={classnames(
                        "text-grey-mid d-flex justify-content-center",
                        styles.dropdownToggleButtonContent,
                        styles.dropdownToggleButtonContentInactive
                    )}
                >
                    Saved Views + Suggested Filters
                </p>
            )}

            {/* Renders active state */}
            {isAppliedViewActive && (
                <p
                    className={classnames(
                        "text-grey-mid",
                        styles.dropdownToggleButtonContent
                    )}
                >
                    Active View:
                    <span className="text-primary pl-5-px">
                        {appliedOrgSavedView.name}
                    </span>
                </p>
            )}
        </button>
    );
}

// // // //

/**
 * OrgSavedViewDropdownProps
 * @param filters - See AdvancedFiltersProvider.props.children
 * @param filtersInput - Currently applied FiltersInput
 * @param orgSavedViews - Array of OrgSavedView instances
 * @param suggestedFilters - Array of SuggestedFilter instances
 * @param orgSavedViewsApi - Interface from OrgSavedViewsApiProvider
 */
interface OrgSavedViewDropdownProps {
    filters: Filters;
    filtersInput: FiltersInput;
    orgSavedViews: OrgSavedView[];
    suggestedFilters: SuggestedFilter[];
    orgSavedViewsApi: OrgSavedViewsApi;
}

// Defines base CSS for <DropdownItem />
const itemClassname = classnames(
    "w-100 text-updated-black whitespace-nowrap",
    dropdownStyles.dropdownButton,
    dropdownStyles.dropdownButtonSm,
    styles.dropdownButton
);

/**
 * OrgSavedViewDropdown
 * Renders a Dropdown of SuggestedFilterLink + OrgSavedViewLink components
 * @param props - see OrgSavedViewDropdownProps
 */
export function OrgSavedViewDropdownLayout(
    props: OrgSavedViewDropdownProps & { route: RouteState }
) {
    const {
        suggestedFilters,
        orgSavedViews,
        route,
        filters,
        filtersInput,
        orgSavedViewsApi,
    } = props;

    // Store state to show/hide the NewOrgSavedViewModal
    const [
        selectedSavedView,
        setSelectedSavedView,
    ] = React.useState<OrgSavedView | null>(null);

    // Store state to show/hide the NewOrgSavedViewModal
    const [showNewModal, setShowNewModal] = React.useState<boolean>(false);

    // Defines flags indicating whether any orgSavedViews or suggestedFilters are defined
    const hasOrgSavedViews: boolean = orgSavedViews.length > 0;
    const hasSuggestedFilters: boolean = suggestedFilters.length > 0;

    // Defines flag indicating whether or not filtersInput has any user-defined filters
    const userFiltersPresent: boolean =
        filtersInput.defaults.length > 0 || filtersInput.advanced.length > 0;

    // Finds the appliedOrgSavedView
    const appliedOrgSavedView: OrgSavedView | null = findOrgSavedView({
        routeParams: route.params,
        orgSavedViews,
    });

    // Builds items for <Dropdown /> component
    let children: Array<React.ReactComponentElement<
        typeof DropdownListItem
    >> = [];

    if (appliedOrgSavedView === null && userFiltersPresent) {
        // Only render "Save Current View" button when appliedOrgSavedView is null
        children = [
            // Render "Save Current View" dropdown item
            <DropdownListItem
                key="org-saved-views-new"
                active={false}
                className={
                    "w-100 text-updated-black whitespace-nowrap px-15-px pt-10-px pb-5-px"
                }
            >
                <button
                    className={classnames(
                        styles.saveOrgSavedViewButton,
                        "font-secondary-bold font-size-14-px bg-white w-100 outline-none px-0 pb-10-px d-flex align-items-center justify-content-center",
                        {
                            [styles.saveOrgSavedViewButtonBorder]:
                                hasSuggestedFilters || hasOrgSavedViews,
                        }
                    )}
                    onClick={() => {
                        // Tracks the click event
                        trackClickDropdownNew();

                        // Shows the NewOrgSavedViewModal
                        setShowNewModal(true);
                    }}
                >
                    <i className="fa fa-plus mr-10-px" />
                    Save Current View
                </button>
            </DropdownListItem>,
        ];
    }

    // Append orgSavedViewsDropdownItems to children IFF orgSavedViews.length > 0
    if (hasOrgSavedViews) {
        // Defines OrgSavedViews dropdown items
        const orgSavedViewsDropdownItems = [
            <DropdownListItem
                key="org-saved-views-header"
                active={false}
                className="w-100 text-updated-black whitespace-nowrap px-15-px py-10-px"
            >
                <p className="font-secondary-bold">Your Saved Views</p>
            </DropdownListItem>,

            ...orgSavedViews.map((osv) => {
                return (
                    <DropdownListItem
                        key={`org-saved-view-link-${osv.id}`}
                        active={false}
                        className={itemClassname}
                        searchText={osv.name}
                    >
                        <div className="d-flex flex-row justify-content-between align-items-start">
                            <OrgSavedViewLink
                                orgSavedView={osv}
                                className={classnames(
                                    styles.dropdownLink,
                                    "flex-grow-1"
                                )}
                            >
                                <div className="d-flex flex-column text-updated-black py-5-px px-5-px">
                                    <p className="font-size-14-px text-primary font-secondary">
                                        {osv.name}
                                    </p>
                                    <p className="font-size-12-px text-updated-black line-height-19-px font-secondary">
                                        {osv.description}
                                    </p>
                                </div>
                            </OrgSavedViewLink>
                            <button
                                className="btn-sm btn-link"
                                onClick={() => {
                                    // Tracks the click event
                                    trackClickDropdownEdit();

                                    // Selects the OrgSavedView
                                    setSelectedSavedView(osv);
                                }}
                            >
                                <i className="fa fa-fw fa-pencil" />
                            </button>
                        </div>
                    </DropdownListItem>
                );
            }),
        ];

        children = [...children, ...orgSavedViewsDropdownItems];
    }

    // Append suggestedFiltersDropdownListItems to children IFF suggestedFilters.length > 0
    if (hasSuggestedFilters) {
        // Define suggestedFiltersDropdownListItems
        const suggestedFiltersDropdownListItems: Array<React.ReactComponentElement<
            typeof DropdownListItem
        >> = [
            // Render SuggestedFilters header + items
            <DropdownListItem
                key="suggested-filters-header"
                active={false}
                className={classnames(
                    "w-100 text-updated-black whitespace-nowrap px-15-px",
                    {
                        "py-10-px": !hasOrgSavedViews,
                        "pb-10-px pt-15-px": hasOrgSavedViews,
                        [styles.suggestedFiltersHeader]: hasOrgSavedViews,
                    }
                )}
            >
                <p className="font-secondary-bold">Suggested Filters</p>
            </DropdownListItem>,
            ...suggestedFilters.map((sf) => {
                return (
                    <DropdownListItem
                        key={`integration-sf-link-${sf.id}`}
                        active={false}
                        className={itemClassname}
                        searchText={sf.content.name}
                    >
                        <SuggestedFilterLink
                            suggestedFilter={sf}
                            className={styles.dropdownLink}
                        >
                            <div className="d-flex flex-column py-5-px px-5-px">
                                <p className="font-size-14-px text-primary font-secondary">
                                    {sf.content.name}
                                </p>
                                <p className="font-size-12-px text-updated-black line-height-19-px font-secondary">
                                    {sf.content.description}
                                </p>
                            </div>
                        </SuggestedFilterLink>
                    </DropdownListItem>
                );
            }),
        ];

        children = [...children, ...suggestedFiltersDropdownListItems];
    }

    // Define empty state item if there are no dropdown items (no OrgSavedViews, no SuggestedFilters, and Save button)
    if (children.length === 0) {
        children = [
            <DropdownListItem
                key="dropdown-empty-state"
                active={false}
                className="w-100 text-updated-black whitespace-nowrap px-15-px text-center py-20-px"
            >
                <FeatureContent uniqueKey="org-saved-views-dropdown-empty-state">
                    {(content) => {
                        // Render null when loading
                        if (content.loading) {
                            return null;
                        }

                        // Render empty state content
                        return (
                            <React.Fragment>
                                <p className="font-secondary text-grey-mid font-size-14-px">
                                    {content.feature.header}
                                </p>
                                <p className="font-secondary text-grey-mid font-size-12-px mt-10-px">
                                    {content.feature.description}
                                </p>
                            </React.Fragment>
                        );
                    }}
                </FeatureContent>
            </DropdownListItem>,
        ];
    }

    // Render Dropdown + NewOrgSavedViewModal + EditOrgSavedViewModal
    return (
        <React.Fragment>
            <Dropdown
                dropdownListWrapperClassName={
                    styles.dropdownListWrapperClassName
                }
            >
                {/* Render <DropdownToggleButton /> */}
                <DropdownToggleButton
                    appliedOrgSavedView={appliedOrgSavedView}
                />

                {/* Render <DropdownListItem /> */}
                {children}
            </Dropdown>

            {/* Render the EditOrgSavedViewModal */}
            {selectedSavedView && (
                <EditOrgSavedViewModal
                    filters={filters}
                    orgSavedView={selectedSavedView}
                    filtersInput={filtersInput}
                    disabled={
                        orgSavedViewsApi.saveLoading ||
                        orgSavedViewsApi.deleteLoading
                    }
                    orgSavedViewsApi={orgSavedViewsApi}
                    onClose={() => {
                        setSelectedSavedView(null);
                    }}
                />
            )}

            {/* Render NewOrgSavedViewModal */}
            {showNewModal && (
                <NewOrgSavedViewModal
                    filters={filters}
                    filtersInput={filtersInput}
                    disabled={orgSavedViewsApi.saveLoading}
                    orgSavedViewsApi={orgSavedViewsApi}
                    onClose={() => {
                        setShowNewModal(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

// // // //

export const OrgSavedViewDropdown: React.ComponentType<OrgSavedViewDropdownProps> = withRouter(
    OrgSavedViewDropdownLayout
);

import { datadogRum } from "@datadog/browser-rum";
import { webConfig } from "@src/config";
import { DatadogCaptureAdditionalProps } from "./types";

// // // //

// Capture warning via Datadog Custom Actions
export function captureMessage(
    message: string,
    additionalProps?: DatadogCaptureAdditionalProps
): void {
    const {
        datadogRumServiceName = "",
        datadogRumClientToken = "",
        datadogRumAppID = "",
    } = webConfig;

    // Short-circuit if SSR, or the requisite environment variables aren't found
    // (i.e. working in local dev environment)
    // Instead we just log out the message
    if (
        typeof window === "undefined" ||
        !datadogRumAppID.length ||
        !datadogRumClientToken.length ||
        !datadogRumServiceName.length
    ) {
        // eslint-disable-next-line no-console
        console.log(message, additionalProps);
        return;
    }

    // Pull the contextual data out of additionalProps.extra
    // NOTE - the nested "extra" object is a vestige of this function originally being written to send data to Sentry.
    // Rather than updating all 80+ locations this "extra" key/value is used,
    // we'll keep the API the same and remove that extra layer of nesting here
    const actionContext = additionalProps?.extra || {};

    // Use addAction to send a custom Datadog RUM user event
    // https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/?tab=npm
    datadogRum.addAction(message, {
        ...actionContext,
    });
}

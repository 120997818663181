import { Dropdown, DropdownListItem } from "@src/shared_modules/dropdown";
import dropdownStyles from "@src/shared_modules/dropdown_select/styles.module.css";
import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * ActionsDropdownProps
 * @param props.children
 *      A function that accepts a `buttonClassName` (used to style the children)
 *      and returns an array of actions used to populate the buttons inside the dropdown
 */
interface ActionsDropdownProps {
    children: (childProps: { buttonClassName: string }) => React.ReactNode[];
}

// Defines className for Dropdown buttons
const dropdownButtonClassName = classnames(
    "text-updated-black",
    dropdownStyles.dropdownButton,
    dropdownStyles.dropdownButtonSm
);

/**
 * ActionsDropdown
 * Defines a dropdown that can be used on with the AdvancedFilters for actions like CSV Export + Manage Columns
 * @param props - see ActionsDropdownProps
 */
export function ActionsDropdown(props: ActionsDropdownProps) {
    // Invoke props.children with buttonClassName value
    const actions: React.ReactNode[] = props.children({
        buttonClassName: classnames(
            "d-block w-100 py-10-px tw-whitespace-nowrap",
            dropdownButtonClassName,
            styles.dropdownLink
        ),
    });

    // Filter out null values in props.children
    const filteredActions: React.ReactNode[] = actions.filter((ch: any) => {
        return (ch as React.ReactNode) && ch !== null;
    });

    // Return null if filteredActions.length is 0
    if (filteredActions.length === 0) {
        return null;
    }

    // Render the <Dropdown/> component with custom styles
    return (
        <Dropdown
            caretClassName="d-none"
            className={styles.actionDropdownWrapper}
            activeWrapperClassName="pr-0-px cursor-pointer bg-white border-grey-border"
            dropdownButtonOpenClassName={styles.dropdownButtonOpenClassName}
            dropdownListWrapperClassName={classnames(
                styles.dropdownListWrapperClassName,
                "tw-shadow-lg"
            )}
        >
            <button className={dropdownButtonClassName}>
                <i className="fa fa-fw fa-lg fa-ellipsis-h" />
            </button>
            {filteredActions.map((child, i) => {
                return (
                    <DropdownListItem
                        key={`dropdown-action-${i}`}
                        active={false}
                    >
                        {child}
                    </DropdownListItem>
                );
            })}
        </Dropdown>
    );
}

import { routes } from "@src/routes";
import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbState } from "../types";

// // // //

export const spendIndPaymentsState = (route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: route.nextPathname === routes.spendIndividual,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Payments" },
        },
    };
};

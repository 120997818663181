import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbState } from "../types";

// // // //

export const teamIndState = (_route: RouteState): BreadcrumbState => {
    return {
        enabled: true,
        active: true,
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label: "Teams" },
        },
    };
};

import classnames from "classnames";
import React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * filterListWhereBadge
 * Renders the dark-grey "Where" badge placed above each FilterList instance in the `AdvancedFilterEditor` component
 */
export function FilterListWhereBadge() {
    return (
        <div
            className={classnames(
                "bg-grey-mid px-5-px py-5-px ml-15-px rounded-sm",
                styles.filterListWhereBadge
            )}
        >
            <p className="text-white font-size-12-px line-height-17-px font-secondary-heavy">
                Where
            </p>
        </div>
    );
}

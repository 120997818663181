import {
    AdvancedFilterInput,
    FiltersInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { buildUrlWithParams, UrlParamsValue } from "@src/shared_modules/router";
import cloneDeep from "lodash.clonedeep";
import { APPLIED_ORG_SAVED_VIEW_PARAM } from "../org_saved_views/constants";
import { FORCE_REQUERY_PARAM, SHOW_OVERLAY_PARAM } from "./constants";
import { FiltersInputParam } from "./types";

// // // //

/**
 * getUrlFromFiltersInput
 * Creates a URL-friendly representation of the FiltersInput state
 * NOTE - all `FiltersInpu` state management (i.e. defaults, advanced, pagination, etc.)
 * occurs on the `filtersInput` parameter before it's passed into this function
 *
 * @param props.fullUrl
 * @param props.filtersInput
 */
export function getURLWithFiltersInput(props: {
    location: string;
    params: UrlParamsValue;
    filtersInput: FiltersInput;
}): string {
    const { location, params, filtersInput } = props;

    // Defines the updated FiltersInput object, ensures there are
    // no empty `AdvancedFilterInput` objects included in the state
    const updatedFiltersInput: FiltersInput = {
        ...filtersInput,
        advanced: filtersInput.advanced.filter(
            (afi: AdvancedFilterInput) => afi.filters.length > 0
        ),
    };

    // Makes a fresh copy of the params object (we don't want to edit the `RouteState` directly)
    const updatedParams: UrlParamsValue = cloneDeep(params);

    // Remove the previous (now out-of-date) FiltersInput state from the URL params object
    delete updatedParams.filters;

    // Removes FORCE_REQUERY_PARAM
    // This is done to ensure that if we force a re-query of a component that depends on
    // the `FiltersInput` state from the AdvancedFiltersProvider, any subsequent
    // queries will NOT include the FORCE_REQUERY_PARAM. If we didn't remove this param,
    // any component that checks RouteState.params[FORCE_REQUERY_PARAM] would
    // re-query for data on every route change, regardless of what's in the Apollo cahce.
    delete updatedParams[FORCE_REQUERY_PARAM];

    // Removes SHOW_OVERLAY_PARAM
    // This is done to ensure that applying FiltersInput closes the AdvancedFiltersEditorOverlay component
    delete updatedParams[SHOW_OVERLAY_PARAM];

    // Removes APPLIED_ORG_SAVED_VIEW_PARAM
    // This is done so the UI knows the filters we're looking at are independent
    // of the previously applied 'OrgSavedView'
    delete updatedParams[APPLIED_ORG_SAVED_VIEW_PARAM];

    // Sanitizes organizationID out of FiltersInput to create url-friendly version
    const sanitizedFiltersInput: FiltersInputParam = {
        defaults: updatedFiltersInput.defaults,
        advanced: updatedFiltersInput.advanced,
        pagination: updatedFiltersInput.pagination,
    };

    // Updates params.filters using the sanitizedFiltersInput
    updatedParams.filters = JSON.stringify(sanitizedFiltersInput);

    // Returns the complete URL with encoded params
    return buildUrlWithParams({ location, params: updatedParams });
}

import gql from "graphql-tag";
import { Metadata } from "./Metadata";
import { Price } from "./Price";
import { PropertyMap } from "./PropertyMap";

// // // //

/**
 * PurchaseOrder contains line item data for a particular purchase order
 */
export interface PurchaseOrder {
    id: string;
    orgID: number;
    source: string;
    instance: string;
    createdAt: string;
    updatedAt: string;
    internalId: string;
    currency: string;
    sourceCreatedAt: null | string;
    createdBy: string;
    paymentMethod: null | string;
    poNumber: string;
    requester: string;
    price: null | Price;
    vendor: {
        id: number;
        name: string;
        logo: string;
        domain: string;
        __typename: "Vendor";
    };
    vendorID: number;
    status: string; // TODO - Make this an enum later when we have more info
    transmission_status: null | string;
    metadata: null | Metadata[];
    additionalData: null | PropertyMap;
    __typename: "PurchaseOrder";
}

export const PURCHASE_ORDER_FRAGMENT = gql`
    fragment PURCHASE_ORDER_FRAGMENT on PurchaseOrder {
        id
        orgID
        source
        instance
        createdAt
        updatedAt
        internalId
        currency
        sourceCreatedAt
        createdBy
        paymentMethod
        poNumber
        requester
        price {
            raw
            formatted
        }
        vendorID
        status
        transmission_status
        metadata {
            fieldName
            val
        }
        additionalData
        vendor {
            id
            name
            logo
            domain
        }
    }
`;

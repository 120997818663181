import { supportEmail } from "@src/util/consts";
import { FullscreenModal } from "@src/shared_modules/fullscreen_modal";
import { Anchor } from "@src/shared_modules/router";
import * as React from "react";
import { connect } from "react-redux";
import { LogoutButton } from "../logout";
import { UserState } from "../user";

// // // //

interface State {
    user: UserState;
}

interface Props {
    children: React.ReactNode;
    allowedStates: string[];
}

// These are single properties from user reducer
interface InternalUserProps {
    userLoading: boolean;
    userState: string;
}

function mapStateToProps({ user }: State): InternalUserProps {
    return {
        userLoading: user.loading,
        userState: user.state,
    };
}

export function UnauthorizedMessage() {
    return (
        <React.Fragment>
            <h2 className="text-navy font-secondary-bold pb-20-px font-size-18-px line-height-24-px tw-text-center">
                Uh oh, looks like you don&#39;t have access to the SailPoint
                SaaS Management dashboard.
            </h2>

            <h2 className="text-navy font-secondary-bold pb-20-px font-size-16-px line-height-24-px tw-text-center">
                If you should have access, please contact your internal
                SailPoint SaaS Management admin or email us at{" "}
                <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>
            </h2>
        </React.Fragment>
    );
}

// UnauthorizedModal is a modal that we render whenever a user is not authorized to access the page.
export class UnauthorizedModal extends React.Component<
    Props & InternalUserProps
> {
    public render() {
        if (
            !this.props.userLoading &&
            !this.props.allowedStates.includes(this.props.userState)
        ) {
            return (
                <FullscreenModal>
                    <UnauthorizedMessage />
                    <LogoutButton />
                </FullscreenModal>
            );
        }

        return this.props.children;
    }
}

export const UnauthorizedModalCC: React.ComponentType<Props> = connect(
    mapStateToProps
)(UnauthorizedModal);

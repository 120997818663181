import {
    withToaster,
    WithToasterProps,
} from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { ApolloClient } from "apollo-client";
import get from "lodash.get";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
    CREATE_ORG_FIELD_MUTATION,
    CreateOrgFieldMutationRequest,
    CreateOrgFieldMutationResponse,
} from "./graphql";
import { OrganizationField, OrgFieldUpsertInput } from "./types";

// // // //

// Defines success message for create action
const CREATE_SUCCESS_MESSAGE = "Successfully created organization field";

/**
 * CreateOrgFieldMutationProps
 * Props for CreateOrgFieldMutation
 */
interface CreateOrgFieldMutationProps {
    children: (childProps: {
        loading: boolean;
        client: ApolloClient<Object>;
        createOrgField: (
            orgFieldToCreate: OrgFieldUpsertInput
        ) => Promise<OrganizationField | null>;
    }) => React.ReactNode;
}

/**
 * CreateOrgFieldMutationLayout
 * Creates a new org field
 * @param props - see CreateOrgFieldMutationProps
 */
export function CreateOrgFieldMutationLayout(
    props: CreateOrgFieldMutationProps & WithToasterProps
) {
    return (
        <Mutation<CreateOrgFieldMutationResponse, CreateOrgFieldMutationRequest>
            mutation={CREATE_ORG_FIELD_MUTATION}
            onError={() => null}
        >
            {(commitMutation, { loading, error, client }) => {
                // Defines createOrgField function
                function createOrgField(
                    orgFieldToCreate: OrgFieldUpsertInput
                ): Promise<OrganizationField | null> {
                    return new Promise((resolve) => {
                        return commitMutation({
                            variables: {
                                input: {
                                    ...orgFieldToCreate,
                                },
                            },
                        }).then(
                            (response: {
                                data:
                                    | undefined
                                    | CreateOrgFieldMutationResponse;
                            }) => {
                                // Pulls OrganizationField from response
                                const organizationField: null | OrganizationField = get(
                                    response,
                                    "data.insertOrgField",
                                    null
                                );

                                // Displays success toaster if organizationField isn't null
                                if (organizationField !== null) {
                                    props.addToaster({
                                        type: "success",
                                        text: CREATE_SUCCESS_MESSAGE,
                                    });
                                }

                                // Resolves promise with OrganizationField | null
                                resolve(organizationField);
                            }
                        );
                    });
                }

                return (
                    <React.Fragment>
                        {/* Handles error state */}
                        {error && <GraphQlErrorHandler error={error} />}

                        {/* Renders props.children + passes createOrgField function */}
                        {props.children({
                            loading,
                            client,
                            createOrgField,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// // // //

export const CreateOrgFieldMutation: React.ComponentType<CreateOrgFieldMutationProps> = withToaster(
    CreateOrgFieldMutationLayout
);

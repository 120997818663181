import React from "react";

export function SailpointColorCoinSmall(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 16 16"
            version="1.1"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <path d="M9.2 0.4L11 11.6 0.2 11.6z" fill="#0033A1"></path>
            <path d="M9.2 0.4L15.8 11.6 11 11.6z" fill="#CC27B0"></path>
            <path d="M0.2 11.6L11 11.6 11.7 15.6z" fill="#0071CE"></path>
            <path d="M11 11.6L15.8 11.6 11.7 15.6z" fill="#E17FD2"></path>
        </svg>
    );
}

import { PAGINATION_FRAGMENT } from "@src/schema/Pagination";
import { AppUserReportResultBucketList } from "@src/schema/AppUserReportResultBucketList";
import { APP_USER_REPORT_RESULT_BUCKET_FRAGMENT } from "@src/schema/AppUserReportResultBucket";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { gql } from "graphql-tag";

// // // //

/**
 * Query for UserReportResultBucket data
 */
export const APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY = gql`
    query APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY(
        $filters: FiltersInput!
    ) {
        usage {
            appUserReportResultBuckets {
                list(filters: $filters) {
                    results {
                        ...APP_USER_REPORT_RESULT_BUCKET_FRAGMENT
                    }
                    pagination {
                        ...PAGINATION_FRAGMENT
                    }
                }
            }
        }
    }
    ${APP_USER_REPORT_RESULT_BUCKET_FRAGMENT}
    ${PAGINATION_FRAGMENT}
`;

/**
 * Response interface for APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY
 */
export interface AppUserReportResultBucketListFetcherResponse {
    usage: {
        appUserReportResultBuckets: {
            list: AppUserReportResultBucketList;
            __typename: "AppUserReportResultBuckets";
        };
        __typename: "Usage";
    };
}

/**
 * Variables interface for APP_USER_REPORT_RESULT_BUCKET_LIST_FETCHER_QUERY
 */
export interface AppUserReportResultBucketListFetcherVariables {
    filters: FiltersInput;
}

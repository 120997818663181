import {
    Filter,
    FilterExpression,
    PaginationInput,
} from "@src/modules/advanced_filters/components/advanced_filter_editor/graphql";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import { ColumnSortDirections } from "@src/schema/Column";

// // // //

export const VENDOR_FIELD_NAME: string = "vendor";
export const RANK_FIELD_NAME: string = "rank";
export const DATE_RANGE_FIELD_NAME: string = "date_range";
export const INTEGRATION_NAME_COLUMN_KEY: string = "integrationName";
export const APP_NAME_COLUMN_KEY: string = "applicationName";
export const RANK_COLUMN_KEY: string = "rank";
export const SPEND_LAST_365_COLUMN_KEY: string = "spendLast365";
export const TOTAL_USERS_COLUMN_KEY: string = "totalLiveUsers";
export const UTILIZATION_COLUMN_KEY: string = "utilization";

// Const for default totalLiveUsers sort on AppUsageSummaryTable
const TOTAL_LIVE_USERS_COLUMN_KEY: string = "totalLiveUsers";

export const DEFAULT_DATE_RANGE_FILTER: Filter = {
    fieldName: DATE_RANGE_FIELD_NAME,
    expression: FilterExpression.eq,
    val: "thirty_day_users",
    filters: [],
};

export const DEFAULT_APP_USAGE_SUMMARY_PAGINATION: PaginationInput = {
    ...DEFAULT_PAGINATION,
    sort: TOTAL_LIVE_USERS_COLUMN_KEY,
    direction: ColumnSortDirections.desc,
};

// Map of custom column widths by column key
export const MODIFIED_COLUMN_WIDTHS_MAP: { [key: string]: number } = {
    [APP_NAME_COLUMN_KEY]: 300,
    [VENDOR_FIELD_NAME]: 175,
    [RANK_COLUMN_KEY]: 175,
    [INTEGRATION_NAME_COLUMN_KEY]: 300,
    [SPEND_LAST_365_COLUMN_KEY]: 175,
};

import { BreadcrumbNode } from "../types";
import { purchaseOrderDetailsState } from "./state";
import { purchaseOrdersNode } from "../purchaseOrders";

// // // //

export const purchaseOrderDetailsNode: BreadcrumbNode = {
    getState: purchaseOrderDetailsState,
    parent: purchaseOrdersNode,
};

import classnames from "classnames";
import * as React from "react";
import { InputSize } from "./types";
import { useDebouncedInput } from "./useDebouncedInputHook";

// // // //

/**
 * TextInputProps
 * `value` - the value modeled by the `<input />` element
 * `type` - the type of value modeled by the `<input />` element (defaults to "text")
 * `placeholder` - (optional) value for by the `<input />`'s placeholder attribute
 * `className` - (optional) additional className to attach to the `<input />` element
 * `disabled` - (optional) dictates whether or not the `<input />` element is disabled
 * `required` - (optional) dictates whether or not the `<input />` element is required
 * `size` - (optional) prop to dictate the sizing CSS of the `<input />` element. Defaults to `InputSize.md`
 * `debounceTimeoutMs` - (optional) dictates the amount of time (in ms) to debounce user input.
 * `onChange` - function invoked when a change event is detected on the `<input />` element. Accepts `string` parameter values only.
 * `focusOnRender` - (optional) force the user's cursor into the <input /> on render
 * `onKeyDown` - (optional) callback fired on `keyDown` event
 * `onFocus` - (optional) callback fired on `focus` event
 * `onBlur` - (optional) callback fired on `blur` event
 */
export interface TextInputProps {
    value: string;
    type?: "text" | "email" | "password";
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    required?: boolean;
    size?: InputSize;
    debounceTimeoutMs?: number;
    focusOnRender?: boolean;
    onChange: (updatedVal: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<any>) => void;
    onFocus?: (e: React.FocusEvent<any>) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
}

/**
 * TextInput
 * Renders a single-line <input /> for editing string values
 * @param props - see `TextInputProps`
 */
export function TextInput(props: TextInputProps) {
    // Track the current inputValue internally to this component
    const [inputValue, setInputValue] = React.useState<string>(props.value);

    // Refines refs for <input /> component
    const textInputRef = React.useRef(null);

    // Focus input on render, if props.focusOnRender
    React.useEffect(() => {
        if (!props.focusOnRender) {
            return;
        }

        // Short-circuit if textInput is null (hasn't been rendered yet)
        if (textInputRef === null) {
            return;
        }

        // Focus the textInput
        textInputRef.current.focus();
    }, [props.value]);

    // Re-sets the internally used `inputValue` in the event that `props.value` changes
    // This prevents an occurance where `inputValue` is not updated to the latest value passed as `props.value`
    // when this input's value is being controlled by its parent component
    React.useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    // Defines a function to invoke props.onChange with the useDebouncedInput hook
    const setValueFunction = useDebouncedInput<string>({
        debounceTimeoutMs: props.debounceTimeoutMs,
        onChange: props.onChange,
    });

    // Pulls `size` prop, defaults to `md`
    // Pulls `type` prop, defaults to `text`
    const { size = InputSize.md, className = "", type = "text" } = props;

    return (
        <input
            type={type}
            ref={textInputRef}
            placeholder={props.placeholder}
            value={inputValue}
            disabled={props.disabled}
            required={props.required}
            className={classnames("form-input", "font-secondary", {
                ["form-input-sm"]: size === InputSize.sm,
                ["form-input-md"]: size === InputSize.md,
                ["form-input-lg"]: size === InputSize.lg,
                [className]: className !== "",
            })}
            onKeyDown={props.onKeyDown}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
                const updatedValue: string = e.currentTarget.value;
                setInputValue(updatedValue);
                setValueFunction(updatedValue);
            }}
        />
    );
}

import { captureMessage } from "../util/analytics";
import { CoreEvents } from "./events";
import { track } from "./track";
import { EventProps } from "./uncommons_track";

// // // //

export interface LinkProps {
    href: string | { pathname: string; query: {} };
    componentName?: string;
}

export function linkProps(
    href: string | { pathname: string; query: {} },
    componentName?: string
): LinkProps {
    const props: LinkProps = {
        href,
    };
    if (componentName != null) {
        props.componentName = componentName;
    }

    return props;
}

export function trackBaseLinkClicked(
    href: string | { pathname: string; query: {} },
    componentName: string
) {
    const props: LinkProps = linkProps(href, componentName);
    track(CoreEvents.linkClicked, props);
}

export function trackLinkClicked(
    eventName: string,
    href: string | { pathname: string; query: {} },
    componentName?: string,
    passedProps: EventProps = {}
) {
    if (eventName.length === 0) {
        captureMessage("Tried to pass an empty event name");
        return;
    }

    let eventProps = passedProps || {};
    if (eventProps == null) {
        eventProps = {};
    }

    const props: LinkProps & EventProps = {
        ...linkProps(href, componentName),
        ...eventProps,
    };

    track(eventName, props);
}

import {
    appFieldInd,
    appSpendChartInd,
    appSpendInd,
    connectedAppsIndividualApplications,
    purchaseOrderVendorListing,
    roleAssignmentsIndividual,
    rolesIndividual,
    routes,
    teamsInd,
    usageInd,
} from "@src/routes";

import { IndividualPageType } from "./types";

// // // //

// Define shape of link props to be returned
interface LinkProps {
    pathname: string;
    as: string;
}

// Define shape of of value in pageToLinkCCMap
type PageTypeMapValue = (vendorID: number) => LinkProps;

// Ensure every key in IndividualPageType enum is included in pageToLinkCCMap
type PageTypeMap = { [key in IndividualPageType]: PageTypeMapValue };

// Hash map where key is page type and value is link CC props
const pageToLinkCCMap: PageTypeMap = {
    [IndividualPageType.info]: (vendorID) => ({
        as: appFieldInd(vendorID),
        pathname: routes.appFieldIndividual,
    }),
    [IndividualPageType.spend]: (vendorID) => ({
        as: appSpendInd(vendorID),
        pathname: routes.spendIndividual,
    }),
    [IndividualPageType.spendChart]: (vendorID) => ({
        as: appSpendChartInd(vendorID),
        pathname: routes.spendIndividualChart,
    }),
    [IndividualPageType.usage]: (vendorID) => ({
        as: usageInd(vendorID),
        pathname: routes.usageIndividual,
    }),
    [IndividualPageType.teamUsage]: (vendorID) => ({
        as: teamsInd(vendorID),
        pathname: routes.teamIndividual,
    }),
    [IndividualPageType.roles]: (vendorID) => ({
        as: rolesIndividual(vendorID),
        pathname: routes.rolesIndividual,
    }),
    [IndividualPageType.roleAssignments]: (vendorID) => ({
        as: roleAssignmentsIndividual(vendorID),
        pathname: routes.roleAssignmentsIndividual,
    }),
    [IndividualPageType.connectedAppsApplications]: (vendorID) => ({
        as: connectedAppsIndividualApplications(vendorID),
        pathname: routes.connectedAppsIndividualApplications,
    }),
    // NOTE - Because the ConnectedAppsUsers page now requires a `connectedAppID` path param,
    // this case now just redirects to the `connectedAppsApplications` page instead
    [IndividualPageType.connectedAppsUsers]: (vendorID) => ({
        as: connectedAppsIndividualApplications(vendorID),
        pathname: routes.connectedAppsIndividualApplications,
    }),
    [IndividualPageType.purchaseOrders]: (vendorID) => ({
        as: purchaseOrderVendorListing(vendorID),
        pathname: routes.purchaseOrderVendorListing,
    }),
};

/**
 * getIndividualPageLinkProps
 * Returns props for LinkCC a specific VendorID + IndividualPage combination
 * @param props.vendorID - the Vendor whose individual page the user is being directed
 * @param props.individualPage - the IndividualPageType that we're linking to
 */
export function getIndividualPageLinkProps(props: {
    vendorID: number;
    individualPage: keyof PageTypeMap;
}): LinkProps {
    const { vendorID, individualPage } = props;
    return pageToLinkCCMap[individualPage](vendorID);
}

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initRedirectAuth } from "./actions";
import { UserState } from "./reducer";
import { UserLoading } from "./UserLoading";

// // // //

// Props are the props to render
type Props = {
    children: React.ReactNode;
} & UserState;

// DispatchProps is the dispatch props methods
interface DispatchProps {
    initRedirectAuth: () => void;
}

// State is the state of the map to props
interface State {
    user: UserState;
}

// RedirectAuthDashboard is a component that handles loading a user and redirecting the user to the dashboard if they're logged in
class RedirectAuthDashboard extends React.Component<Props & DispatchProps, {}> {
    public render() {
        if (this.props.loading || this.props.authenticated) {
            return (
                <div>
                    <UserLoading />
                </div>
            );
        }

        return this.props.children;
    }
    public componentDidMount() {
        this.props.initRedirectAuth();
    }
}

function mapStateToProps({ user }: State): UserState {
    return {
        ...user,
    };
}

function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators({ initRedirectAuth }, dispatch);
}

export const RedirectAuthDashboardCC = connect(
    mapStateToProps,
    mapDispatchToProps
)(RedirectAuthDashboard);

import classnames from "classnames";
import * as React from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { ColumnListItemCheckbox } from "./ColumnListItemCheckbox";
import { ColumnListItemDragHandle } from "./ColumnListItemDragHandle";
import styles from "./styles.module.css";
import { Column, ColumnSetting } from "./types";

// // // //

/**
 * ColumnListItemProps
 * @param column - The `Column` instance associated with props.columnSetting
 * @param columnSetting - The `ColumnSetting` being edited here
 * @param disableReorder - Dictates whether or not the `ColumnListItemDraghandle` is rendered
 * @param disableToggle - Dictates whether or not the `ColumnListItemCheckbox` is rendered
 * @param onChange - callback invoked when toggling ColumnSetting.visible
 * @param provided - See `react-beautiful-dnd` docs
 * @param snapshot - See `react-beautiful-dnd` docs
 */
interface ColumnListItemProps {
    column: Column;
    columnSetting: ColumnSetting;
    disableReorder: boolean;
    disableToggle: boolean;
    onChange: (updatedColumnSetting: ColumnSetting) => void;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}

/**
 * ColumnListItem
 * @param props - see ColumnListItemProps
 */
export function ColumnListItem(props: ColumnListItemProps) {
    const { column, columnSetting, disableReorder, disableToggle } = props;

    const itemClassName = classnames({
        "p-10-px d-flex flex-row align-items-center bg-white": true,
        "cursor-pointer": !disableToggle,
        [styles.columnListItem]: true,
        [styles.columnListItemDragging]: props.snapshot.isDragging,
    });

    return (
        <li
            ref={props.provided.innerRef}
            className={itemClassName}
            {...props.provided.draggableProps}
            role="button"
            onClick={() => {
                if (disableToggle) {
                    return;
                }
                props.onChange({
                    ...columnSetting,
                    visible: !columnSetting.visible,
                });
            }}
        >
            <div className="d-flex flex-column px-5-px">
                <ColumnListItemDragHandle
                    disableReorder={disableReorder || columnSetting.stickyLeft}
                    dragHandleProps={props.provided.dragHandleProps}
                />
            </div>

            <div className="d-flex flex-column px-10-px">
                <ColumnListItemCheckbox
                    disabled={disableToggle}
                    columnSetting={columnSetting}
                />
            </div>

            <div className="d-flex flex-column">
                <p
                    className={
                        "text-updated-black font-size-14-px font-secondary-heavy"
                    }
                >
                    {column.content.name}
                </p>
                <p
                    className={
                        "text-grey-mid font-size-10-px font-secondary mt-5-px"
                    }
                >
                    {column.content.description}
                </p>
            </div>
        </li>
    );
}

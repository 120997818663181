import React from "react";

export function UsageRankSSO(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="11"
            viewBox="0 0 24 11"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="currentColor"
                    fillRule="nonzero"
                    transform="translate(-559 -504)"
                >
                    <path d="M561.776 514.08c2.38 0 3.5-1.526 3.5-3.136 0-1.638-1.372-2.534-2.072-2.954-.84-.504-1.148-.77-1.148-1.26 0-.42.42-.714.896-.714.742 0 1.47.546 1.568.63.098.084.336.07.448-.07l.882-1.078c.112-.126.056-.364-.084-.476-.378-.308-1.33-1.022-2.66-1.022-2.268 0-3.318 1.442-3.318 2.954 0 1.456 1.204 2.338 2.086 2.926.602.392.91.77.924 1.274.014.462-.378.854-.896.854-.756 0-1.442-.476-1.624-.616-.098-.07-.322-.098-.448.084l-.756 1.134c-.084.126-.112.252 0 .392.266.336 1.218 1.078 2.702 1.078zm7.21 0c2.38 0 3.5-1.526 3.5-3.136 0-1.638-1.372-2.534-2.072-2.954-.84-.504-1.148-.77-1.148-1.26 0-.42.42-.714.896-.714.742 0 1.47.546 1.568.63.098.084.336.07.448-.07l.882-1.078c.112-.126.056-.364-.084-.476-.378-.308-1.33-1.022-2.66-1.022-2.268 0-3.318 1.442-3.318 2.954 0 1.456 1.204 2.338 2.086 2.926.602.392.91.77.924 1.274.014.462-.378.854-.896.854-.756 0-1.442-.476-1.624-.616-.098-.07-.322-.098-.448.084l-.756 1.134c-.084.126-.112.252 0 .392.266.336 1.218 1.078 2.702 1.078zm8.708 0c2.94 0 5.264-2.548 5.264-5.698 0-2.422-1.736-4.382-3.906-4.382-2.912 0-5.264 2.576-5.264 5.74 0 2.408 1.75 4.34 3.906 4.34zm.28-2.24c-1.106 0-1.89-1.092-1.89-2.394 0-1.736 1.316-3.206 2.674-3.206 1.12 0 1.904 1.092 1.904 2.408 0 1.736-1.302 3.192-2.688 3.192z"></path>
                </g>
            </g>
        </svg>
    );
}

import { useToaster } from "@src/shared_modules/global_toaster";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { useUserState } from "@src/shared_modules/user";
import React from "react";
import { Mutation } from "react-apollo";

import {
    SyncStatusRecipientsUpdaterVariables,
    SyncStatusRecipientsUpdaterResponse,
    UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS,
} from "./graphql";

// // // //

interface Props {
    children: (childProps: {
        /**
         * loading indicates whether the mutation is currently executing
         */
        loading: boolean;

        /**
         * updateSyncStatusRecipients is provided to children to invoke the update mutation
         */
        updateSyncStatusRecipients: (input: {
            recipients: string[];
        }) => Promise<SyncStatusRecipientsUpdaterResponse>;
    }) => React.ReactNode;
}

// Defines success messages for update action
const SUCCESS_MESSAGE = "Updated integration sync status recipients";

/**
 * SyncStatusRecipientsUpdater
 * Updates sync status recipient emails
 */
export function SyncStatusRecipientsUpdater(props: Props) {
    const user = useUserState();
    const addToaster = useToaster();
    const { children } = props;

    return (
        <Mutation<
            SyncStatusRecipientsUpdaterResponse,
            SyncStatusRecipientsUpdaterVariables
        >
            mutation={UPDATE_INTEGRATION_SYNC_STATUS_RECIPIENTS}
            onError={() => null}
        >
            {(commitMutation, { loading, error }) => {
                function updateSyncStatusRecipients(input: {
                    recipients: string[];
                }): Promise<SyncStatusRecipientsUpdaterResponse> {
                    return new Promise((resolve) => {
                        return commitMutation({
                            variables: {
                                input: {
                                    organizationID: user.organizationID,
                                    ...input,
                                },
                            },
                        }).then(
                            (response: {
                                data: SyncStatusRecipientsUpdaterResponse;
                            }) => {
                                const resp = response?.data;

                                // Display a toast saying that the entity updated successfuly
                                if (resp !== undefined) {
                                    addToaster({
                                        type: "success",
                                        text: SUCCESS_MESSAGE,
                                    });
                                }

                                resolve(resp);
                            }
                        );
                    });
                }

                // Renders children + error handler
                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}

                        {children({
                            loading,
                            updateSyncStatusRecipients,
                        })}
                    </React.Fragment>
                );
            }}
        </Mutation>
    );
}

// Route definitions for Next.js app
import { linkFromUrlQs } from "@src/util/route";

export interface PublicRoutes {
    login: "/login";
    register: "/register";
    forgot_password: "/forgot-password";
    home: "https://www.intello.io";
    scheduleDemo: "https://www.intello.io/schedule-demo";
    loginSSO: "/login/sso";
    reset_password: "/reset-password";
    status: "/status";
}

export const publicRoutes: PublicRoutes = {
    login: "/login",
    register: "/register",
    forgot_password: "/forgot-password",
    home: "https://www.intello.io",
    scheduleDemo: "https://www.intello.io/schedule-demo",
    loginSSO: "/login/sso",
    reset_password: "/reset-password",
    status: "/status",
};

export interface Routes {
    usageIndividual: "/usage/[vendorID]";
    usageIndividualColumnSettings: "/applications/[vendorID]/usage/settings/manage-columns";
    usageSuggestedFilters: "/usage/suggested-filters/[slug]";
    spendIndividual: "/applications/[vendorID]/spend";
    spendIndividualChart: "/applications/[vendorID]/spend/chart";
    appFieldIndividual: "/applications/[vendorID]/fields";
    teamIndividual: "/applications/[vendorID]/usage/teams";
    onboarding: "/onboarding";
    overview: "/overview";
    appListing: "/applications";
    paymentListing: "/payments";
    purchaseOrderListing: "/purchase_orders";
    purchaseOrderVendorListing: "/applications/[vendorID]/spend/purchase_orders";
    purchaseOrderDetails: "/purchase_orders/[purchaseOrderID]";
    purchaseOrderVendorDetails: "/applications/[vendorID]/spend/purchase_orders/[purchaseOrderID]";
    users: "/users";
    userIndividualUsage: "/users/[userID]/usage";
    userIndividualSpend: "/users/[userID]/spend";
    userIndividualSpendChart: "/users/[userID]/spend/chart";
    usage: "/usage";
    usageApplications: "/usage/applications";
    usageSavedViews: "/usage/saved-views";
    vendorListing: "/vendors";
    vendorListingV2: "/vendors/v2";
    vendorListingManageColumns: "/vendors/manage-columns";
    vendorListingManageFilters: "/vendors/manage-filters";
    integrations: "/integrations";
    integrationIndividualDash: "/integrations/[service]";
    integrationIndividualAuth: "/integrations/[service]/authenticate";
    integrationIndividualSuccess: "/integrations/[service]/success";
    manageOrganizationFields: "/settings/fields";
    teams: "/teams";
    settings: {
        customFields: "/settings/fields";
        newOrgField: "/settings/fields/edit";
        editOrgField: "/settings/fields/edit/[orgFieldID]";
        fieldUpload: "/settings/fields/upload";
        users: "/settings/users";
        paymentUploads: "/settings/payment-uploads";
        teams: "/settings/teams";
        newTeam: "/settings/teams/edit";
        editTeam: "/settings/teams/edit/[teamID]";
        saml: "/settings/saml";
        set_team_department: "/settings/set-team-department";
        demo: "/settings/demo";
        accessControlReconciliation: "/settings/access-control/reconciliation";
        accessControlVendorRolesUploadsListing: "/settings/access-control/vendor-roles-uploads";
        accessControlVendorRolesUploadsNew: "/settings/access-control/vendor-roles-uploads/new";
        auditLog: "/settings/security/audit-log";
        webhooks: "/settings/webhooks";
        webhookNew: "/settings/webhooks/new";
        webhookIndividual: "/settings/webhooks/[webhookID]";
        apiKeys: "/settings/api-keys";
    };
    globalRoles: "/access-control/roles";
    globalRoleAssignments: "/access-control/role-assignments";
    rolesIndividual: "/applications/[vendorID]/access-control/roles";
    roleAssignmentsIndividual: "/applications/[vendorID]/access-control/role-assignments";
    workflowList: "/workflows";
    workflowNew: "/workflows/new";
    workflowEdit: "/workflows/[workflowID]/edit";
    workflowDestinations: "/workflows/destinations";
    suggestedWorkflows: "/workflows/suggested";
    workflowIntegrationSuccess: "/workflows/integrations/success/[service]";
    vendorUsageAppListing: "/applications/[vendorID]/usage/apps";
    vendorUsageAppIndividual: "/applications/[vendorID]/usage/apps/[instanceID]/[sourceApplicationID]";
    vendorUsageAppIndividualTimeseries: "/applications/[vendorID]/usage/apps/[instanceID]/[sourceApplicationID]/timeseries";
    // // // //
    // Connected Apps
    connectedAppsListing: "/connected-apps";
    connectedAppsIndividualApplications: "/applications/[vendorID]/connected-apps";
    connectedAppsIndividualUsers: "/applications/[vendorID]/connected-apps/[connectedAppID]";
    // // // //
    // Terminated Users
    terminatedUsersReportList: "/reports/terminated-users";
    terminatedUsersReportNew: "/reports/terminated-users/new";
    terminatedUsersReportIndividual: "/reports/terminated-users/[reportID]";
    terminatedUsersReportIndividualConfigure: "/reports/terminated-users/[reportID]/configure";
    terminatedUsersReportBucketIndividual: "/reports/terminated-users/[reportID]/[appInfoID]";
}

// IntegrationTypeParams is the name of the integration param we use on the integration listing to filter items
export enum IntegrationTypeParams {
    name = "data_type", // NOTE - this is the name of the parameter in the URL
    spend = "spend",
    usage = "usage",
    secure = "secure",
    sso = "sso",
    casb = "casb",
    mine = "my-integrations",
}

// Temporary Integration Routes (Pending Integrations Page Rebuild)
export const integrationsSpend: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.spend}`;
export const integrationsUsage: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.usage}`;
export const integrationsSecure: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.secure}`;
export const integrationsSSO: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.sso}`;
export const integrationsCASB: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.casb}`;
export const integrationsMine: string = `/integrations?${IntegrationTypeParams.name}=${IntegrationTypeParams.mine}`;

export const routes: Routes = {
    usageIndividual: "/usage/[vendorID]",
    usageIndividualColumnSettings:
        "/applications/[vendorID]/usage/settings/manage-columns",
    usageSuggestedFilters: "/usage/suggested-filters/[slug]",
    spendIndividual: "/applications/[vendorID]/spend",
    spendIndividualChart: "/applications/[vendorID]/spend/chart",
    appFieldIndividual: "/applications/[vendorID]/fields",
    teamIndividual: "/applications/[vendorID]/usage/teams",
    onboarding: "/onboarding",
    overview: "/overview",
    appListing: "/applications",
    paymentListing: "/payments",
    users: "/users",
    userIndividualUsage: "/users/[userID]/usage",
    userIndividualSpend: "/users/[userID]/spend",
    userIndividualSpendChart: "/users/[userID]/spend/chart",
    usage: "/usage",
    usageApplications: "/usage/applications",
    usageSavedViews: "/usage/saved-views",
    vendorListing: "/vendors",
    vendorListingV2: "/vendors/v2",
    vendorListingManageColumns: "/vendors/manage-columns",
    vendorListingManageFilters: "/vendors/manage-filters",
    integrations: "/integrations",
    integrationIndividualDash: "/integrations/[service]",
    integrationIndividualAuth: "/integrations/[service]/authenticate",
    integrationIndividualSuccess: "/integrations/[service]/success",
    manageOrganizationFields: "/settings/fields",
    teams: "/teams",
    settings: {
        customFields: "/settings/fields",
        fieldUpload: "/settings/fields/upload",
        newOrgField: "/settings/fields/edit",
        editOrgField: "/settings/fields/edit/[orgFieldID]",
        users: "/settings/users",
        paymentUploads: "/settings/payment-uploads",
        teams: "/settings/teams",
        newTeam: "/settings/teams/edit",
        editTeam: "/settings/teams/edit/[teamID]",
        saml: "/settings/saml",
        set_team_department: "/settings/set-team-department",
        demo: "/settings/demo",
        accessControlReconciliation: "/settings/access-control/reconciliation",
        accessControlVendorRolesUploadsListing:
            "/settings/access-control/vendor-roles-uploads",
        accessControlVendorRolesUploadsNew:
            "/settings/access-control/vendor-roles-uploads/new",
        auditLog: "/settings/security/audit-log",
        webhooks: "/settings/webhooks",
        webhookNew: "/settings/webhooks/new",
        webhookIndividual: "/settings/webhooks/[webhookID]",
        apiKeys: "/settings/api-keys",
    },
    globalRoles: "/access-control/roles",
    globalRoleAssignments: "/access-control/role-assignments",
    rolesIndividual: "/applications/[vendorID]/access-control/roles",
    roleAssignmentsIndividual:
        "/applications/[vendorID]/access-control/role-assignments",
    workflowList: "/workflows",
    workflowNew: "/workflows/new",
    workflowEdit: "/workflows/[workflowID]/edit",
    workflowDestinations: "/workflows/destinations",
    suggestedWorkflows: "/workflows/suggested",
    workflowIntegrationSuccess: "/workflows/integrations/success/[service]",
    purchaseOrderListing: "/purchase_orders",
    purchaseOrderVendorListing:
        "/applications/[vendorID]/spend/purchase_orders",
    purchaseOrderDetails: "/purchase_orders/[purchaseOrderID]",
    purchaseOrderVendorDetails:
        "/applications/[vendorID]/spend/purchase_orders/[purchaseOrderID]",
    vendorUsageAppListing: "/applications/[vendorID]/usage/apps",
    vendorUsageAppIndividual:
        "/applications/[vendorID]/usage/apps/[instanceID]/[sourceApplicationID]",
    vendorUsageAppIndividualTimeseries:
        "/applications/[vendorID]/usage/apps/[instanceID]/[sourceApplicationID]/timeseries",
    // // // //
    // Connected Apps Routes
    connectedAppsListing: "/connected-apps",
    connectedAppsIndividualApplications:
        "/applications/[vendorID]/connected-apps",
    connectedAppsIndividualUsers:
        "/applications/[vendorID]/connected-apps/[connectedAppID]",

    // // // //
    // Terminated Users
    terminatedUsersReportList: "/reports/terminated-users",
    terminatedUsersReportNew: "/reports/terminated-users/new",
    terminatedUsersReportIndividual: "/reports/terminated-users/[reportID]",
    terminatedUsersReportIndividualConfigure:
        "/reports/terminated-users/[reportID]/configure",
    terminatedUsersReportBucketIndividual:
        "/reports/terminated-users/[reportID]/[appInfoID]",
};

export function appendRef(url: string, ref?: string): string {
    if (ref == null) {
        return url;
    }
    const refQs = `ref=${encodeURIComponent(ref)}`;
    return linkFromUrlQs(url, refQs);
}

export function loginRedirect(): string {
    return routes.overview;
}

// authRedirect returns the route to redirec the user to the auth page
export function authRedirect(): string {
    return publicRoutes.login;
}

export function appListing(ref?: string): string {
    return appendRef(routes.appListing, ref);
}

// appSpendInd generates the application spend individual route
export function appSpendInd(vendorID: number, ref?: string): string {
    return appendRef(`${routes.appListing}/${vendorID}/spend`, ref);
}

// appSpendChartInd generates route for routes.spendIndividualChart
export function appSpendChartInd(vendorID: number, ref?: string): string {
    return appendRef(`${routes.appListing}/${vendorID}/spend/chart`, ref);
}

export function appIndividual(vendorID: number, ref?: string): string {
    return appSpendInd(vendorID, ref);
}

export function usageInd(vendorID: number, ref?: string): string {
    return appendRef(`${routes.usage}/${vendorID}`, ref);
}

export function usageSuggestedFilters(slug: string, ref?: string) {
    return appendRef(`${routes.usage}/suggested-filters/${slug}`, ref);
}

export function usageOrgSavedViews(tag: string, ref?: string) {
    return appendRef(`${routes.usageSavedViews}?tag=${tag}`, ref);
}

export function teamsInd(vendorID: number, ref?: string): string {
    return appendRef(`${routes.appListing}/${vendorID}/usage/teams`, ref);
}

// appFieldInd generates the route for the individual app page
export function appFieldInd(vendorID: number, ref?: string): string {
    return appendRef(`${routes.appListing}/${vendorID}/fields`, ref);
}

// integrationIndDash returns the integration individual dashboard route
export function integrationIndDash(service: string): string {
    return `${routes.integrations}/${service}`;
}

// integrationAuth returns the integration individual auth route
export function integrationAuth(service: string): string {
    return `${integrationIndDash(service)}/authenticate`;
}

// integrationSuccess is the page that we render when an integreation has successfully loaded
export function integrationSuccess(service: string): string {
    return `${integrationIndDash(service)}/success`;
}

export function editOrganizationField(orgFieldID: number): string {
    return `${routes.settings.customFields}/edit/${orgFieldID}`;
}

export function editTeam(teamID: number): string {
    return `${routes.settings.newTeam}/${teamID}`;
}

export function editWorkflow(workflowID: string): string {
    return `${routes.workflowList}/${workflowID}/edit`;
}

export function rolesIndividual(vendorID: number, ref?: string): string {
    return appendRef(
        `${routes.appListing}/${vendorID}/access-control/roles`,
        ref
    );
}

export function roleAssignmentsIndividual(
    vendorID: number,
    ref?: string
): string {
    return appendRef(
        `${routes.appListing}/${vendorID}/access-control/role-assignments`,
        ref
    );
}

export function userIndividualUsage(userID: string, ref?: string): string {
    return appendRef(`${routes.users}/${userID}/usage`, ref);
}

export function userIndividualSpend(userID: string, ref?: string): string {
    return appendRef(`${routes.users}/${userID}/spend`, ref);
}

export function userIndividualSpendChart(userID: string): string {
    return `${routes.users}/${userID}/spend/chart`;
}

export interface HelpRoutes {
    faqSSO: string;
}

export const helpRoute: HelpRoutes = {
    faqSSO:
        "https://documentation.sailpoint.com/saas-mgmt/help/integrations/sso/okta.html",
};

// manageIndividualColumns handles managing the individual columns to render
export function manageUsageIndividualColumns(
    vendorID: number,
    ref?: string
): string {
    return appendRef(
        `${routes.appListing}/${vendorID}/usage/settings/manage-columns`,
        ref
    );
}

// connectedAppsIndividualApplications generates link to connected app individual page
export function connectedAppsIndividualApplications(
    vendorID: number,
    ref?: string
): string {
    return appendRef(`${routes.appListing}/${vendorID}/connected-apps`, ref);
}

// connectedAppsIndividualUsers generates link to connected app individual user page
export function connectedAppsIndividualUsers(
    vendorID: number,
    connectedAppID: string,
    ref?: string
): string {
    return appendRef(
        `${routes.appListing}/${vendorID}/connected-apps/${connectedAppID}`,
        ref
    );
}

// purchaseOrderVendorListing generates a link to the purchase orders individual page
export function purchaseOrderVendorListing(vendorID: number): string {
    return `${routes.appListing}/${vendorID}/spend/purchase_orders`;
}

// Line items of a purchase order from purchase order listing page
export function purchaseOrderDetails(purchaseOrderID: string): string {
    return `${routes.purchaseOrderListing}/${purchaseOrderID}`;
}

// Line items of a purchase order from purchase order vendor listing page
export function purchaseOrderVendorDetails(
    vendorID: number,
    purchaseOrderID: string
): string {
    return `${purchaseOrderVendorListing(vendorID)}/${purchaseOrderID}`;
}

/**
 * Builds path to Routes.vendorUsageAppListing
 */
export function vendorUsageAppListing(props: { vendorID: number }): string {
    const { vendorID } = props;
    return `/applications/${vendorID}/usage/apps`;
}

/**
 * Builds path to Routes.vendorUsageAppIndividual
 */
export function vendorUsageAppIndividual(props: {
    vendorID: number;
    instanceID: string;
    sourceApplicationID: string;
}): string {
    const { vendorID, instanceID, sourceApplicationID } = props;
    return `/applications/${vendorID}/usage/apps/${instanceID}/${sourceApplicationID}`;
}

/**
 * Builds path to Routes.vendorUsageAppIndividualTimeseries
 */
export function vendorUsageAppIndividualTimeseries(props: {
    vendorID: number;
    instanceID: string;
    sourceApplicationID: string;
}): string {
    const { vendorID, instanceID, sourceApplicationID } = props;
    return `/applications/${vendorID}/usage/apps/${instanceID}/${sourceApplicationID}/timeseries`;
}

export function webhookIndividual(webhookID: string): string {
    return `${routes.settings.webhooks}/${webhookID}`;
}

// Builds path to Routes.terminatedUsersReportIndividual
export function terminatedUsersReportIndividual(reportID: string) {
    return `${routes.terminatedUsersReportList}/${reportID}`;
}

// Builds path to Routes.terminatedUsersReportIndividualConfigure
export function terminatedUsersReportIndividualConfigure(reportID: string) {
    return `${routes.terminatedUsersReportList}/${reportID}/configure`;
}

// Builds path to Routes.terminatedUsersReportBucketIndividual
export function terminatedUsersReportBucketIndividual(
    reportID: string,
    appInfoID: string
) {
    return `${routes.terminatedUsersReportList}/${reportID}/${appInfoID}`;
}

// Defines unauthenticatedRoutes to be rendered without DashboardNav or SettingsNavLayout for users who are NOT authenticated
// NOTE - users who _are_ authenticated will be redirected to `/overview` if they visit any of these pages
export const unauthenticatedRoutes: string[] = [
    publicRoutes.login,
    publicRoutes.loginSSO,
    publicRoutes.register,
    publicRoutes.forgot_password,
    publicRoutes.reset_password,
];

// Defines settingsRoutes to be rendered with the SettingsNavLayout
export const settingsRoutes: string[] = [...Object.values(routes.settings)];

import { DropdownOption } from "@src/shared_modules/dropdown_select";
import { formatMomentISO } from "@src/util/date_util";
import moment from "moment";

// // // //

/**
 * Returns an array of DropdownOptions objects for each sunday in a given year
 * NOTE - we're going to clean this up and test on our second pass
 */
export function getWeekdays(startYear: number): DropdownOption[] {
    // Gets today in UTC
    const today = moment().utc();

    // Defines start + end days of startYear
    let startOfYear = moment()
        .year(startYear)
        .startOf("year")
        .utc();
    let endOfYear = startOfYear
        .clone()
        .endOf("year")
        .utc();

    // Array to accumulate all the DropdownOption objects below
    let dropdownOptions: DropdownOption[] = [];

    // Get first Sunday of startYear
    let tmp = startOfYear.clone().day(0);
    if (tmp.isAfter(startOfYear, "d")) {
        dropdownOptions.push({
            humanValue: tmp.format("MMM D"),
            internalValue: formatMomentISO(tmp),
        });
    }

    // Loop while tmp is both before endOfYear and before today's day
    // This prevents Sundays in the current year from extending beydond the current date
    while (
        tmp
            .clone()
            .add(7, "days")
            .isBefore(endOfYear) &&
        tmp
            .clone()
            .add(7, "days")
            .isBefore(today)
    ) {
        // Add 7 more days to tmp
        tmp.add(7, "days");

        // Format tmp and append to array
        dropdownOptions.push({
            humanValue: tmp.format("MMM DD"),
            internalValue: formatMomentISO(tmp),
        });
    }

    // Returns the array of dropdownOptions
    return dropdownOptions;
}

/**
 * Returns an array of DropdownOptions[] for state.endWeekDropdownOptions
 * when the startYear and endYear are the same - this prevents an
 * endWeek dropdown from allowing the user to select a value that is *before* startWeek
 */
export function getRemainingWeekOptions(props: {
    startYear: number;
    endYear: number;
    startWeek: string;
}): DropdownOption[] {
    const { startYear, endYear, startWeek } = props;
    if (startYear !== endYear) {
        return getWeekdays(endYear);
    }

    // Determine the subset of weekOptions that are later than state.startWeek
    // This is done because if startYear and endYear are the same,
    // then state.endWeekOptions *must* only include values that are *after* the state.startWeek value
    const weeks = getWeekdays(endYear);
    const startWeekIndex: number = weeks.findIndex(
        (w) => w.internalValue === startWeek
    );
    return weeks.splice(startWeekIndex + 1);
}

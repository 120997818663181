import { Transition } from "@headlessui/react";

// // // //

export function FadeInOut(props: {
    show?: boolean;
    duration?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    children: JSX.Element | JSX.Element[];
}) {
    const { show = true, children, duration = 500 } = props;

    return (
        <Transition
            show={show}
            appear={true}
            enter={`tw-transition-opacity tw-ease-in tw-duration-${duration}`}
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave={`tw-transition-opacity tw-ease-in tw-duration-${duration}`}
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
        >
            {children}
        </Transition>
    );
}

import * as React from "react";
import { connect } from "react-redux";
import { UserState } from "./reducer";

interface State {
    user: UserState;
}

/**
 * WithUserProps
 * Props passed to a component that imeplements `withUser`
 */
export interface WithUserProps {
    user: UserState;
}

// withUser injects the current user into a component
export function withUser<P extends any>(
    Component: React.ComponentType<any>
): React.ComponentType<any> {
    function UserComponent(props: State & P) {
        return <Component {...props} />;
    }

    return connect(
        ({ user }: State): State => ({
            user,
        })
        // @ts-ignore
    )(UserComponent);
}

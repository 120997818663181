import React from "react";

export function CursorQuestion(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-400 -895)">
                    <g transform="translate(400 895)">
                        <circle
                            cx="8.4"
                            cy="8.4"
                            r="8.4"
                            fill="#005BC0"
                        ></circle>
                        <path
                            fill="#FFF"
                            fillRule="nonzero"
                            d="M11.679 12a1.604 1.604 0 01-1.575-1.286H6.181A1.605 1.605 0 014.607 12C3.721 12 3 11.28 3 10.393a1.609 1.609 0 012.036-1.548l1.973-3.1a1.602 1.602 0 01-.473-1.138c0-.886.72-1.607 1.607-1.607.886 0 1.607.72 1.607 1.607 0 .43-.171.837-.473 1.138l1.973 3.1a1.609 1.609 0 012.036 1.548c0 .886-.721 1.607-1.607 1.607zm-1.575-1.929a1.6 1.6 0 01.571-.932L8.74 6.1a1.579 1.579 0 01-1.194 0L5.61 9.138a1.6 1.6 0 01.571.932h3.923z"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

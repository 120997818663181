import gql from "graphql-tag";
import { Pagination } from "@src/schema/Pagination";
import { AppInfo } from "./AppInfo";

// // // //

/**
 * AppInfoList represents a list of AppInfo objects w/ pagination
 */
export interface AppInfoList {
    results: AppInfo[];
    pagination: Pagination;
    __typename: "AppInfoList";
}

// export const APP_INFO_LIST_FRAGMENT = gql`
//     fragment APP_INFO_LIST_FRAGMENT on AppInfoList {
//         results {
//             ...APP_INFO_FRAGMENT
//         }
//         pagination {
//             ...PAGINATION_FRAGMENT
//         }
//     }
//     ${PAGINATION_FRAGMENT}
//     ${APP_INFO_FRAGMENT}
// `;

// Non-nested version of above fragment
export const APP_INFO_LIST_FRAGMENT = gql`
    fragment APP_INFO_LIST_FRAGMENT on AppInfoList {
        results {
            id
            sourceApplicationID
            source
            rank
            instanceID
            applicationName
            label
            state
            createdAt
            updatedAt
            vendorID
            userCount
            oauthIntegration {
                name
                id
                isActive
                createdAt
                status
                lastFetch
                tokenStatus {
                    status
                    statusReason
                }
            }
            vendor {
                id
                category
                description
                domain
                gdprLink
                logo
                name
                nameAliases
                pricingLink
                privacyLink
                state
                tldVendor
                tosLink
                verified
            }
        }
        pagination {
            pageCount
            rangeStart
            rangeEnd
            page
            totalItems
            itemsPerPage
            sort
            direction
        }
    }
`;

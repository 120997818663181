import { track } from "@src/analytics/track";

// // // //

enum Events {
    open = "NumberInputInline - Open",
    apply = "NumberInputInline - Apply Button Clicked",
}

/**
 * trackOpen
 * Tracks when the user opens the `NumberInputInline` component
 */
export function trackOpen() {
    track(Events.open, {});
}

/**
 * trackApply
 * Tracks when the user clicks the `ApplyButton`
 */
export function trackApply() {
    track(Events.apply, {});
}

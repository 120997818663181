import * as React from "react";
import { Vendor } from "./graphql";
import { hasRelatedVendors } from "./hasRelatedVendors";

// // // //

/**
 * sortVendors
 * Accepts a Vendor[], copies it, sorts it based on Vendor.name, and returns
 * We copy the array beacuse invoking .sort() on a source array will mutate the original
 * @param vendors - array of vendors to be sorted
 */
export function sortVendors(vendors: Vendor[]): Vendor[] {
    return [...vendors].sort((v1: Vendor, v2: Vendor) =>
        v1.name > v2.name ? 1 : -1
    );
}

/**
 * getSortedVendors
 * Returns an ordered array of Vendors to be rendered in <RelatedVendors />
 * @param vendor - the Vendor whose parent + siblings + children are being sorted
 */
export function getSortedVendors(vendor: Vendor): Vendor[] {
    // Defines references to parent + sibling + child vendors
    const siblingVendors: Vendor[] = vendor.siblings || [];
    const childVendors: Vendor[] = vendor.children || [];

    // Defines array of ordered vendors (vendor + siblings + children)
    // Note that vendor is sorted with siblings
    const sortedVendors: Vendor[] = [
        ...sortVendors([...siblingVendors, vendor]),
        ...sortVendors(childVendors),
    ];

    // Returns the sortedVendors
    return sortedVendors;
}

/**
 * RelatedVendorsProps
 * @property vendor - the Vendor whose related vendors are being rendered
 * @property children - a function invoked once-per-vendor (including parent, current, siblings, children)
 */
interface RelatedVendorsProps {
    vendor: Vendor;
    children: (childProps: {
        vendor: Vendor;
        isParent: boolean;
    }) => React.ReactNode;
}

/**
 * RelatedVendors
 * Accepts various vendors and renders a props.children function in a specific order of:
 * parent -> current vendor + sibling vendors -> child vendors
 * This component _only_ implements the machinery that renders any of the related vendors in the
 * correct order - rendering the `RelatedVendor` child component is done when this is implemented somewhere.
 * This allows us to use the same rendering logic in several different scenarios through the app.
 * @param props - see RelatedVendorsProps
 */
export function RelatedVendors(props: RelatedVendorsProps) {
    const { vendor } = props;

    // Defines flags used to determine if this component should simply return null
    const { hasParent, hasChildren, hasSiblings, isParent } = hasRelatedVendors(
        vendor
    );

    // If there is no parent, no children, and siblings data -> return null
    if (!hasParent && !hasChildren && !hasSiblings) {
        return null;
    }

    // Defines references to parent vendor -> either the parent of props.vendor, or props.vendor itself
    let parentVendor: Vendor | null | undefined = vendor.parent;
    if (isParent) {
        parentVendor = vendor;
    }

    // Defines array of vendors (props.vendor + siblings + children) to be rendered after parentVendor
    const sortedVendors = getSortedVendors(vendor);

    // Invokes props.children for: parent -> curent -> siblings -> children
    return (
        <React.Fragment>
            {/* Render parentVendor */}
            {(hasParent || isParent) &&
                props.children({ vendor: parentVendor, isParent: true })}

            {/* Renders the vendor + siblings + children */}
            {sortedVendors.map((relatedVendor) => {
                // Skip double rendering of parent (since it was rendered above)
                if (isParent && vendor.id === relatedVendor.id) {
                    return null;
                }

                // Renders the vendor + siblings + children
                return (
                    <React.Fragment key={relatedVendor.id}>
                        {props.children({
                            vendor: relatedVendor,
                            isParent: false,
                        })}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}

import classnames from "classnames";
import * as React from "react";
import makeCharacterString from "./makeCharacterString";

// // // //

// Props for the makeCharacterString component
// NOTE - all `*length` props are measured in units of `placeholderCharacter` strings
// `className` - Optional. Additional className string added to the
// `children` - Optional. Renders <ContentLoader /> around an existing component to match the child's width + height
// `length` - Optional. The exact length describing some text decribed by <ContentLoader />
// `minLength` + `maxLength` - Optional. The min/max length of some text of varying size decribed by <ContentLoader />
interface Props {
    className?: string;
    children?: React.ReactNode;
    length?: number;
    minLength?: number;
    maxLength?: number;
}

// placeholderCharacter
// This is the "character" that's repeated n times in makeCharacterString function
// NOTE: this string should be as close to the width of our typeface's `M` character.
// We don't simply use the `M` character because should a user find themselves in
// a sitation where our CSS styles don't load, they would simply see:
//
//   Loading
//   ......................
//
// Instead of:
//
//   Loading
//   MMMMMMMMMMMMMMMMMMMMMM
//
export const placeholderCharacter: string = "..";

// randomIntegerFromInterval
// Returns a "random" integer between min & max
// Used to add some variation to multiple <ContentLoader /> components renders at the same time
//
// i.e. a component with a list of elements, where each element is rendered with a random interval placeholder:
// ------------------
// | My List        |
// | ..........     |
// | ......         |
// | .............. |
// |________________|
export function randomIntegerFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// ContentLoader
// Displays a single light-gray box used to build skeleton loaders and placeholder components
// Renders placehoder elements of fixed length, varying length, or it can used to
// wrap an existing component to create a placeholder of comparable size.
// NOTE: The reason we don't just specify width: using text allows this component to respect the sizing
// CSS of the text it's a placeholder for. If we didn't render any text and just supplied a width,
// we would have to set a height as well - which creates a burden because we have to actively
// adjust our loader when the styles of the element it's mocking changes. This way, changing
// the styles in one location will adjust how the loader is sized as well
export function ContentLoader(props: Props) {
    // Pulls local variables from props w/ defaults
    const {
        className = "",
        children,
        length = 0,
        minLength = 5,
        maxLength = 10,
    } = props;

    // Assembles className
    const loaderClassName = classnames("tw-rounded tw-bg-gray-border", {
        "tw-block": children,
        "tw-table": !children,
        [className]: className !== "",
    });

    // NOTE - `aria-hidden="true"` ensures the this element's content is hidden from screen readers
    return (
        <span className={loaderClassName} aria-hidden="true">
            <span className="tw-invisible">
                {(() => {
                    // Returns props.children
                    if (children) {
                        return children;
                    }

                    // Returns a character string with an exact length
                    if (length > 0) {
                        return makeCharacterString(
                            length,
                            placeholderCharacter
                        );
                    }

                    // Returns a character string with varying length
                    return makeCharacterString(
                        randomIntegerFromInterval(minLength, maxLength),
                        placeholderCharacter
                    );
                })()}
            </span>
        </span>
    );
}

/**
 * Render a ContentLoader compoenent wrapped with loading state + pass-thru value
 * Helpful for rendering placeholder text when fetching strings from Contentful
 * @returns
 */
export function TextLoadingPlaceholder(props: {
    loading: boolean;
    length: number;
    value: null | undefined | string;
    className?: string;
}) {
    const { value, loading, length, className } = props;

    if (loading) {
        return <ContentLoader className={className} length={length} />;
    }

    if (value === null || value === undefined) {
        return null;
    }

    return <React.Fragment>{value}</React.Fragment>;
}

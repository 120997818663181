import React from "react";

export function VendorFamilyWhite(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="#fff" strokeWidth="1">
                <g
                    fill="#fff"
                    fillRule="nonzero"
                    transform="translate(-263 -17)"
                >
                    <path d="M272.01 17.333c.363 0 .657.294.657.657V22h4.083c.322 0 .583.261.583.583v8.167a.583.583 0 01-.583.583h-8.167a.583.583 0 01-.583-.583v-4.083h-4.01a.656.656 0 01-.657-.657v-8.02c0-.363.294-.657.657-.657h8.02zm.657 5.833v2.844a.656.656 0 01-.657.657h-2.844v3.5h7v-7h-3.5z"></path>
                </g>
            </g>
        </svg>
    );
}

import React from "react";

export function ConnectedAppsTilePrimary(props?: {
    className?: string;
    label: string;
}) {
    return (
        <svg
            className={props.className}
            width="17"
            height="22"
            viewBox="0 0 17 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <path
                d="M10.7721 9.60226H15.4418C15.9839 9.60226 16.4842 9.98933 16.651 10.5054C16.8594 11.0645 16.7344 11.6666 16.3174 12.0537L5.64381 21.6873C5.14349 22.0744 4.47639 22.1174 3.97606 21.7303C3.47574 21.3863 3.26727 20.6981 3.51743 20.096L6.72785 12.3547H2.05815C1.51613 12.3547 1.01581 12.0107 0.849031 11.4946C0.640562 10.9355 0.765643 10.3334 1.18258 9.94632L11.8562 0.312697C12.3565 -0.0743685 13.0236 -0.117376 13.5239 0.26969C14.0243 0.613748 14.2327 1.30186 13.9826 1.90397L10.7721 9.60226Z"
                fill="#005BC0"
            />
        </svg>
    );
}

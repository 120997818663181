import {
    trackApplyColumnSettings,
    trackSaveColumnSettings,
} from "@src/analytics/advanced_filters";
import { InputSize, SearchInput } from "@src/shared_modules/input";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * ManageColumnsHeaderProps
 * @param disabled - disables the apply + save buttons
 * @param columnSearchFilter - The current Columns filter
 * @param onSearchFilterChange - Callback to change the current Columns filter
 * @param onClickApply - Callback to apply the current callback settings
 * @param onClickSave - Callback to save the current callback settings
 */
interface ManageColumnsHeaderProps {
    disabled: boolean;
    columnSearchFilter: string;
    onSearchFilterChange: (updatedSearchFilter: string) => void;
    onClickApply: () => void;
    onClickSave: () => void;
}

/**
 * ManageColumnsHeader
 * Renders SearchInput + buttons to apply + save filters
 * @param props - see ManageColumnsHeaderProps
 */
export function ManageColumnsHeader(props: ManageColumnsHeaderProps) {
    const { disabled } = props;
    return (
        <div className={`p-20-px bg-white ${styles.headerBody}`}>
            <div className="row">
                <div className="col-lg-12">
                    <p className="mb-10-px font-secondary-heavy text-updated-black font-size-14-px">
                        Manage Visible Columns
                    </p>
                </div>
                <div className="col-lg-12">
                    <SearchInput
                        disabled={disabled}
                        placeholder="Search"
                        size={InputSize.lg}
                        value={props.columnSearchFilter}
                        onChange={props.onSearchFilterChange}
                    />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center mt-10-px">
                <button
                    disabled={disabled}
                    className="btn btn-md btn-stroked-primary font-secondary w-100 mr-5-px"
                    onClick={() => {
                        trackApplyColumnSettings();
                        props.onClickApply();
                    }}
                >
                    <i className="fa fa-fw fa-check mr-5-px" />
                    Update Table
                </button>
                <button
                    disabled={disabled}
                    className="btn btn-md btn-stroked-primary font-secondary w-100 ml-5-px"
                    onClick={() => {
                        trackSaveColumnSettings();
                        props.onClickSave();
                    }}
                >
                    <i className="fa fa-fw fa-save mr-5-px" />
                    Save As Default
                </button>
            </div>
        </div>
    );
}

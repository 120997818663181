import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

const statusToColor = {
    live: "green",
    unconfirmed: "yellow",
    inactive: "red",
    archive: "navy",
};

// Helper function to map a standard state/status string to its corresponding color
function mapStatusToColor(status) {
    return statusToColor[status.toLowerCase()];
}

// // // //

interface StateDotProps {
    color?: "green" | "yellow" | "red" | "navy" | "grey";
    status?: string;
}

// StateDot
// Helper component for circular state indicators
export function StateDot(props: StateDotProps) {
    let colorClassName = "";

    // Handle props.status && props.color
    if (props.status) {
        colorClassName = mapStatusToColor(props.status);
    } else if (props.color) {
        colorClassName = props.color;
    } else {
        colorClassName = "navy";
    }

    return (
        <span
            className={classnames(
                styles.outerDot,
                styles[`dot-${colorClassName}`]
            )}
        >
            <span className={styles.innerDot} />
        </span>
    );
}

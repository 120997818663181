import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { RouteState } from "@src/shared_modules/router";
import { BreadcrumbState, GetBreadcrumbState } from "../types";

// // // //

// Factory for building access control routes (all of which are non links, meaning they are active)
function buildActiveState(label: string): GetBreadcrumbState {
    return (_route: RouteState): BreadcrumbState => ({
        enabled: true,
        active: true, // Always active because this will not be a link
        resolverProps: {
            type: DisplayResolverTypes.label,
            params: { label },
        },
    });
}

export const accessControlState: GetBreadcrumbState = buildActiveState(
    "Access Control"
);

export const rolesState: GetBreadcrumbState = buildActiveState("Roles");

export const roleAssignmentsState: GetBreadcrumbState = buildActiveState(
    "Role Assignments"
);

export const rolesIndividualState: GetBreadcrumbState = buildActiveState(
    "Roles"
);

export const roleAssignmentsIndividualState: GetBreadcrumbState = buildActiveState(
    "Role Assignments"
);

export const accessControlStaticVendorState: GetBreadcrumbState = (
    route: RouteState
): BreadcrumbState => ({
    enabled: true,
    active: true, // Always active because this will not be a link
    resolverProps: {
        type: DisplayResolverTypes.vendor,
        params: { vendorID: parseInt(route.pathParams.vendorID, 10) },
    },
});

import { Cursor } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import gql from "graphql-tag";

/**
 * Pagination handles seamlessly paginating over items
 * NOTE - this interface does *not* include the Cursor property beacuse we don't actively use it in the application
 */
export interface Pagination {
    pageCount: number;
    rangeStart: number;
    rangeEnd: number;
    page: number;
    totalItems: number;
    itemsPerPage: number;
    sort: string;
    direction: string;
    cursor?: null | Cursor;
    __typename: "Pagination";
}

export const PAGINATION_FRAGMENT = gql`
    fragment PAGINATION_FRAGMENT on Pagination {
        pageCount
        rangeStart
        rangeEnd
        page
        totalItems
        itemsPerPage
        sort
        direction
    }
`;

import { buildDefaultColumnSetting } from "./buildDefaultColumnSettings";
import { Column, ColumnSetting } from "./types";

// // // // //

/**
 * syncColumnSettings
 * Accepts arrays of `Column` instances and `ColumnSetting` instances and syncs the existing `ColumnSettings[]` array with any
 * Column instances that have been added or removed since the last time the `ColumnSetting[]` were updated. Ensures
 * that the `ColumnSetting[]` is always one-to-one with the `Column` instances returned by the API
 * @param props.columns - Array of `Column` instances
 * @param props.columnsSettings - Array of `ColumnSetting` instances
 */
export function syncColumnSettings(props: {
    columns: Column[];
    columnSettings: ColumnSetting[];
}): ColumnSetting[] {
    const { columns, columnSettings } = props;

    // Compares the sets of keys from columns + columnSettings
    // Return props.columnSettings if the sets are identical
    const columnKeySet = new Set(columns.map((c) => c.key));
    const columnSettingsKeySet = new Set(columnSettings.map((cs) => cs.key));
    if (columnKeySet === columnSettingsKeySet) {
        return columnSettings;
    }

    // Clones the original columnSettings
    let syncedColumnSettings = [...columnSettings];

    // Defines syncedColumnSettings from props.columns
    columns.forEach((col: Column) => {
        // Finds the associated `ColumnSetting` in props.columnSettings
        const existingColumnSetting:
            | ColumnSetting
            | undefined = columnSettings.find((c) => c.key === col.key);

        // Returns if existingColumnSetting is defined
        if (existingColumnSetting) {
            return;
        }

        // Otherwise, append a new ColumnSetting instance to syncedColumnSettings
        syncedColumnSettings.push(buildDefaultColumnSetting({ column: col }));
    });

    // Iterate over ColumnSettings and remove any that don't have a corresponding Column
    syncedColumnSettings = syncedColumnSettings.filter((cs) => {
        return columns.find((c) => c.key === cs.key);
    });

    // Returns syncedColumnSettings
    return syncedColumnSettings;
}

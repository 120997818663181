import { webConfig } from "@src/config";
import { request } from "@src/util/request_util";
import { requestForm, submitForm } from "./forms";

export interface SyncStatus {
    syncing: boolean;
}

interface ApiError {
    error: string;
    code: number;
}

// saveAuth - authenticates a user with a given oauth service
export function saveAuth(
    service: string,
    body: {
        [key: string]: string;
    }
): Promise<
    | ApiError
    | {
          success: boolean;
      }
> {
    return requestForm(
        `oauth2callback/connect/${service}`,
        body,
        `service-${service}`,
        "POST",
        webConfig.gwHost
    );
}

// saveIntegrationCreds sends integration credentials to the API
export function saveIntegrationCreds(
    url: string,
    data: { [key: string]: any },
    useJSON: boolean
): Promise<any> {
    const submitFn: (
        url: string,
        body: { [key: string]: any },
        formName: string,
        method: string,
        formHost: string
    ) => Promise<any> = useJSON ? submitForm : requestForm;

    return submitFn(url, data, "integration-creds", "POST", "");
}

// syncStatus checks the sync status for a given service
export function syncStatus(service: string): Promise<SyncStatus> {
    return request(
        `sync-status/${service}`,
        {},
        webConfig.nemoHost
    ).then((response: Response) => response.json());
}

// spendStatus checks the sync status of a spend app
export function spendStatus(service: string): Promise<SyncStatus> {
    return request(
        `sync-status/${service}`,
        {},
        webConfig.doraHost
    ).then((response: Response) => response.json());
}

// savePlaid saves the plaid integration data to our oauth integration
export function savePlaid(publicToken: string) {
    const body = {
        publicAccessToken: publicToken,
    };
    return request(
        "oauth2callback/connect/credit-card",
        {
            method: "POST",
            body: JSON.stringify(body),
        },
        webConfig.gwHost
    ).then((response: Response) => response.json());
}

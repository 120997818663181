import {
    AddToasterAction,
    GlobalToasterAction,
    GlobalToasterActionTypes,
} from "./actions";
import { GlobalToasterProps } from "./component";

// // // //

/**
 * GlobalToasterState
 * Interface describing the state managed by the `globalToaster` reducer
 */
export interface GlobalToasterState {
    toaster: GlobalToasterProps | null;
}

/**
 * defaultState
 * Default state returned by the `globalToaster` reducer
 */
export const defaultState: GlobalToasterState = {
    toaster: null,
};

/**
 * addToaster
 * Reducer function to add a Toaster to state
 */
export function addToaster(action: AddToasterAction): GlobalToasterState {
    return {
        toaster: {
            ...action.toaster,
        },
    };
}

/**
 * removeToaster
 * Reducer function to remove a Toaster from state
 */
export function removeToaster(): GlobalToasterState {
    return {
        toaster: null,
    };
}

/**
 * globalToaster
 * Reducer to manage state inside the WorkflowEditor component
 */
export function globalToaster(
    state: GlobalToasterState = defaultState,
    action: GlobalToasterAction
): GlobalToasterState {
    switch (action.type) {
        case GlobalToasterActionTypes.addToaster:
            return addToaster(action);
        case GlobalToasterActionTypes.removeToaster:
            return removeToaster();
        default:
            return state;
    }
}

import { LabeledToggle } from "@src/shared_modules/toggle/component";
import * as React from "react";
import { PreviewComponentProps } from "../types";

import styles from "../styles.module.css";

// ToggleSwitchPreviewState represents the state of the ToggleSwitchPreview component
interface ToggleSwitchPreviewState {
    checked: boolean;
}

// ToggleSwitchPreview renders the toggle switch preview
export class ToggleSwitchPreview extends React.Component<
    PreviewComponentProps,
    ToggleSwitchPreviewState
> {
    constructor(props: PreviewComponentProps) {
        super(props);
        this.state = {
            checked: false,
        };
    }

    public onChange(checked: boolean) {
        this.setState({ checked });
    }

    public render() {
        if (!this.props.values) {
            return null;
        }

        return (
            <div className={styles.toggleSwitchPreview}>
                <LabeledToggle
                    id="toggle"
                    name="toggle"
                    checked={this.state.checked}
                    onChange={(checked) => this.onChange(checked)}
                    values={[this.props.values[0], this.props.values[1]]}
                />
            </div>
        );
    }
}

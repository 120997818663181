import React from "react";

export function Applications(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="21"
            viewBox="0 0 16 21"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#BDBDBD" transform="translate(-28 -179)">
                    <path d="M40.1541343 179L28 191.154134 35.807914 191.154134 32.8135176 199.74139 44 188.554908 37.0855478 188.554908z"></path>
                </g>
            </g>
        </svg>
    );
}

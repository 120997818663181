import { ModalCloseButton } from "@src/shared_modules/modal";
import { ModalPageCC } from "@src/shared_modules/modal_page";
import * as React from "react";
import { Vendor } from "./graphql";
import styles from "./styles.module.css";

// // // //

/**
 * RelatedVendorsModalProps
 * @param vendor - the Vendor whose related vendors are being rendered
 * @param onClose - callback to close the RelatedVendorsModal
 * @param children - React.ReactNode rendered inside the ModalPageCC
 */
interface RelatedVendorsModalProps {
    vendor: Vendor;
    onClose: () => void;
    children: React.ReactNode;
}

/**
 * RelatedVendorsModal
 * Responsible for rendering the slide-out modal that will display the RelatedVendorsList component
 * @param props - see RelatedVendorsModalProps
 */
export function RelatedVendorsModal(props: RelatedVendorsModalProps) {
    const { vendor } = props;

    // NOTE - we pass `null` into `background` beacuse the "background" page is already rendered
    return (
        <ModalPageCC
            background={null}
            hideCloseButton
            className={styles.modalPage}
            rightDrawer
            onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClose();
            }}
        >
            <div className="d-flex flex-column py-30-px px-30-px">
                <div className="d-flex justify-content-end">
                    <ModalCloseButton
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onClose();
                        }}
                    />
                </div>

                <p className="font-size-28-px text-updated-black font-primary-bold">
                    Vendor Family
                </p>

                <p className="pt-20-px text-updated-black font-secondary">
                    This vendor is part of the {vendor.name} family
                </p>

                <div className="my-30-px">{props.children}</div>
            </div>
        </ModalPageCC>
    );
}

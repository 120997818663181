import { cursor } from "@src/schema/data/Cursor";
import { Pagination } from "../Pagination";

export const empty: Pagination = {
    pageCount: 0,
    rangeStart: 0,
    rangeEnd: 0,
    page: 1,
    totalItems: 0,
    itemsPerPage: 25,
    sort: "",
    direction: "",
    __typename: "Pagination",
};

export const single: Pagination = {
    pageCount: 1,
    rangeStart: 1,
    rangeEnd: 1,
    page: 1,
    totalItems: 1,
    itemsPerPage: 25,
    sort: "",
    direction: "",
    __typename: "Pagination",
};

export const multiple: Pagination = {
    pageCount: 2,
    rangeStart: 1,
    rangeEnd: 2,
    page: 1,
    totalItems: 35,
    itemsPerPage: 25,
    sort: "",
    direction: "",
    __typename: "Pagination",
};

export const withCursor: Pagination = {
    pageCount: 2,
    rangeStart: 1,
    rangeEnd: 2,
    page: 1,
    totalItems: 35,
    itemsPerPage: 25,
    sort: "",
    direction: "",
    cursor: cursor,
    __typename: "Pagination",
};

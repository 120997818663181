import { BreadcrumbNode, GetBreadcrumbState } from "../types";
import {
    settingsAuditLogState,
    settingsCustomFieldsState,
    settingsPaymentUploadsState,
    settingsState,
    settingsTeamsState,
    settingsUsersState,
    settingsSAMLState,
    settingsCustomFieldsNewState,
    settingsCustomFieldsEditState,
    accessControlState,
    accessControlReconciliationState,
    accessControlVendorRoleUploadsState,
    settingsSetTeamDepartmentState,
    accessControlVendorRoleUploadsNewState,
} from "./state";

// // // //

// Static node for /settings
export const settingsNode: BreadcrumbNode = {
    getState: settingsState,
    parent: null,
};

// Builds all of the breadcrumb Nodes that share the same parent node (settingsNode)
function buildSettingsNode(getState: GetBreadcrumbState): BreadcrumbNode {
    return {
        getState,
        parent: settingsNode,
    };
}
// /settings/users
export const settingsUsersNode: BreadcrumbNode = buildSettingsNode(
    settingsUsersState
);
// /settings/payment-uploads
export const settingsPaymentUploadsNode: BreadcrumbNode = buildSettingsNode(
    settingsPaymentUploadsState
);
// /settings/teams
export const settingsTeamsNode: BreadcrumbNode = buildSettingsNode(
    settingsTeamsState
);
// /settings/audit-log
export const settingsAuditLogNode: BreadcrumbNode = buildSettingsNode(
    settingsAuditLogState
);
// /settings/saml
export const settingsSAMLNode: BreadcrumbNode = buildSettingsNode(
    settingsSAMLState
);
// /settings/fields
export const settingsCustomFieldsNode: BreadcrumbNode = buildSettingsNode(
    settingsCustomFieldsState
);
// /settings/fields/new
export const settingsCustomFieldsNewNode: BreadcrumbNode = {
    ...buildSettingsNode(settingsCustomFieldsNewState),
    parent: settingsCustomFieldsNode,
};
// /settings/fields/edit
export const settingsCustomFieldsEditNode: BreadcrumbNode = {
    ...buildSettingsNode(settingsCustomFieldsEditState),
    parent: settingsCustomFieldsNode,
};

// settings/set-team-department
export const settingsSetTeamDepartmentNode: BreadcrumbNode = buildSettingsNode(
    settingsSetTeamDepartmentState
);

// // //
// settings/access-control
// // //

// Static /access-control node
const accessControlNode: BreadcrumbNode = {
    getState: accessControlState,
    parent: settingsNode,
};

// settings/access-control/reconciliation
export const accessControlReconciliationNode: BreadcrumbNode = {
    getState: accessControlReconciliationState,
    parent: accessControlNode,
};

// /settings/access-control/vendor-role-uploads
export const accessControlVendorRoleUploadsNode: BreadcrumbNode = {
    getState: accessControlVendorRoleUploadsState,
    parent: accessControlNode,
};

// /settings/access-control/vendor-role-uploads
export const accessControlVendorRoleUploadsNewNode: BreadcrumbNode = {
    getState: accessControlVendorRoleUploadsNewState,
    parent: accessControlVendorRoleUploadsNode,
};

import { RouteState } from "@src/shared_modules/router";
import { DisplayResolverTypes } from "@src/shared_modules/display_resolver";
import { BreadcrumbState } from "../types";

// // // //

// routes.usage
export const usageState = (_route: RouteState): BreadcrumbState => ({
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Usage" },
    },
});

// routes.usageOverview
export const usageOverviewState = (_route: RouteState): BreadcrumbState => ({
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Overview" },
    },
});

// routes.usageApplications
export const usageApplicationsState = (
    _route: RouteState
): BreadcrumbState => ({
    enabled: true,
    active: true,
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "All Applications" },
    },
});

// routes.usageSuggestedFilters
export const usageSuggestedFiltersState = (
    _route: RouteState
): BreadcrumbState => ({
    enabled: true,
    active: true, // This will always be active since there are no sub routes
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Suggested Filters" },
    },
});

// routes.usageSavedViews
export const usageSavedViewsState = (_route: RouteState): BreadcrumbState => ({
    enabled: true,
    active: true, // This will always be active since there are no sub routes
    resolverProps: {
        type: DisplayResolverTypes.label,
        params: { label: "Saved Views" },
    },
});

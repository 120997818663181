import { track } from "./track";

// // // //

enum FormEvents {
    inputClicked = "Typeform Input Clicked",
    formSubmitted = "Form Submitted",
    formSubmissionError = "Form Submission Error",
    formSubmissionSuccessful = "Form Submission Successful",
}

interface BaseFormSubmittedProps {
    form_name: string;
    submit_time: number;
    url: string;
}

// trackFormSubmitted tracks that a form was submitted we then use the submitTime to see if a form had an error when submitting it
export function trackFormSubmitted(name: string, url: string): number {
    const submitTime: number = Date.now();
    const baseEventProps: BaseFormSubmittedProps = {
        form_name: name,
        submit_time: submitTime,
        url,
    };

    track(FormEvents.formSubmitted, baseEventProps);
    return submitTime;
}

// trackFormSubmissionError tracks a form submission error and what that error was
export function trackFormSubmissionError(
    name: string,
    submitTime: number,
    url: string
) {
    const baseEventProps: BaseFormSubmittedProps = {
        form_name: name,
        submit_time: submitTime,
        url,
    };

    track(FormEvents.formSubmissionError, baseEventProps);
}

// trackFormSubmissionSuccess tracks that a form submission was successful
export function trackFormSubmissionSuccess(
    name: string,
    submitTime: number,
    url: string
) {
    const baseEventProps: BaseFormSubmittedProps = {
        form_name: name,
        submit_time: submitTime,
        url,
    };

    track(FormEvents.formSubmissionSuccessful, baseEventProps);
}

// trackFormRes tracks a form response and automatically processes whether an error was returned
export function trackFormRes(
    res: any,
    formName: string,
    submitTime: number,
    url: string
): any {
    if (
        "error" in res &&
        res.error != null &&
        Object.keys(res.error).length > 0
    ) {
        trackFormSubmissionError(formName, submitTime, url);
    } else {
        // track successful submit
        trackFormSubmissionSuccess(formName, submitTime, url);
    }
    return res;
}

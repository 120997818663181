import React from "react";

export function FieldToggle(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="16"
            viewBox="0 0 23 16"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <g
                    fill="#1D1D1B"
                    fillRule="nonzero"
                    transform="translate(-259 -327)"
                >
                    <g transform="translate(259 327)">
                        <path d="M14.955 2.286c3.162 0 5.742 2.56 5.742 5.73 0 3.169-2.58 5.729-5.742 5.729h-6.91c-3.193-.03-5.773-2.59-5.773-5.73 0-3.139 2.58-5.73 5.773-5.73h6.91zm0-2.286h-6.91C3.593 0 0 3.596 0 7.985 0 12.373 3.623 16 8.045 16h6.91C19.407 16 23 12.404 23 7.985 23 3.565 19.407 0 14.955 0z"></path>
                        <circle cx="8.5" cy="7.5" r="3.5"></circle>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import gql from "graphql-tag";
import { APPLIED_FILTER_FRAGMENT, AppliedFilter } from "./AppliedFilter";

// // // //

/**
 * AppliedAdvancedFilter is the SAVED AdvancedFilterInput
 */
export interface AppliedAdvancedFilter {
    key: string;
    filters: AppliedFilter[];
    __typename: "AppliedAdvancedFilter";
}

export const APPLIED_ADVANCED_FILTER_FRAGMENT = gql`
    fragment APPLIED_ADVANCED_FILTER_FRAGMENT on AppliedAdvancedFilter {
        key
        filters {
            ...APPLIED_FILTER_FRAGMENT
        }
    }
    ${APPLIED_FILTER_FRAGMENT}
`;

import { SuggestedFilter } from "@src/modules/advanced_filters/components/advanced_filters/suggested_filters/graphql";
import { Loader } from "@src/shared_modules/loader";
import classnames from "classnames";
import React from "react";
import { Dispatch } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { SuggestedFilterLink } from "../advanced_filters/suggested_filters";
import { FeatureContent } from "../../../../shared_modules/feature_content";
import { HorizontalDivider } from "../../../../shared_modules/horizontal_divider";
import {
    AdvancedFilterEditorAction,
    AdvancedFilterEditorActionTypes,
} from "./actions";
import { FilterForm } from "./FilterForm";
import { FilterList } from "./FilterList";
import { FilterListContainer } from "./FilterListContainer";
import { FilterListEmptyState } from "./FilterListEmptyState";
import {
    AdvancedFilter,
    AdvancedFilterInput,
    EditorAdvancedFilterInput,
    EditorFilter,
    EditorFiltersInput,
    Filters,
    FiltersInput,
} from "./graphql";
import {
    advancedFilterEditorReducer,
    AdvancedFilterEditorState,
    blankFilter,
    convertEditorFilterToFilter,
    convertFilterToEditorFilter,
    getInitialState,
} from "./reducer";
import styles from "./styles.module.css";

// // // //

/**
 * AdvancedFilterEditorProps
 * Props for the AdvancedFilterEditor component
 * @param filtersInput - the `FiltersInput` instance being edited
 * @param suggestedFilters - the `SuggestedFilters` available to the user
 * @param selectedQueryFilters - the `Filters` made available in the editor
 * @param wrapperClassName - (optional) wraps the entire editor in a `<div/>` styles with className="props.wrapperClassName"
 * @param formContainerClassName - (optional) wraps the entire editor in a `<div/>` styles with className="props.formContainerClassName"
 * @param listContainerClassName - (optional) wraps the entire editor in a `<div/>` styles with className="props.listContainerClassName"
 * @param formMessageClassName - (optional) wraps the showEmptyState message (in the form container) a `<div/>` styles with className="props.formMessageClassName"
 * @param onEditFilterChange - (optional) emits the edit state of the component
 * @param onChange - callback invoked when the `FiltersInput` currently in the editor changes
 */
interface AdvancedFilterEditorProps {
    filtersInput: FiltersInput;
    suggestedFilters: SuggestedFilter[];
    selectedQueryFilters: Filters;
    wrapperClassName?: string;
    formContainerClassName?: string;
    listContainerClassName?: string;
    formMessageClassName?: string;
    onEditFilterChange?: (editing) => void;
    onChange: (updatedFiltersInput: FiltersInput) => void;
    children?: (childProps: {
        FilterList: React.ReactNode;
        FilterForm: React.ReactNode;
    }) => React.ReactNode;
}

// // // //

/**
 * ClassNameWrapper
 * Conditionally wraps another component if a className is provided.
 * If props.className is defined, it will render `props.children` inside a `<div>` styles with `props.className`.
 * If props.className is NOT defined, it will simply render `props.children` inside a `React.Fragment`
 * NOTE: This component is required to handle missing values for the wrapperClassName`,
 * `formContainerClassName`, and `listContainerClassName` props in `AdvancedFilterEditorProps`. Rendering a <div> with an
 * empty classname will not behave the same as a component being wrapped inside `React.Fragment`. This allows us to
 * support more diverse layout configurations for the AdvancedFiltersEditor.
 * @param props.className - the CSS/SCSS classname to wrap around props.children
 * @param props.children - the `React.ReactNode` that may (or may not) be wrapped by a <div> with `props.className`
 */
export function ClassNameWrapper(props: {
    className?: string;
    children: React.ReactNode;
}) {
    // Wrap `props.children` in a <div> styled with `props.className`
    if (props.className) {
        return <div className={props.className}>{props.children}</div>;
    }

    // Return `props.children` wrapped in `React.Fragment`
    return <React.Fragment>{props.children}</React.Fragment>;
}

export function AdvancedFilterEditor(props: AdvancedFilterEditorProps) {
    // useReducer React hook for managing AdvancedFilterEditorState
    const [state, dispatch]: [
        AdvancedFilterEditorState,
        Dispatch<AdvancedFilterEditorAction>
    ] = React.useReducer(
        advancedFilterEditorReducer,
        props.filtersInput,
        getInitialState
    );

    // Pulls suggestedFilters from props
    const { suggestedFilters = [] } = props;

    // Invokes props.onChange IFF stats.query has changed
    // The callback passed into `useEffect` is invoked AFTER the browser has painted DOM changes in this component
    // NOTE - the `[state.query]` array passed in as the second parameter to `useEffect` is a dependency for
    // the callback - this ensures that the callback function is ONLY invoked when state.query changes
    React.useEffect(() => {
        // Defines updatedFiltersInput
        // Converts the state.query (EditorFiltersInput) into a FiltersInput object
        // Uses the `convertEditorFilterToFilter` function to convert an `EditorFilter` into a standard `Filter`
        const updatedFiltersInput: FiltersInput = {
            ...props.filtersInput,
            defaults: state.query.defaults.map(convertEditorFilterToFilter),
            advanced: state.query.advanced.map(
                (afi: EditorAdvancedFilterInput): AdvancedFilterInput => {
                    const convertedAdvancedFilterInput: AdvancedFilterInput = {
                        key: afi.key,
                        filters: afi.filters.map(convertEditorFilterToFilter),
                    };

                    return convertedAdvancedFilterInput;
                }
            ),
        };

        // Invoke props.onChange with updatedFiltersInput
        props.onChange(updatedFiltersInput);
    }, [state.query]);

    // // // //
    // // // //

    // Updates internal state if props.filtersInput changes
    // NOTE - this is only needed here because of a change in how the WorkflowEditor handles
    // selecting an ApplicationUsageSummary to pre-populate filters for ApplicationUser Workflows
    React.useEffect(() => {
        // Skip if props.filtersInput + state.filtersInput values are "equal"
        // Note that this is a very surface-level equality check since we really only need to do this when
        // a user selects an AppUsageSummary in RequiredFiltersBoundary component.
        // Eventually we should be able to remove all this logic and treat the AdvancedFiltersEditor as fully controlled component
        if (
            state.query.defaults.length ===
                props.filtersInput.defaults.length &&
            state.query.defaults.every(
                (f) =>
                    props.filtersInput.defaults.find((ff) => {
                        return ff.fieldName === f.fieldName;
                    }) !== undefined
            )
        ) {
            return;
        }

        // Defines updatedFiltersInput
        // Converts the state.query (EditorFiltersInput) into a FiltersInput object
        // Uses the `convertEditorFilterToFilter` function to convert an `EditorFilter` into a standard `Filter`
        const updatedFiltersInput: EditorFiltersInput = {
            ...props.filtersInput,
            defaults: props.filtersInput.defaults.map((f) =>
                convertFilterToEditorFilter(f, "defaults")
            ),
            advanced: props.filtersInput.advanced.map(
                (afi: AdvancedFilterInput): EditorAdvancedFilterInput => {
                    const convertedAdvancedFilterInput: EditorAdvancedFilterInput = {
                        key: afi.key,
                        filters: afi.filters.map((f) =>
                            convertFilterToEditorFilter(f, "advanced")
                        ),
                    };

                    return convertedAdvancedFilterInput;
                }
            ),
        };

        // Update internal filters state
        dispatch({
            type: AdvancedFilterEditorActionTypes.setFilters,
            editorFiltersInput: updatedFiltersInput,
        });
    }, [props.filtersInput]);

    // // // //
    // // // //

    // Emit whether or not a filter is currently being edited.
    React.useEffect(() => {
        if (props.onEditFilterChange) {
            props.onEditFilterChange(state.editingFilter);
        }
    }, [state.editingFilter]);

    // // // //
    // // // //

    // Defines a flag indicating whether or not to render the `FilterListEmptyState` component
    const showEmptyState: boolean =
        state.query.defaults.length === 0 && state.query.advanced.length === 0;

    // Defines FilterForm JSX
    const FilterFormNode = (
        <React.Fragment>
            <ClassNameWrapper className={props.formContainerClassName}>
                {state.editingFilter !== null && (
                    <FilterForm
                        filter={state.editingFilter}
                        selectedQueryFilters={props.selectedQueryFilters}
                        onSubmit={(filter) => {
                            // Update existing filter
                            if (filter.id) {
                                dispatch({
                                    type:
                                        AdvancedFilterEditorActionTypes.updateFilter,
                                    filter,
                                });
                                return;
                            }

                            // Choose create or update action to dispatch
                            const type = filter.id
                                ? AdvancedFilterEditorActionTypes.updateFilter
                                : AdvancedFilterEditorActionTypes.addFilter;
                            dispatch({ type, filter });
                        }}
                        onCancel={() => {
                            dispatch({
                                type:
                                    AdvancedFilterEditorActionTypes.clearEditor,
                            });
                        }}
                    />
                )}

                <FeatureContent uniqueKey="advanced-filter-editor">
                    {({ feature, loading }) => {
                        if (loading) {
                            return <Loader />;
                        }

                        return (
                            <React.Fragment>
                                {state.editingFilter === null && (
                                    <div className="row">
                                        {!showEmptyState && (
                                            <ClassNameWrapper
                                                className={
                                                    props.formMessageClassName
                                                }
                                            >
                                                <div className="col-sm-12 text-center my-30-px">
                                                    <p className="font-secondary">
                                                        {feature.description}
                                                    </p>
                                                </div>
                                            </ClassNameWrapper>
                                        )}
                                        <div className="col-sm-12">
                                            <button
                                                className="btn-lg btn-stroked-success font-size-18-px w-100 mt-10-px"
                                                onClick={() => {
                                                    dispatch({
                                                        type:
                                                            AdvancedFilterEditorActionTypes.editFilter,
                                                        filter: {
                                                            ...blankFilter,
                                                        },
                                                    });
                                                }}
                                            >
                                                {feature.header}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    }}
                </FeatureContent>

                {/* Render SuggestedFilters */}
                {state.editingFilter === null && suggestedFilters.length > 0 && (
                    <div className="mt-20-px">
                        <HorizontalDivider />
                        <p
                            className={classnames(
                                "font-secondary font-size-14-px text-grey-mid my-20-px",
                                styles.suggestedFilterHeader
                            )}
                        >
                            Suggested Filters
                        </p>
                        {suggestedFilters.map((sf) => (
                            <React.Fragment key={sf.id}>
                                <SuggestedFilterLink
                                    suggestedFilter={sf}
                                    className="font-secondary-bold font-size-14-px text-updated-black"
                                >
                                    {sf.content.name}
                                </SuggestedFilterLink>
                                <HorizontalDivider className="my-15-px" />
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </ClassNameWrapper>
        </React.Fragment>
    );

    const FilterListNode = (
        <React.Fragment>
            {showEmptyState && (
                <ClassNameWrapper className={props.listContainerClassName}>
                    <FilterListEmptyState />
                </ClassNameWrapper>
            )}

            {!showEmptyState && (
                <ClassNameWrapper className={props.listContainerClassName}>
                    <DragDropContext
                        onDragEnd={(result: DropResult) => {
                            // Short-circuit the function execution if result.destination is not defined
                            // NOTE - result.destination is undefined in scenarios like dropping a draggable component outside the `<DragDropContext >`
                            if (!result.destination) {
                                return;
                            }

                            // Dispatch REORDER_FILTERS action
                            dispatch({
                                type:
                                    AdvancedFilterEditorActionTypes.reorderFilters,
                                destinationFilterId:
                                    result.destination.droppableId,
                                startIndex: result.source.index,
                                endIndex: result.destination.index,
                            });
                        }}
                    >
                        {state.query.defaults.length > 0 && (
                            <FilterListContainer label="Defaults">
                                <FilterList
                                    depth={0}
                                    filters={state.query.defaults}
                                    parentFilter={null}
                                    selectedQueryFilters={
                                        props.selectedQueryFilters
                                    }
                                    editingFilter={state.editingFilter}
                                    droppableID={"defaults"}
                                    editFilter={(filter: EditorFilter) => {
                                        dispatch({
                                            type:
                                                AdvancedFilterEditorActionTypes.editFilter,
                                            filter,
                                        });
                                    }}
                                    removeFilter={(filter: EditorFilter) => {
                                        dispatch({
                                            type:
                                                AdvancedFilterEditorActionTypes.removeFilter,
                                            filter,
                                        });
                                    }}
                                    addOrFilter={(
                                        parentFilter: EditorFilter | null
                                    ) => {
                                        dispatch({
                                            type:
                                                AdvancedFilterEditorActionTypes.editFilter,
                                            filter: {
                                                ...blankFilter,
                                                source: "defaults",
                                            },
                                            destinationFilter: parentFilter,
                                            wrapNewFilterInOr: true,
                                        });
                                    }}
                                    addAndFilter={(
                                        parentFilter: EditorFilter | null
                                    ) => {
                                        dispatch({
                                            type:
                                                AdvancedFilterEditorActionTypes.editFilter,
                                            filter: {
                                                ...blankFilter,
                                                source: "defaults",
                                            },
                                            destinationFilter: parentFilter,
                                        });
                                    }}
                                />
                            </FilterListContainer>
                        )}

                        {props.selectedQueryFilters.advanced.map(
                            (af: AdvancedFilter) => {
                                // Finds the `AdvancedFilterInput` object associated with the `AdvancedFilter`
                                const advancedFilterInput:
                                    | EditorAdvancedFilterInput
                                    | undefined = state.query.advanced.find(
                                    (afi: EditorAdvancedFilterInput) =>
                                        afi.key === af.key
                                );

                                // If there is no `AdvancedFilterInput` associated with the `AdvancedFilter`, return null
                                if (!advancedFilterInput) {
                                    return null;
                                }

                                // Return a `FilterList` component to render advancedFilterInput.filters
                                return (
                                    <FilterListContainer
                                        label={af.content.name}
                                        key={af.key}
                                        includeAndBadge={
                                            state.query.defaults.length > 0
                                        }
                                    >
                                        <FilterList
                                            depth={0}
                                            filters={
                                                advancedFilterInput.filters
                                            }
                                            parentFilter={null}
                                            selectedQueryFilters={
                                                props.selectedQueryFilters
                                            }
                                            editingFilter={state.editingFilter}
                                            droppableID={af.key}
                                            editFilter={(
                                                filter: EditorFilter
                                            ) => {
                                                dispatch({
                                                    type:
                                                        AdvancedFilterEditorActionTypes.editFilter,
                                                    filter,
                                                });
                                            }}
                                            removeFilter={(
                                                filter: EditorFilter
                                            ) => {
                                                dispatch({
                                                    type:
                                                        AdvancedFilterEditorActionTypes.removeFilter,
                                                    filter,
                                                });
                                            }}
                                            addOrFilter={(
                                                parentFilter: EditorFilter | null
                                            ) => {
                                                dispatch({
                                                    type:
                                                        AdvancedFilterEditorActionTypes.editFilter,
                                                    filter: {
                                                        ...blankFilter,
                                                        source: af.key,
                                                    },
                                                    destinationFilter: parentFilter,
                                                    wrapNewFilterInOr: true,
                                                });
                                            }}
                                            addAndFilter={(
                                                parentFilter: EditorFilter | null
                                            ) => {
                                                dispatch({
                                                    type:
                                                        AdvancedFilterEditorActionTypes.editFilter,
                                                    filter: {
                                                        ...blankFilter,
                                                        source: af.key,
                                                    },
                                                    destinationFilter: parentFilter,
                                                });
                                            }}
                                        />
                                    </FilterListContainer>
                                );
                            }
                        )}
                    </DragDropContext>
                </ClassNameWrapper>
            )}
        </React.Fragment>
    );

    // Handle props.children
    if (props.children) {
        return (
            <React.Fragment>
                {props.children({
                    FilterList: FilterListNode,
                    FilterForm: FilterFormNode,
                })}
            </React.Fragment>
        );
    }

    // Render inside div with props.wrapperClassName
    return (
        <ClassNameWrapper className={props.wrapperClassName}>
            {FilterFormNode}
            {FilterListNode}
        </ClassNameWrapper>
    );
}

import { Loader } from "@src/shared_modules/loader";
import * as React from "react";
import { FadeInOut } from "../transitions/FadeInOut";

// // // //

/**
 * UserLoading
 * Renders the loading state for when the frontend is checking if the current user is logged in
 */
export function UserLoading() {
    return (
        <div className="tw-min-h-screen tw-flex tw-flex-col tw-justify-center">
            <FadeInOut>
                <Loader />
            </FadeInOut>
        </div>
    );
}

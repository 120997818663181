import React from "react";

export function UsageRankDirect(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="10"
            viewBox="0 0 22 10"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            >
                <g
                    stroke="currentColor"
                    strokeWidth="2"
                    transform="translate(-561 -571)"
                >
                    <g transform="translate(562 572)">
                        <path d="M0 3.75L19.5 3.75"></path>
                        <path d="M3.25 0L0 3.75 3.25 7.5"></path>
                        <path d="M16.25 0L19.5 3.75 16.25 7.5"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

import React from "react";

export function VerifyEmailLock(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="58"
            viewBox="0 0 44 58"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            >
                <g strokeWidth="3" transform="translate(-608 -208)">
                    <g transform="translate(609 209)">
                        <rect
                            width="39.875"
                            height="32.625"
                            x="0.813"
                            y="22.563"
                            stroke="#082C4B"
                            rx="1.5"
                        ></rect>
                        <path
                            stroke="#082C4B"
                            d="M8.063 22.563V13.5C8.063 6.493 13.742.812 20.75.812S33.438 6.492 33.438 13.5v9.063"
                        ></path>
                        <path
                            stroke="#2DC8A8"
                            d="M20.407 46.125a.906.906 0 110 1.812.906.906 0 010-1.812M12.468 39.43a14.515 14.515 0 0115.878 0M32.751 33.703a21.707 21.707 0 00-24.688 0"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

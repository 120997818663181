import { ButtonDropdown } from "@src/shared_modules/button_dropdown";
import classnames from "classnames";
import React from "react";
import { Filter, FilterExpression } from "./graphql";

// // // //

/**
 * FilterDropdownProps
 * `filter` - the `AdvancedFilter` instance that's being passed to the `removeFilter` function
 * `parentFilter` - the `AdvancedFilter` instance whose the direct parent of `props.filter`
 * `depth`: The "depth" at which the <FilterList /> is rendered. Used to limit depth of recursively nested filters
 * `disabled`: Whether or not the <ButtonDropdown /> component will open when clicked
 * `openDropdownUp`: see `FilterListItemProps` annotation
 * `editFilter`: see `FilterListItemProps` annotation
 * `removeFilter`: see `FilterListItemProps` annotation
 * `addOrFilter`: see `FilterListItemProps` annotation
 * `addAndFilter`: see `FilterListItemProps` annotation
 */
interface FilterDropdownProps {
    filter: Filter;
    parentFilter: Filter | null;
    depth: number;
    openDropdownUp?: boolean;
    editFilter: (filter: Filter) => void;
    removeFilter: (filter: Filter) => void;
    addOrFilter: (parentFilter: Filter | null) => void;
    addAndFilter: (parentFilter: Filter | null) => void;
    disabled: boolean;
}

// Defines the maximum depth at which new filters may be created
const MAX_DEPTH: number = 3;

/**
 * FilterDropdown
 * Renders a dropdown with buttons to invoke change upon the `Filter` passed in as `props.filter`
 * @param props - see `FilterDropdownProps`
 */
export function FilterDropdown(props: FilterDropdownProps) {
    // Defines base className for each button
    const btnClassNameBase = "btn-sm w-100";

    // Defines buttonDropdownClassName
    const buttonDropdownClassName = "btn-sm btn-stroked-grey p-5-px";

    // Pulls openDropdownUp from props, assigns default value of `false`
    const { openDropdownUp = false } = props;

    // Defines flag indicating whether or not to render the `And +` dropdown item
    // The `And +` button should be hidden IFF the parent of props.filter has `{ expression: 'or` }`
    const shouldRenderAndButton: boolean =
        (props.depth < MAX_DEPTH && props.parentFilter === null) ||
        (props.parentFilter &&
            props.parentFilter.expression !== FilterExpression.or);

    return (
        <ButtonDropdown
            alignRight
            openUp={openDropdownUp}
            disabled={props.disabled}
            buttonClassName={buttonDropdownClassName}
        >
            {shouldRenderAndButton && (
                <button
                    className={classnames(
                        btnClassNameBase,
                        "btn-stroked-primary"
                    )}
                    onClick={() => {
                        props.addAndFilter(props.parentFilter);
                    }}
                >
                    <i className="fa fa-fw fa-plus" />
                    And
                </button>
            )}
            {props.depth < MAX_DEPTH && (
                <button
                    className={classnames(
                        btnClassNameBase,
                        "btn-stroked-primary"
                    )}
                    onClick={() => {
                        props.addOrFilter(props.parentFilter);
                    }}
                >
                    <i className="fa fa-fw fa-plus" />
                    Or
                </button>
            )}
            <button
                className={classnames(btnClassNameBase, "btn-stroked-grey")}
                onClick={() => {
                    props.editFilter(props.filter);
                }}
            >
                <i className="fa fa-fw fa-pencil" />
                Edit
            </button>
            <button
                className={classnames(btnClassNameBase, "btn-stroked-alert")}
                onClick={() => {
                    props.removeFilter(props.filter);
                }}
            >
                <i className="fa fa-fw fa-times" />
                Remove
            </button>
        </ButtonDropdown>
    );
}

import classnames from "classnames";
import * as React from "react";

// // // //

/**
 * HorizontalDivider
 * Replacement for <hr /> component, which doesn't render _just_ the way we want for horizontal rules
 * @param props.className - (optional) className to apply to the div
 */
export function HorizontalDivider(props: { className?: string }) {
    const { className = "" } = props;
    return (
        <div
            className={classnames("tw-w-full tw-h-px tw-bg-gray-200", {
                [className]: className !== "",
            })}
        />
    );
}

import * as React from "react";
import {
    NumberInput,
    TextAreaInput,
    TextInput,
} from "@src/shared_modules/input";
import { PreviewComponentProps } from "../types";

// // // //

/**
 * TextareaPreview
 * Renders a preview for string + number + multiline_string
 * @param props
 * @returns
 */
export function TextareaPreview(props: PreviewComponentProps) {
    const { inputType } = props;
    const [value, setValue] = React.useState("");

    // Render NumberInput for "number" inputType
    if (inputType === "number") {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <NumberInput
                    value={Number(value)}
                    onChange={(updatedVal: number) => {
                        setValue(String(updatedVal));
                    }}
                />
            </form>
        );
    }

    // Render TextAreaInput for "multiline_string" inputType
    if (inputType === "multiline_string") {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <TextAreaInput
                    value={value}
                    onChange={(updatedVal: string) => {
                        setValue(updatedVal);
                    }}
                />
            </form>
        );
    }

    // Render TextInput for "string" inputType
    if (inputType === "string") {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <TextInput
                    value={value}
                    onChange={(updatedVal: string) => {
                        setValue(updatedVal);
                    }}
                />
            </form>
        );
    }

    return null;
}

import classnames from "classnames";
import * as React from "react";
import { HeaderItem } from "./HeaderItem";

// // // //

interface DashboardOverviewHeaderProps {
    value: string | number;
    description: string;
    children?: React.ReactNode;
    // wrapperClassName is a custom class name that is applied to the wrapper div if passed
    wrapperClassName?: string;
}

/**
 * DashboardOverviewHeader
 * Renders a secondary header row containing a number and a description. Additional header items can be passed as children
 */
export function DashboardOverviewHeader(props: DashboardOverviewHeaderProps) {
    const wrapperClassName = classnames("tw-flex tw-grow pr-30-px", {
        [props.wrapperClassName]: props.wrapperClassName,
    });
    return (
        <header
            className={classnames(
                "tw-bg-white tw-flex tw-min-h-[68px]",
                "border-t-none border-l-none border-r-none tw-border-solid tw-border tw-border-gray-border"
            )}
        >
            <div className={wrapperClassName}>
                <HeaderItem
                    type="dark"
                    bold
                    noLeftBorder
                    number={props.value}
                    description={props.description}
                    className="px-30-px tw-whitespace-nowrap"
                />
                {props.children}
            </div>
        </header>
    );
}

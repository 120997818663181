import { SuggestedFiltersEvents } from "@src/analytics/advanced_filters";
import { routes } from "@src/routes";
import { FeatureContent } from "@src/shared_modules/feature_content";
import { Anchor, LinkCC } from "@src/shared_modules/router";
import { buildQsFromObject } from "@src/util/route";
import classnames from "classnames";
import * as React from "react";
import { SUGGESTED_WORKFLOW_PARAM } from "./constants";
import { SuggestedFilter } from "./graphql";

// // // //

/**
 * SuggestedWorkflowLinkProps
 * @param suggestedFilter - The SuggestedFilter for which the LinkCC is being rendered
 * @param children - Child content to render inside the LinkCC
 * @param className - (optional) Classname to be applied instead of default `btn-outline-primary`
 * @param openInNewTab - (optional) Boolean flag that passes `target="_blank"` to LinkCC
 */
interface SuggestedWorkflowLinkProps {
    suggestedFilter: SuggestedFilter;
    className?: string;
    children: React.ReactNode;
    openInNewTab?: boolean;
}

/**
 * SuggestedWorkflowLink
 * Renders a LinkCC to build a new Workflow from a SuggestedFilter
 * @param props - see SuggestedWorkflowLinkProps
 */
export function SuggestedWorkflowLink(props: SuggestedWorkflowLinkProps) {
    const { suggestedFilter, className = "", openInNewTab = false } = props;

    // Defines params for URL using SUGGESTED_WORKFLOW_PARAM
    const routeParams = {
        [SUGGESTED_WORKFLOW_PARAM]: JSON.stringify(suggestedFilter),
    };

    // Defines URL to WorkflowNew page with SuggestedFilter defined in RouteState.params
    const url = `${routes.workflowNew}${buildQsFromObject(routeParams)}`;

    // If props.openInNewTab -> Render <Anchor/> instead of <LinkCC/>
    if (openInNewTab) {
        return (
            <Anchor
                className={classnames({
                    [className]: className !== "",
                })}
                href={url}
                target="_blank"
                eventName={SuggestedFiltersEvents.suggestedWorkflowLinkClick}
                eventProps={{ suggestedFilterID: suggestedFilter.id }}
            >
                {props.children}
            </Anchor>
        );
    }

    // Render standard LinkCC w/ props.children
    return (
        <LinkCC
            className={classnames({
                [className]: className !== "",
            })}
            href={{
                pathname: routes.workflowNew,
                query: {},
            }}
            as={url}
        >
            {props.children}
        </LinkCC>
    );
}

/// // // //

/**
 * SuggestedWorkflowButton
 * Renders a <SuggestedWorkflowLink /> wrapped in `FeatureContent`
 * Used in conjunction with the AdvancedFiltersProvider to send user-defined FiltersInput to the WorkflowEditor
 * @param suggestedFilter - The SuggestedFilter for which the SuggestedWorkflowLink is being rendered
 * @param className - (optional) Classname to be applied to SuggestedWorkflowLink
 */
export function SuggestedWorkflowButton(props: {
    suggestedFilter: SuggestedFilter;
    className?: string;
}) {
    return (
        <FeatureContent uniqueKey="send-to-workflow-editor-button">
            {({ loading, feature }) => {
                if (loading) {
                    return null;
                }
                return (
                    <SuggestedWorkflowLink {...props} openInNewTab>
                        {feature.header}
                    </SuggestedWorkflowLink>
                );
            }}
        </FeatureContent>
    );
}

import React from "react";
import classnames from "classnames";
import { getButtonClassName } from "./getButtonClassName";

// // // //

export interface ButtonProps {
    /**
     * Click handler
     */
    onClick: () => void;
    /**
     * Text rendered inside the button
     */
    label: string;
    /**
     * Optional variant
     */
    variant?: "primary" | "primary-outline" | "secondary";
    /**
     * Optional size
     */
    size?: "xs" | "sm" | "md" | "lg";
    /**
     * Optional type prop for indicating the button's purpose (e.g. submission of data, no default behavior)
     */
    type?: "submit" | "button";
    /**
     * Optional configuration option for rendering an icon alongside label
     */
    iconConfig?: {
        /**
         * Icon element
         */
        icon: JSX.Element;
        /**
         * Optional prop for rendering icon to either the left or right side of the label
         */
        position?: "left" | "right";
    };
    /**
     * Optional display prop for full-width buttons
     */
    block?: boolean;
    /**
     * Optional boolean to make button unclickable
     */
    disabled?: boolean;
    /**
     * Optional classname for additional styling
     */
    className?: string;
}

// Button
export function Button({
    onClick,
    label,
    variant = "primary",
    size = "md",
    type = "button",
    iconConfig = null,
    block = false,
    disabled = false,
    className = "",
}: ButtonProps): JSX.Element {
    // Get button classnames
    const buttonClassNames = getButtonClassName({
        variant,
        size,
        iconConfig,
        block,
        disabled,
    });

    return (
        <button
            className={classnames(buttonClassNames, {
                [className]: className !== "",
            })}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {label}
            {iconConfig !== null && (
                <React.Fragment>{iconConfig.icon}</React.Fragment>
            )}
        </button>
    );
}

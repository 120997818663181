import { captureException } from "../util/analytics";
import { nemoTrack } from "./track_nemo";

export interface EventProps {
    [key: string]: any;
}

interface BaseEventProps {
    path: string;
    url: string;
    query_string: string;
    page_referrer: string;
}

// genBaseEventProps generates base properties for event tracking
function genBaseEventProps(): BaseEventProps {
    return {
        path: window.location.pathname,
        url: window.location.href,
        query_string: window.location.search,
        page_referrer: document.referrer,
    };
}

function trackPendo(eventName: string, eventProps: EventProps): void {
    const { pendo } = window;
    try {
        pendo.track(eventProps.name, eventProps);
    } catch (e) {
        if ("pendo" in window) {
            captureException(e, {
                extra: {
                    eventName,
                },
            });
        }
    }
}

// track the eventName and eventProps
export function track(eventName: string, eventProps: EventProps) {
    if (typeof window === "undefined") {
        return Promise.resolve({
            logged: false,
        });
    }

    const baseProps: BaseEventProps = genBaseEventProps();
    const props = {
        ...eventProps,
        ...baseProps,
    };
    trackPendo(eventName, props);
    return nemoTrack(eventName, eventProps);
}

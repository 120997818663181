import {
    identifyIntercomUser,
    IntercomIdentifyResponse,
} from "@src/requests/intercom-identify";
import { UserApi } from "@src/shared_modules/user/types";
import { initPendo } from "./initPendo";

// // // //

// NOTE - uncomment this to debug the Pendo <> Intercom integration handshake
// This bypasses the getter/setter methods for window.Intercom so we can ensure
// the initPendo function below is working as-expected.
// This isn't handled via a dedicated Jest test because testing window
// getter/setter behavior is annoying and time-consuming.
// NOTE - you'll need to update the PENDO_ANALYTICS_KEY environment
// variable with a real value in `dev.env` to load up the Pendo JS library correctly.
// You'll also need the address of your dev environment to be added as a "Staging" domain in the Pendo dashboard here:
// https://app.pendo.io/s/5683840649003008/admin/staging

// let INTERCOM_GLOBAL = undefined;
// if (typeof window !== "undefined") {
//     Object.defineProperty(window, "Intercom", {
//         get: function() {
//             return INTERCOM_GLOBAL;
//         },
//         set: function(value) {
//             // NOTE - comment out this line to test the behavior when window.Intercom *never* loads
//             INTERCOM_GLOBAL = value;
//         },
//     });
// }

// // // //

// identifyUser identify the current user with analytics
export function identifyUser(user: UserApi) {
    // window will be undefined when running on server
    /*if (typeof window === "undefined" || !("analytics" in window)) {
        return;
    }*/

    // Fetch Intercom User Hash from /pages/api/intercom-identify.ts
    identifyIntercomUser({ userEmail: user.email }).then(
        (resp: IntercomIdentifyResponse) => {
            // Short-circuit if user hash isn't returned
            if (!resp.success) {
                return;
            }

            // Initialize Pendo
            initPendo(user, resp.hash);
        }
    );
}

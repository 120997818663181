import gql from "graphql-tag";
import { AppliedFilters, APPLIED_FILTERS_FRAGMENT } from "./AppliedFilters";
import { ReportStatus } from "./ReportStatus";
import { ReportType } from "./ReportType";

// // // //

/**
 * TerminatedAppUserReportConfig encapsulates the parameters required to generate ApplicationUser reports
 */
interface TerminatedAppUserReportConfig {
    integrationInstanceID: string;
    appIDsToReportOn: string[];
    userFilters: AppliedFilters;
    __typename: "TerminatedAppUserReportConfig";
}

/**
 * ReportConfiguration encapsulates the implementation-specific parameters for different ReportTypes
 */
interface ReportConfiguration {
    terminatedAppUser?: TerminatedAppUserReportConfig;
    __typename: "ReportConfiguration";
}

/**
 * Report represents a single Report entity
 */
export interface Report {
    id: string;
    organizationID: number;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    status: ReportStatus;
    statusUpdatedAt: null | string;
    isArchived: boolean;
    type: ReportType;
    config: ReportConfiguration; // NOTE - in GQL schema as "PropertyMap"
    __typename: "Report";
}

export const REPORT_FRAGMENT = gql`
    fragment REPORT_FRAGMENT on Report {
        id
        organizationID
        name
        description
        createdAt
        updatedAt
        status
        statusUpdatedAt
        isArchived
        type
        config {
            terminatedAppUser {
                integrationInstanceID
                appIDsToReportOn
                userFilters {
                    ...APPLIED_FILTERS_FRAGMENT
                }
            }
        }
    }
    ${APPLIED_FILTERS_FRAGMENT}
`;

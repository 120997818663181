import { LinkCC, RouteState, withRouter } from "@src/shared_modules/router";
import {
    getDoraParamDate,
    getParamDate,
} from "@src/shared_modules/date_range_filter";
import { TopHoverTooltip } from "@src/shared_modules/tooltips";
import { getQueryParam as getNumberRangeFilterParam } from "@src/shared_modules/number_range_filter";
import { buildQsFromObject } from "@src/util/route";
import moment from "moment";
import * as React from "react";
import { formatMoment } from "../../util/date_util";
import { Pill } from "../pill";
import styles from "./styles.module.css";

// // // //

interface StringFilterProps {
    // eslint reports false positives here
    // eslint-disable-next-line react/no-unused-prop-types
    type: "string";
    paramName: string;
    displayName: string;
    // defaultValue is displayed when route param for this filter doesn't exist
    defaultValue?: string;
    route: RouteState;
}

interface StringListFilterProps {
    type: "string_list";
    paramName: string;
    displayName: string;
    route: RouteState;
}

interface DateRangeFilterProps {
    // eslint reports false positives here
    // eslint-disable-next-line react/no-unused-prop-types
    type: "date_range";
    displayName: string;
    paramName: {
        min: string;
        max: string;
    };
    defaultValue: {
        min: moment.Moment;
        max: moment.Moment;
    };
    // resetToDefault specifies that the filter should set its value to
    // default instead of the empty value when reset all button is clicked
    resetToDefault?: boolean;
    route: RouteState;
}

interface DoraDateRangeFilterProps {
    // eslint reports false positives here
    // eslint-disable-next-line react/no-unused-prop-types
    type: "dora_date_range";
    displayName: string;
    paramName: string;
    defaultValue?: {
        min: moment.Moment;
        max: moment.Moment;
    };
    route: RouteState;
}

interface NumberRangeFilterProps {
    type: "number_range";
    displayName: string;
    paramName: string;
    route: RouteState;
}

// FilterProps are the props of a single filter
type FilterProps =
    | DateRangeFilterProps
    | StringFilterProps
    | StringListFilterProps
    | DoraDateRangeFilterProps
    | NumberRangeFilterProps;

// FilterPillProps are the props of the FilterPill component
interface FilterPillProps {
    displayName: string;
    value: string;
    // resetUrl is the url that is followed when the pill is clicked. can be omitted, then the pill
    // won't be clickable and the cross icon won't be rendered inside it
    resetUrl?: string;
    // tooltipText is the text to display in the tooltip. it is used even if there is overflowing content
    tooltipText?: string;
}

// FilterPillState is the state of the FilterPill component
interface FilterPillState {
    hasOverflow: boolean;
}

// FilterPill renders the filter pill
class FilterPill extends React.Component<
    FilterPillProps & { route: RouteState },
    FilterPillState
> {
    public ref: HTMLParagraphElement | null | undefined;

    constructor(props: FilterPillProps & { route: RouteState }) {
        super(props);

        this.state = {
            hasOverflow: false,
        };

        this.checkOverflow = this.checkOverflow.bind(this);
    }

    // checkOverflow checks whether the content is overflowing. if so, the tooltip becomes visible.
    // this check is performed on mouseover because there isn't an easy way to get the element's width on
    // initial render correctly. measuring it in componentDidMount doesn't work, the returned value becomes
    // correct only after some time
    public checkOverflow() {
        if (!this.ref) {
            return;
        }
        const hasOverflow = this.ref.clientWidth < this.ref.scrollWidth;
        // avoid unnecessary re-renders
        if (hasOverflow === this.state.hasOverflow) {
            return;
        }
        this.setState({ hasOverflow });
    }

    public render() {
        const { displayName, value, resetUrl, tooltipText } = this.props;
        const { hasOverflow } = this.state;
        const pillBody = (
            <Pill
                label={displayName}
                value={value}
                renderRemoveButton={resetUrl !== undefined}
            />
        );
        const content = (
            <div
                className={styles.pillWrapper}
                onMouseOver={() => this.checkOverflow()}
                onFocus={() => this.checkOverflow()}
            >
                {resetUrl ? (
                    <LinkCC
                        className={styles.link}
                        href={{
                            pathname: this.props.route.nextPathname,
                            query: this.props.route.pathParams,
                        }}
                        as={resetUrl}
                    >
                        {pillBody}
                    </LinkCC>
                ) : (
                    pillBody
                )}
            </div>
        );
        // display the tooltip only when the content is overflowing or the corresponding prop was passed
        if (!hasOverflow && !tooltipText) {
            return content;
        }
        return (
            <TopHoverTooltip
                tooltip={
                    <span className={styles.tooltip}>
                        {tooltipText || value}
                    </span>
                }
            >
                {content}
            </TopHoverTooltip>
        );
    }
}

// normalizeStringFilter normalizes a string filter
function normalizeStringFilter(filter: StringFilterProps, route: RouteState) {
    const { paramName, displayName, defaultValue } = filter;
    const { params, location } = route;
    let value;
    // render the filter only if it has a value set in the query params or the default value was passed
    if (paramName in params) {
        value = params[paramName];
    } else if (defaultValue !== undefined) {
        value = defaultValue;
    } else {
        return null;
    }

    if (typeof value !== "string") {
        return null;
    }

    // disable removing the filter when its value is equal to defaultValue
    if (value === defaultValue) {
        return {
            displayName,
            value,
            tooltipText: "This filter is required",
        };
    }

    // construct the reset url
    const paramsAfterReset = { ...params };
    delete paramsAfterReset[paramName];
    const resetUrl = `${location}${buildQsFromObject(paramsAfterReset)}`;
    return {
        displayName,
        value,
        resetUrl,
    };
}

// normalizeStringListFilter normalizes the string list filter. it can be a checklist filter or a toggle switch
function normalizeStringListFilter(
    filter: StringListFilterProps,
    route: RouteState
) {
    const { paramName, displayName } = filter;
    const { params, location } = route;
    if (!(paramName in params)) {
        return null;
    }
    let value = params[paramName];
    if (typeof value === "string") {
        value = [value];
    }
    if (!Array.isArray(value)) {
        return null;
    }
    value = value.join(", ");
    // construct the reset url
    const paramsAfterReset = { ...params };
    delete paramsAfterReset[paramName];
    const resetUrl = `${location}${buildQsFromObject(paramsAfterReset)}`;
    return {
        displayName,
        value,
        resetUrl,
    };
}

// formatRange returns a display value for the range filter based on minimum and maximum values passed
export function formatRange(minValue: string | null, maxValue: string | null) {
    if (minValue !== null && maxValue !== null) {
        return `${minValue} - ${maxValue}`;
    }
    if (minValue === null && maxValue !== null) {
        return `to ${maxValue}`;
    }
    if (minValue !== null && maxValue === null) {
        return `from ${minValue}`;
    }
    return null;
}

// normalizeStringFilter normalizes a date range filter
function normalizeDateRangeFilter(
    filter: DateRangeFilterProps,
    route: RouteState
) {
    const { paramName, displayName, defaultValue } = filter;
    const { params, location } = route;
    const minValue = formatMoment(
        getParamDate(params, paramName.min, defaultValue.min)
    );
    const maxValue = formatMoment(
        getParamDate(params, paramName.max, defaultValue.max)
    );
    const value = formatRange(minValue, maxValue);
    // don't render the filter if the value was reset manually
    if (value === null) {
        return null;
    }
    // construct the reset url
    const paramsAfterReset = { ...params };
    ["min", "max"].forEach((key) => {
        paramsAfterReset[paramName[key]] = "null";
    });
    const resetUrl = `${location}${buildQsFromObject(paramsAfterReset)}`;
    return {
        displayName,
        value,
        resetUrl,
    };
}

// normalizeDoraDateRangeFilter normalizes dora date range filter
function normalizeDoraDateRangeFilter(
    filter: DoraDateRangeFilterProps,
    route: RouteState
) {
    const { paramName, displayName, defaultValue } = filter;
    const { params } = route;
    const defaultMin = defaultValue ? defaultValue.min : null;
    const minValue = formatMoment(
        getDoraParamDate(params, paramName, "start", defaultMin)
    );
    const defaultMax = defaultValue ? defaultValue.max : null;
    const maxValue = formatMoment(
        getDoraParamDate(params, paramName, "end", defaultMax)
    );
    const value = formatRange(minValue, maxValue);
    // don't render the filter if the value was reset manually
    if (value === null) {
        return null;
    }
    return {
        displayName,
        value,
        tooltipText: "This filter is required",
    };
}

// normalizeNumberRangeFilter normalizes the number range filter
function normalizeNumberRangeFilter(
    filter: NumberRangeFilterProps,
    route: RouteState
) {
    const { paramName, displayName } = filter;
    const { params, location } = route;
    const minValue = getNumberRangeFilterParam(params, paramName, "start");
    const maxValue = getNumberRangeFilterParam(params, paramName, "end");
    const value = formatRange(minValue, maxValue);
    // don't render the filter if its values are empty
    if (value === null) {
        return null;
    }
    // construct the reset url
    const paramsAfterReset = { ...params };
    delete paramsAfterReset[paramName];
    const resetUrl = `${location}${buildQsFromObject(paramsAfterReset)}`;
    return {
        displayName,
        value,
        resetUrl,
    };
}

// normalizeFilter receives an element from the configuration array of the Filters component and returns props ready to be
// used with FilterPill component or null if a filter shouldn't be displayed for any reason
function normalizeFilter(
    filter: FilterProps,
    route: RouteState
): FilterPillProps | null {
    if (filter.type === "string") {
        return normalizeStringFilter(filter, route);
    }
    if (filter.type === "string_list") {
        return normalizeStringListFilter(filter, route);
    }
    if (filter.type === "date_range") {
        return normalizeDateRangeFilter(filter, route);
    }
    if (filter.type === "dora_date_range") {
        return normalizeDoraDateRangeFilter(filter, route);
    }
    if (filter.type === "number_range") {
        return normalizeNumberRangeFilter(filter, route);
    }
    return null;
}

// FilterPillsProps are the props that are passed to the Filters component
interface FilterPillsProps {
    // filters is an array of objects representing filter pills configuration
    filters: FilterProps[];
    route: RouteState;
}

// Filters renders the list of active filter pills. If there are no active filters to render, it doesn't render anything
function Filters(props: FilterPillsProps) {
    const { filters, route } = props;
    const { location } = route;
    const normalizedFilters = filters
        .map((filter) => normalizeFilter(filter, route))
        // remove null values
        .filter((filter) => filter);
    // check if we should render the entire component
    if (normalizedFilters.length === 0) {
        return null;
    }
    // build the `reset all` url
    const paramsAfterReset = {};
    filters.forEach((filter: FilterProps) => {
        if (filter.type === "date_range" && !filter.resetToDefault) {
            paramsAfterReset[filter.paramName.min] = "null";
            paramsAfterReset[filter.paramName.max] = "null";
        } else if (filter.type === "dora_date_range") {
            // dora date range filters are not reset when clicking "reset all"
            paramsAfterReset[filter.paramName] = route.params[filter.paramName];
        }
    });
    const resetAllFiltersUrl = `${location}${buildQsFromObject(
        paramsAfterReset
    )}`;
    return (
        <div className={`${styles.activeFilters} d-flex align-items-center`}>
            <h3 className={styles.header}>Active Filters</h3>
            <div className={`${styles.pillsContainer} d-flex flex-wrap`}>
                {normalizedFilters.map(
                    (filter) =>
                        // the check for an empty filter was required by flow
                        filter && (
                            <FilterPill
                                route={route}
                                key={filter.displayName}
                                {...filter}
                            />
                        )
                )}
            </div>
            <LinkCC
                className={`font-secondary text-black font-size-12-px ${styles.resetAll}`}
                as={resetAllFiltersUrl}
                href={{ pathname: route.nextPathname, query: route.pathParams }}
            >
                Reset All
            </LinkCC>
        </div>
    );
}

export const FilterPills = withRouter(Filters);

import * as React from "react";

import { Checklist } from "@src/shared_modules/checklist";
import { PreviewComponentProps } from "../types";

// ChecklistPreviewState represents the state of the ChecklistPreview component
interface ChecklistPreviewState {
    checkedValues: string[];
}

// ChecklistPreview renders the checklist preview
export class ChecklistPreview extends React.Component<
    PreviewComponentProps,
    ChecklistPreviewState
> {
    public static getDerivedStateFromProps(
        props: PreviewComponentProps,
        state: ChecklistPreviewState
    ) {
        if (props.values != null) {
            return { checkedValues: [] };
        }
        // delete non-existent items from checked values
        const checkedValues: string[] = state.checkedValues.filter(
            (checkedValue: string) =>
                props.values && props.values.includes(checkedValue)
        );
        return { checkedValues };
    }

    constructor(props: PreviewComponentProps) {
        super(props);
        this.state = {
            checkedValues: [],
        };
        this.onClick = this.onClick.bind(this);
    }

    public onClick(checkedValues: string[]) {
        this.setState({ checkedValues });
    }

    public render() {
        if (!this.props.values) {
            return null;
        }

        // remove empty and duplicate field values
        const filteredValues = [
            ...new Set(this.props.values.filter((value) => value)),
        ];

        return (
            <Checklist
                values={filteredValues}
                checkedValues={this.state.checkedValues}
                onClick={this.onClick}
            />
        );
    }
}

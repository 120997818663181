import { Pagination } from "@src/schema/Pagination";
import { Workflow } from "@src/schema/Workflow";
import { WorkflowList } from "@src/schema/WorkflowList";
import { DEFAULT_PAGINATION } from "@src/modules/advanced_filters/components/advanced_filters/constants";
import { FiltersInput } from "@src/modules/advanced_filters/components/advanced_filter_editor";
import { GraphQlErrorHandler } from "@src/shared_modules/graphql_error_handler";
import { WatchQueryFetchPolicy } from "apollo-client";
import * as React from "react";
import { Query } from "react-apollo";

import {
    WORKFLOW_LIST_FETCHER_QUERY,
    WorkflowListFetcherResponse,
    WorkflowListFetcherVariables,
} from "./graphql";

// // // //

interface Props {
    filters: FiltersInput;
    fetchPolicy?: WatchQueryFetchPolicy;
    children: (childProps: {
        loading: boolean;
        workflows: Workflow[];
        pagination: Pagination;
        refetchWorkflows: () => void;
    }) => React.ReactNode;
}

const EMPTY_LIST: WorkflowList = {
    results: [],
    pagination: {
        pageCount: 0,
        rangeStart: 0,
        rangeEnd: 0,
        page: 1,
        totalItems: 0,
        itemsPerPage: 25,
        sort: "",
        direction: "",
        __typename: "Pagination",
    },
    __typename: "WorkflowsList",
};

/**
 * @name WorkflowListFetcher
 * @description Fetches workflow list
 * @param {Props} props
 */
export function WorkflowListFetcher(props: Props) {
    return (
        <Query<WorkflowListFetcherResponse, WorkflowListFetcherVariables>
            query={WORKFLOW_LIST_FETCHER_QUERY}
            fetchPolicy={props.fetchPolicy}
            variables={{
                filters: {
                    // NOTE - we **must** include `FiltersInput.pagination` because it's required by Stormbreaker
                    pagination: {
                        ...DEFAULT_PAGINATION,
                    },
                    ...props.filters,
                },
            }}
        >
            {({ loading, error, data, refetch }) => {
                // Safely pulls workflows from data + assigns default value
                const list: WorkflowList =
                    data?.workflows?.workflows?.list || EMPTY_LIST;

                const workflows: Workflow[] = list.results;

                const pagination: Pagination = list.pagination;

                return (
                    <React.Fragment>
                        {error && <GraphQlErrorHandler error={error} />}
                        {props.children({
                            loading,
                            workflows,
                            pagination,
                            refetchWorkflows: () => {
                                refetch();
                            },
                        })}
                    </React.Fragment>
                );
            }}
        </Query>
    );
}

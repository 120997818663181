import { routes } from "@src/routes";
import { FeatureFlagCC, FeatureNames } from "@src/shared_modules/feature_flag";
import { RouteState, withRouter } from "@src/shared_modules/router";
import {
    SideNavGroup,
    SideNavLayout,
    SideNavLink,
    SideNavPage,
} from "@src/shared_modules/sidenav";
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { Icon, IconTypes } from "@src/shared_modules/icon";
import { bindActionCreators } from "redux";
import { UserState, withUser } from "../user";
import { logoutUser } from "./action";
import styles from "./styles.module.css";
import { IntegrationsModeFeatureFlag } from "../integrations_mode_feature_flag";

// // // //

// SettingsSubNavProps represents the props that should be passed to the SettingsSubNav component
interface SettingsSubNavProps {
    route: RouteState;
    user: UserState;
}

interface DispatchProps {
    logoutUser: () => void;
}

// SettingsSubNav is the navigation for the settings pages
function SettingsSubNav(props: SettingsSubNavProps & DispatchProps) {
    const { route, user } = props;

    // Defines flag to apply the active state to the "Manual Uploads" link
    const vendorRolesUploadsActive: boolean = [
        String(routes.settings.accessControlVendorRolesUploadsListing),
        String(routes.settings.accessControlVendorRolesUploadsNew),
    ].includes(route.nextPathname);

    // Defines flag to apply the active state to the "Custom Fields" link
    const customFieldsActive: boolean = [
        String(routes.settings.customFields),
        String(routes.settings.editOrgField),
        String(routes.settings.newOrgField),
    ].includes(props.route.nextPathname);

    const webhooksActive: boolean = [
        String(routes.settings.webhooks),
        String(routes.settings.webhookNew),
        String(routes.settings.webhookIndividual),
    ].includes(props.route.nextPathname);

    const accessControlActive: boolean = [
        String(routes.settings.accessControlReconciliation),
        String(routes.settings.accessControlVendorRolesUploadsListing),
        String(routes.settings.accessControlVendorRolesUploadsNew),
    ].includes(props.route.nextPathname);

    return (
        <React.Fragment>
            <div className="tw-flex tw-flex-col">
                <SideNavGroup
                    header="Teams &amp; Users"
                    icon={
                        <Icon
                            type={IconTypes.Teams}
                            className={classnames(
                                "tw-h-[19px] tw-w-[19px]",
                                styles.teamsIcon
                            )}
                        />
                    }
                >
                    <SideNavLink
                        href={routes.settings.users}
                        active={route.nextPathname === routes.settings.users}
                    >
                        Dashboard Users
                    </SideNavLink>
                    <IntegrationsModeFeatureFlag>
                        <SideNavLink
                            href={routes.settings.teams}
                            active={
                                route.nextPathname === routes.settings.teams
                            }
                        >
                            Manage Teams
                        </SideNavLink>

                        <FeatureFlagCC
                            type="hidden"
                            featureName={FeatureNames.departmentTeams}
                            desc=""
                        >
                            <SideNavLink
                                href={routes.settings.set_team_department}
                                active={
                                    route.nextPathname ===
                                    routes.settings.set_team_department
                                }
                            >
                                Teams Usage
                            </SideNavLink>
                        </FeatureFlagCC>
                    </IntegrationsModeFeatureFlag>
                </SideNavGroup>

                <IntegrationsModeFeatureFlag>
                    <FeatureFlagCC
                        featureName={FeatureNames.enableVendorRolesUploads}
                    >
                        <SideNavGroup header="Access Control">
                            <FeatureFlagCC
                                featureName={
                                    FeatureNames.enableAccessControlReconciliation
                                }
                            >
                                <SideNavLink
                                    active={
                                        route.nextPathname ===
                                        routes.settings
                                            .accessControlReconciliation
                                    }
                                    href={
                                        routes.settings
                                            .accessControlReconciliation
                                    }
                                >
                                    Reconciliation
                                </SideNavLink>
                            </FeatureFlagCC>

                            <SideNavLink
                                active={vendorRolesUploadsActive}
                                href={
                                    routes.settings
                                        .accessControlVendorRolesUploadsListing
                                }
                            >
                                Manual Uploads
                            </SideNavLink>
                        </SideNavGroup>
                    </FeatureFlagCC>
                </IntegrationsModeFeatureFlag>

                <SideNavGroup header="Configuration">
                    {/* Renders Access Control - Reconciliation link for users without the enableVendorRolesUploads FF enabled */}
                    <IntegrationsModeFeatureFlag>
                        <FeatureFlagCC
                            featureName={FeatureNames.enableVendorRolesUploads}
                            variation={false}
                        >
                            <FeatureFlagCC
                                featureName={
                                    FeatureNames.enableAccessControlReconciliation
                                }
                            >
                                <SideNavLink
                                    active={accessControlActive}
                                    href={
                                        routes.settings
                                            .accessControlReconciliation
                                    }
                                >
                                    Access Control
                                </SideNavLink>
                            </FeatureFlagCC>
                        </FeatureFlagCC>

                        <FeatureFlagCC
                            featureName={FeatureNames.enableApiKeysPage}
                        >
                            {/* API Keys navigation sidebar link is only available to Admin users */}
                            {user.isAdmin && (
                                <SideNavLink
                                    active={
                                        routes.settings.apiKeys ===
                                        props.route.nextPathname
                                    }
                                    href={routes.settings.apiKeys}
                                >
                                    API Keys
                                </SideNavLink>
                            )}
                        </FeatureFlagCC>

                        <FeatureFlagCC
                            featureName={FeatureNames.enableAuditLog}
                        >
                            <SideNavLink
                                active={
                                    routes.settings.auditLog ===
                                    props.route.nextPathname
                                }
                                href={routes.settings.auditLog}
                            >
                                Audit Log
                            </SideNavLink>
                        </FeatureFlagCC>

                        <SideNavLink
                            active={customFieldsActive}
                            href={routes.settings.customFields}
                        >
                            Custom Fields
                        </SideNavLink>

                        <FeatureFlagCC
                            type="hidden"
                            featureName={FeatureNames.demoSettings}
                            desc=""
                        >
                            <SideNavLink
                                active={
                                    routes.settings.demo ===
                                    props.route.nextPathname
                                }
                                href={routes.settings.demo}
                            >
                                Demo Settings
                            </SideNavLink>
                        </FeatureFlagCC>

                        <SideNavLink
                            active={
                                routes.settings.paymentUploads ===
                                props.route.nextPathname
                            }
                            href={routes.settings.paymentUploads}
                        >
                            Payment Uploads
                        </SideNavLink>
                    </IntegrationsModeFeatureFlag>

                    <SideNavLink
                        active={
                            routes.settings.saml === props.route.nextPathname
                        }
                        href={routes.settings.saml}
                    >
                        SAML Settings
                    </SideNavLink>

                    <IntegrationsModeFeatureFlag>
                        <SideNavLink
                            href={routes.settings.webhooks}
                            active={webhooksActive}
                        >
                            Webhooks
                        </SideNavLink>
                    </IntegrationsModeFeatureFlag>
                </SideNavGroup>
            </div>
            <button
                className={classnames(
                    "text-black text-left font-secondary font-size-14-px line-height-19-px", // Font
                    "tw-border-none", // Border
                    "tw-bg-transparent", // Background
                    "hover:tw-underline"
                )}
                onClick={props.logoutUser}
            >
                Sign Out
            </button>
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch): DispatchProps {
    return bindActionCreators(
        {
            logoutUser,
        },
        dispatch
    );
}

export const SettingsSubNavCC: React.ComponentType<SettingsSubNavProps> = connect(
    null,
    mapDispatchToProps
)(SettingsSubNav);

interface NavLayoutProps {
    children: React.ReactNode;
    fullWidth: boolean; // Indicates whether or not this layout is rendered full-width (provided by DashboardNavLayout)
}

// SettingsNav is a hoc to generate the settings nav. The reason that we generate this as a wrapper is so that it can easily wrap around redux's connect function
function NavLayout(
    props: NavLayoutProps & { route: RouteState; user: UserState }
) {
    return (
        <SideNavLayout fullWidth={props.fullWidth}>
            <SettingsSubNavCC user={props.user} route={props.route} />

            <SideNavPage>
                <div className="tw-flex tw-flex-col tw-grow tw-w-full">
                    {props.fullWidth && props.children}
                    {!props.fullWidth && (
                        <div style={{ maxWidth: "880px" }}>
                            {props.children}
                        </div>
                    )}
                </div>
            </SideNavPage>
        </SideNavLayout>
    );
}

export const SettingsNavLayout: React.ComponentType<NavLayoutProps> = withRouter(
    withUser(NavLayout)
);

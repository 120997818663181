/**
 * TableCellType are the possible cell types
 */
export type TableCellType =
    | "boolean"
    | "checkbox"
    | "checklist"
    | "date"
    | "datetime"
    | "download_link"
    | "email"
    | "frequency_category"
    | "gdpr"
    | "integrationInstance"
    | "integration_status"
    | "integrationUser"
    | "masterUser"
    | "name"
    | "notes"
    | "number"
    | "integer"
    | "float"
    | "percentage_bar"
    | "percentage"
    | "price"
    | "privileges"
    | "rank"
    | "rbac_roles"
    | "source"
    | "privilege"
    | "state"
    | "switch"
    | "text_array"
    | "text_expandable"
    | "text"
    | "vendor";

/**
 * TableCellType
 * Defines the available default TableCell types supported by the AdvancedFiltersTable
 * NOTE - this enum is going to grow significantly as we continue to develop the AdvancedFiltersTable
 * Corresponding backend definitions in Walle: https://github.com/intello-io/walle/blob/main/fl/columns.go
 */
export enum TableCellTypes {
    // boolean is a boolean value
    boolean = "boolean",
    // checkbox is a checkbox type
    checkbox = "checkbox",
    // checklist represents a list of multiple options
    checklist = "checklist",
    // date is a field that should be represented as a date
    date = "date",
    // datetime is a field that should be represented as a datetime
    datetime = "datetime",
    // downloadLink is a link to download a file
    downloadLink = "download_link",
    // email is an email type
    email = "email",
    // gdpr is a gdpr type
    gdprStatus = "gdpr",
    // integrationInstance represents an integration instance column
    integrationInstance = "integrationInstance",
    // integrationStatus represents the status of an integration ("live, archived, inactive")
    integrationStatus = "integration_status",
    // integrationUser represents an integration user type
    integrationUser = "integrationUser",
    // masterUser represents a master user type
    masterUser = "masterUser",
    // name represents a name field
    name = "name",
    // notes represents a notes field from application fields
    notes = "notes",
    // number is a number type
    number = "number",
    // integer is a numeric integer type
    integer = "integer",
    // float is a numeric float type
    float = "float",
    // represents percentage rate as a graphic
    percentageBar = "percentage_bar",
    // represents percentage rate
    percentage = "percentage",
    // price is a price column
    price = "price",
    // privilege represents a privilege type from access control
    privileges = "privileges",
    // represents rank cell type, which renders type of data connection (direct, 3rd party, sso, desktop)
    rank = "rank",
    // rbacRoles is an rbac role field
    rbacRoles = "rbac_roles",
    // source represents a source type
    source = "source",
    // state represents a state type
    state = "state",
    // switch is a switch type
    switch = "switch",
    // textArray represents a field for an array of strings
    textArray = "text_array",
    // textExpandable is an expandable text field
    textExpandable = "text_expandable",
    // text is a generic text type
    text = "text",
    // vendor represents a vendor column
    vendor = "vendor",
    // Workflow frequency column
    frequencyCategory = "frequency_category",
}

import { AppEvents } from "@src/analytics/events";
import { appIndividual, routes } from "@src/routes";
import { launchSupport } from "@src/shared_modules/client";
import { LinkCC } from "@src/shared_modules/router";
import {
    CloseModalButton,
    hideModal,
    ModalCC,
} from "@src/shared_modules/modal";
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { FilterExpression } from "../../modules/advanced_filters/components/advanced_filter_editor";
import { VendorInput } from "../../modules/advanced_filters/components/advanced_filter_editor/vendor_input";

// // // //

// Add application modal name
export const ADD_APPLICATION_MODAL_NAME = "add-application";

/**
 * DispatchProps
 * Props that are injected into `AddAppModal` by redux connect() function
 */
interface DispatchProps {
    onCloseModal: () => void;
}

/**
 * AddAppModal
 * Renders the add application modal
 */
export function AddAppModal(props: DispatchProps) {
    const [selectedVendorID, setSelectedVendorID] = React.useState<
        number | null
    >(null);

    const addAppButtonClassName: string =
        "btn btn-md btn-primary font-secondary-bold font-size-14-px rounded-btn w-100 px-20-px line-height-24-px";

    return (
        <ModalCC
            onClickOutside={() => {
                setSelectedVendorID(null);
            }}
            name={ADD_APPLICATION_MODAL_NAME}
            style={{ maxWidth: "626px" }}
        >
            <div className="d-flex justify-content-end">
                <CloseModalButton onClick={props.onCloseModal} />
            </div>
            <div className="px-30-px pb-30-px">
                <h1 className="font-size-28-px text-updated-black font-primary-bold pb-12-px">
                    Add an Application
                </h1>
                <p className="pb-20-px">
                    Type the name of your application you wish to add in the box
                    below.
                </p>
                <form className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <VendorInput
                            placeholder="Application Name"
                            value={selectedVendorID}
                            defaultFilters={[
                                {
                                    fieldName: "state",
                                    val: "live",
                                    expression: FilterExpression.eq,
                                    filters: [],
                                },
                            ]}
                            onChange={(vendorID) => {
                                setSelectedVendorID(vendorID);
                            }}
                        />
                    </div>
                    <div className="pl-12-px col-4 pr-0-px d-flex">
                        {selectedVendorID === null ? (
                            <button className={addAppButtonClassName} disabled>
                                Add Application
                            </button>
                        ) : (
                            <LinkCC
                                eventName={AppEvents.appCreatedFromModal}
                                href={{
                                    pathname: routes.spendIndividual,
                                    query: {
                                        vendorID: String(selectedVendorID),
                                    },
                                }}
                                as={appIndividual(selectedVendorID)}
                                className={addAppButtonClassName}
                            >
                                Add Application
                            </LinkCC>
                        )}
                    </div>
                </form>
                <p className="pt-50-px pb-5-px font-secondary-bold">
                    Can’t find the application you’re trying to add?
                </p>
                <p className="pb-15-px">
                    <button
                        className={classnames(
                            "tw-bg-transparent p-0 border-none text-primary outline-none tw-underline"
                        )}
                        onClick={() =>
                            launchSupport(
                                "Please tell us the domain of the application you want to add"
                            )
                        }
                    >
                        Contact us
                    </button>{" "}
                    and we’ll add it to our database
                </p>
            </div>
        </ModalCC>
    );
}

function mapDispatchToProps(dispatch): DispatchProps {
    return {
        onCloseModal: () => dispatch(hideModal()),
    };
}

export const AddApplicationModal = connect(
    null,
    mapDispatchToProps
)(AddAppModal);

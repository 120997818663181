import classnames from "classnames";
import * as React from "react";
import styles from "./styles.module.css";

// // // //

/**
 * PillProps
 * `label` - (optional) The label rendered inside the pill (the blue text)
 * `value` - (optional) The value associated with the label (the black text)
 * `onClickRemove` - (optional) callback method fired when clicking the little `x` to remove the pill
 * `className` - (optional) additional className to include on the pill
 * `renderRemoveButton` - (optional) boolean indicating whether or not to render the little `x` without an onClick handler
 */
interface PillProps {
    label?: string;
    value?: string;
    children?: React.ReactNode;
    onClickRemove?: () => void;
    className?: string;
    textClassName?: string;
    renderRemoveButton?: boolean;
}

/**
 * Pill
 * Renders a single "Pill"-style element with label+value and a dedicated remove button
 * @param props - see `PillProps`
 */
export function Pill(props: PillProps) {
    // Pulls className w/ default value from props
    const { className = "", textClassName = "text-primary" } = props;

    // Assembles className for remove button
    const removeButtonClassName =
        "font-secondary-heavy font-size-18-px tw-text-gray-400 hover:tw-text-gray-600 tw-transition-colors tw-bg-transparent tw-outline-none border-none cursor-pointer tw-pr-0 tw-pl-1";

    return (
        <div
            className={classnames({
                "d-flex align-items-center bg-white font-secondary-heavy font-size-12-px px-15-px tw-gap-2 tw-border tw-border-solid tw-border-gray-200 tw-rounded-full tw-select-none": true,
                [styles.pill]: true,
                [className]: className !== "",
            })}
        >
            {props.label && <p className={textClassName}>{props.label}</p>}

            {props.value && (
                <p
                    className={`text-updated-black ml-5-px ${styles.filterValue}`}
                >
                    {props.value}
                </p>
            )}

            {/* Render props.children IFF props.children is defined */}
            {props.children && (
                <React.Fragment>{props.children}</React.Fragment>
            )}

            {props.onClickRemove && (
                <span
                    onClick={props.onClickRemove}
                    className={removeButtonClassName}
                >
                    ×
                </span>
            )}

            {props.renderRemoveButton && (
                <span className={removeButtonClassName}>×</span>
            )}
        </div>
    );
}

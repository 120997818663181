// makeCharacterString
// Assembles a string of `placeholderCharacter` copied `length` times
// NOTE - this was abstracted into its own module so it can be individually mocked so
// our test snapshots don't fail due to the variable length of strings returned by this function
export default function makeCharacterString(
    length: number,
    placeholderCharacter: string
): string {
    // Creates an Array and populates it with `length` number of `placeholderCharacter` strings
    // Joins the array together with the empty string "" and returns the result
    return Array(length)
        .fill(placeholderCharacter)
        .join("");
}

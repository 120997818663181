import React from "react";

export function Settings(props?: { className?: string; label: string }) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            aria-labelledby="title"
        >
            <title id="title">{props.label}</title>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#BDBDBD" transform="translate(-18 -308)">
                    <path d="M25 324a.323.323 0 01-.333-.333v-1.9c-.528-.134-1.113-.3-1.555-.533l-1.355 1.366a.327.327 0 01-.471 0l-1.886-1.866a.396.396 0 01-.098-.267c0-.067.035-.167.098-.233l1.355-1.334c-.215-.467-.385-1.033-.519-1.567h-1.903A.323.323 0 0118 317v-2c0-.167.149-.333.333-.333h1.903c.134-.534.304-1.1.52-1.534l-1.356-1.366a.327.327 0 010-.467l1.886-1.9a.328.328 0 01.471 0l1.355 1.367c.443-.234 1.028-.4 1.555-.533v-1.9c0-.167.149-.334.333-.334h2c.184 0 .333.167.333.333v1.9c.526.134 1.11.3 1.554.534l1.355-1.367a.328.328 0 01.471 0l1.886 1.9a.314.314 0 01.098.233.378.378 0 01-.098.234l-1.355 1.333c.216.467.386 1.033.519 1.567h1.904c.184 0 .333.166.333.333v2c0 .2-.15.333-.333.333h-1.904c-.133.534-.303 1.1-.519 1.567l1.355 1.334c.13.133.13.366 0 .5l-1.885 1.866a.327.327 0 01-.471 0l-1.356-1.366c-.442.233-1.027.4-1.554.533v1.9c0 .2-.149.333-.333.333h-2zm1-4.333a3.667 3.667 0 100-7.334 3.667 3.667 0 000 7.334z"></path>
                </g>
            </g>
        </svg>
    );
}

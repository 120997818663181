import { BreadcrumbNode } from "../types";
import { apiKeysState } from "./state";
import { settingsNode } from "../settings";

// // // //

export const apiKeysNode: BreadcrumbNode = {
    getState: apiKeysState,
    parent: settingsNode,
};

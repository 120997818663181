/**
 * Enum of available action types for the
 */
export enum DateRangeActionTypes {
    setStartYear = "SET_BASELINE_YEAR",
    setStartWeek = "SET_START_WEEK",
    setEndYear = "SET_END_YEAR",
    setEndWeek = "SET_END_WEEK",
}

/**
 * Action for setYear action types
 */
export interface DateRangeYearAction {
    type: DateRangeActionTypes.setStartYear | DateRangeActionTypes.setEndYear;
    year: number;
}

/**
 * Action for setWeek action types
 */
export interface DateRangeWeekAction {
    type: DateRangeActionTypes.setStartWeek | DateRangeActionTypes.setEndWeek;
    week: string;
}

/**
 * Type union of available actions
 */
export type DateRangeAction = DateRangeYearAction | DateRangeWeekAction;
